import { BaseWidget } from "@proviz/proviz-sdk"
import { useEffect, useState } from 'react'

interface Props {
    node: BaseWidget;
}

export const NodeJson = (props: Props) => {
    const { node } = props;
    const [json, setJson] = useState(JSON.stringify(node.serialize()));

    useEffect(() => {
        const tabSize = 2;
        setJson(JSON.stringify(node.serialize(), undefined, tabSize));
    }, [node])

    return (
        <div>
            <h5>JSON</h5>
            <textarea key={`json-${node.id}`} style={{ minHeight: '250px' }} value={json} contentEditable={false} />
        </div>
    )
}
