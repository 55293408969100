import { BaseDataService } from "../BaseDataService";
import { APIFolder } from "../models";
import { FolderType } from "../types/FolderType";

class APIFolderService extends BaseDataService<APIFolder> {
  constructor() {
    super("folder");
  }

  public async getFolders(
    folderId: string
  ): Promise<APIFolder[]> {
    return await this.request(`parent/${folderId}`);
  }

  public async getFoldersByType(
    folderType: FolderType,
    folderId: string | null
  ): Promise<APIFolder[]> {
    if (folderId != null)
      return await this.request(`type/${folderType}/${folderId}`);
    return await this.request(`type/${folderType}`);
  }

  public async create(
    folderName: string,
    folderType: FolderType,
    parentFolderId: string | null
  ): Promise<APIFolder> {
    return await this.request(``, { method: 'POST', body: {
      folderName,
      folderType,
      parentFolderId
    }});
  }

  public async update(
    id: string,
    folderName: string,
    parentFolderId: string | null
  ): Promise<APIFolder> {
    return await this.request(`${id}`, { method: 'PUT', body: {
      folderName,
      parentFolderId
    }});
  }
}

const FolderService = new APIFolderService();

export default FolderService;
