import {
  AnimationClip,
  AnimationMixer,
  BackSide,
  DoubleSide,
  FrontSide,
  Group,
  LoopOnce,
  LoopRepeat,
  Material,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  ShaderMaterial,
  Side,
} from 'three';
import { LoadingScreen, ProVizScene, SceneMode } from '../../..';
import {
  APIMaterialSet,
  APIModel,
  APIModelVariant,
  MaterialSetService,
  ModelService,
  ModelVariantService,
} from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import ProVizLoader from '../../../utils/ProVizLoader';
import {
  applyTextureEncoding,
  encodingOptions,
  setVisibleRec,
  setCastShadow,
  setRenderOrder,
  disposeModel,
} from '../../../utils';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { LoadingIndicator } from './loadingIndicator';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { MeshWidget } from './meshWidget';
import { ProVizEventData } from '../../../ProVizEventData';
import ProVizXRRequestCache from '../../../utils/ProVizXRRequestCache';
import { ResourceLink } from '@proviz/api-services';

type LoadingState = 'loading' | 'loaded' | 'failed';

export class ModelWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'model';
  private static proVizLoader = new ProVizLoader();
  private apiModel?: APIModel;
  private apiModelVariant?: APIModelVariant;
  public loadingState: LoadingState = 'loading';
  private model?: Group;
  /**
   * These represent MeshWidgets that correspond that will only exist if
   * this model has been converted to have child meshes. This is a semi experimental
   * feature at this stage.
   */
  private childMeshes: MeshWidget[] = [];

  // Data
  public modelId: string | undefined;
  public modelVariantId?: string;
  public clickable: boolean = true;
  public transparent: boolean = false;
  public opacity: number = 1.0;
  /**
   * This is not a user editable property.
   * It is used by the model embed view scenes so that the apiModel data can be added directly.
   */
  public deferInit: boolean = false;
  public castShadow: boolean = true;
  public playAnimationOnLoad: boolean = false;
  public selectedAnimation: string = '';
  public envMapSrc: string = '';
  public loopAnimation: boolean = true;
  public animationEnd: string = 'return';
  public textureEncoding: string = 'default';
  public hasChildMeshes: boolean = false;
  public renderOrderOverride?: number = undefined;
  public depthTest: boolean = true;
  public depthWrite: boolean = true;
  public renderSide: Side | undefined = undefined;

  private materialOverride: ShaderMaterial | MeshBasicMaterial | undefined = undefined;

  // runtime data
  fileSrc: string = '';
  private animationMixer?: AnimationMixer;
  private activeActions: any[] = [];

  private static modelRequestCache: ProVizXRRequestCache<APIModel> =
    new ProVizXRRequestCache<APIModel>();
  private static modelVariantRequestCache: ProVizXRRequestCache<APIModelVariant> =
    new ProVizXRRequestCache<APIModelVariant>();
  private static materialSetRequestCache: ProVizXRRequestCache<APIMaterialSet> =
    new ProVizXRRequestCache<APIMaterialSet>();

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ModelWidget.type;
    this.widgetName = 'Model';
    this.label = 'Model';
    this.selectable = true;
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Fit to Model',
        name: 'fit-to',
        desc: 'Position an object to the size of this model. Event dispatches once the model loads into the scene.',
        // if you're relying on this you should probably check if the model is loaded first and trigger that widget's
        // fit to method immediately before adding the event listener.
      },
      {
        label: 'Model Loaded',
        name: 'model-loaded',
        desc: 'Dispatched when the model has loaded into the scene.',
      }
    ];

    this.services.push(
      {
        label: 'Set Variant',
        name: 'set-variant',
      },
      {
        label: 'Play Animation',
        name: 'play-animation',
        desc: `If the event originates from a list widget, you can specify which animations play. 
        Otherwise only the selected animation on the model will play.`,
      },
      {
        label: 'Pause Animation',
        name: 'pause-animation',
        desc: `Pause animation so that play can resume at the same key frame. 
      A list widget can be used to specify which animations to pause. If no list is provided all animations will be paused.`,
      },
      {
        label: 'Stop Animation',
        name: 'stop-animation',
        desc: `Stop the animation so that play will reset to the beginning. 
      A list widget can be used to specify which animations to pause. Otherwise, all animations will be paused.`,
      },
      {
        label: 'Hide all meshes',
        name: 'hide-all',
        desc: 'EXPERIMENTAL - Hide all submeshes of this model. Use this when you want to set only select submeshes of the model visible.',
      },
      {
        label: 'Show all meshes',
        name: 'show-all',
        desc: 'EXPERIMENTAL - Set all submeshes of this model to visible. Use this after calling hide all meshes. In most cases you can just use the basic hide and show events.',
      },
    );

    this.addEventListener('service-set-variant', async (event: ProVizEventData) => {
      if (event.dataType === 'modelVariantId') {
        const modelVariantId = event.data as string;
        if (this.modelVariantId === modelVariantId) {
          // do nothing if the variant is already set to the new value
          return;
        }
        this.modelVariantId = modelVariantId;
        this.apiModelVariant = undefined;
        if (this.modelVariantId) {
          this.apiModelVariant = await ModelVariantService.get(this.modelVariantId);
        }
        LoadingScreen.ShowLoading();
        await this.loadMaterials();
        LoadingScreen.HideLoading();
      }
    });

    this.addEventListener('service-play-animation', (e: ProVizEventData) => {
      if (e.dataType === 'list') this.playAnimation(e.data as string[]);
      else this.playAnimation();
    });
    this.addEventListener('service-pause-animation', (e: ProVizEventData) => {
      if (e.dataType === 'list') this.pauseAnimation(e.data as string[]);
      else this.pauseAnimation();
    });
    this.addEventListener('service-stop-animation', (e: ProVizEventData) => {
      if (e.dataType === 'list') this.stopAnimation(e.data as string[]);
      else this.stopAnimation();
    });
    this.addEventListener('service-hide-all', () => {
      if (this.model) {
        setVisibleRec(this.model, false);
      } else {
        this.addEventListener(
          'model-loaded',
          () => this.model && setVisibleRec(this.model, false),
          true,
        );
      }
    });
    this.addEventListener('service-show-all', () => {
      if (this.model) {
        setVisibleRec(this.model, true);
      } else {
        // While the model should be loaded as visible we still add the listener
        // in case hide-all has also been requested. This may result in extra computation
        // but will ensure that the last hide/show all call is executed.
        this.addEventListener(
          'model-loaded',
          () => {
            if (this.model) {
              setVisibleRec(this.model, true);
            } else {
              console.error('Model should exist after model-loaded event');
            }
          },
          true,
        );
      }
    });

    this.addEventListener('service-wireframe', (data: ProVizEventData) => {
      if (data.dataType === 'boolean') {
        this.setWireframe(data.data as boolean);
      }
    });
  }

  public async getResourceLinks(): Promise<ResourceLink[]> {
    const result = await super.getResourceLinks();

    if (this.modelId) {
      const m = await ModelWidget.modelRequestCache.request(this.modelId, () => {
        if (this.modelId) {
          ModelService.get(this.modelId, {
            abortController: this.scene.abortController,
          }).then((result) => {
            if (this.modelId) {
              ModelWidget.modelRequestCache.resolve(this.modelId, result);
            }
          });
        }
      });

      // API request
      result.push({
        filename: `_v1_Model_${this.modelId}`,
        data: JSON.stringify(m),
        location: undefined,
      });

      if (m.gltfFile) {
        result.push({
          filename: `${this.modelId}.gltf`,
          data: undefined,
          location: m.gltfFile.file.location,
        });
      }
    }

    return result;
  }

  public setMaterial(material: ShaderMaterial | MeshBasicMaterial) {
    this.materialOverride = material;
    if (this.model) {
      for (let i = 0; i < this.model.children.length; i++) {
        const obj = this.model.children[i];
        if (obj instanceof Mesh) {
          obj.material = material;
        }
      }
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'modelId',
        'Model',
        'Core',
        'model',
        'string',
        true,
        () => this.modelId,
        (id: string) => this.setModelId(id),
      ),
      this.createProperty(
        'modelVariantId',
        'Model Variant',
        'Core',
        'model-variant',
        'string',
        true,
        () => {
          return this.modelVariantId;
        },
        (id: string) => this.setModelVariantId(id),
      ),
      this.createProperty('clickable', 'Clickable', 'Interaction', 'bool', 'boolean', true),
      this.createProperty('transparent', 'Transparent', 'Core', 'bool', 'boolean', true),
      this.createProperty('castShadow', 'Cast Shadow', 'Core', 'bool', 'boolean', true),
      this.createProperty(
        'playAnimationOnLoad',
        'Play Animation Automatically',
        'Animation',
        'bool', 
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'If there is an animation track associated with this model begin looping the animation once model loads. If there is no track, this option has no effect',
      ),
      this.createProperty('loopAnimation', 'Loop Animation', 'Animation', 'bool', 'boolean', true),
      this.createProperty(
        'selectedAnimation',
        'Select an animation track',
        'Animation',
        'select',
        'string',
        true,
        undefined,
        undefined,
        () => {
          return (this.model?.animations.map((a) => a.name) ?? []).map(x => { return { key: x, label: x }});
        },
        'Choose which animation track should play, if an animation track exists on this model. These animation tracks must be created in an external program such as Blender or Cinema 4D',
      ),
      this.createProperty(
        'animationEnd',
        'Animation End Behavior',
        'Animation',
        'select',
        'string',
        true,
        undefined,
        undefined,
        () => ['Return', 'Clamp'].map(x => { return { key: x, label: x }}),
        'This property only takes effect if you are not looping your animation. Return will return the model to its resting state. Clamp will freeze at the last frame of the animation.',
      ),
      this.createProperty(
        'opacity',
        'Opacity',
        'Core',
        'constrained-number',
        'number',
        true,
        undefined,
        undefined,
        () => [0, 1].map(x => { return { key: x, label: x.toString() }}),
      ),
      this.createProperty(
        'textureEncoding',
        'Texture Encoding',
        'Experimental',
        'select',
        'string',
        true,
        undefined,
        (data: string) => {
          this.textureEncoding = data;
          this.applyTextureEncoding();
        },
        () => ['default', 'sRGB', 'Linear'].map(x => { return { key: x, label: x }}),
        'This property is still experimental. Default will not change anything but you may need to refresh to see the effect.',
      ),
      this.createProperty(
        'hasChildMeshes',
        'Add Child Meshes',
        'Experimental',
        'bool',
        'boolean',
        true,
        undefined,
        (data: boolean, updateNodes?: () => void) => {
          if (
            confirm(
              'Are you sure you want to add child meshes from this mesh to the scene tree. This may break things - proceed with caution.',
            )
          ) {
            this.hasChildMeshes = data;
            if (data) {
              if (this.model) {
                // this.setupKeyNames(this.model);
                this.childMeshes = this.addChildMeshes(this.model);
              }
            } else {
              this.removeChildMeshes();
            }
            updateNodes && updateNodes();
          }
        },
      ),
      this.createProperty(
        'renderOrderOverride',
        'Render Order',
        'Experimental',
        'number',
        'number',
        true,
        () => {
          return this.renderOrderOverride || this.renderNode.renderOrder;
        },
        (data: any) => {
          this.renderOrderOverride = data;
          this.renderNode.renderOrder = data;
        },
      ),
      this.createProperty(
        'depthTest',
        'Depth Test',
        'Experimental',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
      ),
      this.createProperty(
        'depthWrite',
        'Depth Write',
        'Experimental',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
      ),
      this.createProperty(
        'renderSide',
        'Face Side Override',
        'Experimental',
        'select',
        'string',
        true,
        () => {
          if (this.renderSide === FrontSide) {
            return 'Front';
          }
          if (this.renderSide === BackSide) {
            return 'Back';
          }
          if (this.renderSide === DoubleSide) {
            return 'Both';
          }
          return 'default';
        },
        (data: string) => {
          switch (data) {
            case 'Front':
              this.renderSide = FrontSide;
              break;
            case 'Back':
              this.renderSide = BackSide;
              break;
            case 'Both':
              this.renderSide = DoubleSide;
              break;
            case 'default':
              this.renderSide = undefined;
              break;
            default:
              this.renderSide = undefined;
          }
        },
        () => ['default', 'Front', 'Back', 'Both'].map(x => { return { key: x, label: x }}),
        'This property is still experimental. Default will use the models default material settings.',
      ),
    ];
  }

  public setModelId(id: string) {
    this.modelId = id;
    this.loadModel().catch((e) => {
      console.error('could not set model id', e);
      alert('We could not load that model sorry');
    });
  }

  public setModelVariantId(id: string) {
    this.modelVariantId = id;
    this.loadMaterials().catch((e) => {
      console.error('could not set model variant', e);
      alert('We could not load that material set, sorry');
    });
  }

  private applyTextureEncoding() {
    if (this.textureEncoding === 'default') {
      return;
    }
    if (this.model) {
      const encoding = encodingOptions[this.textureEncoding];
      applyTextureEncoding(this.model, encoding);
    }
  }

  public serialize(): any {
    const result = super.serialize();
    result.modelId = this.modelId;
    result.modelVariantId = this.modelVariantId;
    result.clickable = this.clickable;
    result.transparent = this.transparent;
    result.opacity = this.opacity;
    result.deferInit = this.deferInit;
    result.playAnimationOnLoad = this.playAnimationOnLoad;
    result.selectedAnimation = this.selectedAnimation;
    result.loopAnimation = this.loopAnimation;
    result.animationEnd = this.animationEnd;
    result.textureEncoding = this.textureEncoding;
    result.hasChildMeshes = this.hasChildMeshes;
    result.renderOrderOverride = this.renderOrderOverride;
    result.depthTest = this.depthTest;
    result.depthWrite = this.depthWrite;
    if (this.renderSide !== undefined) {
      if (this.renderSide == FrontSide) {
        result.renderSide = 1;
      } else if (this.renderSide == BackSide) {
        result.renderSide = 2;
      } else {
        result.renderSide = 3;
      }
    }
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.modelId = data.modelId;
    this.modelVariantId = data.modelVariantId;
    this.clickable = data.clickable;
    this.transparent = data.transparent;
    this.opacity = data.opacity;
    this.deferInit = data.deferInit;
    this.playAnimationOnLoad = data.playAnimationOnLoad ?? this.playAnimationOnLoad;
    this.selectedAnimation = data.selectedAnimation ?? '';
    this.loopAnimation = data.loopAnimation ?? this.loopAnimation;
    this.animationEnd = data.animationEnd ?? this.animationEnd;
    this.textureEncoding = data.textureEncoding ?? this.textureEncoding;
    this.hasChildMeshes = data.hasChildMeshes ?? this.hasChildMeshes;
    this.renderOrderOverride = data.renderOrderOverride;
    this.depthTest = data.depthTest ?? this.depthTest;
    this.depthWrite = data.depthWrite ?? this.depthWrite;
    if (data.renderSide !== undefined) {
      if (data.renderSide == 1) {
        this.renderSide = FrontSide;
      } else if (this.renderSide == 2) {
        this.renderSide = BackSide;
      } else {
        this.renderSide = DoubleSide;
      }
    }
  }

  dispose() {
    super.dispose();
    if (this.model) {
      disposeModel(this.model);
    }
  }

  public get animations(): any[] {
    if (!this.model || !this.model.animations) {
      return [];
    }
    return this.model.animations;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.clickable && this.visible && this.model) {
      result.push(this.model);
    }
    return result;
  }

  private async loadModel() {
    if (this.model) {
      this.renderNode.remove(this.model);
      this.model = undefined;
    }
    if (this.modelId === undefined) {
      return;
    }

    await this.setupModel();
  }

  public async setupFromModel(
    model: APIModel,
    variant?: APIModelVariant,
    onProgress?: (event: ProgressEvent) => void,
  ) {
    this.apiModel = model;
    this.apiModelVariant = variant;
    this.setupModel(model, variant, onProgress);
  }

  private async setupModel(
    apiModel?: APIModel,
    apiVariant?: APIModelVariant,
    onProgress?: (event: ProgressEvent) => void,
  ) {
    this.removeAnimationListeners();
    if (!this.modelId) {
      return;
    }
    let tempModel: Group | undefined = undefined;
    if (this.scene.sceneMode === SceneMode.Editor) {
      tempModel = LoadingIndicator.createInstance();
      this.renderNode.add(tempModel);
    }

    // Load model from API
    if (!apiModel) {
      apiModel = await ModelWidget.modelRequestCache.request(this.modelId, () => {
        if (this.modelId) {
          ModelService.getSmall(this.modelId).then((result) => {
            if (this.modelId) {
              ModelWidget.modelRequestCache.resolve(this.modelId, result);
            }
          });
        }
      });
    }
    this.apiModel = apiModel;

    let { glb, gltf,  } = ProVizLoader.GetFiles(apiModel, apiVariant); // updateMaterials
    if (glb) {
      this.fileSrc = glb;
    } else if (gltf) {
      this.fileSrc = gltf;
    } else {
      console.error(`No useable source ${apiModel.name || apiModel.id}`);
      throw new Error('No useable source.');
    }
    // Create Three.js render object
    // console.log('Model setup', this.fileSrc, updateMaterials);
    const model = await ModelWidget.proVizLoader.LoadGLTF(this.fileSrc, onProgress);
    console.log(model);
    if (apiModel !== this.apiModel || !this.scene) {
      // if api model has been changed while loading model, return
      // relevant in editor
      return;
    }
    console.log('Render Node: ', this.renderNode, this.renderNode.rotation.x);
    console.log(model);
    setRenderOrder(model, 2); // apply render order to all children
    setCastShadow(model, this.castShadow);
    this.model = model;
    this.renderNode.add(this.model);
    if (this.renderOrderOverride) {
      this.renderNode.renderOrder = this.renderOrderOverride;
      this.model.renderOrder = this.renderOrderOverride;
    }
    if (tempModel) {
      LoadingIndicator.drop(tempModel);
    }

    // Build up sub children
    this.setupChildMeshes();

    this.setupAnimationListeners();

    this.updateMaterialSettings(this.model, this.transparent);
    this.applyTextureEncoding();
    // if (this.materialOverride) {
    //   this.setMaterial(this.materialOverride);
    // } else {
    //   if (updateMaterials) {
    //     await this.loadMaterials();
    //   } else {
    //     this.updateMaterialSettings(this.model, this.transparent);
    //   }
    //   this.applyTextureEncoding();
    // }

    if (this.playAnimationOnLoad && this.scene.sceneMode !== SceneMode.Editor) {
      this.playAnimation();
    }

    this.loadingState = 'loaded';

    this.triggerProVizEvent('model-loaded', 'none');

    // trad (browser/js) event
    this.dispatchEvent('model-loaded', {
      dataType: 'none',
      sourceWidgetId: this.id,
      data: undefined,
      event: 'model-loaded',
    });

    this.setShadowed();
    // proviz event
    // this.triggerProVizEvent('fit-to', {
    //   template: 'standard',
    //   obj: this.renderNode,
    // });
    // render after lighting/any other listeners have adjusted for the newly loaded model.
  }

  private isTransparentMesh(obj: Object3D) {
    if (obj instanceof Mesh) {
      return obj.material.transparent ?? false;
    }
    return false;
  }

  private applyMaterial(obj: Object3D, m: Material) {
    if (obj instanceof Mesh) {
      obj.material = m;
    }
    obj.children.forEach(o => this.applyMaterial(o, m));
  }

  private updateMaterialSettings(obj: Object3D, transparent: boolean) {
    obj.renderOrder = this.renderOrderOverride ?? obj.renderOrder;
    console.log(obj);
    if (obj instanceof Mesh) {
      const m: Material = obj.material;
      if (this.renderSide !== undefined) {
        m.side = this.renderSide;
      }
      m.depthTest = m.depthTest ?? true;
      m.depthWrite = (transparent || this.isTransparentMesh(obj)) ? false : (m.depthWrite ?? true);
    }
    obj.children.forEach(o => this.updateMaterialSettings(o, transparent));
  }

  private addChildMeshes(o: Object3D, parent?: BaseWidget): MeshWidget[] {
    const result: MeshWidget[] = [];
    const meshWidgId = `${parent?.id ?? ''}-${o.name}`;
    // Check if the mesh widget exists in the widgets children
    // this should always be true when embed calls this else create the mesh widgets
    let _meshWidg = this.getById(meshWidgId);
    if (!_meshWidg) {
      _meshWidg = new MeshWidget(this.scene, parent || this);
      _meshWidg.id = meshWidgId;
      _meshWidg.label = o.name;
      (parent || this).addChild(_meshWidg, true);
    }
    const meshWidg = _meshWidg as MeshWidget;
    meshWidg.setReference(o);
    result.push(meshWidg);

    o.children.forEach((c: Object3D) => {
      const cs = this.addChildMeshes(c, _meshWidg);
      cs.forEach((ch) => result.push(ch));
    });

    return result;
  }

  private setupChildMeshes() {
    if (!this.model) {
      console.error('Cannot setup child meshes before model is defined');
      return;
    }
    this.setupKeyNames(this.model, {});
    this.removeChildMeshes();
    if (this.hasChildMeshes) {
      this.addChildMeshes(this.model);
    }
  }

  private removeChildMeshes() {
    this.childMeshes.forEach((w: BaseWidget) => {
      this.removeNode(w);
    });
    this.childMeshes = [];
  }

  private setupAnimationListeners() {
    if (!this.model) {
      this.removeAnimationListeners();
      return;
    }
    this.model.animations.forEach((a) => {
      const stopName = ModelWidget.animationName('stop', a.name);
      this.services.push({
        name: stopName,
        label: `Stop animation "${a.name}"`,
        desc: 'Stop this animation track.',
      });
      this.addEventListener(`service-${stopName}`, () => {
        this.stopAnimation([a.name]);
      });

      const pauseName = ModelWidget.animationName('pause', a.name);
      this.services.push({
        name: pauseName,
        label: `Pause animation "${a.name}"`,
        desc: 'Pause this animation track.',
      });
      this.addEventListener(`service-${pauseName}`, () => {
        this.pauseAnimation([a.name]);
      });

      const startName = ModelWidget.animationName('start', a.name);
      this.services.push({
        name: startName,
        label: `Start animation "${a.name}"`,
        desc: 'Start this animation track.',
      });
      this.addEventListener(`service-${startName}`, () => {
        this.playAnimation([a.name]);
      });
    });
  }

  private removeAnimationListeners() {
    this.services = this.services.filter((s) => !s.name.includes('ani-'));
    // we techicnally don't need to remove this because this should only be called in the editor unless we implement
    // a runtime change model service
    this.fuzzyRemoveEvents('service-ani-');
  }

  private static animationName(action: 'start' | 'stop' | 'pause', n: string) {
    let safeName = ModelWidget.safeAniName(n);
    return `ani-${action}-${safeName}`;
  }
  // Replace all spaces with dashes so
  // that event names serialize correctly
  private static safeAniName = (n: string) => n.replace(/ /g, '-');

  protected async loadMaterials(apiVariant?: APIModelVariant) {
    // Get model variant
    if (apiVariant) {
      this.apiModelVariant = apiVariant;
    } else if (this.modelVariantId || this.apiModel?.defaultVariantId) {
      const modelVariantId = this.modelVariantId || this.apiModel?.defaultVariantId;
      if (modelVariantId) {
        this.apiModelVariant = await ModelWidget.modelVariantRequestCache.request(
          modelVariantId,
          () => {
            ModelVariantService.get(modelVariantId).then((result) =>
              ModelWidget.modelVariantRequestCache.resolve(modelVariantId, result),
            );
          },
        );
      } else {
        console.warn('No model variant available or specified');
      }
    }
    if (!this.model) {
      console.error('No model when applying materials');
      return;
    }
    // Apply material set
    if (this.apiModelVariant) {
      const materialSet = await ModelWidget.materialSetRequestCache.request(
        this.apiModelVariant.materialSet.id,
        () => {
          MaterialSetService.get(this.apiModelVariant!.materialSet.id).then((result) =>
            ModelWidget.materialSetRequestCache.resolve(
              this.apiModelVariant!.materialSet.id,
              result,
            ),
          );
        },
      );
      if (!this.materialOverride) {
        await ProVizLoader.ApplyMaterialSet(
          this.model,
          materialSet,
          this.transparent ? this.opacity : 1.0,
          {
            depthTest: this.depthTest,
            depthWrite: this.depthWrite,
            side: this.renderSide,
          },
        );
        this.setShadowed(this.model);
      }
    } else if (this.transparent) {
      ProVizLoader.SetOpacity(this.model, this.opacity);
    }
  }

  public getHoverable() {
    const result = super.getHoverable();
    if (this.visible && this.clickable && this.model) {
      result.push(this.model);
    }
    return result;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.modelId && !this.deferInit) {
      this.loadModel();
    }

    return true;
  }

  public update(delta: number) {
    if (this.animationMixer) {
      this.animationMixer.update(delta);
    }
    super.update(delta);
  }

  public playAnimation(animations?: string[]) {
    if (!this.model) {
      return;
    }
    if (!this.animationMixer) {
      this.animationMixer = new AnimationMixer(this.model);
    }
    const clips = this.model.animations;
    if (clips.length > 0) {
      if (animations) {
        // multiple animation track
        // find all clips that match the list of animatons
        const clipsToPlay = animations.map((a) => AnimationClip.findByName(clips, a));
        // for each clip play animation and add aniimation to active animations list
        for (let c of clipsToPlay) {
          this.playClip(c, this.animationMixer);
        }
      } else {
        // single animation track
        // we default to the first animation
        let clip = clips[0];
        // override if there is a selected animation on widget
        if (this.selectedAnimation) {
          clip = AnimationClip.findByName(clips, this.selectedAnimation) ?? clip;
        }
        // play animation and add to list of active animations
        this.playClip(clip, this.animationMixer);
      }
    } else {
      console.warn('Could not play animation, no clips.');
    }
  }

  private playClip(clip: AnimationClip, mixer: AnimationMixer) {
    // check if action is already in active actions and resume play if it is
    const activeAction = this.activeActions.find((a) => a._clip.name === clip.name);
    if (activeAction) {
      activeAction.paused = false; // for some reason we also need to set paused false
      if (
        (!activeAction.repetitions || activeAction.loop === LoopOnce) &&
        (activeAction.time === 0 || clip.duration - activeAction.time < 0.01)
      ) {
        activeAction.reset();
      }
      activeAction.play();
      return;
    }
    const action = mixer.clipAction(clip);
    action.loop = this.loopAnimation ? LoopRepeat : LoopOnce;
    action.clampWhenFinished = this.animationEnd === 'Clamp';
    action.paused = false;
    action.play();
    this.activeActions.push(action);
  }

  /**
   * If no list of animations is provided we pause all active animations
   * otherwise we only pause the animations whose names are passed in.
   * Paused animations are kept in active animation list.
   **/
  public pauseAnimation(animationsToPause?: string[]) {
    if (animationsToPause) {
      this.activeActions.forEach((a) => {
        // using the internal _clip here may be hacky
        // but it gets us the name of the animation we wish to pause
        animationsToPause.forEach((toPause) => {
          if (a._clip.name.includes(toPause)) {
            a.paused = true;
          }
        });
      });
    } else {
      if (this.activeActions) {
        // pause all active actions
        this.activeActions.forEach((a) => (a.paused = true));
      }
    }
  }

  /**
   * If no list of animations is provided we stop all active animations
   * otherwise we only stop the animations whose names are passed in.
   * Stopped animations are removed from the active actions list.
   **/
  public stopAnimation(animationsToPause?: string[]) {
    if (animationsToPause) {
      let filteredActions: any[] = [];
      this.activeActions.forEach((a) => {
        if (animationsToPause.includes(a._clip.name)) {
          a.stop();
        } else {
          filteredActions.push(a);
        }
      });
      this.activeActions = filteredActions;
    } else {
      if (this.activeActions) {
        // we stop all active actions and reset active actions to an empty list
        this.activeActions.forEach((a) => a.stop());
        this.activeActions = [];
      }
    }
  }

  public setWireframe(state: boolean) {
    console.log('set wireframe');
    if (this.model) {
      const wireframeMaterial = new MeshStandardMaterial({
        wireframe: true
      });
      const wireframeModel = this.model.clone(true);

      this.applyMaterial(wireframeModel, wireframeMaterial);
      this.renderNode.add(wireframeModel);
    }
  }
}

ModuleService.Register(ModelWidget.type, ModelWidget);
