import { ReactElement, useEffect, useState } from 'react'
import ShareButton from './ShareButton'

const modelRoot = (() => {
    switch(window.location.hostname) {
        case "dev.studio.provizxr.com":
            return `https://dev.embed.provizxr.com/model`;
        case "qa.studio.provizxr.com":
            return `https://qa.embed.provizxr.com/model`;
        case "localhost":
            return `https://localhost:3002/model`;
        default:
            return `https://embed.provizxr.com/model`;
    }
})();

export const getModelLink = (modelId: string) =>
    `${modelRoot}/${modelId}`;

interface Props {
    modelId: string;
    title: string;
}

export default function ModelShareButton(props: Props): ReactElement {
    const { modelId } = props;
    const [modelLink, setModelLink] = useState(getModelLink(modelId));
    const [embedHtml, setEmbedHtml] = useState(`<iframe \n    src="${modelRoot}/\n      ${modelId}" \n/>`)

    useEffect(() => {
        setModelLink(getModelLink(modelId));
        setEmbedHtml(`<iframe \n    src="${modelRoot}/\n      ${modelId}" \n/>`)
    }, [modelId]);

    return <ShareButton link={modelLink} embedHtml={embedHtml} entityType='Model' title={props.title} />;
}
