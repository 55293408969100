import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { ReactElement } from 'react'


const xIcon = getAbsoluteUrl('images/closex.svg');
interface Props {
  //Title can either be a simple string or an entire override to the styles inside the modalHeader
  title: string | ReactElement;
  close: () => void;
  children?: any;
  //Optional parameter used for any kind of state status message
  statusMessage?: any;
  //Optional parameter for adding additional information outside of the modal footer.
  footer?: ReactElement;
  classNames?: string;
}

function Modal(props: Props) {
  const { title, close, children, statusMessage, footer } = props
  
  return (
    <>
      <div className={`modal`}>
        <div className='modalHeader'>
          {typeof(title) === "string" ? <h1>{title}</h1> : title}
          {statusMessage && <h6>{statusMessage}</h6>}
          <div className='buttonContainer'>
            <img src={xIcon} alt='Close' onClick={close} />
          </div>
        </div>
        <div className='modalBody'>
          { children }
        </div>
      </div>
      <div className='modalOverlay' onClick={close}></div>
      {footer}
    </>
  )
}

export default Modal
