export class LoadingScreen {
  public static HideLoading() {
    const loadingEl = document.getElementById('loading-screen');
    if (loadingEl) {
      loadingEl.setAttribute('class', 'loading-screen loaded');
    }
    const loadingText = document.getElementById('proviz-loading-text');
    if (loadingText) {
      loadingText.innerText = '';
    }
  }

  public static ShowLoading(text?: string) {
    const loadingEl = document.getElementById('loading-screen');
    if (loadingEl) {
      loadingEl.setAttribute('class', 'loading-screen');
    }
    if (text) {
      const loadingText = document.getElementById('proviz-loading-text');
      if (loadingText) {
        loadingText.innerText = text;
      }
    }
  }
}
