import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Texture,
  TextureLoader,
} from 'three';
import { encodingOptions, ProVizScene } from '../../..';
import { APIFile, FileService, getMediaFilePath } from '@proviz/api-services';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { ProVizEventData } from '../../../ProVizEventData';
import { Vector3 } from '../../../types/vector3';
import { MultiLangOption } from '../../..';
import { ImageWidgetDefinition } from './imageWidgetDefinition';

export class ImageWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static instanceCount = 0;
  private static textureLoader?: TextureLoader;
  private static geometry?: PlaneGeometry;
  private spriteNode?: Object3D;
  private material?: MeshBasicMaterial;

  // Data
  public imageSources: MultiLangOption = {};
  public fileIds: MultiLangOption = {};
  public overrideFileIdSources: boolean = false;
  public clickable: boolean = false;
  public transparent: boolean = false;
  public opacity: number = 1.0;
  public textureEncoding: string = 'sRGB';
  public version = 3;
  public renderOrder?: number = undefined;
  public renderOrderOverride?: number = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);

    this.widgetName = ImageWidgetDefinition.type || ImageWidgetDefinition.label || 'Image';
    ImageWidget.instanceCount++;
    this.label = 'Image ' + ImageWidget.instanceCount;
    this.selectable = true;

    // Properties

    this.setup(ImageWidgetDefinition);

    this.attachToProperty('opacity', {
      set: (data: number) => {
        this.opacity = data;
        if (this.material) {
          this.material.opacity = this.opacity;
        }
      },
      options: () => {
        return [
          { key: 0, label: '0' },
          { key: 1, label: '1' },
        ];
      },
    });

    this.attachToProperty('fileIds', {
      set: (data: any) => {
        console.log('file ids', data);
        this.fileIds = data;
        this.setupImage();
      },
    });
    this.attachToProperty('overrideFileIdSources', {
      set: (data: any) => {
        this.overrideFileIdSources = data;
        this.setupImage();
      },
      options: () => {
        return [
          { key: 'Override', label: 'Override' },
          { key: 'Off', label: 'Off' },
        ];
      },
    });

    this.attachToProperty('renderOrder', {
      set: (data: any) => {
        this.renderOrder = data;
        this.renderOrderOverride = data;
        this.renderNode.renderOrder = data;
      },
      get: () => {
        return (
          this.renderOrderOverride ||
          this.getPropertyValue('renderOrder') ||
          this.renderNode.renderOrder
        );
      },
    });

    this.attachToProperty('textureEncoding', {
      options: () => {
        return Object.keys(encodingOptions).map((x) => {
          return {
            key: x,
            label: x,
          };
        });
      },
    });

    // Services

    this.addEventListener('service-setscale', (event: ProVizEventData) => {
      if (event.dataType === 'vector3') {
        const data = event.data as Vector3;
        this.setScale({ x: data.x, y: data.y, z: data.z });
      }
    });
    this.addEventListener('service-set-image', (event: ProVizEventData) => {
      if (event.dataType === 'string') {
        const data = event.data as string;
        if (data) {
          FileService.get(data).then((apiFile: APIFile) => {
            if (!ImageWidget.textureLoader) {
              ImageWidget.textureLoader = new TextureLoader();
            }
            const tex = ImageWidget.textureLoader.load(apiFile.location, () => {
              if (!this.spriteNode) {
                this.spriteNode = new Mesh(ImageWidget.geometry, this.material);
              }
              this.spriteNode.scale.set(tex.image.width / 100.0, tex.image.height / 100.0, 1);
            });
            tex.encoding = encodingOptions[this.textureEncoding] ?? tex.encoding;
            if (!this.material) {
              this.material = this.createMaterial(tex);
            } else {
              this.material.map = tex;
            }
            this.material.needsUpdate = true;
          });
        } else {
          console.warn('no data', event);
        }
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return result;
  }

  public async setupImage() {
    if (this.spriteNode) {
      this.renderNode.remove(this.spriteNode);
    }
    const imgSrc = await this.getImageSource();
    if (!imgSrc) {
      return;
    }

    if (!ImageWidget.textureLoader) {
      ImageWidget.textureLoader = new TextureLoader();
    }
    const tex = ImageWidget.textureLoader.load(imgSrc, () => {
      if (!this.spriteNode) {
        this.spriteNode = new Mesh(ImageWidget.geometry, this.material);
        this.setShadowed();
      }
      this.spriteNode.scale.set(tex.image.width / 100.0, tex.image.height / 100.0, 1);
      tex.encoding = encodingOptions[this.textureEncoding] ?? tex.encoding;
    });

    this.material = this.createMaterial(tex);

    this.scene.addEventListener('language-change', async () => {
      const imgSrc = await this.getImageSource();
      if (!imgSrc) {
        return;
      }
      if (!this.material) {
        console.error('Material should exist', this.label);
        return;
      }
      if (!ImageWidget.textureLoader) {
        ImageWidget.textureLoader = new TextureLoader();
      }
      const tex = ImageWidget.textureLoader.load(imgSrc, () => {
        if (!this.spriteNode) {
          this.spriteNode = new Mesh(ImageWidget.geometry, this.material);
          this.setShadowed();
        }
        this.spriteNode.scale.set(tex.image.width / 100.0, tex.image.height / 100.0, 1);
        tex.encoding = encodingOptions[this.textureEncoding] ?? tex.encoding;
      });
      this.material.map = tex;
      this.material.needsUpdate = true;
    });

    this.renderNode.renderOrder = 2;
    if (!ImageWidget.geometry) {
      ImageWidget.geometry = new PlaneGeometry(1, 1, 2, 2);
    }
    this.spriteNode = new Mesh(ImageWidget.geometry, this.material);
    this.spriteNode.renderOrder = 3;

    if (this.renderOrderOverride) {
      this.renderNode.renderOrder = this.renderOrderOverride;
      this.spriteNode.renderOrder = this.renderOrderOverride + 1;
    }

    this.renderNode.add(this.spriteNode);
    this.setShadowed();
  }

  private createMaterial(tex: Texture) {
    const transparent = this.opacity < 1 || this.transparent || false;
    return new MeshBasicMaterial({
      map: tex,
      side: DoubleSide,
      transparent: transparent,
      opacity: this.opacity ?? 1.0,
    });
  }

  private async getImageSource() {
    if (this.overrideFileIdSources === true) {
      const srcName =
        this.imageSources[this.scene.selectedLanguage] ??
        this.imageSources[this.scene.defaultLanguage] ??
        '';
      if (!srcName) {
        console.error('Could not find image source', this.imageSources);
      }
      const imgSrc = getMediaFilePath(srcName);

      return imgSrc;
    } else {
      const fileId =
        this.fileIds[this.scene.selectedLanguage] ?? this.fileIds[this.scene.defaultLanguage] ?? '';
      if (!fileId) {
        return undefined;
      }
      const file = await FileService.get(fileId, { abortController: this.scene.abortController });
      return file.location;
    }
  }

  dispose() {
    super.dispose();
    this.material?.dispose();
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }

    switch (data.version) {
      case 1:
        if (data.imageSources) {
          // If data is encoded in a comma and colon seperated string
          // representation of a dictionary we parse it out
          // and reset the data's imageSources field to the corresponding
          // json object
          const imageSources = {};
          data.imageSources.split(',').forEach((t: string) => {
            const parts = t.split(':');
            if (parts[0] === '') {
              console.warn('Not migrating', parts);
              return;
            }
            imageSources[parts[0]] = decodeURIComponent(parts[1]);
          });
          data.imageSources = imageSources;
        }
        if (data.imageSrc) {
          // If a single source is on the image we create
          // a dict with assumed default english
          data.imageSources = { en: data.imageSrc };
          data.imageSrc = undefined;
        }
      case 2:
        data.overrideFileIdSources = true;
        data.fileIds = {};
    }
    return data;
  }

  public async init() {
    if (!(await super.init())) {
      return false;
    }

    await this.setupImage();

    return true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.clickable && this.spriteNode) {
      result.push(this.spriteNode);
    }
    return result;
  }
}
