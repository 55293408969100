import { ReactElement, useState } from 'react'
import MaterialSetName from './MaterialSetName';
import { APIMaterialSet, APIModelVariant, MaterialSetService, ModelVariantService } from "@proviz/api-services";
import { getAbsoluteUrl, } from '@proviz/proviz-sdk';
import { TabbedUpload } from './TabbedUpload';
import InfoButton from '../../common/infobutton/InfoButton';
import { ManagerStore, userStore } from '../../../store';

const blueCheck = getAbsoluteUrl('images/BlueCheckmark.svg');
const processingIcon = getAbsoluteUrl('images/Processing.svg');
const failedIcon = getAbsoluteUrl('images/Error.svg');

const sanitizeName = (n: string) =>
    n.toLowerCase().split('.')[0];

type UploadState = 'none' | 'uploading' | 'uploaded' | 'failed';

const uploadIcon = (state: UploadState) => {
    switch (state) {
        case 'uploaded':
            return blueCheck;
        case 'uploading':
            return processingIcon;
        case 'failed':
            return failedIcon;
        case 'none':
            return '';
    }
}

interface Props {
    variant: APIModelVariant;
    updateName: (name: string) => void;
    uploadMtl: (event: any) => Promise<void>;
    setMaterialSet: (matSet: APIMaterialSet) => void;
}

export default function VariantDetails(props: Props): ReactElement {
    const { variant, setMaterialSet, updateName, uploadMtl } = props;

    const [textureFiles, setTextureFiles] = useState<any[]>([]);
    const [uploadState, setUploadState] = useState<UploadState>('none')
    const [flipYValue, setFlipYValue] = useState(variant.materialSet.flipY);
    const [disableConversion, setDisableConversion] = useState(variant.disableConversion);
    const user = userStore( s => s.user)
    // const [converting, setConverting] = useState(false);
    const  manager = ManagerStore(s=>s.ModelViewManager);

    function handleTextureUpload(files: File[]) {   
        const fList = [];
        for (let i = 0; i < files.length; i++) {
            fList.push(files[i])
        }
        console.log('Texture Files uploading', files);
        setTextureFiles(fList);
        batchUpload(files);
    }

    async function batchUpload(files: File[]) {
        setUploadState('uploading')
        try {
            let matSet = await MaterialSetService.uploadTextureBatch(variant.materialSetId!, files);
            variant.materialSet = matSet;
            setMaterialSet(matSet);
            setUploadState('uploaded')
        } catch (e) {
            alert("Texture upload failed");
            setUploadState('failed')
            console.error(e);
        }
    }

    async function flipYChange(value: boolean) {
        try {
            setFlipYValue(value);
            variant.materialSet.flipY = value;
            await MaterialSetService.setFlipY(variant.materialSet.id, value);
            await manager.refreshMaterials();
        } catch {
            alert("We're having trouble communicating with our servers right now, sorry.");
        }
    }

    async function toggleDisableConversion() {
        try {
            const val = !disableConversion;
            setDisableConversion(val);
            await ModelVariantService.disableConversion(variant.id, val);
        } catch (e) {
            alert("We're having trouble communicating with our servers right now, sorry.");
        }
    }

    // async function triggerConversion() {
    //     try {
    //         setConverting(true);
    //         await ModelVariantService.convertModelVariant(variant.id);
    //         setConverting(false);
    //     } catch {
    //         setConverting(false);
    //         alert("We're having trouble communicating with our servers right now, sorry.")
    //     }
    // }

    const mtlFile = variant.materialSet.file;

    const imageUploadValidator = (file: File) => {
        let cleanName = sanitizeName(file.name);
        for (const mat of variant.materialSet.materials) {
            if (sanitizeName(mat.name ?? '') === cleanName) return null;
        }
        return { code: "No material match", message: 'File name does not match a material.' };
    }

    const mtlValidator = (file: File) => {
        const split = file.name.split('.');
        return split[split.length - 1] === 'mtl' ? null :
            { code: "No material match", message: 'Not an MTL File' }
    }

    return <div className='variantInfo'>
        <p>Name:</p>
        <MaterialSetName modelVariant={variant}
            updateName={updateName} />
        <div className="spacedContainer">
            <div className='flex'>
                <input type="checkbox" checked={flipYValue} onChange={() => flipYChange(!flipYValue)} />
                <p className='normalHeight'>Flip Y Coordinates</p>
            </div>
            {user?.isSuperAdmin && <div className='flex'>
                <input type="checkbox" checked={disableConversion} onChange={toggleDisableConversion} />
                <p className='normalHeight'>Disable Conversion</p>
            </div>}
            <InfoButton infoText={`Some software to produce models  interprets
             thedirection of textures in the opposite way that we do.
              If your texture seems to be displaying incorrectly try 
              toggling this checkmark.`} />
        </div>
        <p>Mtl File: {mtlFile ? mtlFile.displayName : 'none'} </p>

        <TabbedUpload
            tabs={[{
                title: "MTL Upload",
                onUpload: uploadMtl,
                validator: mtlValidator,
                accept: '.mtl',
                uploadText: 'Upload an MTL file',
                reset: () => setTextureFiles([]),
                postUploadText: "MTL uploaded"
            },
            {
                title: "Image upload",
                onUpload: handleTextureUpload,
                validator: imageUploadValidator,
                accept: 'image/*',
                uploadText: 'Upload Textures',
                reset: () => setTextureFiles([]),
                postUploadText: 'Upload More Textures'
            }]}
        />
        {textureFiles.length > 0 && <div className='uploadResult'>
                <h6>Uploaded Files</h6>
                <ul className='uploadFileList'>
                    {textureFiles.map((t) => <li key={t.name}>
                        <img className='checkmark' src={uploadIcon(uploadState)} alt='' />
                        <p>{t.name}</p>
                    </li>)}
                </ul>
            </div>}
    </div>
}
