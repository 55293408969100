import { useState } from "react";
import styled from "styled-components";

const AnalyticBlock = styled.div`
    width: 100%;
    padding: 2rem;
    text-align: center;
    font-size: 1.5rem;
    background: #fff;
    box-shadow: 15px 15px 25px rgb(100 100 100 / 10%);
    box-sizing: border-box;
    border-radius: 3px;
    .analytic-content {
        padding: 1rem;
        .header {
            display: flex;
            font-size: 1rem;
            text-align: left;
            margin-bottom: 1.5rem;
            div {
                flex: 1;
            }
            .range-selector {
                text-align: right;
                color: #a5a5a5;
                position: relative;
                cursor: pointer;
            }
            .range-select {
                position: absolute;
                top: 1.5rem;
                left: 1rem;
                background: white;
                padding: 1rem;
                box-shadow: 15px 15px 25px rgb(100 100 100 / 10%);
                z-index: 2000;
                white-space: nowrap;
                div:hover {
                    color: #46538b;
                    
                }
            }
        }
        .chart-container {
            height: 15rem;
            font-size: 1rem;
        }
    }
`;

interface Props {
    title: string;
    rangeChange: (start: Date, end?: Date) => void,
    children?: any;
}

export default function AnalyticCard(props: Props) {
    const [ rangeOpen, setRangeOpen ] = useState(false);
    const [ range, setRange ] = useState('This Week');

    return <AnalyticBlock>
        <div className='analytic-content'>
            <div className='header'>
                <div>{props.title}</div>
                <div className='range-selector'>
                    <span onClick={() => setRangeOpen(!rangeOpen)}>{range} ▼</span>
                    {rangeOpen && <div className="range-select">
                        <div onClick={() => {
                            setRangeOpen(false);
                            setRange('Today');
                            const dt = new Date();
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            dt.setMilliseconds(0);
                            props.rangeChange(dt);
                        }}>Today</div>
                        <div onClick={() => {
                            setRangeOpen(false);
                            setRange('Yesterday');
                            const dt = new Date();
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            dt.setMilliseconds(0);
                            dt.setDate(dt.getDate()-1)
                            const end = new Date(dt);
                            end.setDate(end.getDate() + 1);
                            props.rangeChange(dt, end);
                        }}>Yesterday</div>
                        <div onClick={() => {
                            setRangeOpen(false);
                            setRange('This Week');
                            const dt = new Date();
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            dt.setMilliseconds(0);
                            dt.setDate(dt.getDate()-dt.getDay())
                            props.rangeChange(dt);
                        }}>This Week</div>
                        <div onClick={() => {
                            setRangeOpen(false);
                            setRange('Last Week');
                            const dt = new Date();
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            dt.setMilliseconds(0);
                            dt.setDate(dt.getDate()-dt.getDay()-7);
                            const end = new Date(dt);
                            end.setDate(end.getDate() + 7);
                            props.rangeChange(dt, end);
                        }}>Last Week</div>
                        <div onClick={() => {
                            setRangeOpen(false);
                            setRange('Last Month');
                            const dt = new Date();
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            dt.setMilliseconds(0);
                            dt.setMonth(dt.getMonth()-1);
                            const end = new Date(dt);
                            end.setMonth(end.getMonth() + 1);
                            props.rangeChange(dt, end);
                        }}>Last Month</div>
                        <div onClick={() => {
                            setRangeOpen(false);
                            setRange('Last Quarter');
                            const dt = new Date();
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            dt.setMilliseconds(0);
                            dt.setMonth(dt.getMonth()-3);
                            props.rangeChange(dt);
                        }}>Last Quarter</div>
                        <div onClick={() => {
                            setRangeOpen(false);
                            setRange('Last Year');
                            const dt = new Date();
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            dt.setMilliseconds(0);
                            dt.setFullYear(dt.getFullYear()-1);
                            props.rangeChange(dt);
                        }}>Last Year</div>
                    </div>}
                </div>
            </div>
            <div className='chart-container'>
                {props.children}
            </div>

        </div>
    </AnalyticBlock>
}