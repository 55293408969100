import { APICompanyLicense, APILicense, CompanyLicenseService } from "@proviz/api-services";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { userStore } from "../../store";
import Button from "../common/button/Button";
import PageHeader from "../home/pageHeader";

const LicenseFormStyle = styled.div`
    div > div {
        margin: 0.5rem;
    }
    input, select {
        padding: 0.5rem 0.5rem;
        width: 100%;
        box-sizing: border-box;
        border: 0;
        border-radius: 0.25rem;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.125);
    }
`;

export default function LicensesView() {
    const history = useHistory();
    const [licenses, setLicenses] = useState<APICompanyLicense[]>([]);
    const user = userStore((s) => s.user);
    const [ licenseName, setLicenseName ] = useState<string>('');

    const [available, setAvailable] = useState<APILicense[]>([]);
    const [ selectedLicese, setSelectedLicense ] = useState<string | undefined>(undefined);

    useEffect(() => {
        CompanyLicenseService.all().then(setLicenses);

        if (user?.isSuperAdmin) {
            CompanyLicenseService.available().then(setAvailable);
        }
    }, []);

    const createLicense = async () => {
        const license = await CompanyLicenseService.create(licenseName);
        setLicenses([...licenses, license]);
    }

    const addLicense = async () => {
        if (selectedLicese) {
            const license = await CompanyLicenseService.add(selectedLicese);
            setLicenses([...licenses, license]);
        }
    }

    return <div className="homeRightView">
        <PageHeader />
        <div className='homeContent'>
            <div className='homeContentContainer'>
                <div className='paddedContainer'>

                    {user?.isSuperAdmin && <LicenseFormStyle style={{display: 'flex'}}>
                        <div style={{flex: '1'}}>
                            <div>
                            <h3>Create Custom License</h3>
                                <input type="text" placeholder="License Name" value={licenseName} onChange={(e) => setLicenseName(e.target.value)} />
                                <br />
                                <br />
                                <Button fullWidth onClick={() => createLicense()}>Create License</Button>
                            </div>
                        </div>
                        <div style={{flex: '1'}}>
                            <div>
                                <h3>Add Global License</h3>
                                <select onChange={(e) => setSelectedLicense(e.target.value)}>
                                    {available.map((license) => <option key={license.id} value={license.id}>{license.name}</option>)}
                                </select>
                                <br />
                                <br />
                                <Button fullWidth onClick={() => addLicense()}>Add License</Button>
                            </div>
                        </div>
                    </LicenseFormStyle>}

                    <h3>Organization Licenses</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>License</th>
                                <th>Expires</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {licenses.map((license) => <tr key={license.id}>
                                <td>{license.license.name}</td>
                                <td>{license.expires?.toString()}</td>
                                <td><Button fullWidth onClick={() => {
                                    history.push(`/home/settings/licenses/${license.id}`);
                                }}>Select</Button></td>
                                <td><Button fullWidth onClick={() => {
                                    CompanyLicenseService.delete(license.id).then(() => {
                                        setLicenses(licenses.filter((l) => l.id !== license.id));
                                    });
                                }}>Remove</Button></td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
}