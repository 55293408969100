import React, { ReactElement, useEffect, useState } from 'react'
import SetWidgetProperty from '../../../commands/SetWidgetProperty';
import { SettableWidgetProperty } from './SettableWidgetProperty'
import { APIModel, ModelService, initAbortController, isAbortError } from '@proviz/api-services';
import { ManagerStore } from '../../../store';
import { ResourceManagerStore } from '../../../store/ResourceStore';

interface Props {
    property: SettableWidgetProperty;
    header: ReactElement;
    forceUpdate: () => void;
}

export const ModelSelector = (props: Props) => {
    const { header, property } = props;
    const sceneManager = ManagerStore( s => s.SceneManager )
    const modelId = property.get();
    const openFileSelect = ResourceManagerStore(s => s.openFileSelect) ?? { openFileSelect: undefined };
    const selectionCallback = (newId: string | null) => {
            sceneManager.execute(new SetWidgetProperty(property, modelId, newId, sceneManager));
            props.forceUpdate();
    }
    const [thumbnailURL, setThumbnailURL] = useState<string | undefined>(undefined);
    const [modelName, setModelName] = useState('');

    useEffect(() => {
        const abortController = initAbortController();
        
        if (modelId) {
            ModelService.get(modelId, { abortController }).then((model: APIModel) => {
                setThumbnailURL(model.thumbnail);
                setModelName(model.name || model.id);
            }).catch(e => {
                if (isAbortError(e)) return; // if the query was aborted, do nothing
                console.error(e);
            });
        }

        return () => {
            abortController.abort()
        };
    }, [modelId]);

    const handleResourceDrop = (e: React.DragEvent<HTMLElement>) => {
        const resourceType = e.dataTransfer.getData('resource/type');
        if (resourceType === 'Model') {
            sceneManager.execute(new SetWidgetProperty(property, modelId, e.dataTransfer.getData('resource/id'), sceneManager));
            props.forceUpdate();
        }
    }

    return <div key={`model-${property.name}-${modelId}`} onDragEnter={(ev) => ev.preventDefault()} onDragOver={(ev) => ev.preventDefault()} onDrop={handleResourceDrop}>
        {header}
        <div><span title={modelId}>{modelName || modelId}</span></div>
        {thumbnailURL ?
            <img alt="model preview" onDrop={(ev) => console.log(ev)} style={{ width: '100%', cursor: 'pointer' }} src={thumbnailURL} onClick={() => {
                openFileSelect && openFileSelect('Model', false, selectionCallback)
            }} /> :
            <div>
                <button className='button' onClick={() => {
                    openFileSelect && openFileSelect('Model', false, selectionCallback)
                }}>Change</button>
            </div>}
    </div>;
}