import { SceneService } from '@proviz/api-services';
import { BaseWidget, GroupWidget } from '../../';
import BasePositionableWidget from '../../basePositionableWidget';
import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class SceneWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'scene';

  public loadOnInit: boolean = true;
  public sceneId: string = '';

  public sceneLoaded: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = SceneWidget.type;
    this.widgetName = 'Scene';
    this.label = 'Scene';
    this.usage = '3D';
    this.category = 'Core';
    this.events.push({
      label: 'Loaded Scene',
      name: 'loaded-scene',
    });
    this.addService('Load Scene', 'load-scene', '<b>Loads the scene</b>', () => {
      this.loadScene();
    });
  }

  public async init(): Promise<boolean> {
    await super.init();
    if (this.loadOnInit || this.scene.sceneMode === SceneMode.Editor) {
      await this.loadScene();
    }
    return this.sceneLoaded;
  }

  private async loadScene() {
    if (!this.sceneLoaded) {
      let parent = this.parent;
      while (parent) {
        if (parent.widgetType === SceneWidget.type && parent['sceneId'] === this.sceneId) {
          console.warn('Looping scene');
          return;
        }
        parent = parent.parent;
      }
      let apiSceneData = await SceneService.get(this.sceneId);
      let sceneData = JSON.parse(apiSceneData.data || '{}');
      if (sceneData.widgets) {
        for (const widget of sceneData.widgets) {
          try {
            const node = await this.deserializeWidget(widget, this);
            this.addChild(node);
          } catch (e) {
            console.error(`Could not load widget ${widget.data} ${e}`);
          }
        }
      }
      for (const widget of this.children) {
        await widget.init();
      }
      this.sceneLoaded = true;
      if (this.scene.sceneMode === SceneMode.Play) this.triggerProVizEvent('scene-loaded', 'none');
    }
  }

  public serialize() {
    let data = super.serialize();
    data.children = [];
    data.sceneId = this.sceneId;
    data.loadOnInit = this.loadOnInit;
    return data;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.children = [];
    this.sceneId = data.sceneId ?? this.sceneId;
    this.loadOnInit = data.loadOnInit ?? this.loadOnInit;
  }

  public getProperties(): BaseWidgetProperty[] {
    const res = super.getProperties();
    return [
      ...res,
      this.createProperty('sceneId', 'scene', 'Core', 'scene', 'string', true, undefined, (data) => {
        this.sceneId = data;
        this.sceneLoaded = false;
        this.loadScene();
      }),
      this.createProperty('loadOnInit', 'Load on init', 'Core', 'bool', 'boolean', true),
    ];
  }

  private async deserializeWidget(data: any, parent?: BaseWidget) {
    const WidgetType = ModuleService.Get(data.type);
    if (!WidgetType) {
      console.error(
        'Widget type not found',
        WidgetType,
        data.type,
        data.label,
        data.id,
        data.widgetName,
      );
    }
    // If we cant find the widget we add a group so that any children can be added to the scene
    const widget = WidgetType
      ? new WidgetType(this.scene, parent)
      : new GroupWidget(this.scene, parent);
    data = await widget.migrate(data);
    widget.deserialize(data);
    widget.children = [];
    for (const child of data.children) {
      const childWidget = await this.deserializeWidget(child, widget);
      widget.addChild(childWidget);
    }
    return widget;
  }
}

ModuleService.Register(SceneWidget.type, SceneWidget);
