import { ProVizScene, SceneMode } from '../../..';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { Vector3 } from 'three';
import { BillboardWidgetDefinition } from './billboardWidgetDefinition';

export class BillboardWidget extends BasePositionableWidget implements IBaseWidgetType {
  // Data
  public active: boolean = true;
  public yOnly: boolean = true;
  public invert: boolean = false;

  private worldPos: Vector3 = new Vector3();

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.setup(BillboardWidgetDefinition);
    this.widgetName = 'Billboard';
    this.label = 'Billboard';

    this.category = 'Core';

    this.addEventListener('service-activate', (event) => {
      this.active = true;
      this.triggerProVizEvent('activated', 'none');
    });
    this.addEventListener('service-deactivate', (event) => {
      this.active = false;
      this.triggerProVizEvent('deactivated', 'none');
    });
    this.addEventListener('service-toggle', (event) => {
      if (this.active) {
        this.active = false;
        this.triggerProVizEvent('deactivated', 'none');
      } else {
        this.active = true;
        this.triggerProVizEvent('activated', 'none');
      }
    });
  }

  public async init() {
    if (!(await super.init())) {
      return false;
    }

    this.selectable = true;

    return true;
  }

  public update(delta: number) {
    super.update(delta);
    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }

    if (!this.active) {
      return;
    }

    if (this.yOnly) {
      this.renderNode.getWorldPosition(this.worldPos);
      this.renderNode.lookAt(
        this.scene.camera.position.x,
        this.worldPos.y,
        this.scene.camera.position.z,
      );
    } else {
      this.renderNode.lookAt(
        this.scene.camera.position.x,
        this.scene.camera.position.y,
        this.scene.camera.position.z,
      );
    }

    if (this.invert) {
      this.renderNode.rotateY(Math.PI);
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    const rotindx = result.findIndex((prop) => prop.name === 'rotation');
    const scaleindx = result.findIndex((prop) => prop.name === 'scale');
    const transfindx = result.findIndex((prop) => prop.name === 'transformations');
    if (rotindx > -1) {
      result[rotindx].editable = false;
    }
    if (scaleindx > -1) {
      result[scaleindx].editable = false;
    }
    if (transfindx > -1) {
      result[transfindx].editable = false;
    }
    return [...result];
  }

  dispose() {
    super.dispose();
  }
}
