import { ReactElement } from "react";
import PageHeader from "../pageHeader";
import styled from "styled-components";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { SchemasView } from "./SchemasView";
import { ClientDatabasesView } from "./ClientDatabasesView";
import TargetsView from "./TargetsView";
import FileView from "../FileView";
import { SchemasCreateView } from "./SchemasCreateView";
import { userStore } from "../../../store";
import { PermissionEntity, PermissionType } from "@proviz/api-services";

const SubNavMenu = styled.div`
  background: #fff;
  display: inline-block;
  height: 100%;

  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 10rem;
  border-right: 1px solid #ecf0f8;
  .inner {
    .viewSelector {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    ul li {
      list-style: none;
      cursor: pointer;
      &:hover {
        background: #ecf0f8;
      }
      a {
        text-decoration: none;
        color: #313e64;
        padding: 1rem 3rem;
        display: inline-block;
      }
      &.active {
        background: #ecf0f8;
      }
    }
  }
`;

const SubContentContainer = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 100%:
    div {
        flex: 1;
    }
    .sub-content {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .homeContent {
        left: 0;
        right: 0;
    }
`;

export default function ResourcesView(): ReactElement {
  const history = useHistory();
  let { path } = useRouteMatch();
  const hasPermission = userStore((s) => s.hasPermission);

  return (
    <div className="homeRightView">
      <SubContentContainer>
        <SubNavMenu>
          <div className="inner">
            <ul className="viewSelector">
              <li
                className={
                  history.location.pathname === "/home/resources"
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources">All Files</NavLink>
              </li>
              {hasPermission(PermissionEntity.Target, PermissionType.View) && (
                <li
                  className={
                    history.location.pathname.startsWith(
                      "/home/resources/targets"
                    )
                      ? "active"
                      : ""
                  }
                >
                  <NavLink to="/home/resources/targets">Targets</NavLink>
                </li>
              )}
              <li
                className={
                  history.location.pathname.startsWith(
                    "/home/resources/schemas"
                  )
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/schemas">Schemas</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith(
                    "/home/resources/databases"
                  )
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/databases">Databases</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith("/home/resources/Sprite")
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/Sprite">Sprites</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith("/home/resources/Model")
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/Model">Models</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith("/home/resources/Video")
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/Video">Videos</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith("/home/resources/Audio")
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/Audio">Audio</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith("/home/resources/Image")
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/Image">Images</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith(
                    "/home/resources/Texture"
                  )
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/Texture">Textures</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith("/home/resources/EnvMap")
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/EnvMap">Env Maps</NavLink>
              </li>
              <li
                className={
                  history.location.pathname.startsWith(
                    "/home/resources/Subtitle"
                  )
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/home/resources/Subtitle">Subtitles</NavLink>
              </li>
            </ul>
          </div>
        </SubNavMenu>
        <div className="sub-content">
          <div className="homeContent">
            <PageHeader />
            <div className="homeContentContainer">
              <Switch>
                <Route
                  exact
                  path={`${path}/databases`}
                  component={ClientDatabasesView}
                />
                <Route
                  exact
                  path={`${path}/schemas/create`}
                  component={SchemasCreateView}
                />
                <Route exact path={`${path}/schemas`} component={SchemasView} />
                {hasPermission(
                  PermissionEntity.Target,
                  PermissionType.View
                ) && (
                  <Route path={`${path}/targets/`} component={TargetsView} />
                )}
                <Route path={`${path}/:fileType`} component={FileView} />
                <Route path={`${path}`} component={FileView} />
              </Switch>
            </div>
          </div>
        </div>
      </SubContentContainer>
    </div>
  );
}
