import {
  BoxGeometry,
  CylinderGeometry,
  Mesh,
  MeshBasicMaterial,
  OctahedronGeometry,
} from "three";
import { GizmoPart } from "./GizmoBase";

export function TranslatePicker(): { [key: string]: GizmoPart[] } {
  const gizmoMaterial = new MeshBasicMaterial({
    depthTest: false,
    depthWrite: false,
    fog: false,
    toneMapped: false,
    transparent: true,
  });

  const matInvisible = gizmoMaterial.clone();
  matInvisible.opacity = 0.15;

  return {
    X: [
      new GizmoPart(
        new Mesh(new CylinderGeometry(0.2, 0, 0.6, 4), matInvisible),
        [0.3, 0, 0],
        [0, 0, -Math.PI / 2]
      ),
      new GizmoPart(
        new Mesh(new CylinderGeometry(0.2, 0, 0.6, 4), matInvisible),
        [-0.3, 0, 0],
        [0, 0, Math.PI / 2]
      ),
    ],
    Y: [
      new GizmoPart(
        new Mesh(new CylinderGeometry(0.2, 0, 0.6, 4), matInvisible),
        [0, 0.3, 0]
      ),
      new GizmoPart(
        new Mesh(new CylinderGeometry(0.2, 0, 0.6, 4), matInvisible),
        [0, -0.3, 0],
        [0, 0, Math.PI]
      ),
    ],
    Z: [
      new GizmoPart(
        new Mesh(new CylinderGeometry(0.2, 0, 0.6, 4), matInvisible),
        [0, 0, 0.3],
        [Math.PI / 2, 0, 0]
      ),
      new GizmoPart(
        new Mesh(new CylinderGeometry(0.2, 0, 0.6, 4), matInvisible),
        [0, 0, -0.3],
        [-Math.PI / 2, 0, 0]
      ),
    ],
    XYZ: [
      new GizmoPart(new Mesh(new OctahedronGeometry(0.2, 0), matInvisible)),
    ],
    XY: [
      new GizmoPart(
        new Mesh(new BoxGeometry(0.2, 0.2, 0.01), matInvisible),
        [0.15, 0.15, 0]
      ),
    ],
    YZ: [
      new GizmoPart(
        new Mesh(new BoxGeometry(0.2, 0.2, 0.01), matInvisible),
        [0, 0.15, 0.15],
        [0, Math.PI / 2, 0]
      ),
    ],
    XZ: [
      new GizmoPart(
        new Mesh(new BoxGeometry(0.2, 0.2, 0.01), matInvisible),
        [0.15, 0, 0.15],
        [-Math.PI / 2, 0, 0]
      ),
    ],
  };
}
