import { APICompany } from "./Company";
import { APIRole } from "./Role";
import { IHasId } from "./interfaces/IHasId";

export const DefaultRoles = {
  User: 0,
  Admin: 1,
};

export const ParseDefaultRole = {
  0: "User",
  1: "Admin",
};

const DefaultRoleIds = {
  "75218673-8290-4bad-afb9-7962b17c3c81": "User",
  "c10d6a39-e86a-4c19-b414-d19eda974023": "Admin",
};

export interface UserCompany {
  userId: string;
  companyId: string;
  company?: APICompany;
  roleId: string;
  role?: APIRole;
  default: boolean;
}

export function getRole(roleId: string) {
  return DefaultRoleIds[roleId];
}

export function getCompanyRole(user: APIUser, companyId: string) {
  const userCompany = user.companies?.find((c) => c.companyId === companyId);
  if (!userCompany) {
    // alert("TRSIG");
    return deleteCookieAndReloadDefault();
  }
  return userCompany.roleId;
  // const roleName = DefaultRoleIds[userCompany.roleId];
  // return roleName ? roleName : "Custom";
}

const deleteCookieAndReloadDefault = () => {
  document.cookie =
    "impersonateId" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  location.reload();
};

export interface APIUser extends IHasId {
  id: string;
  // currently this is auth0 and comes in the format auth0|<userid>
  // thirdPartyUserId: string;
  firstName: string;
  lastName: string;
  email: string;
  isSuperAdmin: boolean;
  companies: UserCompany[];
  created: string;
  updated: string;
  avatar: string;
}

export function getDefaultCompanyId(user: APIUser) {
  const company = user?.companies?.find((c) => c.default);
  return company?.companyId;
}
