import { useEffect, useState } from 'react';
import { IModelLog, IModelVariantLog, initAbortController, isAbortError, ModelService, ModelVariantService } from '@proviz/api-services';
import { userStore } from '../../../store';

type ILog = IModelLog | IModelVariantLog;

type Props = {
    id: string;
    entity: 'Model' | 'ModelVariant';
};

export default function LogList(props: Props) {
    const [logs, setLogs] = useState<ILog[]>([])
    const user = userStore( s => s.user)

    useEffect(() => {
        const abortController = initAbortController();
        if(!user?.isSuperAdmin) {
            return;
        }
        if(props.entity === 'Model') {
            ModelService.getLogs(props.id, { abortController })
            .then(setLogs)
            .catch((e) => {
                if (isAbortError(e)) return; // if the query was aborted, do nothing
                console.error(e);
                setLogs([{ message: "Error", modelId: "", created: "", id: "" }]);
            })
        } else {
            ModelVariantService.getLogs(props.id, { abortController})
            .then(setLogs)
            .catch((e) => {
                if (isAbortError(e)) return; // if the query was aborted, do nothing
                console.error(e);
                setLogs([{ message: "Error", modelId: "", created: "", id: "" }]);
            });
        }
      return () => abortController.abort();
    }, [props.entity, props.id, user?.isSuperAdmin])

    return user?.isSuperAdmin
        ? <div className='loglist'>
            {logs && logs.map((log, idx) =>
                <div className='fullWidthSpaced'  key={idx}>
                    <p>{new Date(log.created).toLocaleString()}</p>
                    <p>{log.message}</p>
                </div>)
            }
            {(!logs || logs.length === 0) &&
                <p>No Logs</p> 
            }
        </div>
        : <></>;
}
