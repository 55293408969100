import { ReactElement, useRef, useEffect, useState } from "react";
import SceneEditorBottomMenu from "./SceneEditorBottomMenu";
import { ResourceTypes } from "../../common/fileselect/useFileSelect";
import { useHandleAddResourceWidget } from "../handleAddResourceWidget";
import {
  APIModel,
  APIFile,
  APISprite,
  APITarget,
  APIFolder,
} from "@proviz/api-services";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import UploadModal from "../../common/upload/UploadModal";
import LoadingModal from "../../common/loadingmodal/LoadingModal";
import { WidgetIcons } from "../../../documentation/WidgetIcons";
import { ResourceCard } from "./ResourceCard";
import { ISceneRecord } from "../../home/ThumbnailCard";
import SearchBar from "../../common/search/SearchBar";
import ConditionalWrapper from "../../common/ConditionalWrapper";
import UploadButton from "../../common/button/UploadButton";
import Button from "../../common/button/Button";
import { useGetFileThumbnail } from "../../common/filethumbnail/useGetFileThumbnail";
import { ResourceManagerStore } from "../../../store/ResourceStore";
import FoldersList from "../../home/FoldersList";

const defaultThumbnail = getAbsoluteUrl("images/thumbnail.svg");

function fmtResourceName(r: string) {
  if (r === "EnvMap") {
    return "Environment";
  }
  if (r === "AreaTarget") {
    return "Area Target";
  }
  if (r === "ModelTarget") {
    return "Model Target";
  }
  return r;
}

type Props = {
  children?: any;
};

export default function ResourceMenu(props: Props): ReactElement {
  const handleAddResourceWidget = useHandleAddResourceWidget();
  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const [shouldDefault, setShouldDefault] = useState(true);
  const [displayMode, setDisplayMode] = useState(false);

  function scrollToNode() {
    setTimeout(() => {
      if (ref && ref.current) {
        ref.current.scrollIntoView();
      }
    }, 250);
  }

  const resourceType = ResourceManagerStore((s) => s.resourceType);
  const setResourceType = ResourceManagerStore((s) => s.setResourceType);
  const selectResourceCallback =
    ResourceManagerStore((s) => s.selectResourceCallback) || undefined;
  const loading = ResourceManagerStore((s) => s.loading) || false;
  // const noAssets = ResourceCard.length <= 0;

  // Focus the resource tab when someone is selecting the change button in the sidebar
  useEffect(() => {
    selectResourceCallback && scrollToNode();
  }, [selectResourceCallback]);

  useEffect(() => {
    if (shouldDefault) {
      setResourceType("Model");
      setShouldDefault(false);
    }
  }, [resourceType, setShouldDefault, shouldDefault]);

  const getThumbnail = useGetFileThumbnail();

  const page = ResourceManagerStore((s) => s.page);
  const totalPages = ResourceManagerStore((s) => s.totalPages);
  const searchParams = ResourceManagerStore((s) => s.searchParams);
  const setPage = ResourceManagerStore((s) => s.setPage);
  const setSearchParams = ResourceManagerStore((s) => s.setSearchParams);

  const uploadFile = ResourceManagerStore((s) => s.uploadFile);
  const setUploadFile = ResourceManagerStore((s) => s.setUploadFile);

  const waitingText = ResourceManagerStore((s) => s.waitingText);
  const setWaitingText = ResourceManagerStore((s) => s.setWaitingText);

  const setUpdate = ResourceManagerStore((s) => s.setUpdate);
  const error = ResourceManagerStore((s) => s.error);
  const fileNotTexture = ResourceManagerStore((s) => s.fileNotTexture);
  const setSelectResourceCallback = ResourceManagerStore(
    (s) => s.setSelectResourceCallback
  );
  const extensions = ResourceManagerStore((s) => s.extensions);
  const setTotalPages = ResourceManagerStore((s) => s.setTotalPages);
  const setCurrentFolderID = ResourceManagerStore((s) => s.setCurrentFolderID);
  const currentFolderId = ResourceManagerStore((s) => s.currentFolderId);
  // Assets
  const files = ResourceManagerStore((s) => s.files);
  const models = ResourceManagerStore((s) => s.models);
  const sprites = ResourceManagerStore((s) => s.sprites);
  const displayScenes = ResourceManagerStore((s) => s.displayScenes);
  const targets = ResourceManagerStore((s) => s.targets);

  const closeModal = () => setSelectResourceCallback(undefined);

  // only use internally
  const handleResourceSelectEvent = (id: string | null) => {
    closeModal();
    setPage(0);
    selectResourceCallback && selectResourceCallback(id);
  };

  let bodyContent;
  if (
    ![
      "Model",
      "Scene",
      "Image",
      "Sprite",
      "AreaTarget",
      "ModelTarget",
    ].includes(resourceType)
  ) {
    bodyContent = files.map((f: APIFile) => {
      return (
        <ResourceCard
          key={f.id}
          name={f.displayName || f.fileName || ""}
          image={f.thumbnail?.thumbnail || defaultThumbnail}
          lastUpdated={f.updated}
          id={f.id}
          onClick={() => {
            handleAddResourceWidget(
              selectResourceCallback,
              resourceType,
              f.id,
              f.displayName || f.fileName || "File"
            );
          }}
          resourceType={resourceType}
        />
      );
    });
  } else if (resourceType === "Model") {
    bodyContent = models.map((m: APIModel) => {
      return (
        <ResourceCard
          key={m.id}
          name={m.name || ""}
          image={m.thumbnail || defaultThumbnail}
          lastUpdated={m.updated}
          id={m.id}
          onClick={() =>
            handleAddResourceWidget(
              selectResourceCallback,
              resourceType,
              m.id,
              m.name || "Model"
            )
          }
          resourceType={resourceType}
        />
      );
    });
  } else if (resourceType === "Scene") {
    bodyContent = displayScenes.map((s: ISceneRecord) => {
      return (
        <ResourceCard
          key={s.id}
          name={s.name || ""}
          image={s.thumbnailFile ? s.thumbnailFile.location : defaultThumbnail}
          lastUpdated={s.updated}
          id={s.id}
          onClick={() =>
            handleAddResourceWidget(
              selectResourceCallback,
              resourceType,
              s.id,
              s.name
            )
          }
          resourceType={resourceType}
        />
      );
    });
  } else if (resourceType === "Image") {
    bodyContent = files.map((f: APIFile) => {
      return (
        <ResourceCard
          key={f.id}
          name={f.displayName || ""}
          image={getThumbnail(f)}
          lastUpdated={f.updated}
          id={f.id}
          onClick={() =>
            handleAddResourceWidget(
              selectResourceCallback,
              resourceType,
              f.id,
              f.displayName || f.fileName || "File"
            )
          }
          resourceType={resourceType}
        />
      );
    });
  } else if (resourceType === "Sprite") {
    bodyContent = sprites.map((s: APISprite) => {
      return (
        <ResourceCard
          key={s.id}
          name={s?.file?.displayName || s.name || ""}
          image={defaultThumbnail}
          lastUpdated={s.updated}
          id={s.id}
          onClick={() =>
            handleAddResourceWidget(
              selectResourceCallback,
              resourceType,
              s.id,
              s.name || "Sprite"
            )
          }
          resourceType={resourceType}
        />
      );
    });
  } else if (resourceType === "AreaTarget") {
    console.log({ targets });
    bodyContent = targets.map((t: APITarget) => {
      return (
        <ResourceCard
          key={t.id}
          name={t.name || ""}
          image={defaultThumbnail}
          lastUpdated={t.updated}
          id={t.id}
          onClick={() =>
            handleAddResourceWidget(
              selectResourceCallback,
              resourceType,
              t.id,
              t.targetType
            )
          }
          resourceType={resourceType}
          targetType={t.targetType}
        />
      );
    });
  } else if (resourceType === "ModelTarget") {
    bodyContent = targets.map((t: APITarget) => {
      return (
        <ResourceCard
          key={t.id}
          name={t.name || ""}
          image={defaultThumbnail}
          lastUpdated={t.updated}
          id={t.id}
          onClick={() =>
            handleAddResourceWidget(
              selectResourceCallback,
              resourceType,
              t.id,
              t.targetType
            )
          }
          resourceType={resourceType}
          targetType={t.targetType}
        />
      );
    });
  }

  const LoadService = ResourceManagerStore((s) => s.LoadService);

  useEffect(() => {
    LoadService();
  }, [
    LoadService,
    page,
    searchParams,
    resourceType,
    fileNotTexture,
    currentFolderId,
  ]);

  const pageChanged = (page: number) => {
    setPage(page);
  };

  return (
    <SceneEditorBottomMenu
      sidebar={
        <div className="categories">
          {ResourceTypes.sort().map((rt) => {
            return (
              <div
                className={`category ${resourceType === rt ? "selected" : ""} ${
                  selectResourceCallback ? "focused" : ""
                }`}
                key={rt}
                onClick={() => {
                  setResourceType(rt);
                  setSelectResourceCallback(undefined);
                  setTotalPages(0);
                  setPage(0);
                }}
                ref={resourceType === rt ? ref : null}
              >
                <img
                  className="category-icon"
                  src={WidgetIcons[rt.toLowerCase()] ?? defaultThumbnail}
                  alt=""
                />
                <h4>{fmtResourceName(rt)}</h4>
              </div>
            );
          })}
        </div>
      }
      content={
        <>
          {/* ConditionalWrapper is here to conditionally display the upload button.
                                        A switch statement is not used because the UploadModal has children.  */}
          <ConditionalWrapper
            condition={
              !(resourceType === "Scene") && !(resourceType === "Model")
            }
            wrapper={(children) => (
              <UploadModal
                open={uploadFile}
                setOpen={() => setUploadFile(true)}
                close={() => setUploadFile(false)}
                reload={() => setUpdate(true)}
                setWaitingText={setWaitingText}
                resourceType={resourceType}
                extensionsOverride={extensions}
                dragRef={dragRef}
              >
                {children}
              </UploadModal>
            )}
          >
            <>
              <div className="resourceContent" ref={dragRef}>
                <SearchBar
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  showPaging={true}
                  page={page}
                  totalPages={totalPages}
                  pageSet={pageChanged}
                  isFileSelect={false}
                  setDisplayMode={setDisplayMode}
                  showModeToggle={true}
                >
                  {!!selectResourceCallback && (
                    <Button onClick={() => handleResourceSelectEvent(null)}>
                      Set to None
                    </Button>
                  )}
                  <div className="divider" />
                  {!(resourceType === "Scene") &&
                    !(resourceType === "Model") && (
                      <>
                        <UploadButton onClick={() => setUploadFile(true)} />
                        <div className="divider" />
                      </>
                    )}
                  <div className="item">
                    {(!["Scene", "Texture", "Image", "Sprite"].includes(
                      resourceType
                    ) ||
                      (resourceType === "Texture" && !fileNotTexture) ||
                      resourceType === "Image") && (
                      <>
                        {waitingText.length > 0 && (
                          <LoadingModal
                            close={() => setWaitingText("")}
                            text={waitingText}
                          />
                        )}
                      </>
                    )}
                  </div>
                </SearchBar>
              </div>
              <div className="files">
                {loading && <div>Loading...</div>}
                {loading === true ||
                  bodyContent === undefined ||
                  (bodyContent.length === 0 && (
                    <div>There are no assets to show...</div>
                  ))}

                {!error && !displayMode && (
                  <div
                    key={resourceType}
                    className={`widgetSelectGrid ${
                      !(bodyContent === undefined || bodyContent.length === 0)
                        ? "widgetSelectBackground"
                        : ""
                    } ${selectResourceCallback ? "focused" : ""}`}
                  >
                    <FoldersList
                      folderType={resourceType}
                      onFolderChange={(folder: APIFolder | null) =>
                        setCurrentFolderID(folder?.id ?? null)
                      }
                    />
                    {bodyContent}
                  </div>
                )}
                {!error && displayMode && (
                  <div className="list-view">{bodyContent}</div>
                )}
              </div>
            </>
          </ConditionalWrapper>
        </>
      }
    />
  );
}
