import { useState } from "react";
import styled from "styled-components";

interface Props {
    value?: string;
    onChange: (val: string) => void;
    debounce?: number;
    className?: string;
    placeholder?: string;
}

const StyledInput = styled.input`
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 0;

    &.full {
        display: block;
        box-sizing: border-box;
        width: 100%;
    }
`;

export default function Input(props: Props) {
    const [ debounceHandler, setDebounceHandler ] = useState<NodeJS.Timeout | undefined>(undefined);

    const changeVal = (value: string) => {
        if (props.debounce) {
            // Clear out previous
            if (debounceHandler) {
                clearTimeout(debounceHandler);
            }
            // Set a debounce
            setDebounceHandler(setTimeout(() => {
                props.onChange(value);
            }, props.debounce));
        } else {
            props.onChange(value);
        }
    };

    return <StyledInput className={props.className} defaultValue={props.value} placeholder={props.placeholder} onChange={(e) => changeVal(e.target.value)} />
}