import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components';

// import TagSelector from './TagSelector';
import { AnalyticService } from '@proviz/api-services';
import Analytic from '@proviz/api-services/lib/types/Analytic';
import AnalyticsCard from '../analytics/AnalyticsCard';
import BarChartWidget from '../analytics/BarChartWidget';
import { APIAnalytic } from '@proviz/api-services/lib/models/Analytic';
import PageHeader from './pageHeader';
import PageContentHeader from './pageContentHeader';
import PaddedContainer from '../common/containers/paddedContainer';

const Container = styled.div`
    background: #f7f8fa;
`;

const AnalyticBlock = styled.div`
    width: 100%;
    padding: 2rem;
    text-align: center;
    font-size: 1.5rem;
    background: #fff;
    box-shadow: 15px 15px 25px rgb(100 100 100 / 10%);
    box-sizing: border-box;
    border-radius: 3px;

    font-size: 3rem;
    div {
        font-size: 1.5rem;
    }

    .analytic-content {
        padding: 1rem;
        .header {
            display: flex;
            font-size: 1rem;
            text-align: left;
            margin-bottom: 1.5rem;
            div {
                flex: 1;
            }
            .range-selector {
                text-align: right;
                color: #a5a5a5;
            }
        }
        .chart-container {
            height: 15rem;
            font-size: 1rem;
        }
    }
`;

const GridView = styled.div`
    margin-top: 2rem;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
`;
const GridViewSplitHorizontal = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: 1;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
`;
const GridViewSplitVertical = styled.div`
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: repeat(2, 2fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
`;

function AnlayticBarChart(props: {
    title: string,
    getData: (start: Date, end?: Date) => Promise<APIAnalytic[]>,
}) {

    const [ startDate, setStartDate ] = useState((() => { 
        const tmpStartDate = new Date();
        tmpStartDate.setDate(tmpStartDate.getDate()-tmpStartDate.getDay())
        return tmpStartDate;
    })());
    const [ endDate, setEndDate ] = useState<Date | undefined>(undefined);

    const [ data, setData ] = useState<{ key: string, name: string, amt: number }[]>([]);

    const dateToMidnight = (dt: Date) => {
        dt.setHours(0);
        dt.setMinutes(0);
        dt.setSeconds(0);
        dt.setMilliseconds(0);
        return dt;
    }
    const dateToKey = (dt: Date) => `${dt.getFullYear()}_${dt.getMonth()}_${dt.getDate()}`;
    const dateToName = (dt: Date) => `${dt.getMonth() + 1}/${dt.getDate()}`;

    useEffect(() => {
        props.getData(startDate, endDate).then((analyticData) => {
            const result: { key: string, name: string, amt: number }[] = [];
            for(let i = 0; i < analyticData.length; i++) {
                const dt = dateToMidnight(new Date(analyticData[i].created));
                const dtKey = dateToKey(dt);
                const existingInd = result.findIndex(x => x.key === dtKey);
                if (existingInd === -1) {
                    result.push({
                        key: dtKey,
                        name: dateToName(dt),
                        amt: 1
                    })
                } else {
                    result[existingInd].amt++;
                }
            }
            setData(result);
        });
    }, [startDate]);

    return <AnalyticsCard title={props.title} rangeChange={(start, end) => {
        setStartDate(start);
        setEndDate(end);
    }}>
        <BarChartWidget data={data} />
    </AnalyticsCard>
}

export default function AnalyticsView(): ReactElement {
    const [ sceneAnalyticsCount, setSceneAnalyticsCount ] = useState<{ [key: string]: number }>({
        open: 0,
        view: 0,
        create: 0,
        publish: 0
    });
    const [ userAnalyticsCount, setUserAnalyticsCount ] = useState<{ [key: string]: number }>({
        open: 0,
        view: 0,
        create: 0,
        publish: 0
    });
    const [ modelAnalyticsCount, setModelAnalyticsCount ] = useState<{ [key: string]: number }>({
        create: 0,
        upload: 0
    });
    const setup = async () => {
        {
            const open = await AnalyticService.sceneCount(Analytic.SceneOpen);
            const view = await AnalyticService.sceneCount(Analytic.SceneView);
            const create = await AnalyticService.sceneCount(Analytic.SceneCreate);
            const publish = await AnalyticService.sceneCount(Analytic.ScenePublish);
            setSceneAnalyticsCount({
                open,
                view,
                create,
                publish
            });
        }
        {
            const create = await AnalyticService.sceneCount(Analytic.UserCreate);
            const del = await AnalyticService.sceneCount(Analytic.UserDelete);
            const invite = await AnalyticService.sceneCount(Analytic.UserInvite);
            const login = await AnalyticService.sceneCount(Analytic.UserLogin);
            const logout = await AnalyticService.sceneCount(Analytic.UserLogout);
            const update = await AnalyticService.sceneCount(Analytic.UserUpdate);
            setUserAnalyticsCount({
                create,
                del,
                invite,
                login,
                logout,
                update
            });
        }
        {
            const create = await AnalyticService.modelCount(Analytic.ModelCreate);
            const upload = await AnalyticService.modelCount(Analytic.ModelUpload);
            setModelAnalyticsCount({
                create,
                upload
            });
        }
    }

    useEffect(() => {
        setup();
    }, []);

    return <div className="homeRightView">

        <PageHeader />
        
        <div className='homeContent'>
            <div className='homeContentContainer'>
                <Container>

                    <PaddedContainer>
                        <PageContentHeader title="Analytics" />
                    </PaddedContainer>
                    
                    <GridView>
                        <GridViewSplitHorizontal>
                            <AnlayticBarChart title='Scene Opens' getData={(start: Date, end?: Date) => {
                                return new Promise((resolve, reject) => {
                                    AnalyticService.scene(Analytic.SceneOpen, undefined, undefined, undefined, start, end).then(resolve);
                                });
                            }} />

                            <AnlayticBarChart title='Scene Views' getData={(start: Date, end?: Date) => {
                                return new Promise((resolve, reject) => {
                                    AnalyticService.scene(Analytic.SceneView, undefined, undefined, undefined, start, end).then(resolve);
                                });
                            }} />
                        </GridViewSplitHorizontal>

                        <GridViewSplitVertical>
                            <AnalyticBlock>
                                {sceneAnalyticsCount.create}
                                <div>Scenes Created</div>
                            </AnalyticBlock>
                            
                            <AnalyticBlock>
                                {sceneAnalyticsCount.publish}
                                <div>Scene Publishes</div>
                            </AnalyticBlock>
                        </GridViewSplitVertical>
                        
                        <GridViewSplitHorizontal>
                            <AnlayticBarChart title='Model Opens' getData={(start: Date, end?: Date) => {
                                return new Promise((resolve, reject) => {
                                    AnalyticService.model(Analytic.ModelOpen, undefined, undefined, start, end).then(resolve);
                                });
                            }} />

                            <AnlayticBarChart title='Model Views' getData={(start: Date, end?: Date) => {
                                return new Promise((resolve, reject) => {
                                    AnalyticService.model(Analytic.ModelView, undefined, undefined, start, end).then(resolve);
                                });
                            }} />
                        </GridViewSplitHorizontal>

                        <GridViewSplitVertical>
                            <AnalyticBlock>
                                {modelAnalyticsCount.create}
                                <div>Models Created</div>
                            </AnalyticBlock>
                            
                            <AnalyticBlock>
                                {modelAnalyticsCount.upload}
                                <div>Models Uploaded</div>
                            </AnalyticBlock>
                        </GridViewSplitVertical>


                        <GridViewSplitHorizontal>
                            <GridViewSplitVertical>
                                <AnalyticBlock>
                                    {userAnalyticsCount.create}
                                    <div>Users Created</div>
                                </AnalyticBlock>
                                
                                <AnalyticBlock>
                                    {userAnalyticsCount.del}
                                    <div>Users Deleted</div>
                                </AnalyticBlock>
                            </GridViewSplitVertical>
                            <GridViewSplitVertical>
                                <AnalyticBlock>
                                    {userAnalyticsCount.invite}
                                    <div>Users Invited</div>
                                </AnalyticBlock>
                                
                                <AnalyticBlock>
                                    {userAnalyticsCount.login}
                                    <div>User Logins</div>
                                </AnalyticBlock>
                            </GridViewSplitVertical>
                        </GridViewSplitHorizontal>

                        <GridViewSplitVertical>
                            <AnalyticBlock>
                                {userAnalyticsCount.logout}
                                <div>User Logouts</div>
                            </AnalyticBlock>
                            
                            <AnalyticBlock>
                                {userAnalyticsCount.update}
                                <div>User Updates</div>
                            </AnalyticBlock>
                        </GridViewSplitVertical>
                    </GridView>
                    
                </Container>
            </div>
        </div>
    </div>;
}