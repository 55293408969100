import { Auth0Context } from '@auth0/auth0-react';
import { ReactElement, useContext } from 'react'
import Button from '../common/button/Button';
import PageContentHeader from '../home/pageContentHeader';
import PageHeader from '../home/pageHeader';
import EditAccount from './EditAccount';

export default function AccountPage(): ReactElement {
    const { logout } = useContext(Auth0Context);
    return <div className="homeRightView">
        <PageHeader />
        <div className='homeContent'>
            <div className='homeContentContainer'>

                <PageContentHeader title="Account Settings" />

                <div className='paddedContainer'>
                    <EditAccount />
                    <br />
                    <Button type='danger' onClick={logout}>Logout</Button>
                </div>
            </div>
        </div>
    </div>
}
