import { ReactElement } from 'react';


type Props = {
    sidebar: ReactElement;
    content: ReactElement;
}

export default function SceneEditorBottomMenu(props: Props): ReactElement {
    return <>
         <div className="widgetSelector">
            <div className='leftColumn'>
                {props.sidebar}
            </div>
            <div className="widgetSelectGridContainer">
                {props.content}
            </div>
        </div>
    </>
}
