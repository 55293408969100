import {
  CircleGeometry,
  Mesh,
  Texture,
  TextureLoader,
  CubeTextureLoader,
  Vector3,
  ImageLoader,
  CubeTexture,
  Material,
} from 'three';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import SpriteResource from '../../../utils/SpriteResource';
import AnimatedSpriteMaterial from '../../../utils/AnimatedSpriteMaterial';
import { DefaultCameraRotation, LoadingScreen, ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { EquirectangularTexture, SkyboxTexture, isEquirectangular, isSkyboxTexture } from '.';
import PreloadQueue from '../../../utils/PreloadQueue';
import { TextBox2DWidget } from '../..';
import { EventDispatcher } from '../../../EventDispatcher';
import { timeout } from '../../../utils';
import { TextureService } from '@proviz/api-services';
import { ProVizEventData } from '../../../ProVizEventData';

const spriteResource: SpriteResource = new SpriteResource({
  id: 'panoramaDefaultSprite',
  imageSrc: '0dfa7682-97f6-47b9-829b-1ec02116828a.png',
  verticalTiles: 1,
  horizontalTiles: 50,
  framesPerSecond: 30,
  numberOfTiles: 50,
});

export class PanoramaWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'panorama';
  public static instanceCount = 0;
  static eventHandler: EventDispatcher = new EventDispatcher();
  private static imageLoader = new ImageLoader();
  private static textureLoader = new TextureLoader();
  private static cubeTextureLoader = new CubeTextureLoader();
  private static activePanoramaWidget: PanoramaWidget | undefined = undefined;
  public static active = () => PanoramaWidget.activePanoramaWidget !== undefined;

  // Data
  public spriteId?: string;
  public textureSource: SkyboxTexture | EquirectangularTexture = new SkyboxTexture();
  public loadedTexture?: Texture;
  public yOffset: number = 1.5;
  public canExit: boolean = false;
  /** Whether to apply the cameraRotationOnStart when this pano is entered. */
  public cameraRotationOnStartAlways: boolean = false;
  /** Camera rotation to set when the pano is entered if cameraRotationOnStart is true. */
  public cameraRotationOnStart: Vector3 = new Vector3();
  /** Euler representing the rotation of the skybox. Comes from matteerport
   * represents the angle of the camera when these imgs were captured */
  public cameraRotation: Vector3 = new Vector3();

  private marker?: Mesh<CircleGeometry, Material>;
  public active: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);

    this.widgetType = PanoramaWidget.type;
    this.widgetName = 'Panorama';
    if (!notInScene) {
      PanoramaWidget.instanceCount += 1;
    }
    this.label = 'Panorama ' + PanoramaWidget.instanceCount;
    this.category = 'Advanced';

    this.events.push(
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Entered',
        name: 'entered',
      },
      {
        label: 'Exited',
        name: 'exited',
      },
    );
    this.services.push(
      {
        label: 'Enter',
        name: 'enter',
        desc: '<b>Enter the 360 panorama</b><br/><i>Changes camera modes</i>',
      },
      {
        label: 'Exit',
        name: 'exit',
        desc: 'Return to normal camera mode and leave 360 panorama',
      },
    );
    this.addEventListener('clicked', () => {
      this.enterPanorama();
    });
    this.addEventListener('service-enter', () => {
      this.enterPanorama();
    });

    PanoramaWidget.eventHandler.addEventListener('preload', (e: ProVizEventData) => {
      if (!this.visible || this.renderNode.position.length() === 0) {
        // special case for a bunch of erroneous hotspots at 0,0,0
        return;
      }
      if (this.inRange && !this.loadedTexture) {
        PreloadQueue.enqueue(() => this.createTextures()).then((loadedTex) => {
          if (!this.loadedTexture) {
            this.loadedTexture = loadedTex;
          }
        });
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    const allProperties = [
      ...result,
      this.createProperty(
        'spriteId',
        'Sprite Source',
        'Core',
        'sprite', 
        'string',
        true,
        undefined,
        (data: any) => {
          this.spriteId = data;
          this.setupMarker();
        },
      ),
      this.createProperty(
        'textureId',
        '360 Image',
        'Visual',
        'texture-360',
        'string',
        true,
        () => {
          return this.textureSource || undefined;
        },
        (val: string | SkyboxTexture) => this.setTexture(val),
      ),
      this.createProperty(
        'yOffset',
        'Camera Y Offset',
        'Visual',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        "Set the y-value of the camera's postion when viewing this panorama (in meters).",
      ),
      this.createProperty(
        'canExit',
        'Show Exit Button',
        'Experimental',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'If true an exit button will be present while in panorama that will return user to dollhouse view. Currently the camera will stay in the same position.',
      ),
      this.createProperty(
        'cameraRotationOnStart',
        'Camera Rotation On Entering',
        'Visual',
        'vec3-camera-rot',
        'vector3',
        true,
        undefined,
        undefined,
        () => [{ key: DefaultCameraRotation, label: 'Default' }],
        'Angle that camera is moved to when entering this panorama.',
      ),
      this.createProperty(
        'cameraRotationOnStartAlways',
        'Use Start Rotation On Every Entry',
        'Visual',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'Whether to use the start amera rotation value only if this is the first panorama entered or every time this panorama is entered.',
      ),
      this.createProperty(
        'textureType',
        'Skybox or Equirectangular',
        'Visual',
        'bool',
        'boolean',
        true,
        () => this.textureSource && this.textureSource.isSkybox,
        (skyboxOn: boolean) => {
          this.setTextureType(skyboxOn);
        },
        () => ['Skybox', 'Equirectangular'].map(x => { return { key: x, label: x }}),
        'Toggle this value to switch between using a single equirectangular image or 6 images for a skybox to construct the panorama.',
      ),
      this.createProperty(
        'cameraRotation',
        'Skybox Rotation',
        'Visual',
        'vec3',
        'vector3',
        true,
        undefined,
        undefined,
        undefined,
        'Adjust this property to adjust how the skybox that the panorama is drawn to is rotated.',
      ),
      this.createProperty(
        'Enter',
        'Enter Panorama',
        'Core',
        'button',
        'none',
        true,
        () => (this.active ? 'Exit' : 'Enter'),
        async (_: any) => {
          if (this.active && this.scene.leaveModeFn) {
            this.scene.leaveModeFn();
          } else {
            await this.enterPanorama();
          }
        },
      ),
    ];

    // if (this.textureSource && this.textureSource.isSkybox && !this.textureSource.isMergedSkybox) {
    allProperties.push(
      this.createProperty(
        'Convert',
        'Convert to Merged Skybox',
        'Experimental',
        'button',
        'none',
        true,
        () => {
          return this.textureSource.isMergedSkybox ? 'Converted' : 'Convert';
        },
        async () => {
          if (this.textureSource.isSkybox && !this.textureSource.isMergedSkybox) {
            // get all 6 textures and merge into one texture
            const source: SkyboxTexture = this.textureSource as SkyboxTexture;
            const images = (await source.getTextureSources()) as string[];
            const sources: HTMLImageElement[] = [];
            for (let i = 0; i < images.length; i++) {
              sources.push(await PanoramaWidget.imageLoader.loadAsync(images[i]));
            }

            const canvas = document.createElement('canvas');
            const w = sources[0].width;
            const h = sources[0].height;
            canvas.width = w * 3;
            canvas.height = h * 2;
            const context = canvas.getContext('2d');
            if (!context) {
              console.error('Cannot get canvas ctx');
              return;
            }
            for (let ind = 0; ind < images.length; ind++) {
              const i = ind % 3;
              const j = Math.floor(ind / 3);
              context.drawImage(sources[ind], 0, 0, w, h, w * i, h * j, w, h);
            }

            const getBlob = function (): Promise<Blob | null> {
              return new Promise((resolve) => {
                canvas.toBlob(
                  (result: Blob | null) => {
                    resolve(result);
                  },
                  'image/jpeg',
                  0.5,
                );
              });
            };

            const result = await getBlob();
            if (!result) {
              alert('Could not convert sorry.');
              return;
            }
            const file = await TextureService.uploadTextureFile(result); //, 'Texture', 'skybox-merge.jpg');

            source.isMergedSkybox = true;
            source.id0 = file.id;
            await this.textureSource.updateTextureSources();
          }
        },
      ),
    );

    return allProperties;
  }

  public getSelectables() {
    const result = super.getSelectables();
    for (const child of this.renderNode.children) {
      result.push(child);
    }
    return result;
  }

  /** Enter this panorama, build a skybox around the camera. */
  public async enterPanorama() {
    if (PanoramaWidget.activePanoramaWidget) {
      PanoramaWidget.activePanoramaWidget.exitPanorama();
    }

    console.log('Enter Panorama');

    if (!this.loadedTexture) {
      /** We don't want to flash the loading screen immediately because that makes the experience
       * feel very discontinuous and sloppy, so we only show the loading screen if it has taken more than
       * .75 seconds to load get the skybox textures downloaded and processed. */
      const delayedLoading = timeout(750, LoadingScreen.ShowLoading, LoadingScreen.HideLoading);
      this.loadedTexture = await this.createTextures();
      delayedLoading.cancel();
    }

    if (!PanoramaWidget.activePanoramaWidget || this.cameraRotationOnStartAlways) {
      console.log('setting rotation', !PanoramaWidget.activePanoramaWidget, this.cameraRotationOnStartAlways, PanoramaWidget.activePanoramaWidget);
      this.scene.setRotation(this.cameraRotationOnStart);
      // setTimeout(() => {
      //   this.scene.setRotation(this.cameraRotationOnStart);
      //   console.log('Set rotation to', this.cameraRotationOnStart);
      // }, 1000);

    } else {
      console.log('not setting rotation', !PanoramaWidget.activePanoramaWidget, this.cameraRotationOnStartAlways, PanoramaWidget.activePanoramaWidget);
      this.scene.setRotation(undefined);
    }

    PanoramaWidget.activePanoramaWidget = this;
    this.active = true;
    if (this.marker) {
      this.marker.visible = false;
    }
    this.scene.calculateInRange(this.renderNode.position);
    const { x, y, z } = this.renderNode.position;
    this.scene.setPosition({ x, y: y + this.yOffset, z });
    this.scene.perspectiveRotation = this.cameraRotation.clone();



    this.scene.setBackground(this.loadedTexture);
    this.scene.leaveModeFn = () => {
      this.exitPanoramaMode();
    };
    this.scene.showPanoramaButton = this.canExit === true;
    this.scene.updateView = true;
    this.scene.view = 'Panorama';
    this.scene.dispatchEvent('view-change', {
      data: undefined,
      dataType: 'none',
      sourceWidgetId: this.id,
      event: 'view-change'
    });
    this.triggerProVizEvent('entered', 'none');
    TextBox2DWidget.eventHandler.dispatchEvent('view-changed', {
      data: undefined,
      dataType: 'none',
      sourceWidgetId: this.id,
      event: 'view-changed'
    });
    const position = this.renderNode.position;
    PanoramaWidget.eventHandler.dispatchEvent('preload', {
      data: { x: position.x, y: position.y, z: position.z },
      dataType: 'vector3',
      sourceWidgetId: this.id,
      event: 'preload'
    });
  }

  /** Leave this panorama.  */
  public async exitPanorama() {
    this.active = false;
    if (this.marker) {
      this.marker.visible = true;
    }
    this.scene.updateView = true;
    this.triggerProVizEvent('exited', 'none');
  }

  /** Exit the panorama mode. Goes back to dollhouse type view.
   * NOT for moving BETWEEN panoramas.*/
  private async exitPanoramaMode() {
    PanoramaWidget.activePanoramaWidget = undefined;
    this.active = false;
    this.scene.setBackground(undefined);
    this.scene.view = 'Normal';
    this.scene.showPanoramaButton = false;
    this.scene.leaveModeFn = undefined;
    this.renderNode.visible = true;
    this.scene.updateView = true;
    this.scene.dispatchEvent('view-change', {
      data: undefined,
      dataType: 'none',
      sourceWidgetId: this.id,
      event: 'view-change'
    });
    this.triggerProVizEvent('exited', 'none');
  }

  private setTexture(source: string | SkyboxTexture) {
    if (isSkyboxTexture(source)) {
      this.textureSource = source;
    } else {
      this.textureSource = new EquirectangularTexture();
      this.textureSource.id = source;
    }
    this.textureSource.updateTextureSources();
  }

  private setTextureType(skyboxOn: boolean) {
    const createNewSource = () => {
      if (skyboxOn) {
        return new SkyboxTexture();
      } else {
        return new EquirectangularTexture();
      }
    };
    if (!this.textureSource) {
      this.textureSource = createNewSource();
    } else if (this.textureSource && this.textureSource.isSkybox) {
      if (!skyboxOn) {
        this.textureSource = createNewSource();
      }
    } else if (this.textureSource && !this.textureSource.isSkybox) {
      if (skyboxOn) {
        this.textureSource = createNewSource();
      }
    }
  }

  private async createTextures() {
    const source = this.textureSource;
    if (isEquirectangular(source)) {
      const url = await source.getTextureSource();
      if (!url) {
        console.error('No equi pano source url');
        return;
      }
      return await this.loadTexture(url);
    }
    if (isSkyboxTexture(source) && !source.isMergedSkybox) {
      const urls = (await source.getTextureSources()) as any;
      if (!urls) {
        console.error('No skybox pano source url');
        return;
      }
      return await this.loadCubeTexture(urls);
    } else if (isSkyboxTexture(source) && source.isMergedSkybox) {
      // turn 6 merged images into cube
      const urls = await source.getTextureSources();
      if (!urls) {
        console.error('No merged pano source url');
        return;
      }
      return await this.loadCubeTextureFromMerged(urls[0]!);
    }
  }

  private async loadTexture(imgUrl: string): Promise<Texture> {
    return new Promise((resolve, reject) => {
      PanoramaWidget.textureLoader.load(
        imgUrl,
        (texture) => {
          texture.name = this.label + this.id;
          resolve(texture);
        },
        undefined,
        (e) => reject(e),
      );
    });
  }

  private async loadCubeTexture(urls: string[]): Promise<Texture> {
    return new Promise((resolve, reject) => {
      PanoramaWidget.cubeTextureLoader.load(
        urls,
        (texture) => {
          texture.name = this.label + this.id;
          resolve(texture);
        },
        undefined,
        (e) => reject(e),
      );
    });
  }

  private async loadCubeTextureFromMerged(url: string): Promise<Texture> {
    return new Promise((resolve) => {
      PanoramaWidget.imageLoader.load(url, (image) => {
        const sides: HTMLImageElement[] = [];

        // Separate into 6 parts
        const canvas = document.createElement('canvas');
        canvas.width = image.width / 3;
        canvas.height = image.height / 2;
        const context = canvas.getContext('2d');
        if (!context) {
          console.error('Cannot get canvas ctx');
          return;
        }
        for (let ind = 0; ind < 6; ind++) {
          const i = ind % 3;
          const j = Math.floor(ind / 3);

          context.drawImage(
            image,
            canvas.width * i,
            canvas.height * j,
            canvas.width,
            canvas.height,
            0,
            0,
            canvas.width,
            canvas.height,
          );
          const img = document.createElement('img');
          img.src = canvas.toDataURL('image/jpeg', 0.5);
          sides.push(img);
        }

        const textureCube = new CubeTexture(sides);
        textureCube.needsUpdate = true;
        resolve(textureCube);
      });
    });
  }

  public getClickable() {
    const result = super.getClickable();
    if (!this.active && this.renderNode) {
      result.push(this.renderNode);
    }
    return result;
  }

  public serialize(): any {
    const result = super.serialize();
    if (this.textureSource) {
      result.textureSource = this.textureSource.serialize();
    }
    {
      const { x, y, z } = this.cameraRotation;
      result.cameraRotation = { x, y, z };
    }
    {
      const { x, y, z } = this.cameraRotationOnStart;
      result.cameraRotationOnStart = { x, y, z };
    }
    result.cameraRotationOnStartAlways = this.cameraRotationOnStartAlways;
    result.canExit = this.canExit;
    result.yOffset = this.yOffset;
    result.spriteId = this.spriteId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    if (data.yOffset) {
      this.yOffset = data.yOffset;
    }
    if (data.canExit) {
      this.canExit = data.canExit;
    }
    if (data.cameraRotation) {
      const { x, y, z } = data.cameraRotation;
      this.cameraRotation.set(x, y, z);
    }
    if (data.cameraRotationOnStart) {
      const { x, y, z } = data.cameraRotationOnStart;
      this.cameraRotationOnStart.set(x, y, z);
    }
    if (data.cameraRotationOnStartAlways) {
      this.cameraRotationOnStartAlways = data.cameraRotationOnStartAlways;
    }
    this.spriteId = data.spriteId;
    if (data.textureSource.id) {
      this.textureSource = new EquirectangularTexture();
      this.textureSource.id = data.textureSource.id;
      return;
    } else if (data.textureSource) {
      this.textureSource = new SkyboxTexture();
      this.textureSource.deserialize(data.textureSource);
      return;
    }
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.setupMarker();

    return true;
  }

  private async setupMarker() {
    // console.log("Hello!");
    const geometry = new CircleGeometry(0.25, 32);
    const material = this.spriteId
      ? await AnimatedSpriteMaterial.InstanceFromId(this.spriteId)
      : AnimatedSpriteMaterial.Instance(spriteResource.id, spriteResource);
      
      if (this.marker) {
        this.renderNode.remove(this.marker);
        this.marker.material.dispose();
        this.marker = undefined;
      }

      this.marker = new Mesh(geometry, material.material);
      this.marker.renderOrder = 2;
      this.marker.receiveShadow = false;
      this.marker.rotation.set(Math.PI / 2, 0, 0);

      this.renderNode.add(this.marker);
  }

  dispose() {
    super.dispose();
    this.marker?.material.dispose();
    this.marker?.geometry.dispose();
  }
}

ModuleService.Register(PanoramaWidget.type, PanoramaWidget);
