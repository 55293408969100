import { BaseWidget } from '../../baseWidget';
import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../..';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';

export class EnvironmentWidget extends BaseWidget {
  public static type: string = 'environment';

  // Data
  public web: boolean = true;
  public ar: boolean = true;
  public vr: boolean = true;
  public hololens: boolean = true;
  public oculus: boolean = true;
  public iOS: boolean = true;
  public android: boolean = true;

  public phone: boolean = true;
  public tablet: boolean = true;
  public wearable: boolean = true;
  public desktop: boolean = true;

  public editor: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = EnvironmentWidget.type;
    this.widgetName = 'Environment';
    this.label = 'Environment';
    this.category = 'Advanced';
  }

  public async init() {
    if (this.web || (this.editor && this.scene.sceneMode === SceneMode.Editor)) {
      await super.init();

      this.triggerProVizEvent('web', 'none');

      return true;
    } else {
      this.renderNode.visible = false;
    }
    return false;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('web', 'Web', 'Experiences', 'bool', 'boolean', true),
      this.createProperty('ar', 'AR', 'Experiences', 'bool', 'boolean', true),
      this.createProperty('vr', 'VR', 'Experiences', 'bool', 'boolean', true),
      this.createProperty('editor', 'Editor Only', 'Experiences', 'bool', 'boolean', true),
      this.createProperty('hololens', 'Hololens', 'Platforms', 'bool', 'boolean', true),
      this.createProperty('oculus', 'Oculus', 'Platforms', 'bool', 'boolean', true),
      this.createProperty('iOS', 'iOS', 'Platforms', 'bool', 'boolean', true),
      this.createProperty('android', 'Android', 'Platforms', 'bool', 'boolean', true),
      this.createProperty('phone', 'Phone', 'Devices', 'bool', 'boolean', true),
      this.createProperty('tablet', 'Tablet', 'Devices', 'bool', 'boolean', true),
      this.createProperty('wearable', 'Wearable', 'Devices', 'bool', 'boolean', true),
      this.createProperty('desktop', 'Desktop', 'Devices', 'bool', 'boolean', true),
    ];
  }

  public serialize() {
    const result = super.serialize();
    result.web = this.web;
    result.ar = this.ar;
    result.vr = this.vr;
    result.hololens = this.hololens;
    result.oculus = this.oculus;
    result.iOS = this.iOS;
    result.android = this.android;
    result.phone = this.phone;
    result.tablet = this.tablet;
    result.wearable = this.wearable;
    result.desktop = this.desktop;
    result.editor = this.editor;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.web = data.web;
    this.ar = data.ar;
    this.vr = data.vr;
    this.hololens = data.hololens;
    this.oculus = data.oculus;
    this.iOS = data.iOS;
    this.android = data.android;
    this.phone = data.phone;
    this.tablet = data.tablet;
    this.wearable = data.wearable;
    this.desktop = data.desktop;
    this.editor = data.editor;
  }
}

ModuleService.Register(EnvironmentWidget.type, EnvironmentWidget);
