import { APIFolder } from "@proviz/api-services";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../common/button/Button";
import PaddedContainer from "../common/containers/paddedContainer";
import CustomTemplate from "./CustomTemplate";
import PageContentHeader from "./pageContentHeader";
import PageHeader from "./pageHeader";
import ProvizTemplate from "./ProvizTemplate";

const PROVIZ_TEMPLATES_VIEW = 0;
const CUSTOM_TEMPLATES_VIEW = 1;

export default function CreateSceneView() {
  const location = useLocation();
  const [currentFolder, setCurrentFolder] = useState<APIFolder>();
  const [templateView, setTemplateView] = useState(PROVIZ_TEMPLATES_VIEW);
  useEffect(() => {
    if (location.state) {
      setCurrentFolder(location.state as APIFolder);
    }
  }, []);
  const useProvizTemplates = () => {
    setTemplateView(PROVIZ_TEMPLATES_VIEW);
  };
  const useCustomTemplates = () => {
    setTemplateView(CUSTOM_TEMPLATES_VIEW);
  };
  return (
    <div className="homeRightView">
      <PageHeader />

      <div className="homeContent">
        <div className="homeContentContainer">
          <PaddedContainer>
            <PageContentHeader title="Scene Templates">
              <div>
                <Button
                  classNames={`btn-${
                    templateView === PROVIZ_TEMPLATES_VIEW
                      ? "primary"
                      : "default"
                  }`}
                  onClick={useProvizTemplates}
                >
                  Proviz Templates
                </Button>
                <Button
                  classNames={`btn-${
                    templateView === CUSTOM_TEMPLATES_VIEW
                      ? "primary"
                      : "default"
                  }`}
                  onClick={useCustomTemplates}
                >
                  Custom Templates
                </Button>
              </div>
            </PageContentHeader>
          </PaddedContainer>
          {templateView === PROVIZ_TEMPLATES_VIEW && (
            <ProvizTemplate currentFolder={currentFolder} />
          )}
          {templateView === CUSTOM_TEMPLATES_VIEW && (
            <CustomTemplate currentFolder={currentFolder} />
          )}
        </div>
      </div>
    </div>
  );
}
