import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class WhenWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'when';
  private static instanceCount = 0;

  // Data
  private state: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    if (!notInScene) {
      WhenWidget.instanceCount++;
    }
    this.label = 'When ' + WhenWidget.instanceCount;

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = WhenWidget.type;
    this.widgetName = 'When';
    this.selectable = true;
    this.events = [
      {
        label: 'Continue',
        name: 'continue',
      },
      {
        label: 'Not',
        name: 'not',
      }
    ];

    this.services = [
      {
        label: 'Set State',
        name: 'set-state',
        desc: '<b>Sets the boolean state of the When widget</b>',
      },
      {
        label: 'Evaluate',
        name: 'evaluate',
        desc: '<b>Evalulates the state of the When widget</b>',
      },
    ];

    this.addEventListener('service-set-state', (event: ProVizEventData) => {
      if (event.dataType === 'boolean') {
        this.state = event.data as boolean;
      } else {
        console.warn('Tried to set a non boolean value on When widget');
      }
    });
    this.addEventListener('service-evaluate', (event: ProVizEventData) => {
      this.handleLogicUpdate(event);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    return [
      ...super.getProperties(),
      this.createProperty(
        'state',
        'State',
        'Core',
        'bool',
        'boolean',
        true
      )
    ];
  }

  private handleLogicUpdate(event: ProVizEventData) {
    console.log('[When] Widget', this.state, event);
    if (this.state) {
      this.triggerProVizEvent('continue', event.dataType, event.data);
    } else {
      this.triggerProVizEvent('not', event.dataType, event.data);
    }
  }

  public serialize() {
    const result = super.serialize();
    result.state = this.state;
    return result;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.state = data.state ?? this.state;
  }
}

ModuleService.Register(WhenWidget.type, WhenWidget);
