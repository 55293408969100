import { APIFolder, SceneType, SceneService } from "@proviz/api-services";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Split from "react-split";
import styled from "styled-components";
import Button from "../common/button/Button";
import ThumbnailCard, { ISceneRecord } from "./ThumbnailCard";

const defaultThumbnail = getAbsoluteUrl("images/thumbnail.svg");

const SceneListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .template {
    width: calc(25% - 10px);
    pointer-events: auto;
    display: flex;
    height: fit-content;
    cursor: pointer;
    .thumbnailCard {
      width: 100% !important;
      pointer-events: none;
    }
  }
`;

const SelectedCircle = styled.div`
  width: 25px;
  height: 25px;
  background: blue;
  border-radius: 50%;
  z-index: 1001;
  margin-left: -1.75em;
  margin-top: -0.5em;
`;

interface IProvizTemplateViewProps {
  currentFolder?: APIFolder;
}

export default function ProvizTemplate({
  currentFolder,
}: IProvizTemplateViewProps) {
  const history = useHistory();
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  //Just a placeholder static array substituing the api call
  const sceneTemplates = [
    {
      name: "Default Scene",
      desc: "Creates an empty proviz scene for use",
      thumbnail: defaultThumbnail,
      id: "00000-00000-00000-00000",
      sceneType: "Standard",
      updated: "2023-01-27T05:14:51.2735526",
    },
    {
      name: "Template Scene",
      desc: "Creates a empty proviz scene as a custom template",
      thumbnail: defaultThumbnail,
      id: "00000-00000-00000-00001",
      sceneType: "Template",
      updated: "2023-01-23T00:00:00",
    },
  ];

  const createScene = async (sceneType: SceneType) => {
    const scene: ISceneRecord = await SceneService.create({
      name: "New Scene",
      sceneType: sceneType,
      folderId: currentFolder?.id || null,
    });
    history.push(`/editor/${scene.id}`);
  };

  return (
    <>
      <Split className="split" sizes={[75, 25]} gutterSize={5}>
        <SceneListContainer className="templatesList">
          {sceneTemplates.map((template) => {
            return (
              <div
                className="template"
                onClick={() => {
                  if (selectedTemplate?.id === template.id) {
                    //should be using the id or the type of scene here to create it
                    createScene(selectedTemplate.sceneType);
                  } else {
                    setSelectedTemplate(template);
                  }
                  console.log(selectedTemplate);
                }}
              >
                <ThumbnailCard
                  key={template.id}
                  title={template.name}
                  thumbnail={template.thumbnail}
                  openUrl={`/editor/${template.id}`}
                  updated={template.updated}
                  onDragStart={() => {}}
                />
                {selectedTemplate?.id === template.id && <SelectedCircle />}
              </div>
            );
          })}
        </SceneListContainer>
        <div className="templateInfo" style={{ padding: "0 1em" }}>
          {selectedTemplate && (
            <>
              <h1>{selectedTemplate.name}</h1>
              <div
                style={{
                  height: "150px",
                  backgroundImage: `url(${selectedTemplate.thumbnail})`,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <p>{selectedTemplate.desc}</p>
              <Button
                onClick={() => {
                  //should be using the selected scene info for the scene type
                  createScene(selectedTemplate.sceneType);
                }}
              >
                Create Scene
              </Button>
            </>
          )}
        </div>
      </Split>
    </>
  );
}
