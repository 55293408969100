import { Camera, PerspectiveCamera, WebGLRenderer } from "three";
import {
  applyRendererSettings,
  buildRenderer,
  ProVizScene,
} from "@proviz/proviz-sdk";
import GraphicsManager from "./GraphicsManager";

export default class Renderer {
  renderer: WebGLRenderer;
  manager: GraphicsManager;
  screenshotRequested: boolean = false;
  screenshotCb: ((blob: Blob) => void) | undefined;
  camera: Camera;

  constructor(
    width: number,
    height: number,
    camera: PerspectiveCamera,
    manager: GraphicsManager
  ) {
    this.manager = manager;
    this.camera = camera;
    this.renderer = buildRenderer({ width, height });
    this.render = this.render.bind(this);
    this.requestRender = this.requestRender.bind(this);
    this.render();
  }

  connectToDom(container: HTMLDivElement) {
    container.appendChild(this.renderer.domElement);
  }

  attachListeners(proVizScene: ProVizScene) {
    this.applySceneSettings(proVizScene);
    proVizScene.addEventListener("renderer-update", () => {
      this.applySceneSettings(proVizScene);
      this.render();
    });
  }

  private applySceneSettings(proVizScene: ProVizScene) {
    applyRendererSettings(this.renderer, proVizScene);
  }

  get domElement() {
    return this.renderer.domElement;
  }

  requestRender() {
    this.render();
  }

  render() {
    this.renderer.clear();
    this.renderer.render(this.manager.scene, this.camera);
    if (this.screenshotRequested) {
      this.renderer.domElement.toBlob((blob) => {
        if (blob === null || !this.screenshotCb) {
          alert("Sorry, that screenshot failed.");
          return;
        }
        this.screenshotCb(blob);
      });
      this.screenshotRequested = false;
    }
    this.renderer.clearDepth();
    if (this.manager.helperScene) {
      this.renderer.render(this.manager.helperScene, this.camera);
    }
  }

  setSize(width: number, height: number) {
    this.renderer.setSize(width, height, true);
  }

  requestScreenShot(cb: (blob: Blob) => void) {
    this.screenshotRequested = true;
    this.screenshotCb = cb;
  }

  dispose() {
    this.renderer.dispose();
  }
}
