import { CircleGeometry, Material, Mesh, Vector3 } from 'three';
import { ProVizScene, SceneMode } from '../../..';
import { getMediaFilePath } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import AnimatedSpriteMaterial from '../../../utils/AnimatedSpriteMaterial';
import SpriteResource from '../../../utils/SpriteResource';
import { is_ios } from '../../../utils/BrowserTest';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { EventDispatcher } from '../../../EventDispatcher';
import { ProVizEventData } from '../../../ProVizEventData';
import { MultiLangOption } from '../../..';

// TODO: Load this from the API
const spriteResource: SpriteResource = new SpriteResource({
  id: 'textBox2DDefaultSprite',
  imageSrc: '07522b41-96ef-4ae9-a1fc-2d3452a01592.png',
  verticalTiles: 8,
  horizontalTiles: 8,
  framesPerSecond: 30,
  numberOfTiles: 60,
});

/**
 * HTML Elements used by widget.
 */
interface IElements {
  el: HTMLElement;
  hoverEl: HTMLElement;
  titleEl: HTMLElement;
  contentEl: HTMLElement;
}

export class TextBox2DWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'text-box-2d';
  public static eventHandler = new EventDispatcher();
  private static instanceCount = 0;
  private static styleEl?: HTMLStyleElement;
  private marker?: Mesh<CircleGeometry, Material>;
  private elements?: IElements;
  private elVisible: boolean = false;
  private internalClock: number = 0;

  // Data
  public titles: MultiLangOption = {};
  public textOptions: MultiLangOption = {};
  public customCSS: string = '';
  public imageSrc: string = '';
  public pixelOffsetX: number = 50;
  public pixelOffsetY: number = 50;
  public version = 2;

  private lastPosition: Vector3 = new Vector3();

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = TextBox2DWidget.type;
    this.widgetName = 'Text Box 2D';
    if (!notInScene) {
      TextBox2DWidget.instanceCount++;
    }
    this.label = 'Text Box 2D ' + TextBox2DWidget.instanceCount;
    this.selectable = true;

    this.events.push({
      label: 'Clicked',
      name: 'clicked',
    });
    this.events.push({
      label: 'Open',
      name: 'open',
    });
    this.events.push({
      label: 'Close',
      name: 'close',
    });

    this.addEventListener('clicked', () => {
      this.changeVisibility(!this.elVisible);
    });
    this.addEventListener('service-open', () => {
      this.changeVisibility(true);
    });
    this.addEventListener('service-close', () => {
      this.changeVisibility(false);
    });

    TextBox2DWidget.eventHandler.addEventListener('box-opened', (e: ProVizEventData) => {
      if (e.dataType === 'string') {
        const id = e.data as string;
        if (id !== this.id) {
          this.changeVisibility(false);
        }
      }
    });

    TextBox2DWidget.eventHandler.addEventListener('view-changed', () => {
      if (this.elVisible) {
        this.changeVisibility(false);
      }
    });

    this.scene.addEventListener('modal-open', () => {
      if (this.elVisible) {
        this.changeVisibility(false);
      }
    });
  }

  public update(delta: number) {
    super.update(delta);
    if (!this.elVisible || !this.elements) {
      return;
    }

    // rate limit to 30fps
    this.internalClock += delta * 1000;
    const rate = 1000 / 30;
    if (this.internalClock < rate) {
      return;
    }
    this.internalClock -= rate;

    const pos = this.scene.toScreenPosition(
      this.renderNode,
      this.scene.camera,
      this.scene.renderer,
    );
    if (this.lastPosition.x != pos.x && this.lastPosition.y != pos.y) {
      this.elements.el.setAttribute('style', `left: ${pos.x}px; top: ${pos.y}px`);
      this.lastPosition = pos;
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('titles', 'Title', 'Core', 'multi-lang-opts', 'string', true),
      this.createProperty(
        'textOptions',
        'Text Options',
        'Core',
        'multi-lang-opts-multi-line',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'Add text for each language. Language options are added to the scene as a whole in the Language Options property.',
      ),
      this.createProperty('customCSS', 'Custom CSS', 'Experimental', 'multi-line-string', 'string', true),
      this.createProperty(
        'imageSrc',
        'Background Image Source',
        'Core',
        'image-options',
        'string',
        true,
        undefined,
        (data: any) => {
          this.imageSrc = data;
        },
      ),
      this.createProperty('pixelOffsetX', 'Pixel Offset X', 'Experimental', 'number', 'number', true),
      this.createProperty('pixelOffsetY', 'Pixel Offset Y', 'Experimental', 'number', 'number', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.titles = this.titles;
    result.textOptions = this.textOptions;
    result.customCSS = this.customCSS;
    result.imageSrc = this.imageSrc;
    result.pixelOffsetX = this.pixelOffsetX;
    result.pixelOffsetY = this.pixelOffsetY;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.titles = data.titles ?? this.titles;
    this.textOptions = data.textOptions ?? this.textOptions;
    this.customCSS = data.customCSS || '';
    this.imageSrc = data.imageSrc;
    this.pixelOffsetX = data.pixelOffsetX || 50;
    this.pixelOffsetY = data.pixelOffsetY || 50;
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }

    switch (data.version) {
      case 1:
        const textOpts = { en: '' };
        if (data.textOptions) {
          // Parse the options once and use in the closure.
          data.textOptions.split(',').forEach((t: string) => {
            const parts = t.split(':');
            if (parts[1]) {
              try {
                textOpts[parts[0]] = decodeURIComponent(parts[1]);
              } catch (e) {
                console.error("can't decode", parts[1]);
              }
            }
          });
        }
        data.textOptions = textOpts;
        if (data.content) {
          // If content field exists then we just
          // put the data.content in for the english text option
          data.textOptions = { en: data.conent };
          data.content = '';
        }

        if (!data.titles) {
          data.titles = { en: data.title || '' };
        } else {
          const titles = {};
          data.titles.split(',').forEach((t: string) => {
            const parts = t.split(':');
            if (parts[1]) {
              try {
                titles[parts[0]] = decodeURIComponent(parts[1]);
              } catch (e) {
                console.error("can't decode", parts[1]);
              }
            }
          });
          data.titles = titles;
        }
    }
    return data;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    // Add 3D clickable hotspot node

    const geometry = new CircleGeometry(0.25, 32);
    this.marker = new Mesh(
      geometry,
      AnimatedSpriteMaterial.Instance(spriteResource.id, spriteResource).material,
    );
    this.marker.renderOrder = 2;
    this.marker.receiveShadow = false;
    this.marker.rotation.set(Math.PI / 2, 0, 0);

    this.renderNode.add(this.marker);

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    // Add 2D node

    if (!TextBox2DWidget.styleEl) {
      TextBox2DWidget.styleEl = document.createElement('style');
      TextBox2DWidget.styleEl.innerText = `
.custom-text-box-2d {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  ${is_ios ? 'width: 60vw;' : 'min-width: 200px; max-width: 400px;'}
    font-family: Arial, Helvetica, sans-serif;
        }
  .custom-text-box-2d .inner-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.custom-text-box-2d .line {
  position: absolute;
  left: 0;
  width: 50px;
  height: 3px;
  background: #7fa934;
}
.custom-text-box-2d .container {
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 3px solid #7fa934;
  color: #000;
}
.custom-text-box-2d .title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
}
.custom-text-box-2d .content {
  max-height: ${is_ios ? '70vh' : '75px'};
  overflow-y: auto;
  font-size: 16px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.25);
}
.custom-text-box-2d .content::-webkit-scrollbar {
  width: 5px;
}
.custom-text-box-2d .content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.custom-text-box-2d .content::-webkit-scrollbar-thumb {
  background: #7fa934;
}
.custom-text-box-2d .close {
  ${!is_ios && 'cursor: pointer;'}
  z-index: 10000;
  padding-top: 10px;
}
.custom-text-box-2d-hover-text {
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  z-index: 1000;
  background: #808080;
  border: 1px solid #7fa934;
  color: #fff;
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
}
.custom-text-box-2d-hover-text.hide {
  height: 0px;
  width: 0px;
  display: none;
}`;
      document.body.append(TextBox2DWidget.styleEl);
    }

    // TODO: (Garrett) Remove on scene unload
    if (this.customCSS && !document.getElementById(`custom-text-box-2d-style-${this.id}`)) {
      const style = document.createElement('style');
      style.setAttribute('id', `custom-text-box-2d-style-${this.id}`);
      style.innerText = this.customCSS;
      document.body.append(style);
    }

    const el = document.createElement('div');
    el.setAttribute('id', `textbox-2d-${this.id}`);
    el.setAttribute('class', 'custom-text-box-2d');

    const innerContainer = document.createElement('div');
    innerContainer.setAttribute('class', 'inner-container');
    innerContainer.setAttribute('style', `top: -${this.pixelOffsetY}px;`);
    el.append(innerContainer);

    const line = document.createElement('div');
    line.setAttribute('class', 'line');
    line.setAttribute('style', `top: ${this.pixelOffsetY}px; width: ${this.pixelOffsetX}px`);
    innerContainer.append(line);

    const box2DContainer = document.createElement('div');
    box2DContainer.setAttribute('class', 'container');

    let box2DContainerStyle;
    if (this.imageSrc) {
      const imgSrc = getMediaFilePath(this.imageSrc);
      box2DContainerStyle = `left: ${this.pixelOffsetX}px; background-image: url('${imgSrc}')`;
    } else {
      box2DContainerStyle = `left: ${this.pixelOffsetX}px; background-color: #d3d1ce`;
    }
    box2DContainer.setAttribute('style', box2DContainerStyle);
    innerContainer.append(box2DContainer);

    const titleEl = document.createElement('div');
    titleEl.setAttribute('class', 'title');

    box2DContainer.append(titleEl);

    const contentEl = document.createElement('div');
    contentEl.setAttribute('class', 'content');
    this.scene.addEventListener('language-change', () => {
      this.setDisplayText();
    });
    contentEl.onscroll = (e) => {
      e.stopPropagation();
      e.preventDefault();
      return false;
    };
    el.onpointermove = (e) => {
      e.stopPropagation();
    };
    if (is_ios) {
      el.ontouchmove = (e) => {
        e.stopPropagation();
      };
    }
    box2DContainer.append(contentEl);

    const close = document.createElement('div');
    close.setAttribute('class', 'close');
    close.innerText = 'Close';
    close.onclick = () => {
      this.changeVisibility(false);
    };
    box2DContainer.append(close);

    this.scene.parentEl?.append(el);

    const hoverEl = document.createElement('div');
    hoverEl.setAttribute('class', 'custom-text-box-2d-hover-text hide');
    hoverEl.setAttribute('style', 'visibility: hidden;');

    this.elements = { contentEl, el, hoverEl, titleEl };
    this.setDisplayText();
    this.scene.parentEl?.append(hoverEl);
    this.changeVisibility(false);

    return true;
  }

  private setDisplayText() {
    if (!this.elements) {
      return;
    }
    const { titleEl, contentEl, hoverEl } = this.elements;
    const titleContent =
      this.titles[this.scene.selectedLanguage] ?? this.titles[this.scene.defaultLanguage] ?? '';
    hoverEl.innerText = titleContent;
    titleEl.innerText = titleContent;

    contentEl.innerText =
      this.textOptions[this.scene.selectedLanguage] ??
      this.textOptions[this.scene.defaultLanguage] ??
      '';
  }

  private changeVisibility(state: boolean) {
    this.elVisible = state;
    if (!this.elements) {
      return;
    }
    const { el, contentEl, hoverEl } = this.elements;
    this.lastPosition = new Vector3();
    el.setAttribute('style', state ? '' : 'display: none');
    this.triggerProVizEvent(state ? 'open' : 'close', 'none');
    if (state) {
      hoverEl.setAttribute('class', 'custom-text-box-2d-hover-text.hide');
      hoverEl.setAttribute('style', 'visibility: hidden;');
      contentEl.scrollTo(0, 0);
    }
    if (state) {
      TextBox2DWidget.eventHandler.dispatchEvent('box-opened', {
        data: this.id,
        dataType: 'string',
        sourceWidgetId: this.id,
        event: 'box-opened'
      });
    }
  }

  public onHoverEnter() {
    if (this.elVisible || !this.elements || !this.marker) {
      return;
    }
    const { hoverEl } = this.elements;
    const pos = this.scene.toScreenPosition(this.marker, this.scene.camera, this.scene.renderer);
    hoverEl.setAttribute('class', 'custom-text-box-2d-hover-text');
    hoverEl.setAttribute('style', `left: ${pos.x + 5}px; top: ${pos.y}px;`);
  }

  public onHoverLeave() {
    if (!this.elements) {
      return;
    }
    const { hoverEl } = this.elements;
    hoverEl.setAttribute('class', 'custom-text-box-2d-hover-text.hide');
    hoverEl.setAttribute('style', 'visibility: hidden;');
  }

  public getHoverable() {
    const result = super.getHoverable();
    if (this.visible && this.marker) {
      result.push(this.marker);
    }
    return result;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.marker) {
      result.push(this.marker);
    }
    return result;
  }

  dispose() {
    super.dispose();
    if (this.elements) {
      this.elements.el.remove();
      this.elements.hoverEl.remove();
      this.elements.contentEl.remove();
      this.elements.titleEl.remove();
    }
    this.marker?.material.dispose();
    this.marker?.geometry.dispose();
  }
}

ModuleService.Register(TextBox2DWidget.type, TextBox2DWidget);
