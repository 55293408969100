import { ReactElement } from "react";

interface Props {
  page: number;
  totalPages: number;
  pageSet: (page: number) => void;
  isFileSelect?: boolean;
}

const MAX_VISIBLE_PAGES = 5;

const generateSequence = (current: number, total: number): number[] => {
  let offset = -3;
  if (total < MAX_VISIBLE_PAGES) {
    offset = -current;
  } else if (current === 1) {
    offset = -1;
  } else if (current === 2) {
    offset = -2;
  } else if (current === total) {
    offset = -5;
  } else if (current === total - 1) {
    offset = -4;
  }
  return new Array(Math.min(MAX_VISIBLE_PAGES, total))
    .fill(0)
    .map((_, i) => i + 1 + current + offset);
};

export default function PagingControl(props: Props): ReactElement {
  const numbers = generateSequence(props.page + 1, props.totalPages).map(
    (pageNum) => (
      <span
        key={`page-${pageNum}`}
        className={`${props.isFileSelect ? "pagination" : ""} ${pageNum === props.page + 1 ? "active" : ""}`}
        onClick={() => props.pageSet(pageNum - 1)}
      >
        {pageNum}
      </span>
    )
  );

  return (
    <div className="pagingControls">
      {
        <div>
            <div onClick={props.page === 0 ? undefined : () => props.pageSet(0)}>
              <span className={`${props.page === 0 ? "inactive" : ""}`}>{"<<"}</span>
            </div>
            <div onClick={() => props.pageSet(props.page - 1)}>
              <span className={`${props.page === 0 ? "inactive" : ""}`}>{"<"}</span>
            </div>
            <div className="pagingPages">{numbers}</div>
            <div onClick={props.page === props.totalPages - 1 ? undefined : () => props.pageSet(props.page + 1)}>
              <span className={`${props.page === props.totalPages - 1 ? "inactive" : ""}`}>{">"}</span>
            </div>
            <div onClick={props.page === props.totalPages - 1 ? undefined : () => props.pageSet(props.totalPages - 1)}>
              <span className={`${props.page === props.totalPages - 1 ? "inactive" : ""}`}>{">>"}</span>
            </div>
        </div>
      }
    </div>
  );
}
