import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class CounterWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'counter';

  // Data
  public start: number = 1;
  public max: number = 0;
  public loop: boolean = false;

  private value: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Counter';

    this.usage = 'Flow';
    this.category = 'Events';

    this.widgetType = CounterWidget.type;
    this.widgetName = 'Counter';
    this.selectable = true;
    this.events = [
      {
        label: 'Changed Value',
        name: 'changed-value',
      },
      {
        label: 'Changed Ratio',
        name: 'changed-ratio',
      },
    ];

    this.services = [
      {
        label: 'Add One',
        name: 'increment',
        // desc: 'By default, adds 1 to the internal count.'
      },
      {
        label: 'Subtract One',
        name: 'decrement',
        // desc: 'By default, subtracts 1 to the internal count.'
      },
    ];

    this.addEventListener('service-increment', () => {
      this.value++;
      if (this.value > this.max && this.max > 0) {
        if (this.loop) {
          this.value = this.start;
        } else {
          this.value = this.max;
        }
      }
      this.emitNewValue();
    });
    this.addEventListener('service-decrement', () => {
      this.value--;
      if (this.value < this.start) {
        if (this.loop && this.max > 0) {
          this.value = this.max;
        } else {
          this.value = this.start;
        }
      }
      this.emitNewValue();
    });

    this.addService('Reset', 'reset', 'Reset counter back to initial', () => {
      this.value = this.start;
      this.triggerProVizEvent('changed-value', 'number', this.value);
      if (this.max > 0) {
        this.triggerProVizEvent('changed-ratio', 'number', this.value / this.max);
      }
    });
  }

  private emitNewValue() {
    this.triggerProVizEvent('changed-value', 'number', this.value);
    if (this.max > 0) {
      this.triggerProVizEvent('changed-ratio', 'number', this.value / this.max);
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('start', 'Start', 'Data', 'number', 'number', true),
      this.createProperty(
        'max',
        'Max',
        'Data',
        'number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.max = data;
        },
        undefined,
        'This is value will be the denominator of the changed-ratio value. If set to 0, it will not be used.',
      ),
      this.createProperty('integers', 'Integers Only', 'Data', 'bool', 'boolean', true),
      this.createProperty(
        'loop',
        'Looping',
        'Data',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'If set it will loop around to to the start/end if it reaches past the min or max',
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.start = this.start;
    result.max = this.max;
    result.loop = this.loop;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.start = data.start;
    this.max = data.max;
    this.loop = data.loop;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    this.value = this.start;
    if (this.scene.sceneMode !== SceneMode.Editor) {
      this.emitNewValue(); // update any attached widgets
    }
    return true;
  }
}

ModuleService.Register(CounterWidget.type, CounterWidget);
