import { BaseWidget } from "@proviz/proviz-sdk";
import React, { ReactElement } from "react";
import { ManagerStore } from "../../../store";
import { SettableWidgetProperty } from "./SettableWidgetProperty";

interface Props {
  property: SettableWidgetProperty;
  header: ReactElement;
  forceUpdate: () => void;
}
/**
* Drop widgets to build a list.
*/
export const WidgetSelectList = (props: Props) => {
  const { header, property } = props;
  const sceneManager = ManagerStore( s => s.SceneManager )

  const widgets: string[] = property.get() || [];

  const handleDrop = (e: React.DragEvent<HTMLElement>, ind: number) => {
    console.log(e);
    // const resourceId = e.dataTransfer.getData("resource/id");
    const nodeId = e.dataTransfer.getData("node/id");
    if ( nodeId ) {
      const curr: string[] = property.get() || [];
      if (ind === -1) {
        property.set([...curr, nodeId]);
      } else {
        curr[ind] = nodeId;
        property.set([...curr]);
      }
      props.forceUpdate();
    }
  }

  const select = (widget?: BaseWidget) => {
    if (widget) {
      sceneManager.setSelectionById(widget.id);
    }
  }

  const remove = (widget?: BaseWidget) => {
    if (widget) {
      const curr: string[] = property.get() || [];
      const ind = curr.indexOf(widget.id);
      if (ind > -1) {
        curr.splice(ind, 1);
        property.set([...curr]);
        props.forceUpdate();
      }
    }
  }

  return <div className="videoSelector">
      {header}
      {widgets.map((wdg: string, ind: number) => {
        const widget = sceneManager.getById(wdg);
        return <div onDrop={(e: React.DragEvent<HTMLElement>) => handleDrop(e, ind)} onDragEnter={(e) => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
          <div className="widget-select">
              {(widget && <div>
                <div className="widget-select-part" onClick={() => select(widget)}>
                    {widget.label}
                </div>
                <div className="widget-select-remove" onClick={() => remove(widget)}>
                  X
                </div>
              </div>) || <div className="widget-select-full">[Drop Widget]</div>}
          </div>
        </div>
      })}
      <div onDrop={(e: React.DragEvent<HTMLElement>) => handleDrop(e, -1)} onDragEnter={(e) => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
          <div className="widget-select">
            <span>[Drop Widget]</span>
          </div>
        </div>
    </div>;
};
