import { IPosition } from '@proviz/api-services';
import {
  Vector3,
  Object3D,
  Quaternion,
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  CapsuleGeometry,
  Euler,
} from 'three';
import { BaseWidget } from '../..';
import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { Transform } from '../../../types';
import BasePositionableWidget from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';

export class PhysicsWidget extends BasePositionableWidget {
  public static type: string = 'physics';
  //Data
  public isDynamic: boolean = false;
  public physicDimensions: Vector3 = new Vector3(1, 1, 1);
  public physicsRadius: number = 1;
  public physicsHeight: number = 1;
  public physicShape: 'box' | 'sphere' | 'capsule' = 'box';
  public physicsObjectTranslation: Vector3 = new Vector3(0, 0, 0);
  public pxActor: any = null;
  public physicWireframe: Object3D | null = null;
  public staticFriction: number = 0.2;
  public dynamicFriction: number = 0.2;
  public restitution: number = 0.2;
  public mass: number = 1;
  public isKinematic: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent, notInScene);
    this.widgetType = PhysicsWidget.type;
    this.widgetName = 'Physics';
    this.label = 'Physics';
    this.usage = '3D';
    this.category = 'Experimental';
    this.selectable = true;
    this.updatePhysicsWireframe();
    this.events.push(
      {
        name: 'collision-begin',
        label: 'Collision Begin',
      },
      {
        name: 'collision-persist',
        label: 'Collision Persist',
      },
      {
        name: 'collision-end',
        label: 'Collision End',
      },
    );
    this.addService(
      'Apply Force',
      'apply-force',
      '<b>Applies the given vector as a force to the object</b>',
      (val) => {
        if (this.isDynamic && this.pxActor && val.dataType === 'vector3') {
          if (!this.pxActor.getRigidBodyFlags()) {
            this.pxActor.addForceAtLocalPos(
              {
                x: Number((val.data as Vector3).x),
                y: Number((val.data as Vector3).y),
                z: Number((val.data as Vector3).z),
              },
              { x: 0, y: 0, z: 0 },
            );
          }
        }
      },
    );
    this.addService(
      'Apply Velocity',
      'apply-velocity',
      '<b>Applies the given vector as a velocity to the object</b>',
      (val) => {
        if (this.isDynamic && this.pxActor && val.dataType === 'vector3') {
          if (this.pxActor.getRigidBodyFlags) {
            this.pxActor.setLinearVelocity(
              {
                x: Number((val.data as Vector3).x),
                y: Number((val.data as Vector3).y),
                z: Number((val.data as Vector3).z),
              },
              true,
            );
          }
        }
      },
    );
    this.addService(
      'Apply Angular Velocity',
      'apply-angular-velocity',
      '<b>Applies the given vector as an angular velocity to the object</b>',
      (val) => {
        if (this.isDynamic && this.pxActor && val.dataType === 'vector3') {
          if (this.pxActor.getRigidBodyFlags) {
            this.pxActor.setAngularVelocity(
              {
                x: Number((val.data as Vector3).x),
                y: Number((val.data as Vector3).y),
                z: Number((val.data as Vector3).z),
              },
              true,
            );
          }
        }
      },
    );
    this.addService(
      'Set Kinematic Flag',
      'set-kinematic-flag',
      '<b>changes the value of the kinematic flag</b>',
      (val) => {
        if (this.isDynamic && this.pxActor && val.dataType === 'boolean' && this.scene.physics) {
          this.scene.physics.setKinematicFlag(this, val.data);
          //console.log(this.pxActor.getRigidBodyFlags());
        }
      },
    );
    this.addService(
      'Set Kinematic Target',
      'set-kinematic-target',
      '<b>sets the new position and rotation of the kinematic object</b>',
      (val) => {
        if (this.isDynamic && this.pxActor.getRigidBodyFlags && val.dataType === 'transform') {
          const rotationVal = new Euler(
            Number((val.data as Transform).rotation.x),
            Number((val.data as Transform).rotation.y),
            Number((val.data as Transform).rotation.z),
          );
          const quaternionVal = new Quaternion();
          quaternionVal.setFromEuler(rotationVal);
          this.pxActor.setKinematicTarget({
            translation: {
              x: Number((val.data as Transform).position.x),
              y: Number((val.data as Transform).position.y),
              z: Number((val.data as Transform).position.z),
            },
            rotation: {
              w: quaternionVal.w,
              x: quaternionVal.x,
              y: quaternionVal.y,
              z: quaternionVal.z,
            },
          });
        }
      },
    );
  }

  public setPosition(data: IPosition): void {
    super.setPosition(data);
    if (this.scene.sceneMode === SceneMode.Play) {
      let worldPosisition = new Vector3();
      let worldQuaternion = new Quaternion();
      this.renderNode.getWorldPosition(worldPosisition);
      this.renderNode.getWorldQuaternion(worldQuaternion);
      const transform = {
        translation: {
          x: Number(worldPosisition.x) + Number(this.physicsObjectTranslation.x),
          y: Number(worldPosisition.y) + Number(this.physicsObjectTranslation.y),
          z: Number(worldPosisition.z) + Number(this.physicsObjectTranslation.z),
        },
        rotation: {
          w: worldQuaternion.w, // PhysX uses WXYZ quaternions,
          x: worldQuaternion.x,
          y: worldQuaternion.y,
          z: worldQuaternion.z,
        },
      };
      this.pxActor.setGlobalPose(transform, true);
    }
  }

  public update(delta: number): void {
    super.update(delta);
    if (this.pxActor) {
      const transform = this.pxActor.getGlobalPose();
      const worldPos = new Vector3(
        transform.translation.x,
        transform.translation.y,
        transform.translation.z,
      );
      if (this.renderNode.parent) this.renderNode.parent.worldToLocal(worldPos);
      super.setPosition({
        x: Number(worldPos.x) - Number(this.physicsObjectTranslation.x),
        y: Number(worldPos.y) - Number(this.physicsObjectTranslation.y),
        z: Number(worldPos.z) - Number(this.physicsObjectTranslation.z),
      });
      let q = new Quaternion(
        transform.rotation.x,
        transform.rotation.y,
        transform.rotation.z,
        transform.rotation.w,
      );
      this.renderNode.setRotationFromQuaternion(q.normalize());
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    let extraProperties: BaseWidgetProperty[] = [];
    if (this.isDynamic) {
      extraProperties.push(
        this.createProperty(
          'mass',
          'mass of the object',
          'Experimental',
          'number',
          'number',
          true,
          undefined,
          (val) => {
            this.mass = val >= 1 ? val : 1;
            this.pxActor.setMass(this.mass);
          },
        ),
        this.createProperty(
          'isKinematic',
          'Kinematic Object',
          'Experimental',
          'bool',
          'boolean',
          true,
        ),
      );
    }
    if (this.physicShape == 'box') {
      extraProperties.push(
        this.createProperty(
          'physicDimensions',
          'Object Dimensions',
          'Experimental',
          'vec3',
          'vector3',
          true,
          undefined,
          (val) => {
            this.physicDimensions = val;
            this.updatePhysicsWireframe();
          },
        ),
      );
    } else if (this.physicShape == 'sphere') {
      extraProperties.push(
        this.createProperty(
          'physicsRadius',
          'Object Dimensions',
          'Experimental',
          'number',
          'number',
          true,
          undefined,
          (val) => {
            this.physicsRadius = val;
            this.updatePhysicsWireframe();
          },
        ),
      );
    } else if (this.physicShape == 'capsule') {
      extraProperties.push(
        this.createProperty(
          'physicsRadius',
          'Capsule Radius',
          'Experimental',
          'number',
          'number',
          true,
          undefined,
          (val) => {
            this.physicsRadius = val;
            this.updatePhysicsWireframe();
          },
        ),
        this.createProperty(
          'physicsHeight',
          'Capsule height',
          'Experimental',
          'number',
          'number',
          true,
          undefined,
          (val) => {
            this.physicsHeight = val;
            this.updatePhysicsWireframe();
          },
        ),
      );
    }
    return [
      ...result,
      this.createProperty('isDynamic', 'Dynamic object', 'Experimental', 'bool', 'boolean', true),
      this.createProperty(
        'physicShape',
        'Object Shape',
        'Experimental',
        'select',
        'string',
        true,
        undefined,
        (val) => {
          this.physicShape = val;
          this.updatePhysicsWireframe();
        },
        () =>
          ['box', 'sphere', 'capsule'].map((x) => {
            return { key: x, label: x };
          }),
      ),
      this.createProperty(
        'physicsObjectTranslation',
        'Physics Object Position',
        'Experimental',
        'vec3',
        'vector3',
        true,
        undefined,
        (val) => {
          this.physicsObjectTranslation = val;
          this.updatePhysicsWireframe();
        },
      ),
      this.createProperty(
        'staticFriction',
        'Static Friction',
        'Experimental',
        'number',
        'number',
        true,
      ),
      this.createProperty(
        'dynamicFriction',
        'Dynamic Friction',
        'Experimental',
        'number',
        'number',
        true,
      ),
      this.createProperty(
        'restitution',
        'restitution',
        'Experimental',
        'constrained-number',
        'number',
        true,
        undefined,
        undefined,
        () =>
          [0, 1].map((x) => {
            return { key: x, label: x.toString() };
          }),
      ),
      ...extraProperties,
    ];
  }

  public serialize() {
    let data = super.serialize();
    data.isDynamic = this.isDynamic;
    data.physicDimensions = this.physicDimensions;
    data.physicsHeight = this.physicsHeight;
    data.physicsRadius = this.physicsRadius;
    data.physicShape = this.physicShape;
    data.physicsObjectTranslation = this.physicsObjectTranslation;
    data.mass = this.mass;
    data.staticFriction = this.staticFriction;
    data.dynamicFriction = this.dynamicFriction;
    data.restitution = this.restitution;
    data.isKinematic = this.isKinematic;
    return data;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.isDynamic = data.isDynamic ?? this.isDynamic;
    this.physicDimensions = data.physicDimensions ?? this.physicDimensions;
    this.physicsHeight = data.physicsHeight ?? this.physicsHeight;
    this.physicsRadius = data.physicsRadius ?? this.physicsRadius;
    this.physicShape = data.physicShape ?? this.physicShape;
    this.physicsObjectTranslation = data.physicsObjectTranslation ?? this.physicsObjectTranslation;
    this.mass = data.mass ?? this.mass;
    this.staticFriction = data.staticFriction ?? this.staticFriction;
    this.dynamicFriction = data.dynamicFriction ?? this.dynamicFriction;
    this.restitution = data.restitution ?? this.restitution;
    this.isKinematic = data.isKinematic ?? this.isKinematic;
    this.updatePhysicsWireframe();
  }

  public updatePhysicsWireframe() {
    if (this.scene.sceneMode !== SceneMode.Editor) {
      return;
    }
    let geometry;
    if (this.physicShape === 'box') {
      geometry = new BoxGeometry(
        this.physicDimensions.x,
        this.physicDimensions.y,
        this.physicDimensions.z,
      );
    } else if (this.physicShape === 'sphere') {
      geometry = new SphereGeometry(this.physicsRadius);
    } else if (this.physicShape === 'capsule') {
      geometry = new CapsuleGeometry(this.physicsRadius, this.physicsHeight);
    }
    const material = new MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
    this.physicWireframe = new Mesh(geometry, material);
    this.physicWireframe.userData = { name: 'pxWireframe' };
    this.physicWireframe.position.set(
      this.physicsObjectTranslation.x,
      this.physicsObjectTranslation.y,
      this.physicsObjectTranslation.z,
    );
    let oldWireframe = this.renderNode.children.filter(
      (child) => child.userData['name'] === 'pxWireframe',
    )[0];
    if (oldWireframe) this.renderNode.remove(oldWireframe);
    this.renderNode.add(this.physicWireframe);
  }

  public async init() {
    const result = super.init();
    if (this.scene.sceneMode === SceneMode.Play && this.scene.physics) {
      this.scene.physics.addWidget(this, {
        staticFriction: this.staticFriction,
        dynamicFriction: this.dynamicFriction,
        restitution: this.restitution,
      });
    }
    return result;
  }

  public triggerCollision(type: 'begin' | 'persist' | 'end') {
    this.triggerProVizEvent(`collision-${type}`, 'none');
  }

  public dispose(): void {
    super.dispose();
    if (this.pxActor) {
      this.pxActor.release();
      delete this.pxActor;
    }
  }
}
ModuleService.Register(PhysicsWidget.type, PhysicsWidget);
