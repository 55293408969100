import { APIRole, PermissionEntity, PermissionType } from "../models";
import { BaseDataService, IServiceOptions } from "../BaseDataService";

export class APIRoleService extends BaseDataService<any> {
  constructor() {
    super("role");
  }

  async create(name: string, options?: IServiceOptions): Promise<APIRole> {
    return this.post('', { name });
  }

  async available() : Promise<APIRole[]> {
    return await this.get('available');
  }

  async addPermission(id: string, entity: PermissionEntity, perm: PermissionType) {
    return this.post(`${id}/${entity}/${perm}`);
  }
  async removePermission(id: string, entity: PermissionEntity, perm: PermissionType) {
    return this.delete(`${id}/${entity}/${perm}`);
  }
}

const RoleService = new APIRoleService();

export default RoleService;
