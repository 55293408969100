import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class BarcodeScanWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'barcode-scan';

  // Data
  public autoScan: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = BarcodeScanWidget.type;
    this.widgetName = 'Barcode Scan';
    this.label = 'Barcode Scan';
    this.selectable = false;
    this.category = 'Experimental';

    this.events.push({
      label: 'Code Scanned',
      name: 'code-scanned',
    });

    this.addService('Start Scan', 'start-scan', 'Start scanning for barcode', () => {
      console.warn('Not supported in browser yet');
    });
    this.addService('Process Frame', 'process-frame', 'Process the current frame if currently scanning', () => {
      console.warn('Not supported in browser yet');
    });
    this.addService('Stop Scan', 'stop-scan', 'Stop scanning for barcode', () => {
      console.warn('Not supported in browser yet');
    });
    this.addService('Process Image', 'process-image', 'Process an image to find a barcode', () => {
      console.warn('Not supported in browser yet');
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    return [
      ...super.getProperties(),
      this.createProperty('autoScan', 'Auto Scan', 'Core', 'bool', 'boolean', true, undefined, undefined, undefined, 'Continuously process every frame (2 fps). If off, waits for Process Frame service to be called.')
    ]
  }

  public serialize() {
    const result = super.serialize();
    result.autoScan = this.autoScan;
    return result;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.autoScan = data.autoScan ?? this.autoScan;
  }
}

ModuleService.Register(BarcodeScanWidget.type, BarcodeScanWidget);
