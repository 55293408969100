import { ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetEvent } from '../../BaseWidgetEvent';
import { IBaseWidgetService } from '../../BaseWidgetService';
import { ProVizEventData } from '../../../ProVizEventData';

export class WebWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'web';

  // Data
  public url: string = '';
  public customEvents: string[] = [];
  public customServices: string[] = [];
  public version = 2;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = WebWidget.type;
    this.widgetName = 'Web';
    this.label = 'Web';
    this.category = 'UI';
    this.events = [{
      label: 'Loaded',
      name: 'loaded',
      desc: 'When the web view has been loaded'
    }];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('url', 'URL', 'Core', 'string', 'string', true),
      this.createProperty('customEvents', 'Web Events', 'Core', 'list', 'list', true),
      this.createProperty('customServices', 'Web Services', 'Core', 'list', 'list', true),
    ];
  }

  public getEvents(): IBaseWidgetEvent[] {
    const result = super.getEvents();
    const webEvents = this.customEvents.map((val: string) => {
      return { label: val, name: `custom-event-${val}` };
    });
    return [...result, ...webEvents];
  }

  public getServices(): IBaseWidgetService[] {
    const result = super.getServices();
    const webServices = this.customServices.map((val: string) => {
      return { label: val, name: val };
    });
    return [...result, ...webServices];
  }

  public async init(): Promise<boolean> {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    const w: any = window;
    const embed = document.createElement('div');
    embed.setAttribute('style', 'position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; pointer-events: none;');

    this.getServices().forEach((service) => {
      this.addEventListener(`service-${service.name}`, (evData: ProVizEventData) => {
        console.log(evData);

        const content = {
          type: `service-${service.name}`,
          data: ''
        }
        if (evData.dataType === 'string') {
          content.data = evData.data as string;
        }

        w.provizxr && w.provizxr({
          data: JSON.stringify(content)
        });
      });
    });

    fetch(this.url).then((resp) => {
      // TODO: Implement better way to propogate events
      w.triggerProVizEvent = (event: string) => {
        this.triggerProVizEvent(`custom-event-${event}`, 'none');
      };

      resp.text().then((text) => {
        embed.innerHTML = text;

        nodeScriptReplace(embed);

        super.init();
      });
      const container = document.getElementById('proviz-container');
      if (container) {
        container.append(embed);
        container.setAttribute('style', 'pointer-events: all');
      }
      function nodeScriptReplace(node) {
        if ( node.tagName === 'SCRIPT' ) {
          node.parentNode.replaceChild( nodeScriptClone(node) , node );
        }
        else {
          var i = -1, children = node.childNodes;
          while ( ++i < children.length ) {
                nodeScriptReplace( children[i] );
          }
        }

        return node;
      }
      function nodeScriptClone(node){
              var script  = document.createElement("script");
              script.text = node.innerHTML;

              var i = -1, attrs = node.attributes, attr;
              while ( ++i < attrs.length ) {                                    
                    script.setAttribute( (attr = attrs[i]).name, attr.value );
              }
              return script;
      }
    });
    return true;
  }

  public serialize() {
    const result = super.serialize();

    result.url = this.url;
    result.customEvents = this.customEvents;
    result.customServices = this.customServices;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.url = data.url;
    this.customEvents = data.customEvents ?? this.customEvents;
    this.customServices = data.customServices ?? this.customServices;
  }

  public migrate(data: any): Promise<any> {
    if (!data.version) {
      data.version = 1;
    }

    switch (data.version) {
      case 1:
        data.customEvents = (data.customEvents || '').split(',');
    }
    return data;
  }
}

ModuleService.Register(WebWidget.type, WebWidget);
