import { SceneService, TextureService } from "@proviz/api-services";
import { PanoramaWidget, ProVizScene, SkyboxTexture } from "@proviz/proviz-sdk";
import { ReactElement, useState } from 'react'
import { Euler, PerspectiveCamera, Quaternion, Scene } from 'three';
import MatterportService, { Resolution } from '../../services/MatterportService';
import Button from "../common/button/Button";
import Modal from '../common/modals/Modals';

export default function MatterportModal(): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState("");
    const [failed, setFailed] = useState(false);
    const [sceneCreated, setSceneCreated] = useState(false);
    const [started, setStarted] = useState(false);
    const [requestCount, setRequestCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [resolution, setResolution] = useState<Resolution>("high")
    const [scale, setScale] = useState(1);

    const importMatterport = async () => {
        setFailed(false)
        setSceneCreated(false)
        try {
            const data = await MatterportService.getPanoramas(id, resolution)
            console.log('Panoramas from Matterport', data);
            const name = (data.model.name || "New Matterport Import") + ` ${resolution} resolution`;
            const apiScene = await SceneService.create({ name, sceneType: "Standard", folderId: null });
            const locations = data.model.panoLocations;
            const threeScene = new Scene();
            const provizScene = new ProVizScene(apiScene.id, threeScene, new PerspectiveCamera());
            provizScene.deserialize(apiScene);
            setTotal(locations.length);
            setStarted(true);
            // We only upload one panorama's worth of textures at time to not overwhelm the server/the client
            // with parallel requests since each pano requires 12 http requests
            for(let idx = 0; idx < locations.length; idx++) {
                setRequestCount(idx);

                try {
                    const location = locations[idx];
                    const skybox = location.skybox;
                    const panoramaWidget = new PanoramaWidget(provizScene);

                    if (skybox.perspective) {
                        const r = location.rotation;
                        const p = location.position;
                        if (!skybox.perspective.anchor) {
                            console.log('not a perspective anhor');
                            continue;
                        }
                        const anchorP = skybox.perspective.anchor.position;
                        panoramaWidget.setPosition({ x: anchorP.x * scale, y: anchorP.z * scale, z: -anchorP.y * scale });
                        panoramaWidget.yOffset = p.z - anchorP.z;// (skybox.perspective.anchor.position.z - location.position.z) * scale + 0.6;
                        // change coordinate 
                        const quaternion = new Quaternion(r.x, r.y, r.z, r.w);
                        const euler = new Euler();
                        euler.setFromQuaternion(quaternion);
                        panoramaWidget.cameraRotation.set(euler.x, euler.z, -euler.y);
                    }
                    const textures: any = {};
                    const panoImages = skybox.children;
                    if (!panoImages || panoImages.length === 0) {
                        console.log('no pano images', skybox);
                        continue;
                    }
                    console.log('Get pano images', panoImages);

                    await Promise.all(panoImages.map(async (imgUrl: string, idx: number) => {
                        const fileName = MatterportService.ExtractFileName(imgUrl);
                        console.log('get img url');
                        const imgBlob = await fetch(imgUrl)
                            .then(r => r.blob())
                            .then(blobFile => new File([blobFile], fileName, { type: "image/jpg" }));
                        console.log('upload img');
                        const tex = await TextureService.uploadTextureFile(imgBlob)

                        console.log('apply tex');
                        textures[MatterportService.matterportToThreeIndex(idx)] = { id: tex.id, location: tex.file?.location };
                    }));

                    console.log('Got pano images');

                    panoramaWidget.textureSource = new SkyboxTexture();
                    panoramaWidget.textureSource.deserialize({
                        id0: textures[0].id,
                        id1: textures[1].id,
                        id2: textures[2].id,
                        id3: textures[3].id,
                        id4: textures[4].id,
                        id5: textures[5].id,
                        location0: textures[0].location,
                        location1: textures[1].location,
                        location2: textures[2].location,
                        location3: textures[3].location,
                        location4: textures[4].location,
                        location5: textures[5].location,
                    });

                    panoramaWidget.label = `Panorama ${idx}`;
                    provizScene.add(panoramaWidget);
                } catch (e) {
                    console.log(e);
                }
            }
            const sceneData = provizScene.serialize();
            apiScene.data = JSON.stringify(sceneData);
            await SceneService.update(apiScene);
            setSceneCreated(true);
            setTotal(0);
            setRequestCount(0);
            setStarted(false);
        } catch (e) {
            console.error('Could not import Matterport scene', e);
            setFailed(true);
        }
    }

    return <>
        <>
            <Button onClick={() => setShowModal(true)}>Import</Button>
        </>
        {showModal && <Modal
            title={"Import a Matterport Scene"}
            close={() => setShowModal(false)}
        >

            <p>To import a matterport scene first run this command in a local terminal.</p>

            <p className="code">npm i -g local-cors-proxy # (this only needs to be run the first time)</p>
            <p className="code">{`npx lcp --proxyUrl https://api.matterport.com/api/models/graph --origin ${window.location.origin}`}</p>

            <h6>Resolution</h6>
            <select value={resolution} onChange={(e) => setResolution(e.target.value as any)}>
                <option value="high">High</option>
                <option value="low">Low</option>
                <option value="2k">2k</option>
                <option value="4k">4k</option>
            </select>
            <h6>Coordinate scaling</h6>
            <input value={scale} onChange={(e) => setScale(parseFloat(e.target.value))} />
            <h6>Matterport Id:</h6>
            <input value={id} onChange={(e: any) => setId(e.target.value)} />
            <hr />
            {started && <div>
                {requestCount} / {total} Panoramas created
            </div>}
            {id && <Button type='primary' onClick={importMatterport}>Import</Button>}
            {failed && <h4>Failed</h4>}
            {sceneCreated && <h4>Scene Created</h4>}
        </Modal>}
    </>
}
