import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  children?: ReactNode | ReactNode[];
  centerTitle?: ReactNode;
}

export default function Header(props: Props) {
  const { isAuthenticated } = useAuth0();
  const { children, centerTitle } = props;
  return (
    <nav className="headerBar scene-editor">
      <Link to={isAuthenticated ? "/home" : "/"} className="homeLink">
        <span aria-hidden="true" className="icon-ProViz_Studio_Header_Logo" />
      </Link>
      {centerTitle}
      <div className="buttonsContainer">{children}</div>
    </nav>
  );
}
