import Command from "./Command";
import { BaseWidget } from "@proviz/proviz-sdk";
import GraphicsManager from "../graphics/GraphicsManager";

export default class RemoveNode extends Command {
  manager: GraphicsManager;
  node: BaseWidget;
  name = "Remove Widget";

  constructor(sceneManager: GraphicsManager, node: BaseWidget) {
    super();
    this.node = node;
    this.manager = sceneManager;
  }

  execute() {
    if (!this.node) {
      return; // todo maybe throw error
    }
    this.manager.remove(this.node);
  }

  undo() {
    this.manager.add(this.node);
  }
}
