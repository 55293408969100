import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { ProVizScene } from '../../../ProVizScene';
import { ModuleService } from '../../../moduleService';
import { BaseWidgetProperty } from '../..';
import { Vector3 } from 'three';
import { SceneMode } from '../../..';

export class ScaleGroupWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'scale-group';

  public initialScale: Vector3 = new Vector3(1, 1, 1);
  public scaleDistanceVector = new Vector3(1, 1, 1);
  public scaling: boolean = false;
  public scaleSpeed: number = 0;
  public scaleUp: boolean = false;
  public isLooping: boolean = false;
  //This is used to control the amount of time between leeeeeeeerrrrrrrrps.
  private elapsedTime: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Scale Loop';
    this.initialScale = this.getScale().clone();
    this.widgetType = ScaleGroupWidget.type;
    this.widgetName = 'Scale Loop';
    this.selectable = true;
    this.events = [];

    this.category = 'Animation';

    this.services = [
      {
        label: 'Start scale',
        name: 'start-scale',
        desc: '<b>Starts the scale of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Stop scale',
        name: 'stop-scale',
        desc: '<b>Stops the scale of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Loop scale',
        name: 'loop-scale',
        desc: '<b>Starts looping the scale of this widget between the set distance over time.</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Scale up',
        name: 'scale-up',
        desc: '<b>Toggles the scale of this widget in the upward direction</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Scale down',
        name: 'scale-down',
        desc: '<b>Toggles the scale of this widget in the downward direction</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
    ];

    this.addEventListener('service-start-scale', () => {
      this.isLooping = false;
      this.scaling = true;
    });

    this.addEventListener('service-stop-scale', () => {
      this.isLooping = false;
      this.scaling = false;
    });

    this.addEventListener('service-scale-up', () => {
      if (!this.scaling) this.scaling = true;
      if (this.isLooping) this.isLooping = false;
      this.scaleUp = true;
    });
    this.addEventListener('service-scale-down', () => {
      if (!this.scaling) this.scaling = true;
      if (this.isLooping) this.scaling = false;
      this.scaleUp = false;
    });
    this.addEventListener('service-loop-scale', () => {
      if (!this.scaling) this.scaling = true;
      this.isLooping = true;
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'scaleDistanceVector',
        'Set Scale Direction',
        'Core',
        'vec3',
        'vector3',
        true,
        undefined,
        undefined,
        undefined,
        'Setting this property sets how far the transition will go.',
      ),
      this.createProperty(
        'scaleSpeed',
        'Scaling Speed',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Setting this property sets the speed by which we will be scaling the group at.',
      ),
      this.createProperty(
        'scaling',
        'Start Scaling on Load',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'By clicking this, you are enabling the starting and stopping of the widget scale on load.',
      ),
    ];
  }

  public doScale(delta: number): any {
    if (!this.scaling) return;
    if (this.isLooping) this.elapsedTime += delta;

    if (this.isLooping && this.elapsedTime >= this.scaleSpeed) {
      this.elapsedTime = 0;
      this.scaleUp = !this.scaleUp;
    }

    if (this.scaleUp) {
      this.getScale().lerpVectors(this.getScale(), this.scaleDistanceVector, delta / this.scaleSpeed);
    } else {
      this.getScale().lerpVectors(this.getScale(), this.initialScale, delta / this.scaleSpeed);
    }
  }

  public update(delta: number): void {
    super.update(delta);
    if (this.scene.sceneMode === SceneMode.Editor) return;
    this.doScale(delta);
  }

  public serialize(): any {
    const result = super.serialize();
    //Set the data for the scale here.
    result.scaleDistanceVector = this.scaleDistanceVector;
    result.scaling = this.scaling;
    result.scaleSpeed = this.scaleSpeed;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.scaling = data.scaling;
    this.scaleDistanceVector = data.scaleDistanceVector;
    this.scaleSpeed = data.scaleSpeed;
  }
}

ModuleService.Register(ScaleGroupWidget.type, ScaleGroupWidget);
