import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class AndWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'and';
  private static instanceCount = 0;

  // Data
  private state1: boolean = false;
  private state2: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    if (!notInScene) {
      AndWidget.instanceCount++;
    }
    this.label = 'And ' + AndWidget.instanceCount;

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = AndWidget.type;
    this.widgetName = 'And';
    this.selectable = true;
    this.events = [
      {
        label: 'True',
        name: 'true',
      },
      {
        label: 'False',
        name: 'false',
      },
      {
        label: 'Value',
        name: 'value',
      },
    ];

    this.services = [
      {
        label: 'Set Value 1',
        name: 'set-value-1',
        desc: '<b>Sets Value 1 of the And widget</b>',
      },
      {
        label: 'Set Value 2',
        name: 'set-value-2',
        desc: '<b>Sets Value 1 of the And widget</b>',
      },
    ];

    this.addEventListener('service-set-value-1', (event: ProVizEventData) => {
      if (event.dataType === 'boolean') {
        const data = event.data as boolean;
        this.state1 = data;
        this.handleLogicUpdate();
      } else {
        console.warn('Tried to use a non-boolean value to set the state1 value of And widget: ' + this.widgetName);
      }
    });
    this.addEventListener('service-set-value-2', (event: ProVizEventData) => {
      if (event.dataType === 'boolean') {
        const data = event.data as boolean;
        this.state2 = data;
        this.handleLogicUpdate();
      } else {
        console.warn('Tried to use a non-boolean value to set the state2 value of And widget: ' + this.widgetName);
      }
    });
  }

  private handleLogicUpdate() {
    console.log('[AND] widget', this.state1, this.state2);
    if (this.state1 && this.state2) {
      this.triggerProVizEvent('true', 'boolean', true);
      this.triggerProVizEvent('value', 'boolean', true);
    } else {
      this.triggerProVizEvent('false', 'boolean', false);
      this.triggerProVizEvent('value', 'boolean', false);
    }
  }
}

ModuleService.Register(AndWidget.type, AndWidget);
