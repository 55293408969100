import { ModelWidget, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { Color, Mesh, MeshBasicMaterial, Object3D, SphereGeometry, Vector3 } from 'three';
import { ProVizEventData, ProVizEventDataList } from '../../../ProVizEventData';

export class WayfindingWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'wayfinding';

  // Data
  private nodes: string[] = [];
  private modelId: string | undefined = undefined;
  private distanceCutOff: number = 3.0;
  private distanceBetweenVisual: number = 0.25;
  private useCutOff: boolean = true;

  private displayModel: ModelWidget | undefined = undefined;
  private displayNodes: Object3D[] = [];

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = WayfindingWidget.type;
    this.widgetName = 'Wayfinding';
    this.label = 'Wayfinding';
    this.selectable = false;
    this.category = 'Experimental';

    this.addService(
      'Set Nodes',
      'set-nodes',
      'Sets the list of nodes to use for wayfinding',
      (e: ProVizEventData) => {
        // console.log(e);
        if (e.dataType === 'list') {
          const data: ProVizEventDataList = e.data as ProVizEventDataList;
          if (data.dataType === 'widgetId') {
            const nodes: string[] = data.data as string[];
            this.nodes = nodes;
            this.setupNodes();
          } else {
            console.warn('Wayfinding widget only accepts widget id list');
          }
        } else {
          console.warn('Wayfinding widget only accepts widget id list');
        }
      },
    );

    this.addService(
      'Add Node(s)',
      'add-node',
      'Adds a node to the current list',
      (e: ProVizEventData) => {
        if (e.dataType === 'list') {
          const data: ProVizEventDataList = e.data as ProVizEventDataList;
          if (data.dataType === 'widgetId') {
            const nodes: string[] = data.data as string[];
            this.nodes.push(...nodes);
            this.setupNodes();
          } else {
            console.warn('Wayfinding widget only accepts widget id list');
          }
        } else if (e.dataType === 'widgetId') {
          const data: string = e.data as string;
          this.nodes.push(data);
          this.setupNodes();
        } else {
          console.warn('Wayfinding widget only accepts widget id or widget id list');
        }
      },
    );

    this.addService(
      'Set Cut Off Distance',
      'set-cut-off-distance',
      'Sets the cut off distance before a node renders.',
      (e: ProVizEventData) => {
        if (e.dataType === 'number') {
          const data: number = e.data as number;
          this.distanceCutOff = data;
        }
      },
    );

    this.addService(
      'Set Cut Off',
      'set-cut-off',
      'Turns the cut off on/off',
      (e: ProVizEventData) => {
        if (e.dataType === 'boolean') {
          const data: boolean = e.data as boolean;
          this.useCutOff = data;
        }
      },
    );
  }

  public getProperties(): BaseWidgetProperty[] {
    return [
      ...super.getProperties(),
      this.createProperty(
        'distanceCutOff',
        'Distance Cut Off',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Maximum distance between nodes',
      ),
      this.createProperty(
        'distanceBetweenVisual',
        'Distance Between Nodes',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Distance between visual nodes of the path',
      ),
      this.createProperty(
        'useCutOff',
        'Use Cutoff',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'Whether the tunnel will always be shown',
      ),
      this.createProperty(
        'modelId',
        'Node Model',
        'Core',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model to use instead of the default sphere',
      ),
      this.createProperty('nodes', 'Nodes', 'Core', 'widget-list', 'list', true),
    ];
  }

  private async setupNodes() {
    this.displayNodes.forEach((node) => {
      this.renderNode.remove(node);
    });
    this.displayNodes = [];

    const nodePos = (node?: BaseWidget) => {
      return new Vector3(
        node?.renderNode.position.x || 0,
        node?.renderNode.position.y || 0,
        node?.renderNode.position.z || 0,
      );
    };

    // let totalDistance = 0;
    // if (this.nodes.length > 0) {
    //   let prev = this.scene.getById(this.nodes[0]);
    //   for(let i = 1; i < this.nodes.length; i++) {
    //     const next = this.scene.getById(this.nodes[i]);
    //     const prevPos = nodePos(prev);
    //     const nextPos = nodePos(next);

    //     totalDistance += prevPos.distanceTo(nextPos);
    //   }
    // }

    const positions: { pos: Vector3; dir: Vector3 }[] = [];
    if (this.nodes.length > 2) {
      let currDist = 0;
      let ind = 0;
      let curr = nodePos(this.scene.getById(this.nodes[ind]));
      let next = nodePos(this.scene.getById(this.nodes[ind + 1]));
      let currNextDist = curr.distanceTo(next);
      let currTravelSectionDist = 0;
      let direction = next.sub(curr);
      while (ind < this.nodes.length) {
        const adjustedDist = currDist - currTravelSectionDist;
        const percent = adjustedDist / currNextDist;
        // console.log('ind:', ind, currNextDist);
        // console.log('ind:', ind, 'of', this.nodes.length - 1, 'adjustedDist', adjustedDist, 'currNextDist', currNextDist, 'percent', percent);

        const pos = curr.clone().add(direction.clone().multiplyScalar(percent));
        pos.y = 0;
        positions.push({
          pos: pos,
          dir: direction.clone().normalize(),
        });
        currDist += this.distanceBetweenVisual || 0.1; // prevent from being 0
        // console.log(currDist, (this.distanceBetweenVisual || 0.1), totalDistance);

        if (currDist >= currTravelSectionDist + currNextDist) {
          // console.log('next section', currDist, currTravelSectionDist + currNextDist, percent);

          ind++;
          if (ind >= this.nodes.length - 1) {
            break;
          } else {
            currTravelSectionDist += currNextDist;
            curr = nodePos(this.scene.getById(this.nodes[ind]));
            next = nodePos(this.scene.getById(this.nodes[ind + 1]));
            // console.log(ind, this.nodes[ind])
            console.log(ind + 1, this.nodes[ind + 1]);
            currNextDist = curr.distanceTo(next);
            // console.log(currNextDist, curr, next, curr.length(), next.length());
            direction = next.clone().sub(curr);
          }
        }
      }
    }

    // console.log('nodes', this.nodes);

    if (!this.modelId) {
      const geometry = new SphereGeometry(0.05);
      const material = new MeshBasicMaterial({
        color: new Color(0xcccccc),
      });

      for (let i = 0; i < positions.length; i++) {
        const p = positions[i];
        const mesh = new Mesh(geometry, material);
        mesh.position.set(p.pos.x, p.pos.y, p.pos.z);
        this.renderNode.add(mesh);
        this.displayNodes.push(mesh);
      }
    } else {
      for (let i = 0; i < positions.length; i++) {
        const p = positions[i];

        this.displayModel = new ModelWidget(this.scene, this);
        this.displayModel.modelId = this.modelId;
        this.displayModel.deserialize({
          modelId: this.modelId,
          clickable: false,
          transparent: false,
          opacity: 1.0,
        });
        await this.displayModel.init();
        this.displayModel.renderNode.position.set(p.pos.x, p.pos.y, p.pos.z);
        this.displayModel.renderNode.lookAt(
          p.pos.x + p.dir.x,
          p.pos.y + p.dir.y,
          p.pos.z + p.dir.z,
        );

        this.renderNode.add(this.displayModel.renderNode);
        this.displayNodes.push(this.displayModel.renderNode);
      }
    }
    // console.log("positions", positions)
  }

  public async init(): Promise<boolean> {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    this.setupNodes();

    return true;
  }

  public update(delta: number): void {
    super.update(delta);
    if (!this.useCutOff) {
      return;
    }
    const t = new Vector3();
    const camPos = new Vector3(
      this.scene.cameraPosition.x,
      this.scene.cameraPosition.y,
      this.scene.cameraPosition.z,
    );
    this.displayNodes.forEach((node) => {
      const w = node.getWorldPosition(t);
      node.visible = w.distanceTo(camPos) < this.distanceCutOff;
    });
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.nodes = data.nodes;
    this.distanceCutOff = data.distanceCutOff;
    this.useCutOff = data.useCutOff;
    this.modelId = data.modelId;
    this.distanceBetweenVisual = data.distanceBetweenVisual ?? this.distanceBetweenVisual;
  }

  public serialize() {
    const result = super.serialize();
    result.nodes = this.nodes;
    result.distanceCutOff = this.distanceCutOff;
    result.useCutOff = this.useCutOff;
    result.modelId = this.modelId;
    result.distanceBetweenVisual = this.distanceBetweenVisual;
    return result;
  }
}

ModuleService.Register(WayfindingWidget.type, WayfindingWidget);
