import { getAbsoluteUrl } from "@proviz/proviz-sdk";

export const CategoryIcons: { [category: string]: string } = {
  Experimental: getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  Integration: getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  MRTK: getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  Colliders: getAbsoluteUrl("images/Widgets/Colliders/Collider.svg"),
  Animation: getAbsoluteUrl("images/Widgets/Animation/Animation.svg"),
  Advanced: getAbsoluteUrl("images/Widgets/Advanced/Advanced.svg"),
  Core: getAbsoluteUrl("images/Widgets/Core/Core.svg"),
  ARCS: getAbsoluteUrl("images/Widgets/ARCS/ARCS.svg"),
  Lights: getAbsoluteUrl("images/Widgets/Lights/Lights.svg"),
  Targets: getAbsoluteUrl("images/Widgets/Targets/Targets.svg"),
  UI: getAbsoluteUrl("images/Widgets/UI/UI.svg"),
};

export const WidgetIcons: { [category: string]: string } = {
  //Core
  model: getAbsoluteUrl("images/Widgets/Core/Model.svg"),
  "depth-model": getAbsoluteUrl("images/Widgets/Core/Depth Model.svg"),
  group: getAbsoluteUrl("images/Widgets/Core/Group.svg"),
  sprite: getAbsoluteUrl("images/Widgets/Core/Sprite.svg"),
  video: getAbsoluteUrl("images/Widgets/Core/Video.svg"),
  image: getAbsoluteUrl("images/Widgets/Core/Image.svg"),
  text: getAbsoluteUrl("images/Widgets/Core/Text.svg"),
  "text-box-2d": getAbsoluteUrl("images/Widgets/Core/2D Text Box.svg"),
  explode: getAbsoluteUrl("images/Widgets/Core/Explode.svg"),
  annotation: getAbsoluteUrl("images/Widgets/Core/Annotation.svg"),
  audio: getAbsoluteUrl("images/Widgets/Core/Audio.svg"),
  billboard: getAbsoluteUrl("images/Widgets/Core/Billboard.svg"),
  plane: getAbsoluteUrl("images/Widgets/Core/Plane.svg"),
  "progress-bar": getAbsoluteUrl("images/Widgets/Core/Progress Bar.svg"),
  teleport: getAbsoluteUrl("images/Widgets/Core/Teleport.svg"),
  //Advanced
  custom: getAbsoluteUrl("images/Widgets/Advanced/Custom Widget.svg"),
  panorama: getAbsoluteUrl("images/Widgets/Advanced/Panorama.svg"),
  //Animation
  "position-group": getAbsoluteUrl(
    "images/Widgets/Animation/Position Group.svg"
  ),
  "rotation-group": getAbsoluteUrl(
    "images/Widgets/Animation/Rotation Group.svg"
  ),
  "scale-group": getAbsoluteUrl("images/Widgets/Animation/Scale Group.svg"),
  //Experimental
  "closed-caption": getAbsoluteUrl(
    "images/Widgets/Experimental/Closed Caption.svg"
  ),
  // "variant-switch": getAbsoluteUrl("images/ModelDepthIcon.svg"), //Needs Icon
  "variant-switch": getAbsoluteUrl(
    "images/Widgets/Experimental/Experimental.svg"
  ), //PLACEHOLDER
  "timeline-element-widget": getAbsoluteUrl(
    "images/Widgets/Experimental/Timeline.svg"
  ),
  "radial-menu": getAbsoluteUrl("images/Widgets/Experimental/Radial Menu.svg"),
  // "model-viewer": getAbsoluteUrl("images/Widgets/Experimental/Timeline.svg"), //Needs Icon
  "model-viewer": getAbsoluteUrl(
    "images/Widgets/Experimental/Experimental.svg"
  ), //PLACEHOLDER
  // "native-ar-widget": getAbsoluteUrl("images/Widgets/Experimental/Native AR Widget.svg"), //Needs Icon
  "native-ar-widget": getAbsoluteUrl(
    "images/Widgets/Experimental/Experimental.svg"
  ), //PLACEHOLDER
  toolbelt: getAbsoluteUrl("images/Widgets/Experimental/Toolbelt.svg"),
  //Colliders
  "box-collider": getAbsoluteUrl("images/Widgets/Colliders/Box Collider.svg"),
  //Lights
  "ambient-light": getAbsoluteUrl("images/Widgets/Lights/Ambient Light.svg"),
  "directional-light": getAbsoluteUrl(
    "images/Widgets/Lights/Directional Light.svg"
  ),
  "hemi-light": getAbsoluteUrl("images/Widgets/Lights/Hemisphere Light.svg"),
  "point-light": getAbsoluteUrl("images/Widgets/Lights/Point Light.svg"),
  "spot-light": getAbsoluteUrl("images/Widgets/Lights/Spot Light.svg"),
  //ARCS
  "arcs-section": getAbsoluteUrl("images/Widgets/ARCS/ARCS Section.svg"),
  "arcs-qr": getAbsoluteUrl("images/Widgets/ARCS/ARCS QR.svg"),
  "arcs-step": getAbsoluteUrl("images/Widgets/ARCS/ARCS Step.svg"),
  "arcs-sync-point": getAbsoluteUrl("images/Widgets/ARCS/ARCS Sync Point.svg"),
  //Targets
  "area-target": getAbsoluteUrl("images/Widgets/Targets/Area Target.svg"),
  "face-target": getAbsoluteUrl("images/Widgets/Targets/Face Target.svg"),
  "image-target": getAbsoluteUrl("images/Widgets/Targets/Image Target.svg"),
  "model-target": getAbsoluteUrl("images/Widgets/Targets/Model Target.svg"),
  "plane-target": getAbsoluteUrl("images/Widgets/Targets/Plane Target.svg"),

  //UI - NO ICONS EXIST (WILL NEED UPDATING WHEN WE GET THEM)
  gui: getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  "gui-column": getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  "gui-row": getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  "gui-text": getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  "video-playlist-2d": getAbsoluteUrl(
    "images/Widgets/Experimental/Experimental.svg"
  ),
  web: getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),
  "button-2d": getAbsoluteUrl("images/Widgets/Experimental/Experimental.svg"),

  //Other
  "circular-button-widget": getAbsoluteUrl("images/CircleImageIcon.svg"), //Needs Icon?
  "language-selector": getAbsoluteUrl("images/LanguageSelectorIcon.svg"), //Needs updated Icon

  //Resources
  texture: getAbsoluteUrl("images/Resources/Texture.svg"),
  envmap: getAbsoluteUrl("images/Resources/Environment.svg"),
  subtitle: getAbsoluteUrl("images/Resources/Subtitles.svg"),
  scene: getAbsoluteUrl("images/Resources/Scene.svg"),
  areatarget: getAbsoluteUrl("images/Widgets/Targets/Area Target.svg"),
  modeltarget: getAbsoluteUrl("images/Widgets/Targets/Model Target.svg"),

  default: getAbsoluteUrl("images/Widgets/Widgets.svg"),
};
