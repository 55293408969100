import { Group, Material, Mesh, Object3D } from "three";

export async function flatMaterialNames(
  obj: Mesh | Object3D | Group
): Promise<string[]> {
  let mats: string[] = [];
  return new Promise<string[]>((resolve, reject) => {
    obj.traverse((o: Mesh | Object3D | Group) => {
      if (o instanceof Mesh) {
        if (o.material) {
          if (
            o.material instanceof Material &&
            !mats.includes(o.material.name)
          ) {
            mats.push(o.material.name);
          }
        }
      }
    });
    resolve(mats);
  });
}
