import { BaseDataService } from "../BaseDataService";
import Analytic from "../types/Analytic";
import { APIAnalyticScene, APIAnalyticUser } from '../models/Analytic'

export class APIAnalyticService extends BaseDataService<any> {
  constructor() {
    super("Analytics");
  }

  public async userLoggedIn(): Promise<any> {
    return await this.request(`user/login`, { method: 'POST' });
  }

  public async userLoggedOut(): Promise<any> {
    return await this.request(`user/logout`, { method: 'POST' });
  }

  public async user(analyticType: Analytic, userId?: string, organizationId?: string, start?: Date, end?: Date, page: number = 0, size: number = 0, filter: string = ''): Promise<APIAnalyticUser[]> {
    const queryParams: { key: string, value: string }[] = [];
    if (userId) queryParams.push({ key: 'userId', value: userId });
    if (organizationId) queryParams.push({ key: 'organizationId', value: organizationId });
    if (start) queryParams.push({ key: 'start', value: start.toUTCString() });
    if (end) queryParams.push({ key: 'end', value: end.toUTCString() });
    if (page) queryParams.push({ key: 'page', value: page.toString() });
    if (size) queryParams.push({ key: 'size', value: size.toString() });

    return await this.get(`user/${analyticType}`, undefined, queryParams);
  }

  public async scene(analyticType: Analytic, sceneId?: string, version?: number, organizationId?: string, start?: Date, end?: Date, page: number = 0, size: number = 0, filter: string = ''): Promise<APIAnalyticScene[]> {
    const queryParams: { key: string, value: string }[] = [];
    if (sceneId) queryParams.push({ key: 'sceneId', value: sceneId });
    if (version) queryParams.push({ key: 'version', value: version.toString() });
    if (organizationId) queryParams.push({ key: 'organizationId', value: organizationId });
    if (start) queryParams.push({ key: 'start', value: start.toUTCString() });
    if (end) queryParams.push({ key: 'end', value: end.toUTCString() });
    if (page) queryParams.push({ key: 'page', value: page.toString() });
    if (size) queryParams.push({ key: 'size', value: size.toString() });

    return await this.get(`scene/${analyticType}`, undefined, queryParams);
  }

  public async sceneCount(analyticType: Analytic, sceneId?: string, version?: number, organizationId?: string, start?: Date, end?: Date, page: number = 0, size: number = 0, filter: string = ''): Promise<number> {
    const queryParams: { key: string, value: string }[] = [];
    if (sceneId) queryParams.push({ key: 'sceneId', value: sceneId });
    if (version) queryParams.push({ key: 'version', value: version.toString() });
    if (organizationId) queryParams.push({ key: 'organizationId', value: organizationId });
    if (start) queryParams.push({ key: 'start', value: start.toUTCString() });
    if (end) queryParams.push({ key: 'end', value: end.toUTCString() });
    if (page) queryParams.push({ key: 'page', value: page.toString() });
    if (size) queryParams.push({ key: 'size', value: size.toString() });

    return await this.request(`scene/${analyticType}/count`, undefined, queryParams);
  }

  public async model(analyticType: Analytic, modelId?: string, organizationId?: string, start?: Date, end?: Date, page: number = 0, size: number = 0, filter: string = ''): Promise<APIAnalyticScene[]> {
    const queryParams: { key: string, value: string }[] = [];
    if (modelId) queryParams.push({ key: 'modelId', value: modelId });
    if (organizationId) queryParams.push({ key: 'organizationId', value: organizationId });
    if (start) queryParams.push({ key: 'start', value: start.toUTCString() });
    if (end) queryParams.push({ key: 'end', value: end.toUTCString() });
    if (page) queryParams.push({ key: 'page', value: page.toString() });
    if (size) queryParams.push({ key: 'size', value: size.toString() });

    return await this.get(`model/${analyticType}`, undefined, queryParams);
  }

  public async modelCount(analyticType: Analytic, modelId?: string, organizationId?: string, start?: Date, end?: Date, page: number = 0, size: number = 0, filter: string = ''): Promise<number> {
    const queryParams: { key: string, value: string }[] = [];
    if (modelId) queryParams.push({ key: 'modelId', value: modelId });
    if (organizationId) queryParams.push({ key: 'organizationId', value: organizationId });
    if (start) queryParams.push({ key: 'start', value: start.toUTCString() });
    if (end) queryParams.push({ key: 'end', value: end.toUTCString() });
    if (page) queryParams.push({ key: 'page', value: page.toString() });
    if (size) queryParams.push({ key: 'size', value: size.toString() });

    return await this.request(`model/${analyticType}/count`, undefined, queryParams);
  }

}

const AnalyticService = new APIAnalyticService();

export default AnalyticService;
