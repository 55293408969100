import { ReactElement } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from "./Home";
import Settings from "../settings/Settings";


export default function Main(): ReactElement {
    let { path } = useRouteMatch();

    return (<div className='home'>
            <div className='homeContainer'>
                <Switch>
                    <Route path={`${path}/settings`} component={Settings} />
                    <Route component={Home} />
                </Switch>
            </div>
        </div> );   
}