import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { ReactNode, useState } from "react";

const downArrow = getAbsoluteUrl("images/downArrow.svg");
const leftArrow = getAbsoluteUrl("images/leftArrow.svg");

interface Props {
  children: ReactNode;
  startExpanded: boolean;
  groupName: string;
}

export const CollapsableGroup = (props: Props) => {
  const [expanded, setExpanded] = useState(props.startExpanded);
  return (
    <>
      <div className="collapsible-properties-header">
        <h5>{props.groupName}</h5>
        <img
          className="expandIcon"
          src={expanded ? downArrow : leftArrow}
          alt={expanded ? "Expand" : "Minimize"}
          title={expanded ? "Expand" : "Minimize"}
          style={{ transform: expanded ? "rotate: 90deg" : "" }}
          onClick={() => setExpanded(!expanded)}
        />
      </div>
      {expanded && props.children}
    </>
  );
};
