import {  ReactElement, useState, useEffect } from 'react'

import { ColorResult, SketchPicker } from 'react-color'
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const blueSlash = getAbsoluteUrl('images/slash.svg');

interface Props {
    color: string | null;
    setColor: (c: string) => void;
    hideTitle?: boolean;
    materialId?: string;
    isPropertyRenderer?: boolean;
}

function parseAlphaToHex(num: number) {
    return (Math.floor(num * 255)).toString(16);
}  

function colorIsTransparent(color: string | null) {
    if(!color) {
        return false;
    }
    if(color.length < 9) {
        return false;
    }
    const alphaStr = color.substring(7, 9);
    return alphaStr === '00';
}

function stripAlpha(color: string) {
    return color.substring(0, 7);
}

export default function ColorBox(props: Props): ReactElement {
    const { color, setColor } = props;
    const [showDropdown, setShowDropdown] = useState(false);
    const [pickerColor, setPickerColor] = useState(color ?? '#FFF');
    const [preTransparentColor, setPreTransparentColor] = useState<string | null>(null);
    const [isTransparent, setIsTransparent] = useState<boolean>(colorIsTransparent(color));

    useEffect(() => {
        setShowDropdown(false)
    }, [props.materialId])

    const display = (color === null) || (color === "") ? 
            <img className={`colorSwatch default ${props.isPropertyRenderer ? 'propertyRenderer' : ''}`} src={blueSlash} alt=''  onClick={() => setShowDropdown(!showDropdown)} /> : 
            <div className={`colorSwatch ${props.isPropertyRenderer ? 'propertyRenderer' : ''}`} style={{backgroundColor: color}}  onClick={() => setShowDropdown(!showDropdown)}/>


    function getColor(color: string, colorResult: ColorResult) {
        let colorValue;
        if(colorResult.hex === 'transparent') {
            // when alpha is set to 0 react-color hex ColorResult is set
            // to transparent. We want to preserve the color that was set
            // before transparent if the user sets transparency to anything
            // but 00 in the future.
            colorValue = (color ?? '#ffffff').substring(0, 7) + '00';
            if(!isTransparent) {
                setPreTransparentColor(color);
                setIsTransparent(true);
            } 
        } else {
            if(isTransparent && preTransparentColor) {
                colorValue = stripAlpha(preTransparentColor) + parseAlphaToHex(colorResult.rgb.a ?? 1);
                setPreTransparentColor(null);
                setIsTransparent(false);
            } else {
                colorValue = colorResult.hex + parseAlphaToHex(colorResult.rgb.a ?? 1);
            }
        }
        return colorValue;
    }
    
    function handleChange(colorResult: ColorResult) {
        setPickerColor(getColor(pickerColor, colorResult));
    }

    function handleChangeComplete(colorResult: ColorResult) {
        setColor(getColor(pickerColor, colorResult));
    }

    return <div className="outerColorBox">
            {!props.hideTitle && <p>Color:</p>}
            {display}
            {showDropdown && <>
                <div className='sketchPickerContainer'>
                    <SketchPicker color={pickerColor || ''} onChange={handleChange} onChangeComplete={handleChangeComplete} disableAlpha={true}/>
                </div>
                <div className='modalOverlay clear' onClick={() => setShowDropdown(false)}></div>
                </>
            }
        </div>;
}
