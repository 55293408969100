import { Cache, Scene } from "three";
import { initAbortController, SceneType } from "@proviz/api-services";
import {
  BaseWidget,
  disposeModel,
  EventDispatcher,
  ProVizScene,
  SceneMode,
} from "@proviz/proviz-sdk";
import Renderer from "./Renderer";
import { Size } from "./Types";
import Background from "./Background";
import Camera from "./Camera";
import CommandBroker, { Command } from "../commands";
import Grid from "./Grid";
import Lights from "./Lights";

export type GraphicsManagerEvents =
  | "selectednode-update"
  | "history-update"
  | "init-done"
  | "background-update"
  | "layout-update"
  | "widgets-updated"
  | "focus-node";

export default class GraphicsManager extends EventDispatcher {
  protected canvas: HTMLCanvasElement;
  renderer: Renderer;
  protected container?: HTMLElement;
  scene: Scene;
  helperScene: Scene;
  background: Background;
  camera: Camera;
  protected grid: Grid;
  proVizScene: ProVizScene;
  broker: CommandBroker;
  lights: Lights;

  dimensions: Size;
  disposed: boolean = false;

  constructor(id: string, sceneType: SceneType) {
    super();
    this.dimensions = { width: 0, height: 0 };
    const { width, height } = this.dimensions;
    Cache.enabled = true; // Must be set true before loader is created for caching
    this.scene = new Scene();
    this.helperScene = new Scene();
    this.camera = new Camera(this.dimensions, { x: 10, y: 10, z: 10 });
    this.renderer = new Renderer(width, height, this.camera.camera, this);
    this.canvas = this.renderer.domElement;
    // tabindex = 0 means that (from mozilla) the element should be focusable in
    // sequential keyboard navigation, after any positive tabindex values and
    // its order is defined by the document's source order.
    this.canvas.tabIndex = 1;
    this.grid = new Grid(this.scene, true);
    this.lights = new Lights(this.scene);
    this.proVizScene = new ProVizScene(
      id,
      this.scene,
      this.camera.camera,
      this.renderer.renderer,
      SceneMode.Editor,
      sceneType,
      initAbortController()
    );

    this.proVizScene.addEventListener("background-update", () => {
      this.background.applySceneSettings(this.proVizScene);
    });
    this.proVizScene.addEventListener("ground-update", () => {
      this.setUpGround(this.proVizScene);
    });
    this.background = new Background(this);
    this.broker = new CommandBroker(this);
  }
  /**
   * The renderer creates a canvas element when it is constructed
   * To make this canvas visible we need to attach it to the dom.
   * So, once a reference to a dom element is available
   * connectToDom adds the canvas to that container.
   */
  public connectToDom(container: HTMLDivElement) {
    this.renderer.connectToDom(container);
    this.dimensions = {
      width: container.clientWidth,
      height: container.clientHeight,
    };
  }

  protected setUpFromScene() {
    this.setUpGround(this.proVizScene);
    this.lights.applySceneSettings(this.proVizScene);
    this.background.applySceneSettings(this.proVizScene);
    this.renderer.attachListeners(this.proVizScene);
  }

  protected setUpGround(proVizScene: ProVizScene) {
    if (this.disposed) return;
    proVizScene.updateGround();
  }

  execute(command: Command) {
    this.broker.execute(command);
  }

  redo() {
    this.broker.redo();
  }

  undo() {
    this.broker.undo();
  }

  add(_obj: BaseWidget, _parent?: BaseWidget, _addToSelected = true) {}

  remove(_node: BaseWidget) {}

  dispose() {
    super.dispose();
    this.proVizScene.dispose();
    this.renderer.dispose();
    this.lights.dispose();
    disposeModel(this.scene);
    disposeModel(this.helperScene);
    this.disposed = true;
    // @ts-ignore
    delete this.proVizScene;
    // @ts-ignore
    delete this.scene;
    // @ts-ignore
    delete this.helperScene;
  }

  getById(id: string) {
    return this.proVizScene.getById(id);
  }

  public postUpdateTask() {}

  /* Wrapper to give us type checking on events */
  attach(event: GraphicsManagerEvents, fn: () => void) {
    this.addEventListener(event, fn);
  }

  /* Wrapper to give us type checking on events */
  detach(event: GraphicsManagerEvents, fn: () => void) {
    this.removeEventListener(event, fn);
  }

  dispatchWidgetUpdate = () =>
    this.dispatchEvent("widgets-updated", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: 'widgets-updated'
    });
  dispatchHistoryUpdate = () =>
    this.dispatchEvent("history-update", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: 'history-update'
    });
  dispatchBackgroundUpdate = () =>
    this.dispatchEvent("background-update", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: 'background-update'
    });

  dispatchSelectionUpdate(doNotUpdateTree?: boolean, selectionId?: string) {
    this.dispatchEvent("selectednode-update", {
      data: doNotUpdateTree,
      dataType: "boolean",
      sourceWidgetId: selectionId ?? "",
      event: 'selectednode-update'
    });
  }

  render() {}

  public toggleGrid() {
    this.grid.toggle();
  }

  public get gridOn() {
    return this.grid.visible;
  }
}
