import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ListWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'list';

  // Data
  public list: string[] = [];

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'List';

    this.usage = 'Flow';
    this.category = 'Events';

    this.widgetType = ListWidget.type;
    this.widgetName = 'List';
    this.selectable = true;
    this.events = [
      {
        name: 'emit',
        label: 'Emit',
      },
      {
        name: 'pop',
        label: 'Pop',
        desc: 'The element popped from the list'
      }
    ];
    this.services = [
      {
        name: 'trigger',
        label: 'Trigger',
      },
    ];
    this.addEventListener('service-trigger', () => {
      this.triggerProVizEvent('emit', 'list', this.list);
    });
    this.addService('Clear', 'clear', 'Clear the list', () => {
      this.list = [];
    });
    this.addService('Add', 'add', 'Add to the list', (event: ProVizEventData) => {
      if (event.dataType === 'string') {
        this.list.push(event.data as string);
        this.triggerProVizEvent('emit', 'list', this.list);
      } else {
        console.warn('Tried to add a non string value to list widget: ', this.label);
      }
    });
    this.addService('Remove', 'remove', 'Remove from the list', (event: ProVizEventData) => {
      if (event.dataType === 'string') {
        this.list = [...this.list.filter(x => x !== (event.data as string))];
        this.triggerProVizEvent('emit', 'list', this.list);
      } else {
        console.warn('Tried to add a non string value to list widget: ', this.label);
      }
    });
    this.addService('Pop', 'pop', 'Pop the last entry from the list', () => {
      if (this.list.length > 1) {
        this.list = this.list.slice(0, this.list.length - 1);
      } else {
        this.list = [];
      }
      this.triggerProVizEvent('emit', 'list', this.list);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('list', 'List', 'Core', 'list', 'list', true)];
  }

  public serialize(): any {
    const result = super.serialize();
    result.list = this.list;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.list = data.list;
  }
}

ModuleService.Register(ListWidget.type, ListWidget);
