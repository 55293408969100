import create from 'zustand'

/********************************************************************* TYPES */
type LoadingType = {
    LoadingText: string;
    setLoadingText: (val:string) => void
}

/********************************************************************* STORE */
export const LoadingStore = create<LoadingType>( (set) => ({
    LoadingText: '',
    setLoadingText: (LoadingText) => set( () =>( {LoadingText} ) ),
}))
