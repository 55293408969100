import React, { ReactElement, useEffect, useState } from 'react'
import { APIModelVariant,  initAbortController,  isAbortError,  ModelService } from "@proviz/api-services"
import { BaseWidget } from "@proviz/proviz-sdk"
import SetWidgetProperty from '../../../commands/SetWidgetProperty';
import { SettableWidgetProperty } from './SettableWidgetProperty'
import { ManagerStore } from '../../../store';

interface NodeWithModelId extends BaseWidget {
    modelId?: string
}

interface Props {
    node: NodeWithModelId;
    property: SettableWidgetProperty;
    header: ReactElement;
}

export const ModelVariantSelector = (props: Props) => {
    const { header, node, property } = props;
    const sceneManager = ManagerStore( s => s.SceneManager )
    const [variants, setVariants] = useState<APIModelVariant[]>([])
    const [, updateState] = React.useState<any>();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const variantValue = property.get();

    useEffect(() => {
        const abortController = initAbortController();
        const updateVariants = async () => {
            if(!node.modelId) {
                return;
            }
            try {
                const model = await ModelService.get(node.modelId, { abortController });
                if (model && model.modelVariants) {
                    setVariants(model.modelVariants);
                } else {
                    setVariants([])
                }
            } catch(e) {
                if (isAbortError(e)) return; // if the query was aborted, do nothing
                console.error(e);
            }
        }
        updateVariants();
        return () => abortController.abort();
    }, [node])

    return (<div className="videoSelector">
        {header}
        <select key={`widget-prop`} value={variantValue} onChange={(e) => {
            sceneManager.execute(new SetWidgetProperty(property, variantValue, e.target.value, sceneManager))
            forceUpdate();
        }}>
            {variants.map((v: APIModelVariant) =>
                <option key={v.id} value={v.id}>{v.materialSet.name}</option>)
            }
        </select>
    </div>);
}
