import { ReactElement } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";


export default function Callback(): ReactElement {
    const { isAuthenticated, isLoading } = useAuth0();
    const history = useHistory();
    if (isLoading) {
        return <div>Loading ...</div>;
    }

    if(isAuthenticated) {
        history.push('/home')
        return(<div></div>)
    } else {
        history.push('/')
    }
    return (
        <div></div>
    )
}
