import { SupportLevel, SupportList } from '../../documentation/WidgetInfoText';

interface Props {
    supportList: SupportList;
}

const parseStatus = (status: SupportLevel) => {
    switch(status) {
        case SupportLevel.Full:
            return "Full";
        case SupportLevel.Partial:
            return "Partial";
        case SupportLevel.None:
            return "None";
        case SupportLevel.Never:
            return "Never";
    }
}

export const CompatibilityTable = (props: Props) => {
    const { supportList } = props;
    return <div className='compatabilityTable'>
            <table>
                <tbody>
                    <tr>
                        <td><h4>Platform</h4></td>
                        <td><h4>Support level</h4></td>
                    </tr>
                    <tr>
                        <td>Studio</td>
                        <td>{parseStatus(supportList.studio)}</td>
                    </tr>
                    <tr>
                        <td>Embed</td>
                        <td>{parseStatus(supportList.embed)}</td>
                    </tr>
                    <tr>
                        <td>Android iOS WebSDK</td>
                        <td>{parseStatus(supportList.androidIosWebSdk)}</td>
                    </tr>
                    <tr>
                        <td>Hololens</td>
                        <td>{parseStatus(supportList.hololens)}</td>
                    </tr>
                    <tr>
                        <td>Oculus</td>
                        <td>{parseStatus(supportList.oculus)}</td>
                    </tr>
                    <tr>
                        <td>Android iOS Unity SDK</td>
                        <td>{parseStatus(supportList.androidIosUnitySdk)}</td>
                    </tr>
                </tbody>
            </table>
        </div>;
}
