import { Color, DoubleSide, LineBasicMaterial, Mesh, ShapeGeometry } from 'three';
import * as THREE from 'three';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { ModelWidget } from '../../';
import { ModuleService } from '../../../moduleService';
import { ProVizEventData, ProVizEventDataTypes, ProVizScene, SceneMode } from '../../..';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { proVizFonts } from '../../../proVizFonts';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { FileService, ProVizConfig } from '@proviz/api-services';

export class CustomWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'custom';
  private static threejsFont: Font;
  private static defferedLoads: (() => void)[] = [];

  // Data
  public customType: string = '';
  public data: string = '';
  public css: string | null = null;
  public js: string | null = null;
  public customEvents: string[] = [];
  public customServices: string[] = [];
  public version = 2;

  public modelId: string | undefined;
  public modelVariantId?: string;

  private displayModel: ModelWidget | undefined = undefined;

  private customWidget: {
    onService: (service: string, dataType: ProVizEventDataTypes, data: any) => void;
  } | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    console.log('custom widget');
    super(scene, parent, notInScene);
    this.widgetType = CustomWidget.type;
    this.widgetName = 'Custom';
    this.label = 'Custom';
    this.category = 'Advanced';
  }

  public async init() {
    console.log('custom widget init');
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.setupDisplayModel();

    if (this.scene.sceneMode === SceneMode.Editor) {
      if (!CustomWidget.threejsFont) {
        CustomWidget.defferedLoads.push(() => this.addPlaceHolder());
        CustomWidget.loadFont();
      } else {
        this.addPlaceHolder();
      }
      return true;
    }

    if (document && document.body) {
      // Custom CSS
      if (this.css) {
        const cssNode = document.createElement('link');
        cssNode.setAttribute('rel', 'stylesheet');
        cssNode.setAttribute('href', this.getPath(this.css));
        document.body.append(cssNode);
      }
      // Custom JS
      if (this.js) {
        await this.initializeJS();
      }
    }

    // hook up services
    this.services.forEach((service) => {
      this.addEventListener(`service-${service.name}`, (evData: ProVizEventData) => {
        if (this.customWidget) {
          this.customWidget.onService(service.name, evData.dataType, evData.data);
        }
      });
    });

    return true;
  }

  private getPath(path: string) {
    if (ProVizConfig.selfContained) {
      const origin = FileService.getOrigin();
      return `${origin}${path.split(':').join('_').split('/').join('_')}`
    }
    return path;
  }

  private initializeJS() {
    return new Promise((resolve, reject) => {

      if (!this.js) {
        return resolve(undefined);
      }

      if (typeof(exports) === 'undefined') {
        // if global exports hasn't been defined, then we declare it here
        // so that commonjs modules can load into it
        global.exports = {};
      }
      
      const jsNode = document.createElement('script');
      jsNode.setAttribute('type', 'module');
      jsNode.setAttribute('src', this.getPath(this.js));
      jsNode.addEventListener('load', () => {
        if (exports[this.customType]) {
          const result = exports[this.customType](this.data, (event: string, dataType: ProVizEventDataTypes, data: any) => {
            this.triggerProVizEvent(`custom-event-${event}`, dataType, data);
          }, {
            scene: this.scene,
            THREE: THREE,
            ProVizConfig: ProVizConfig,
          });
          this.customWidget = result;
          console.log('[Custom Widget]', result);
          resolve(this.customWidget);
        } else {
          console.error('Could not load type', this.customType, 'from script', this.js);
          reject();
        }
      });
      document.body.append(jsNode);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('customType', 'Custom Type', 'Core', 'string', 'string', true),
      this.createProperty('data', 'Data', 'Core', 'multi-line-string', 'string', true),
      this.createProperty('js', 'Javascript', 'Experimental', 'string', 'string', true),
      this.createProperty('css', 'CSS', 'Experimental', 'string', 'string', true),
      this.createProperty(
        'modelId',
        'Editor Reference Model',
        'Experimental',
        'model',
        'string',
        true,
        () => {
          return this.modelId;
        },
        (id: string) => this.setModelId(id),
      ),
      this.createProperty(
        'modelVariantId',
        'Editor Reference Model Variant',
        'Experimental',
        'model-variant',
        'list',
        true,
        () => {
          return this.modelVariantId;
        },
        (id: string) => this.setModelVariantId(id),
      ),
      this.createProperty(
        'customEvents',
        'Custom Events',
        'Core',
        'list',
        'list',
        true,
        undefined,
        (value: string[]) => {
          this.customEvents = value;
          this.events = [];
          this.events = this.customEvents.map((val: string) => {
            return { label: val, name: `custom-event-${val}` };
          });
        },
      ),
      this.createProperty(
        'customServices',
        'Custom Services',
        'Core',
        'list',
        'string',
        true,
        undefined,
        (value: string[]) => {
          this.customServices = value;
          this.services = [];
          this.services = this.customServices.map((val: string) => {
            return { label: val, name: `custom-${val}` };
          });
        },
      ),
    ];
  }

  private async setupDisplayModel() {
    if (this.displayModel) {
      this.displayModel.remove();
    }

    if (this.modelId) {
      this.displayModel = new ModelWidget(this.scene, this);
      this.displayModel.modelId = this.modelId;
      this.displayModel.modelVariantId = this.modelVariantId;
      this.deserialize({
        modelId: this.modelId,
        modelVariantId: this.modelVariantId,
        clickable: false,
        transparent: false,
        opacity: 1.0,
      });
      await this.displayModel.init();
      this.renderNode.add(this.displayModel.renderNode);
    } else {
      this.displayModel = undefined;
    }
  }

  public setModelId(id: string) {
    this.modelId = id;
    this.setupDisplayModel();
  }

  public setModelVariantId(id: string) {
    this.modelVariantId = id;
    this.setupDisplayModel();
  }

  public serialize() {
    const result = super.serialize();
    result.data = this.data;
    result.customType = this.customType;
    result.js = this.js;
    result.css = this.css;
    result.customEvents = this.customEvents;
    result.customServices = this.customServices;
    result.modelId = this.modelId;
    result.modelVariantId = this.modelVariantId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.data = data.data;
    this.customType = data.customType;
    this.customEvents = data.customEvents;
    this.customServices = data.customServices;
    this.events = this.customEvents.map((val: string) => {
      return { label: val, name: `custom-event-${val}` };
    });
    this.services = this.customServices.map((val: string) => {
      return { label: val, name: `custom-${val}` };
    });
    if (data.css) {
      this.css = data.css;
    }
    if (data.js) {
      this.js = data.js;
    }
    this.modelId = data.modelId;
    this.modelVariantId = data.modelVariantId;
  }

  public migrate(data: any): Promise<any> {
    if (!data.version) {
      data.version = 1;
    }

    switch (data.version) {
      case 1:
        data.customEvents = (data.customEvents || '').split(',');
        data.customServices = (data.customServices || '').split(',');
    }
    return data;
  }

  public addPlaceHolder() {
    if (!this.scene) {
      // abort if scene/ this widget have been disposed
      return;
    }
    const shapes = CustomWidget.threejsFont.generateShapes('C', 0.5);
    const geometry = new ShapeGeometry(shapes);
    const matDark = new LineBasicMaterial({
      color: new Color(0x65b473),
      side: DoubleSide,
    });
    const mesh = new Mesh(geometry, matDark);
    this.renderNode.add(mesh);
  }

  public static loadFont() {
    const fontLoader = new FontLoader();
    fontLoader.load(proVizFonts.helvetiker.regular, (threejsFont: Font) => {
      CustomWidget.threejsFont = threejsFont;
      CustomWidget.defferedLoads.forEach((d) => d());
    });
  }
}

ModuleService.Register(CustomWidget.type, CustomWidget);
