export type Size = {
  width: number;
  height: number;
};

export type Position = {
  x: number;
  y: number;
  z: number;
};

export type Scale = {
  x: number;
  y: number;
  z: number;
};

export type Rotation = {
  x: number;
  y: number;
  z: number;
};

const epsilon = 0.0001;
function equal(n1: number, n2: number) {
  return Math.abs(n1 - n2) < epsilon;
}

export function tuplesEqual(s1: Scale | Rotation, s2: Scale | Rotation) {
  return equal(s1.x, s2.x) && equal(s1.y, s2.y) && equal(s1.z, s2.z);
}
