export class Tween {
  // Tweening Function : y = x
  static linear(p: number): number {
    return p;
  }

  // Tweening Function : y = x^2
  static quadraticEaseIn(p: number): number {
    return p * p;
  }

  // Tweening Function : y = -x^2 + 2x
  static quadraticEaseOut(p: number): number {
    return -(p * (p - 2.0));
  }

  // Tweening Function :
  //		y = (1/2)((2x)^2)             ; [0, 0.5)
  //		y = -(1/2)((2x-1)*(2x-3) - 1) ; [0.5, 1]
  static quadraticEaseInOut(p: number): number {
    if (p < 0.5) {
      return 2.0 * p * p;
    }
    return -2.0 * p * p + 4.0 * p - 1;
  }

  // Tweening Function : y = x^3
  static cubicEaseIn(p: number): number {
    return p * p * p;
  }

  // Tweening Function : y = (x - 1)^3 + 1
  static cubicEaseOut(p: number): number {
    const f = p - 1.0;
    return f * f * f + 1.0;
  }

  // Tweening Function :
  //		y = (1/2)((2x)^3)       ; [0, 0.5)
  //		y = (1/2)((2x-2)^3 + 2) ; [0.5, 1]
  static cubicEaseInOut(p: number): number {
    if (p < 0.5) {
      return 4.0 * p * p * p;
    }
    const f = 2.0 * p - 2.0;
    return 0.5 * f * f * f + 1;
  }

  // Tweening Function : y = x^4
  static quarticEaseIn(p: number): number {
    return p * p * p * p;
  }

  // Tweening Function : y = 1 - (x - 1)^4
  static quarticEaseOut(p) {
    const f = p - 1.0;
    return f * f * f * (1.0 - p) + 1;
  }

  // Tweening Function :
  //		y = (1/2)((2x)^4)        ; [0, 0.5)
  //		y = -(1/2)((2x-2)^4 - 2) ; [0.5, 1]
  static quarticEaseInOut(p: number): number {
    if (p < 0.5) {
      return 8.0 * p * p * p * p;
    }
    const f = p - 1.0;
    return -8.0 * f * f * f * f + 1.0;
  }

  // Tweening Function : y = x^5
  static quinticEaseIn(p: number): number {
    return p * p * p * p * p;
  }

  // Tweening Function : y = (x - 1)^5 + 1
  static quinticEaseOut(p: number): number {
    const f = p - 1.0;
    return f * f * f * f * f + 1;
  }

  // Tweening Function :
  //		y = (1/2)((2x)^5)       ; [0, 0.5)
  //		y = (1/2)((2x-2)^5 + 2) ; [0.5, 1]
  static quinticEaseInOut(p: number): number {
    if (p < 0.5) {
      return 16 * p * p * p * p * p;
    }
    const f = 2.0 * p - 2.0;
    return 0.5 * f * f * f * f * f + 1.0;
  }

  // Tweening Function : quarter cycle sin(x)
  static sineEaseIn(p) {
    return Math.sin((p - 1.0) * (Math.PI * 2.0)) + 1.0;
  }

  // Tweening Function : quarter cycle offset sin(x)
  static sineEaseOut(p: number): number {
    return Math.sin(p * (Math.PI * 2.0));
  }

  // Tweening Function : half sine(x)
  static sineEaseInOut(p: number): number {
    return 0.5 * (1.0 - Math.cos(p * (Math.PI * 2.0)));
  }

  // Tweening Function : shifted quadrant IV of unit circle
  static circularEaseIn(p: number): number {
    return 1.0 - Math.sqrt(1.0 - p * p);
  }

  // Tweening Function : shifted quadrant II of unit circle
  static circularEaseOut(p: number): number {
    return Math.sqrt((2.0 - p) * p);
  }

  // Tweening Function :
  //		y = (1/2)(1 - sqrt(1 - 4x^2))           ; [0, 0.5)
  //		y = (1/2)(sqrt(-(2x - 3)*(2x - 1)) + 1) ; [0.5, 1]
  static circularEaseInOut(p: number): number {
    if (p < 0.5) {
      return 0.5 * (1.0 - Math.sqrt(1.0 - 4.0 * (p * p)));
    }
    return 0.5 * (Math.sqrt(-(2.0 * p - 3.0) * (2.0 * p - 1.0)) + 1.0);
  }

  // Tweening Function : y = 2^(10(x - 1))
  static exponentialEaseIn(p: number): number {
    return p === 0.0 ? p : Math.pow(2.0, 10.0 * (p - 1.0));
  }

  // Tweening Function : y = -2^(-10x) + 1
  static exponentialEaseOut(p: number): number {
    return p === 1.0 ? p : 1.0 - Math.pow(2.0, -10.0 * p);
  }

  // Tweening Function :
  //		y = (1/2)2^(10(2x - 1))         ; [0,0.5)
  //		y = -(1/2)*2^(-10(2x - 1))) + 1 ; [0.5,1]
  static exponentialEaseInOut(p: number): number {
    if (p === 0.0 || p === 1.0) return p;

    if (p < 0.5) {
      return 0.5 * Math.pow(2.0, 20.0 * p - 10.0);
    }
    return -0.5 * Math.pow(2.0, -20.0 * p + 10.0) + 1.0;
  }

  // Tweening Function : y = sin(13pi/2*x)*pow(2, 10 * (x - 1))
  static elasticEaseIn(p: number): number {
    return Math.sin(13.0 * (Math.PI * 2.0) * p) * Math.pow(2.0, 10.0 * (p - 1.0));
  }

  // Tweening Function : y = sin(-13pi/2*(x + 1))*pow(2, -10x) + 1
  static elasticEaseOut(p: number): number {
    return Math.sin(-13.0 * (Math.PI * 2.0) * (p + 1.0)) * Math.pow(2.0, -10.0 * p) + 1.0;
  }

  // Tweening Function :
  //		y = (1/2)*sin(13pi/2*(2*x))*pow(2, 10 * ((2*x) - 1))      ; [0,0.5)
  //		y = (1/2)*(sin(-13pi/2*((2x-1)+1))*pow(2,-10(2*x-1)) + 2) ; [0.5, 1]
  static elasticEaseInOut(p: number): number {
    if (p < 0.5) {
      return (
        0.5 * Math.sin(13.0 * (Math.PI * 2.0) * (2.0 * p)) * Math.pow(2.0, 10.0 * (2.0 * p - 1.0))
      );
    }
    return (
      0.5 *
      (Math.sin(-13.0 * (Math.PI * 2.0) * (2.0 * p - 1.0 + 1.0)) *
        Math.pow(2.0, -10.0 * (2.0 * p - 1.0)) +
        2.0)
    );
  }

  // Tweening Function : y = x^3-x*sin(x*pi)
  static backEaseIn(p: number): number {
    return p * p * p - p * Math.sin(p * Math.PI);
  }

  // Tweening Function : y = 1-((1-x)^3-(1-x)*sin((1-x)*pi))
  static backEaseOut(p: number): number {
    const f = 1.0 - p;
    return 1.0 - (f * f * f - f * Math.sin(f * Math.PI));
  }

  // Tweening Function :
  //		y = (1/2)*((2x)^3-(2x)*sin(2*x*pi))           ; [0, 0.5)
  //		y = (1/2)*(1-((1-x)^3-(1-x)*sin((1-x)*pi))+1) ; [0.5, 1]
  static backEaseInOut(p: number): number {
    if (p < 0.5) {
      const f = 2.0 * p;
      return 0.5 * (f * f * f - f * Math.sin(f * Math.PI));
    }
    const f = 1.0 - (2.0 * p - 1.0);
    return 0.5 * (1.0 - (f * f * f - f * Math.sin(f * Math.PI))) + 0.5;
  }

  // Tween Function : Bounce at Hard Preset Points
  static bounceEaseOut(p: number): number {
    if (p < 4.0 / 11.0) {
      return (121.0 * p * p) / 16.0;
    } else if (p < 8.0 / 11.0) {
      return (363.0 / 40.0) * p * p - (99.0 / 10.0) * p + 17.0 / 5.0;
    } else if (p < 9.0 / 10.0) {
      return (4356.0 / 361.0) * p * p - (35442.0 / 1805.0) * p + 16061.0 / 1805.0;
    }
    return (54.0 / 5.0) * p * p - (513.0 / 25.0) * p + 268.0 / 25.0;
  }

  // Tween Function : Bounce Backwards at Hard Preset Points
  static bounceEaseIn(p: number): number {
    return 1.0 - this.bounceEaseOut(1.0 - p);
  }

  // Tween Function : Bounce at Hard Preset Points
  static bounceEaseInOut(p: number): number {
    if (p < 0.5) {
      return 0.5 * this.bounceEaseIn(p * 2.0);
    }
    return 0.5 * this.bounceEaseOut(p * 2.0 - 1) + 0.5;
  }

  static tween(type: TweenType, p: number) {
    return TweenMap[type](p);
  }

  static tweenValue(type: TweenType, valStart: number, valEnd: number, p: number) {
    return valStart + TweenMap[type](p) * (valEnd - valStart);
  }
}

export enum TweenType {
  BounceEaseIn,
  BounceEaseInOut,
  BounceEaseOut,
  Linear,
  QuadraticEaseIn,
  QuadraticEaseOut,
  QuadraticEaseInOut,
  CubicEaseIn,
  CubicEaseOut,
  CubicEaseInOut,
  QuarticEaseIn,
  QuarticEaseOut,
  QuarticEaseInOut,
  QuinticEaseIn,
  QuinticEaseOut,
  QuinticEaseInOut,
  SineEaseIn,
  SineEaseOut,
  SineEaseInOut,
  CircularEaseIn,
  CircularEaseOut,
  CircularEaseInOut,
  ExponentialEaseIn,
  ExponentialEaseOut,
  ExponentialEaseInOut,
  ElasticEaseIn,
  ElasticEaseOut,
  ElasticEaseInOut,
  BackEaseIn,
  BackEaseOut,
  BackEaseInOut,
}

const TweenMap: { [key: number]: (p: number) => number } = {};
TweenMap[TweenType.BounceEaseIn] = Tween.bounceEaseIn;
TweenMap[TweenType.BounceEaseInOut] = Tween.bounceEaseInOut;
TweenMap[TweenType.BounceEaseOut] = Tween.bounceEaseOut;
TweenMap[TweenType.Linear] = Tween.linear;
TweenMap[TweenType.QuadraticEaseIn] = Tween.quadraticEaseIn;
TweenMap[TweenType.QuadraticEaseOut] = Tween.quadraticEaseOut;
TweenMap[TweenType.QuadraticEaseInOut] = Tween.quadraticEaseInOut;
TweenMap[TweenType.CubicEaseIn] = Tween.cubicEaseIn;
TweenMap[TweenType.CubicEaseOut] = Tween.cubicEaseOut;
TweenMap[TweenType.CubicEaseInOut] = Tween.cubicEaseInOut;
TweenMap[TweenType.QuarticEaseIn] = Tween.quarticEaseIn;
TweenMap[TweenType.QuarticEaseOut] = Tween.quarticEaseOut;
TweenMap[TweenType.QuarticEaseInOut] = Tween.quarticEaseInOut;
TweenMap[TweenType.QuinticEaseIn] = Tween.quinticEaseIn;
TweenMap[TweenType.QuinticEaseOut] = Tween.quinticEaseOut;
TweenMap[TweenType.QuinticEaseInOut] = Tween.quinticEaseInOut;
TweenMap[TweenType.SineEaseIn] = Tween.sineEaseIn;
TweenMap[TweenType.SineEaseOut] = Tween.sineEaseOut;
TweenMap[TweenType.SineEaseInOut] = Tween.sineEaseInOut;
TweenMap[TweenType.CircularEaseIn] = Tween.circularEaseIn;
TweenMap[TweenType.CircularEaseOut] = Tween.circularEaseOut;
TweenMap[TweenType.CircularEaseInOut] = Tween.circularEaseInOut;
TweenMap[TweenType.ExponentialEaseIn] = Tween.exponentialEaseIn;
TweenMap[TweenType.ExponentialEaseOut] = Tween.exponentialEaseOut;
TweenMap[TweenType.ExponentialEaseInOut] = Tween.exponentialEaseInOut;
TweenMap[TweenType.ElasticEaseIn] = Tween.elasticEaseIn;
TweenMap[TweenType.ElasticEaseOut] = Tween.elasticEaseOut;
TweenMap[TweenType.ElasticEaseInOut] = Tween.elasticEaseInOut;
TweenMap[TweenType.BackEaseIn] = Tween.backEaseIn;
TweenMap[TweenType.BackEaseOut] = Tween.backEaseOut;
TweenMap[TweenType.BackEaseInOut] = Tween.backEaseInOut;
