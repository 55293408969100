import { TextWidget } from './textWidget';

export class TextWidgetStudio extends TextWidget {
  public async init(): Promise<boolean> {
    super.init();

    // add wireframe

    return true;
  }
}
