import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class NoteWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'note';

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = NoteWidget.type;
    this.widgetName = 'Note';
    this.label = 'Note';
    this.usage = 'Flow';
    this.category = 'Others';
  }
}
ModuleService.Register(NoteWidget.type, NoteWidget);
