import { MultiLangOption, ProVizScene, SceneMode } from '../../..';
import { FileService } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { defaultIcon } from './defaultIcon';
export class Button2D extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'button-2d';
  private el?: HTMLElement;

  // Data
  public buttonIcon: MultiLangOption = {};
  public hoverText: MultiLangOption = {};
  public scale: number = 1;
  public useBorder: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = Button2D.type;
    this.widgetName = '2D Button';
    this.category = 'UI';

    this.label = '2D Button';

    this.events.push({
      name: 'clicked',
      label: 'Clicked',
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('buttonIcon', 'Icon', 'Core', 'image-options', 'string', true),
      this.createProperty('hoverText', 'Button Label', 'Core', 'multi-lang-opts', 'string', true),
      this.createProperty('scale', 'Scale', 'Core', 'number', 'number', true),
      this.createProperty('useBorder', 'Border', 'Core', 'bool', 'boolean', true),
    ];
  }

  public serialize() {
    const result = super.serialize();
    result.buttonIcon = this.buttonIcon;
    result.hoverText = this.hoverText;
    result.scale = this.scale;
    result.useBorder = this.useBorder;
    return result;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.buttonIcon = data.buttonIcon ?? this.buttonIcon;
    this.hoverText = data.hoverText ?? this.hoverText;
    this.scale = data.scale ?? this.scale;
    this.useBorder = data.useBorder ?? this.useBorder;
  }

  private setButtonStyle() {
    if (this.el) {
      if (!this.visible) {
        this.el.setAttribute('style', 'display: none;');
      } else {
        let style = `
          height: ${40 * this.scale}px;
          max-width: ${50 * this.scale}px;
          border-radius: ${50 * this.scale}px;
        `;
        this.el.setAttribute('style', style);
      }
    }
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    if (this.scene.sceneMode === SceneMode.Editor) {
      // it doesn't make sense to show this type of button in the editor
      return true;
    }

    const container = document.getElementById('proviz-button-container');
    container?.setAttribute('style', `height: ${50 * this.scale}px`);

    this.el = document.createElement('img');
    this.el.setAttribute('class', `proviz-button button-2d ${this.useBorder ? 'bordered' : ''}`);
    this.setButtonStyle();

    const currFileId =
      this.buttonIcon[this.scene.selectedLanguage] ??
      this.buttonIcon[this.scene.defaultLanguage] ??
      '';
    const file = await FileService.get(currFileId);
    const src = FileService.getLocation(file);
    const base64LinkImage = defaultIcon;

    this.el.setAttribute('src', src ? src : base64LinkImage);

    this.el.title =
      this.hoverText[this.scene.selectedLanguage] ??
      this.hoverText[this.scene.defaultLanguage] ??
      '';
    this.el.addEventListener('click', () => {
      this.triggerProVizEvent('clicked', 'none');
    });
    this.scene.addEventListener('language-change', async () => {
      if (!this.el) {
        return;
      }
      this.el.title =
        this.hoverText[this.scene.selectedLanguage] ??
        this.hoverText[this.scene.defaultLanguage] ??
        '';
      const fileId =
        this.buttonIcon[this.scene.selectedLanguage] ??
        this.buttonIcon[this.scene.defaultLanguage] ??
        '';
      if (fileId !== currFileId) {
        const file = await FileService.get(fileId);
        const src = FileService.getLocation(file);
        this.el.setAttribute('src', src);
      }
    });

    const imgContainer = document.createElement('div');
    imgContainer.setAttribute('class', 'proviz-button-image-container');
    imgContainer.appendChild(this.el);

    container?.appendChild(imgContainer);
    return true;
  }

  public setVisible(state: boolean): void {
    super.setVisible(state);
    if (!this.el) {
      return;
    }
    this.setButtonStyle();
  }
}

ModuleService.Register(Button2D.type, Button2D);
