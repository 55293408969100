import { BufferGeometry, Group, Mesh, MeshBasicMaterial, Object3D, Vector2, Vector3 } from 'three';
import { Helpers, SceneMode } from '..';
import { ProVizScene } from '../ProVizScene';
import { BaseWidgetDataConnection, connectionsEqual } from './BaseWidgetDataConnection';
import { IBaseWidgetEvent } from './BaseWidgetEvent';
import { BaseWidgetProperty } from './BaseWidgetProperty';
import { IBaseWidgetService } from './BaseWidgetService';
import { IBaseWidgetType } from './IBaseWidgetType';
import { WidgetUsage } from './WidgetUsage';
import { EventDispatcher } from '../EventDispatcher';
import { PropertyCategory, WidgetPropertyType } from '.';
import {
  ProVizEventData,
  ProVizEventDataTypes,
  ProVizEventDataValueTypes,
} from '../ProVizEventData';
import { ResourceLink } from '@proviz/api-services';
import _ from 'lodash';
import { AnimationTrack } from './AnimationTrack';
import { AnimationController } from './AnimationController';
type WidgetCategories =
  | 'Core'
  | 'Advanced'
  | 'Experimental'
  | 'Integration'
  | 'Flow'
  | 'Conditional'
  | 'Events'
  | 'Position'
  | 'Time'
  | 'Others'
  | 'Lights'
  | 'Targets'
  | 'Colliders'
  | 'ARCS'
  | 'UI'
  | 'Animation'
  | 'MRTK'
  | 'Database'
  | 'Unlisted';

interface WidgetDefinitionEvent {
  label: string;
  dataType: string;
  key?: string;
  desc?: string;
}
interface WidgetDefinitionService {
  label: string;
  dataType: ProVizEventDataTypes | ProVizEventDataTypes[];
  key?: string;
  desc?: string;
}
interface WidgetDefinitionProperty {
  label: string;
  dataType: WidgetPropertyType;
  category: PropertyCategory;
  key?: string;
  desc?: string;
  propertyType?: ProVizEventDataTypes;
  editable?: boolean;
  default?: any;
  infoText?: string;
}
export interface WidgetDefinition {
  label: string;
  type: string;
  events: WidgetDefinitionEvent[];
  services: WidgetDefinitionService[];
  properties: WidgetDefinitionProperty[];
}

export class BaseWidget extends EventDispatcher implements IBaseWidgetType {
  // Data
  public id: string = Helpers.uuidv4();
  public label: string = 'Base Widget';
  public note: string = '';
  public showNote: boolean = false;
  public connections: BaseWidgetDataConnection[] = [];
  public flowPosition: Vector2 = new Vector2();
  public showInFlow: boolean = false;
  public visible: boolean = true;
  public editorVisible: boolean = true;
  public editorLocked: boolean = false;
  public editorMinimized: boolean = false;
  public version: number = 1;
  public clickable: boolean = false;
  public animationTrack: AnimationTrack[] = [new AnimationTrack(this)];

  public jsonData: any = {};

  public widgetType: string = '';
  public widgetName: string = '';
  /** Whether the widget may be selected in the editor. */
  public selectable: boolean = false;
  public category: WidgetCategories = 'Core';
  /** Child widgets */
  public children: BaseWidget[] = [];
  public parent?: BaseWidget = undefined;
  public usage: WidgetUsage = '3D';
  /**
   * Whether the widget can be dragged to a different position in the node hierarchy.
   * note that widgets that cannot be repositioned may be dragged on to the flow editor.
   * */
  public canReposition: boolean = true;

  public properties: BaseWidgetProperty[] = [];
  public services: IBaseWidgetService[] = [];
  public events: IBaseWidgetEvent[] = [];

  public renderNode: Group | Object3D = new Group();
  public physicsBounds?: Mesh<BufferGeometry, MeshBasicMaterial>;
  public inRange: boolean = false;
  public isInitialized: boolean = false;

  protected allowedWidgets: string[] = [];

  protected scene: ProVizScene;

  protected activeAnimation: AnimationController | undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super();

    this.scene = scene;
    this.parent = parent;

    this.renderNode.userData.widget = this;
    this.renderNode.renderOrder = 2;
    this.renderNode.visible = false;

    this.properties = this.loadWidgetProperties();

    this.services.push({
      label: 'Hide',
      name: 'hide',
      desc: 'Hide this widget from rendering in 3D',
    });
    this.addEventListener('service-hide', () => {
      this.setVisible(false);
    });

    this.services.push({
      label: 'Show',
      name: 'show',
      desc: 'Show this widget in 3D',
    });
    this.addEventListener('service-show', () => {
      this.setVisible(true);
    });

    this.addService(
      'Play animation Track',
      'play-animation-track',
      'plays the selected track or plays the paused/stopped animation',
      (event: ProVizEventData) => {
        if (event.dataType === 'number') {
          let track =
            (event.data as number) < this.animationTrack.length
              ? (event.data as number)
              : this.animationTrack.length - 1;
          this.activeAnimation = new AnimationController(this.animationTrack[track], this);
        } else if (event.dataType === 'none' && this.activeAnimation) {
          this.activeAnimation.play();
        }
      },
    );

    this.addService(
      'Pause Animation Track',
      'pause-animation-track',
      'Pauses the running animation track',
      () => {
        if (this.activeAnimation) {
          this.activeAnimation.pause();
        }
      },
    );

    this.addService(
      'Stop Animation Track',
      'stop-animation-track',
      'Stops the running animation track',
      () => {
        if (this.activeAnimation) {
          this.activeAnimation.stop();
        }
      },
    );
  }

  protected setup(def: WidgetDefinition) {
    def.events.forEach((e) => {
      this.events.push({
        label: e.label,
        name: this.keyToService(e.key ?? e.label),
        desc: e.desc,
      });
    });

    this.widgetType = def.type;

    def.services.forEach((s) => {
      this.services.push({
        label: s.label,
        name: this.keyToService(s.label),
        desc: s.desc,
      });
    });

    def.properties.forEach((p) => {
      this.properties.push(
        this.createProperty(
          p.key || this.keyToCamelCase(p.label),
          p.label,
          p.category,
          p.dataType,
          p.propertyType || 'string',
          p.editable ?? true,
          undefined, // p.get && this[p.get] ? this[p.get].bind(this) : undefined,
          undefined, // p.set && this[p.set] ? this[p.set].bind(this) : undefined,
          undefined, // p.options && this[p.options] ? this[p.options].bind(this) : undefined,
          p.desc,
        ),
      );
    });
  }

  public debugState(state: boolean) {
    this.children.forEach((c) => { c.debugState(state); });
  }

  protected keyToCamelCase(key: string) {
    return _.camelCase(key);
    // const parts = key.split(' ');
    // if (parts.length === 0) {
    //   return '';
    // }

    // // turn words into camel case variable
    // // ex Text Content => textContent
    // return parts.map((part, ind) => {
    //   const firstLetter = ind === 0 ?
    //     part[0].toLowerCase() :
    //     part[0].toUpperCase();
    //     if (part.length > 1) {
    //       return firstLetter + part.substring(1);
    //     } else {
    //       return firstLetter;
    //     }
    // }).join('');
  }

  protected keyToService(key: string) {
    return key.split(' ').join('-').toLowerCase();
  }

  protected addServiceHandler(key: string, cb: (event: ProVizEventData) => void, desc?: string) {
    this.addEventListener(`service-${this.keyToService(key)}`, cb);
  }

  protected attachToProperty(
    key: string,
    attachments: {
      set?: (data: any, updateNodes?: (() => void) | undefined) => void;
      get?: () => any;
      options?: () => { key: any; label: string }[];
    },
  ) {
    const prop = this.getProperty(key);
    if (!prop) {
      console.error(
        `Could not find property ${key} to assign attachments. Available options:`,
        this.properties.map((x) => x.name),
      );
      return;
    }

    if (attachments.set) {
      prop.set = attachments.set;
    }
    if (attachments.get) {
      prop.get = attachments.get;
    }
    if (attachments.options) {
      prop.options = attachments.options;
    }
  }

  protected getProperty(key: string) {
    return this.properties.find((x) => x.name === this.keyToCamelCase(key));
  }

  protected getPropertyValue(key: string) {
    return this[this.keyToCamelCase(key)];
  }

  protected setPropertyValue(key: string, value: any) {
    this[this.keyToCamelCase(key)] = value;
  }

  protected addService(
    label: string,
    name: string,
    desc: string,
    callback: (event: ProVizEventData) => void,
  ) {
    this.services.push({
      label,
      name,
      desc,
    });
    this.addEventListener(`service-${name}`, callback);
  }

  public update(delta: number) {
    if (this.activeAnimation) {
      this.activeAnimation.update(delta);
    }
    this.children.forEach((c) => c.update(delta));
  }

  public setVisible(state: boolean) {
    this.visible = state;
    if (state && !this.isInitialized) {
      this.init();
    }
    this.renderNode.visible = state && this.editorVisible;
  }

  public setEditorVisible(state: boolean) {
    this.editorVisible = state;
    this.renderNode.visible = state && this.visible;
  }

  public setEditorLocked(state: boolean) {
    this.editorLocked = state;
  }

  public setShowNote(state: boolean) {
    this.showNote = state;
  }

  public remove() {
    this.unparent();
  }

  public unparent() {
    if (this.parent) {
      this.parent.removeNode(this);
    }
    if (this.renderNode && this.renderNode.parent) {
      this.renderNode.parent.remove(this.renderNode);
      //Force the render node's positioning values within the world to stay the same when it has no parent.
      //Might be able to use this if the above bug with attach shows itself
      this.renderNode.matrixWorld.decompose(
        this.renderNode.position,
        this.renderNode.quaternion,
        this.renderNode.scale,
      );
    }
  }

  public setShadowed(node?: Object3D, receive: boolean = true, cast: boolean = true) {
    if (!node) {
      return this.setShadowed(this.renderNode);
    }
    node.receiveShadow = true;
    node.castShadow = true;
    node.children.forEach((o) => this.setShadowed(o));
  }

  public setParent(node: BaseWidget) {}

  public removeNode(node: BaseWidget) {
    this.children = this.children.filter((x) => x !== node);
  }

  /**
   *
   */
  public async init(): Promise<boolean> {
    if (this.isInitialized) {
      return false;
    }
    console.warn(this.widgetName, this.label, 'init');
    // console.log(`%c '${this.label}' initializing'`, 'background: #000; color: #fff');
    // So, when widgets are set to visible init is called again and all children
    // that may not have initially been initialized are set to be visible
    // Is it a probelm if a child widget is set visible before its parent
    this.renderNode.visible = this.visible;
    if (this.visible) {
      // We only actually initialize widgets or their children if they are visible
      this.isInitialized = true;
      this.triggerProVizEvent(`${this.id}-widget-source`, 'widgetId', this.id);
      this.children.forEach((w) => w.init());
    }
    return this.isInitialized;
  }

  public addChild(widget: BaseWidget, attach?: boolean): boolean {
    if (this.allowedWidgets.length > 0 && this.allowedWidgets.indexOf(widget.widgetType) === -1) {
      throw new Error(
        `Widget of type '${widget.widgetType}' is not allowed in '${this.widgetType}'`,
      );
    }

    widget.parent = this;
    this.children.push(widget);
    if (attach) {
      // Allegedly, there's something wrong with this "goofing up the tree", but I'm not sure where or why.
      this.renderNode.attach(widget.renderNode);
    } else {
      this.renderNode.add(widget.renderNode);
    }

    widget.setParent(this);

    return true;
  }

  public addChildAfter(widget: BaseWidget, other: BaseWidget, attach?: boolean): boolean {
    if (this.allowedWidgets.length > 0 && this.allowedWidgets.indexOf(widget.widgetType) === -1) {
      throw new Error(
        `Widget of type '${widget.widgetType}' is not allowed in '${this.widgetType}'`,
      );
    }

    widget.parent = this;

    const indexOfOther = this.children.indexOf(other);
    if (indexOfOther < 0) {
      throw new Error('Not a child of this widget');
    }

    this.children.splice(indexOfOther, 0, widget);

    // this.children.push(widget);
    if (attach) {
      // Allegedly, there's something wrong with this "goofing up the tree", but I'm not sure where or why.
      this.renderNode.attach(widget.renderNode);
    } else {
      this.renderNode.add(widget.renderNode);
    }

    widget.setParent(this);

    return true;
  }
  /**
   * Migrate modifies the data it receives in order
   * to reformulate it into the shape that the current version of the software expects.
   * It uses a switch statement and should NOT include any break stateements.
   *
   * It should Not have any break statements because it applies a
   * migration for each version after the data's version.
   * This takes advantage of the fall through behavior of switch statements.
   *  */
  public async migrate(data: any): Promise<any> {
    return data;
  }

  public contains(object: Object3D) {
    return this._contains(this.renderNode, object);
  }

  public getSelectables(): Object3D[] {
    const result: Object3D[] = [];
    if (!this.editorLocked && this.selectable && this.renderNode && this.renderNode.visible) {
      result.push(this.renderNode);
    }
    for (const child of this.children) {
      const childSelectables = child.getSelectables();
      for (const childSelectable of childSelectables) {
        result.push(childSelectable);
      }
    }
    return result;
  }

  public getWidgetsOfType(t: string): BaseWidget[] {
    const result: BaseWidget[] = [];
    if (this.widgetType === t) {
      result.push(this);
    }

    for (const child of this.children) {
      const childWidgets = child.getWidgetsOfType(t);
      for (const childWidgetOfType of childWidgets) {
        result.push(childWidgetOfType);
      }
    }
    return result;
  }

  public getIdsWithConnections() {
    const result: string[] = [];
    if (this.connections.length > 0) {
      result.push(this.id);
      for (const conn of this.connections) {
        result.push(conn.targetId);
      }
    }
    for (const child of this.children) {
      const ids = child.getIdsWithConnections();
      for (const id of ids) {
        result.push(id);
      }
    }
    return result;
  }

  public getIdsWithFlow() {
    const result: string[] = [];
    if (this.showInFlow || this.connections.length > 0) {
      result.push(this.id);
      for (const conn of this.connections) {
        result.push(conn.targetId);
      }
    }
    for (const child of this.children) {
      const ids = child.getIdsWithFlow();
      for (const id of ids) {
        result.push(id);
      }
    }
    return result;
  }

  public getById(id: string): BaseWidget | undefined {
    if (this.id === id) {
      return this;
    }

    for (const child of this.children) {
      const result: BaseWidget | undefined = child.getById(id);
      if (result) {
        return result;
      }
    }

    return undefined;
  }

  // Clickable when in embed
  // Use Selectable for Editor
  public getClickable(): Object3D[] {
    let result: Object3D[] = [];
    if (!this.visible) {
      return result;
    }
    // The root render node should not be used
    // Each widget should define its own clickable node
    // and override the getClickable function
    // if (this.clickable && this.renderNode) {
    //   result.push(this.renderNode);
    // }
    for (const child of this.children) {
      result = result.concat(child.getClickable());
    }
    return result;
  }

  public verifyClick(point: Vector3, uv: Vector2) {
    return true;
  }

  public getHoverable(): Object3D[] {
    let result: Object3D[] = [];
    if (!this.visible) {
      return result;
    }
    for (const child of this.children) {
      result = result.concat(child.getHoverable());
    }
    return result;
  }

  public onHoverEnter() {}

  public onHoverLeave() {}

  public loadWidgetProperties(): BaseWidgetProperty[] {
    return [
      this.createProperty(
        'label',
        'Label',
        'Core',
        'string',
        'string',
        true,
        () => this.label,
        (data: any) => {
          this.label = data;
        },
      ),
      this.createProperty(
        'note',
        'Note',
        'Core',
        'multi-line-string',
        'string',
        true,
        () => this.note,
        (data: any) => {
          this.note = data;
        },
      ),
      this.createProperty(
        'visible',
        'Visible',
        'Core',
        'bool',
        'boolean',
        true,
        () => this.visible,
        (data: any) => {
          this.setVisible(data);
        },
      ),
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    return this.properties;
  }

  /**
   * Widget implementations will never call deserialize or serialize for themselves.
   * this code will be called by the init function.
   */
  public serialize(): any {
    const data: any = this.jsonData ?? {};
    data.id = this.id;
    data.visible = this.visible;
    data.editorLocked = this.editorLocked;
    data.editorVisible = this.editorVisible;
    data.type = this.widgetType;
    data.label = this.label;
    data.note = this.note;
    data.showNote = this.showNote;
    data.version = this.version;
    data.editorMinimized = this.editorMinimized;
    data.connections = [];
    const filteredConns: BaseWidgetDataConnection[] = [];
    for (const conn of this.connections) {
      if (!filteredConns.find((c) => connectionsEqual(conn, c))) {
        filteredConns.push(conn);
      }
    }
    for (const conn of filteredConns) {
      data.connections.push(conn.serialize());
    }
    data.flowPosition = {
      x: this.flowPosition.x,
      y: this.flowPosition.y,
    };
    data.showInFlow = this.showInFlow || this.connections.length > 0;
    data.children = this.children.map((w: BaseWidget) => w.serialize());
    data.animationTrack = this.animationTrack.map((t: AnimationTrack) => {
      return { time: t.time, animationData: t.animationData };
    });

    console.log('props', this.properties);
    this.properties.forEach((prop) => {
      data[prop.name] = this[prop.name];
    });

    return data;
  }

  /**
   * Widget implementations will never call deserialize or serialize for themselves.
   * this code will be called by the init function.
   */
  public deserialize(data: any) {
    if (this.scene.sceneMode === SceneMode.Editor) {
      this.jsonData = Object.assign({}, data);
      this.jsonData.children = [];
    }
    this.id = data.id || Helpers.uuidv4();
    // this.widgetType = data.type;
    this.label = data.label;
    this.note = data.note;
    this.showNote = data.showNote;
    if (data.visible !== undefined) this.visible = data.visible;
    this.editorLocked = data.editorLocked || false;
    this.editorVisible = data.editorVisible || true;
    this.editorMinimized = data.editorMinimized ?? false;
    if (data.animationTrack) {
      this.animationTrack = [];
      for (let animationTrack of data.animationTrack) {
        this.animationTrack.push(
          new AnimationTrack(this, animationTrack.time, animationTrack.animationData),
        );
      }
    }
    if (this.renderNode) {
      this.renderNode.visible = data.visible !== undefined ? data.visible : true;
    }
    this.connections = [];
    if (data.connections) {
      for (const conn of data.connections) {
        const c = new BaseWidgetDataConnection(conn);
        this.connections.push(c);
      }
    }
    if (this.scene.sceneMode === SceneMode.Editor) {
      // Flow data is only necessary in the editor
      this.flowPosition =
        (data.flowPosition && new Vector2(data.flowPosition.x, data.flowPosition.y)) ||
        new Vector2();
      this.showInFlow = data.showInFlow || this.connections.length > 0;
    }
    this.renderNode.name = this.label;

    this.properties.forEach((p) => {
      this[p.name] = data[p.name] ?? p.label;
    });
  }

  /**
   * Clean up any resources used by this widget.
   * We ignore the typescript errors and delete references to the rendernode, the scene and their children.
   * If you are using any of these properties asynchronously take care to check that they still exist.
   */
  public dispose() {
    this.removeEventListeners();
    if (this.renderNode.parent) {
      this.renderNode.parent.remove(this.renderNode);
    }
    for (let i = 0; i < this.children.length; i++) {
      this.children[i].dispose();
      delete this.children[i];
    }
    // this.children.forEach(c => c.dispose());
    // @ts-ignore
    delete this.renderNode;
    // @ts-ignore
    delete this.scene;
  }

  public getServices() {
    return this.services;
  }

  public getEvents() {
    return this.events;
  }

  protected createProperty(
    name: string,
    label: string,
    category: PropertyCategory,
    widgetType: WidgetPropertyType,
    dataType: ProVizEventDataTypes,
    editable?: boolean,
    get?: () => any,
    set?: (data: any, updateNodes?: () => void) => void,
    options?: () => { key: any; label: string }[],
    infoText?: string,
  ) {
    const prop = new BaseWidgetProperty(
      name,
      label,
      category,
      widgetType,
      dataType,
      editable,
      infoText,
    );
    prop.get = get || (() => this[name]);
    prop.set =
      set ||
      ((data: string) => {
        this[name] = data;
      });
    prop.options = options;
    return prop;
  }

  /**
   * Trigger a proviz scene event
   */
  protected triggerProVizEvent(
    ev: string,
    dataType: ProVizEventDataTypes,
    data?: ProVizEventDataValueTypes,
  ) {
    if (this.scene.sceneMode === SceneMode.Editor) return;

    this.dispatchEvent(ev, {
      data,
      dataType,
      sourceWidgetId: this.id,
      event: ev,
    });

    console.log(`%c '${ev}' event emit from '${this.label}'`, 'background: #000; color: #ff0');

    let eventHandled = false;
    for (const conn of this.connections.filter((x) => x.event === ev)) {
      eventHandled = true;
      this.scene.dispatchService(conn.targetId, conn.targetService, conn.data, {
        data,
        dataType,
        sourceWidgetId: this.id,
        event: ev,
      });
    }
    if (!eventHandled) {
      console.log(
        `%c '${ev}' event from '${this.label}' not handled `,
        'background: #000; color: #0ff',
        this.connections,
      );
    }
  }

  protected getAllKeyNames(o: Object3D) {
    const result: string[] = [];
    if (o.userData.keyName) {
      result.push(o.userData.keyName);
    }
    o.children.forEach((c: Object3D) => {
      const cs = this.getAllKeyNames(c);
      cs.forEach((ch) => result.push(ch));
    });
    return result;
  }
  /**
   * Adds a unique name to each threejs object that is based off of the
   * the names of its parents
   */
  protected setupKeyNames(o: Object3D, names: { [key: string]: number }) {
    if (!names[o.name]) {
      names[o.name] = -1;
    }
    names[o.name]++;
    o.userData.keyName = `${o.name}:${names[o.name]}`;
    o.children.forEach((c) => this.setupKeyNames(c, names));
  }

  private _contains(parent: Object3D, object: Object3D) {
    if (parent.id === object.id) {
      return true;
    }
    for (const child of parent.children) {
      if (this._contains(child, object)) {
        return true;
      }
    }
    return false;
  }

  public async getResourceLinks(): Promise<ResourceLink[]> {
    const result: ResourceLink[] = [];
    for (let i = 0; i < this.children.length; i++) {
      const childResources = await this.children[i].getResourceLinks();
      result.push(...childResources);
    }
    return result;
  }

  public addAnimationTrack() {
    this.animationTrack.push(new AnimationTrack(this));
  }

  public dispatchEventTree(event: string, data: ProVizEventData) {
    this.dispatchEvent(event, data);
    this.children.forEach((c) => c.dispatchEventTree(event, data));
  }
}
