import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class JsonWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'json';

  // Data
  public fields: string[] = [];

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'JSON Parser';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = JsonWidget.type;
    this.widgetName = 'JSON Parser';
    this.selectable = true;
    this.services = [
      {
        label: 'Parse',
        name: 'parse',
        desc: 'Recieve a json string to parse',
      },
    ];

    this.addEventListener('service-parse', (e: ProVizEventData) => {
      if (e.dataType === 'object') {
        const data = e.data as any;
        if (!data) {
          return;
        }
        for (let field of this.events) {
          if (data[field.name] !== undefined) {
            // If the json we parse has a field with this name
            // we emit an event with the value that it maps to as the
            // event's data
            if (typeof data[field.name] === 'object') {
              this.triggerProVizEvent(field.name, 'object', data[field.name]);
            } else if (typeof data[field.name] === 'string') {
              this.triggerProVizEvent(field.name, 'string', data[field.name]);
            } else if (typeof data[field.name] === 'number') {
              this.triggerProVizEvent(field.name, 'number', data[field.name]);
            } else {
              console.error(
                'Unsupported output type for json widget: ',
                typeof data[field.name],
                data[field.name],
              );
            }
          }
        }
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'fields',
        'Fields To Parse',
        'Core',
        'list',
        'list',
        true,
        undefined,
        (data, updateNodes) => {
          this.fields = data ?? [];
          this.events = this.fields.map((f) => {
            return { label: f, name: f };
          });
          updateNodes && updateNodes();
        },
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.fields = this.fields;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    if (data.fields && !Array.isArray(data.fields)) {
      // If the data gets corrupted somehow such that it takes
      // the shape of an object with numbers as keys we reformat it to an array
      // { 0: "description", 1: "mai", 2: "icon" }
      // https://stackoverflow.com/questions/20881213/converting-javascript-object-with-numeric-keys-into-array
      data.fields = Object.assign([], data.fields);
    }
    this.fields = data.fields ?? [];
    this.events = this.fields.map((f) => {
      return { label: f, name: f };
    });
  }
}

ModuleService.Register(JsonWidget.type, JsonWidget);
