type QueueEntry = {
  fn: () => Promise<any>;
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
};
/** Queue of promises that limits concurrency. */
class PreloadQueueClass {
  queue: QueueEntry[] = [];
  activePromises: number = 0;
  // create texture function includes multiple net requests
  // so 2 seems to be a reasonable cap on concurrency
  promiseLimit = 2;

  async enqueue<T>(fn: () => Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      this.queue.push({ fn, resolve, reject });
      this.dequeue();
    });
  }

  dequeue() {
    if (this.activePromises >= this.promiseLimit) {
      return;
    }
    const el = this.queue.shift();
    if (!el) {
      return;
    }
    try {
      this.activePromises++;
      el.fn()
        .then((val: any) => {
          this.activePromises--;
          el.resolve(val);
          this.dequeue();
        })
        .catch((e) => {
          this.activePromises--;
          el.reject(e);
          this.dequeue();
        });
    } catch (e) {
      this.activePromises--;
      el.reject(e);
    }
  }
}

const PreloadQueue = new PreloadQueueClass();
export default PreloadQueue;
