import { IServiceOptions } from "..";
import { BaseDataService } from "../BaseDataService";
import { APIMaterialSet } from "../models";

export class APIMaterialSetService extends BaseDataService<APIMaterialSet> {
  constructor() {
    super("materialset");
  }
  public async uploadMaterialSetFromFile(
    file: any,
    options?: IServiceOptions
  ): Promise<APIMaterialSet> {
    const body = new FormData();
    body.append("material", file);
    return await this.submit("upload", "POST", body, options);
  }

  public async updateName(id: string, name: string, options?: IServiceOptions) {
    const body = new FormData();
    body.append("name", name);
    return await this.submit(`${id}/update`, "PUT", body);
  }

  public async uploadNewMaterialSet(
    materials: string[],
    options?: IServiceOptions
  ) {
    return await this.request("create", {
      method: "POST",
      body: { materials: materials },
      ...options,
    });
  }

  public async addMtlFileToSet(
    materialSetId: string,
    file: any,
    options?: IServiceOptions
  ) {
    const body = new FormData();
    body.set("mtlFile", file);
    return await this.submit(
      `${materialSetId}/upload/file`,
      "POST",
      body,
      options
    );
  }

  public async uploadTextureBatch(
    materialSetId: string,
    textures: any[],
    options?: IServiceOptions
  ) {
    const body = new FormData();
    for (const tex of textures) {
      body.append("textureFiles", tex);
    }
    return await this.submit(
      `${materialSetId}/upload/batch`,
      "POST",
      body,
      options
    );
  }

  public async setFlipY(
    materialSetId: string,
    value: boolean,
    options?: IServiceOptions
  ) {
    await this.request(`${materialSetId}/flipY/${value ? "true" : "false"}`, {
      method: "PUT",
      ...options,
    });
  }
}

const MaterialSetService = new APIMaterialSetService();

export default MaterialSetService;
