import { ReactElement } from "react";
import ShareButton from "./ShareButton";

const sceneRoot = (() => {
  switch (window.location.hostname) {
    case "dev.studio.provizxr.com":
      return `https://dev.embed.provizxr.com/scene`;
    case "qa.studio.provizxr.com":
      return `https://qa.embed.provizxr.com/scene`;
    case "localhost":
      return `https://localhost:3002/scene`;
    default:
      return `https://embed.provizxr.com/scene`;
  }
})();

export const getSceneLink = (sceneId: string) => {
  return `${sceneRoot}/${sceneId}`;
};

interface Props {
  sceneId: string;
  title: string;
  appParams: () => string[];
}

export default function SceneShareButton(props: Props): ReactElement {
  const { sceneId } = props;
  const link = getSceneLink(sceneId);
  const embedHtml = `<iframe \n    src="${sceneRoot}/\n      ${sceneId}" \n/>`;

  return (
    <ShareButton
      link={link}
      embedHtml={embedHtml}
      title={props.title}
      entityType="Scene"
      appParams={props.appParams}
    />
  );
}
