import { APITarget, CompanyService, initAbortController, TagService } from '@proviz/api-services'
import { useEffect, useState } from 'react'
import Button from '../common/button/Button'
import FormContainer from '../common/formcontainer'

interface Props {
    updateCompanies: () => void;
}

type CreateState = 'None' | 'Creating' | 'Created' | 'Failed';

function creationText(s: CreateState) {
    switch(s) {
        case 'None':
            return '';
        case 'Creating':
            return 'Creating company... (this may take a while)';
        case 'Created':
            return 'Company created successfully';
        case 'Failed':
            return 'There was an error creating a company. Please try again later or reach out to a dev.';
        default:
            return '';
    }
}

export default function OrganizationCreate(props: Props) {
    const { updateCompanies } = props;
    const [orgName, setOrgName] = useState<string>('');
    const [availableTags, setAvailableTags] = useState<APITarget[]>([]);
    const [tags, setTags] = useState<string[]>([])
    const [creationState, setCreationState] = useState<CreateState>('None');

    useEffect(() => {
        const abortController = initAbortController();
        TagService.getAll({ abortController }).then(setAvailableTags);
      return () => abortController.abort();
    }, []);

    const resetState = () => {
        setCreationState('None');
        setTags([]);
        setOrgName('');
    }
    
    const createOrganization = async () => {
        try {
            setCreationState('Creating');
            await CompanyService.create({ name: orgName, tags });
            setCreationState('Created');
            updateCompanies();
            setTimeout(resetState, 3000);
        } catch (error) {
            console.error(error);
        }
    }
    return <FormContainer title="Create New Organization">
        <table className="inner">
            <tbody>
                <tr>
                    <td>Organization Name</td>
                    <td><input className="provizInputBox" type="text" value={orgName} onChange={(e) => setOrgName(e.target.value)} placeholder="Organization Name" /></td>
                </tr>
                {tags.map((tag: string, ind: number) => {
                    return <div key={`${tag}-${ind}`} className='flex'>
                        <div>
                            <select className="selector"  value={tag} onChange={(e) => {
                                const newTags = [...tags];
                                newTags[ind] = e.target.value;
                                setTags(newTags);
                            }}>
                                <option value=""></option>
                                {availableTags.map((t) => <option value={t.id} key={t.id}>{t.name}</option>)}
                            </select>
                        </div>
                        <div>
                            <Button type='icon' onClick={() => {
                                const newTags = [...tags];
                                newTags.splice(ind, 1);
                                setTags(newTags);
                            }}>
                                X
                            </Button>
                        </div>
                    </div>
                })}
                <tr>
                    <td colSpan={2}>
                        <Button fullWidth={true} onClick={() => setTags([...tags, ""])}>
                            Add Tag
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Button onClick={createOrganization}>Create Organization</Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>{creationText(creationState)}</div>
    </FormContainer >
}