import { getMediaFilePath } from '@proviz/api-services';

export interface ISpriteResource {
  id: string;
  imageSrc: string;
  verticalTiles: number;
  horizontalTiles: number;
  framesPerSecond: number;
  numberOfTiles: number;
}

export default class SpriteResource {
  public id: string;
  public imageSrc: string;
  public verticalTiles: number;
  public horizontalTiles: number;
  public framesPerSecond: number;
  public numberOfTiles: number = 0;

  constructor(data: ISpriteResource) {
    this.imageSrc = data.imageSrc;
    this.verticalTiles = data.verticalTiles;
    this.horizontalTiles = data.horizontalTiles;
    this.framesPerSecond = data.framesPerSecond;
    this.numberOfTiles = data.numberOfTiles;
    this.id = data.id;
  }

  public getImageLocation() {
    return getMediaFilePath(this.imageSrc);
  }
}
