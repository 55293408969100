import React, { ReactElement, useEffect, useState } from 'react'
import PagingControl from '../paging/PagingControl';
import Button from '../button/Button';
import SearchButton from './SearchButton';

interface Props  {
    children?: React.ReactNode;
    searchParams: string;
    setSearchParams: (search: string) => void;
    setDisplayMode: (state: boolean) => void;
    showPaging?: boolean;
    page?: number;
    totalPages?: number;
    pageSet?: (page: number) => void;
    isFileSelect?: boolean;
    showModeToggle?: boolean;
}

export default function SearchBar(props: Props): ReactElement {
    const { children } = props;
    const [mode, setMode] = useState(false);

    useEffect(() => {
    }, [props.totalPages])


    return  <div className="searchBar">
        <SearchButton setSearchParams={(search: string) => {
            props.pageSet && props.pageSet(0);
            props.setSearchParams(search);
        }} />

            {((props.showPaging && 
            props.totalPages !== undefined && 
            props.page !== undefined && 
            props.pageSet) && <PagingControl page={props.page} totalPages={props.totalPages} pageSet={props.pageSet} isFileSelect={props.isFileSelect} />)}
            <div className='buttonsContainer'>
                {children}
            
                {props.showModeToggle && <Button type="icon" onClick={() => {
                    props.setDisplayMode(!mode);
                    setMode(!mode);
                }}>
                    <span aria-hidden="true" className="icon-folder-icon"></span>
                </Button>}
            </div>
        </div>;
}
