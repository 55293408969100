import create from 'zustand'
import { APIUser, APIPermission, hasAccess, PermissionEntity, PermissionType } from '@proviz/api-services';
import { AccessToken } from '../auth/AccessToken';

/********************************************************************* TYPES */
type UserStoreType = {
    user?: APIUser;
    setUser: (u: APIUser) => void;
    Permissions: APIPermission[];
    setPermissions: (p: APIPermission[]) => void;
    hasPermission: (_: PermissionEntity, _p: PermissionType) => (boolean | undefined);
}

/********************************************************************* STORE */
export const userStore = create<UserStoreType>( (set, get) => ({
    user: AccessToken.user,
    setUser: (user) => set( () =>( {user} ) ),
    Permissions: [],
    setPermissions: (Permissions) => set( s =>( {Permissions} ) ),
    hasPermission: (permissionEntity, permissionType) => {
        const {user, Permissions} = get()
        return user?.isSuperAdmin || hasAccess(Permissions, permissionEntity, permissionType) !== undefined
    } 
}))
