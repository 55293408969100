import { BaseWidget } from '../baseWidget';
import { IBaseWidgetType } from '../IBaseWidgetType';

export class GUIBaseWidget extends BaseWidget implements IBaseWidgetType {
  public guiElement?: HTMLElement;

  remove(): void {
    super.remove();
    this.guiElement?.remove();
  }

  public unparent() {
    super.unparent();
  }

  public setParent(node: BaseWidget) {
    super.setParent(node);
    const guiWidget = node as GUIBaseWidget;
    if (this.guiElement && guiWidget.guiElement) {
      guiWidget.guiElement.appendChild(this.guiElement);
    }
  }

  protected setDraggingMode(state: boolean) {
    if (this.widgetType === 'gui' && this.guiElement) {
      this.guiElement.setAttribute('class', 'gui-element' + (state ? ' dragging' : ''));
    } else {
      const guiParent = this.parent as GUIBaseWidget;
      guiParent.setDraggingMode(state);
    }
  }
}
