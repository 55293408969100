import { useEffect, useState } from "react";
import {
    APIFile,
    APISprite,
    APITexture,
    APIModel,
    APITarget,
    FileService,
    FileType,
    FileTypes,
    ModelService,
    SceneService,
    SpriteService,
    TextureService,
    TargetService, 
    TargetType
  } from "@proviz/api-services";
  import { compareScenesByTime } from "../../home/SceneView";
  import { ISceneRecord } from "../../home/ThumbnailCard";

export type ResourceType = FileType | "Scene" | "AreaTarget" | "ModelTarget";
export const ResourceTypes: ResourceType[] = [...FileTypes, "Scene", "AreaTarget", "ModelTarget"];
export type HandleSelectType = (id: string | null) => void //| Promise<void>;


export type FileSelectData = {
        page: number; 
        setPage: (p: number) => void; 
        totalPages: number;
        setTotalPages: (d: number) => void; 
        handleDisplayQuantChange: (e: any) => void;
        displayQuantity: number; 
        setDisplayQuantity: (d: number) => void; 
        searchParams: string; 
        setSearchParams: (s: string) => void; 
        files: APIFile[]; 
        setFiles: (f: APIFile[]) => void;
        models: APIModel[]; 
        setModels: (m: APIModel[]) => void;
        scenes: ISceneRecord[]; 
        setScenes: (s: ISceneRecord[]) => void;
        uploadFile: boolean; 
        setUploadFile: (u: boolean) => void; 
        waitingText: string;
        setWaitingText: (w: string) => void; 
        displayScenes: ISceneRecord[]; 
        setDisplayScenes: (d: ISceneRecord[]) => void; 
        textures: APITexture[]; 
        setTextures: (t: APITexture[]) => void;
        sprites: APISprite[];
        setSprites: (s: APISprite[]) => void;
        update: boolean;
        setUpdate: (u: boolean) => void;
        error: boolean;
        setError: (e: boolean) => void; 
        resourceType: ResourceType;
        setResourceType: (r: ResourceType) => void; 
        fileNotTexture: boolean;
        setFileNotTexture: (f: boolean) => void;
        selectResourceCallback?: HandleSelectType; 
        setSelectResourceCallback: (s: HandleSelectType | undefined) => void; 
        extensions?: string[];
        targets: APITarget[];
        setTargets: (t: APITarget[]) => void;
        loading: boolean;
}

export default function useFileSelect() : FileSelectData {
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [displayQuantity, setDisplayQuantity] = useState(20);
    const [searchParams, setSearchParams] = useState("");
    const [files, setFiles] = useState<APIFile[]>([]);
    const [models, setModels] = useState<APIModel[]>([]);
    const [scenes, setScenes] = useState<ISceneRecord[]>([]);
    const [uploadFile, setUploadFile] = useState(false);
    const [waitingText, setWaitingText] = useState("");
    const [displayScenes, setDisplayScenes] = useState<ISceneRecord[]>([]);
    const [textures, setTextures] = useState<APITexture[]>([]);
    const [sprites, setSprites] = useState<APISprite[]>([]);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(false);
    const [resourceType, setResourceType] = useState<ResourceType>("Texture");
    const [fileNotTexture, setFileNotTexture] = useState<boolean>(true);
    const [selectResourceCallback, setSelectResourceCallback] = useState<HandleSelectType | undefined>(undefined);
    const [targets, setTargets] = useState<APITarget[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleDisplayQuantChange = (e: any) => {
        setDisplayQuantity(e.target.value);
    };

    let extensions : string[] | undefined = undefined;
    if (resourceType === "Model" || resourceType === "Scene") {
        extensions = undefined;
    } else if (resourceType === "Video") {
        extensions =  ["mp4", "mov", "webm"];
    } else if (resourceType === "Audio") {
        extensions =  ["wav", "mp3"];
    } else if (resourceType === "Image") {
        extensions =  ["png", "jpg", "jpeg"];
    } else if (resourceType === "Texture") {
        extensions =  ["png", "jpg", "jpeg"];
    } else if (resourceType === "Sprite") {
        extensions =  ["png"];
    }

    useEffect(() => {
        const load = () => {
          setUpdate(false);
          setLoading(true);
          if (resourceType === "Model") {
            ModelService.paged(page, displayQuantity, searchParams)
              .then((_models) => {
                setModels(_models.data);
                setTotalPages(_models.pagesTotal);
                setLoading(false);
            })
            .catch((e) => {
              console.error("Requesting models failed", e);
              setError(true);
            });
          } else if (resourceType === "Scene") {
            SceneService.paged(page, displayQuantity, searchParams)
              .then((data) => {
                setScenes(data.data);
                setTotalPages(data.pagesTotal);
                setLoading(false);
            });
          } else if (resourceType === "Texture" && !fileNotTexture) {
            TextureService.paged(page, displayQuantity, searchParams)
              .then((_textures) => {
                setTextures(_textures.data);
                setTotalPages(_textures.pagesTotal);
                setLoading(false);
              })
              .catch((e) => {
                console.error("Requesting textures failed", e);
                setError(true);
              });
          } else if (resourceType === "Image") {
            FileService.getAllByFileTypePaged(resourceType, page, displayQuantity, searchParams)
              .then((_files) => {
                setFiles(_files.data);
                setTotalPages(_files.pagesTotal);
                setLoading(false);
              })
              .catch((e) => {
                console.error("Requesting images failed", e);
                setError(true);
              }).finally(() => {
                setLoading(false);
              });
          } else if (resourceType === "Sprite") {
            SpriteService.paged(page, displayQuantity, searchParams)
              .then((_sprites) => {
                setSprites(_sprites.data);
                setTotalPages(_sprites.pagesTotal);
                setLoading(false);
              })
              .catch((e) => {
                console.error("Requesting sprites failed", e);
                setError(true);
              });
          } else if (resourceType === "AreaTarget") {
            TargetService.getAllPagedWithTargetType(page, displayQuantity, searchParams, [TargetType.AreaTarget]) 
              .then((_targets) => {
                setTargets(_targets.data);
                setTotalPages(_targets.pagesTotal);
                setLoading(false);
              })
              .catch((e) => {
                console.error("Requesting area targets failed", e);
                setError(true);
              });
          } else if (resourceType === "ModelTarget") {
            TargetService.getAllPagedWithTargetType(page, displayQuantity, searchParams, [TargetType.Model, TargetType.Model360]) 
              .then((_targets) => {
                setTargets(_targets.data);
                setTotalPages(_targets.pagesTotal);
                setLoading(false);
              })
              .catch((e) => {
                console.error("Requesting model targets failed", e);
                setError(true);
              });
          } else {
            FileService.getAllByFileTypePaged(
              resourceType,
              page,
              displayQuantity,
              searchParams
            )
              .then((_files) => {
                setFiles(_files.data)
                setLoading(false);
              })
              .catch((e) => {
                console.error("Requesting files failed", e);
                setError(true);
              });
          }
        };
        load();
      }, [
        page,
        searchParams,
        resourceType,
        update,
        displayQuantity,
        fileNotTexture,
      ]);

      useEffect(() => {
        function filterScenes(scenesToFilter: ISceneRecord[]) {
          if (!searchParams) {
            return scenesToFilter;
          }
          let cleanParams = searchParams.toLowerCase();
          return scenesToFilter.filter((s) =>
            s.name?.toLowerCase().includes(cleanParams)
          );
        }
        function sortScenes(scenesToSort: ISceneRecord[]) {
          return scenesToSort.sort(compareScenesByTime);
        }
        const sortedAndFiltered = sortScenes(filterScenes(scenes));
        setDisplayScenes(sortedAndFiltered);
      }, [searchParams, scenes]);

    return {
        page, 
        setPage, 
        totalPages, 
        setTotalPages, 
        handleDisplayQuantChange,
        displayQuantity, 
        setDisplayQuantity, 
        searchParams, 
        setSearchParams, 
        files, 
        setFiles,
        models, 
        setModels,
        scenes, 
        setScenes, 
        uploadFile, 
        setUploadFile, 
        waitingText, 
        setWaitingText, 
        displayScenes, 
        setDisplayScenes, 
        textures, 
        setTextures,
        sprites, 
        setSprites, 
        update, 
        setUpdate, 
        error, 
        setError, 
        resourceType, 
        setResourceType, 
        fileNotTexture, 
        setFileNotTexture, 
        selectResourceCallback, 
        setSelectResourceCallback, 
        extensions, 
        targets, 
        setTargets, 
        loading,
    }
}