import { BaseWidgetProperty, getAbsoluteUrl, IBaseWidgetEvent, IBaseWidgetService } from "@proviz/proviz-sdk";
import React, { ReactElement, useState } from 'react'
import { Handle, Position } from 'react-flow-renderer';
import Checkbox from '../../common/threevalinput/Checkbox';
import ReactMarkdown from "react-markdown";
import { ManagerStore } from "../../../store";
import { ProVizConfig } from "@proviz/api-services";

// const boltIcon = getAbsoluteUrl('images/bolt.svg');

interface Props {
    data: {
        label: string,
        note: {
            data: string,
            alwaysShow:boolean,
            toggleShow: Function,
        },
        node: {
            id: string,
            widgetType: string,
            services: IBaseWidgetService[],
            events: IBaseWidgetEvent[],
            properties: BaseWidgetProperty[]
        },
        resourceId: string | undefined
    }
}

function isAnimationService(name: string) {
    return name.includes('ani-')
}

const animationSubHeaderHeight = 40;

export default function ModelNode(props: Props): ReactElement {
    const { node, note, label } = props.data;
    const manager = ManagerStore( s => s.SceneManager );
    

    const isSelected = manager.selectedNodes.findIndex((wdg) => wdg.id === node.id) > -1;
    // const [selected, setSelected] = useState(node === manager.selectedNode);
    // Since there may be many animation tracks on a model and each track has 3 services
    // associated with it we want to be able to hide them so they don't clutter up our view.
    const [showAniNodes, setShowAniNodes] = useState(false);
    const [showPropertyNodes, setShowPropertyNodes] = useState(false);

    // React.useEffect(() => {
    //     const updateSelected = () => {
    //         setSelected(props.data.node.id === manager.selectedNode?.id);
    //     }
    //     manager.attach('selectednode-update', updateSelected);
    //     return () => {
    //         manager.detach('selectednode-update', updateSelected);
    //     }
    // })
    const aniServiceCnt = node.services.filter(s => isAnimationService(s.name)).length;
    const servicesToShowCnt = showAniNodes
        ? node.services.length
        : node.services.length - aniServiceCnt;
    const propertiesToShow = showPropertyNodes ? node.properties.length + 1 : 1;
    const maxHeightNodes = Math.max(servicesToShowCnt + propertiesToShow, props.data.node.events.length);
    const subHeaderOffset = (aniServiceCnt > 0) ? animationSubHeaderHeight : 15;
    const height = subHeaderOffset + ((1 + maxHeightNodes) * 15);

    let renderedServiceCount = 0;
    return <React.Fragment key={node.id}>
        <div className="flow-node-model" style={{ position: 'relative', padding: '0px', height: `${height}px`, width: '200px' }}>

            {props.data.node.services.map((s: IBaseWidgetService) => {
                if (!showAniNodes && isAnimationService(s.name)) {
                    return <></>
                }
                renderedServiceCount++;
                return <Handle
                    className="flow-handle"
                    key={s.name + node.id}
                    id={s.name}
                    type="target"
                    position={Position.Left}
                    style={{
                        bottom: (renderedServiceCount - 1) * 15,
                        top: 'auto',
                        left: -15,
                        background: '#fff',
                        width: '10px',
                        height: '10px',
                        padding: '0',
                        zIndex: 100 //- renderedServiceCount
                    }}
                // onConnect={(params) => console.log('handle onConnect', params)}
                >
                    {/* <img src={boltIcon} style={{ width: '100%', position: 'absolute', pointerEvents: 'none' }} alt='' /> */}
                    <div style={{ position: 'absolute', top: '-2px', left: '18px', fontSize: '10px', whiteSpace: 'nowrap', width: '100px' }}>
                        {s.label}
                    </div>
                    {s.desc && <div className="desc" dangerouslySetInnerHTML={{ __html: s.desc || '' }}></div>}
                </Handle>
            })}

            {props.data.node.properties.map((s: BaseWidgetProperty) => {
                if (!showPropertyNodes) {
                    return <></>
                }
                renderedServiceCount++;
                return <Handle
                    className="flow-handle flow-handle-node-property"
                    key={s.name + node.id}
                    id={`property-${s.name}`}
                    type="target"
                    position={Position.Left}
                    style={{
                        bottom: (renderedServiceCount - 1) * 15,
                        top: 'auto',
                        left: -15,
                        background: '#fff',
                        width: '10px',
                        height: '10px',
                        padding: '0',
                        zIndex: 100,
                        borderRadius: '10px' //- renderedServiceCount
                    }}
                // onConnect={(params) => console.log('handle onConnect', params)}
                >
                    {/* <img src={boltIcon} style={{ width: '100%', position: 'absolute', pointerEvents: 'none' }} alt='' /> */}
                    <div style={{ position: 'absolute', top: '-2px', left: '18px', fontSize: '10px', whiteSpace: 'nowrap', width: '100px' }}>
                        {s.label}
                    </div>
                    {s.infoText && <div className="desc" dangerouslySetInnerHTML={{ __html: s.infoText || '' }}></div>}
                </Handle>
            })}

            <div className={`flow-node-header ${isSelected ? 'selected' : ''}`}
                style={{display: "flex"}}
                onClick={() => {
                    manager.setSelectionById(node.id);
                }} >
                {isSelected ? <b style={{flexGrow: 1}}>{label}</b> : <span style={{flexGrow: 1}}>{label}</span>}
                {note.data && <div className='note'>
                    <div onClick={() => {
                        console.log(note.alwaysShow)
                        note.toggleShow(note.alwaysShow)
                    }}>
                        <img className='widget-icon' 
                            src={note.alwaysShow?getAbsoluteUrl("images/noteIcon2.svg"):getAbsoluteUrl("images/noteIcon1.svg")}
                            alt="toggle notes"
                        />
                    </div>
                    <div className="note-desc" style={{display: `${note.alwaysShow?"block":""}`}}>
                        <ReactMarkdown children={note.data} />
                    </div>
                </div>}
            </div>
                
            {props.data.resourceId && <div className='flow-node-preview' style={{backgroundImage: `url('${ProVizConfig.backend}/v1/model/${props.data.resourceId}/thumbnail/data')`}} />}

            <div className={`flow-node-subheader ${isSelected ? 'selected' : ''}`} style={{
                bottom: (renderedServiceCount) * 15,
                position: 'absolute'
            }}>
                <Checkbox id={`model-${node.id}`}
                    key={'ani-check' + node.id}
                    value={showAniNodes}
                    onChange={() => setShowPropertyNodes(!showPropertyNodes)} />

                <p>{showPropertyNodes ? "Minimize" : "Expand"} widget properties</p>
            </div>

            {aniServiceCnt > 0 &&
                <div className={`flow-node-subheader ${isSelected ? 'selected' : ''}`}>
                    <Checkbox id={`model-${node.id}`}
                        key={'ani-check' + node.id}
                        value={showAniNodes}
                        onChange={() => setShowAniNodes(!showAniNodes)} />

                    <p>{showAniNodes ? "Minimize" : "Expand"} animation services</p>
                </div>
            }

            {props.data.node.events.map((e: IBaseWidgetEvent, ind: number) => <Handle
                className="flow-handle"
                key={e.name+ props.data.node.id}
                type="source"
                position={Position.Right}
                id={e.name}
                style={{ bottom: ind * 15, top: 'auto', right: -15, background: '#fff', borderRadius: '100%', padding: '2px', zIndex: 100 - ind }}
            >
                <div style={{ position: 'absolute', top: '-2px', right: '20px', fontSize: '10px', whiteSpace: 'nowrap' }}>
                    {e.label}
                </div>
                {e.desc && <div className="desc" dangerouslySetInnerHTML={{ __html: e.desc || '' }}></div>}
            </Handle>)}
        </div>
    </React.Fragment>;
}
