import { SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class RestWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'rest';

  // Data
  private url: string | undefined = undefined;
  private frequency: number = 0;
  private json: boolean = true;
  private method: string = 'GET';
  private headers: string = '';
  private repeat: boolean = false;
  private requestInterval?: NodeJS.Timer;

  private value?: string | Object;
  private isCancelled = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'REST';

    this.usage = 'Flow';
    this.category = 'Events';

    this.widgetType = RestWidget.type;
    this.widgetName = 'REST';
    this.selectable = true;
    this.events = [
      {
        label: 'Changed',
        name: 'changed',
      },
    ];
    this.services = [
      {
        label: 'Request',
        name: 'request',
      },
      {
        label: 'Cancel Repeating Requests',
        name: 'cancel',
      },
    ];
    this.addEventListener('service-request', () => {
      this.makeRequest();
    });
    this.addEventListener('service-cancel', () => {
      this.makeRequest();
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('url', 'URL', 'Data', 'string', 'string', true),
      this.createProperty('method', 'Method', 'Data', 'string', 'string', true),
      this.createProperty('headers', 'Headers', 'Data', 'string', 'string', true),
      this.createProperty('json', 'Is JSON', 'Data', 'bool', 'boolean', true),
      this.createProperty('repeat', 'Repeat', 'Data', 'bool', 'boolean', true),
      this.createProperty('frequency', 'Frequency', 'Data', 'number', 'number', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.url = this.url;
    result.frequency = this.frequency;
    result.json = this.json;
    result.method = this.method;
    result.headers = this.headers;
    result.repeat = this.repeat;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.url = data.url;
    this.frequency = data.frequency;
    this.json = data.json;
    this.method = data.method;
    this.headers = data.headers;
    this.repeat = data.repeat;
  }

  private async makeRequest() {
    let headers: any = {};
    if (this.headers) {
      const reqHeaders = this.headers.split(';');
      reqHeaders.forEach((h: string) => {
        const headerParts = h.split(',');
        headers[headerParts[0]] = headerParts[1];
      });
    }
    if (!this.url) {
      console.warn('Cannot make request because the url is empty');
      return;
    }
    const req: RequestInfo = new Request(this.url, {
      method: this.method,
      headers,
    });
    const resp = await fetch(req);
    if (this.json) {
      try {
        const data = await resp.json();
        this.value = data;
      } catch (e) {
        if (this.requestInterval) {
          clearInterval(this.requestInterval);
        }
      }
    } else {
      try {
        this.value = await resp.text();
      } catch (e) {
        if (this.requestInterval) {
          clearInterval(this.requestInterval);
        }
      }
    }
    // trigger event
    this.triggerProVizEvent('changed', 'object', this.value);
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }
    if (this.frequency > 0 && this.repeat) {
      this.requestInterval = setInterval(() => {
        if (this.isCancelled) {
          return;
        }
        this.makeRequest();
      }, this.frequency);
    }
    return true;
  }

  public dispose() {
    super.dispose();
    this.isCancelled = true;
  }
}

ModuleService.Register(RestWidget.type, RestWidget);
