import { BaseDataService, IServiceOptions } from "../BaseDataService";
import { APIModelVariant, IModelVariantLog } from "../models/ModelVariant";

export class APIModelVariantService extends BaseDataService<APIModelVariant> {
  constructor() {
    super("modelvariant");
  }
  public async postModelVariant(
    modelId: string,
    materialSetId: string,
    options?: IServiceOptions
  ) {
    return await this.request(`${modelId}/${materialSetId}`, {
      method: "POST",
      ...options,
    });
  }

  public async getLogs(
    id: string,
    options?: IServiceOptions
  ): Promise<IModelVariantLog[]> {
    return await this.request(`${id}/logs`, options);
  }

  public async deleteModelVariant(
    variantId: string,
    options?: IServiceOptions
  ) {
    return await this.request(`${variantId}/delete`, {
      method: "POST",
      ...options,
    });
  }
  public async convertModelVariant(
    variantId: string,
    options?: IServiceOptions
  ) {
    return await this.request(`${variantId}/convert`, {
      method: "POST",
      ...options,
    });
  }
  public async disableConversion(
    variantId: string,
    state: boolean,
    options?: IServiceOptions
  ) {
    return await this.post(
      `${variantId}/disableConversion/${state}`,
      undefined,
      options
    );
  }
}

const ModelVariantService = new APIModelVariantService();

export default ModelVariantService;
