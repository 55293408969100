import { SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class RandomValueWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'random-value';

  // Data
  private minRange: number = 0;
  private maxRange: number = 100;
  private frequency: number = 1000;
  private integers: boolean = true;

  private value: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Random Value';

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = RandomValueWidget.type;
    this.widgetName = 'Random Value';
    this.selectable = true;
    this.events = [
      {
        label: 'Changed',
        name: 'changed',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('minRange', 'Min Range', 'Data', 'number', 'number', true),
      this.createProperty('maxRange', 'Max Range', 'Data', 'number', 'number', true),
      this.createProperty('frequency', 'Frequency', 'Data', 'number', 'number', true),
      this.createProperty('integers', 'Integers Only', 'Data', 'bool', 'boolean', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.minRange = this.minRange;
    result.maxRange = this.maxRange;
    result.frequency = this.frequency;
    result.integers = this.integers;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.minRange = data.minRange;
    this.maxRange = data.maxRange;
    this.frequency = data.frequency;
    this.integers = data.integers;
  }

  private randomize() {
    this.value = this.minRange + Math.random() * (this.maxRange - this.minRange);
    if (this.integers) {
      this.value = Math.round(this.value);
    }
    // trigger event
    this.triggerProVizEvent('changed', 'number', this.value);
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }
    if (this.frequency > 0) {
      setInterval(() => {
        this.randomize();
      }, this.frequency);
    }

    this.randomize();
    return true;
  }
}

ModuleService.Register(RandomValueWidget.type, RandomValueWidget);
