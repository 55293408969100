import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class TextFormatWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'text-format';
  private values: { [key: string]: string } = {};

  // Data
  public textFormat: string = '';
  private options: { key: string; default: string }[] = [];

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Text Format';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = TextFormatWidget.type;
    this.widgetName = 'Text Format';
    this.selectable = true;
    this.events = [
      {
        label: 'Result',
        name: 'result',
      },
    ];

    this.buildServices();
  }

  private buildServices() {
    this.removeEventListeners();

    const inputOptions = (this.options || []).map((o) => {
      this.addEventListener(`service-set-${o.key}`, (event: ProVizEventData) => {
        const ev = event.data;
        if (event.dataType === 'string') {
          this.values[o.key] = ev as string;
          this.emitNewValue();
        }
      });

      return {
        label: `Set ${o.key}`,
        name: `set-${o.key}`,
      };
    });

    this.services = [
      {
        label: 'Apply',
        name: 'apply',
      },
      ...inputOptions,
    ];

    this.addEventListener('service-apply', (event: ProVizEventData) => {
      this.emitNewValue();
    });
  }

  private emitNewValue() {
    let result = `${this.textFormat}`;
    this.options.forEach((o) => {
      result = result.replace('${' + o.key + '}', this.values[o.key]);
    });

    this.triggerProVizEvent('result', 'string', result);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'textFormat',
        'Text Format',
        'Data',
        'string',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'Put the string to format here. Wrap all points you wish to replace with ${option}.',
      ),
      this.createProperty(
        'options',
        'Input Options',
        'Data',
        'options-array',
        'string',
        true,
        undefined,
        (data: any, updateNodes?: () => void) => {
          this.options = data;
          this.buildServices();
          updateNodes && updateNodes();
        },
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.textFormat = this.textFormat;
    result.options = this.options;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.textFormat = data.textFormat;
    this.options = data.options;
    this.values = {};
    // fill in default values
    this.options.forEach((o) => {
      this.values[o.key] = o.default || '';
    });
    this.buildServices();
  }
}

ModuleService.Register(TextFormatWidget.type, TextFormatWidget);
