import { ReactElement } from 'react'

interface Props {
    label: string;
    index: number;
    value: number;
    icon?: string;
    selectedIcon?: string;
    selectedLabel?: string;
    unselectedLabel?: string;
    
    onClick: () => void;
}

export default function Tab(props: Props): ReactElement {
    const { index, value, label, icon, selectedIcon, selectedLabel, unselectedLabel, onClick} = props;
    let selected = index === value;
    return (
        <div className={`tab ${selected ? 'selected selectedLabel' : 'unselectedLabel'}`} onClick={onClick}>
            {icon && selectedIcon &&
                <>
                    <span className={selected ? selectedIcon : icon} aria-hidden="true"/>
                    <span className="tooltipText">{label}</span>
                </>
            }
            {!icon && 
                <span className={selected ? selectedLabel : unselectedLabel} aria-hidden="false">{label}</span>}
        </div>
    )
}
