import { Group, Material, Mesh, MeshBasicMaterial, Object3D } from 'three';
import { ProVizScene, SceneMode } from '../../..';
import { APIModel, ModelService } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import ProVizLoader from '../../../utils/ProVizLoader';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { disposeModel } from '../../../utils';

export class DepthModelWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'depth-model';
  public apiModel?: APIModel;

  // Data
  public modelId: string = '';
  public clickable: boolean = true;

  private model?: Group;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = DepthModelWidget.type;
    this.widgetName = 'Depth Model';
    this.label = 'Depth Model';
    this.selectable = true;
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Model Loaded',
        name: 'model-loaded',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'modelId',
        'Model',
        'Core',
        'model',
        'string',
        true,
        () => {
          return this.modelId;
        },
        (id: string) => this.setModelId(id),
      ),
      this.createProperty(
        'clickable',
        'Clickable',
        'Core',
        'bool', 
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        `Some models may cause problems if they are set to clickable. If you need to block click and hover interactions, and your model is 
      not working, use bounding boxes to intercept the click and hover events. You could also try re-exporting your model from blender as 
      that sometimes helps.`,
      ),
    ];
  }

  public setModelId(id: string) {
    this.modelId = id;
    this.loadModel().catch((e) => {
      console.error('could not load depth model', e);
      alert('We could not load that model sorry');
    });
  }

  public serialize(): any {
    const result = super.serialize();
    result.modelId = this.modelId;
    result.clickable = this.clickable;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.modelId = data.modelId;
    this.clickable = data.clickable ?? true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.model && this.clickable && this.visible && !this.editorLocked) {
      result.push(this.model);
    }
    return result;
  }

  public getHoverable() {
    const result = super.getHoverable();
    if (this.model && this.clickable) {
      result.push(this.model);
    }
    return result;
  }

  private async loadModel() {
    // Load model from API
    this.apiModel = await ModelService.getSmall(this.modelId);

    // Create Three.js render object
    const proVizLoader = new ProVizLoader();
    if (this.model) {
      this.renderNode.remove(this.model);
    }
    this.model = await proVizLoader.LoadModel(this.apiModel, 'gltf');
    if (!this.scene) {
      // in case this scene/widget were disposed while waiting for model to load
      return;
    }
    if (this.model) {
      const depthMat = new MeshBasicMaterial();
      this.model.receiveShadow = false;
      if (this.scene.sceneMode !== SceneMode.Editor) {
        depthMat.colorWrite = false;
      }
      this.model.renderOrder = 1;
      this.assignMaterial(this.model, depthMat);
    }
    this.renderNode.add(this.model);
    this.triggerProVizEvent('model-loaded', 'none');
  }

  private assignMaterial(o: Object3D, m: Material) {
    o.children.forEach((c) => this.assignMaterial(c, m));
    if (o instanceof Mesh) {
      o.material = m;
    }
  }

  dispose() {
    super.dispose();
    if (this.model) {
      disposeModel(this.model);
    }
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.modelId) {
      this.loadModel();
    }

    return true;
  }
}

ModuleService.Register(DepthModelWidget.type, DepthModelWidget);
