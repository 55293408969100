import { FileService } from '@proviz/api-services';
import { debounce } from 'lodash';
import { MultiLangOption, ProVizScene } from '../../..';
import { removeChildren } from '../../../utils/DomTools';
import { SRTEntry, parseSRT } from './srtUtils';

export const SizeLookup = {
  Small: 'x-large',
  Medium: 'xx-large',
  Large: 'xxx-large',
};
export const ReverseSizeLookup = {
  'x-large': 'Small',
  'xx-large': 'Medium',
  'xxx-large': 'Large',
};
export class SRTPlayer {
  public static instances: SRTPlayer[] = [];
  /** The same CC Button is shared by all instances */
  private static sbtButton: HTMLDivElement;
  /** If any SRTPlayer instance is playing this must be true */
  private static isPlaying: boolean = false;
  /** This is a relatively global state that dictates wheter to show
   * subtitles when this is playing, if the instance is active and this
   * is false the subitles should be hidden but the button will be visible
   */
  private static styleEl?: HTMLStyleElement;
  public static showSubtitles: boolean = true;
  public static containers: {
    outer: HTMLElement;
    inner: HTMLElement;
  };

  private options: MultiLangOption;
  /** This is the current list of srt entries for the active language */
  private activeSrt?: SRTEntry[];
  private activeIdx?: number;
  private color: string;
  private bkgrndColor: string;
  private fontSize: string;
  public label: string;

  private instanceIsActive: boolean = false;

  /**
   * NOTE: this should only be called in the video widgets INIT function NOT in it's constructor
   * i.e. this should not be run in the editor
   * */
  constructor(
    options: MultiLangOption,
    scene: ProVizScene,
    color: string,
    bkgrndColor: string,
    size: string,
    label: string,
  ) {
    this.options = options;
    this.bkgrndColor = bkgrndColor;
    this.color = color;
    this.fontSize = size;
    this.label = label;
    SRTPlayer.instances.push(this);
    if (!SRTPlayer.containers) {
      const outer = document.createElement('div');
      outer.className = 'subt-cntr';
      outer.style.display = 'none'; // by default container is hidden
      const inner = document.createElement('div');
      inner.className = 'subt-inner-cntr';
      outer.append(inner);

      SRTPlayer.containers = { outer, inner };

      if (scene.parentEl) {
        scene.parentEl.append(outer);
      } else {
        console.error('No dom element');
      }

      const debouncedUpdateContainerSize = debounce(() => SRTPlayer.updateContainerSize(), 200, {
        trailing: true,
      });
      window.addEventListener('resize', () => debouncedUpdateContainerSize());
    }

    if (!SRTPlayer.styleEl) {
      SRTPlayer.styleEl = document.createElement('style');
      SRTPlayer.styleEl.innerText = `
.proviz-button.sbt {
  font-weight: 900;
  padding: 4px 3px 2px 3px;
  display: none;
}
.proviz-button.sbt.active {
  background: #7a7a7a;
  color: #fff;
  display: inline;
}
.proviz-button.sbt.active:hover {
  color: #7a7a7a;
  background: none;
}
.subt-cntr {
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-height: 30%;
  display: grid;
  place-content: center;
  font-size: x-large;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.subt-inner-cntr {
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0px 10px;
  text-align: center;
}`;
      document.body.append(SRTPlayer.styleEl);
    }

    if (!SRTPlayer.sbtButton) {
      const btnContainer = document.getElementById('proviz-button-container');
      if (!btnContainer) {
        console.error('No button container for sbt btn');
        return;
      }
      SRTPlayer.sbtButton = SRTPlayer.createButton();
      btnContainer.append(SRTPlayer.sbtButton);
    }
    this.setLanguage(scene.selectedLanguage, scene.defaultLanguage);
  }

  public setLanguage(selectedLang: string, defaultLang: string) {
    const fileId = this.options[selectedLang] ?? this.options[defaultLang];
    if (!fileId) {
      console.warn('No caption source to play');
      return;
    }
    this.loadSrt(fileId);
  }

  /** This may be called before the srt file has been loaded, in that
   * case the update method will use the srt once it has been loaded
   */
  public play(time = 0) {
    this.instanceIsActive = true;
    // Stop any other active instances
    SRTPlayer.instances.forEach((i) => {
      if (i !== this && i.instanceIsActive) {
        i.stop();
      }
    });
    SRTPlayer.isPlaying = true;
    this.updateSubtitleVisibility();
    SRTPlayer.updateButtonVisibility();
    SRTPlayer.sbtButton.onclick = () => {
      // We toggle subitle visibility and apply
      // this SRT instance's subtitle styling
      SRTPlayer.showSubtitles = !SRTPlayer.showSubtitles;
      this.updateSubtitleVisibility();
    };
    SRTPlayer.updateContainerSize();
    this.update(time);
  }

  public stop() {
    this.instanceIsActive = false;
    this.activeIdx = undefined;
    SRTPlayer.updateIsPlaying();
    this.updateSubtitleVisibility();
    SRTPlayer.updateButtonVisibility();
  }

  private static updateIsPlaying() {
    console.log('is playing');
    for (let i of SRTPlayer.instances) {
      if (i.instanceIsActive) {
        SRTPlayer.isPlaying = true;
        return true;
      }
    }
    SRTPlayer.isPlaying = false;
  }

  private static updateButtonVisibility() {
    SRTPlayer.sbtButton.className = `proviz-button sbt ${SRTPlayer.isPlaying ? 'active' : ''}`;
  }

  private updateSubtitleVisibility() {
    console.log(
      'Update sbtitles',
      this.label,
      SRTPlayer.isPlaying,
      SRTPlayer.showSubtitles,
      this.instanceIsActive,
    );
    if (SRTPlayer.showSubtitles) {
      if (this.instanceIsActive) {
        // We only apply this instance's settings if this instance is active
        SRTPlayer.containers.inner.style.color = this.color;
        SRTPlayer.containers.inner.style.background = this.bkgrndColor;
        SRTPlayer.containers.inner.style.fontSize = this.fontSize;
        SRTPlayer.containers.outer.style.display = '';
      }
    } else {
      // If subitles are not on acorss the board we hide the container
      SRTPlayer.containers.outer.style.display = 'none';
    }
  }

  public update(vidTime: number) {
    if (!this.instanceIsActive || !this.activeSrt) {
      return;
    }
    if (this.instanceIsActive && !SRTPlayer.isPlaying) {
      // This should not happen because isPlaying is only set to false
      // by the update IsPlaying function that should check that
      // no instance is active for setting it false
      console.warn('SRTPlayer state corrupted?');
      SRTPlayer.isPlaying = true;
      SRTPlayer.updateButtonVisibility();
    }
    if (this.activeIdx) {
      // If the video time is still within the time range for
      // the active idx do nothing.
      const entry = this.activeSrt[this.activeIdx];
      if (entry.start <= vidTime && vidTime <= entry.end) {
        return;
      }
    }
    for (let i = 0; i < this.activeSrt.length; i++) {
      let entry = this.activeSrt[i];
      if (entry.start <= vidTime && vidTime <= entry.end) {
        if (this.activeIdx !== i) {
          this.activeIdx = i;
          this.setSrtEntry(entry);
        }
        break;
      }
    }
  }

  private setSrtEntry(e: SRTEntry) {
    const con = SRTPlayer.containers.inner;
    removeChildren(con);
    const d = document.createElement('div');
    // We join these lines with a br tag because we want to preserve the formatting
    // of SRT files will use <i> and <b> tags which may stretch over multiple lines
    // so we don't want to put the text into seperate elements which would
    // not apply the formatting tag to both.
    d.innerHTML = e.text.join('<br/>');
    con.append(d);
  }

  private async loadSrt(id: string) {
    const file = await FileService.get(id);
    const resp = await fetch(file.location);
    const srtData = await resp.text();
    try {
      this.activeSrt = parseSRT(srtData);
    } catch (e) {
      console.error('Could not parse SRT File sorry');
      console.error(e);
    }
  }

  private static createButton() {
    const sbtButton = document.createElement('div');
    sbtButton.setAttribute('class', 'proviz-button hide');
    sbtButton.innerText = 'CC';
    return sbtButton;
  }

  /**
   * Centers closed caption video in center of screen left and beginning
   * of the proviz-button-container
   */
  private static updateContainerSize() {
    const btnContainer = document.getElementById('proviz-button-container');
    if (!btnContainer) {
      console.error('No button container for sbt btn');
      return;
    }
    SRTPlayer.containers.outer.style.width = `calc(90% - ${btnContainer.clientWidth}px`;
  }
}
