import { BaseWidget } from "@proviz/proviz-sdk";
import Modal from '../components/common/modals/Modals';
import { CompatibilityTable } from '../components/sceneeditor/CompatibilityTable';
// import DocGenerator from "./DocGenerator";
import { WidgetInfoText, WidgetSupport } from './WidgetInfoText';

interface Props {
    open: boolean;
    widget: BaseWidget;
    close: () => void;
}

export const WidgetInfoModal = (props: Props) => {
    const { open, widget } = props;
    return (open
        ? <Modal title={widget.widgetName} close={props.close}>
            {/* <DocGenerator /> */}
            <div className="content">
                {WidgetInfoText[widget.widgetType]
                    ? WidgetInfoText[widget.widgetType]
                    : ""}
                {WidgetSupport[widget.widgetType] &&
                    <CompatibilityTable supportList={WidgetSupport[widget.widgetType]} />
                }
            </div>
        </Modal>
        : <></>)
}
