import {  ReactElement, useState } from 'react'
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const infoIcon = getAbsoluteUrl('images/Other/Information.svg')

interface Props {
    infoText: string;
}

export default function InfoButton(props: Props): ReactElement {
    const { infoText } = props;
    const [show, setShow] = useState(false);

    return (
        <div className='infobutton-container'>
            <img src={infoIcon} alt='' onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                className='infoButton'></img>
            {show && <div className='info-hover-box'>
                {infoText}
            </div>}
        </div>
    )
}
