import { GridHelper, Scene } from "three";

export default class Grid {
  grid: GridHelper;
  scene: Scene;

  constructor(scene: Scene, visible = false) {
    this.grid = new GridHelper(500, 500);
    this.grid.visible = visible;
    this.grid.position.y = 0.015;
    scene.add(this.grid);
    this.scene = scene;
  }

  toggle() {
    this.grid.visible = !this.grid.visible;
  }

  get visible() {
    return this.grid.visible;
  }
}
