import { TargetService } from '@proviz/api-services';
import { BaseWidgetProperty, ModelWidget, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class AreaTargetWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'area-target';

  // Data
  public targetId: string = '';
  private displayModelOverrideId: string = '';
  visibleInScene: boolean = false;

  private displayModel: ModelWidget | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = AreaTargetWidget.type;
    this.widgetName = 'Area Target';
    this.label = 'Area Target';
    this.category = 'Targets';
    this.selectable = false;
    this.clickable = false;
    this.editorLocked = true;
    this.events = [
      {
        label: 'Tracking Found',
        name: 'tracking-found',
      },
      {
        label: 'Tracking Lost',
        name: 'tracking-lost',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('targetId', 'Area Target', 'Core', 'area-target', 'string', true),
      this.createProperty('visibleInScene', 'Visible In Scene', 'Core', 'bool', 'boolean', true),
      this.createProperty(
        'displayModelOverrideId',
        'Display Model Override',
        'Core',
        'model',
        'string',
        true,
        () => {
          return this.displayModelOverrideId;
        },
        (id: string) => {
          this.displayModelOverrideId = id;
          this.setupDisplayModel(id);
        },
      ),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.targetId) {
      // get the target and use the display model
      TargetService.get(this.targetId).then((target) => {
        const settings = JSON.parse(target.settings || '{}');
        if (settings.displayModelId) {
          this.setupDisplayModel(settings.displayModelId);
        }
      });
    }

    return true;
  }

  private async setupDisplayModel(modelId: string) {
    if (this.displayModel) {
      this.displayModel.remove();
    }

    if (modelId || this.displayModelOverrideId) {
      if (this.scene.sceneMode === SceneMode.Editor || this.visibleInScene) {
        this.displayModel = new ModelWidget(this.scene, this);
        this.displayModel.modelId = this.displayModelOverrideId || modelId;
        this.displayModel.deserialize({
          modelId: this.displayModelOverrideId || modelId,
          clickable: false,
          transparent: false,
          opacity: 1.0,
          rotation: { x: 0, y: 0, z: 0 },
          scale: { x: 1, y: 1, z: 1 }
        });
        await this.displayModel.init();
        this.renderNode.add(this.displayModel.renderNode);
      }
    } else {
      this.displayModel = undefined;
    }
  }

  public serialize(): any {
    const result = super.serialize();
    result.targetId = this.targetId;
    result.visibleInScene = this.visibleInScene;
    result.displayModelOverrideId = this.displayModelOverrideId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.targetId = data.targetId;
    this.visibleInScene = data.visibleInScene ?? this.visibleInScene;
    this.displayModelOverrideId = data.displayModelOverrideId;
  }

  dispose() {
    super.dispose();
    this.displayModel?.dispose();
  }
}

ModuleService.Register(AreaTargetWidget.type, AreaTargetWidget);
