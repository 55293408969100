import { BaseWidget } from "@proviz/proviz-sdk";
import { ReactElement, useMemo, useState } from 'react'
import { ManagerStore } from "../../../store";
import CollapsibleHeaderIcon from "../../common/collapsiblegroup/CollapsibleHeader";
import PropertyRenderer from '../../sceneeditor/propertypanel/PropertyRenderer';

const SuppressedProperties = [
    'scale',
    'visible',
    'position',
    'rotation',
    'label',
];

const SceneProperties = ['ambientIntensity', 'ambientColor'];

interface Props {
    widgets: BaseWidget[];
}

export default function ModelLightingControls(props: Props): ReactElement {
    const { widgets } = props;
    const [expand, setExpand] = useState(false);
    const manager  = ManagerStore(s=>s.ModelViewManager);
    const managerProperties = useMemo(() => manager.proVizScene.properties
                    .filter(x => SceneProperties.includes(x.name)), [manager])

    return <div>
        <div className="collapsible-properties-header">
            <h5>Lighting</h5>
            <CollapsibleHeaderIcon expanded={expand} handleClick={() => setExpand(!expand)}  />
        </div>
        <ul>
            {expand && <>
                <PropertyRenderer manager={manager} properties={managerProperties} /> 
                {widgets.filter(w => w.category === 'Lights')
                    .map(w => <div key={w.id}>
                        <h4>{w.label}</h4>
                        <PropertyRenderer node={w}
                            properties={w.properties}
                            manager={manager}
                            supressedProperties={SuppressedProperties} />
                    </div>)}
            </>}
        </ul>
    </div>;
}
