import React, { ReactElement, useState, useEffect } from 'react'
import PropertiesPanel from './PropertiesPanel';
import { ResourceLink, SceneService } from "@proviz/api-services";
import { BaseWidget, } from "@proviz/proviz-sdk";
import { CategoryRenderer } from './CategoryRenderer';
import SceneVersionModal from '../../home/SceneVersionModal';
import Button from '../../common/button/Button';
import { AliasProperty } from './AliasProperty';
import ToggleFlip from '../../common/toggleFlip/ToggleFlip';
import { ManagerStore, userStore } from '../../../store';

export default function NodeProperties(): ReactElement {
    const sceneManager = ManagerStore( s => s.SceneManager )
    const [selection, setSelection] = useState<BaseWidget[]>(sceneManager.selectedNodes);
    const [, updateState] = React.useState<any>();
    const [sceneVersionModalData, setSceneVersionModalData] = useState<any>(null);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    // const [revisions, setRevisions] = useState([])
    const user = userStore( s => s.user)

    useEffect(() => {
        const updateSelection = () => {
            setSelection([...sceneManager.selectedNodes]);
        }
        sceneManager.attach('selectednode-update', updateSelection)
        return () => sceneManager.detach('selectednode-update', updateSelection)
    })

    useEffect(() => {
        sceneManager.attach('widgets-updated', forceUpdate);
        return () => sceneManager.detach('widgets-updated', forceUpdate);
    })

    useEffect(() => {
        sceneManager.attach('history-update', forceUpdate);
        return () => sceneManager.detach('history-update', forceUpdate);
    })

    useEffect(() => {
        sceneManager.attach('init-done', forceUpdate);
        return () => sceneManager.detach('init-done', forceUpdate);
    })

    // useEffect(() => {
    //     const getRevisions = async () => {
    //         if (!sceneManager.id) return;
    //         const revisions = await SceneService.getVersionHistory(sceneManager.id)
    //         if (revisions) setRevisions(revisions);
    //     }
    //     getRevisions()
    // }, [sceneManager.id])

    const categories: string[] = [];
    const properties = sceneManager.proVizScene.getProperties();
    properties.forEach((p) => {
        const c = p.category;
        if (categories.indexOf(c) === -1) {
            categories.push(c);
        }
    });

    return <div className="nodePropertiesPanel">
            {selection.length > 0 && <PropertiesPanel node={selection[selection.length - 1]} />} {/* selectCallback={props.selectCallback} />} */}
            {selection.length === 0 && sceneManager.apiScene && <div className="propertiesPanel">
                <div className='propertiesHeader'>
                    <h3>Properties</h3>
                </div>
                <div >
                    <Button onClick={() => setSceneVersionModalData(sceneManager.apiScene)} fullWidth={true}>
                        Browse Scene History
                    </Button>
                    <br />
                    <div>
                        <h3>Public Scene</h3>
                        <ToggleFlip setOnOff={sceneManager.apiScene.isPublic} onChange={() => { 
                            sceneManager.apiScene.isPublic = !sceneManager.apiScene.isPublic;
                            SceneService.update(sceneManager.apiScene).then(() => {
                                forceUpdate();
                            });
                        }} />
                    </div>
                    <br />
                    <AliasProperty/>
                    <br />
                    {/* <span className='smallText'>{revisions?.length} Revisions</span>  */}
                </div>

                <div className='propertiesPanel'>
                    {categories.map((cat) => {
                        return <CategoryRenderer key={cat} category={cat}
                            properties={properties.filter(x => x.category === cat)}
                            manager={sceneManager} />
                    })}

                </div>
                {user && user?.isSuperAdmin &&
                    <Button onClick={() => SceneService.package(sceneManager.id)} type='primary' fullWidth={true}>
                        Package
                    </Button>}
                {user && user?.isSuperAdmin &&
                    <Button onClick={() => sceneManager.proVizScene.getResourceLinks(sceneManager.id).then((entries) => {
                        const noDuplicates: ResourceLink[] = [];
                        entries.forEach((val) => {
                            if (noDuplicates.findIndex((r) => r.filename === val.filename) === -1) {
                                noDuplicates.push(val);
                            }
                        });
                        console.log(noDuplicates);
                        SceneService.setPackage(sceneManager.id, noDuplicates);
                    })} type='primary' fullWidth={true}>
                        Package Zip
                    </Button>}
            </div>}
            {sceneVersionModalData && <SceneVersionModal scene={sceneVersionModalData}
                close={() => setSceneVersionModalData(null)}
            />}
        </div>;
    
}
