import React, { useState } from "react";

const useInput = (initialValue: string = "", inputFn?: any) => {
    const [value, setValue] = useState<string>(initialValue);
    const defaultBindListeners = {
      value,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue( e.target?.value ) 
      },
    }
    return {
      value,
      setValue,
      reset: () => setValue(""),
      bind: {
        ...defaultBindListeners,
        ...inputFn
      },
    };
  };

  export default useInput