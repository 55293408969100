import { BaseWidget } from '.';
import { AnimationTrack } from './AnimationTrack';

export class AnimationController {
  animationTrack: AnimationTrack;
  widget: BaseWidget;
  currentTime: number = 0;
  maxTime: number;
  isPlaying: boolean;

  constructor(animationTrack: AnimationTrack, widget: BaseWidget) {
    this.animationTrack = animationTrack;
    this.widget = widget;
    this.currentTime = 0;
    this.maxTime = this.animationTrack.time;
    this.isPlaying = true;
  }

  public update(delta: number) {
    if (!this.isPlaying || !this.animationTrack) {
      return;
    }
    this.currentTime = Math.min(this.currentTime + delta, this.maxTime);
    this.widget.getProperties().forEach((property) => {
      if (this.animationTrack.getAnimationData(property) && property.set) {
        property.set(this.animationTrack.getAnimationValue(this.currentTime, property));
      }
    });
    if (this.currentTime >= this.maxTime) {
      this.isPlaying = false;
    }
  }

  public play() {
    this.isPlaying = true;
  }

  public stop() {
    this.isPlaying = false;
    this.currentTime = 0;
  }

  public pause() {
    this.isPlaying = false;
  }
}
