import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ARCSSectionWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'arcs-section';

  // Data
  public fileId: string = '';

  private currentStep: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ARCSSectionWidget.type;
    this.widgetName = 'ARCS Section';
    this.label = 'ARCS Section';
    this.category = 'ARCS';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Finished',
        name: 'finished',
      },
      {
        label: 'Started',
        name: 'started',
      },
    ];

    this.services.push({
      label: 'Start',
      name: 'start',
      desc: '<b>Starts the section</b>',
    });
    this.services.push({
      label: 'Next Step',
      name: 'next-step',
      desc: '<b>Starts the next step</b>',
    });
    this.services.push({
      label: 'Previous Step',
      name: 'previous-step',
      desc: '<b>Goes back to the previous step</b>',
    });

    this.addEventListener('service-start', (event) => {
      this.triggerProVizEvent('started', 'none');
      this.children.forEach((child: BaseWidget) => {
        child.setVisible(false);
      });
      this.currentStep = 0;
      if (this.children.length > 0) {
        this.children[this.currentStep].setVisible(true);
      }
    });
  }

  public nextStep() {
    this.children[this.currentStep].setVisible(false);
    this.currentStep += 1;
    if (this.currentStep >= this.children.length) {
      this.triggerProVizEvent('finished', 'none');
      return;
    }
    this.children[this.currentStep].setVisible(true);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('fileId', 'World Anchor', 'Data', 'world-anchor', 'string')];
  }

  public serialize(): any {
    const result = super.serialize();
    result.fileId = this.fileId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.fileId = data.fileId;
  }
}

ModuleService.Register(ARCSSectionWidget.type, ARCSSectionWidget);
