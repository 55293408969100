import ModelView from "./ModelView";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import { ReactElement, useEffect, useMemo } from "react";
import SceneView from "./SceneView";
import PrimaryNavigation from "./PrimaryNavigation";
import AnalyticsView from "./AnalyticsView";
import DashboardView from "./DashboardView";
import ResourcesView from "./resources/RoutesView";
import { PermissionEntity, PermissionType } from "@proviz/api-services";
import { userStore } from "../../store";
import CreateSceneView from "./CreateSceneView";

interface Props {
  children?: any;
}

export default function Home(props: Props): ReactElement {
  let { path } = useRouteMatch();
  let location = useLocation();
  useEffect(() => {
    let url = document.URL.split(".")[0];
    document.title =
      location.pathname === `${path}/files`
        ? `${
            url.includes("dev") || url.includes("localhost") ? "Dev " : " "
          }ProViz | Files`
        : `${
            url.includes("dev") || url.includes("localhost") ? "Dev " : " "
          }ProViz Studio`;
    return () => {
      if (document.title.includes("| Files")) {
        document.title = `${
          url.includes("dev") || url.includes("localhost") ? "Dev " : " "
        }ProViz Studio`;
      }
    };
  }, [location, path]);

  const hasPermission = userStore((s) => s.hasPermission);
  const permissions = userStore((x) => x.Permissions);
  const secureRoutes = useMemo(() => {
    return [
      {
        path: `${path}/scenes/new`,
        fallback: `${path}/models`,
        component: CreateSceneView,
        permission: {
          entity: PermissionEntity.Scene,
          type: PermissionType.View,
        },
      },
      {
        path: `${path}/scenes`,
        fallback: `${path}/models`,
        component: SceneView,
        permission: {
          entity: PermissionEntity.Scene,
          type: PermissionType.View,
        },
      },
      {
        path: `${path}/resources`,
        fallback: `${path}/models`,
        component: ResourcesView,
        permission: {
          entity: PermissionEntity.File,
          type: PermissionType.View,
        },
      },
    ];
  }, [permissions]);

  return (
    <>
      <PrimaryNavigation />
      <div className="homeRightView">
        <Switch>
          {secureRoutes
            .filter((r) =>
              hasPermission(r.permission.entity, r.permission.type)
            )
            .map((r) => (
              <Route path={r.path} component={r.component} />
            ))}
          <Route exact path={`${path}/models`} component={ModelView} />
          <Route exact path={`${path}/analytics`} component={AnalyticsView} />
          <Route component={DashboardView} />
        </Switch>
      </div>
    </>
  );
}
