import { TweenType } from "@proviz/proviz-sdk";
import { ReactElement } from "react";
import { SettableWidgetProperty } from "./SettableWidgetProperty";

interface Props {
    property: SettableWidgetProperty;
    header: ReactElement;
    forceUpdate: () => void;
}
/**
* Select from available methods for tweening.
*/
export const Tween = (props: Props) => {
    const { header, property } = props;

    return <div className="videoSelector">
        {header}
        <select value={property.get()} onChange={(e) => {
            property.set(e.target.value);
            props.forceUpdate();
        }}>
            <option value={TweenType.BounceEaseIn}>BounceEaseIn</option>
            <option value={TweenType.BounceEaseInOut}>BounceEaseInOut</option>
            <option value={TweenType.BounceEaseOut}>BounceEaseOut</option>
            <option value={TweenType.Linear}>Linear</option>
            <option value={TweenType.QuadraticEaseIn}>QuadraticEaseIn</option>
            <option value={TweenType.QuadraticEaseOut}>QuadraticEaseOut</option>
            <option value={TweenType.QuadraticEaseInOut}>QuadraticEaseInOut</option>
            <option value={TweenType.CubicEaseIn}>CubicEaseIn</option>
            <option value={TweenType.CubicEaseOut}>CubicEaseOut</option>
            <option value={TweenType.CubicEaseInOut}>CubicEaseInOut</option>
            <option value={TweenType.QuarticEaseIn}>QuarticEaseIn</option>
            <option value={TweenType.QuarticEaseOut}>QuarticEaseOut</option>
            <option value={TweenType.QuarticEaseInOut}>QuarticEaseInOut</option>
            <option value={TweenType.QuinticEaseIn}>QuinticEaseIn</option>
            <option value={TweenType.QuinticEaseOut}>QuinticEaseOut</option>
            <option value={TweenType.QuinticEaseInOut}>QuinticEaseInOut</option>
            <option value={TweenType.SineEaseIn}>SineEaseIn</option>
            <option value={TweenType.SineEaseOut}>SineEaseOut</option>
            <option value={TweenType.SineEaseInOut}>SineEaseInOut</option>
            <option value={TweenType.CircularEaseIn}>CircularEaseIn</option>
            <option value={TweenType.CircularEaseOut}>CircularEaseOut</option>
            <option value={TweenType.CircularEaseInOut}>CircularEaseInOut</option>
            <option value={TweenType.ExponentialEaseIn}>ExponentialEaseIn</option>
            <option value={TweenType.ExponentialEaseOut}>ExponentialEaseOut</option>
            <option value={TweenType.ExponentialEaseInOut}>ExponentialEaseInOut</option>
            <option value={TweenType.ElasticEaseIn}>ElasticEaseIn</option>
            <option value={TweenType.ElasticEaseOut}>ElasticEaseOut</option>
            <option value={TweenType.ElasticEaseInOut}>ElasticEaseInOut</option>
            <option value={TweenType.BackEaseIn}>BackEaseIn</option>
            <option value={TweenType.BackEaseOut}>BackEaseOut</option>
            <option value={TweenType.BackEaseInOut}>BackEaseInOut</option>
        </select>
    </div>;
};
