import {
  DirectionalLight,
  Object3D,
  PerspectiveCamera,
  Raycaster,
  Renderer,
  Scene,
  Texture,
  Vector2,
  Vector3,
  Group,
} from 'three';
import { SceneService, SceneType } from '@proviz/api-services';
import {
  encodingOptions,
  Helpers,
  setMaterialsNeedUpdate,
  toneMappingOptions,
  WidgetPropertyType,
  GroundMaterial,
  createPlane,
  BaseWidgetDataConnection,
} from '.';
import { ModuleService } from './moduleService';
import AnimatedSpriteMaterial from './utils/AnimatedSpriteMaterial';
import { BaseWidget } from './widgets/baseWidget';
import { BaseWidgetProperty, PropertyCategory } from './widgets/BaseWidgetProperty';
import { EventDispatcher } from './EventDispatcher';
import { LoadingIndicator } from './widgets/core/model/loadingIndicator';
import resizeShadowCameraFrustum from './utils/Shadow';
import {
  AmbientLightWidget,
  GroupWidget,
  PanoramaWidget,
  TextBox2DWidget,
  VideoWidget,
} from './widgets';
import { MultiLangOption } from './types';
import { ProVizSceneARCSConfig } from './ProVizSceneARCSConfig';
import { ProVizEventData, ProVizEventDataTypes } from './ProVizEventData';
import { ResourceLink } from '@proviz/api-services';
import { Physics } from './Physx';

export enum SceneMode {
  Play,
  Editor,
}
export const DefaultCameraPosition = { x: 1.8, y: 1.8, z: 2 };
export const DefaultCameraRotation = { x: 0, y: 0, z: 0 };
export type View = 'Normal' | 'Panorama';
type Dimensions = {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
};
const PanoramaTransitions = ['default', 'fade'];
const ControlTypes = ['Default', 'First Person'];

interface NextTrigger {
  handler: Function;
  sourceWidget?: BaseWidget;
  connWidget: BaseWidget;
  service: string;
  eventData: ProVizEventData;
}

export class ProVizScene extends EventDispatcher {
  public id: string;
  public nodes: BaseWidget[] = [];
  public properties: BaseWidgetProperty[];
  public sceneMode: SceneMode = SceneMode.Play;
  public abortController?: AbortController;
  public isDisposed: boolean = false;
  private initialized: boolean = false;

  // Data
  public css: string | null = null;
  public js: string | null = null;
  public startingCameraPosition = DefaultCameraPosition;
  public startingCameraRotation = DefaultCameraRotation;
  public fov: number = 60;
  public gyroControlsEnabled: boolean = false;
  public fullScreenEnabled: boolean = false;
  public showLanguageSelect: boolean = false;
  public showLanguageSelectOnLoad: boolean = false;
  /** Languages are represented by shortcodes. Eventually the interface to create
   * them should probably already have the most common options preset. For now users
   * add any shortcode they want. 'en' for english is always the in the list at creation. */
  public languages: string[] = ['en'];
  /** The full name that is displayed for each language shortcode. */
  public languageDisplayNames: MultiLangOption = {};
  public defaultLanguage: string = 'en';
  /** The title used by the language select modal. A different title is used by
   * each language. */
  public selectLanguageTitle: MultiLangOption = {};
  public languageSelectorBackgroundImg = '';
  public ccHelpText = { en: 'Click here for Closed Captioning' };
  public sceneTitle;

  public arEnabled?: boolean;
  public allowScalingInAR?: boolean;
  public autoRotate?: boolean;
  public rotateSpeed?: number;
  // These skybox fields mostly work, they are really just waiting for a use case to .
  // completed. If you are finishing them be sure to note how the panorama
  // widget interacts with the scene's background and how the studio and embed
  // Background managers behave differently.
  // public useSkyboxBackground: boolean;
  // public backgroundTextureSrc?: SkyboxTexture;
  // public backgroundSkyboxRotation?: Quaternion;
  public envMapId: string = '';
  public backgroundColor: string = '#d0d0d0';
  public fogOn: boolean = false;
  public fogColor: string = '#fff';
  public fogNear: number = 100;
  public fogFar: number = 400;
  public shadowIntensity: number = 0;
  public shadowSoftness: number = 0.5;
  public groundOn: boolean = false;
  public groundColor: string = '#eaeaea';
  public outputEncoding: string = 'sRGB';
  public toneMapping: string = 'Cineon';
  public toneMapExposure: number = 1;
  public ambientIntensity: number = 0.75;
  public ambientColor: string = '#fff';
  public physCorrectLight: boolean = false;

  /** The version of the proviz-sdk. Does not correspond to the package version or release versoin
   * this number is incremented whenever there is a change to the data's format. If this change is
   * breaking a migration should be added and this number incremented by 1.
   * Note that the scene version is not assigned in the deserialize function because the data will
   * be migrated to the expected shape for this version of the code. */
  public sceneVersion: number = 3;
  public panoramaTransition: string = 'default';
  public controlType: string = 'default';

  public sceneType: SceneType = 'Standard';

  public backgroundTexture?: Texture;
  public cameraPosition = DefaultCameraPosition;
  public prevCameraPosition?: { x: number; y: number; z: number };
  public cameraRotation?: { x: number; y: number; z: number };
  /** Rotation of any skybox that may be applied. Adjusts for camera angle during capture. */
  public perspectiveRotation: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 };
  public updateView = false;
  public view: View = 'Normal';
  /** A call back to be executed when leaving a viewing mode. Should cleanup any resources
   * grabbed by for example skyboxes. Not executed when moving BETWEEN panorams. Only when exiting
   * panorama viewing mode. Attached to the exit panorama button. */
  public leaveModeFn?: () => void;
  /** Whether to display a panorama exit button. Whether a panorama can be exited is controlled by
   * the widget and is not a scene level setting. */
  public showPanoramaButton: boolean = false;
  /** Dimensions of the renderer's DOM element */
  public dimensions?: Dimensions;

  public selectedLanguage: string = 'en';

  public arcsConfig: ProVizSceneARCSConfig | undefined = undefined;

  public scene: Scene;
  sceneRoot: Group; //Group widget handler for the scene
  public physics: Physics | undefined;
  public groundActor: any;

  private raycaster: Raycaster;
  public renderer: Renderer | undefined;

  public camera: PerspectiveCamera;
  private ground?: GroundMaterial;
  sceneManager: any;

  private debugHandler: DebugHandler | undefined = undefined;
  public isDev: boolean = false;
  public isDebugging: boolean = false;

  constructor(
    id: string,
    scene: Scene,
    camera: PerspectiveCamera,
    renderer?: Renderer,
    mode?: SceneMode,
    sceneType?: SceneType,
    abortController?: AbortController,
    physicsScene?: Physics,
    isDev?: boolean,
  ) {
    super();
    this.id = id;

    this.isDev = isDev ?? false;
    this.dev();
    this.nodes = [];
    this.sceneMode = mode || SceneMode.Play;
    this.scene = scene; //Ovewrite the scene with the xr8 scene
    this.sceneRoot = new Group();
    this.camera = camera;
    this.renderer = renderer;
    this.raycaster = new Raycaster();
    this.sceneType = sceneType ?? this.sceneType;
    if (renderer) {
      this.dimensions = renderer.domElement.getBoundingClientRect();
    }
    this.abortController = abortController;
    // Core
    if (this.sceneMode === SceneMode.Editor) {
      this.properties = [
        this.createProperty(
          'sceneType',
          'Scene Type',
          'Core',
          'select',
          'string',
          true,
          undefined,
          undefined,
          () => [
            { key: 'Standard', label: 'Standard' },
            { key: 'ARCS', label: 'ARCS' },
            { key: 'InspectCollect', label: 'InspectCollect' },
            { key: 'WorkInstruct', label: 'WorkInstruct' },
            { key: 'Template', label: 'Template' },
          ],
          `Set the scene type to determine which platform this scene is built for. Standard scenes target the web. ARCS,
           InspectCollect and WorkInstruct scenes are used by experiences built with Unity and can target mobile devices,
           Oculus and the Hololens natively.`,
        ),
        this.createProperty(
          'controlType',
          'Controls Type',
          'Core',
          'select',
          'string',
          true,
          () => this.controlType,
          (val: string) => {
            this.controlType = val;
          },
          () =>
            ControlTypes.map((x) => {
              return {
                key: x,
                label: x,
              };
            }),
          'Select which controls you want in your scene. This will not effect controls within panoramas.',
        ),

        // Background Settings
        // this.properties.push(
        //   this.createProperty(
        //     'useSkyboxBackground',
        //     'Use Skybox Background',
        //     'Background',
        //     'boolean',
        //     true,
        //   ),
        // );
        // this.properties.push(this.createProperty('backgroundTextureSrc', 'Background Texture', 'Background', 'texture-360', true));
        // this.properties.push(this.createProperty('backgroundSkyboxRotation', 'Background Skybox Rotation', 'Background', 'vector3', true));
        this.createProperty(
          'envMapId',
          'Environment Map (hdr)',
          'Lighting',
          'env-map',
          'string',
          true,
          undefined,
          (data: string) => {
            this.envMapId = data;
            this.dispatchEvent('background-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'background-update',
            });
          },
          undefined,
          'Upload an hdr file to light the scene. HDR files are scaled to 1K at runtime so 1K HDR uploads are preffered.',
        ),
        this.createProperty(
          'backgroundColor',
          'Background Color',
          'Environment',
          'color',
          'string',
          true,
          undefined,
          (data: any) => {
            this.backgroundColor = data;
            this.dispatchEvent('background-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'background-update',
            });
          },
        ),
        this.createProperty(
          'fogOn',
          'Fog',
          'Environment',
          'bool',
          'boolean',
          true,
          undefined,
          (data: boolean) => {
            this.fogOn = data;
            this.dispatchEvent('background-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'background-update',
            });
          },
        ),
        this.createProperty(
          'fogColor',
          'Fog Color',
          'Environment',
          'color',
          'string',
          true,
          undefined,
          (data: string) => {
            this.fogColor = data;
            this.dispatchEvent('background-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'background-update',
            });
          },
        ),
        this.createProperty(
          'fogNear',
          'Fog Near',
          'Environment',
          'constrained-number',
          'number',
          true,
          undefined,
          (data: number) => {
            this.fogNear = data;
            this.dispatchEvent('background-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'background-update',
            });
          },
          () =>
            [0, this.fogFar].map((x) => {
              return { key: x, label: x.toString() };
            }),
        ),
        this.createProperty(
          'fogFar',
          'Fog Far',
          'Environment',
          'constrained-number',
          'number',
          true,
          undefined,
          (data: number) => {
            this.fogFar = data;
            this.dispatchEvent('background-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'background-update',
            });
          },
          () =>
            [0, this.fogFar].map((x) => {
              return { key: x, label: x.toString() };
            }),
        ),
        // Language
        this.createProperty(
          'showLanguageSelect',
          'Show Language Select',
          'Language',
          'bool',
          'boolean',
          true,
          undefined,
          undefined,
          undefined,
          'Toggle this on to show a language selector button in the bottom right corner.',
        ),
        this.createProperty(
          'showLanguageSelectOnLoad',
          'Show Language Select on Load',
          'Language',
          'bool',
          'boolean',
          true,
          undefined,
          undefined,
          undefined,
          'Show the language selector menu when the scene is loaded.',
        ),
        this.createProperty('languages', 'Language Options', 'Language', 'list', 'list', true),
        this.createProperty(
          'defaultLanguage',
          'Default Language',
          'Language',
          'string',
          'string',
          true,
        ),
        this.createProperty(
          'languageSelectorBackgroundImg',
          'Language Selector Background Image',
          'Language',
          'string',
          'string',
          true,
        ),
        this.createProperty(
          'languageDisplayNames',
          'Language Display Names',
          'Language',
          'multi-lang-opts',
          'string',
          true,
        ),
        this.createProperty(
          'selectLanguageTitle',
          'Select Language Title',
          'Language',
          'multi-lang-opts',
          'string',
          true,
        ),
        this.createProperty(
          'ccHelpText',
          'Closed Caption Help Text',
          'Language',
          'multi-lang-opts',
          'string',
          true,
        ),
        // Advanced
        this.createProperty(
          'fullScreenEnabled',
          'Show Fullscreen Button',
          'Advanced',
          'bool',
          'boolean',
          true,
          undefined,
          undefined,
          undefined,
          'Toggle whether to show a full screen button in the corner of the scene viewer.',
        ),
        this.createProperty(
          'gyroControlsEnabled',
          'Gyro Controls Enabled',
          'Advanced',
          'bool',
          'boolean',
          true,
          undefined,
          undefined,
          undefined,
          `Toggle whether to show a gyro controls button to let users navigate with device orientaion on mobile. Only active in
         panorama control mode. This feature will slow down performance.`,
        ),
        this.createProperty(
          'groundOn',
          'Show a floor in the scene.',
          'Environment',
          'bool',
          'boolean',
          true,
          undefined,
          (data: boolean) => {
            this.groundOn = data;
            this.updateGround();
          },
        ),
        this.createProperty(
          'groundColor',
          'Ground Color.',
          'Environment',
          'color',
          'string',
          true,
          undefined,
          (data: string) => {
            this.groundColor = data;
            this.updateGround();
          },
        ),
        this.createProperty(
          'panoramaTransition',
          'Panorama Transition',
          'Advanced',
          'select',
          'string',
          true,
          () => this.panoramaTransition,
          (val: string) => {
            this.panoramaTransition = val;
          },
          () =>
            PanoramaTransitions.map((x) => {
              return { key: x, label: x };
            }),
          'ALPHA: use default unless developing. Choose which effect to use when transitioning between panorams.',
        ),
        this.createProperty(
          'toneMapping',
          'Tone Mapping',
          'Advanced',
          'select',
          'string',
          true,
          undefined,
          (data: any) => {
            this.toneMapping = data;
            this.dispatchEvent('renderer-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'renderer-update',
            });
          },
          () =>
            Object.keys(toneMappingOptions).map((x) => {
              return { key: x, label: x };
            }),
          'Set tone mapping of renderer.',
        ),
        this.createProperty(
          'toneMapExposure',
          'Tone Mapping Exposure',
          'Advanced',
          'constrained-number',
          'number',
          true,
          undefined,
          (data: any) => {
            this.toneMapExposure = data;
            this.dispatchEvent('renderer-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'renderer-update',
            });
          },
          () =>
            [0, 2].map((x) => {
              return { key: x, label: x.toString() };
            }),
          'Set tone mapping of renderer.',
        ),
        this.createProperty(
          'outputEncoding',
          'Output Encoding',
          'Advanced',
          'select',
          'string',
          true,
          undefined,
          (data: any) => {
            this.outputEncoding = data;
            this.dispatchEvent('renderer-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'renderer-update',
            });
          },
          () =>
            Object.keys(encodingOptions).map((x) => {
              return { key: x, label: x };
            }),
        ),
        this.createProperty(
          'physCorrectLight',
          'Physically Correct Lighting',
          'Lighting',
          'bool',
          'boolean',
          true,
          undefined,
          (data: any) => {
            this.physCorrectLight = data;
            this.dispatchEvent('renderer-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'renderer-update',
            });
          },
          undefined,
          'If true light intensity will decrease over distance.',
        ),
        this.createProperty(
          'ambientIntensity',
          'Ambient Light Intensity',
          'Lighting',
          'constrained-number',
          'number',
          true,
          undefined,
          (data) => {
            console.log(data);
            this.ambientIntensity = data;
            this.dispatchEvent('light-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'light-update',
            });
          },
          () =>
            [0, 5].map((x) => {
              return { key: x, label: x.toString() };
            }),
          'This will determine the intensity of the ambient light source in scenes. Set to 0 to turn off.',
        ),
        this.createProperty(
          'ambientColor',
          'Ambient Light Color',
          'Lighting',
          'color',
          'string',
          true,
          undefined,
          (data) => {
            this.ambientColor = data;
            this.dispatchEvent('light-update', {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: '',
              event: 'light-update',
            });
          },
          () =>
            [0, 5].map((x) => {
              return { key: x, label: x.toString() };
            }),
          'This will determine the color of the ambient light source in scenes. Set to 0 to turn off.',
        ),
        this.createProperty(
          'startingCameraPosition',
          'Starting Camera Position',
          'Advanced',
          'vec3-camera-pos',
          'vector3',
          true,
          undefined,
          undefined,
          () => [DefaultCameraPosition],
        ),
        this.createProperty(
          'startingCameraRotation',
          'Starting Camera Rotation',
          'Advanced',
          'vec3-camera-rot',
          'vector3',
          true,
          undefined,
          undefined,
          () => [DefaultCameraRotation],
        ),
        this.createProperty(
          'fov',
          'Field of view',
          'Advanced',
          'constrained-number',
          'number',
          true,
          undefined,
          (data) => {
            this.fov = data;
            this.camera.fov = data;
            this.camera.updateProjectionMatrix();
          },
          () =>
            [15, 150].map((x) => {
              return { key: x, label: x.toString() };
            }),
        ),
        this.createProperty('css', 'CSS', 'Experimental', 'string', 'string', true),
        this.createProperty('js', 'JS', 'Experimental', 'string', 'string', true),
      ];
      if (this.sceneType === 'ModelViewer') {
        this.properties.push(
          this.createProperty('arEnabled', 'Show AR Button', 'Core', 'bool', 'boolean', true),
          this.createProperty(
            'allowScalingInAR',
            'Allow Scaling in AR',
            'Core',
            'bool',
            'boolean',
            true,
          ),
          this.createProperty(
            'autoRotate',
            'Auto Rotate Camera',
            'Core',
            'bool',
            'boolean',
            true,
            undefined,
            undefined,
            undefined,
            'Whether camera should rotate around the model.',
          ),
          this.createProperty(
            'rotateSpeed',
            'Rotation Speed',
            'Core',
            'constrained-number',
            'number',
            true,
            undefined,
            undefined,
            () =>
              [0, 10].map((x) => {
                return { key: x, label: x.toString() };
              }),
          ),
        );
      }
    } else {
      this.properties = [];
      this.physics = physicsScene;
      if (this.physics) {
        this.physics.init(this);
      }
    }
  }

  public getProperties() {
    const result = [...this.properties];
    if (this.sceneType === 'ARCS' && this.arcsConfig) {
      result.push(...this.arcsConfig.getProperties());
    }
    return result;
  }

  public getScene(): Scene {
    return this.scene;
  }

  public get parentEl(): HTMLElement | undefined | null {
    return this.renderer?.domElement?.parentElement;
  }

  private triggerDebugStateChange() {
    this.nodes.forEach((w: BaseWidget) => {
      w.debugState(this.isDebugging);
    });
  }

  public update(delta: number) {
    if (this.isDebugging && (this.debugHandler == undefined || !this.debugHandler.isPaused())) {
      this.isDebugging = false;
      this.triggerDebugStateChange();
    } else if (
      !this.isDebugging &&
      this.debugHandler != undefined &&
      this.debugHandler.isPaused()
    ) {
      this.isDebugging = true;
      this.triggerDebugStateChange();
      return;
    } else if (!this.initialized) {
      return;
    }
    AnimatedSpriteMaterial.Update(delta);
    LoadingIndicator.Update(delta);
    if (this.physics) {
      this.physics.update(delta);
    }
    this.nodes.forEach((w: BaseWidget) => {
      w.update(delta);
    });
  }

  public serialize(): any {
    const result: any = {};
    result.widgets = this.nodes.map((n: BaseWidget) => n.serialize());
    result.js = this.js;
    result.css = this.css;
    {
      const { x, y, z } = this.startingCameraPosition;
      result.startingCameraPosition = { x, y, z };
    }
    {
      const { x, y, z } = this.startingCameraRotation;
      result.startingCameraRotation = { x, y, z };
    }
    result.gyroControlsEnabled = this.gyroControlsEnabled;
    result.fullScreenEnabled = this.fullScreenEnabled;
    result.languages = this.languages;
    result.defaultLanguage = this.defaultLanguage;
    result.showLanguageSelect = this.showLanguageSelect;
    result.selectLanguageTitle = this.selectLanguageTitle;
    result.languageSelectorBackgroundImg = this.languageSelectorBackgroundImg;
    result.languageDisplayNames = this.languageDisplayNames;
    result.showLanguageSelectOnLoad = this.showLanguageSelectOnLoad;
    result.ccHelpText = this.ccHelpText;

    result.sceneTitle = this.sceneTitle;

    result.panoramaTransition = this.panoramaTransition;
    result.controlType = this.controlType;
    result.sceneVersion = this.sceneVersion;
    if (this.sceneType === 'ModelViewer') {
      result.arEnabled = this.arEnabled;
      result.allowScalingInAR = this.allowScalingInAR;
      result.autoRotate = this.autoRotate;
      result.rotateSpeed = this.rotateSpeed;
    }
    // result.useSkyboxBackground = this.useSkyboxBackground;
    // result.backgroundTextureSrc = this.backgroundTextureSrc;
    // result.backgroundSkyboxRotation = this.backgroundSkyboxRotation;
    result.backgroundColor = this.backgroundColor;
    result.envMapId = this.envMapId;
    result.fogOn = this.fogOn;
    result.fogColor = this.fogColor;
    result.fogNear = this.fogNear;
    result.fogFar = this.fogFar;
    result.shadowIntensity = this.shadowIntensity;
    result.shadowSoftness = this.shadowSoftness;

    result.groundOn = this.groundOn;
    result.groundColor = this.groundColor;
    result.toneMapping = this.toneMapping;
    result.toneMapExposure = this.toneMapExposure;
    result.outputEncoding = this.outputEncoding;
    result.physCorrectLight = this.physCorrectLight;
    result.ambientIntensity = this.ambientIntensity;
    result.ambientColor = this.ambientColor;
    if (this.sceneType === 'ARCS' && this.arcsConfig) {
      result.arcsConfig = this.arcsConfig.serialize();
    }
    result.fov = this.fov;
    return result;
  }

  public async deserialize(data: any, sceneType: SceneType = 'Standard') {
    data = await this.migrate(data);

    this.sceneType = sceneType;

    this.sceneTitle = data.sceneTitle;

    if (this.sceneType === 'ARCS') {
      this.arcsConfig = new ProVizSceneARCSConfig();
      this.arcsConfig.deserialize(data);
    }

    if (data.languages) {
      if (Array.isArray(data.languages)) {
        this.languages = data.languages;
      } else {
        this.languages = data.languages.split(',');
      }
    }
    if (data.defaultLanguage) {
      this.defaultLanguage = data.defaultLanguage;
    }
    if (data.css) {
      this.css = data.css;
    }
    if (data.js) {
      this.js = data.js;
    }

    if (data.startingCameraPosition) {
      const { x, y, z } = data.startingCameraPosition;
      this.startingCameraPosition = { x, y, z };
    }
    if (data.startingCameraRotation) {
      const { x, y, z } = data.startingCameraRotation;
      this.startingCameraRotation = { x, y, z };
    }
    this.gyroControlsEnabled = data.gyroControlsEnabled ?? this.gyroControlsEnabled;
    this.fullScreenEnabled = data.fullScreenEnabled ?? this.fullScreenEnabled;
    this.showLanguageSelect = data.showLanguageSelect ?? this.showLanguageSelect;
    this.selectLanguageTitle = data.selectLanguageTitle ?? this.selectLanguageTitle;
    this.languageSelectorBackgroundImg =
      data.languageSelectorBackgroundImg ?? this.languageSelectorBackgroundImg;
    this.languageDisplayNames = data.languageDisplayNames ?? this.languageDisplayNames;
    this.showLanguageSelectOnLoad = data.showLanguageSelectOnLoad ?? this.showLanguageSelectOnLoad;
    this.ccHelpText = data.ccHelpText ?? this.ccHelpText;

    this.panoramaTransition = data.panoramaTransition ?? this.panoramaTransition;
    this.controlType = data.controlType ?? this.controlType;
    if (this.sceneType === 'ModelViewer') {
      this.arEnabled = data.arEnabled;
      this.allowScalingInAR = data.allowScalingInAR ?? false;
      this.autoRotate = data.autoRotate ?? true;
      this.rotateSpeed = data.rotateSpeed ?? 1;
    }
    // this.useSkyboxBackground = data.useSkyboxBackground;
    // this.backgroundTextureSrc = data.backgroundTextureSrc;
    // this.backgroundSkyboxRotation = data.backgroundSkyboxRotation;
    this.backgroundColor = data.backgroundColor;
    this.envMapId = data.envMapId;
    this.fogOn = data.fogOn;
    this.fogColor =
      (typeof data.fogColor !== 'string' ? this.fogColor : data.fogColor) ?? this.fogColor;
    // some data was corrupted but the conditional can probably be removed in a month or two
    this.fogNear = data.fogNear ?? this.fogNear;
    this.fogFar = data.fogFar ?? this.fogFar;
    this.shadowIntensity = data.shadowIntensity;
    this.shadowSoftness = data.shadowSoftness;
    this.groundOn = data.groundOn ?? this.groundOn;
    this.groundColor = data.groundColor ?? this.groundColor;
    this.updateGround();
    this.toneMapping = data.toneMapping ?? this.toneMapping;
    this.toneMapExposure = data.toneMapExposure ?? this.toneMapExposure;
    this.outputEncoding = data.outputEncoding ?? this.outputEncoding;
    this.physCorrectLight = data.physCorrectLight ?? this.physCorrectLight;
    this.ambientIntensity = data.ambientIntensity ?? this.ambientIntensity;
    this.ambientColor = data.ambientColor ?? this.ambientColor;
    this.fov = data.fov ?? this.fov;
    this.camera.fov = this.fov;
    this.camera.updateProjectionMatrix();

    // Deserialize children
    if (data.widgets) {
      for (const widget of data.widgets) {
        try {
          const node = await this.deserializeWidget(widget);
          this.add(node);
        } catch (e) {
          console.error(`Could not load widget ${widget.data} ${e}`);
        }
      }
    }
    this.scene.add(this.sceneRoot);

    for (const widget of this.nodes) {
      await widget.init();
    }

    this.initialized = true;

    this.dispatchEvent('loaded', {
      data: undefined,
      dataType: 'none',
      sourceWidgetId: '',
      event: 'loaded',
    });
  }

  dispose() {
    this.isDisposed = true;
    this.removeEventListeners();
    this.abortController?.abort();
    this.scene.traverse((o) => {
      delete o.userData.widget;
      // @ts-ignore
      delete o.userData;
    });
    for (let i = 0; i < this.nodes.length; i++) {
      this.nodes[i].dispose();
      delete this.nodes[i];
    }
    // @ts-ignore
    delete this.scene;
    if (this.groundActor) {
      this.groundActor.release();
      delete this.groundActor;
    }
    if (this.physics) {
      this.physics.dispose();
    }
    PanoramaWidget.eventHandler.removeEventListeners();
    VideoWidget.eventHandler.removeEventListeners();
    TextBox2DWidget.eventHandler.removeEventListeners();
  }
  /**
   * Migrate modifies the data it receives in order
   * to reformulate it into the shape that the current version of the software expects.
   * It uses a switch statement and should NOT include any break stateements.
   *
   * It should Not have any break statements because it applies a
   * migration for each version after the data's version.
   * This takes advantage of the fall through behavior of switch statements.
   *  */
  protected async migrate(data: any) {
    if (!data.sceneVersion) {
      data.sceneVersion = 1;
    }
    switch (data.sceneVersion) {
      case 1:
        if (this.sceneType === 'ModelViewer') {
          const ambientWidget = data.widgets.find((w) => w.widgetType === AmbientLightWidget.type);
          data.ambientIntensity = ambientWidget.intensity;
          data.ambientColor = ambientWidget.color;
          data.widgets = data.widgets.filter((w) => w.WidgetType !== AmbientLightWidget.type);
        }
      case 2: {
        if (data.languageDisplayNames) {
          const langMappings = {};
          data.languageDisplayNames.split(',').forEach((l: string) => {
            const parts = l.split(':');
            langMappings[parts[0]] = parts[1];
          });
          data.languageDisplayNames = langMappings;
        }
        if (data.selectLanguageTitle) {
          const langMappings = {};
          data.selectLanguageTitle.split(',').forEach((t: string) => {
            const parts = t.split(':');
            if (parts[1]) {
              try {
                langMappings[parts[0]] = decodeURIComponent(parts[1]);
              } catch (e) {
                console.warn("can't decode", parts[1]);
                langMappings[parts[0]] = '';
              }
            }
          });
          data.selectLanguageTitle = langMappings;
        }
      }
    }
    return data;
  }

  public add(node: BaseWidget) {
    this.nodes.push(node);
    // console.log("Use the root instead", node)
    this.sceneRoot.add(node.renderNode);
    // this.scene.add(node.renderNode);
  }

  public remove(node: BaseWidget) {
    node.remove();
    if (node.parent) {
      node.parent.removeNode(node);
    }
    this.nodes = this.nodes.filter((x) => x !== node);
  }

  public orphan(node: BaseWidget) {
    node.unparent();
    this.nodes = this.nodes.filter((x) => x !== node);
  }

  public getSelectables(): Object3D[] {
    let result: Object3D[] = [];
    for (const node of this.nodes) {
      const selectables = node.getSelectables();
      result = result.concat(selectables);
    }
    return result;
  }

  public getById(id: string): BaseWidget | undefined {
    for (const node of this.nodes) {
      const result: BaseWidget | undefined = node.getById(id);
      if (result) {
        return result;
      }
    }
    return undefined;
  }

  public getIdsWithConnections() {
    const result: string[] = [];
    for (const node of this.nodes) {
      const connectionIds = node.getIdsWithConnections();
      for (const id of connectionIds) {
        if (!result.find((x) => x === id)) {
          result.push(id);
        }
      }
    }
    return result;
  }

  public getIdsWithFlow() {
    const result: string[] = [];
    for (const node of this.nodes) {
      const connectionIds = node.getIdsWithFlow();
      for (const id of connectionIds) {
        if (!result.find((x) => x === id)) {
          result.push(id);
        }
      }
    }
    return result;
  }

  /** Clone a node in the scene. Replaces links between nodes. */
  public async clone(node: BaseWidget) {
    const data = node.serialize();
    return this.cloneFromData(data, node.parent);
  }

  public async cloneFromData(data: any, parent?: BaseWidget) {
    const replaceConnectionIds = (node: BaseWidget, updatedId: string, existingId: string) => {
      node.connections.forEach((conn: BaseWidgetDataConnection) => {
        if (conn.targetId == existingId) {
          conn.targetId = updatedId;
        }
      });
      node.children.forEach((child: BaseWidget) =>
        replaceConnectionIds(child, updatedId, existingId),
      );
    };

    const idHelper = (node: BaseWidget) => {
      const updatedId = Helpers.uuidv4();
      const existingId = node.id;
      replaceConnectionIds(data, updatedId, existingId);
      node.id = updatedId;
      node.children.forEach((child: BaseWidget) => idHelper(child));
    };
    idHelper(data);

    const clonedNode = await this.deserializeWidget(data, parent);
    if (parent) {
      parent.addChild(clonedNode);
    } else {
      this.add(clonedNode);
    }
    clonedNode.init();
    return clonedNode;
  }

  public setLanguage(language: string) {
    if (this.selectedLanguage === language) {
      return;
    }
    if (this.languages.includes(language)) {
      this.selectedLanguage = language;
    } else {
      this.selectedLanguage = this.defaultLanguage;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('lang', this.selectedLanguage);
    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      urlSearchParams.toString();
    window.history.pushState({ path: newUrl }, '', newUrl);
    this.dispatchEvent('language-change', {
      data: undefined,
      dataType: 'none',
      sourceWidgetId: '',
      event: 'language-change',
    });
  }

  public setScene(scene: Scene) {
    console.log('Changing scene to', scene);
    this.scene = scene;
    this.scene.attach(this.sceneRoot);
  }

  public updateGround() {
    if (this.groundOn) {
      if (!this.ground) {
        // Create the ground if it doesn't exist
        this.ground = createPlane(this.groundColor);
        this.ground.receiveShadow = true;
        this.scene.add(this.ground);
      } else {
        this.ground.material.color.setStyle(this.groundColor);
        this.ground.receiveShadow = true;
        this.ground.material.needsUpdate = true;
      }
      this.ground.material.visible = true;
      if (!this.groundActor && this.physics) {
        this.physics.addGroundPlane(this.groundActor);
      }
    } else {
      if (this.ground) {
        this.ground.material.visible = false;
      }
    }
  }

  public resizeShadowCameraFrustum = (light: DirectionalLight) =>
    resizeShadowCameraFrustum(light, this.scene);

  public async handleClick(camera: PerspectiveCamera, mouse: Vector2) {
    const objectsToIntersect = this.getClickables();
    this.raycaster.setFromCamera(mouse, camera);
    // recursive is true by default for >= r133
    const intersects = this.raycaster.intersectObjects(objectsToIntersect, true);
    for (const intersect of intersects) {
      const widget = this.getWidget(intersect.object);
      if (widget) {
        if (intersect.uv) {
          const hit = { x: intersect.uv.x, y: intersect.uv.y };

          // Ask the widget to verify the click, it could be in the
          // transparent part of the texture
          if (!widget.verifyClick(intersect.point, intersect.uv)) {
            continue;
          }

          widget.dispatchEvent('clicked', {
            data: hit,
            dataType: 'vector2',
            sourceWidgetId: widget.id,
            event: 'clicked',
          });

          for (const conn of widget.connections.filter((x) => x.event === 'clicked')) {
            this.dispatchService(conn.targetId, conn.targetService, conn.data, {
              data: undefined,
              dataType: 'none',
              sourceWidgetId: widget.id,
              event: 'clicked',
            });
          }

          // Only handle one intersect
          break;
        }
      }
    }
  }

  private lastHover: BaseWidget | undefined = undefined;
  public async handleMouseMove(camera: PerspectiveCamera, mouse: Vector2) {
    const objectsToIntersect = this.getHoverables();
    this.raycaster.setFromCamera(mouse, camera);
    // recursive is true by default for >= r133
    const intersects = this.raycaster.intersectObjects(objectsToIntersect, true);
    let collided = false;

    //Keep the default cursor as the "grab"

    for (const intersect of intersects) {
      const widget = this.getWidget(intersect.object);
      if (widget) {
        // if (intersect.uv && !widget.verifyClick(intersect.point, intersect.uv)) {
        //   // might have hovered over transparent part of texture
        //   continue;
        // }

        collided = true;
        if (this.lastHover !== undefined && widget != this.lastHover) {
          this.lastHover.onHoverLeave();
        }
        this.lastHover = widget;
        widget.onHoverEnter();
        //Change cursor to the pointer on widget being "clickable"
        if (widget.clickable) {
          if (this.renderer) this.renderer.domElement.style.cursor = 'pointer';
        }
        break;
      }
    }

    // Default to grab cursor
    if (!collided && this.renderer) {
      this.renderer.domElement.style.cursor = 'grab';
    }

    if (!collided && this.lastHover !== undefined) {
      this.lastHover.onHoverLeave();
      this.lastHover = undefined;
    }
  }

  public dispatchService(
    id: string,
    service: string,
    connectionData: any,
    eventData: ProVizEventData,
  ) {
    const connWidget = this.getById(id);
    if (connWidget) {
      const sourceWidget = this.getById(eventData.sourceWidgetId);
      const sourceWidgetName =
        sourceWidget?.label || sourceWidget?.widgetName || eventData.sourceWidgetId;
      console.log(
        `%c '${connWidget.label}' trigger service '${service}' from handling widget '${sourceWidgetName}' event '${eventData.event}'`,
        'background: #000; color: #bada55',
      );

      this.serviceDispatch(sourceWidget, connWidget, service, eventData, () => {
        connWidget.dispatchEvent(`service-${service}`, eventData);
      });
    }
  }

  private serviceDispatch(
    sourceWidget: BaseWidget | undefined,
    connWidget: BaseWidget,
    service: string,
    eventData: ProVizEventData,
    handler: Function,
  ) {
    if (this.isDev && this.debugHandler) {
      this.debugHandler.handleTrigger({
        handler,
        sourceWidget,
        connWidget,
        service,
        eventData,
      });
    } else {
      handler();
    }
  }

  /** Set the background texture value this is primarily for skyboxes. */
  public setBackground(texture: Texture | undefined) {
    this.backgroundTexture = texture;
    this.dispatchEvent('background-change', {
      data: undefined,
      dataType: 'none',
      sourceWidgetId: '',
      event: 'background-change',
    });
  }

  /** Set camera position */
  public setPosition(position: { x: number; y: number; z: number }) {
    const { x, y, z } = this.cameraPosition;
    this.prevCameraPosition = { x, y, z };
    this.cameraPosition = position;
  }

  /** Set camera rotation */
  public setRotation(rotation?: { x: number; y: number; z: number }) {
    this.cameraRotation = rotation;
    this.dispatchEvent('rotation-update', {
      data: undefined,
      dataType: 'none',
      sourceWidgetId: '',
      event: 'rotation-update',
    });
  }

  /** Set that all materials in the scene need to be updated.
   * This is used less and less frequently with recent three.js updates. */
  public setMaterialsNeedUpdate() {
    setMaterialsNeedUpdate(this.scene);
  }

  /** Get the screen coordinates of an object based off of a camera.. */
  public toScreenPosition(obj: Object3D, camera: PerspectiveCamera, renderer: any): Vector3 {
    if (!renderer || !camera || !this.dimensions) {
      return new Vector3();
    }
    const size = this.dimensions;
    const vector = obj.getWorldPosition(new Vector3());
    vector.project(camera);
    vector.x = Math.floor(((vector.x + 1) / 2) * size.width);
    vector.y = -Math.floor(((vector.y - 1) / 2) * size.height);
    return vector;
  }

  /** Recursively determin whether all relevant widgets are 'in range'
   * from the camera. */
  public calculateInRange(position: Vector3) {
    let updateRange = 5;
    let widgetPos = new Vector3();
    const _calculateInRange = (w: BaseWidget) => {
      /** at this time the in range property is only used by the panorama
       * widget so we only do the math for panorama widgets.*/
      if (w.widgetType === PanoramaWidget.type) {
        w.renderNode.getWorldPosition(widgetPos);
        const dist = widgetPos.distanceTo(position);
        w.inRange = dist < updateRange;
      }
      w.children.forEach(_calculateInRange);
    };
    this.nodes.forEach(_calculateInRange);
  }

  public getWidgetsOfType(t: string): BaseWidget[] {
    const result: BaseWidget[] = [];
    for (const node of this.nodes) {
      const widgetsOfType = node.getWidgetsOfType(t);
      for (const c of widgetsOfType) {
        result.push(c);
      }
    }
    return result;
  }

  public clear() {
    this.nodes = [];
  }

  public getWidget(o: Object3D): BaseWidget | undefined {
    if (o.userData.widget) {
      return o.userData.widget as BaseWidget;
    }

    if (o.parent) {
      return this.getWidget(o.parent);
    }

    return undefined;
  }

  /** Get all clickable objects in the scene. */
  public getClickables(): Object3D[] {
    const result: Object3D[] = [];
    for (const node of this.nodes) {
      const clickables = node.getClickable();
      for (const c of clickables) {
        result.push(c);
      }
    }
    return result;
  }

  /** Get all hoverable objects in the scene. */
  private getHoverables(): Object3D[] {
    const result: Object3D[] = [];
    for (const node of this.nodes) {
      const clickables = node.getHoverable();
      for (const c of clickables) {
        result.push(c);
      }
    }
    return result;
  }

  /** Set up a widget from provided data. */
  private async deserializeWidget(data: any, parent?: BaseWidget) {
    const WidgetType = ModuleService.Get(data.type);
    if (!WidgetType) {
      console.error(
        'Widget type not found',
        WidgetType,
        data.type,
        data.label,
        data.id,
        data.widgetName,
      );
    }
    // If we cant find the widget we add a group so that any children can be added to the scene
    const widget = WidgetType ? new WidgetType(this, parent) : new GroupWidget(this, parent);
    data = await widget.migrate(data);
    widget.deserialize(data);
    widget.children = [];
    for (const child of data.children) {
      const childWidget = await this.deserializeWidget(child, widget);
      widget.addChild(childWidget);
    }
    return widget;
  }

  private createProperty(
    name: string,
    label: string,
    category: PropertyCategory,
    widgetType: WidgetPropertyType,
    dataType: ProVizEventDataTypes,
    editable?: boolean,
    get?: () => any,
    set?: (data: any) => void,
    options?: () => any,
    infoText?: string,
  ) {
    const prop = new BaseWidgetProperty(
      name,
      label,
      category,
      widgetType,
      dataType,
      editable,
      infoText,
    );
    prop.get = get || (() => this[name]);
    prop.set =
      set ||
      ((data: string) => {
        this[name] = data;
      });
    prop.options = options;
    return prop;
  }

  public async getResourceLinks(sceneId: string): Promise<ResourceLink[]> {
    const result: ResourceLink[] = [];

    const sceneResp = await SceneService.get(sceneId);
    result.push({
      filename: `_v1_Scene_${sceneId}`,
      data: JSON.stringify(sceneResp),
      location: undefined,
    });

    for (let i = 0; i < this.nodes.length; i++) {
      result.push(...(await this.nodes[i].getResourceLinks()));
    }

    return result;
  }

  public setSceneTitle(name: string) {
    this.sceneTitle = name;
  }

  public setWireframe(wireframe: boolean) {
    this.nodes.forEach((node) =>
      node.dispatchEventTree('service-wireframe', {
        data: wireframe,
        dataType: 'boolean',
        event: 'devtools',
        sourceWidgetId: 'devtools',
      }),
    );
  }

  public getDebugHandler() {
    return this.debugHandler;
  }

  public dev() {
    // setup hooks and communcation for devtools
    window.addEventListener('message', (event) => {
      console.log('ProVizScene handling message', event);

      switch (event.data.name) {
        case 'get-scenes':
          window.postMessage(
            {
              name: 'proviz-scene',
              scene: this.serialize(),
            },
            '*',
          );

          break;
        case 'wireframe': {
          const modelWidgets = this.getWidgetsOfType('model');
          console.log('model widgets', modelWidgets);
          modelWidgets.forEach((modelWidget) => {
            modelWidget.dispatchEvent('service-wireframe', {
              data: true,
              dataType: 'boolean',
              event: 'devtools',
              sourceWidgetId: 'devtools',
            });
          });
          break;
        }
        case 'debug-mode': {
          break;
        }
      }
    });
    this.debugHandler = new DebugHandler();
  }
}

class DebugHandler {
  private nextTrigger: NextTrigger[] = [];
  private paused: boolean = false;
  private nextTriggerCallback: ((nextTrigger: NextTrigger[]) => void) | undefined;
  private pauseCallback: ((paused: boolean) => void) | undefined;

  public handleTrigger(nextTrigger: NextTrigger) {
    if (this.paused) {
      this.nextTrigger.push(nextTrigger);
      if (this.nextTriggerCallback) {
        console.log(this.nextTrigger);
        this.nextTriggerCallback(this.nextTrigger);
      }
    } else {
      nextTrigger.handler();
    }
  }

  public setTriggerCallback(callback: (nextTrigger: NextTrigger[]) => void) {
    this.nextTriggerCallback = callback;
    this.nextTriggerCallback(this.nextTrigger);
  }

  public setPauseCallback(callback: (paused: boolean) => void) {
    this.pauseCallback = callback;
    this.pauseCallback(this.paused);
  }

  public next() {
    console.log('Handle next trigger', this.nextTrigger);
    const trigger = this.nextTrigger.shift();
    if (trigger) {
      trigger.handler();
    }
    if (this.nextTriggerCallback) {
      console.log(this.nextTrigger);
      this.nextTriggerCallback(this.nextTrigger);
    }
    return !!trigger;
  }

  public runAll() {
    while (this.next()) {}
  }

  public hold() {
    this.paused = true;
    if (this.pauseCallback) {
      this.pauseCallback(this.paused);
    }
  }

  public resume() {
    this.paused = false;
    if (this.pauseCallback) {
      this.pauseCallback(this.paused);
    }
    while (this.next()) {}
  }

  public isPaused() {
    return this.nextTrigger.length > 0 && this.paused;
  }
}
