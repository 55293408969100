import React, { ReactElement } from 'react';
import SetWidgetProperty from '../../../commands/SetWidgetProperty';
import { SettableWidgetProperty } from './SettableWidgetProperty';
import { ManagerStore } from '../../../store';
import { ResourceManagerStore } from '../../../store/ResourceStore';

interface Props {
    property: SettableWidgetProperty;
    header: ReactElement;
    forceUpdate: () => void;
}

export const AreaTargetSelector = (props: Props) => {
    const { header, property } = props;
    const sceneManager = ManagerStore( s => s.SceneManager )
    const targetId = property.get();
    const openFileSelect = ResourceManagerStore(s=>s.openFileSelect) ?? { openFileSelect: undefined };
    const selectionCallback = (newId: string | null) => {
        sceneManager.execute(new SetWidgetProperty(property, targetId, newId, sceneManager));
        props.forceUpdate();
    }


    const handleResourceDrop = (e: React.DragEvent<HTMLElement>) => {
        const resourceType = e.dataTransfer.getData('resource/type');
        if (resourceType === 'AreaTarget') {
            sceneManager.execute(new SetWidgetProperty(property, targetId, e.dataTransfer.getData('resource/id'), sceneManager));
            props.forceUpdate();
        }
    }

    return (
        <div key={`target-${property.name}-${targetId}`} onDragEnter={(ev) => ev.preventDefault()} onDragOver={(ev) => ev.preventDefault()} onDrop={handleResourceDrop}>
            {header}
                <div>
                    <div>{targetId}</div>
                    <button className='button' onClick={() => {
                        openFileSelect && openFileSelect('AreaTarget', false, selectionCallback);
                    }}>Change</button>
                </div>
        </div>
    )
}