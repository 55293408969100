import { IPosition, IRotation, IScale } from '@proviz/api-services';
import { BaseWidgetProperty } from './BaseWidgetProperty';
import { BaseWidget } from './baseWidget';
import { IBaseWidgetType } from './IBaseWidgetType';
import { ProVizScene } from '../ProVizScene';
import { Vector3 } from 'three';

export class BasePositionableWidget extends BaseWidget implements IBaseWidgetType {
  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent, notInScene);

    this.addService(
      'Set World Position',
      'set-world-position',
      'Set Widgets to World Position',
      (val) => {
        if (val.dataType === 'vector3') {
          const worldPos = new Vector3(+val.data!['x'], +val.data!['y'], +val.data!['z']);
          if (this.renderNode.parent) this.renderNode.parent.worldToLocal(worldPos);
          this.setPosition(worldPos);
        }
      },
    );

    this.addService(
      'Set World Rotation',
      'set-world-rotation',
      'Set Widgets to World Rotation',
      (val) => {
        if (val.dataType === 'vector3') {
          const worldRot = new Vector3(+val.data!['x'], +val.data!['y'], +val.data!['z']);
          if (this.renderNode.parent) this.renderNode.parent.worldToLocal(worldRot);
          this.setRotation(worldRot);
        }
      },
    );

    this.addService('Set World Scale', 'set-world-scale', 'Set Widgets to World Scale', (val) => {
      if (val.dataType === 'vector3') {
        const worldScale = new Vector3(+val.data!['x'], +val.data!['y'], +val.data!['z']);
        if (this.renderNode.parent) this.renderNode.parent.worldToLocal(worldScale);
        this.setScale(worldScale);
      }
    });

    this.properties = this.loadWidgetProperties();
  }

  public setPosition(data: IPosition) {
    this.renderNode.position.set(data.x, data.y, data.z);
  }

  public getRotation() {
    return this.renderNode.rotation;
  }

  public setRotation(data: IRotation) {
    if (isNaN(data.x)) data.x = 0;
    if (isNaN(data.y)) data.y = 0;
    if (isNaN(data.z)) data.z = 0;
    this.renderNode.rotation.set(data.x, data.y, data.z);
  }

  public setScale(data: IScale) {
    if (isNaN(data.x)) data.x = 1;
    if (isNaN(data.y)) data.y = 1;
    if (isNaN(data.z)) data.z = 1;
    this.renderNode.scale.set(data.x, data.y, data.z);
  }

  public getScale() {
    return this.renderNode.scale;
  }

  public loadWidgetProperties(): BaseWidgetProperty[] {
    const result = super.loadWidgetProperties();
    return [
      ...result,
      this.createProperty(
        'transformations',
        'Transformations',
        'Core',
        'transform',
        'transform',
        true,
        () => {
          return {
            position: this.renderNode.position,
            rotation: this.renderNode.rotation,
            scale: this.renderNode.scale,
          };
        },
        (data: any) => {
          this.renderNode.position.set(data.position.x, data.position.y, data.position.z);
          this.renderNode.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
          this.renderNode.scale.set(data.scale.x, data.scale.y, data.scale.z);
        },
      ),
      this.createProperty(
        'position',
        'Position',
        'Core',
        'vec3',
        'vector3',
        true,
        () => this.renderNode.position,
        (data: any) => {
          this.renderNode.position.set(data.x, data.y, data.z);
        },
        undefined,
        'Position in meters.',
      ),
      this.createProperty(
        'rotation',
        'Rotation',
        'Core',
        'vec3',
        'vector3',
        true,
        () => this.renderNode.rotation,
        (data: any) => {
          this.setRotation(data);
        },
        undefined,
        'Adjust the rotation of the widget in degrees.',
      ),
      this.createProperty(
        'scale',
        'Scale',
        'Core',
        'vec3',
        'vector3',
        true,
        () => this.renderNode.scale,
        (data: any) => {
          this.setScale(data);
        },
      ),
    ];
  }

  /*
   * Widget implementations will never call deserialize or serialize for themselves.
   * this code will be called by the init function.
   */
  public serialize(): any {
    const data: any = super.serialize();
    data.position = {
      x: this.renderNode.position.x,
      y: this.renderNode.position.y,
      z: this.renderNode.position.z,
    };
    data.rotation = {
      x: this.renderNode.rotation.x,
      y: this.renderNode.rotation.y,
      z: this.renderNode.rotation.z,
    };
    data.scale = {
      x: this.renderNode.scale.x,
      y: this.renderNode.scale.y,
      z: this.renderNode.scale.z,
    };
    return data;
  }

  /*
   * Widget implementations will never call deserialize or serialize for themselves.
   * this code will be called by the init function.
   */
  public deserialize(data: any) {
    super.deserialize(data);
    if (this.renderNode && data.position) {
      data.position.x = parseFloat(data.position.x);
      data.position.y = parseFloat(data.position.y);
      data.position.z = parseFloat(data.position.z);
      this.setPosition(data.position);
    }
    if (this.renderNode && data.rotation) {
      data.rotation.x = parseFloat(data.rotation.x);
      data.rotation.y = parseFloat(data.rotation.y);
      data.rotation.z = parseFloat(data.rotation.z);
      this.setRotation(data.rotation);
    }
    if (this.renderNode && data.scale) {
      data.scale.x = parseFloat(data.scale.x);
      data.scale.y = parseFloat(data.scale.y);
      data.scale.z = parseFloat(data.scale.z);
      this.setScale(data.scale);
    }
  }
}
export default BasePositionableWidget;
