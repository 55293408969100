import { ProVizEventDataTypes } from '../ProVizEventData';

export type WidgetPropertyType =
  | 'vec2'
  | 'vec3'
  | 'vec3-radian'
  | 'vec3-camera-pos'
  | 'vec3-camera-rot'
  | 'vec4'
  | 'button'
  | 'bool'
  | 'number'
  | 'constrained-number'
  | 'string'
  | 'multi-line-string'
  | 'child-select'
  | 'select'
  | 'model-variant'
  | 'model'
  | 'scene'
  | 'sprite'
  | 'env-map'
  | 'area-target'
  | 'model-target'
  | 'texture-360'
  | 'options'
  | 'options-array'
  | 'video-options'
  | 'audio-options'
  | 'image-options'
  | 'srt-options'
  | 'color'
  | 'multi-lang-opts'
  | 'multi-lang-opts-multi-line'
  | 'list'
  | 'font'
  | 'multi-lang-list'
  | 'world-anchor'
  | 'widget'
  | 'widget-list'
  | 'image'
  | 'tween'
  | 'transform';

const typeToPropDataType: { [key: string]: ProVizEventDataTypes } = {};
typeToPropDataType['multi-line-string'] = 'string';
