import { WidgetDefinition } from '../../baseWidget';

export const TextWidgetDefinition: WidgetDefinition = {
  label: 'Text',
  type: 'text',

  // name is used as Label and then converted to the event type by using only lowercase letters
  // and converting spaces to -'s. Example: 'Clicked' -> 'clicked
  events: [{ label: 'Clicked', dataType: 'none' }],

  // name is used as label and converted to 'set-text'
  services: [{ label: 'Set Text', dataType: ['none', 'string'] }],

  // Data property name converts to camel case 'textContent'.
  // Serialization is setup by the base widget constructor
  properties: [
    { label: 'Text Content', dataType: 'multi-line-string', category: 'Core' },
    { label: 'Font', dataType: 'font', category: 'Core' },
    { label: 'Font Size', dataType: 'number', category: 'Core' },
    { label: 'Color', dataType: 'color', category: 'Core' },
  ],
};
