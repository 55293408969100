import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { APIModel, ModelService, PermissionEntity, PermissionType } from "@proviz/api-services";
import { AnnotationWidget } from "@proviz/proviz-sdk";
import { SetModelRotation, SetModelScale } from '../../commands';
import { Rotation, Scale, tuplesEqual } from '../../graphics/Types';
import RotationInput from '../common/RotationInput';
import ThreeValInput from '../common/threevalinput/ThreeValInput';
import { useHistory } from 'react-router-dom';
import Button from '../common/button/Button';
import ModelViewControls from '../../graphics/controls/ModelViewControls';
import { ManagerStore, userStore } from '../../store';

interface Props {
    model: APIModel;
    controls: ModelViewControls
} 

export default function TransformPanel(props: Props): ReactElement {
    const { model, controls } = props;
    const  manager  = ManagerStore( s => s.ModelViewManager);
    const hasPermission = userStore( s => s.hasPermission)
    const [rotation, setRotation] = useState({x: model.rotX, y: model.rotY, z: model.rotZ});
    const [scale, setScale] = useState({x: model.scaleX, y: model.scaleY, z: model.scaleZ});
    const [controlMode, setControlMode] = useState(controls.mode);
    const [annotations, setAnnotations] = useState<AnnotationWidget[]>([]);
    const canDelete = useMemo(() => hasPermission(PermissionEntity.Model, PermissionType.Delete), [hasPermission]);
    const isFirstRender = useRef(true);
    const history = useHistory();
    
    const updateAnnotations = useCallback(() => {
            const annotationWidgets = manager.proVizScene.nodes.filter((widget) => widget.widgetType === 'annotation');
            setAnnotations((annotationWidgets as AnnotationWidget[]) || []);
        }, [manager],
    );

    function historyUpdateHandler() {
        try {
            updateAnnotations();
            let r = manager.rotation();
            let s = manager.scale();
            if (!(tuplesEqual(rotation, r) && tuplesEqual(scale, s))) {
                setRotation(r);
                setScale(s);
            }
        } catch (e) {
            console.warn("waiting for load to update transform values")
        }
    }

    useEffect(() => {
        function updateMode() {
            setControlMode(controls.mode);
        }
        controls.attach('mode-update', updateMode)
        return () => {
            controls.detach('mode-update', updateMode)
        }
    }, [controls])

    useEffect(() => {
        async function updateServer() {
            if (isFirstRender.current) {
                // we should not update the server when we first load the values
                isFirstRender.current = false;
                return;
            }
            try {
                await ModelService.updateModel(model.id, model.name || '', scale, rotation);
            } catch (e) {
                alert("Our server is having trouble your update may not be saved")
            }
        }
        updateServer()
    }, [scale, rotation, model.id, model.name])

    useEffect(() => {
        manager.attach('history-update', historyUpdateHandler)
        return () => 
            manager.detach('history-update', historyUpdateHandler);
    });

    useEffect(() => {
        updateAnnotations();
    }, [manager, updateAnnotations]);

    function rotationChange(newRotation: Rotation) {
        setRotation(newRotation)
        manager.execute(new SetModelRotation(manager, newRotation, manager.rotation()));
    }

    function  scaleChange(newScale: Scale) {
        manager.execute(new SetModelScale(manager, newScale, manager.scale()));
        setScale(newScale);
    }

    function reset() {
        let newScale = {x: 1, y: 1, z: 1}
        let newRot = {x: 0, y: 0, z: 0}
        setScale(newScale)
        setRotation(newRot)
        manager.execute(new SetModelScale(manager, newScale, manager.scale()));
        manager.execute(new SetModelRotation(manager, newRot, manager.rotation()));
    }

    const deleteModel = async () => {
        if (window.confirm('Are you sure you want to delete this model?')) {
            await ModelService.delete(model.id);
            history.push('/home');
        }
    }

    return <div className='controlPanelInterior'>
                <h2>Transform</h2>
                <p>Scale:</p>
                <ThreeValInput input1={{id: "s-x", value: scale.x, label: 'x'}} 
                                input2={{id: "s-y", value: scale.y, label: 'y'}} 
                                input3={{id: "s-z", value: scale.z, label: 'z'}}
                                onChange={scaleChange}
                                checkmark={{
                                    id: 'modelScaleCheckbox', 
                                    value: controlMode === 'scale', 
                                    onChange: () => controls.changeMode('scale')}} />
                <p>Rotation:</p>
                <RotationInput rotation={rotation} 
                                setRotation={rotationChange} 
                                checkmark={{
                                    id: 'modelRotationCheckbox', 
                                    value: controlMode === 'rotate', 
                                    onChange: () => controls.changeMode('rotate')}} />
            <button className='reset' onClick={reset}>Reset Transformation Values</button>

            <h2>Annotations</h2>
            {annotations.map((annotation: AnnotationWidget) => {
                return <div className="annotation-block" key={`annotation-${annotation.id}`}>
                    <div className="numeric">
                        <input type="text" value={annotation.text} onChange={(e) => {
                            annotation.setText(e.target.value);
                            updateAnnotations();
                            manager.postUpdateTask();
                        }} />
                    </div>
                    <button className="button danger" onClick={() => {
                        manager.remove(annotation);
                        updateAnnotations();
                        manager.postUpdateTask();
                    }}>X</button>
                </div>;
            })}

            <hr />
            {canDelete && <Button type="danger" fullWidth={true} onClick={() => deleteModel()}>Delete Model</Button>}
        </div>;
}
