import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  RepeatWrapping,
  sRGBEncoding,
  Texture,
  TextureLoader,
} from 'three';
import { APISprite, FileService, SpriteService } from '@proviz/api-services';
import { encodingOptions, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class SpriteWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'sprite';
  private static instanceCount = 0;
  private spriteNode?: Mesh<PlaneGeometry, MeshBasicMaterial>;
  private texture?: Texture;
  private apiSprite?: APISprite;
  /**
   * This bool denotes that the apiSprite value has been overriden
   * by the migrate method (or potentially by a change source method)
   * if one is added. It implies that the apiSprite field is populated.
   */
  private override: boolean = false;

  // Data
  public spriteId: string = '';
  public numberOfTiles: number = 0;
  public autoPlay: boolean = true;
  public animateOnHover: boolean = false;
  public version = 2;
  public framesPerSecond: number = 30;
  public renderOrderOverride?: number = undefined;
  public textureEncoding: string = 'sRGB';

  private animating: boolean = false;
  private hovering: boolean = false;
  private frame: number = 0;
  private displayTime: number = 0;
  private verticalTiles: number = 1;
  private horizontalTiles: number = 1;

  private displayDuration: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = SpriteWidget.type;
    this.widgetName = 'Sprite';
    if (!notInScene) {
      SpriteWidget.instanceCount++;
    }
    this.label = 'Sprite ' + SpriteWidget.instanceCount;
    this.selectable = true;
    this.events.push({
      label: 'Clicked',
      name: 'clicked',
    });

    this.services.push({
      label: 'Play',
      name: 'play',
    });
    this.services.push({
      label: 'Stop',
      name: 'stop',
    });
    this.services.push({
      label: 'Reset',
      name: 'reset',
    });

    this.addEventListener('service-play', () => {
      this.animating = true;
    });
    this.addEventListener('service-stop', () => {
      this.animating = false;
    });
    this.addEventListener('service-reset', () => {
      this.frame = 0;
    });
  }

  public update(delta: number) {
    super.update(delta);

    if (
      !this.visible ||
      !this.texture ||
      (!this.animating && !(this.animateOnHover && this.hovering))
    ) {
      return;
    }

    this.displayTime += delta * 1000;

    while (this.displayTime > this.displayDuration) {
      this.displayTime -= this.displayDuration;
      this.frame++;
      if (this.frame >= this.numberOfTiles) {
        this.frame = 0;
      }

      this.updateTexture();
    }
  }

  private updateTexture() {
    if (!this.texture) {
      console.error('Calling update texture before texture is created');
      return;
    }
    const currentColumn = this.frame % this.horizontalTiles;
    this.texture.offset.x = currentColumn / this.horizontalTiles;
    const currentRow = Math.floor(this.frame / this.horizontalTiles);
    this.texture.offset.y = -(currentRow / this.verticalTiles);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'spriteId',
        'Sprite Source',
        'Core',
        'sprite',
        'string',
        true,
        undefined,
        (data: any) => {
          this.spriteId = data;
          this.setupImage();
        },
      ),
      this.createProperty(
        'framesPerSecond',
        'Frames Per Second',
        'Core',
        'number',
        'number',
        true,
        undefined,
        (data: any) => {
          this.framesPerSecond = data;
          this.displayDuration = 1000 / this.framesPerSecond;
        },
      ),
      this.createProperty('autoPlay', 'AutoPlay', 'Media Behavior', 'bool', 'boolean', true),
      this.createProperty(
        'animateOnHover',
        'Animate On Hover',
        'Interaction',
        'bool',
        'boolean',
        true,
      ),
      this.createProperty(
        'renderOrderOverride',
        'Render Order',
        'Experimental',
        'number',
        'number',
        true,
        () => {
          return this.renderOrderOverride || this.renderNode.renderOrder;
        },
        (data: any) => {
          this.renderOrderOverride = data;
          this.renderNode.renderOrder = data;
        },
      ),
      this.createProperty(
        'textureEncoding',
        'Texture Encoding',
        'Experimental',
        'select',
        'string',
        true,
        undefined,
        (data: any) => {
          this.textureEncoding = data;
          this.setupImage();
        },
        () =>
          Object.keys(encodingOptions).map((x) => {
            return { key: x, label: x };
          }),
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.spriteId = this.spriteId;
    // result.numberOfTiles = this.numberOfTiles;
    result.framesPerSecond = this.framesPerSecond;
    result.autoPlay = this.autoPlay;
    result.animateOnHover = this.animateOnHover;
    result.textureEncoding = this.textureEncoding;
    result.renderOrderOverride = this.renderOrderOverride;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.spriteId = data.spriteId;
    this.framesPerSecond = data.framesPerSecond;
    // calculate display duration once from fps
    this.displayDuration = 1000 / this.framesPerSecond;
    this.autoPlay = data.autoPlay;
    this.animateOnHover = data.animateOnHover;
    this.textureEncoding = data.textureEncoding ?? this.textureEncoding;
    this.renderOrderOverride = data.renderOrderOverride;

    this.animating = this.autoPlay;
  }

  /**
   * setupImage should be called when the widget is new
   * or the api sprite is stale from being changed in the editor
   */
  private async setupImage() {
    if (this.spriteNode) {
      this.renderNode.remove(this.spriteNode);
    }
    if (this.spriteId || this.override) {
      if (!this.override) {
        this.apiSprite = await SpriteService.get(this.spriteId, {
          abortController: this.scene.abortController,
        });
      }
      if (!this.apiSprite || !this.apiSprite.file) {
        // this would only happen if override was erroneously set to true without
        // updating the apiSprite field
        console.error('There is no sprite');
        return;
      }
      this.numberOfTiles = this.apiSprite.numberOfTiles;
      this.verticalTiles = this.apiSprite.verticalTiles;
      this.horizontalTiles = this.apiSprite.horizontalTiles;
      const textureLoader = new TextureLoader();
      const imgSrc = FileService.getLocation(this.apiSprite.file);

      this.texture = textureLoader.load(imgSrc, (tex) => {
        this.spriteNode?.scale.set(
          tex.image.width / 100.0 / this.horizontalTiles,
          tex.image.height / 100.0 / this.verticalTiles,
          1,
        );
      });

      this.texture.wrapS = this.texture.wrapT = RepeatWrapping;
      this.texture.repeat.set(1 / this.horizontalTiles, 1 / this.verticalTiles);
      this.texture.encoding = encodingOptions[this.textureEncoding] ?? sRGBEncoding;
      const geometry = new PlaneGeometry();
      const material = new MeshBasicMaterial({
        map: this.texture,
        side: DoubleSide,
        transparent: true,
      });

      this.spriteNode = new Mesh(geometry, material);
      this.renderNode.add(this.spriteNode);

      if (this.renderOrderOverride) {
        this.renderNode.renderOrder = this.renderOrderOverride;
        this.spriteNode.renderOrder = this.renderOrderOverride + 1;
      }
    }
  }

  public dispose(): void {
    super.dispose();
    this.spriteNode?.material?.dispose();
    this.spriteNode?.geometry?.dispose();
    this.texture?.dispose();
    delete this.texture;
    delete this.spriteNode;
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }

    switch (data.version) {
      case 1:
        // we create a sprite record from image source
        if (data.imageSrc) {
          if (this.scene.sceneMode === SceneMode.Editor) {
            const imageUrl = `https://proviz.blob.core.windows.net/files/${data.imageSrc}`;
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const file = new File([blob], `sprite-${data.label}.png`, { type: 'image/png' });
            const sprite = await SpriteService.upload(file);
            console.log('Uploaded sprite during migration', sprite);
            sprite.numberOfTiles = data.numberOfTiles;
            sprite.verticalTiles = data.verticalTiles;
            sprite.horizontalTiles = data.horizontalTiles;
            sprite.name = data.label;
            await SpriteService.update(sprite);
            data.spriteId = sprite.id;
          } else {
            // If we are not in studio and expected to send the serialized version
            // of this scene up to the api we create a 'fake' APISprite object
            // and populate it with values from our data
            this.override = true;
            this.apiSprite = {
              // @ts-ignore we are spoofing APIFile here
              file: {
                location: `https://proviz.blob.core.windows.net/files/${data.imageSrc}`,
              },
              numberOfTiles: data.numberOfTiles,
              verticalTiles: data.verticalTiles,
              horizontalTiles: data.horizontalTiles,
            };
          }
        }
    }
    return data;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    this.setupImage();

    return true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.spriteNode && this.visible) {
      result.push(this.spriteNode);
    }
    return result;
  }

  public getHoverable() {
    const result = super.getHoverable();
    if (this.animateOnHover && this.spriteNode && this.visible) {
      result.push(this.spriteNode);
    }
    return result;
  }

  public onHoverEnter() {
    this.hovering = true;
  }

  public onHoverLeave() {
    this.hovering = false;
    this.frame = 0;
    this.updateTexture();
  }
}

ModuleService.Register(SpriteWidget.type, SpriteWidget);
