import { ProVizEventDataTypes } from '../ProVizEventData';
import { WidgetPropertyType } from './WidgetPropertyTypes';

// Data types to use

export type PropertyCategory =
  | 'Core'
  | 'Data'
  | 'Media Behavior'
  | 'Interaction'
  | 'Explode'
  | 'Experimental'
  | 'Integrations'
  | 'Transparency'
  | 'Font'
  | 'Subtitles'
  | 'Video Events'
  | 'Visual'
  | 'Function'
  | 'Shadow'
  | 'UI'
  | 'Animation'
  | 'Video Properties'
  | 'Advanced'
  | 'Language'
  | 'Environment'
  | 'Lighting'
  | 'Camera'
  | 'Platforms'
  | 'Devices'
  | 'Experiences'
  | 'Overrides';

export class BaseWidgetProperty {
  public name: string;
  public label: string;
  public category: PropertyCategory;
  public widgetType: WidgetPropertyType;
  public dataType: ProVizEventDataTypes;
  public editable: boolean;
  public infoText?: string;
  public set?: (data: any, updateNodes?: () => void) => any;
  public get?: () => any;
  public options?: () => { key: any, label: string }[];

  constructor(
    name: string,
    label: string,
    category: PropertyCategory,
    widgetType: WidgetPropertyType,
    dataType: ProVizEventDataTypes,
    editable?: boolean,
    infoText?: string,
  ) {
    this.name = name;
    this.label = label;
    this.category = category;
    this.widgetType = widgetType;
    this.dataType = dataType;
    this.editable = editable || false;
    this.infoText = infoText;
  }
}
