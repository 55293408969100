import { ReactElement, useEffect, useState } from 'react'
import { Vector3 } from 'three';
import { Rotation } from '../../graphics/Types'
import ThreeValInput from './threevalinput/ThreeValInput';

interface Props {
    rotation: Rotation;
    setRotation: (rotation: Rotation) => void;
    checkmark?: { value: boolean; onChange: () => void; id: string} ;
}

function radiansToDegrees(angle: number) {
    return angle * 57.2958;
}

function degreesToRadians(angle: number) {
    return angle === 0 ? angle : angle / 57.2958;
}

function rotationToDegrees(rot: Rotation) {
    return {
        x: radiansToDegrees(rot.x),
        y: radiansToDegrees(rot.y),
        z: radiansToDegrees(rot.z)
    }
}

export default function RotationInput(props: Props): ReactElement {
    const { rotation, setRotation, checkmark } = props;
    const [displayRotation, setDisplayRotation] = useState(rotationToDegrees(rotation))

    useEffect(() => {
        setDisplayRotation(rotationToDegrees(rotation))
    }, [rotation])

    function rotationChange(rotation: Rotation) {
        setRotation(new Vector3(
            degreesToRadians(rotation.x),
            degreesToRadians(rotation.y),
            degreesToRadians(rotation.z)
        ));
    }
    const { x, y, z } = displayRotation;
    return  <ThreeValInput input1={{id: "ro-x", value: x, label: 'x'}} 
                            input2={{id: "ro-y", value: y, label: 'y'}} 
                            input3={{id: "ro-z", value: z, label: 'z'}}
                            onChange={rotationChange}
                            checkmark={checkmark} />
}
