import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { Transform } from '../../../types';
import { Vector3 } from '../../../types/vector3';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class TransformationWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'transformation';

  //Data
  public position: Vector3 = { x: 0, y: 0, z: 0 };
  public rotation: Vector3 = { x: 0, y: 0, z: 0 };
  public scale: Vector3 = { x: 0, y: 0, z: 0 };

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = TransformationWidget.type;
    this.widgetName = 'Tranformation';
    this.label = 'Transformation';
    this.usage = 'Flow';
    this.category = 'Position';
    this.events.push(
      {
        label: 'Value',
        name: 'value',
      },
      {
        label: 'Position',
        name: 'position',
      },
      {
        label: 'Position Z',
        name: 'position-z',
      },
      {
        label: 'Position Y',
        name: 'position-y',
      },
      {
        label: 'Position X',
        name: 'position-x',
      },
      {
        label: 'Rotation',
        name: 'rotation',
      },
      {
        label: 'Rotation Z',
        name: 'rotation-z',
      },
      {
        label: 'Rotation Y',
        name: 'rotation-y',
      },
      {
        label: 'Rotation X',
        name: 'rotation-x',
      },
      {
        label: 'Scale',
        name: 'scale',
      },
      {
        label: 'Scale Z',
        name: 'scale-z',
      },
      {
        label: 'Scale Y',
        name: 'scale-y',
      },
      {
        label: 'Scale X',
        name: 'scale-x',
      },
    );
    this.addService(
      'set Value',
      'set-value',
      '<b>Updates transform value</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'transform') {
          this.position = (event.data as Transform).position;
          this.rotation = (event.data as Transform).rotation;
          this.scale = (event.data as Transform).scale;
        }
        this.emitValues();
      },
    );
    this.addService(
      'set Position',
      'set-position',
      '<b>Updates position value</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'vector3') {
          this.position = event.data as Vector3;
        }
        this.triggerProVizEvent('value', 'transform', {
          position: this.position,
          rotation: this.rotation,
          scale: this.scale,
        });
        this.emitPositionValues();
      },
    );
    this.addService(
      'set Rotation',
      'set-rotation',
      '<b>Updates rotation value</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'vector3') {
          this.rotation = event.data as Vector3;
        }
        this.triggerProVizEvent('value', 'transform', {
          position: this.position,
          rotation: this.rotation,
          scale: this.scale,
        });
        this.emitRotationValues();
      },
    );
    this.addService(
      'set Scale',
      'set-scale',
      '<b>Updates scale value</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'vector3') {
          this.scale = event.data as Vector3;
        }
        this.triggerProVizEvent('value', 'transform', {
          position: this.position,
          rotation: this.rotation,
          scale: this.scale,
        });
        this.emitScaleValues();
      },
    );
    this.addService('apply', 'apply', '<b>Apply Transform value</b>', (event: ProVizEventData) => {
      this.emitValues();
    });
  }

  public emitPositionValues() {
    this.triggerProVizEvent('position', 'vector3', this.position);
    this.triggerProVizEvent('position-x', 'number', this.position.x);
    this.triggerProVizEvent('position-y', 'number', this.position.y);
    this.triggerProVizEvent('position-z', 'number', this.position.z);
  }

  public emitRotationValues() {
    this.triggerProVizEvent('rotation', 'vector3', this.rotation);
    this.triggerProVizEvent('rotation-x', 'number', this.rotation.x);
    this.triggerProVizEvent('rotation-y', 'number', this.rotation.y);
    this.triggerProVizEvent('rotation-z', 'number', this.rotation.z);
  }

  public emitScaleValues() {
    this.triggerProVizEvent('scale', 'vector3', this.scale);
    this.triggerProVizEvent('scale-x', 'number', this.scale.x);
    this.triggerProVizEvent('scale-y', 'number', this.scale.y);
    this.triggerProVizEvent('scale-z', 'number', this.scale.z);
  }

  public emitValues() {
    this.triggerProVizEvent('value', 'transform', {
      position: this.position,
      rotation: this.rotation,
      scale: this.scale,
    });
    this.emitPositionValues();
    this.emitRotationValues();
    this.emitScaleValues();
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('position', 'Data', 'Core', 'vec3', 'vector3', true),
      this.createProperty('rotation', 'Data', 'Core', 'vec3', 'vector3', true),
      this.createProperty('scale', 'Data', 'Core', 'vec3', 'vector3', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.position = this.position;
    result.rotation = this.rotation;
    result.scale = this.scale;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.position = data.position;
    this.rotation = data.rotation;
    this.scale = data.scale;
  }
}
ModuleService.Register(TransformationWidget.type, TransformationWidget);
