import { ReactElement } from "react";
import Header from "./common/header/Header";
import AuthButton from "./common/auth/AuthButton";
import { useHistory } from "react-router-dom";
import Button from "./common/button/Button";

export default function AccountCreatedPage(): ReactElement {
  const history = useHistory();

  const goHome = () => {
    history.push("/home");
  };

  return (
    <div className="home">
      <Header>
        <AuthButton />
      </Header>
      <div className="loginTitle">
        <h3>An account was created for you. </h3>
        <h3>Proceed to your home page. </h3>
        <Button type="primary" onClick={goHome}>
          Home
        </Button>
      </div>
    </div>
  );
}
