import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SceneEditor from "./components/sceneeditor/SceneEditor";
import Callback from "./components/common/auth/Callback";
import ModelEditor from "./components/modeleditor/ModelEditor";
import Loading from "./components/common/Loading";
import SignInPage from "./components/SignInPage";
import "./App.scss";
import Main from "./components/home/Main";
import AccountCreatedPage from "./components/AccountCreated";
import ErrorPage from "./components/ErrorPage";
import SafariErrorPage from "./components/SafariErrorPage";
import AuthorizedRoute from "./auth/AuthorizedRoute";
import SignUpPage from "./components/SignUpPage";
import { useEffect } from "react";

function App() {
  const { isLoading } = useAuth0();

  useEffect(() => {
    let url = document.URL.split(".")[0];
    document.title = `${
      url.includes("dev") || url.includes("localhost") ? "Dev " : " "
    }ProViz Studio`;
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <Switch>
        <AuthorizedRoute
          path="/model/:id"
          component={ModelEditor}
          fallback="/home"
        />
        <AuthorizedRoute
          path="/editor/:id"
          component={SceneEditor}
          fallback="/home"
        />
        <AuthorizedRoute path="/home" component={Main} fallback="/" />
        <Route path="/callback" component={Callback} />
        <Route path="/created" component={AccountCreatedPage} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/safariWebGL" component={SafariErrorPage} />
        <Route path="/" component={SignInPage} />
      </Switch>
    </Router>
  );
}

export default App;
