import styled from "styled-components";

const PageHeaderContainer = styled.div`
    display: flex;
    padding-top: 0;
    div {
        flex: 1;
    }
    .right-content {
        text-align: right;
        div {
            margin-left: 1rem;
            display: inline-block;
        }
    }
    h1 {
        margin: 0;
        font-weight: normal;
    }

    &.sub {
        h1 {
            font-size: 1rem;
        }
    }
`

interface Props {
    title: string;
    children?: any;
    headerType?: 'sub'
}

export default function PageContentHeader(props: Props) {
    return <PageHeaderContainer className={props.headerType || ''}>
        <div>
            <h1>{props.title}</h1>
        </div>
        <div className='right-content'>
            {props.children}
        </div>
    </PageHeaderContainer>
}