import { ComponentType, FunctionComponent, useEffect, useState } from "react";
import {
  useAuth0,
  WithAuthenticationRequiredOptions,
} from "@auth0/auth0-react";
import { AccessToken } from "./AccessToken";
import { ProVizConfig, UserService } from "@proviz/api-services";
import { useHistory } from "react-router-dom";
import { userStore } from "../store";

const initialSearchURL = window.location.search;

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;
const defaultOnRedirecting = (): JSX.Element => <></>;

export interface TokenProps {
  token: any;
}

/**
 * This HOC copies most of the logic from the Auth0 WithAuthenticationRequired
 * with authentication required HOC. With the addition of logic that waits
 * to render the child component until a JWT has been pulled down.
 */
const withToken = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): FunctionComponent<P> => {
  return function WithToken(props: P): JSX.Element {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const [token, setToken] = useState<string | undefined>(undefined);
    const user = userStore((s) => s.user);
    const setUser = userStore((s) => s.setUser);
    const setPermissions = userStore((s) => s.setPermissions);
    const history = useHistory();
    const [authenticated, setAuthenticated] = useState(false);

    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      loginOptions = {},
    } = options;
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
      let mounted = true;

      // Check server if there is an entry for this user in db
      // Create user if there is not
      const getUser = async () => {
        try {
          const me = await UserService.getMe();
          setUser(me);
          if (me.companies.length > 0) {
            AccessToken.user = me;
          } else {
            history.push("/signup");
          }
        } catch (err: any) {
          console.error("User service error", err.name, err.message);
          console.error("initial search url", initialSearchURL);
          if (err.message === "None found") {
            const urlParams = new URLSearchParams(initialSearchURL);
            const inviteCode = urlParams.get("invite");
            if (inviteCode) {
              history.push(`/signup?invite=${inviteCode}`);
            } else {
              history.push(`/signup`);
            }
          } else {
            history.push("/error");
          }
        }
      };

      async function updatePerms() {
        try {
          const perms = await UserService.getPermissions();
          setPermissions(perms);
        } catch (e) {
          console.error("Could not get user permissions", e);
        }
      }

      // Get Auth0 User token
      const getToken = async () => {
        try {
          const _token = await getAccessTokenSilently();
          if (!mounted) {
            return;
          }
          AccessToken.token = _token;
          ProVizConfig.setEnv(process.env.REACT_APP_ENV || "local", _token);
          setToken(_token);
          if (AccessToken.user) {
            return;
          }

          await getUser();
          await updatePerms();
          setAuthenticated(true);
        } catch (e) {
          history.push("/");
        }
      };

      if (!AccessToken.token || !user) {
        getToken();
      }

      return () => {
        mounted = false;
      };
    }, [
      getAccessTokenSilently,
      isAuthenticated,
      history,
      user,
      setUser,
      setPermissions,
    ]);

    useEffect(() => {
      if (isLoading || isAuthenticated || !token) {
        return;
      }
      const opts = {
        ...loginOptions,
        appState: {
          ...loginOptions.appState,
          returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
        },
      };
      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }, [
      isLoading,
      isAuthenticated,
      loginWithRedirect,
      loginOptions,
      returnTo,
      token,
    ]);

    if (!AccessToken.token && (!token || !user)) {
      return (
        <div className="loading-indicator">
          <h1>Loading...</h1>
        </div>
      );
    }
    const newProps = { ...props, token };
    return authenticated ? <Component {...newProps} /> : onRedirecting();
  };
};

export default withToken;
