import { useState } from 'react'
import Button from '../../common/button/Button'

interface Props {
  companyId: string;
  companyName: string;
  defaultCompanyId?: string;
  setDefault: (id: string) => void;
  editCompanyName: (companyName: string, companyId: string) => void;
  deleteCompany: (companyId: string) => void;
}

export const CompanyOrganizationSettings = (props: Props) => {
  const {
    companyId,
    editCompanyName,
    deleteCompany,
    defaultCompanyId,
    setDefault
  } = props;

  const [editingCompany, setEditingCompany] = useState(false);
  const [companyName, setCompanyName] = useState(props.companyName);

  const handleCompanyEdit = (comp?: any) => {
    setEditingCompany(!editingCompany);
    if (editingCompany)
      editCompanyName(comp, companyName);
  }

  // if(companyName === 'error test') throw new Error("ERROR TEST COMPANY");

  return <>
    <td>
      {
        !editingCompany ? companyName : <input type="text" name="company value" value={companyName} onChange={e => setCompanyName(e.target.value)} />
      }
    </td>
    <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button type={'default'} fullWidth={true} onClick={() => deleteCompany(companyId)}>Delete</Button>
    </td>
    <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button type={editingCompany ? 'submit' : 'default'} fullWidth={true} onClick={() => handleCompanyEdit(companyId)}>{editingCompany ? "Save" : "Edit"}</Button>
    </td>
    <td style={{}}>
      {companyId === defaultCompanyId
        ? <p>Default</p>
        : <Button onClick={() => setDefault(companyId)}>Set Default</Button>
      }
    </td>
  </>
}

