import { ReactElement, useEffect, useState } from 'react';
import { APIModel, initAbortController, isAbortError, ModelService } from "@proviz/api-services";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import VariantEntry from './VariantEntry';
import { ModelFileEntry } from './ModelFileEntry';
import LogList from './LogList';
import Modal from '../../common/modals/Modals';
import CollapsibleHeaderIcon from '../../common/collapsiblegroup/CollapsibleHeader';

const okIcon = getAbsoluteUrl('images/Greencheckmark.svg');
const processingIcon = getAbsoluteUrl('images/Processing.svg');
const errorIcon = getAbsoluteUrl('images/Error.svg')

const modelStatusToIcon = (model: APIModel) => {
    let status = model.status;
    if (status === 'Failed') {
        return errorIcon
    }
    if (status === 'Processing') {
        return processingIcon
    }
    return okIcon
}

interface Props {
    model: APIModel;
    children?: any;
    close: () => void;
}

export default function ModelStatusModal(props: Props): ReactElement {
    const { model, close } = props;
    const [localModel, setLocalModel] = useState(model);
    const [showModelFiles, setShowModelFiles] = useState(false);

    useEffect(() => {
        const abortController = initAbortController();
        ModelService.getWithAllFiles(model.id, { abortController })
            .then(setLocalModel)
            .catch(e => {
                if (isAbortError(e)) return; // if the query was aborted, do nothing
                console.error(e); 
            });
        return () => abortController.abort();
        }, [model])

    const { gltfFile, modelFiles, sourceFile } = localModel;

    return <Modal title='Model Status' close={close}>
        <div className="statusLists">
            <div className="listHeader">
                <div></div>
                <b>Status</b>
                <b>Model Name</b>
            </div>
            <hr />

            <div className="modelEntry">
                <div></div>
                <img src={modelStatusToIcon(model)} alt='' />
                <p>{model.name}</p>
                <CollapsibleHeaderIcon expanded={showModelFiles} 
                    handleClick={() => setShowModelFiles(!showModelFiles)} />
            </div>
            {showModelFiles && <div className="files">
                <p>Source</p>
                {sourceFile && <ModelFileEntry file={sourceFile} modelId={model.id}/>}
                <p>Normalized Gltf</p>
                {gltfFile && <ModelFileEntry file={gltfFile} modelId={model.id}/>}
                <hr />
                {modelFiles &&
                    modelFiles.map((f) =>
                        <ModelFileEntry file={f} modelId={model.id} key={f.id} />)}
            </div>}
            <div className="listHeader">
                <div></div>
                <b>Status</b>
                <b>Variant Name</b>
                <div></div>
            </div>
            <hr />
            {localModel.modelVariants &&
                localModel.modelVariants.map((v) =>
                    <VariantEntry variant={v} modelId={model.id} key={v.id} />)}
            <LogList entity='Model' id={model.id} />

            {props.children}
        </div>
    </Modal>;
}
