import { useState } from 'react'
import { APIModel } from "@proviz/api-services";
import VariantPanel from './variantpanel/VariantPanel';
import { Tab, TabBar, TabPanel } from '../common/tabs';
import TransformPanel from './TransformPanel';
import { ModelViewerSettings } from './modelviewersettings/ModelViewerSettings';
import { ManagerStore } from '../../store';
interface Props {
    model: APIModel;
}

export default function ModelControls(props: Props) {
    const { model } = props;
    const  manager  = ManagerStore(s=>s.ModelViewManager);
    const [tab, setTab] = useState(0);

    return <div className="modelControlsPanel">
        <TabBar>
            <Tab label='Transform' index={0} value={tab}
                onClick={() => setTab(0)} icon='icon-transform'
                selectedIcon='icon-transform selected' />
            <Tab label='Finishes' index={1} value={tab}
                onClick={() => setTab(1)} icon='icon-finishes'
                selectedIcon='icon-finishes selected' />
            <Tab label='Viewer Settings' index={2} value={tab}
                onClick={() => setTab(2)} icon='icon-background'
                selectedIcon='icon-share selected' />
        </TabBar>

        <TabPanel index={0} value={tab}>
            {manager?.controls && <TransformPanel model={model} controls={manager.controls} />}
        </TabPanel>
        <TabPanel index={1} value={tab}>
            <VariantPanel model={model} />
        </TabPanel>
        <TabPanel index={2} value={tab}>
            <ModelViewerSettings />
        </TabPanel>
    </div>;
}