import { IServiceOptions, Paged } from "..";
import { BaseDataService } from "../BaseDataService";
import { APIDatabase } from "../models/Database";

export class APIDatabaseService extends BaseDataService<APIDatabase> {
  constructor() {
    super("Database");
  }

  public async create(name: string, connectionString: string) : Promise<APIDatabase> {
    return await this.post('', {
      name,
      connectionString
    });
  }

  public async updateName(databaseId: string, name: string) : Promise<APIDatabase> {
    return await this.post(`${databaseId}/name`, {
      name
    });
  }

  public async updateConnectionString(databaseId: string, connectionString: string) : Promise<APIDatabase> {
    return await this.post(`${databaseId}/connectionString`, {
      connectionString
    });
  }

  public async getCollections(databaseId: string) : Promise<string[]> {
    return await this.request(`${databaseId}/collections`);
  }

  public async getCollectionRecords(
    databaseId: string, collection: string,
    page: number,
    size: number,
    options?: IServiceOptions
  ): Promise<Paged<any>> {
    return await this.request(
      `${databaseId}/collections/${collection}?page=${page}&size=${size}`
    );
  }

  public async createCollectionRecord(databaseId: string, collection: string, data: any) : Promise<any> {
    return await this.post(`${databaseId}/collections/${collection}`, data);
  }

  public async createSchemaRecord(databaseId: string, schemaId: string, data: any) : Promise<any> {
    return await this.post(`${databaseId}/schema/${schemaId}`, data);
  }

  public async updateCollectionRecord(databaseId: string, collection: string, id: string, data: any) : Promise<any> {
    return await this.put(`${databaseId}/collections/${collection}/${id}`, data);
  }

  public async updateSchemaRecord(databaseId: string, schemaId: string, id: string, data: any) : Promise<any> {
    return await this.put(`${databaseId}/schema/${schemaId}/${id}`, data);
  }

  public async getCollectionRecord(databaseId: string, collection: string, id: string) : Promise<any> {
    return await this.request(`${databaseId}/collections/${collection}/${id}`);
  }

  public async getSchemaRecord(databaseId: string, schemaId: string, id: string) : Promise<any> {
    return await this.request(`${databaseId}/schema/${schemaId}/${id}`);
  }

  public async search(
    databaseId: string, 
    collection: string,
    page: number,
    size: number,
    query: any
  ): Promise<Paged<any>> {
    return await this.request(
      `${databaseId}/collections/${collection}?page=${page}&size=${size}`,
      {
        method: 'POST',
        body: query
      }
    );
  }
}

const DatabaseService = new APIDatabaseService();

export default DatabaseService;
