import { getAbsoluteUrl } from '@proviz/proviz-sdk';
import { ReactElement } from 'react'

const downArrow = getAbsoluteUrl('images/downArrow.svg');
const leftArrow = getAbsoluteUrl('images/leftArrow.svg');

interface Props {
    handleClick: () => void;
    expanded: boolean;
}

export default function CollapsibleHeaderIcon(props: Props): ReactElement {
    const { handleClick, expanded} = props;
    return <img className="expandIcon"
        src={expanded ? downArrow : leftArrow}
        alt={expanded ? "Expand" : "Minimize"}
        title={expanded ? "Expand" : "Minimize"}
        style={{ transform: expanded ? 'rotate: 90deg' : '' }}
        onClick={handleClick}
    />;
}
