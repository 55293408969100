import { ReactElement, useState, ChangeEvent } from 'react'
import { APIModelVariant, MaterialSetService } from "@proviz/api-services";
import BufferedSet from '../../../utils/BufferedSet';

interface Props {
    modelVariant: APIModelVariant;
    updateName: (name: string) => void;
}

export default function MaterialSetName(props: Props): ReactElement {
    const { modelVariant, updateName } = props;
    const materialSet = modelVariant.materialSet;
    const [name, setName] = useState((materialSet && materialSet.name) || '');

    const bufferedName = new BufferedSet((name: string) => {
        modelVariant.materialSet.name = name;
        updateName(name);
        MaterialSetService.updateName(materialSet.id, name);
    })

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        let newName = event.target.value;
        setName(newName);
        bufferedName.setValue(newName);
    }

    return <div className='materialSetName'>
        <input value={name} onChange={handleChange} />
    </div>;
}
