import { ReactElement, useEffect, useState } from 'react'
import QRious from 'qrious';
import HoverDropDown from '../hoverdropdown/HoverDropdown';

function generateQR(link: string) {
    const qr = new QRious({
        background: '#fff',
        // foreground: '#6d6e71',
        foreground: '#313E64',
        level: 'H',
        padding: 25,
        size: 250,
        value: link,
    }
    );
    return qr.toDataURL('image/png');
}

interface Props {
    embedHtml: string;
    link: string;
    title: string;
    entityType: 'Model' | 'Scene';
    appParams?: () => string[];
}

export default function ShareButton(props: Props): ReactElement {
    const { embedHtml } = props;
    const [ link, setLink ] = useState(props.link);
    const [qrSrc, setQrSrc] = useState("");
    const [ appParams, setAppParams ] = useState<{key: string, val: string}[]>([])

    useEffect(() => {
        setQrSrc(generateQR(link));
    }, [link]);

    const update = () => {
        const currParams = ((props.appParams && props.appParams()) || []).map((appParam) => { return  { key: appParam, val: '' } });
        setAppParams(currParams);
    }

    const set = (key: string, val: string) => {
        const result = [...appParams];
        const r = result.find((p) => p.key === key);
        if (r) {
            r.val = val;
        }
        setAppParams(result);
        let url = '?' + result.map((prop) => `${prop.key}=${prop.val}&`);
        setLink(props.link + url.slice(0, url.length - 1));
    }

    return <HoverDropDown buttonText='Share' className='share-button' onShow={update} onClick={() => window.open(link, "_blank") } >
        <div>
            <h1>Share</h1>

            <p>View your <a href={link} target="_blank" rel="noreferrer" >{props.entityType}</a></p>
            <div className='qrContainer'>
                <img src={qrSrc} alt={`View your ${props.entityType}.`} />
                <div className='editableTitle'>
                    <div className='nameInputQR'>
                        <input type="text" defaultValue={props.title} />
                    </div>
                </div>
            </div>
            {appParams.length > 0 && <div>
                <p>App Params</p>
                {appParams.map((val) => <div>
                    <div><b>{val.key}</b></div>
                    <input className='textbox' type="text" value={val.val} onChange={(e) => set(val.key, e.target.value)} />
                </div>)}
            </div>}
            <p>Embed this {props.entityType}.</p>
            <pre className='code'>{embedHtml}</pre>
        </div>
    </HoverDropDown>;
}
