import { ReactElement, useMemo, useState } from "react";
import { BaseWidget, StringWidget, ModuleService } from "@proviz/proviz-sdk";
import { CategoryIcons } from "../../../documentation/WidgetIcons";
import { WidgetCard } from "./WidgetCard";
import { WidgetInfoModal } from "../../../documentation/WidgetInfoModal";
import { AddNode } from "../../../commands";
import SceneEditorBottomMenu from "./SceneEditorBottomMenu";
import { ManagerStore } from "../../../store";

const UnlistedCategories = [
  "Flow",
  "Unlisted",
  "Conditional",
  "Events",
  "Position",
  "Time",
  "Others",
];

type Props = {
  children?: any;
};

export default function WidgetMenu(props: Props): ReactElement {
  const manager = ManagerStore( s => s.SceneManager );
  const modules = useMemo(() => Object.keys(ModuleService.widgets), []);
  const widgetInstances = useMemo(
    () => modules.map((w) => new ModuleService.widgets[w](manager.proVizScene)),
    [modules, manager.proVizScene]
  );
  const categories = useMemo(
    () =>
      widgetInstances
        .map((k) => k.category)
        .filter(
          (value, index, self) =>
            self.indexOf(value) === index && !UnlistedCategories.includes(value)
        ),
    [widgetInstances]
  );
  const [category, setCategory] = useState(categories[0]);
  const [openModal, setOpenModal] = useState<BaseWidget | StringWidget | null>(
    null
  );

  const create = (widget: BaseWidget | StringWidget) => {
    const wdg = new ModuleService.widgets[widget.widgetType](
      manager.proVizScene
    );
    wdg.init();
    manager.execute(new AddNode(manager, wdg));
  };

  return (
    <>
      <SceneEditorBottomMenu
        sidebar={
          <>
            {props.children}
            <div className="categories">
              {categories.map((c) => {
                return (
                  <div
                    className={`category ${category === c ? "selected" : ""}`}
                    key={c}
                    onClick={() => setCategory(c)}
                  >
                    <img
                      className="category-icon"
                      src={CategoryIcons[c] ?? ""}
                      alt=""
                    />
                    <h4>{c}</h4>
                  </div>
                );
              })}
            </div>
          </>
        }
        content={
          <div
            key={category}
            className="widgetSelectGrid widgetSelectBackground"
          >
            {widgetInstances
              .filter(
                (v) =>
                  v.category === category &&
                  v.usage !== "Flow" &&
                  !UnlistedCategories.includes(v.category)
              )
              .sort((a, b) => {
                if (a.widgetName > b.widgetName) {
                  return 1;
                } else if (a.widgetName < b.widgetName) {
                  return -1;
                }
                return 0;
              })
              .map((widget) => {
                return (
                  <WidgetCard
                    widget={widget}
                    create={create}
                    key={widget.widgetName}
                    openModal={() => setOpenModal(widget)}
                  />
                );
              })}
          </div>
        }
      />
      {openModal && (
        <WidgetInfoModal
          open={openModal !== null}
          widget={openModal}
          close={() => setOpenModal(null)}
        />
      )}
    </>
  );
}
