import Command from "./Command";
import { BaseWidget } from "@proviz/proviz-sdk";
import GraphicsManager from "../graphics/GraphicsManager";

export default class AddNode extends Command {
  graphicsManager: GraphicsManager;
  node: BaseWidget;
  parent: BaseWidget | undefined
  addToSelected: boolean;
  name = "Add Widget";

  constructor(sceneManager: GraphicsManager, node: BaseWidget, parent?: BaseWidget| undefined, addToSelected = true) {
    super();
    this.node = node;
    this.parent = parent;
    this.graphicsManager = sceneManager;
    this.addToSelected = addToSelected;
  }

  execute() {
    this.graphicsManager.add(this.node, this.parent, this.addToSelected);
  }

  undo() {
    if (!this.node) {
      return; // todo maybe throw error
    }
    this.graphicsManager.remove(this.node);
  }
}
