import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ClockWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'clock';

  // Data
  private currTime: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = ClockWidget.type;
    this.widgetName = 'Clock';
    this.label = 'Clock';
    this.category = 'Time';
    this.usage = 'Flow';
    this.selectable = false;
    this.events = [
      {
        label: 'Elapsed',
        name: 'elapsed',
        desc: 'Total seconds elapsed in scene',
      },
      {
        label: 'Delta',
        name: 'delta',
        desc: 'Total seconds elapsed since last update frame',
      },
    ];
  }

  public update(delta: number): void {
    this.currTime += delta;
    this.triggerProVizEvent('elapsed', 'number', this.currTime);
    this.triggerProVizEvent('delta', 'number', delta);
  }
}

ModuleService.Register(ClockWidget.type, ClockWidget);
