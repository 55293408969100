import {
  Group,
  Scene,
  AmbientLight,
  DirectionalLight,
  Box3,
  Color,
  PerspectiveCamera,
  WebGLRenderer,
  Object3D,
  PCFSoftShadowMap,
  Vector3,
  Vector2,
} from "three";
import { APIFile, APIModel, MaterialSetService } from "@proviz/api-services";
import { ProVizLoader } from "@proviz/proviz-sdk";

export default class ThumbnailGenerator extends EventTarget {
  renderer: WebGLRenderer;
  scene: Scene;
  camera: PerspectiveCamera;
  screenDimensions: Vector2;
  canvas: HTMLCanvasElement;
  processing: boolean;
  loader: ProVizLoader;

  constructor(width: number, height?: number) {
    super();
    this.canvas = document.createElement("canvas");
    this.canvas.width = width;
    this.canvas.height = height || width;
    this.screenDimensions = new Vector2(this.canvas.width, this.canvas.height);
    this.processing = false;

    this.scene = this.buildScene();
    this.renderer = this.buildRender();
    this.camera = this.buildCamera();

    this.loader = new ProVizLoader();
  }

  private WaitForProcessing(): Promise<void> {
    return new Promise((resolve, reject) => {
      const checkForProcessing = setInterval(() => {
        if (!this.processing) {
          clearInterval(checkForProcessing);
          resolve();
        }
      }, 100);
    });
  }

  async Generate(model: APIModel, file: APIFile, materialSetId?: string) {
    await this.WaitForProcessing();
    const object: Group = await this.loadMeshFromFile(file, materialSetId);
    object.scale.set(model.scaleX, model.scaleY, model.scaleZ);
    object.rotation.set(model.rotX, model.rotY, model.rotZ);
    this.scene.add(object);
    this.fitCameraToObject(object);

    this.renderer.render(this.scene, this.camera);
    const result = this.canvas.toDataURL();
    this.scene.remove(object);

    return result;
  }

  private buildScene() {
    const scene = new Scene();

    scene.add(new AmbientLight(0xffffff, 0.4));

    const directionalLight = new DirectionalLight(0xffffff, 1);
    directionalLight.position.set(40, 30, 20);
    directionalLight.lookAt(0, 0, 0);
    scene.add(directionalLight);

    return scene;
  }

  private async loadMeshFromFile(modelFile: APIFile, materialSetId?: string) {
    const model = await this.loader.LoadGLTF(modelFile.location);

    // Load material
    if (materialSetId) {
      const proVizMaterialSet = await MaterialSetService.get(materialSetId);
      // Assign material to model
      await ProVizLoader.ApplyMaterialSet(model, proVizMaterialSet);
    }

    // Return Group obj
    return model;
  }

  private buildRender(): WebGLRenderer {
    const renderer = new WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
    });
    renderer.setClearColor(new Color(255, 255, 255), 0);
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(this.screenDimensions.x, this.screenDimensions.y);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    return renderer;
  }

  private buildCamera() {
    const aspectRatio = this.screenDimensions.x / this.screenDimensions.y;
    const fieldOfView = 60;
    const nearPlane = 0.1;
    const farPlane = 2000;
    const camera = new PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );

    camera.position.x = 400;
    camera.position.y = 400;
    camera.position.z = 400;
    camera.lookAt(0, 0, 0);

    return camera;
  }

  private fitCameraToObject(object: Object3D, offset?: number) {
    const boundingBox = new Box3();

    boundingBox.setFromObject(object);

    const center = boundingBox.getCenter(new Vector3());
    const size = boundingBox.getSize(new Vector3());

    this.camera.position.set(1, 1, 1);
    this.camera.position.normalize();

    offset = offset || size.length();

    this.camera.position.set(
      this.camera.position.x * offset,
      this.camera.position.y * offset,
      this.camera.position.z * offset
    );
    this.camera.lookAt(center);
  }
}
