import { useEffect, useRef, useState } from "react";
import { Link, RouteComponentProps, useParams } from "react-router-dom";
import { APIModel, ModelService } from "@proviz/api-services";

import ModelViewManager from "../../graphics/ModelViewManager";
import { TokenProps } from "../../auth/WithToken";

import ModelName from "./ModelName";
import ModelControls from "./ModelControls";

import HistoryControls from "../common/HistoryControls";
import Header from "../common/header/Header";

import {
  ScaleReferenceButton,
  AnnotationButton,
  GridButton,
  EditorToolbar,
} from "../common/editortoolbar";
import { LoadingModal, LoadingModalHolder } from "../common/loadingmodal";

import ModelShareButton from "../common/sharebutton/ModelShareButton";
import FileSelect from "../common/fileselect/FileSelect";
import Button from "../common/button/Button";
import { ManagerStore } from "../../store";

enum PageState {
  LoadingData,
  LoadingModel,
  Loaded,
  Failed,
}

type Params = { id: string };

interface Props extends RouteComponentProps<Params>, TokenProps {}

const ModelEditor: React.FC<{ props: Props }> = (props) => {
  const { id: modelId } = useParams<{ id: string }>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [manager] = useState<ModelViewManager>(new ModelViewManager(modelId));
  const setModelViewManager = ManagerStore((s) => s.setModelViewManager);
  setModelViewManager(manager);

  const [pageState, setPageState] = useState<PageState>(PageState.LoadingData);
  const [model, setModel] = useState<APIModel | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;
    manager.connectToDom(containerRef.current);
    manager.resize();
    window.addEventListener("resize", () => manager.resize());
    return window.removeEventListener("resize", () => manager.resize());
  }, [containerRef, manager]);

  useEffect(() => {
    const getModel = async () => {
      try {
        const gModel = await ModelService.get(modelId);
        console.log(gModel);
        setModel(gModel);
        setPageState(PageState.LoadingModel);
        await manager.init(gModel);
        setPageState(PageState.Loaded);
      } catch (err) {
        console.error(err);
        setPageState(PageState.Failed);
      }
    };
    getModel();
  }, [modelId, manager]);

  if (pageState === PageState.Failed) {
    return (
      <div>
        <h1>Oops, we're having trouble with our servers. Try again soon.</h1>
      </div>
    );
  }

  return (
    <div className="container">
      <FileSelect>
        <Header centerTitle={model && <ModelName model={model} />}>
          <HistoryControls manager={manager} visible={true} />
          <ModelShareButton modelId={modelId} title={model?.name || ""} />
          <Link to="/" className="homeButton">
            <Button>Home</Button>
          </Link>
        </Header>
        <div className="modelEditorContainer">
          <div className="canvasContainer" ref={containerRef}>
            <EditorToolbar>
              <GridButton manager={manager} />
              <ScaleReferenceButton />
              <AnnotationButton />
            </EditorToolbar>
          </div>
          <div className="rightControls">
            {model && <ModelControls model={model} />}
          </div>
        </div>
        {(pageState === PageState.LoadingData ||
          pageState === PageState.LoadingModel) && (
          <LoadingModal
            close={() => {}}
            text={
              pageState === PageState.LoadingData
                ? "Downloading data"
                : "Downloading Model"
            }
          />
        )}
      </FileSelect>
      <LoadingModalHolder />
    </div>
  );
};

export default ModelEditor;
