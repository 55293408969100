import { ReactElement, useRef, useState } from 'react';
import { SceneService, } from "@proviz/api-services"
import { getAbsoluteUrl, } from "@proviz/proviz-sdk"
import { ISceneRecord } from './ThumbnailCard';
import Modal from '../common/modals/Modals';

const blueSlash = getAbsoluteUrl('images/slash.svg');

interface Props {
    scene: ISceneRecord;
    refresh: () => void;
    close: () => void;
}

export default function SceneThumbnailModal(props: Props): ReactElement {
    const { scene, close } = props;
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const [thumbnailSrc, setThumbnailSrc] = useState(scene.thumbnailFile ? scene.thumbnailFile.location : blueSlash);
    const [isthumbnailset, setIsThumbnailSet] = useState(false);

    async function setThumbnail(e: any) {
        let file = e.target.files[0];
        setThumbnailSrc(URL.createObjectURL(file))
        await SceneService.SetThumbnail(scene.id, file);
        setIsThumbnailSet(true);
        props.refresh();
    }

    function uploadThumbnail() {
        if (hiddenFileInput.current instanceof HTMLInputElement) {
            hiddenFileInput.current?.click();
        }
    }

    return <Modal
        title={scene.name}
        close={close}
    >
        <p style={{ marginBottom: '50px' }}>Click Image to Choose Thumbnail</p>
        <img src={thumbnailSrc} alt='' onClick={uploadThumbnail} style={{ height: '100px', width: '100px', cursor: 'pointer' }} />
        <input type="file"
            accept="image/*"
            ref={hiddenFileInput}
            onChange={setThumbnail}
            style={{ display: 'none' }}
        />
        {isthumbnailset && <p style={{ marginBottom: '50px' }}>Thumbnail has been set</p>}
    </Modal>
}
