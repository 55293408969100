import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class StringWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'string';

  // Data
  public value: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'String';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = StringWidget.type;
    this.widgetName = 'String';
    this.selectable = true;
    this.events = [
      {
        label: 'Value',
        name: 'value',
      },
    ];

    this.services = [
      {
        label: 'Apply',
        name: 'apply',
      },
    ];

    this.addEventListener('service-apply', (event: ProVizEventData) => {
      this.emitNewValue();
    });
  }

  private emitNewValue() {
    this.triggerProVizEvent('value', 'string', this.value);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'value',
        'Value',
        'Data',
        'multi-line-string',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'This is the value that will be set',
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.value = this.value;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.value = data.value;
  }
}

ModuleService.Register(StringWidget.type, StringWidget);
ModuleService.Register('resource', StringWidget);
