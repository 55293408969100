import { ProVizEventData } from './ProVizEventData';

type Listener = (data: ProVizEventData) => void;
interface ListenerEntry {
  type: string;
  listener: Listener;
  options: { once: boolean };
}

/**
 * This is a custom implementation of an eventdispatcher (interface should stay
 * largely consistent with Dom/nodejs event targets/dispatchers).
 * We use this because Safari <= 13 does not allow elements not on the dom to emit events.
 */
export class EventDispatcher {
  _listeners: ListenerEntry[];
  constructor() {
    this._listeners = [];
  }

  hasEventListener(type: string, listener: Listener) {
    return this._listeners.some((item) => item.type === type && item.listener === listener);
  }

  addEventListener(type: string, listener: Listener, once = false) {
    if (!this.hasEventListener(type, listener)) {
      this._listeners.push({ type, listener, options: { once } });
    }
    // console.log(`${this}-listeners:`,this._listeners);
    return this;
  }

  removeEventListener(type: string, listener: Listener) {
    let index = this._listeners.findIndex(
      (item) => item.type === type && item.listener === listener,
    );
    if (index >= 0) {
      this._listeners.splice(index, 1);
    }
    //        console.log(`${this}-listeners:`, this._listeners);
    return this;
  }

  removeEventListeners() {
    this._listeners = [];
    return this;
  }

  dispose() {
    this.removeEventListeners();
  }

  /**
   * Remove any listerns whose name includes the pattern
   */
  fuzzyRemoveEvents(pattern: string) {
    this._listeners = this._listeners.filter((l) => !l.type.includes(pattern));
  }

  dispatchEvent(eventType: string, data: ProVizEventData) {
    this._listeners
      .filter((item) => item.type === eventType)
      .forEach((item) => {
        const {
          type,
          listener,
          options: { once },
        } = item;
        listener.call(this, data);
        if (once === true) this.removeEventListener(type, listener);
      });
    // console.log(`${this}-listeners:`,this._listeners);
    return this;
  }
}
