import { DatabaseService } from '@proviz/api-services';
import { APIDatabase } from '@proviz/api-services/src/models/Database';
import { ModuleService } from '../../../../moduleService';
import { ProVizScene, SceneMode } from '../../../../ProVizScene';
import { BaseWidget } from '../../../baseWidget';
import { BaseWidgetProperty } from '../../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../../IBaseWidgetType';

export class DatabaseWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'database';

  // Data
  public databaseId: string | undefined = undefined;

  private databaseOptions: APIDatabase[] = [];

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Database';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = DatabaseWidget.type;
    this.widgetName = 'Database';
    this.selectable = true;
    this.events = [ ];

    this.services = [ ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('databaseId', 'Database', 'Core', 'select', 'string', true, undefined, undefined, () => {
        const options = this.databaseOptions.map(x => {
            return {
                key: x.id,
                label: x.name || ''
            };
        });
        return [
            { key: '', label: '[None]'},
            ...options
        ]
      }),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.databaseId = this.databaseId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.databaseId = data.databaseId ?? this.databaseId;
  }

  public override init(): Promise<boolean> {
    if (this.scene.sceneMode === SceneMode.Editor) {
        DatabaseService.getAll().then((data) => {
            this.databaseOptions = data;
            this.databaseId = this.databaseId ?? data[0].id;
        });
    }
      return super.init();
  }
}

ModuleService.Register(DatabaseWidget.type, DatabaseWidget);
