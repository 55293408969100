import { Clock } from 'three';
import debounce from 'lodash/debounce';
import { MultiLangOption, ProVizScene } from '../../..';
import { APIFile, FileService } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import { SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { ProVizEventData } from '../../../ProVizEventData';

export class ClosedCaptionWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'closed-caption';
  public static instances: ClosedCaptionWidget[] = [];
  public static closedCaptionsOn: boolean = true;
  public static closedCaptionsContainer: HTMLElement;
  private static styleEl?: HTMLStyleElement;
  public videoFile?: APIFile;
  private static instanceCount = 0;

  // Data
  public videoOptions: MultiLangOption = {};
  public setVisibleOnPlay: boolean = false;
  public version = 2;

  private video?: HTMLVideoElement;
  private ccButton?: HTMLDivElement;
  private static loaded: boolean = false;

  public imageIds: MultiLangOption = {};
  public imageSources: MultiLangOption = {};
  /**
   * This boolean keeps track of if the widget received a play
   * event while it was not visible or not done initializing.
   *  */
  private shouldPlay: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ClosedCaptionWidget.type;
    this.widgetName = 'Closed Caption';
    ClosedCaptionWidget.instanceCount++;
    this.label = 'Closed Caption ' + ClosedCaptionWidget.instanceCount;
    this.category = 'Experimental';
    this.events.push(
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Play Finished',
        name: 'play-finished',
      },
      {
        label: 'Play Started',
        name: 'play-started',
      },
      {
        label: 'Play Paused',
        name: 'play-paused',
      },
    );
    this.services.push(
      {
        label: 'Play',
        name: 'play',
        desc: '<b>Plays the video from current time</b>',
      },
      {
        label: 'Stop',
        name: 'stop',
        desc: '<b>Stops the video and resets playback location to start</b>',
      },
      {
        label: 'Pause',
        name: 'pause',
        desc: '<b>Pauses the video</b>',
      },
    );
    this.addEventListener('service-play', (event: ProVizEventData) => {
      this.play();
      if (!this.video) {
        return;
      }
      if (event.dataType === 'number') {
        const time = event.data as number;

        this.video.currentTime = time;
        if (this.video.currentTime !== time) {
          const timer = new Clock();
          timer.start();
          const setTimeOnCanPlay = () => {
            timer.stop();
            if (this.video) {
              this.video.currentTime = time + timer.getDelta();
              this.video.removeEventListener('canplay', setTimeOnCanPlay);
            }
          };
          this.video.addEventListener('canplay', setTimeOnCanPlay);
        }
      }
    });
    this.addEventListener('service-pause', () => this.pause());
    this.addEventListener('service-stop', () => {
      this.pause();
      if (!this.video) {
        return;
      }
      this.video.currentTime = 0;
    });
  }
  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'videoOptions',
        'Video Options',
        'Video Properties',
        'video-options',
        'string',
        true,
      ),
      this.createProperty('imageIds', 'File IDs', 'Core', 'image-options', 'string', true),
    ];
  }

  private async getImageSource() {
    const fileId =
      this.imageIds[this.scene.selectedLanguage] ?? this.imageIds[this.scene.defaultLanguage] ?? '';
    if (!fileId) {
      return undefined;
    }
    const file = await FileService.get(fileId, { abortController: this.scene.abortController });
    return file.location;
  }

  public serialize(): any {
    const result = super.serialize();
    result.videoOptions = this.videoOptions;
    result.setVisibleOnPlay = this.setVisibleOnPlay;
    result.imageIds = this.imageIds;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.videoOptions = data.videoOptions ?? this.videoOptions;
    this.setVisibleOnPlay = data.setVisibleOnPlay ?? this.setVisibleOnPlay;
    this.imageIds = data.imageIds ?? this.imageIds;
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }
    switch (data.version) {
      case 1:
        if (!data.videoOptions) {
          data.videoOptions = { en: data.videoId ?? '' };
        } else {
          const videoIds = {};
          data.videoOptions.split(',').forEach((t: string) => {
            const parts = t.split(':');
            videoIds[parts[0]] = decodeURIComponent(parts[1]);
          });
          data.videoOptions = videoIds;
        }
    }
    return data;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    let file = await this.getImageSource();

    ClosedCaptionWidget.instances.push(this);
    if (!ClosedCaptionWidget.closedCaptionsContainer) {
      const container = document.createElement('div');
      container.setAttribute('class', 'closed-caption-container');
      ClosedCaptionWidget.closedCaptionsContainer = container;
      this.scene.parentEl?.append(container);

      const debouncedUpdateContainerSize = debounce(
        () => ClosedCaptionWidget.updateContainerSize(),
        200,
        {
          trailing: true,
        },
      );
      window.addEventListener('resize', () => debouncedUpdateContainerSize());
    }

    if (!ClosedCaptionWidget.styleEl) {
      ClosedCaptionWidget.styleEl = document.createElement('style');
      ClosedCaptionWidget.styleEl.innerText = `
.proviz-button.cc {
    font-weight: 900;
    padding: 4px 3px 2px 3px;
}
.proviz-button.cc.active {
  background: #7a7a7a;
  color: #fff;
}
.proviz-button.cc.active:hover {
  color: #7a7a7a;
  background: none;
}
.closed-caption-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  max-height: 30%;
  display: grid;
  place-content: center;
}
.closed-captions-video {
  margin: auto;
  height: 90%;
  width: 95%;
  opacity: 0.85;
  color: #fff;
}
.closed-captions-video.hide {
  display: none;
  width: 0px;
  height: 0px;
}`;
      document.body.append(ClosedCaptionWidget.styleEl);
    }
    const ccButton = document.createElement('div');
    const ccImage = document.createElement('img');
    ccImage.style.height = '60px';
    ccImage.style.width = '60px';
    ccImage.style.borderRadius = '12px';
    this.ccButton = ccButton;

    this.ccButton.setAttribute('class', 'proviz-button hide');

    if (file) {
      ccImage.src = file;
      this.ccButton.appendChild(ccImage);
    } else {
      this.ccButton.innerText = 'CC';
    }

    this.ccButton.addEventListener('click', () => {
      ClosedCaptionWidget.closedCaptionsOn = !ClosedCaptionWidget.closedCaptionsOn;
      this.video?.setAttribute(
        'class',
        ClosedCaptionWidget.closedCaptionsOn
          ? 'closed-captions-video'
          : 'closed-captions-video hide',
      );
      ClosedCaptionWidget.updateContainerSize();
      ccButton.setAttribute(
        'class',
        `proviz-button cc ${ClosedCaptionWidget.closedCaptionsOn ? 'active' : ''}`,
      );
    });
    const tooltip = document.createElement('div');
    tooltip.setAttribute('class', 'tooltip');
    tooltip.innerText =
      this.scene.ccHelpText[this.scene.selectedLanguage] ??
      this.scene.ccHelpText[this.scene.defaultLanguage];
    this.scene.addEventListener('language-change', () => {
      tooltip.innerText =
        this.scene.ccHelpText[this.scene.selectedLanguage] ??
        this.scene.ccHelpText[this.scene.defaultLanguage];
    });
    this.ccButton.append(tooltip);

    const btnContainer = document.getElementById('proviz-button-container');
    if (btnContainer) {
      btnContainer.append(this.ccButton);
    } else {
      console.error('No btn container');
      throw new Error('No button container');
    }

    if (this.videoOptions) {
      let videoId = this.getVideoId();
      if (!videoId) {
        console.error('Missing video id for ', this.label);
        return true;
      }

      const video = document.createElement('video');
      this.video = video;
      this.video.setAttribute('crossorigin', 'anonymous');
      this.video.crossOrigin = 'anonymous';
      this.video.setAttribute('id', this.id);
      this.video.setAttribute('webkit-playsinline', '');
      this.video.setAttribute('playsinline', '');
      this.video.setAttribute('muted', 'true');
      this.video.setAttribute('disableRemotePlayback', '');
      this.video.muted = true;

      this.scene.addEventListener('language-change', async () => {
        const newVideoId = this.getVideoId();
        if (!newVideoId) {
          console.error('Missing video id for ', this.label);
          return;
        }
        // If its the same video do nothing
        if (newVideoId === videoId) {
          return;
        }
        videoId = newVideoId;
        this.videoFile = await FileService.get(newVideoId);
        video.setAttribute('src', FileService.getLocation(this.videoFile));
      });

      this.videoFile = await FileService.get(videoId);
      this.video.setAttribute('src', FileService.getLocation(this.videoFile));

      this.video.onended = () => {
        this.triggerProVizEvent('play-finished', 'none');
        ccButton.setAttribute('class', 'proviz-button hide');
        video.setAttribute('class', 'closed-captions-video hide');
      };

      this.video.onplay = () => this.triggerProVizEvent('play-started', 'none');
      this.video.onpause = () => this.triggerProVizEvent('play-paused', 'none');
      this.video.setAttribute('class', 'closed-captions-video hide');
      ClosedCaptionWidget.closedCaptionsContainer.append(this.video);
      if (this.shouldPlay) {
        this.play();
      }
    }
    if (!ClosedCaptionWidget.loaded) {
      ClosedCaptionWidget.updateContainerSize();
      ClosedCaptionWidget.loaded = true;
    }
    return true;
  }

  private play() {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }
    if (ClosedCaptionWidget.instances.length > 0) {
      // Stop and hide all other closed caption instances
      ClosedCaptionWidget.instances.forEach((i) => {
        if (i.id === this.id) return;
        i.pause();
      });
    }
    if (!this.visible || !this.isInitialized || !this.video) {
      this.shouldPlay = true;
      return;
    }
    this.ccButton?.setAttribute(
      'class',
      `proviz-button cc ${ClosedCaptionWidget.closedCaptionsOn ? 'active' : ''}`,
    );
    if (ClosedCaptionWidget.closedCaptionsOn) {
      this.video.setAttribute('class', 'closed-captions-video');
    }
    this.video
      .play()
      .then(() => {
        ClosedCaptionWidget.updateContainerSize();
      })
      .catch((e) => {
        console.error('CC play call failed', e);
      });
  }

  private pause() {
    this.shouldPlay = false;
    this.ccButton?.setAttribute('class', 'proviz-button hide');
    this.video?.setAttribute('class', 'closed-captions-video hide');
    this.video?.pause();
  }

  /**
   * use the selected language video and fall back to default if that doesn't exist and no id
   * if that doens't exist either
   */
  private getVideoId = () =>
    this.videoOptions[this.scene.selectedLanguage] ??
    this.videoOptions[this.scene.defaultLanguage] ??
    '';

  private static updateContainerSize() {
    if (!ClosedCaptionWidget.closedCaptionsContainer) {
      return;
    }
    // centers closed caption video in center of screen left and beginning of proviz-button-container
    const btnContainer = document.getElementById('proviz-button-container');
    if (!btnContainer) {
      console.error('No  button container');
      return;
    }
    ClosedCaptionWidget.closedCaptionsContainer.setAttribute(
      'style',
      `width: calc(100% - ${btnContainer.clientWidth}px)`,
    );
  }

  public dispose(): void {
    super.dispose();
    this.video?.remove();
    this.ccButton?.remove();
    ClosedCaptionWidget.instances = ClosedCaptionWidget.instances.filter((c) => c !== this);
  }
}

ModuleService.Register(ClosedCaptionWidget.type, ClosedCaptionWidget);
