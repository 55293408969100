import { APICompany, APICompanyLicense, APIRole, CompanyLicenseService, RoleService } from "@proviz/api-services";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../common/button/Button";
import GlobalTag from "../common/general/globalTag";
import PageHeader from "../home/pageHeader";

const UnlimitedTag = styled.span`
    cursor: pointer;
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
`;

const LicenseInput = styled.input`
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: 0;
    margin: 0 1rem;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.125);
`;

const LicenseCheckbox = styled.input`
    cursor: pointer;
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: 0;
    margin: 0 1rem;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.125);
    width: 1.5rem;
    height: 1.5rem;
`;

function LicenseNumberInput(props: { value?: number, onChange: (val?: number) => void }) {
    return <div>
        {props.value !== undefined && props.value !== null && <>
            <LicenseInput type="number" value={props.value} onChange={(e) => {
                const val = parseInt(e.target.value);
                console.log(val);
                props.onChange(val);
            }} />
            <Button onClick={() => {
                props.onChange(undefined);
            }}>X</Button>
        </>}
        {(props.value === undefined || props.value === null) && <>
            <UnlimitedTag onClick={() => {
                props.onChange(1);
            }}>Unlimited</UnlimitedTag>
        </>}
    </div>
}

function LicenseDateInput(props: { value?: Date, onChange: (val?: number) => void }) {
    return <div>
        {props.value !== undefined && props.value !== null && <>
            <LicenseInput type="date" value={`${props.value.toISOString().split('T')[0]}`} onChange={(e) => {
                props.onChange(+new Date(e.target.value));
            }} />
            <Button onClick={() => {
                props.onChange(undefined);
            }}>X</Button>
        </>}
        {(props.value === undefined || props.value === null) && <>
            <UnlimitedTag onClick={() => {
                props.onChange(+new Date());
            }}>Never</UnlimitedTag>
        </>}
    </div>
}

const WarningEl = styled.div`
    background: #fceed6;
    font-size: 1.25rem;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
`;

export default function LicenseEditView() {
    const [license, setLicense] = useState<APICompanyLicense | undefined>(undefined);
    const [ saving, setSaving ] = useState(false);
    const [ roles, setRoles ] = useState<APIRole[]>([]);
    const [ orgs, setOrgs ] = useState<APICompany[]>([]);

    // get id from route param
    const params = useParams<{ id: string }>();

    useEffect(() => {
        CompanyLicenseService.get(params.id).then(setLicense);
        RoleService.getAll().then(setRoles);
    }, []);

    useEffect(() => {
        if (license) {
            CompanyLicenseService.getOrgs(license.license.id).then(setOrgs);
        }
    }, [license]);

    const saveOrgLicense = async () => {
        setSaving(true);
        if (license) {
            await CompanyLicenseService.updateExpiration(params.id, license.expires);
        }
        setSaving(false);
    }

    const saveLicense = async () => {
        setSaving(true);
        if (license) {
            await CompanyLicenseService.update(license.licenseId, license);
        }
        setSaving(false);
    }

    return <div className="homeRightView">
        <PageHeader />
        <div className='homeContent'>
            <div className='homeContentContainer'>
                <div className='paddedContainer'>
                    <h3>License: {license?.license?.name}</h3>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Expires for Organization</td>
                                    <td>
                                        <LicenseDateInput value={license?.expires ? new Date(license?.expires) : undefined} onChange={(val?: number) => {
                                            if (license && license.license) {
                                                if (val) {
                                                    license.expires = new Date(val);
                                                } else {
                                                    license.expires = undefined;
                                                }
                                                setLicense({...license})
                                            }
                                        }} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div>
                            <Button fullWidth disabled={saving} onClick={() => {
                                saveOrgLicense();
                            }}>Save</Button>
                        </div>
                    </div>
                    <br />
                    {!license?.license.companyId && <>
                        <WarningEl>
                            This is a global license. Changes to the below will affect all companies using it
                        </WarningEl>
                        <br />
                    </>}
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Enabled</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Max Users</td>
                                    <td>
                                        <LicenseNumberInput value={license?.license?.settings.maxUsers} onChange={(val?: number) => {
                                            if (license && license.license) {
                                                license.license.settings.maxUsers = val;
                                                setLicense({...license})
                                            }
                                        }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Max Scenes</td>
                                    <td>
                                        <LicenseNumberInput value={license?.license?.settings.maxScenes} onChange={(val?: number) => {
                                            if (license && license.license) {
                                                license.license.settings.maxScenes = val;
                                                setLicense({...license})
                                            }
                                        }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Max Models</td>
                                    <td>
                                        <LicenseNumberInput value={license?.license?.settings.maxModels} onChange={(val?: number) => {
                                            if (license && license.license) {
                                                license.license.settings.maxModels = val;
                                                setLicense({...license})
                                            }
                                        }} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Enabled</th>
                                </tr>
                            </thead>
                            <tbody>
                                {roles.map((role) => {
                                    const roleValue = license?.license?.settings.roles?.find((r) => r.roleId === role.id)?.maxUsers;
                                    return <tr>
                                        <td>{role.name} {!role.companyId && <GlobalTag>GLOBAL</GlobalTag>}</td>
                                        <td>
                                            <LicenseNumberInput value={roleValue} onChange={(val?: number) => {
                                                if (license && license.license) {
                                                    const roles = license.license.settings.roles || [];
                                                    const ind = roles.findIndex((r) => r.roleId === role.id);
                                                    if (ind === -1) {
                                                        roles.push({
                                                            roleId: role.id,
                                                            maxUsers: val
                                                        });
                                                        license.license.settings.roles = roles;
                                                    } else if (ind !== undefined) {
                                                        roles[ind].maxUsers = val;
                                                        license.license.settings.roles = roles;
                                                    }
                                                    setLicense({...license})
                                                }
                                            }} />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Enabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Work Instruct</td>
                                <td><LicenseCheckbox type="checkbox" checked={!!((license?.license?.settings?.features || []).find(x => x === 'work-instruct'))} onChange={(e) => {
                                    if (license && license.license) {
                                        const features = license.license.settings.features || [];
                                        const ind = features.findIndex((r) => r === 'work-instruct');
                                        if (ind === -1 && e.target.checked) {
                                            features.push('work-instruct');
                                        } else if (ind > -1 && !e.target.checked) {
                                            features.splice(ind, 1);
                                        }
                                        license.license.settings.features = features;
                                        setLicense({...license})
                                    }
                                }} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <div>
                        <Button fullWidth disabled={saving} onClick={() => {
                            saveLicense();
                        }}>Save</Button>
                    </div>

                    <br />
                    <hr />
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Organization</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orgs.map((org) => {
                                    return <tr>
                                        <td>{org.name}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}