import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { GUIBaseWidget } from '../guiBaseWidget';

export class GUIRowWidget extends GUIBaseWidget implements IBaseWidgetType {
  public static type: string = 'gui-row';

  // Data
  private padding: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = GUIRowWidget.type;
    this.widgetName = 'GUI Row';
    this.label = 'GUI Row';
    this.category = 'UI';
    this.events = [];
    this.allowedWidgets = ['gui-column'];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('padding', 'Padding', 'Core', 'number', 'number', true, undefined, (data) => {
        this.padding = data;
        this.setStyle();
      }),
    ];
  }

  private setStyle() {
    const style = `
        display: flex;
        flex-direction: row;
        padding: ${this.padding}px;
    `;
    this.guiElement?.setAttribute('style', style);
  }

  public serialize() {
    const result = super.serialize();
    result.padding = this.padding;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.padding = data.padding ?? this.padding;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.parent) {
      const gui = this.parent as GUIBaseWidget;
      const guiParent = gui.guiElement;

      this.guiElement = document.createElement('div');
      this.guiElement.setAttribute('class', 'gui-row');
      this.setStyle();
      guiParent?.appendChild(this.guiElement);
    }

    return true;
  }
}

ModuleService.Register(GUIRowWidget.type, GUIRowWidget);
