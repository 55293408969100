type EventHandlerCallback = (data?: any) => void;

export class EventHandler {
  private listeners: { [key: string]: EventHandlerCallback[] } = {};

  public addListener(event: string, callback: EventHandlerCallback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  public removeListener(event: string, callback: EventHandlerCallback) {
    if (!this.listeners[event]) {
      throw new Error("Event does not exists");
    }

    const ind = this.listeners[event].indexOf(callback);
    if (ind > -1) {
      this.listeners[event].splice(ind, 1);
    }
  }

  public dispatch(event: string, data?: any) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((c) => c(data));
    }
  }
}
