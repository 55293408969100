import { CircleGeometry, Material, Mesh, MeshBasicMaterial, TextureLoader } from 'three';
import { MultiLangOption, ProVizScene, SceneMode } from '../../..';
import { getMediaFilePath } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import AnimatedSpriteMaterial from '../../../utils/AnimatedSpriteMaterial';
import SpriteResource from '../../../utils/SpriteResource';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import BasePositionableWidget from '../../basePositionableWidget';

const playButtonResource = new SpriteResource({
  id: 'circularButtonDefaultSprite',
  imageSrc: 'e9ae25ec-92d9-45c8-9877-1e7a16638a25.png',
  verticalTiles: 1,
  horizontalTiles: 60,
  framesPerSecond: 15,
  numberOfTiles: 60,
});

interface IMarkerComponets {
  marker: Mesh<CircleGeometry, MeshBasicMaterial>;
  markerPlayButton: Mesh<CircleGeometry, Material>;
  markerBorder: Mesh<CircleGeometry, MeshBasicMaterial>;
}

export class CircularButton extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'circular-button-widget';
  private static instanceCount = 0;
  private static styleEl?: HTMLStyleElement;

  // Data
  public imageSrc: string = '';
  public borderColor: string = '#fff';
  public borderHighlightColor: string = '#7eaf46';
  public hoverText: MultiLangOption = {};
  public outputOptions: string = '';
  public closedCaptionSource: string = '';
  public version = 2;

  private markerPieces?: IMarkerComponets;
  private hoverEl?: HTMLElement;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = CircularButton.type;
    this.widgetName = 'Circle Image';
    CircularButton.instanceCount++;
    this.label = 'Circle Image ' + CircularButton.instanceCount;
    this.category = 'Experimental';
    this.selectable = true;

    this.events.push(
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Selected',
        name: 'selected',
        desc: '<b>Triggered on click</b><br /><i>Output: <b>String</b> (Set with Output String)</i>',
      },
    );

    this.addEventListener('clicked', () => this.handleClick());
  }

  private handleClick() {
    const outputOpts = {};
    // Parse the options once and use in the closure.
    this.outputOptions.split(',').forEach((t: string) => {
      const parts = t.split(':');
      outputOpts[parts[0]] = decodeURIComponent(parts[1]);
    });
    const outputString = this.scene.selectedLanguage
      ? outputOpts[this.scene.selectedLanguage] || ''
      : outputOpts[this.scene.defaultLanguage] || '';

    this.triggerProVizEvent('selected', 'videoSource', {
      videoSrc: outputString,
      closedCaptionSource: this.closedCaptionSource,
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'imageSrc',
        '',
        'Data',
        'image-options',
        'string',
        true,
        undefined,
        (data: any) => {
          this.imageSrc = data;
        },
      ),
      this.createProperty('borderColor', 'Border Color', 'Data', 'string', 'string', true),
      this.createProperty('borderHighlightColor', 'Border Highlight Color', 'Data', 'string', 'string', true),
      this.createProperty('hoverText', 'Hover Text', 'Data', 'multi-lang-opts', 'string', true),
      this.createProperty('outputOptions', 'Video Sources', 'Data', 'options', 'string', true),
      this.createProperty('closedCaptionSource', 'Closed Caption Source', 'Data', 'string', 'string', true),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    // Add 3D clickable hotspot node
    if (!this.imageSrc) {
      return true;
    }
    const imgSrc = getMediaFilePath(this.imageSrc);

    const geometry = new CircleGeometry(0.28, 32);
    const borderGeometry = new CircleGeometry(0.3, 32);
    const textureLoader = new TextureLoader();
    const tex = textureLoader.load(imgSrc);
    const marker = new Mesh(
      geometry,
      new MeshBasicMaterial({ map: tex, transparent: true, opacity: 0.9 }),
    );
    const markerBorder = new Mesh(
      borderGeometry,
      new MeshBasicMaterial({ color: this.borderColor }),
    );
    markerBorder.position.set(0, 0, -0.01);
    markerBorder.renderOrder = 1;
    marker.add(markerBorder);

    const circleGeo = new CircleGeometry(0.1, 32);
    const markerPlayButton = new Mesh(
      circleGeo,
      AnimatedSpriteMaterial.Instance(playButtonResource.id, playButtonResource).material,
    );
    markerPlayButton.position.set(0, 0, 0.01);
    markerPlayButton.renderOrder = 2;
    marker.add(markerPlayButton);
    marker.renderOrder = 2;
    marker.receiveShadow = false;

    this.renderNode.add(marker);
    this.markerPieces = { marker, markerBorder, markerPlayButton };

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    if (!CircularButton.styleEl) {
      CircularButton.styleEl = document.createElement('style');
      CircularButton.styleEl.innerText = `
.circle-widget-hover-text {
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  z-index: 1000;
  background: #808080;
  border: 1px solid #7fa934;
  color: #fff;
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
}`;
      document.body.append(CircularButton.styleEl);
    }

    this.hoverEl = document.createElement('div');
    this.hoverEl.setAttribute('class', 'circle-widget-hover-text');
    this.hoverEl.setAttribute('style', 'visibility: hidden;');
    this.hoverEl.innerText =
      this.hoverText[this.scene.selectedLanguage] ??
      this.hoverText[this.scene.defaultLanguage] ??
      '';

    this.scene.parentEl?.append(this.hoverEl);
    this.scene.addEventListener('language-change', () => {
      if (!this.hoverEl) {
        console.warn('Hover el should exist');
        return;
      }
      this.hoverEl.innerText =
        this.hoverText[this.scene.selectedLanguage] ??
        this.hoverText[this.scene.defaultLanguage] ??
        '';
    });
    return true;
  }

  public dispose(): void {
    super.dispose();
    if (this.markerPieces) {
      this.markerPieces.marker.geometry.dispose();
      this.markerPieces.marker.material.dispose();
      this.markerPieces.markerPlayButton.geometry.dispose();
      this.markerPieces.markerPlayButton.material.dispose();
      this.markerPieces.markerBorder.geometry.dispose();
      this.markerPieces.markerBorder.material.dispose();
    }
    this.hoverEl?.remove();
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }
    switch (data.version) {
      case 1:
        data.hoverText = { en: data.hoverText };
    }
    return data;
  }

  public onHoverEnter() {
    if (!this.markerPieces) {
      return;
    }
    const { markerBorder, marker } = this.markerPieces;
    markerBorder.material.color.set(this.borderHighlightColor);
    marker.material.opacity = 1;
    const pos = this.scene.toScreenPosition(marker, this.scene.camera, this.scene.renderer);
    this.hoverEl?.setAttribute('style', `left: ${pos.x + 30}px; top: ${pos.y}px;`);
  }

  public onHoverLeave() {
    if (!this.markerPieces) {
      return;
    }
    const { markerBorder, marker } = this.markerPieces;
    markerBorder.material.color.set(this.borderColor);
    marker.material.opacity = 0.9;
    this.hoverEl?.setAttribute('style', 'visibility: hidden;');
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.markerPieces) {
      const { marker, markerBorder, markerPlayButton } = this.markerPieces;
      result.push(marker, markerBorder, markerPlayButton);
    }
    return result;
  }

  public getHoverable() {
    const result = super.getHoverable();
    if (this.visible && this.markerPieces) {
      const { marker, markerBorder, markerPlayButton } = this.markerPieces;
      result.push(marker, markerBorder, markerPlayButton);
    }
    return result;
  }

  public serialize(): any {
    const result = super.serialize();
    result.imageSrc = this.imageSrc;
    result.borderHighlightColor = this.borderHighlightColor;
    result.borderColor = this.borderColor;
    result.hoverText = this.hoverText;
    result.outputOptions = this.outputOptions;
    result.closedCaptionSource = this.closedCaptionSource;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.imageSrc = data.imageSrc;
    this.borderHighlightColor = data.borderHighlightColor;
    this.borderColor = data.borderColor;
    this.hoverText = data.hoverText ?? this.hoverText;
    if (data.closedCaptionSource) this.closedCaptionSource = data.closedCaptionSource;
    if (!data.outputOptions) {
      this.outputOptions = `en:${data.outputString},`;
    } else {
      this.outputOptions = data.outputOptions;
    }
  }
}

ModuleService.Register(CircularButton.type, CircularButton);
