import { useCallback, useEffect, useState } from 'react'
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { FileError, useDropzone } from 'react-dropzone';
import Button from '../button/Button';

const uploadIcon = getAbsoluteUrl('images/upload.svg');

interface Props {
    onUpload: (acceptedFiles: File[], rejectedFiles?: File[]) => void;
    accept: string | string[];
    text?: string;
    validator?: <T extends File>(file: T) => FileError | FileError[] | null;
    reset: () => void;
    postUploadText?: string;
    multiple?: boolean;
    hide?: boolean;
    hideUploadImage?: boolean;
    isFolderUpload?: boolean;
    small?: boolean;
    showFilesSelected?: boolean;
    uploadFile?: boolean;
}

export const Dropzone = (props: Props) => {
    const { onUpload, accept, validator, text, hideUploadImage, isFolderUpload, showFilesSelected, uploadFile } = props;
    const [hide, setHide] = useState(props.hide ?? false);
    const [rejected, setRejected] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any[]) => {
        setSelectedFiles(acceptedFiles);
        if (acceptedFiles.length === 0) {
            setRejected(true)
            return;
        }

        console.log('Files dropped on dropzone:', acceptedFiles, rejectedFiles)
        onUpload(acceptedFiles);
        setHide(true);
    }, [onUpload]);

    useEffect(() => {
        setHide(props.hide ?? hide)
    }, [props.hide, hide])


    const onDropRejected = useCallback(() => {
        setRejected(true)
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone(
        { onDrop, accept, validator, onDropRejected, multiple: props.multiple });

    const uploadFolder = hideUploadImage && isFolderUpload
        ? {
            directory: "",
            webkitdirectory: "",
            type: "file",
        }
        : {};

    return (
        <>
            <div className={`proviz-dropzone ${props.small ? 'small' : ''} ${isDragActive ? 'active' : ''} ${(hide || rejected) && !props.multiple ? 'hide' : ''} ${hideUploadImage ? 'proviz-dropzone-small' : ''}`}
                {...getRootProps()}  >
                <input {...getInputProps()}
                    {...uploadFolder}
                />
                {!hideUploadImage &&
                    <>
                        <img className={`${text === "Drop any additional resources here." ? "upload" : ""}`} src={uploadIcon} alt='Upload' />
                        {text && <h2>{text}</h2>}
                    </>
                }
                {hideUploadImage && isFolderUpload &&
                    <Button>Choose Folder</Button>
                }
                {hideUploadImage && !isFolderUpload && !uploadFile &&
                    <Button>Choose Files</Button>
                }
                {hideUploadImage && !isFolderUpload && uploadFile && 
                    <Button>Choose File</Button>
                }
            </div>
            {showFilesSelected && <div>
                {selectedFiles.map((file: File) => <div>
                    {file.name} <div onClick={() => { setRejected(false); setHide(false); props.reset(); setSelectedFiles([]); } } className="remove"><span>X</span></div>
                </div>)}
            </div>}
            {hide && !rejected && props.postUploadText && !props.multiple &&
                <Button type='primary'
                    onClick={() => { setHide(false); props.reset(); }}>
                    {props.postUploadText}
                </Button>
            }
            {rejected && !props.multiple &&
                <>
                    <p>File rejected</p>
                    <Button type='primary' onClick={() => { setRejected(false); setHide(false); setSelectedFiles([]); props.reset(); }}>
                        Reset
                    </Button>
                </>
            }
        </>
    );
}

export default Dropzone;