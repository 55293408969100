import { debounce } from 'lodash';
import { APIFile, FileService } from "@proviz/api-services";
import { useEffect, useRef, useState } from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import Modal from '../common/modals/Modals';
import { DownloadFileButton } from './DownloadFileButton';
import { UploadThumbnailButton } from './UploadThumbnailButton';

interface Props { 
    file: APIFile;
    close: () => void;
}

const SaveStates = ['Saving', 'Saved'];
type SaveState = typeof SaveStates[number];

export const FileEditModal = (props: Props) => {
    const { file, close } = props;
    const [saveState, setSaveState] = useState<SaveState>('Saved');

    const [name, setName] = useState(file.displayName || file.fileName || "");

    const isFirstRender = useRef(true);
    const debouncedUpdate = useRef(debounce(async (fileData: APIFile) => {
        await FileService.update(fileData);
        setSaveState('Saved');
    }, 1000, {trailing: true }));
    
    useEffect(() => {
        if (isFirstRender.current) {
            // we should not update the server when we first load the values
            isFirstRender.current = false;
            return;
        }
        setSaveState('Saving');
        const fileData = Object.assign({}, file);
        fileData.displayName = name;
        debouncedUpdate.current(fileData);
    }, [file, name]);

    let lastEdit = "";
    let d = new Date(file.updated + "Z");
    try {
        lastEdit = formatDistanceToNow(d, { includeSeconds: true });
    } catch (e: any) {
        console.error("Time since failed", e.message, file.id, d);
    }

    return <Modal 
        title={"Edit File"}
        statusMessage={saveState}
        close={close}
    >
        <div className="fileEditModal">
            <p>Name: </p>  
            <p><input className="nameInput" value={name} onChange={(e) => setName(e.target.value)} /></p>

            <p>Updated: </p>
            <p>{new Date(file.updated + "Z").toLocaleString()}</p>

            <p>Created: </p>
            <p>{new Date(file.created + "Z").toLocaleString()}</p>

            <p>Time Since Last Edit: </p>
            <p>{lastEdit}</p>

            <p>Uploaded By: </p>
            <p>{file?.user?.firstName} {file?.user?.lastName}</p>

            <p>Type: </p>
            <p>{file.extension}</p>
            <DownloadFileButton file={file} />
            <UploadThumbnailButton fileId={file.id} />
        </div>
    </Modal>
}

