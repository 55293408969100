import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class NumberWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'number';

  //Data
  public value = 0;
  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = NumberWidget.type;
    this.widgetName = 'Number';
    this.label = 'Number';
    this.usage = 'Flow';
    this.category = 'Events';
    this.events.push({
      label: 'Value',
      name: 'value',
    });
    this.addService(
      'set Value',
      'set-value',
      '<b>Updates Number value</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'number') {
          this.value = event.data as number;
        } else if (event.dataType === 'string') {
          const val = parseInt(event.data as string);
          if (!Number.isNaN(val)) {
            this.value = event.data as number;
          }
        }
        this.triggerProVizEvent('value', 'number', this.value);
      },
    );
    this.addService('apply', 'apply', '<b>Apply Number value</b>', (event: ProVizEventData) => {
      this.triggerProVizEvent('value', 'number', this.value);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('value', 'Value', 'Core', 'number', 'number', true)];
  }

  public serialize(): any {
    const result = super.serialize();
    result.value = this.value;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.value = data.value;
  }
}

ModuleService.Register(NumberWidget.type, NumberWidget);
