import { ReactElement } from 'react'

interface Props {
    value: number;
    fieldName: string;
    handleChange: (e: any) => void;
}

export default function MaterialInput(props: Props): ReactElement {
    const { value, fieldName, handleChange } = props;
    const displayVal = value.toFixed ? value.toFixed(3) : value
    return <>
        <p>{fieldName}</p>
        <input value={displayVal} onChange={handleChange} />
    </>;
}
