import { BoxGeometry, Color, Mesh, MeshBasicMaterial, SphereGeometry } from 'three';
import { ARCSSectionWidget } from '..';
import { ModelWidget, MultiLangOption, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ARCSStepWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'arcs-step';

  // Data
  public audioOptions: MultiLangOption = {};
  private title: string = '';
  private description: string = '';
  private stepType: string = '';
  private prebuilt: boolean = false;
  private validate: boolean = false;
  private overrideShowVideos: boolean = false;
  private overrideShowImages: boolean = false;
  private overrideShowDescription: boolean = false;
  private playAudioOnStart: boolean = true;

  private arcsDefaultStepCenterModelId: string = '';
  private arcsDefaultToggleVideosModelId: string = '';
  private arcsDefaultToggleTextModelId: string = '';
  private arcsDefaultToggleImagesModelId: string = '';
  private arcsDefaultNextModelId: string = '';
  private arcsDefaultValidateModelId: string = '';

  private showVideoWidgets: string[] = [];
  private showImageWidgets: string[] = [];
  private showDescriptionWidgets: string[] = [];

  private displayModel: ModelWidget | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ARCSStepWidget.type;
    this.widgetName = 'ARCS Step';
    this.label = 'ARCS Step';
    this.category = 'ARCS';
    this.events = [
      {
        label: 'On Show',
        name: 'show',
      },
      {
        label: 'On Hide',
        name: 'hide',
      },
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Started',
        name: 'started',
      },
      {
        label: 'Finished',
        name: 'finished',
      },
      {
        label: 'Show Videos',
        name: 'show-videos',
      },
      {
        label: 'Hide Videos',
        name: 'hide-videos',
      },
      {
        label: 'Show Images',
        name: 'show-images',
      },
      {
        label: 'Hide Images',
        name: 'hide-images',
      },
      {
        label: 'Show Description',
        name: 'show-description',
      },
      {
        label: 'Hide Description',
        name: 'hide-description',
      },
      {
        label: 'Play Audio',
        name: 'play-audio',
      },
      {
        label: 'Stop Audio',
        name: 'stop-audio',
      },
    ];

    this.services.push({
      label: 'Validate',
      name: 'validate',
      desc: '<b>Validate the step with a photo</b>',
    });

    this.services.push({
      label: 'Finish',
      name: 'finish',
      desc: '<b>Completes the step</b>',
    });

    this.services.push({
      label: 'Show Videos',
      name: 'show-videos',
      desc: '<b>Show videos event when overriding</b>',
    });
    this.services.push({
      label: 'Show Images',
      name: 'show-images',
      desc: '<b>Show images event when overriding</b>',
    });
    this.services.push({
      label: 'Show Description',
      name: 'show-description',
      desc: '<b>Show description event when overriding</b>',
    });

    this.addEventListener('service-validate', (event) => {
      console.warn('validation does not happen in the web');
      this.nextStep();
    });

    this.addEventListener('service-finish', (event) => {
      // move to the next step
      this.nextStep();
    });
  }

  private nextStep() {
    this.triggerProVizEvent('hide', 'none');
    if (this.parent) {
      const section: ARCSSectionWidget = this.parent as ARCSSectionWidget;
      if (section) {
        section.nextStep();
      }
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const baseProperties = super.getProperties();
    const result = [
      ...baseProperties,
      this.createProperty('validate', 'Validate Step', 'Data', 'bool', 'boolean', true),
      this.createProperty('audioOptions', 'Audio Sources', 'Data', 'audio-options', 'string', true),
      this.createProperty(
        'playAudioOnStart',
        'Play Audio on Start',
        'Data',
        'bool',
        'boolean',
        true,
      ),
      this.createProperty('title', 'Title', 'Data', 'string', 'string', true),
      // this.createProperty('stepType', 'StepType', 'Data', 'string', true),
      this.createProperty(
        'stepType',
        'StepType',
        'Data',
        'select',
        'string',
        true,
        undefined,
        (data) => {
          this.stepType = data;
        },
        () => {
          return ['default', 'no-content', 'alert-warning'].map((x) => {
            return { key: x, label: x };
          });
        },
      ),
      this.createProperty(
        'description',
        'Description',
        'Data',
        'multi-line-string',
        'string',
        true,
      ),
      this.createProperty(
        'overrideShowVideos',
        'Override Show Videos',
        'Data',
        'bool',
        'boolean',
        true,
      ),
      this.createProperty(
        'showVideoWidgets',
        'Show Video Widgets',
        'Data',
        'widget-list',
        'list',
        true,
      ),
      this.createProperty(
        'overrideShowImages',
        'Override Show Images',
        'Data',
        'bool',
        'boolean',
        true,
      ),
      this.createProperty(
        'showImageWidgets',
        'Show Image Widgets',
        'Data',
        'widget-list',
        'list',
        true,
      ),
      this.createProperty(
        'overrideShowDescription',
        'Override Show Description',
        'Data',
        'bool',
        'boolean',
        true,
      ),
      this.createProperty(
        'showDescriptionWidgets',
        'Show Description Widgets',
        'Data',
        'widget-list',
        'list',
        true,
      ),
      this.createProperty(
        'arcsDefaultStepCenterModelId',
        'Step Center Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used as the base of an ARCS Step behind the text title',
      ),
      this.createProperty(
        'arcsDefaultToggleVideosModelId',
        'Toggle Videos Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the toggle videos button',
      ),
      this.createProperty(
        'arcsDefaultToggleTextModelId',
        'Toggle Text Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the toggle description button',
      ),
      this.createProperty(
        'arcsDefaultToggleImagesModelId',
        'Toggle Images Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the toggle images button',
      ),
      this.createProperty(
        'arcsDefaultNextModelId',
        'Next Step Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the next step/proceed button',
      ),
      this.createProperty(
        'arcsDefaultValidateModelId',
        'Validate Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the validate button',
      ),
    ];
    return result;
  }

  public serialize(): any {
    const result = super.serialize();
    result.audioOptions = this.audioOptions;
    result.title = this.title;
    result.description = this.description;
    result.stepType = this.stepType;
    result.prebuilt = this.prebuilt;
    result.validate = this.validate;

    result.overrideShowVideos = this.overrideShowVideos;
    result.overrideShowImages = this.overrideShowImages;
    result.overrideShowDescription = this.overrideShowDescription;
    result.playAudioOnStart = this.playAudioOnStart;

    result.arcsDefaultStepCenterModelId = this.arcsDefaultStepCenterModelId;
    result.arcsDefaultToggleVideosModelId = this.arcsDefaultToggleVideosModelId;
    result.arcsDefaultToggleTextModelId = this.arcsDefaultToggleTextModelId;
    result.arcsDefaultToggleImagesModelId = this.arcsDefaultToggleImagesModelId;
    result.arcsDefaultNextModelId = this.arcsDefaultNextModelId;
    result.arcsDefaultValidateModelId = this.arcsDefaultValidateModelId;

    result.showVideoWidgets = this.showVideoWidgets;
    result.showImageWidgets = this.showImageWidgets;
    result.showDescriptionWidgets = this.showDescriptionWidgets;

    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.audioOptions = data.audioOptions ?? this.audioOptions;
    this.title = data.title;
    this.description = data.description;
    this.stepType = data.stepType;
    this.prebuilt = data.prebuilt ?? this.prebuilt;
    this.validate = data.validate ?? this.validate;
    this.overrideShowVideos = data.overrideShowVideos ?? this.overrideShowVideos;
    this.overrideShowImages = data.overrideShowImages ?? this.overrideShowImages;
    this.overrideShowDescription = data.overrideShowDescription ?? this.overrideShowDescription;
    this.playAudioOnStart = data.playAudioOnStart ?? this.playAudioOnStart;

    this.arcsDefaultStepCenterModelId = data.arcsDefaultStepCenterModelId;
    this.arcsDefaultToggleVideosModelId = data.arcsDefaultToggleVideosModelId;
    this.arcsDefaultToggleTextModelId = data.arcsDefaultToggleTextModelId;
    this.arcsDefaultToggleImagesModelId = data.arcsDefaultToggleImagesModelId;
    this.arcsDefaultNextModelId = data.arcsDefaultNextModelId;
    this.arcsDefaultValidateModelId = data.arcsDefaultValidateModelId;

    this.showVideoWidgets = data.showVideoWidgets;
    this.showImageWidgets = data.showImageWidgets;
    this.showDescriptionWidgets = data.showDescriptionWidgets;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    // Editor only display mesh
    if (this.scene.sceneMode === SceneMode.Editor) {
      const centerModelId =
        this.arcsDefaultStepCenterModelId ||
        (this.scene.arcsConfig && this.scene.arcsConfig.arcsDefaultStepCenterModelId);
      if (centerModelId) {
        this.displayModel = new ModelWidget(this.scene, this);
        this.displayModel.modelId = centerModelId;
        this.displayModel.deserialize({
          modelId: centerModelId,
          clickable: false,
          transparent: false,
          opacity: 1.0,
        });
        await this.displayModel.init();
        this.renderNode.add(this.displayModel.renderNode);
      } else {
        const geometry = new SphereGeometry(0.05);
        const mesh = new Mesh(
          geometry,
          new MeshBasicMaterial({
            color: new Color(0xcccccc),
          }),
        );
        this.renderNode.add(mesh);
      }

      const geometryBox = new BoxGeometry(0.08, 0.08, 0.08);
      const mesh2 = new Mesh(
        geometryBox,
        new MeshBasicMaterial({
          color: new Color(0x00cc00),
        }),
      );
      mesh2.position.set(0, 0, 0.15);
      this.renderNode.add(mesh2);
    } else {
      this.setVisible(false);
    }

    return true;
  }
}

ModuleService.Register(ARCSStepWidget.type, ARCSStepWidget);
