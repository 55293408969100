import { ReactElement } from 'react';
import { APIMaterial } from "@proviz/api-services";

interface Props {
    materials: APIMaterial[] | null;
    selection: number;
    setSelection: (idx: number) => void;
}

export default function MaterialSelector(props: Props): ReactElement {
    const { materials, selection, setSelection } = props;

    function handleClick(index: number) {
        setSelection(index);
    }

    return <ul className="materialSelector">
        {materials && materials.map((m, idx) => {
            return (<li key={idx}
                value={idx}
                className={selection === idx ? 'selected' : ''}
                onClick={() => handleClick(idx)}>
                {`${idx} -> ${m.name ?? 'Unamed Material'}`}
            </li>)
        })}
    </ul>;
}
