import { ProVizEventDataTypes, WidgetPropertyType } from '.';
import { BaseWidgetProperty, PropertyCategory } from './widgets/BaseWidgetProperty';

export class ProVizSceneARCSConfig {
  arcsDefaultStepCenterModelId: string = '';
  arcsDefaultToggleVideosModelId: string = '';
  arcsDefaultToggleTextModelId: string = '';
  arcsDefaultToggleImagesModelId: string = '';
  arcsDefaultNextModelId: string = '';
  arcsDefaultValidateModelId: string = '';

  deserialize(data: any) {
    (this.arcsDefaultStepCenterModelId = data.arcsConfig?.arcsDefaultStepCenterModelId),
      (this.arcsDefaultToggleVideosModelId = data.arcsConfig?.arcsDefaultToggleVideosModelId),
      (this.arcsDefaultToggleTextModelId = data.arcsConfig?.arcsDefaultToggleTextModelId),
      (this.arcsDefaultToggleImagesModelId = data.arcsConfig?.arcsDefaultToggleImagesModelId),
      (this.arcsDefaultNextModelId = data.arcsConfig?.arcsDefaultNextModelId),
      (this.arcsDefaultValidateModelId = data.arcsConfig?.arcsDefaultValidateModelId);
  }

  serialize() {
    const result = {
      arcsDefaultStepCenterModelId: this.arcsDefaultStepCenterModelId,
      arcsDefaultToggleVideosModelId: this.arcsDefaultToggleVideosModelId,
      arcsDefaultToggleTextModelId: this.arcsDefaultToggleTextModelId,
      arcsDefaultToggleImagesModelId: this.arcsDefaultToggleImagesModelId,
      arcsDefaultNextModelId: this.arcsDefaultNextModelId,
      arcsDefaultValidateModelId: this.arcsDefaultValidateModelId,
    };
    return result;
  }

  getProperties() {
    return [
      this.createProperty(
        'arcsDefaultStepCenterModelId',
        'Step Center Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used as the base of an ARCS Step behind the text title',
      ),
      this.createProperty(
        'arcsDefaultToggleVideosModelId',
        'Toggle Videos Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the toggle videos button',
      ),
      this.createProperty(
        'arcsDefaultToggleTextModelId',
        'Toggle Text Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the toggle description button',
      ),
      this.createProperty(
        'arcsDefaultToggleImagesModelId',
        'Toggle Images Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the toggle images button',
      ),
      this.createProperty(
        'arcsDefaultNextModelId',
        'Next Step Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the next step/proceed button',
      ),
      this.createProperty(
        'arcsDefaultValidateModelId',
        'Validate Model',
        'Overrides',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model that will be used on an ARCS Step for the validate button',
      ),
    ];
  }

  private createProperty(
    name: string,
    label: string,
    category: PropertyCategory,
    widgetType: WidgetPropertyType,
    dataType: ProVizEventDataTypes,
    editable?: boolean,
    get?: () => any,
    set?: (data: any) => void,
    options?: () => any,
    infoText?: string,
  ) {
    const prop = new BaseWidgetProperty(name, label, category, widgetType, dataType, editable, infoText);
    prop.get = get || (() => this[name]);
    prop.set =
      set ||
      ((data: string) => {
        this[name] = data;
      });
    prop.options = options;
    return prop;
  }
}
