import {
  CircleGeometry,
  Material,
  Mesh,
  MeshBasicMaterial,
  Shape,
  ShapeGeometry,
  Vector2,
} from 'three';
import { ProVizScene, SceneMode } from '../../..';
import { getMediaFilePath } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import { is_android, is_ios, is_landscape } from '../../../utils';
import AnimatedSpriteMaterial from '../../../utils/AnimatedSpriteMaterial';
import SpriteResource from '../../../utils/SpriteResource';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import BasePositionableWidget from '../../basePositionableWidget';
import { MultiLangOption } from '../../..';

const OpenTime = 1.8;
const CloseTime = 0.8;
const ImageHeightFactor = 0.3;
const ImageWidthFactor = 0.2;
const StartingPos = new Vector2();
const TextDest = new Vector2();
const Pos = new Vector2();

const radialSprite: SpriteResource = new SpriteResource({
  id: 'timelineElementDefaultSprite',
  imageSrc: '07522b41-96ef-4ae9-a1fc-2d3452a01592.png',
  verticalTiles: 8,
  horizontalTiles: 8,
  framesPerSecond: 30,
  numberOfTiles: 60,
});

/**
 * HTML Elements used by widget.
 */
interface IElements {
  backgroundEl: HTMLVideoElement;
  imageEl: HTMLElement;
  el: HTMLElement;
  titleEl: HTMLElement;
  contentEl: HTMLElement;
  hoverEl: HTMLElement;
}
export class TimelineWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'timeline-element-widget';
  private static instanceCount = 0;
  public static backgroundVideos: { [url: string]: HTMLVideoElement };
  private static styleEl?: HTMLStyleElement;

  // Data
  public titles: MultiLangOption = {};
  public textOptions: MultiLangOption = {};
  public backgroundSpriteSrc: string = '';
  public imageSrc: string = '';
  public borderColor: string = '#1a5633';
  public borderHighlightColor: string = '#75833f';
  public version = 2;

  private marker?: Mesh<ShapeGeometry, MeshBasicMaterial>;
  private markerRadial?: Mesh<CircleGeometry, Material>;
  private markerBorder?: Mesh<ShapeGeometry, MeshBasicMaterial>;
  private elements?: IElements;
  private elVisible: boolean = false;
  private timeSinceChange: number = 0;
  private initial = true;
  private moving = false;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = TimelineWidget.type;
    this.widgetName = 'Timeline Element';
    if (!notInScene) {
      TimelineWidget.instanceCount++;
    }
    this.label = 'Timeline element ' + TimelineWidget.instanceCount;
    this.category = 'Experimental';
    this.selectable = true;

    this.events.push({
      label: 'Clicked',
      name: 'clicked',
    });
    this.events.push({
      label: 'Open',
      name: 'open',
    });
    this.events.push({
      label: 'Close',
      name: 'close',
    });

    this.services.push({
      label: 'Show 1',
      name: 'show',
    });
    this.services.push({
      label: 'Hide 1',
      name: 'hide',
    });

    this.addEventListener('clicked', () => {
      this.changeVisibility(!this.elVisible);
    });
    this.addEventListener('service-open', () => {
      this.changeVisibility(true);
    });
    this.addEventListener('service-close', () => {
      this.changeVisibility(false);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('titles', 'Title', 'Data', 'multi-lang-opts', 'string', true),
      this.createProperty('textOptions', 'Content', 'Data', 'multi-lang-opts-multi-line', 'string', true),
      this.createProperty(
        'imageSrc',
        '',
        'Data',
        'image-options',
        'string',
        true,
        undefined,
        (data: any) => {
          this.imageSrc = data;
        },
      ),
      this.createProperty('backgroundSpriteSrc', 'Background Video Src', 'Data', 'string', 'string', true),
      this.createProperty('borderColor', 'Border Color', 'Data', 'string', 'string', true),
      this.createProperty('borderHighlightColor', 'Border Highlight Color', 'Data', 'string', 'string', true),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    // Add 3D clickable hotspot node
    if (!this.imageSrc) {
      return continueInitializing;
    }

    const imgSrc = getMediaFilePath(this.imageSrc);
    const backgroundSpriteSrc = getMediaFilePath(this.backgroundSpriteSrc);

    const shape = TimelineWidget.drawShape(0, 0, 1.25, 1.25, 0.4);
    const borderShape = TimelineWidget.drawShape(-0.1, -0.1, 1.35, 1.35, 0.4);
    const geometry = new ShapeGeometry(shape);
    const borderGeometry = new ShapeGeometry(borderShape);
    // const textureLoader = new TextureLoader();
    // const tex = textureLoader.load(imgSrc);
    this.marker = new Mesh(
      geometry,
      new MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0 }),
    );
    this.markerBorder = new Mesh(
      borderGeometry,
      new MeshBasicMaterial({ color: this.borderColor }),
    );
    this.markerBorder.position.set(0.05, 0.05, -0.01);
    this.markerBorder.renderOrder = 1;
    this.marker.add(this.markerBorder);
    const circleGeo = new CircleGeometry(0.3, 32);
    this.markerRadial = new Mesh(
      circleGeo,
      AnimatedSpriteMaterial.Instance(radialSprite.id, radialSprite).material,
    );
    this.markerRadial.position.set(0.625, 0.625, 0.02);
    this.markerRadial.renderOrder = 2;
    this.marker.add(this.markerRadial);
    this.marker.renderOrder = 2;
    this.marker.receiveShadow = false;

    this.renderNode.add(this.marker);

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    // Add 2D node
    if (!TimelineWidget.styleEl) {
      TimelineWidget.styleEl = document.createElement('style');
      TimelineWidget.styleEl.innerText = `
.custom-timeline-el {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  min-width: 200px;
  max-width: 400px;
  font-family: Arial, Helvetica, sans-serif;
  ${is_ios ? 'background: #dedede; border-radius: 10px; padding: 5px;' : ''}
}
.custom-timeline-el.hide {
  display: none;
  visibility: hidden;
}
.custom-timeline-el .container {
  position: relative;
}
.custom-timeline-el .content {
  max-height: 100px;
  overflow-y: auto;
  font-size: 16px;
  padding: 5px;
  border-radius: 10px;
}
.custom-timeline-el .content::-webkit-scrollbar {
  width: 5px;
}
.custom-timeline-background {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.6;
  width: 100%; 
  height: 100%;
  background: #dedede;
}
.custom-timeline-background.hide {
  display: none;
  width: 0px;
  height: 0px;
}
.custom-timeline-image-el {
  position: absolute;
  border-radius: 0px 25px 0px 25px;
  border: 4px solid ${this.borderHighlightColor};
}
.custom-timeline-image-el.hide {
  display: none;
  visibility: hidden;
  height: 0px;
  width: 0px;
}
.timeline-hover-text {
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  z-index: 1000;
  background: #808080;
  border: 1px solid #7fa934;
  color: #fff;
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
}
.timeline-hover-text.hide {
  height: 0px;
  width: 0px;
  display: none;
}`;
      document.body.append(TimelineWidget.styleEl);
    }

    const el = document.createElement('div');
    el.setAttribute('id', `timeline-el-${this.id}`);
    el.setAttribute('class', 'custom-timeline-el hide');

    const innerContainer = document.createElement('div');
    innerContainer.setAttribute('class', 'inner-container');
    el.append(innerContainer);

    const textContainer = document.createElement('div');
    textContainer.setAttribute('class', 'container');
    innerContainer.append(textContainer);

    const backgroundEl = this.createVideoEl(backgroundSpriteSrc);
    if (!is_android && !is_ios) {
      backgroundEl.addEventListener('click', () => this.changeVisibility(false));
    }

    const contentEl = document.createElement('div');
    contentEl.setAttribute('class', 'content');

    const titleEl = document.createElement('h4');
    textContainer.append(titleEl);

    contentEl.onscroll = (e) => {
      e.stopPropagation();
      e.preventDefault();
      return false;
    };
    textContainer.append(contentEl);

    const imageEl = document.createElement('img');
    imageEl.setAttribute('src', imgSrc);
    imageEl.setAttribute('class', 'custom-timeline-image-el hide');
    imageEl.addEventListener('click', () => {
      if (this.elVisible) {
        this.changeVisibility(false);
      }
    });
    this.scene?.renderer?.domElement?.parentElement?.append(imageEl);

    const hoverEl = document.createElement('div');
    hoverEl.setAttribute('class', 'timeline-hover-text hide');
    hoverEl.setAttribute('style', 'visibility: hidden;');
    // this.hoverEl.innerText = this.title;
    this.scene?.renderer?.domElement?.parentElement?.append(hoverEl);
    this.elements = { hoverEl, imageEl, contentEl, titleEl, el, backgroundEl };
    this.setDisplayText();
    this.scene.addEventListener('language-change', () => {
      this.setDisplayText();
    });
    this.scene?.renderer?.domElement?.parentElement?.append(el);
    this.changeVisibility(false, true);
    this.initial = true;
    return true;
  }

  private setDisplayText() {
    if (!this.elements) {
      return;
    }
    const { hoverEl, titleEl, contentEl } = this.elements;
    // Parse and set titles

    const titleContent =
      this.titles[this.scene.selectedLanguage ?? this.scene.defaultLanguage] || '';
    hoverEl.innerText = titleContent;
    titleEl.innerText = titleContent;

    if (this.scene.selectedLanguage) {
      contentEl.innerText = this.textOptions[this.scene.selectedLanguage] || '';
    } else {
      contentEl.innerText = this.textOptions[this.scene.defaultLanguage] || '';
    }
  }

  public update(delta: number) {
    super.update(delta);
    if (!this.elements || this.initial || !this.moving) {
      return;
    }
    this._update(delta);
  }

  private _update(delta: number) {
    this.timeSinceChange += delta;
    const dimensions = this.scene.dimensions;
    if (!dimensions || !this.elements) {
      console.error('No dimensions for timeline wdg');
      return;
    }
    const { el, imageEl } = this.elements;
    const textWidthOffset = el.clientWidth / 2;
    const timeLimit = this.elVisible ? OpenTime : CloseTime;
    if (this.timeSinceChange <= timeLimit) {
      const timeRatio = this.timeSinceChange / timeLimit;
      const scale = this.elVisible ? Math.min(1, timeRatio) : Math.min(1, 1 - timeRatio);

      const imgSize = {
        h: dimensions.height * ImageHeightFactor * scale,
        w: dimensions.width * ImageWidthFactor * scale,
      };
      const imgPos = {
        x: dimensions.width / 2 - imgSize.w / 2,
        y: is_landscape() ? 10 : dimensions.height / 2 - imgSize.h / 2,
      };
      imageEl.setAttribute(
        'style',
        `max-width: ${imgSize.h}px; max-height: ${imgSize.h}px; left: ${imgPos.x}px; top: ${imgPos.y}px; `,
      );

      const startPos = this.getTextStartingPos(imgPos, imgSize, textWidthOffset);
      const dest = this.getTextDest(imgPos, imgSize, textWidthOffset);
      Pos.lerpVectors(startPos, dest, Math.min(timeRatio, 1));
      el.setAttribute('style', `left: ${Pos.x}px; top: ${Pos.y}px`);
    } else {
      const imgSize = {
        h: dimensions.height * ImageHeightFactor,
        w: dimensions.width * ImageWidthFactor,
      };
      const imgPos = {
        x: dimensions.width / 2 - imgSize.w / 2,
        y: is_landscape() ? 10 : dimensions.height / 2 - imgSize.h / 2,
      };
      Pos.copy(
        this.elVisible
          ? this.getTextDest(imgPos, imgSize, textWidthOffset)
          : this.getTextStartingPos(imgPos, imgSize, textWidthOffset),
      );
      el.setAttribute('style', `left: ${Pos.x}px; top: ${Pos.y}px`);
      imageEl.setAttribute(
        'style',
        `max-width: ${imgSize.h}px; max-height: ${imgSize.h}px; left: ${imgPos.x}px; top: ${imgPos.y}px; `,
      );
      this.moving = false;
    }
  }

  public onHoverEnter() {
    if (!this.elements || !this.marker) {
      return;
    }
    const { hoverEl } = this.elements;
    // @ts-ignore
    this.markerBorder.material.color.set(this.borderHighlightColor);
    // @ts-ignore
    this.marker.material.opacity = 0.8;
    if (this.elVisible) {
      return;
    }
    const pos = this.scene.toScreenPosition(this.marker, this.scene.camera, this.scene.renderer);
    hoverEl.setAttribute('class', 'timeline-hover-text');
    hoverEl.setAttribute('style', `left: ${pos.x + 20}px; top: ${pos.y - 15}px;`);
  }

  public onHoverLeave() {
    if (!this.elements) {
      return;
    }
    const { hoverEl } = this.elements;
    // @ts-ignore
    this.markerBorder.material.color.set(this.borderColor);
    // @ts-ignore
    this.marker.material.opacity = 0;
    hoverEl.setAttribute('class', 'timeline-hover-text.hide');
    hoverEl.setAttribute('style', 'visibility: hidden;');
  }

  private changeVisibility(state: boolean, initial = false) {
    if (this.elVisible === state || !this.elements) {
      return;
    }
    const { hoverEl, backgroundEl, el, imageEl } = this.elements;
    this.moving = true;
    this.elVisible = state;
    if (state) {
      if (!is_ios) {
        backgroundEl.pause();
        backgroundEl.currentTime = 0;
        backgroundEl.setAttribute('class', 'custom-timeline-background');
        setTimeout(() => {
          if (this.elVisible) {
            backgroundEl.pause();
          }
        }, OpenTime * 1000);
      }
      imageEl.setAttribute('class', 'custom-timeline-image-el');
      el.setAttribute('class', 'custom-timeline-el');
      hoverEl.setAttribute('class', 'timeline-hover-text.hide');
      hoverEl.setAttribute('style', 'visibility: hidden;');
    } else {
      backgroundEl.currentTime = 3.8;
      setTimeout(() => {
        if (!this.elVisible) {
          if (!is_ios) {
            backgroundEl.setAttribute('class', 'custom-timeline-background hide');
            backgroundEl.pause();
          }
          el.setAttribute('class', 'custom-timeline-el hide');
          imageEl.setAttribute('class', 'custom-timeline-image-el hide');
        }
      }, CloseTime * 1000);
    }
    if (!initial && !is_ios) {
      backgroundEl.play();
    }
    this.triggerProVizEvent(state ? 'open' : 'close', 'none');
    this.timeSinceChange = 0;
    this.initial = initial;
  }

  getTextStartingPos(
    imgPos: { x: number; y: number },
    imgSize: { h: number; w: number },
    textWidthOffset: number,
  ) {
    const dimensions = this.scene.dimensions;
    if (!dimensions) {
      console.error('No dimensions for timeline wdg');
      return StartingPos;
    }
    return this.elVisible
      ? StartingPos.set(dimensions.width / 2 - textWidthOffset, dimensions.height * 1.3)
      : StartingPos.set(dimensions.width / 2 - textWidthOffset, imgPos.y + imgSize.h + 20);
  }

  getTextDest(
    imgPos: { x: number; y: number },
    imgSize: { h: number; w: number },
    textWidthOffset: number,
  ) {
    const dimensions = this.scene.dimensions;
    if (!dimensions) {
      console.error('No dimensions for timeline wdg');
      return TextDest;
    }
    return this.elVisible
      ? TextDest.set(dimensions.width / 2 - textWidthOffset, imgPos.y + imgSize.h + 20)
      : TextDest.set(dimensions.width / 2 - textWidthOffset, dimensions.height * 1.3);
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.marker) {
      result.push(this.marker);
    }
    return result;
  }

  public getHoverable() {
    const result = super.getHoverable();
    if (this.visible && this.marker) {
      result.push(this.marker);
    }
    return result;
  }

  public serialize(): any {
    const result = super.serialize();

    result.titles = this.titles;
    result.textOptions = this.textOptions;
    result.imageSrc = this.imageSrc;
    result.backgroundSpriteSrc = this.backgroundSpriteSrc;
    result.borderHighlightColor = this.borderHighlightColor;
    result.borderColor = this.borderColor;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);

    this.titles = data.titles ?? this.titles;
    this.textOptions = data.textOptions ?? this.textOptions;
    this.imageSrc = data.imageSrc;
    this.backgroundSpriteSrc = data.backgroundSpriteSrc;
    this.borderHighlightColor = data.borderHighlightColor;
    this.borderColor = data.borderColor;
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }
    switch (data.version) {
      case 1:
        if (!data.titles) {
          data.titles = {};
        } else {
          const titles = {};
          data.titles.split(',').forEach((t: string) => {
            const parts = t.split(':');
            if (parts[1]) {
              try {
                titles[parts[0]] = decodeURIComponent(parts[1]);
              } catch (e) {
                console.error("can't decode", parts[1]);
                titles[parts[0]] = '';
              }
            }
          });
          data.titles = titles;
        }
        if (!data.textOptions) {
          data.textOptions = {};
        } else {
          const textOpts = {};
          // Parse the options once and use in the closure.
          data.textOptions.split(',').forEach((t: string) => {
            const parts = t.split(':');
            if (parts[1]) {
              try {
                textOpts[parts[0]] = decodeURIComponent(parts[1]);
              } catch (e) {
                console.error("can't decode", parts[1]);
              }
            }
          });
          data.textOptions = textOpts;
        }
    }
    return data;
  }

  public dispose(): void {
    super.dispose();
    this.marker?.material.map?.dispose();
    this.marker?.material.dispose();
    this.marker?.geometry.dispose();
    this.markerRadial?.material.dispose();
    this.markerRadial?.geometry.dispose();
    this.markerBorder?.material.dispose();
    this.markerBorder?.geometry.dispose();
    if (this.elements) {
      this.elements.backgroundEl.remove();
      this.elements.imageEl.remove();
      this.elements.titleEl.remove();
      this.elements.contentEl.remove();
      this.elements.hoverEl.remove();
    }
  }

  private createVideoEl(url: string) {
    if (TimelineWidget[url]) {
      return TimelineWidget[url];
    }
    const vid = document.createElement('video');

    vid.setAttribute('src', url);
    vid.setAttribute('disableRemotePlayback', '');
    vid.setAttribute('playsinline', '');
    vid.setAttribute('disablePictureInPicture', '');
    vid.setAttribute('class', 'custom-timeline-background hide');
    this.scene?.renderer?.domElement?.parentElement?.append(vid);
    TimelineWidget[url] = vid;
    return vid;
  }

  private static drawShape(x: number, y: number, width: number, height: number, radius: number) {
    const shape = new Shape();
    shape.moveTo(x, y + radius);
    shape.lineTo(x, y + height);
    shape.lineTo(x + width - radius, y + height);
    shape.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
    shape.lineTo(x + width, y);
    shape.lineTo(x + radius, y);
    shape.quadraticCurveTo(x, y, x, y + radius);
    return shape;
  }
}

ModuleService.Register(TimelineWidget.type, TimelineWidget);
