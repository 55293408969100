import {
  Euler,
  PerspectiveCamera,
  Vector2,
  Event as ThreeEvent,
  Object3D,
  Vector3,
} from "three";
import { MapControls } from "three/examples/jsm/controls/OrbitControls";
import Controls from "./Controls";
import Renderer from "../Renderer";
import SceneManager from "../SceneManager";
import { getMousePosition } from "../Raycast";
import { SetNodePosition, SetNodeScale, SetNodeRotation } from "../../commands";
import { BasePositionableWidget, BaseWidget } from "@proviz/proviz-sdk";
import ProVizTransformControls from "./ProVizTransformControls";

const _euler = new Euler(0, 0, 0, "YXZ");
const _PI_2 = Math.PI / 2;
const _minPolarAngle = 0;
const _maxPolarAngle = Math.PI;

export type ControlMode = "translate" | "rotate" | "scale" | "panorama";

function setupMapControls(camera: PerspectiveCamera, renderer: Renderer) {
  const controls = new MapControls(camera, renderer.domElement);
  controls.dampingFactor = 0.05;
  controls.screenSpacePanning = true;
  controls.minDistance = 0.01;
  controls.maxDistance = 100;
  controls.minPolarAngle = -Math.PI;
  controls.maxPolarAngle = Math.PI;
  controls.minZoom = 0;
  controls.maxZoom = 10000000;
  //controls.enableKeys = false;
  controls.target.set(0, 0, 0);
  controls.update();
  return controls;
}

function samePosition(v1: Vector2, v2: Vector2) {
  return v1.x === v2.x && v1.y && v2.y;
}

export default class EditorControls extends Controls {
  type = "EditorControls";
  public panorama: boolean = false;
  transformControls: ProVizTransformControls;
  mapControls: MapControls;
  mode: ControlMode;
  sceneManager: SceneManager;
  renderer: Renderer;
  mouseDown: boolean = false;
  selectionGroup: Object3D;
  speed: number = 5.0;

  controls = {
    forward: false,
    backward: false,
    left: false,
    right: false,
    rightMouse: false,
  };

  constructor(
    sceneManager: SceneManager,
    camera: PerspectiveCamera,
    renderer: Renderer
  ) {
    super(renderer.domElement, sceneManager.helperScene);

    const initialMode = "translate";
    this.mode = initialMode;
    this.transformControls = new ProVizTransformControls(
      camera,
      renderer.domElement
    );
    this.mapControls = setupMapControls(camera, renderer);
    this.renderer = renderer;
    this.sceneManager = sceneManager;
    this.selectionGroup = new Object3D();
    this.sceneManager.scene.add(this.selectionGroup);

    this.mapControls.addEventListener("change", () =>
      this.sceneManager.render()
    );
    this.sceneManager.attach("selectednode-update", (e?: CustomEvent) => {
      const firstSelected = this.receiveSelectionUpdate();

      if (e?.detail && firstSelected.length) {
        e.detail["id"] = firstSelected[0].id;
      }

      this.controlsHandleChange();
    });

    // binding here instead of using arrow functions to enable listener disposal
    this.panoramaMouseMove = this.panoramaMouseMove.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);

    let transformControls = this.transformControls;
    transformControls.enabled = true;
    transformControls.setMode(initialMode);
    transformControls.addEventListener("change", () =>
      this.controlsHandleChange()
    );
    transformControls.addEventListener("mouseDown", (e) =>
      this.controlsOnMouseDown(e)
    );
    transformControls.addEventListener("mouseUp", (e) =>
      this.controlsOnMouseUp(e)
    );

    sceneManager.helperScene.add(this.transformControls);
    sceneManager.attach("history-update", () => this.controlsHandleChange());

    // OrbitControls uses Pointer Events which requires the app to use Poiner Events, too.
    // https://discourse.threejs.org/t/mousedown-event-is-not-getting-triggered/18685
    this.canvas.parentElement?.addEventListener(
      "pointerdown",
      this.onMouseDown
    );
    this.canvas.parentElement?.addEventListener(
      "pointermove",
      this.onMouseMove
    );
    this.canvas.parentElement?.addEventListener("pointerup", this.onMouseUp);
  }

  public moveForward(state: boolean) {
    this.controls.forward = state;
  }

  public moveBackward(state: boolean) {
    this.controls.backward = state;
  }

  public moveLeft(state: boolean) {
    this.controls.left = state;
  }

  public moveRight(state: boolean) {
    this.controls.right = state;
  }

  public update(delta: number) {
    if (!this.mapControls) {
      return;
    }
    if (!this.controls.rightMouse) {
      return;
    }

    // this.mapControls.object.position.x += () * delta;
    let forward =
      (this.controls.forward ? 1 : 0) + (this.controls.backward ? -1 : 0);
    let right = (this.controls.right ? 1 : 0) + (this.controls.left ? -1 : 0);

    forward *= this.speed;
    right *= this.speed;

    const up = new Vector3(0, 1, 0);
    const camForward = new Vector3();
    this.mapControls.object.getWorldDirection(camForward);
    const camRight = new Vector3();
    camRight.crossVectors(camForward, up);

    this.mapControls.object.position.x +=
      camForward.x * forward * delta + camRight.x * right * delta;
    this.mapControls.object.position.y +=
      camForward.y * forward * delta + camRight.y * right * delta;
    this.mapControls.object.position.z +=
      camForward.z * forward * delta + camRight.z * right * delta;
    // this.mapControls.position0.z += forward * delta;
    this.mapControls.target.x +=
      camForward.x * forward * delta + camRight.x * right * delta;
    this.mapControls.target.y +=
      camForward.y * forward * delta + camRight.y * right * delta;
    this.mapControls.target.z +=
      camForward.z * forward * delta + camRight.z * right * delta;
    this.mapControls.update();
  }

  controlsHandleChange() {
    const objects = this.transformControls.objects;
    if (objects.length === 0) {
      this.setBoxes([]);
      return;
    }
    let nodes = this.sceneManager.selectedNodes; // lookupNodeFromObject(object);
    // if (!node) {
    //   this.setBoxes([]);
    //   return;
    // }
    this.setBoxes(nodes);
    // this.renderer.render();
  }

  handleClick() {
    if (samePosition(this.onDownPosition, this.onUpPosition)) {
      const selectables = this.sceneManager.proVizScene.getSelectables();
      let intersections = this.raycaster.intersectObjects(
        this.onUpPosition,
        selectables,
        this.sceneManager.camera.camera
      );
      if (intersections.length > 0) {
        let nodeSet = false;
        for (let i = 0; i < intersections.length; i++) {
          let obj = intersections[i].object;
          let node = this.getNodeFromIntersection(obj);
          if (
            node &&
            !node.editorLocked &&
            node.selectable &&
            intersections[i].face
          ) {
            nodeSet = true;
            this.sceneManager.setSelection(node);
            break;
          }
        }
        if (!nodeSet) {
          this.sceneManager.setSelection(null);
        }
      } else {
        this.sceneManager.setSelection(null);
      }
    }
  }

  getNodeFromIntersection(o: Object3D): BaseWidget | undefined {
    if (o.userData.widget) {
      return o.userData.widget;
    }
    if (o.parent) {
      return this.getNodeFromIntersection(o.parent);
    }
    return undefined;
  }

  onMouseDown(event: MouseEvent) {
    if (event.button === 2) {
      this.controls.rightMouse = true;
    } else if (event.button === 0) {
      this.mouseDown = true;
    }
    const array = getMousePosition(
      this.canvas.parentElement!,
      event.clientX,
      event.clientY
    );
    this.onDownPosition.fromArray(array);
  }

  onMouseMove(event: MouseEvent) {
    if (!this.mouseDown || !this.panorama || this.transformControls.dragging) {
      return;
    }

    this.panoramaMouseMove(event);
  }

  onMouseUp(event: MouseEvent) {
    if (event.button === 2) {
      this.controls.rightMouse = false;
    } else if (event.button === 0) {
      this.mouseDown = false;
    }
    event.preventDefault();
    const array = getMousePosition(
      this.canvas.parentElement!,
      event.clientX,
      event.clientY
    );
    this.onUpPosition.fromArray(array);
    this.handleClick();
  }

  panoramaMouseMove(event: any) {
    const movementX =
      event.movementX || event.mozMovementX || event.webkitMovementX || 0;
    const movementY =
      event.movementY || event.mozMovementY || event.webkitMovementY || 0;
    _euler.setFromQuaternion(this.sceneManager.camera.camera.quaternion);

    _euler.y += movementX * 0.002;
    _euler.x += movementY * 0.002;
    _euler.x = Math.max(
      _PI_2 - _maxPolarAngle,
      Math.min(_PI_2 - _minPolarAngle, _euler.x)
    );
    this.sceneManager.camera.camera.quaternion.setFromEuler(_euler);
  }

  clearSelections() {
    this.transformControls.detach();
    this.setBoxes([]);
  }

  receiveSelectionUpdate() {
    let selections = this.sceneManager.selectedNodes;
    if (selections.length === 0) {
      this.clearSelections();
    } else {
      this.clearSelections();

      // Only attach top level objects of selected
      const parents = (wdg: BaseWidget) => {
        const result: BaseWidget[] = [];
        if (wdg.parent) {
          result.push(wdg.parent);
          result.push(...parents(wdg.parent));
        }
        return result;
      };

      const widgetsToTransform = selections.filter((o) => {
        function isPositionable(wdg: any): wdg is BasePositionableWidget {
          return wdg.setPosition;
        }
        if (!isPositionable(o)) {
          return false;
        }
        const parentWidgets = parents(o);
        // console.log(parentWidgets, selections);
        let parentInList = false;
        parentWidgets.forEach((p) => {
          if (selections.findIndex((w) => w.id === p.id) > -1) {
            parentInList = true;
          }
        });
        return !parentInList;
      });

      widgetsToTransform.forEach((o) => {
        this.transformControls.attach(o.renderNode);
      });
    }
    return selections;
  }

  controlsOnMouseDown(event: ThreeEvent) {
    const objects = this.transformControls.objects;
    if (objects.length === 0) {
      return;
    }
    this.objectPositionOnDown.copy(objects[objects.length - 1].object.position);
    this.objectRotationOnDown.copy(objects[objects.length - 1].object.rotation);
    this.objectScaleOnDown.copy(objects[objects.length - 1].object.scale);
    if (this.mapControls) {
      this.mapControls.enabled = false;
    }
  }

  controlsOnMouseUp(event: ThreeEvent) {
    const objects = this.transformControls.objects;
    if (objects.length === 0) {
      return;
    }
    const object = objects[objects.length - 1];
    const node: BasePositionableWidget = object.object.userData.widget;
    if (!node) {
      return;
    }
    switch (this.transformControls.getMode()) {
      case "translate":
        if (!this.objectPositionOnDown.equals(object.object.position)) {
          const { x, y, z } = object.object.position;
          this.sceneManager.execute(
            new SetNodePosition(node, { x, y, z }, this.objectPositionOnDown)
          );
        }
        break;
      case "rotate":
        if (!this.objectRotationOnDown.equals(object.object.rotation)) {
          const { x, y, z } = object.object.rotation;
          this.sceneManager.execute(
            new SetNodeRotation(node, { x, y, z }, this.objectRotationOnDown)
          );
        }
        break;
      case "scale":
        if (!this.objectScaleOnDown.equals(object.object.scale)) {
          const { x, y, z } = object.object.scale;
          this.sceneManager.execute(
            new SetNodeScale(node, { x, y, z }, this.objectScaleOnDown)
          );
        }
        break;
    }
    if (this.mapControls) {
      this.mapControls.enabled = true;
    }
  }

  changeMode(mode: ControlMode) {
    this.mode = mode;

    if (mode === "panorama") {
      this.transformControls.enabled = false;
    } else {
      this.transformControls.enabled = true;
      this.transformControls.setMode(mode);
    }
    this.receiveSelectionUpdate();
    this.dispatchModeUpdate();
  }

  dispose() {
    this.removeEventListeners();
    this.transformControls.dispose();
    this.mapControls?.dispose();
    // @ts-ignore
    delete this.mapControls;
    // @ts-ignore
    delete this.transformControls;
  }
}
