import React, { ReactElement, useState } from 'react'
import SetWidgetProperty from '../../../commands/SetWidgetProperty';
import { SettableWidgetProperty } from './SettableWidgetProperty';
import { MultiLangOption } from '@proviz/proviz-sdk';
import { ManagerStore } from '../../../store';

interface Props {
    languages: string[];
    property: SettableWidgetProperty;
    header: ReactElement;
    multiLine: boolean;
}

/**
 * This componet renders an object property where the keys are the languages in the scene.
 * The component is named TextObjectLanguageProperty to disambiguate from the TextLanguageProperty
 * which stores the dictionary as encoded strings and which we are transitioning away from using. 
 */
export const MultiLangTextProperty = (props: Props) => {
    const { header, languages, property, multiLine } = props;
    const [language, setLanguage] = useState(languages[0]);
    const [, updateState] = React.useState<any>();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const manager = ManagerStore((s) => s.GraphicsManager);


    const dict: MultiLangOption = property.get() || {};

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const old = Object.assign({}, dict);
        dict[language] = e.target.value;
        manager.execute(new SetWidgetProperty(property, old, dict, manager));
        forceUpdate();
    }

    return <div className="property-table">
        {header}
        {languages.length > 1 &&
            <select value={language} onChange={e => setLanguage(e.target.value)}>
                {languages.map((l) => <option key={l} value={l}>{l}</option>)}
            </select>}
        <table>
            <tbody>
                <tr>
                    <td>
                        {multiLine
                            ? <textarea value={dict[language] || ''} onChange={onChange} />
                            : <input className="multi-language-input"
                                value={dict[language] || ''}
                                onChange={onChange} />
                        }
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>;
}
