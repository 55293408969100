import { AudioWidget } from './audioWidget';

export class AudioWidgetStudio extends AudioWidget {
  public async init(): Promise<boolean> {
    super.init();

    // add wireframe

    return true;
  }
}
