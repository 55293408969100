import create from 'zustand'

/********************************************************************* TYPES */
type SearchStoreType = {
    search?: string;
    setSearch: (search: string) => void;
}

/********************************************************************* STORE */
export const searchStore = create<SearchStoreType>( (set, get) => ({
    search: '',
    setSearch: (search: string) => set( () =>( { search } ) )
}))
