import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { ModuleService } from '../../../moduleService';
import { is_android, is_ios } from '../../../utils';
import { MultiLangOption, SceneMode } from '../../..';
import { BaseWidgetDataConnection } from '../../BaseWidgetDataConnection';

export interface IMultiLanguageList {
  [language: string]: string[];
}
/**
 * HTML Elements used by widget.
 */
interface IElements {
  el: HTMLElement;
  optionEls: HTMLElement[];
  titleContainerEl: HTMLElement;
  titleEl: HTMLElement;
}

export class TransportMenuWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'transport-menu';
  private static styleEls: {
    dropdown?: HTMLStyleElement;
    hamburger?: HTMLStyleElement;
    fontLato?: HTMLLinkElement;
  } = {};

  public titles: MultiLangOption = {};
  public mobileTitle: string = '...';
  public locations: IMultiLanguageList = {};
  public newWindow: boolean = false;
  public displayStyle: 'dropdown' | 'hamburger' = 'dropdown';
  public menuAlignment: 'left' | 'right' = 'right';
  public bgColor: string = '#fff';
  public textColor: string = '#000';
  public titleTextColor: string = '#000';
  public iconColor: string = '#000';
  public closeIconColor: string = '#000';
  public headerColor: string = '#fff';
  public headerTextColor: string = '#000';
  public coverBgColor: string = '#fff';
  public coverBgOpacity: number = 0.3;
  public showTitleBar: boolean = true;
  public font: 'Default' | 'Lato' = 'Default';
  public version = 2;

  private minimized: boolean = true;
  private elements?: IElements;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = TransportMenuWidget.type;
    this.widgetName = 'Transport Menu';
    this.label = 'Transport Menu';
    this.category = 'Experimental';
    this.usage = 'Flow';
    this.category = 'Events';
    this.selectable = true;

    this.events = [];
    this.showInFlow = true;
  }

  private setupDropdownMenu() {
    const mobileView = (is_android || is_ios) && window.innerWidth <= 600;
    const el = document.createElement('div');
    el.className = 'transport-menu-container';
    el.style.height = this.minimized ? '25px' : '';
    const titleContainerEl = document.createElement('div');
    titleContainerEl.className = 'transport-menu-title-container';
    titleContainerEl.onclick = () => this.toggleOptionVisibility();
    const optionEls: HTMLElement[] = [];
    titleContainerEl.style.borderRadius = this.minimized ? '10px' : '10px 10px 0px 0px';
    const titleEl = document.createElement('h4');
    titleContainerEl.append(titleEl);
    el.append(titleContainerEl);

    const optList = this.locations[this.scene.defaultLanguage] ?? [];
    for (let i = 0; i < optList.length; i++) {
      let opt = optList[i];
      const button = document.createElement('div');
      button.className = 'transport-menu-el';
      if (i === optList.length - 1) {
        button.className += ' last';
      }
      button.innerHTML = opt;
      const eventKey = TransportMenuWidget.EventName(i);
      const e = this.events.find((x) => x.name === eventKey);
      let clickHandler;
      if (e) {
        clickHandler = () => {
          this.triggerProVizEvent(eventKey, 'none');
        };
      }
      button.style.visibility = this.minimized ? 'hidden' : 'visible';
      button.onclick = clickHandler;
      optionEls.push(button);
      el.append(button);
    }
    this.scene?.renderer?.domElement?.parentElement?.append(el);

    this.elements = { el, optionEls, titleContainerEl, titleEl };
    if (this.displayStyle === 'dropdown' && !TransportMenuWidget.styleEls.dropdown) {
      TransportMenuWidget.styleEls.dropdown = document.createElement('style');
      const minWidth = mobileView ? '20px' : '200px';
      TransportMenuWidget.styleEls.dropdown.innerText = `
        .transport-menu-container {
          position: absolute;
          top: 15px;
          right: 15px;
          min-width: ${minWidth};
          z-index: 1000;
          opacity: 0.8;
          border-radius: 10px;
          background: rgba(0,0,0,0.4);
          cursor: pointer;
        }
        .transport-menu-title-container {
          height: 25px;
          display: grid;
          place-content: center;
          font: normal 13px sans-serif;
          color: #fff;
          border-spacing: 0;
          background: rgba(0,0,0,0.4);
        }
        .transport-menu-el {
          padding: 5px;
          font: normal 13px sans-serif;
          display: grid;
          place-content: center;
          height: 20px;
          color: #fff;
          background: rgba(0,0,0,0.6);
        }
        .transport-menu-el.last {
          border-radius: 0px 0px 10px 10px;
        }
            `;
      document.body.append(TransportMenuWidget.styleEls.dropdown);
    }
  }

  private setupHamburgerMenu() {
    const el = document.createElement('div');
    el.className = 'transport-hamburger-menu-container';

    this.scene?.renderer?.domElement?.parentElement?.append(el);

    // this.elements = { el }; // optionEls, titleContainerEl, titleEl };
    const titleContainerEl = document.createElement('div');
    const titleEl = document.createElement('h4');
    const openMenuImg = document.createElement('span');
    const sidebar = document.createElement('div');
    const closeMenuImg = document.createElement('span');
    const closeMenuContainer = document.createElement('div');
    const menuBackground = document.createElement('div');
    const toggleVisibility = () => {
      this.minimized = !this.minimized;
      sidebar.style[this.menuAlignment === 'right' ? 'marginRight' : 'marginLeft'] = this.minimized
        ? 'calc(-200pt - 2rem)'
        : '0';
      menuBackground.style.visibility = this.minimized ? 'hidden' : 'visible';
    };
    menuBackground.className = 'transport-hamburger-menu-background';
    menuBackground.style.visibility = this.minimized ? 'hidden' : 'visible';
    titleContainerEl.className = 'transport-hamburger-menu-title-container';
    titleEl.className = 'transport-hamburger-menu-title';
    if (this.showTitleBar) {
      titleEl.innerText =
        (this.titles[this.scene.selectedLanguage] ?? this.titles[this.scene.defaultLanguage]) || '';
    }
    openMenuImg.innerHTML = `
    <svg version="1.1" id="svg-e2e5" viewBox="0 0 16 16" x="0px" y="0px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <g style="fill:${this.iconColor}">
        <rect y="1" width="16" height="2"></rect>
        <rect y="7" width="16" height="2"></rect>
        <rect y="13" width="16" height="2"></rect>
      </g>
    </svg>`;
    openMenuImg.className = 'transport-hamburger-menu-icon';
    openMenuImg.onclick = toggleVisibility;
    closeMenuImg.innerHTML = `
    <svg version="1.1" id="svg-e2e5" viewBox="0 0 16 16" x="0px" y="0px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <g style="stroke:${this.closeIconColor}">
        <line x1="0" y1="0" x2="16" y2="16" style="stroke-width:2"/>
        <line x1="0" y1="16" x2="16" y2="0" style="stroke-width:2"/>
      </g>
    </svg>`;
    closeMenuImg.className = 'transport-hamburger-menu-icon';
    closeMenuImg.onclick = toggleVisibility;
    sidebar.className = 'transport-hamburger-menu-sidebar';
    sidebar.style[this.menuAlignment === 'right' ? 'marginRight' : 'marginLeft'] = this.minimized
      ? 'calc(-200pt - 2rem)'
      : '0';
    closeMenuContainer.className = 'transport-hamburger-menu-close-sidebar';
    titleContainerEl.append(titleEl);
    titleContainerEl.append(openMenuImg);
    closeMenuContainer.append(closeMenuImg);
    sidebar.append(closeMenuContainer);

    const title = document.createElement('div');
    title.setAttribute('class', 'transport-hamburger-menu-el title');
    title.innerText =
      (this.titles[this.scene.selectedLanguage] ?? this.titles[this.scene.defaultLanguage]) || '';
    sidebar.append(title);

    const optionEls: HTMLElement[] = [];
    const optList = this.locations[this.scene.defaultLanguage] ?? [];
    for (let i = 0; i < optList.length; i++) {
      let opt = optList[i];
      const button = document.createElement('div');
      button.className = 'transport-hamburger-menu-el';
      if (i === optList.length - 1) {
        button.className += ' last';
      }
      button.innerHTML = opt;
      const eventKey = TransportMenuWidget.EventName(i);
      const e = this.events.find((x) => x.name === eventKey);
      let clickHandler;
      if (e) {
        clickHandler = () => {
          this.triggerProVizEvent(eventKey, 'none');
          toggleVisibility();
        };
      }
      button.onclick = clickHandler;
      optionEls.push(button);
      sidebar.append(button);
    }
    el.append(titleContainerEl);
    el.append(sidebar);
    el.append(menuBackground);
    if (this.font === 'Lato' && !TransportMenuWidget.styleEls.fontLato) {
      TransportMenuWidget.styleEls.fontLato = document.createElement('link');
      TransportMenuWidget.styleEls.fontLato.setAttribute(
        'href',
        'https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@400;500;600;700&display=swap',
      );
      TransportMenuWidget.styleEls.fontLato.setAttribute('rel', 'stylesheet');
      document.body.append(TransportMenuWidget.styleEls.fontLato);
    }
    if (!TransportMenuWidget.styleEls.hamburger) {
      TransportMenuWidget.styleEls.hamburger = document.createElement('style');
      TransportMenuWidget.styleEls.hamburger.innerText = `
        .transport-hamburger-menu-container{
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 1;
          cursor: auto;
        }
        .transport-hamburger-menu-title-container {
          display: flex;
          position: relative;
          flex-direction: ${this.menuAlignment === 'right' ? 'row' : 'row-reverse'};
          align-items: center;
          font: normal 16pt sans-serif;
          height: 50pt;
          background: ${this.showTitleBar ? this.headerColor : 'transparent'};
          color: ${this.headerTextColor};
          padding: 0 1rem;
          ${this.showTitleBar ? '' : 'pointer-events: none;'}
        }
        .transport-hamburger-menu-title {
          flex-grow: 1;
          text-align: center;
        }
        .transport-hamburger-menu-sidebar {
          font: normal 16pt sans-serif;
          width: 200pt;
          height: 100%;
          position: fixed;
          z-index: 10;
          background: ${this.bgColor};
          color: ${this.textColor};
          top: 0;
          transition: margin 0.5s;
          right: ${this.menuAlignment === 'right' ? '0' : 'auto'};
          left: ${this.menuAlignment === 'left' ? '0' : 'auto'};
          padding: 0 1rem;
        }
        .transport-hamburger-menu-close-sidebar {
          display: flex;
          flex-direction: ${this.menuAlignment === 'left' ? 'row' : 'row-reverse'};
          align-items: center;
          height: 50pt;
        }
        .transport-hamburger-menu-background {
          height: 100%;
          width: 100%;
          position: fixed;
          top: 0;
          z-index: 1;
          background: ${this.coverBgColor};
          opacity: ${this.coverBgOpacity};
        }
        .transport-hamburger-menu-el {
          margin: 1.5rem 1rem;
          cursor: pointer;
          transition: text-decoration-color 300ms, text-underline-offset 300ms;
          text-decoration: underline auto ${this.bgColor};
          text-underline-offset: 0.1em;
        }
        .transport-hamburger-menu-el:hover {
          text-decoration-color: ${this.textColor};
          text-underline-offset: 0.3em;
        }
        .transport-hamburger-menu-el.title {
          text-decoration: none;
          cursor: default;
          font-size: 1.1em;
          color: ${this.titleTextColor};  
        }
        .transport-hamburger-menu-el.title:hover {
          text-decoration: none;
        }
        .transport-hamburger-menu-icon {
          width: 20pt;
          cursor: pointer;
          pointer-events: all;
        }
      `;
      document.body.append(TransportMenuWidget.styleEls.hamburger);
    }
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    switch (this.displayStyle) {
      case 'hamburger':
        this.setupHamburgerMenu();
        break;
      case 'dropdown':
      default:
        this.setupDropdownMenu();
        break;
    }

    this.setText();
    this.scene.addEventListener('language-change', () => {
      this.setText();
    });
    if (is_ios || is_android) {
      window.addEventListener('resize', () => {
        this.setText();
      });
    }
    return true;
  }

  private setText() {
    // There is only one mobile title and we use it if we are on a mobile device
    const mobileView = (is_android || is_ios) && window.innerWidth <= 600;
    if (!this.elements) {
      return;
    }
    const { titleEl, el } = this.elements;
    if (mobileView) {
      titleEl.innerText = this.mobileTitle;
    } else {
      const titleContent =
        this.titles[this.scene.selectedLanguage] ?? this.titles[this.scene.defaultLanguage] ?? '';

      titleEl.innerText = titleContent;
    }

    const options =
      this.locations[this.scene.selectedLanguage] ??
      this.locations[this.scene.defaultLanguage] ??
      '';
    for (let i = 0; i < options.length; i++) {
      el.children[i + 1].innerHTML = options[i];
    }
  }
  private static EventName = (idx: number) => `clicked-${idx}`;

  public toggleOptionVisibility() {
    this.minimized = !this.minimized;
    if (!this.elements) {
      return;
    }
    const { el, optionEls, titleContainerEl } = this.elements;
    optionEls.forEach((optionEl) => {
      optionEl.style.visibility = this.minimized ? 'hidden' : 'visible';
    });
    el.style.height = this.minimized ? '25px' : '';
    titleContainerEl.style.borderRadius = this.minimized ? '10px' : '10px 10px 0px 0px';
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    const extra: BaseWidgetProperty[] = [];

    console.log(this, this.displayStyle);
    if (this.displayStyle === 'hamburger') {
      extra.push(
        this.createProperty(
          'menuAlignment',
          'Menu Opening Side',
          'Visual',
          'select',
          'string',
          true,
          undefined,
          undefined,
          () =>
            ['right', 'left'].map((x) => {
              return { key: x, label: x };
            }),
        ),
        this.createProperty(
          'headerColor',
          'Menu Header Background Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'Color of the background header for the title',
        ),
        this.createProperty(
          'headerTextColor',
          'Menu Header Text Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'Color of the header text title',
        ),
        this.createProperty(
          'showTitleBar',
          'Menu Show Title Bar',
          'Visual',
          'bool',
          'boolean',
          true,
          undefined,
          undefined,
          undefined,
          'Whether to show the top menu bar or hide it',
        ),
        this.createProperty(
          'bgColor',
          'Menu Background Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'Background color of the side bar when the menu is open',
        ),
        this.createProperty(
          'textColor',
          'Menu Text Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'Color of the option items in the menu',
        ),
        this.createProperty(
          'titleTextColor',
          'Menu Title Text Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'The text color of the title in the sidebar when open',
        ),
        this.createProperty(
          'iconColor',
          'Menu Icon Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'Color of the hamburger icon before opening',
        ),
        this.createProperty(
          'closeIconColor',
          'Menu Close Icon Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'Color of the X icon in the menu when open',
        ),
        this.createProperty(
          'coverBgColor',
          'Cover Color',
          'Visual',
          'color',
          'string',
          true,
          undefined,
          undefined,
          undefined,
          'Color that will be used to cover the screen when the menu is open',
        ),
        this.createProperty(
          'coverBgOpacity',
          'Cover Opacity',
          'Visual',
          'constrained-number',
          'number',
          true,
          undefined,
          undefined,
          undefined,
          'Opacity of the cover color for the screen to see the contents under the menu when open',
        ),
        this.createProperty(
          'font',
          'Menu Font',
          'Visual',
          'select',
          'string',
          true,
          undefined,
          undefined,
          () =>
            ['Default', 'Lato'].map((x) => {
              return { key: x, label: x };
            }),
        ),
      );
    }

    return [
      ...result,
      this.createProperty(
        'displayStyle',
        'Display Type',
        'Core',
        'select',
        'string',
        true,
        undefined,
        undefined,
        () =>
          ['dropdown', 'hamburger'].map((x) => {
            return { key: x, label: x };
          }),
      ),
      this.createProperty('titles', 'Title', 'Data', 'multi-lang-opts', 'string', true),
      this.createProperty('mobileTitle', 'Mobile Title', 'Data', 'multi-lang-opts', 'string', true),
      this.createProperty(
        'locations',
        'Options',
        'Core',
        'multi-lang-list',
        'string',
        true,
        () => this.locations,
        (value: IMultiLanguageList) => {
          let maxLen = 0;
          for (let lang of Object.keys(value)) {
            if (value[lang]?.length > maxLen) {
              maxLen = value[lang].length;
            }
          }
          for (let lang of Object.keys(value)) {
            if (value[lang] && value[lang].length < maxLen) {
              for (let i = value[lang].length; i < maxLen; i++) {
                value[lang].push('');
              }
            }
          }
          this.locations = value;
          this.events = [];

          this.createEvents();
        },
      ),
      this.createProperty('newWindow', 'Open in new window', 'Core', 'bool', 'boolean', true),
      ...extra,
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.titles = this.titles;
    result.mobileTitle = this.mobileTitle;
    result.newWindow = this.newWindow;
    result.locations = this.locations;
    result.displayStyle = this.displayStyle;
    result.bgColor = this.bgColor;
    result.textColor = this.textColor;
    result.titleTextColor = this.titleTextColor;
    result.font = this.font;
    result.iconColor = this.iconColor;
    result.closeIconColor = this.closeIconColor;
    result.menuAlignment = this.menuAlignment;
    result.headerColor = this.headerColor;
    result.headerTextColor = this.headerTextColor;
    result.coverBgColor = this.coverBgColor;
    result.coverBgOpacity = this.coverBgOpacity;
    result.showTitleBar = this.showTitleBar;
    return result;
  }

  private createEvents() {
    if (!this.locations) {
      return;
    }
    if (this.locations[this.scene.defaultLanguage] === undefined) {
      this.locations[this.scene.defaultLanguage] = [];
    }
    this.locations[this.scene.defaultLanguage].forEach((val: string, idx) => {
      this.events.push({
        label: `Click ${val}`,
        name: TransportMenuWidget.EventName(idx),
        desc: val,
      });
    });
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.newWindow = data.newWindow;
    this.titles = data.titles ?? this.titles;
    if (data.mobileTitle) {
      this.mobileTitle = data.mobileTitle;
    }
    this.newWindow = data.newWindow;
    this.locations = data.locations ?? {};
    this.displayStyle = data.displayStyle ?? this.displayStyle;
    this.bgColor = data.bgColor ?? this.bgColor;
    this.textColor = data.textColor ?? this.textColor;
    this.titleTextColor = data.titleTextColor ?? this.titleTextColor;
    this.font = data.font ?? this.font;
    this.iconColor = data.iconColor ?? this.iconColor;
    this.headerColor = data.headerColor ?? this.headerColor;
    this.closeIconColor = data.closeIconColor ?? this.closeIconColor;
    this.menuAlignment = data.menuAlignment ?? this.menuAlignment;
    this.headerTextColor = data.headerTextColor ?? this.headerTextColor;
    this.coverBgColor = data.coverBgColor ?? this.coverBgColor;
    this.coverBgOpacity = data.coverBgOpacity ?? this.coverBgOpacity;
    this.showTitleBar = data.showTitleBar ?? this.showTitleBar;
    this.createEvents();
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }
    switch (data.version) {
      case 1:
        // Version 1 of this widget stored its data
        //  <lang>:<el>,<el>,<el>;<lang>:<el>,<el>,<el>;<lang>:<el>,<el>,<el>
        // now we use an object that uses languages as keys mapped to arrays of strings
        const locLists = data.locations.split(';');
        const locDict: { [language: string]: string[] } = {};
        locLists.forEach((l: string) => {
          const parts = l.split(':');
          locDict[parts[0]] = (parts[1] ?? '').split(',');
        });
        data.locations = locDict;

        if (!data.titles) {
          data.titles = { en: data.title || '' };
        } else {
          const titles = {};
          data.titles.split(',').forEach((t: string) => {
            const parts = t.split(':');
            if (parts[1]) {
              try {
                titles[parts[0]] = decodeURIComponent(parts[1]);
              } catch (e) {
                console.error("can't decode", parts[1]);
              }
            }
          });
          data.titles = titles;
        }
        data.connections.forEach((con: BaseWidgetDataConnection, idx: number) => {
          // data.locations.
          // find index of con
          // rename event to use index instead of string
          if (con.event.includes('value-change')) {
            const handleName = con.event.substring(13);
            for (let lang of Object.keys(data.locations)) {
              // Old format
              //   label: `$Connection {val}`,
              //   name: `value-change-${val}`,
              //   desc: val,
              const idx = data.locations[lang].findIndex((x: string) => x === handleName);
              if (idx !== -1) {
                con.event = TransportMenuWidget.EventName(idx);
                break;
              }
            }
          }
        });
    }
    return data;
  }
}

ModuleService.Register(TransportMenuWidget.type, TransportMenuWidget);
