import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class IfWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'if';
  private static instanceCount = 0;

  // Data
  private state1: boolean | string | number = true;
  private state2: boolean | string | number = true;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    if (!notInScene) {
      IfWidget.instanceCount++;
    }
    this.label = 'If ' + IfWidget.instanceCount;

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = IfWidget.type;
    this.widgetName = 'If';
    this.selectable = true;
    this.events = [
      {
        label: 'True',
        name: 'true',
      },
      {
        label: 'False',
        name: 'false',
      },
      {
        label: 'Value',
        name: 'value',
      },
    ];

    this.services = [
      {
        label: 'Set Value 1',
        name: 'set-value-1',
        desc: '<b>Sets Value 1 of the If widget</b>',
      },
      {
        label: 'Set Value 2',
        name: 'set-value-2',
        desc: '<b>Sets Value 1 of the If widget</b>',
      },
    ];

    this.addEventListener('service-set-value-1', (event: ProVizEventData) => {
      if (event.dataType === 'boolean') {
        this.state1 = event.data as boolean;
        this.handleLogicUpdate();
      } else if (event.dataType === 'string') {
        this.state1 = event.data as string;
        this.handleLogicUpdate();
      } else if (event.dataType === 'number') {
        this.state1 = event.data as number;
        this.handleLogicUpdate();
      }
    });
    this.addEventListener('service-set-value-2', (event: ProVizEventData) => {
      if (event.dataType === 'boolean') {
        this.state2 = event.data as boolean;
        this.handleLogicUpdate();
      } else if (event.dataType === 'string') {
        this.state2 = event.data as string;
        this.handleLogicUpdate();
      } else if (event.dataType === 'number') {
        this.state2 = event.data as number;
        this.handleLogicUpdate();
      }
    });
  }

  // public async init(): Promise<boolean> {
  //   const continueInitializing = await super.init();
  //   if (!continueInitializing) {
  //     return continueInitializing;
  //   }

  //   // this.handleLogicUpdate();

  //   return true;
  // }

  public getProperties(): BaseWidgetProperty[] {
    return [
      ...super.getProperties(),
      this.createProperty(
        'state1',
        'Value 1',
        'Core',
        'string',
        'string',
        true,
        () => this.state1.toString(),
        (data) => {
          const floatVal = parseFloat(data);
          if (!isNaN(floatVal)) {
            this.state1 = parseFloat(data);
          } else if (data.toString().toLowerCase() === 'true') {
            this.state1 = true;
          } else if (data.toString().toLowerCase() === 'false') {
            this.state1 = false;
          } else {
            this.state1 = data.toString();
          }
        },
      ),
      this.createProperty(
        'state2',
        'Value 2',
        'Core',
        'string',
        'string',
        true,
        () => this.state2.toString(),
        (data) => {
          const floatVal = parseFloat(data);
          if (!isNaN(floatVal)) {
            this.state2 = parseFloat(data);
          } else if (data.toString().toLowerCase() === 'true') {
            this.state2 = true;
          } else if (data.toString().toLowerCase() === 'false') {
            this.state2 = false;
          } else {
            this.state2 = data.toString();
          }
        },
      ),
    ];
  }

  private handleLogicUpdate() {
    console.log('[If] Widget', this.state1, this.state2);
    if (this.state1.toString() === this.state2.toString()) {
      this.triggerProVizEvent('true', 'none');
      this.triggerProVizEvent('value', 'boolean', true);
    } else {
      this.triggerProVizEvent('false', 'none');
      this.triggerProVizEvent('value', 'boolean', false);
    }
  }

  public serialize() {
    const result = super.serialize();
    result.state1 = this.state1;
    result.state2 = this.state2;
    return result;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.state1 = data.state1 ?? this.state1;
    this.state2 = data.state2 ?? this.state2;
  }
}

ModuleService.Register(IfWidget.type, IfWidget);
