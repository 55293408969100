import { setRenderOrder } from './';
import { FrontSide, Mesh, MeshStandardMaterial, PlaneGeometry, ShadowMaterial } from 'three';

export type ShadowPlane = Mesh<PlaneGeometry, ShadowMaterial>;
export type GroundMaterial = Mesh<PlaneGeometry, MeshStandardMaterial>;

export function createShadowPlane(): ShadowPlane {
  const geometry = new PlaneGeometry(500, 500, 500, 500);
  const standardMaterial = new ShadowMaterial({
    opacity: 0.7,
    side: FrontSide,
    visible: false,
  });
  const mesh = new Mesh(geometry, standardMaterial);
  mesh.rotation.set(-3.14 / 2, 0, 0);
  mesh.position.set(0, -0.01, 0);
  mesh.receiveShadow = true;
  mesh.castShadow = false;
  mesh.frustumCulled = false;
  mesh.name = 'plane';
  setRenderOrder(mesh, 0);
  return mesh;
}

export function createPlane(color: string): Mesh<PlaneGeometry, MeshStandardMaterial> {
  const geometry = new PlaneGeometry(500, 500, 500, 500);
  const standardMaterial = new MeshStandardMaterial({
    // opacity: 0.7,
    name: 'ground',
    side: FrontSide,
    visible: false,
    color,
  });
  const mesh = new Mesh(geometry, standardMaterial);
  mesh.rotation.set(-3.14 / 2, 0, 0);
  mesh.position.set(0, -0.01, 0);
  mesh.receiveShadow = true;
  mesh.castShadow = false;
  mesh.frustumCulled = false;
  mesh.name = 'plane';
  setRenderOrder(mesh, 0);
  return mesh;
}
