import { is_firefox } from '@proviz/proviz-sdk';
import React, { ReactElement, useEffect, useState } from 'react'
import { Vector3 } from 'three';
import Checkbox from './Checkbox';

interface Props {
    input1: { id: string, value: number, label: string };
    input2: { id: string, value: number, label: string };
    input3: { id: string, value: number, label: string };
    onChange: (e: Vector3) => void;
    checkmark?: { id: string, value: boolean, onChange: () => void; };
    draggable?: boolean; 
}

export default function ThreeValInput(props: Props): ReactElement {
    const { input1, input2, input3, onChange, checkmark, draggable } = props;

    // To fixed returns a string, so we need to parse that string
    // back into a float
    const convertToFixedFloat = (val: any) => {
        let res;
        //Make sure the passed value is a number, if it isn't just reset it.
        if (isNaN(parseFloat(val)))
            val = 0;
        if (typeof (val) === 'number') res = val.toFixed(3);
        else res = parseFloat(val).toFixed(3);

        return res === '0.000' || res === null ? '0' : res;
    }

    // Take the initial input values and do the fixed float conversion hack
    // This allows you to input things like 1.5 and output 1.500 or 1.543 and output 1.543 or 1 and get 1.000 all the while not resetting the cursor position
    const [inp1, setInp1] = useState<any>(convertToFixedFloat(input1.value));
    const [inp2, setInp2] = useState<any>(convertToFixedFloat(input2.value));
    const [inp3, setInp3] = useState<any>(convertToFixedFloat(input3.value));
    const [startX, setStartX] = useState<any>(0);
    const [currentX, setCurrentX] = useState<any>(0);
    const [draggedInput, setDraggedInput] = useState<any>(0);

    useEffect(() => {
        setInp1(convertToFixedFloat(input1.value));
        setInp2(convertToFixedFloat(input2.value));
        setInp3(convertToFixedFloat(input3.value));
    }, [input1, input2, input3])
    
    const handleKeySubmit = (event: any) => {
        if (event.key === 'Enter') onChange(new Vector3(inp1, inp2, inp3));
    }

    const handleDrag = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        let dragSum = ((is_firefox?currentX:event.clientX) - startX)/100;
        switch(draggedInput) {
            case 1:
                setInp1(convertToFixedFloat(Number(inp1) + dragSum));
                break;
            case 2:
                setInp2(convertToFixedFloat(Number(inp2) + dragSum));
                break;
            case 3:
                setInp3(convertToFixedFloat(Number(inp3) + dragSum));
                break;
            default:
        }
    }

    const handleDragStart = (event: React.DragEvent<HTMLElement>) => {
        setStartX(event.clientX);
        setCurrentX(event.clientX);
    }

    const handleDragEnd = (event: React.DragEvent<HTMLElement>) => {
        onChange(new Vector3(inp1, inp2, inp3));
    }

    const handleDragOver = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setCurrentX(event.clientX);
    }

    return <div className="threevalinputrow" onDragOver={handleDragOver}>
        <form className='threevalentry'>
            <div className="inputcell">
                <label htmlFor={input1.id} draggable={draggable} 
                onDragStart={handleDragStart}
                onDrag={(e) => {
                    setDraggedInput(1);
                    handleDrag(e);
                }}
                onDragEnd={handleDragEnd}
                >{input1.label}</label>
                {(!isNaN(input1.value)) &&
                    <input type="number"
                        id={input1.id}
                        value={inp1}
                        onChange={e => setInp1(e.target.value)}
                        onBlur={() => onChange(new Vector3(inp1, inp2, inp3))}
                        onKeyPressCapture={e => handleKeySubmit(e)}
                        onFocus={e => e.target.select()}
                    />}
            </div>
            <div className="inputcell borderLeft borderRight">
                <label htmlFor={input2.id} draggable={draggable}
                onDragStart={handleDragStart}
                onDrag={(e) => {
                    setDraggedInput(2);
                    handleDrag(e);
                }}
                onDragEnd={handleDragEnd}
                >{input2.label}</label>
                {(!isNaN(input2.value)) &&
                    <input type="number"
                        id={input2.id}
                        value={inp2}
                        onChange={e => setInp2(e.target.value)}
                        onBlur={() => onChange(new Vector3(inp1, inp2, inp3))}
                        onKeyPressCapture={e => handleKeySubmit(e)}
                        onFocus={e => e.target.select()}
                    />}
            </div>
            <div className="inputcell">
                <label htmlFor={input3.id} draggable={draggable}
                onDragStart={handleDragStart}
                onDrag={(e) => {
                    setDraggedInput(3);
                    handleDrag(e);
                }}
                onDragEnd={handleDragEnd}
                >{input3.label}</label>
                {(!isNaN(input3.value)) &&
                    <input type="number"
                        id={input3.id}
                        value={inp3}
                        onChange={e => setInp3(e.target.value)}
                        onBlur={() => onChange(new Vector3(inp1, inp2, inp3))}
                        onKeyPressCapture={e => handleKeySubmit(e)}
                        onFocus={e => e.target.select()}
                    />}
            </div>
        </form>
        {checkmark && <Checkbox id={checkmark.id} value={checkmark.value} onChange={checkmark.onChange} />}
    </div>;
}
