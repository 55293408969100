import { ReactElement } from 'react'
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const provizGif = getAbsoluteUrl("gifs/ProViz_Logo-blue.gif")

export default function Loading(): ReactElement {
    return (
        <div style={{width: '300px', margin: '0 auto'}}>
            <img style={{width: '300px'}} src={provizGif} alt='Loading' />
            <br />
            <h1 style={{width: '300px', textAlign: 'center'}}>Loading...</h1>
        </div>
    )
}
