import { ReactElement, ReactNode } from 'react'

interface Props {
    children?: ReactNode[];
}

export default function TabBar(props: Props): ReactElement {
    const { children } = props;
    return (
        <div className={`tabBarContainer tabs${children?.length}`}>
            {children}
        </div>
    )
}
