import { ReactElement, useState } from 'react'
import { APIModelVariant, } from "@proviz/api-services"
import { getAbsoluteUrl } from "@proviz/proviz-sdk"
import { VariantFileEntry } from './VariantFileEntry';
import CollapsibleHeaderIcon from '../../common/collapsiblegroup/CollapsibleHeader';
import LogList from './LogList';

const okIcon = getAbsoluteUrl('images/Greencheckmark.svg');
const processingIcon = getAbsoluteUrl('images/Processing.svg');
const errorIcon = getAbsoluteUrl('images/Error.svg')

const modelStatusToIcon = (variant: APIModelVariant) => {
    if (!variant.modelVariantFile) {
        return '';
    }
    let status = variant.modelVariantFile.status;
    if (status === 'Failed') {
        return errorIcon
    }
    if (status === 'Processing') {
        return processingIcon
    }
    return okIcon
}

interface Props {
    variant: APIModelVariant;
    modelId: string;
}

export default function VariantEntry(props: Props): ReactElement {
    const { modelId, variant } = props;
    const [show, setShow] = useState(false);

    const statusIcon = modelStatusToIcon(variant);

    const getEmbedLink = (variantId: string) => {
        switch (window.location.hostname) {
            case "dev.studio.provizxr.com":
                return `https://dev.embed.provizxr.com/model/${modelId}?variant=${variantId}`;
            case "qa.studio.provizxr.com":
                return `https://qa.embed.provizxr.com/model/${modelId}?variant=${variantId}`;
            case "localhost":
                return `https://localhost:3002/model/${modelId}?variant=${variantId}`;
            default:
                return `https://embed.provizxr.com/model/${modelId}?variant=${variantId}`;
        }
    }

    return <>
            <div key={variant.id} className="variantEntry">
                <div></div>
                <img src={statusIcon} alt='' />
                <a href={getEmbedLink(variant.id)} target="_blank" rel="noreferrer">{variant.materialSet.name}</a>
                <CollapsibleHeaderIcon expanded={show} handleClick={() => setShow(!show)} />
            </div>
            {show && variant.modelVariantFile &&
                <div className="files">
                    <div className="fullWidthSpaced">
                        <div>Status: {variant.modelVariantFile.status} </div>
                        <div>
                            {/* <a href={variant.modelVariantFile.glbFile.location} rel="">Download all Files</a> */}
                        </div>
                    </div>
                    {variant.modelVariantFile.glbFileId &&
                        <VariantFileEntry file={variant.modelVariantFile.glbFile}
                            modelId={modelId} ext={'GLB'} />
                    }
                    {variant.modelVariantFile.usdzFileId &&
                        <VariantFileEntry file={variant.modelVariantFile.usdzFile}
                            modelId={modelId} ext={'USDZ'} />}
                    {variant.materialSet.file &&
                        <div className="fileEntry">
                            <p>MTL</p>
                            <a href={variant.materialSet.file.location}>Download</a>
                        </div>}
                    <LogList entity='ModelVariant' id={variant.id} />
                </div>
            }
        </>;
}
