import { ReactElement } from 'react'
import Header from './common/header/Header';
import AuthButton from './common/auth/AuthButton';


export default function SafariErrorPage(): ReactElement {
    return (
        <div className='home'>
            <Header>
                <AuthButton />
            </Header>

            <h3>It looks like you're using Safari.</h3>
            <h4>Apple has made one of the technologies that this page uses opt in only..</h4>
            <div>
                You can view this page more easily in Firefox, Chrome or Edge. However, if you want to proceed
                with Safari follow these steps:
            </div>
            <ol>
                <li>Open the <b>"Safari"</b> menu and select <b>"Preferences."</b></li>
                <li>Click the <b>"Advanced"</b> tab in the <b>"Preferences"</b> window.</li>
                <li>At the bottom of the window, check the <b>"Show Develop" menu in menu bar </b> checkbox.</li>
                <li>Open the <b>"Develop"</b> menu in the menu bar and under the <b>Experimental Features</b> submenu select "Enable WebGL."</li>
            </ol>
        </div>
    )
}
