import { useState } from 'react'
import Dropzone from '../../common/dropzone/Dropzone';


type UploadProp = {
    title: string;
    onUpload: (files: File[]) => void;
    uploadText: string;
    validator?: (file: File) => {
        code: string;
        message: string;
    } | null;
    accept: string;
    reset: () => void;
    postUploadText: string;
}

interface Props {
    tabs: UploadProp[];
}

export const TabbedUpload = (props: Props) => {
    const { tabs } = props;
    const [tab, setTab] = useState(0);

    return <div className={`tabbedUploadArea`}>
        <div className='tabBar'>
            {tabs.map((t: UploadProp, idx: number) => {
                return <div className={`tabTitle ${idx === tab ? 'selected' : ''}`}
                    onClick={() => setTab(idx)}
                    key={t.title + idx}
                >
                    {t.title}
                </div>
            })}
        </div>
        {tabs.map((t: UploadProp, idx: number) => {
            return <div className={`uploadTab ${idx === tab ? 'selected' : 'hidden'}`}
                key={t.title + idx}>
                <Dropzone onUpload={t.onUpload}
                    accept={t.accept}
                    validator={t.validator}
                    text={t.uploadText}
                    reset={t.reset}
                    postUploadText={t.postUploadText} />
            </div>
        })}
    </div>;
}
