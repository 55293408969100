import { ReactElement } from 'react'
import { SkyboxTexture } from "@proviz/proviz-sdk"
import SinglePanoramaUpload from './SinglePanoramaUpload';

interface Props {
    textureSources: SkyboxTexture;
    setTextures: (val: SkyboxTexture) => void;
}

export default function MultiPanoramaUpload(props: Props): ReactElement {
    const { textureSources } = props;

    function setTexture(id: string, idx: number) {
        const newTexSources = textureSources;
        switch(idx) {
            case 0:
                newTexSources.id0 = id;
                break;
            case 1:
                newTexSources.id1 = id;
                break;
            case 2:
                newTexSources.id2 = id;
                break;
            case 3:
                newTexSources.id3 = id;
                break;
            case 4:
                newTexSources.id4 = id;
                break;
            case 5:
                newTexSources.id5 = id;
                break;
        }
        props.setTextures(newTexSources);
    }


    return (
        <div className="multiPanoramaUpload" >
            <SinglePanoramaUpload textureId={textureSources.id0 || ''} setTextureId={(id: string) => setTexture(id, 0)} />
            <SinglePanoramaUpload textureId={textureSources.id1 || ''} setTextureId={(id: string) => setTexture(id, 1)} />
            <SinglePanoramaUpload textureId={textureSources.id2 || ''} setTextureId={(id: string) => setTexture(id, 2)} />
            <SinglePanoramaUpload textureId={textureSources.id3 || ''} setTextureId={(id: string) => setTexture(id, 3)} />
            <SinglePanoramaUpload textureId={textureSources.id4 || ''} setTextureId={(id: string) => setTexture(id, 4)} />
            <SinglePanoramaUpload textureId={textureSources.id5 || ''} setTextureId={(id: string) => setTexture(id, 5)} />
        </div>
    )
}
