import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class GroupWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'group';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = GroupWidget.type;
    this.widgetName = 'Group';
    this.label = 'Group';
    this.selectable = true;
    this.clickable = false;
    this.events = [];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    this.renderNode.renderOrder = 3;

    return true;
  }
}

ModuleService.Register(GroupWidget.type, GroupWidget);
