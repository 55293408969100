import { ModelStats } from "@proviz/api-services";
import { numberWithCommas, fileSizeFromBytes } from '../../utils/StyleNumbers';


interface Props {
    stats: ModelStats;
}

export const StatsDisplay = (props: Props) => {
    const { stats } = props;
    return (<div>
        <p>Vertices:  {numberWithCommas(stats.vertices)}</p>
        <p>Polygons: {numberWithCommas(stats.polygons)}</p>
        <p>File Size: {fileSizeFromBytes(stats.fileSize)}</p>
    </div>
    )
}
