import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { is_android, is_ios } from '../../../utils';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class MobileSwitchWidget extends BaseWidget implements IBaseWidgetType {
  static type: string = 'mobile-switch';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = MobileSwitchWidget.type;
    this.widgetName = 'Mobile Switch';
    this.label = 'Mobile Switch';
    this.usage = 'Flow';
    this.category = 'Events';
    this.services = [
      {
        label: 'Selected',
        name: 'selected',
        desc: '<b>Trigger Event</b><br/><i>Mobile if on android or iOs and destkop otherwise.</i>',
      },
    ];
    this.events.push(
      {
        label: 'Mobile Event',
        name: 'mobile-event',
        desc: 'Fires if this widget is loaded on a mobile device.',
      },
      {
        label: 'Desktop Event',
        name: 'desktop-event',
        desc: 'Fires if this widget is loaded on a mobile device.',
      },
    );

    this.addEventListener('service-selected', (event) => {
      if (is_ios || is_android) {
        this.triggerProVizEvent('mobile-event', 'none');
      } else {
        this.triggerProVizEvent('desktop-event', 'none');
      }
    });
  }
}

ModuleService.Register(MobileSwitchWidget.type, MobileSwitchWidget);
