import { FileService, ResourceLink } from '@proviz/api-services';
import { BaseWidgetProperty, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class PlaneTargetWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'plane-target';

  // Data
  private loadOnInit: boolean = true;
  private imageIdOverride: string | undefined = undefined;
  private targetMoveable: boolean = true;
  private lookAtCameraAfterMoved: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = PlaneTargetWidget.type;
    this.widgetName = 'Plane Target';
    this.label = 'Plane Target';
    this.category = 'Targets';
    this.selectable = true;
    this.events = [
      {
        label: 'Tracking Found',
        name: 'tracking-found',
      },
      {
        label: 'Tracking Lost',
        name: 'tracking-lost',
      },
    ];
    this.addService(
      'Start Tracking',
      'start-tracking',
      'Start the tracking service for this plane target',
      () => {
        console.warn('Plane Targets not supported on web yet.');
        super.init().then(() => {
          // in the browser we default to tracked state
          this.triggerProVizEvent('tracking-found', 'none');
        });
      },
    );
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('loadOnInit', 'Load On Init', 'Function', 'bool', 'boolean', true),

      this.createProperty('imageIdOverride', 'Image Id', 'Core', 'image', 'string', true),
      this.createProperty(
        'targetMoveable',
        'Can Move',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'Whether you can move the target after it has been placed',
      ),
      this.createProperty(
        'lookAtCameraAfterMoved',
        'Look At Camera On Move',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'Whether the scene content will look at the camera as you move the target',
      ),
    ];
  }

  public async init() {
    console.log(`%c '${this.label}' initializing start: ${this.loadOnInit}'`, 'background: #000; color: #fff');
    if (this.scene.sceneMode !== SceneMode.Editor && !this.loadOnInit) {
      return false;
    }
    console.log(`%c '${this.label}' initializing children'`, 'background: #000; color: #fff');

    return await super.init();
  }

  public serialize(): any {
    const result = super.serialize();
    result.loadOnInit = this.loadOnInit;
    result.imageIdOverride = this.imageIdOverride;
    result.targetMoveable = this.targetMoveable;
    result.lookAtCameraAfterMoved = this.lookAtCameraAfterMoved;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.loadOnInit = data.loadOnInit ?? true;
    this.imageIdOverride = data.imageIdOverride ?? this.imageIdOverride;
    this.targetMoveable = data.targetMoveable ?? this.targetMoveable;
    this.lookAtCameraAfterMoved = data.lookAtCameraAfterMoved ?? this.lookAtCameraAfterMoved;
  }

  public async getResourceLinks(): Promise<ResourceLink[]> {
    const result = await super.getResourceLinks();
    
    if (this.imageIdOverride) {
      const imgFIle = await FileService.get(this.imageIdOverride);
      result.push({
        filename: `_v1_File_${this.imageIdOverride}`,
        data: JSON.stringify(imgFIle),
        location: undefined
      });
    }
    return result;
  }

}

ModuleService.Register(PlaneTargetWidget.type, PlaneTargetWidget);
