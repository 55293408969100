import { ReactElement } from 'react';
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import ModelViewManager from '../../../graphics/ModelViewManager';
import SceneManager from '../../../graphics/SceneManager';

const gridIcon = getAbsoluteUrl('images/GridIcon.svg');

interface Props {
    manager: ModelViewManager | SceneManager;
}

export default function GridButton(props: Props): ReactElement {
    return <div className='iconContainer'>
        <img className='icon' 
            src={gridIcon} 
            onClick={() => props.manager.toggleGrid()} 
            alt='Toggle Grid'/>
    </div>;
}
