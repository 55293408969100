import { MaterialParameterType } from "../types";
import { IHasId } from "./interfaces/IHasId";
import { APITexture } from "./Texture";

export interface APIMaterialParameter extends IHasId {
  id: string;
  materialId: string;
  materialParameterType: MaterialParameterType;
  floatValue: number | null;
  stringValue: string | null;
  textureId: string | null;
  texture: APITexture | null;
  isDeleted?: boolean;
  deleted?: string | null;
  created?: string;
  updated?: string;
}

export function getParam(
  params: APIMaterialParameter[] | null,
  mpType: MaterialParameterType
): APIMaterialParameter | null {
  if (!params) {
    return null;
  }
  const param = params.find((p) => p.materialParameterType === mpType);
  return param ?? null;
}
