import {
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  WireframeGeometry,
} from 'three';
import { ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import BasePositionableWidget from '../../basePositionableWidget';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class SphereColliderWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'sphere-collider';

  // Data

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = SphereColliderWidget.type;
    this.widgetName = 'Sphere Collider';
    this.label = 'Sphere Collider';
    this.selectable = true;
    this.category = 'Colliders';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'On Enter',
        name: 'on-enter',
      },
      {
        label: 'On Leave',
        name: 'on-leave',
      },
    ];
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.physicsBounds) {
      result.push(this.physicsBounds);
    }
    return result;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    const geometry = new SphereGeometry(1, 8, 6);
    if (this.scene.sceneMode === SceneMode.Editor) {
      const wireframeGeo = new WireframeGeometry(geometry);
      const line = new LineSegments(
        wireframeGeo,
        new LineBasicMaterial({
          depthTest: false,
          color: '#47538C',
        }),
      );
      this.renderNode.add(line);
    }
    const params: any = { color: 0xffffff, visible: false };
    this.physicsBounds = new Mesh(geometry, new MeshBasicMaterial(params));
    this.physicsBounds.userData.widget = this;
    this.renderNode.add(this.physicsBounds);

    return true;
  }

  dispose() {
    super.dispose();
    if (this.physicsBounds) {
      this.physicsBounds.material.dispose();
      this.physicsBounds.geometry.dispose();
      if (this.physicsBounds) {
        this.physicsBounds.userData.widget = undefined;
      }
      this.renderNode.remove(this.physicsBounds);
    }
  }
}

ModuleService.Register(SphereColliderWidget.type, SphereColliderWidget);
