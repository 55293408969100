import { Fragment, useState } from 'react'
import { APIFile, ModelService, ModelStats } from "@proviz/api-services";
import { StatsDisplay } from '../../common/StatsDisplay';
import Button from '../../common/button/Button';
import { userStore } from '../../../store';

interface Props {
    file: APIFile;
    modelId: string;
    ext: string;
}

export const VariantFileEntry = (props: Props) => {
    const { ext, file, modelId } = props;
    const user = userStore( s => s.user)
    const [stats, setStats] = useState<ModelStats | null>(null);
    const [statsError, setStatsError] = useState(false);

    const getStats = () => {
        ModelService.getStats(modelId, file.id)
            .then(setStats)
            .catch(() => setStatsError(true) );
    }

    return (<Fragment>
        <div className="fileEntry">
            <p>{ext.toUpperCase()}</p>
            <a href={file.location}>Download</a>
        </div>
        {user?.isSuperAdmin &&
            <div>
                {!stats && !statsError  &&
                    <Button type='primary' onClick={getStats}>Get Stats</Button>}
                {statsError && <p>We had an error calculating file stats.</p>}
                {stats && <StatsDisplay stats={stats} /> }
            </div>
        }
    </Fragment>
    );
}
