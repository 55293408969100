import { IHasId } from "./interfaces/IHasId";

export enum PermissionEntity {
  Model = 0,
  Texture,
  Image,
  Sprite,
  Scene,
  User,
  Company,
  File,
  Target,
  MachineKey,
  Role,

  Length
}

export enum PermissionType {
  View = 1,
  Edit = 2,
  Delete = 4
}

export function hasAccess(
  permissions: APIPermission[],
  entity: PermissionEntity,
  accessLevel: PermissionType
) {
  console.log(permissions, entity, accessLevel);
  const el = permissions.find(
    (p) =>
      p.permissionEntity === entity &&
      (p.permissionTypes ?? []).includes(accessLevel)
  );
  return el;
}

export interface APIPermission extends IHasId {
  id: string;
  name: string;
  permissionTypes: PermissionType[];
  permissionEntity: PermissionEntity;
  created: string;
  updated: string;
}
