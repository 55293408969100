import { IServiceOptions, MachineKey } from "..";
import { BaseDataService } from "../BaseDataService";

export class APIMachineKeyService extends BaseDataService<MachineKey> {
  constructor() {
    super("MachineKey");
  }

  async createKey(
    name: string,
    role: "Admin" | "User",
    options?: IServiceOptions
  ): Promise<any> {
    return await this.request("", {
      method: "POST",
      body: { name, role },
      ...options,
    });
  }
}

const AccessKeyService = new APIMachineKeyService();

export default AccessKeyService;
