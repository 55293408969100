import { DatabaseService } from '@proviz/api-services';
import { APIDatabase } from '@proviz/api-services/lib/models/Database';
import { useEffect, useState } from 'react';
import Button from '../../common/button/Button';
import PageContentHeader from '../pageContentHeader';

export function ClientDatabasesView() {
    const [ databases, setDatabases ] = useState<APIDatabase[]>([]);

    useEffect(() => {
        DatabaseService.getAll().then(setDatabases);
    }, [])
    return <>
    <PageContentHeader title="Databases">
        <Button>Create</Button>
    </PageContentHeader>
    <table>
        {databases.map(d => <tr>
            <td>
                {d.name}
            </td>
            <td>
                <Button type='danger'>Delete</Button>
            </td>
        </tr>)}
    </table>
    </>;
}
