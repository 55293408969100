/**
 * Contains generic code that is used across views
 */
export class Helpers {
  /**
   * Returns a random uuidv4 each call
   * @returns random uuidv4
   */
  /* eslint no-bitwise: ["error", { "allow": ["&", "|"] }] */
  /* tslint:disable:no-bitwise */
  public static uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  /* tslint:enable:no-bitwise */
}
