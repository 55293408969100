import { Color, Mesh, MeshBasicMaterial, SphereGeometry } from 'three';
import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ARCSSyncPointWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'arcs-sync-point';

  // Data
  private imageFileId: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ARCSSyncPointWidget.type;
    this.widgetName = 'ARCS Sync Point';
    this.label = 'ARCS Sync Point';
    this.category = 'ARCS';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('imageFileId', 'Thumbnail', 'Data', 'string', 'string')];
  }

  public serialize(): any {
    const result = super.serialize();
    result.imageFileId = this.imageFileId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.imageFileId = data.imageFileId;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    // create display only 3d sphere
    const geometry = new SphereGeometry(1);
    const mesh = new Mesh(
      geometry,
      new MeshBasicMaterial({
        color: new Color(0xffffff),
      }),
    );
    this.renderNode.add(mesh);

    return true;
  }
}

ModuleService.Register(ARCSSyncPointWidget.type, ARCSSyncPointWidget);
