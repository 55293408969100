import { BillboardWidget } from './billboardWidget';

export class BillboardWidgetStudio extends BillboardWidget {
  public async init(): Promise<boolean> {
    super.init();

    // add wireframe

    return true;
  }
}
