import { BaseWidget } from './widgets/baseWidget';

export class ModuleService {
  public static widgets: { [key: string]: typeof BaseWidget } = {};

  public static Register(widgetType: string, value: typeof BaseWidget) {
    this.widgets[widgetType] = value;
  }

  public static Get(widgetType: string): typeof BaseWidget | undefined {
    return this.widgets[widgetType];
  }
}
