
import { ReactElement, useEffect, useState } from 'react'
import { APITag, TagService, ProVizConfig, initAbortController, isAbortError, } from "@proviz/api-services";
import FormContainer from '../common/formcontainer';
import Button from '../common/button/Button';
import PageHeader from '../home/pageHeader';

export default function TagsView(): ReactElement {
    const [failed, setFailed] = useState<boolean>(false);
    const [updateTags, setUpdateTags] = useState(true);
    const [name, setName] = useState<string>('');
    const [tags, setTags] = useState<APITag[]>([]);

    useEffect(() => {
        const abortController = initAbortController();
        TagService.getAll().then(setTags);
      return () => abortController.abort();
    }, []);
    
    useEffect(() => {
        const getUpdatedModelsCallback = () => setUpdateTags(true);
        ProVizConfig.events.addListener('impersonate-change', getUpdatedModelsCallback);
        return () => {
            ProVizConfig.events.removeListener('impersonate-change', getUpdatedModelsCallback);
        }
    }, []);

    useEffect(() => {
        if (!updateTags) {
            return;
        }
        setUpdateTags(false);
        setFailed(false);
        const abortController = initAbortController();
        TagService.getAll({ abortController })
            .then(setTags)
            .catch((e) => {
                if (isAbortError(e)) return; // if the query was aborted, do nothing
                console.error(e);
                setFailed(true);
            });
        return () => abortController.abort();
    }, [updateTags]);

    const createTag = () => {
        TagService.create(name)
            .then(() => setUpdateTags(true));
    };



    if (failed) {
        return <h1>We're afraid something went wrong. Try again later.</h1>
    }
    return <div className="homeRightView">
        
    <PageHeader />

    <div className='homeContent'>
        <div className='homeContentContainer'>
            <div className='paddedContainer'>
        <div className="userList">
            <div>

                <FormContainer title="Create Tag">
                    <table className="inner">
                      <tbody>
                            <tr>
                                <td>Key Name</td>
                                <td>
                                    <input className="provizInputBox" type="text" onChange={(e) => setName(e.target.value)} placeholder="Tag Name" />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <Button onClick={() => createTag()}>Create New Tag</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </FormContainer>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                    </tr>
                </thead>
                <tbody>
                    {tags.map((tag) => <tr key={tag.id}>
                        <td># {tag.name}</td>
                        {/* <td>
                            <Button fullWidth={true} onClick={() => deleteKey(key.key)}>
                                Delete
                            </Button>
                        </td> */}
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
        </div>
        </div>
    </div>;
}
