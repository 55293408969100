import { ReactNode } from 'react';

type ButtonTypes = 'danger' | 'default' | 'primary' | 'icon' | 'disabled' | 'resource' | 'submit'

type Props = {
    classNames?: string;
    onClick?: (e: any) => void;
    onMouseEnter?: (e: any) => void;
    onMouseLeave?: (e: any) => void;
    children: ReactNode;
    fullWidth?: boolean;
    type?: ButtonTypes;
    link?: string;
    title?: string;
    disabled?: boolean;
};

export default function Button(props: Props) {
    const { onClick, onMouseEnter, onMouseLeave, children, classNames, link, fullWidth, title } = props;

    const cls = `btn btn-${props.type || 'default'} ${props.disabled ? 'btn-disabled' : ''} ${classNames || ''} ${(fullWidth && 'full-width') || ''}`;

    if (link) {
      return <a className={cls}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        title={title}
        href={link}>
          {children}
      </a>;
    } else {
      return <button className={cls} 
        disabled={props.type === 'disabled' || props.disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        title={title}>
          {children}
      </button>;
    }
}
