import { ReactElement } from 'react'
import { APIModel, ModelService } from "@proviz/api-services";
import EditableTitle from '../common/editabletitle/EditableTitle';

interface Props {
    model: APIModel;
}

export default function ModelName(props: Props): ReactElement {
    const { model } = props;

    return <EditableTitle name={model.name} updateName={(name: string) => {
        model.name = name;
        let scale = {x: model.scaleX, y: model.scaleY, z: model.scaleZ}
        let rotation = {x: model.rotX, y: model.rotY, z: model.rotZ}
        ModelService.updateModel(model.id, name, scale, rotation);
    }} />
}
