import { FileService, ProVizConfig, TextureService } from '@proviz/api-services';
import { PanoramaTexture } from './panoramaTexture';

export class SkyboxTexture extends PanoramaTexture {
  isSkybox = true;
  id0: string = '';
  id1: string = '';
  id2: string = '';
  id3: string = '';
  id4: string = '';
  id5: string = '';
  location0?: string;
  location1?: string;
  location2?: string;
  location3?: string;
  location4?: string;
  location5?: string;

  deserialize(data: any) {
    const {
      id0,
      id1,
      id2,
      id3,
      id4,
      id5,
      location0,
      location1,
      location2,
      location3,
      location4,
      location5,
    } = data;
    this.id0 = id0;
    this.id1 = id1;
    this.id2 = id2;
    this.id3 = id3;
    this.id4 = id4;
    this.id5 = id5;
    this.location0 = location0;
    this.location1 = location1;
    this.location2 = location2;
    this.location3 = location3;
    this.location4 = location4;
    this.location5 = location5;
    this.isMergedSkybox = data.isMergedSkybox;
  }
  serialize() {
    const {
      id0,
      id1,
      id2,
      id3,
      id4,
      id5,
      location0,
      location1,
      location2,
      location3,
      location4,
      location5,
      isMergedSkybox,
    } = this;
    return {
      id0,
      id1,
      id2,
      id3,
      id4,
      id5,
      location0,
      location1,
      location2,
      location3,
      location4,
      location5,
      isMergedSkybox,
    };
  }
  hasLocations() {
    const { location0, location1, location2, location3, location4, location5 } = this;
    if (this.isMergedSkybox) {
      return !!location0;
    }
    return location0 && location1 && location2 && location3 && location4 && location5;
  }

  async updateTextureSources() {
    const { id0, id1, id2, id3, id4, id5 } = this;
    if (ProVizConfig.selfContained) {
      this.location0 = FileService.getLocation((await TextureService.get(id0))?.file!);
      if (!this.isMergedSkybox) {
        this.location1 = FileService.getLocation((await TextureService.get(id1))?.file!);
        this.location2 = FileService.getLocation((await TextureService.get(id2))?.file!);
        this.location3 = FileService.getLocation((await TextureService.get(id3))?.file!);
        this.location4 = FileService.getLocation((await TextureService.get(id4))?.file!);
        this.location5 = FileService.getLocation((await TextureService.get(id5))?.file!);
      }
    } else {
      const texResponses = await TextureService.getBatch([id0, id1, id2, id3, id4, id5]);
      this.location0 = texResponses.find((t) => t.id === id0)?.file!.location;
      if (!this.isMergedSkybox) {
        this.location1 = texResponses.find((t) => t.id === id1)?.file!.location;
        this.location2 = texResponses.find((t) => t.id === id2)?.file!.location;
        this.location3 = texResponses.find((t) => t.id === id3)?.file!.location;
        this.location4 = texResponses.find((t) => t.id === id4)?.file!.location;
        this.location5 = texResponses.find((t) => t.id === id5)?.file!.location;
      }
    }
  }

  async getTextureSources() {
    if (!this.hasLocations() || ProVizConfig.selfContained) {
      await this.updateTextureSources();
    }
    return this.locationArray;
  }

  get locationArray() {
    const { location0, location1, location2, location3, location4, location5 } = this;
    if (this.isMergedSkybox) {
      return [location0];
    }
    return [location0, location1, location2, location3, location4, location5];
  }
}

export function isSkyboxTexture(t: any): t is PanoramaTexture {
  return t.isSkybox === true;
}
