import { ProVizConfig } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class SceneChangeWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'scene-change';

  // Data
  public sceneId: string = '';
  public options: string = '';
  public newWindow: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Scene Change';

    this.usage = 'Flow';
    this.category = 'Events';

    this.widgetType = SceneChangeWidget.type;
    this.widgetName = 'Scene Change';
    this.selectable = true;
    this.events = [];
    this.showInFlow = true;
    this.services = [
      {
        label: 'Open',
        name: 'open',
      },
    ];

    this.addEventListener('service-open', (event) => {
      if (this.scene.sceneMode === SceneMode.Editor) {
        return;
      }

      const currentParams = new URLSearchParams(window.location.search);

      (this.options || '').split(',').forEach((keypair: string) => {
        const keyValPair = keypair.split(':');
        if (keyValPair.length === 2) {
          currentParams.set(keyValPair[0], keyValPair[1]);
        } else {
          currentParams.set(keyValPair[0], '');
        }
      });

      const params = decodeURIComponent(currentParams.toString());

      if (ProVizConfig.selfContained && window.location.pathname.split('/').includes('$web')) {
        window.open(
          `${window.location.protocol}//${window.location.hostname}/$web/${this.sceneId}/index.html?${params}`,
          this.newWindow ? '_blank' : '_self',
        );
      } else if (ProVizConfig.selfContained) {
        window.open(
          `${window.location.protocol}//${window.location.hostname}/${this.sceneId}/index.html?${params}`,
          this.newWindow ? '_blank' : '_self',
        );
      } else {
        window.open(`${this.sceneId}?${params}`, this.newWindow ? '_blank' : '_self');
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('sceneId', 'Scene', 'Core', 'scene', 'string', true),
      this.createProperty('options', 'Options', 'Core', 'options', 'string', true),
      this.createProperty('newWindow', 'Open in new window', 'Core', 'bool', 'boolean', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.sceneId = this.sceneId;
    result.options = this.options;
    result.newWindow = this.newWindow;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.sceneId = data.sceneId;
    this.options = data.options;
    this.newWindow = data.newWindow;
  }
}

ModuleService.Register(SceneChangeWidget.type, SceneChangeWidget);
