import { BaseDataService } from "../BaseDataService";
import { APIDataSchema } from "../models/DataSchema";

export class APIDataSchemaService extends BaseDataService<APIDataSchema> {
  constructor() {
    super("DataSchema");
  }

  async create(data: { name: string, collection: string, read: boolean, write: boolean, schema: string }) {
    return this.post('', data);
  }
}

const DataSchemaService = new APIDataSchemaService();

export default DataSchemaService;
