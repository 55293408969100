import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { Vector3 } from '../../../types/vector3';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class Vector3Widget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'vector3';

  //Data
  public value: Vector3 = { x: 0, y: 0, z: 0 };
  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = Vector3Widget.type;
    this.widgetName = 'Vector3';
    this.label = 'Vector3';
    this.usage = 'Flow';
    this.category = 'Position';
    this.events.push(
      {
        label: 'Value',
        name: 'value',
      },
      {
        label: 'Z',
        name: 'z',
      },
      {
        label: 'Y',
        name: 'y',
      },
      {
        label: 'X',
        name: 'x',
      },
    );
    this.addService(
      'set Value',
      'set-value',
      '<b>Updates vector3 value</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'vector3') {
          this.value = event.data as Vector3;
        }
        this.emitValues();
      },
    );
    this.addService('apply', 'apply', '<b>Apply Vector3 value</b>', (event: ProVizEventData) => {
      this.emitValues();
    });
  }

  public emitValues() {
    this.triggerProVizEvent('value', 'vector3', this.value);
    this.triggerProVizEvent('x', 'number', this.value.x);
    this.triggerProVizEvent('y', 'number', this.value.y);
    this.triggerProVizEvent('z', 'number', this.value.z);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('value', 'Data', 'Core', 'vec3', 'vector3', true)];
  }

  public serialize(): any {
    const result = super.serialize();
    result.value = this.value;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.value = data.value;
  }
}

ModuleService.Register(Vector3Widget.type, Vector3Widget);
