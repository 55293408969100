import {
  BufferGeometry,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
} from "three";
import { GizmoPart } from "./GizmoBase";

export function ScaleHelper(): { [key: string]: GizmoPart[] } {
  const gizmoLineMaterial = new LineBasicMaterial({
    depthTest: false,
    depthWrite: false,
    fog: false,
    toneMapped: false,
    transparent: true,
  });

  const matHelper = gizmoLineMaterial.clone();
  matHelper.opacity = 0.5;

  const lineGeometry = new BufferGeometry();
  lineGeometry.setAttribute(
    "position",
    new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3)
  );

  return {
    X: [
      new GizmoPart(
        new Line(lineGeometry, matHelper.clone()),
        [-1e3, 0, 0],
        undefined,
        [1e6, 1, 1],
        "helper"
      ),
    ],
    Y: [
      new GizmoPart(
        new Line(lineGeometry, matHelper.clone()),
        [0, -1e3, 0],
        [0, 0, Math.PI / 2],
        [1e6, 1, 1],
        "helper"
      ),
    ],
    Z: [
      new GizmoPart(
        new Line(lineGeometry, matHelper.clone()),
        [0, 0, -1e3],
        [0, -Math.PI / 2, 0],
        [1e6, 1, 1],
        "helper"
      ),
    ],
  };
}
