import { APIUser } from "@proviz/api-services";
class AccessTokenData {
  token: string | undefined;
  user?: APIUser;
}

export const AccessToken: AccessTokenData = {
  token: undefined,
  user: undefined,
};
