import { DoubleSide, LineBasicMaterial, Mesh, ShapeGeometry } from 'three';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { proVizFonts } from '../../../proVizFonts';
import { ProVizEventData } from '../../../ProVizEventData';

export class Text3dWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'text-3d';
  private static instanceCount = 0;

  // Data
  public textContent: string = 'Text';
  public font: string = '';
  public fontSize: number = 48;
  public color: string = '#000';
  public bevelThickness: number = 2;
  public bevelSize: number = 1.5;
  public bevelEnabled: boolean = false;

  private threejsFont?: Font;
  private textMesh?: Mesh<ShapeGeometry, LineBasicMaterial>;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = Text3dWidget.type;
    this.widgetName = 'Text 3D';
    if (!notInScene) {
      Text3dWidget.instanceCount++;
    }
    this.label = 'Text 3D' + Text3dWidget.instanceCount;
    this.selectable = true;
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
    ];

    this.services.push({
      label: 'Set Text',
      name: 'set-text',
      desc: 'Use a Resource flow widget to set the text',
    });

    this.addEventListener('service-set-text', (event: ProVizEventData) => {
      if (event.dataType === 'string') {
        this.textContent = event.data as string;
      }
      this.updateText();
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'textContent',
        'Text',
        'Core',
        'multi-line-string', 
        'string',
        true,
        () => this.textContent,
        (data: string) => {
          this.textContent = data;
          this.updateText();
        },
      ),
      this.createProperty('font', 'Font', 'Font', 'font', 'string', true),
      this.createProperty(
        'fontSize',
        'Font Size',
        'Font',
        'number',
        'number',
        true,
        () => this.fontSize,
        (data: number) => {
          this.fontSize = data;
          this.updateText();
        },
      ),
      this.createProperty(
        'color',
        'Color',
        'Font',
        'color',
        'string',
        true,
        () => this.color,
        (data: string) => {
          this.color = data;
          this.updateText();
        },
      ),
      this.createProperty(
        'bevelThickness',
        'Bevel Thickness',
        'Font',
        'number',
        'number',
        true,
        () => this.color,
        (data: number) => {
          this.bevelThickness = data;
          this.updateText();
        },
      ),
      this.createProperty(
        'bevelSize',
        'Bevel Size',
        'Font',
        'number',
        'number',
        true,
        () => this.color,
        (data: number) => {
          this.bevelSize = data;
          this.updateText();
        },
      ),
      this.createProperty(
        'bevelEnabled',
        'Bevel Enabled',
        'Font',
        'bool',
        'boolean',
        true,
        () => this.color,
        (data: boolean) => {
          this.bevelEnabled = data;
          this.updateText();
        },
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.textContent = this.textContent;
    result.font = this.font;
    result.fontSize = this.fontSize;
    result.color = this.color;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.textContent = data.textContent;
    this.font = data.font;
    this.fontSize = data.fontSize;
    this.color = data.color;
  }

  public getClickable() {
    const result = super.getClickable();
    result.push(this.renderNode);
    return result;
  }

  updateText() {
    if (!this.threejsFont) {
      return;
    }
    if (this.textMesh) {
      this.renderNode.remove(this.textMesh);
    }
    if (!this.textContent) {
      return;
    }
    const matDark = new LineBasicMaterial({
      color: this.color,
      side: DoubleSide,
    });

    const shapes = this.threejsFont.generateShapes(this.textContent, (this.fontSize || 12) * 0.01);

    const geometry = new ShapeGeometry(shapes);
    geometry.computeBoundingBox();
    if (!geometry.boundingBox) {
      console.error('Cannot compute bounding box');
      return;
    }
    const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
    geometry.translate(xMid, 0, 0);
    this.textMesh = new Mesh(geometry, matDark);
    this.renderNode.add(this.textMesh);
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    const fontLoader = new FontLoader();
    fontLoader.load(proVizFonts.helvetiker.regular, (threejsFont: Font) => {
      this.threejsFont = threejsFont;
      if (this.textContent) {
        // Load font
        this.updateText();
      }
    });

    return true;
  }

  dispose() {
    super.dispose();
    this.textMesh?.material.dispose();
    this.textMesh?.geometry.dispose();
  }
}

ModuleService.Register(Text3dWidget.type, Text3dWidget);
