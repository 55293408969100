import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  APIScene,
  APIFolder,
  ProVizConfig,
  SceneService,
  Paged,
  TagService,
  initAbortController,
  isAbortError,
  PermissionEntity,
  PermissionType,
  AnalyticService,
} from "@proviz/api-services";
import { useHistory } from "react-router-dom";
import ThumbnailCard, { ISceneRecord } from "./ThumbnailCard";
import MatterportModal from "./MatterportModal";
import SceneThumbnailModal from "./SceneThumbnailModal";
import Button from "../common/button/Button";
import FolderNewModal from "./FolderNewModal";
import FoldersList from "./FoldersList";
import InfoIcon from "./InfoIcon";
import Modal from "../common/modals/Modals";
import PagingControl from "../common/paging/PagingControl";
import PageHeader from "./pageHeader";
import PageContentHeader from "./pageContentHeader";
import { searchStore } from "../../store/SearchStore";
import PaddedContainer from "../common/containers/paddedContainer";
import { userStore } from "../../store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Analytic from "@proviz/api-services/lib/types/Analytic";

export function compareScenesByTime(s1: ISceneRecord, s2: ISceneRecord) {
  return new Date(s2.updated).valueOf() - new Date(s1.updated).valueOf();
}

type ModelQueryKeyType = [
  string,
  number,
  string | undefined,
  string | undefined
];

export default function SceneView(): ReactElement {
  console.log("Scene View");

  const queryClient = useQueryClient();

  const history = useHistory();

  const [currentFolder, setCurrentFolder] = useState<APIFolder | null>(null);
  const [page, setPage] = useState<number>(0);
  const [thumbnailModal, setThumbnailModal] = useState<APIScene | null>(null);
  const [infoModal, setInfoModal] = useState<APIScene | null>(null);
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [tags, setTags] = useState<any[]>([]);
  const [tagId, setTagId] = useState<any>("");
  const [sceneTags, setSceneTags] = useState<any[]>([]);
  const [sceneAnalytics, setSceneAnalytics] = useState<any>();

  const search = searchStore((x) => x.search);
  const user = userStore((s) => s.user);
  const hasPermission = userStore((s) => s.hasPermission);
  const canDelete = useMemo(
    () => hasPermission(PermissionEntity.Scene, PermissionType.Delete),
    [hasPermission]
  );

  const queryKey: ModelQueryKeyType = [
    "scenes",
    page,
    currentFolder?.id,
    search,
  ];
  const queryFn = (params: { queryKey: ModelQueryKeyType }) => {
    const [, page, currentFolderId, search] = params.queryKey;
    if (currentFolderId) {
      return SceneService.pagedByFolder(
        currentFolderId,
        page as number,
        10,
        search
      );
    }
    return SceneService.paged(page as number, 10, search);
  };

  const { isLoading, error, data } = useQuery<
    Promise<Paged<APIScene>>,
    undefined,
    Paged<APIScene>,
    ModelQueryKeyType
  >(queryKey, queryFn);

  const getUpdatedScenes = () => {
    queryClient.invalidateQueries(["scenes", page, currentFolder?.id, search]);
  };

  const onImpersonateChange = () => {
    setCurrentFolder(null);
    getUpdatedScenes();
  };

  useEffect(() => {
    ProVizConfig.events.addListener("impersonate-change", onImpersonateChange);
    return () => {
      ProVizConfig.events.removeListener(
        "impersonate-change",
        onImpersonateChange
      );
    };
  }, []);

  useEffect(() => {
    const abortController = initAbortController();
    (async () => {
      try {
        if (infoModal) {
          const res = await TagService.getAll({ abortController });
          setTags(res);
        }
      } catch (e) {
        if (isAbortError(e)) return; // if the query was aborted, do nothing
        console.error(e);
      }
    })();
    (async () => {
      try {
        if (infoModal) {
          await loadSceneTags(infoModal.id);
        }
      } catch (e) {
        if (isAbortError(e)) return; // if the query was aborted, do nothing
        console.error(e);
      }
    })();
    (async () => {
      if (infoModal) {
        const sceneView = await AnalyticService.sceneCount(
          Analytic.SceneView,
          infoModal.id
        );
        const scenePublish = await AnalyticService.sceneCount(
          Analytic.ScenePublish,
          infoModal.id
        );
        const sceneUpdate = await AnalyticService.sceneCount(
          Analytic.SceneUpdate,
          infoModal.id
        );
        setSceneAnalytics({ sceneView, scenePublish, sceneUpdate });
      } else {
        setSceneAnalytics({});
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [infoModal]);

  const loadSceneTags = async (sceneId: string) => {
    const res = await SceneService.getSceneTags(sceneId);
    setSceneTags(res);
  };

  const deleteScene = async (scene: APIScene) => {
    await SceneService.delete(scene.id);
    getUpdatedScenes();
  };

  const duplicate = async (scene: APIScene) => {
    const newScene = await SceneService.create({
      name: scene.name + " Copy",
      sceneType: scene.sceneType,
      folderId: scene.folderId,
    });
    const sceneWithData = await SceneService.getDraft(scene.id);
    newScene.data = sceneWithData.data;
    await SceneService.update(newScene);
    history.push(`/editor/${newScene.id}`);
  };

  if (error) {
    return <h1>We're afraid something went wrong. Try again later.</h1>;
  }

  // const createScene = async () => {
  //   const scene: ISceneRecord = await SceneService.create({
  //     name: "New Scene",
  //     sceneType: "Standard",
  //     folderId: currentFolder?.id || null,
  //   });
  //   history.push(`/editor/${scene.id}`);
  // };

  return (
    <div className="homeRightView">
      <PageHeader />

      <div className="homeContent">
        <div className="homeContentContainer">
          <PaddedContainer>
            <PageContentHeader title="Scenes">
              <div>{user && user.isSuperAdmin && <MatterportModal />}</div>
              <div>
                <Button onClick={() => setShowNewFolder(true)}>
                  <span className="icon icon-plus"> </span>
                  Folder
                </Button>
              </div>
              <div>
                <Button
                  onClick={() =>
                    history.push("/home/scenes/new", currentFolder)
                  }
                >
                  <span className="icon icon-plus"> </span>
                  Create Scene
                </Button>
              </div>
            </PageContentHeader>
          </PaddedContainer>

          <FoldersList
            folderType="Scene"
            onFolderChange={(folder: APIFolder | null) => {
              setCurrentFolder(folder);
              getUpdatedScenes();
            }}
          />
          <div className="modelList">
            {isLoading && <div className="loading-indicator">Loading...</div>}
            {data?.data.map((scene) => {
              return (
                <ThumbnailCard
                  key={scene.id}
                  title={scene.name}
                  thumbnail={scene.thumbnailFile?.location}
                  openUrl={`/editor/${scene.id}`}
                  updated={scene.updated}
                  onDragStart={(e: React.DragEvent<HTMLElement>) => {
                    console.log("scene drag started");
                    e.dataTransfer.setData("scene/id", scene.id);
                  }}
                >
                  <InfoIcon
                    onClick={() => {
                      setInfoModal(scene);
                    }}
                  />
                </ThumbnailCard>
              );
            })}
          </div>
          {thumbnailModal && (
            <SceneThumbnailModal
              scene={thumbnailModal}
              close={() => setThumbnailModal(null)}
              refresh={() => getUpdatedScenes()}
            />
          )}

          {infoModal && (
            <Modal title="Scene Info" close={() => setInfoModal(null)}>
              <Button fullWidth={true} onClick={() => duplicate(infoModal)}>
                <span className="icon-duplicate"></span> Duplicate
              </Button>
              <br />
              <br />
              <Button
                fullWidth={true}
                onClick={() => {
                  setThumbnailModal(infoModal);
                  setInfoModal(null);
                }}
              >
                <span className="icon-pencil"></span> Set Thumbnail
              </Button>
              <br></br>
              <div id="tags-container">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {sceneTags.map((tag, index) => {
                    return (
                      <span
                        key={index}
                        style={{ marginRight: "5px", marginTop: "15px" }}
                      >
                        <span
                          style={{
                            background: "rgba(0, 0, 0, 0.4",
                            padding: "2px",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          #
                        </span>
                        <span
                          style={{
                            background: "rgba(0, 0, 0, 0.2",
                            padding: "2px",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                        >
                          {tag.name}
                        </span>
                        <span
                          style={{
                            float: "right",
                            marginTop: "-12.5px",
                            marginLeft: "-5px",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            await SceneService.deleteSceneTag(
                              infoModal.id,
                              tag.id
                            );
                            await loadSceneTags(infoModal.id);
                          }}
                        >
                          X
                        </span>
                      </span>
                    );
                  })}
                </div>
                <br></br>
                <div style={{ display: "flex" }}>
                  <select
                    style={{ flexGrow: 1 }}
                    value={tagId}
                    onChange={(e) => {
                      setTagId(e.target.value);
                    }}
                  >
                    <option></option>
                    {tags.map((tag) => {
                      return (
                        <option key={tag.id} value={tag.id}>
                          #{tag.name}
                        </option>
                      );
                    })}
                  </select>
                  <Button
                    disabled={!tagId}
                    onClick={async () => {
                      const abortController = initAbortController();
                      try {
                        await SceneService.addTag(infoModal.id, tagId, {
                          abortController,
                        });
                        await loadSceneTags(infoModal.id);
                      } catch (e) {
                        if (isAbortError(e)) return; // if the query was aborted, do nothing
                        console.error(e);
                      }
                    }}
                  >
                    Add Tag
                  </Button>
                </div>
              </div>
              <br />
              <table>
                <tr>
                  <th>Scene Views</th>
                  <th>Scene Publishes</th>
                  <th>Scene Updates</th>
                </tr>
                <tr>
                  <td>{sceneAnalytics.sceneView ?? 0}</td>
                  <td>{sceneAnalytics.scenePublish ?? 0}</td>
                  <td>{sceneAnalytics.sceneUpdate ?? 0}</td>
                </tr>
              </table>
              <br />
              {canDelete && (
                <Button
                  type="danger"
                  fullWidth={true}
                  onClick={() => deleteScene(infoModal)}
                >
                  <span className="icon-trash_grey"></span> Delete
                </Button>
              )}
            </Modal>
          )}

          {showNewFolder && (
            <FolderNewModal
              folder={currentFolder}
              folderType={"Scene"}
              close={() => {
                setShowNewFolder(false);
                getUpdatedScenes();
              }}
            />
          )}

          <div className="searchBar">
            <PagingControl
              page={page}
              totalPages={data?.pagesTotal || 0}
              pageSet={(p: number) => setPage(p)}
              isFileSelect={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
