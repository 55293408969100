import {
  BoxGeometry,
  CylinderGeometry,
  Mesh,
  MeshBasicMaterial,
  OctahedronGeometry,
} from "three";
import { GizmoPart } from "./GizmoBase";

export function TranslateGizmo(): { [key: string]: GizmoPart[] } {
  const gizmoMaterial = new MeshBasicMaterial({
    depthTest: false,
    depthWrite: false,
    fog: false,
    toneMapped: false,
    transparent: true,
  });

  const matRed = gizmoMaterial.clone();
  matRed.color.setHex(0xff0000);

  const matGreen = gizmoMaterial.clone();
  matGreen.color.setHex(0x00ff00);

  const matBlue = gizmoMaterial.clone();
  matBlue.color.setHex(0x0000ff);

  const matRedTransparent = gizmoMaterial.clone();
  matRedTransparent.color.setHex(0xff0000);
  matRedTransparent.opacity = 0.5;

  const matGreenTransparent = gizmoMaterial.clone();
  matGreenTransparent.color.setHex(0x00ff00);
  matGreenTransparent.opacity = 0.5;

  const matBlueTransparent = gizmoMaterial.clone();
  matBlueTransparent.color.setHex(0x0000ff);
  matBlueTransparent.opacity = 0.5;

  const matWhiteTransparent = gizmoMaterial.clone();
  matWhiteTransparent.opacity = 0.25;

  const arrowGeometry = new CylinderGeometry(0, 0.04, 0.1, 12);
  arrowGeometry.translate(0, 0.05, 0);

  const lineGeometry2 = new CylinderGeometry(0.0075, 0.0075, 0.5, 3);
  lineGeometry2.translate(0, 0.25, 0);

  return {
    X: [
      new GizmoPart(
        new Mesh(arrowGeometry, matRed),
        [0.5, 0, 0],
        [0, 0, -Math.PI / 2]
      ),
      new GizmoPart(
        new Mesh(arrowGeometry, matRed),
        [-0.5, 0, 0],
        [0, 0, Math.PI / 2]
      ),
      new GizmoPart(
        new Mesh(lineGeometry2, matRed),
        [0, 0, 0],
        [0, 0, -Math.PI / 2]
      ),
    ],
    Y: [
      new GizmoPart(new Mesh(arrowGeometry, matGreen), [0, 0.5, 0]),
      new GizmoPart(
        new Mesh(arrowGeometry, matGreen),
        [0, -0.5, 0],
        [Math.PI, 0, 0]
      ),
      new GizmoPart(new Mesh(lineGeometry2, matGreen)),
    ],
    Z: [
      new GizmoPart(
        new Mesh(arrowGeometry, matBlue),
        [0, 0, 0.5],
        [Math.PI / 2, 0, 0]
      ),
      new GizmoPart(
        new Mesh(arrowGeometry, matBlue),
        [0, 0, -0.5],
        [-Math.PI / 2, 0, 0]
      ),
      new GizmoPart(new Mesh(lineGeometry2, matBlue), undefined, [
        Math.PI / 2,
        0,
        0,
      ]),
    ],
    XYZ: [
      new GizmoPart(
        new Mesh(new OctahedronGeometry(0.1, 0), matWhiteTransparent.clone()),
        [0, 0, 0]
      ),
    ],
    XY: [
      new GizmoPart(
        new Mesh(new BoxGeometry(0.15, 0.15, 0.01), matBlueTransparent.clone()),
        [0.15, 0.15, 0]
      ),
    ],
    YZ: [
      new GizmoPart(
        new Mesh(new BoxGeometry(0.15, 0.15, 0.01), matRedTransparent.clone()),
        [0, 0.15, 0.15],
        [0, Math.PI / 2, 0]
      ),
    ],
    XZ: [
      new GizmoPart(
        new Mesh(
          new BoxGeometry(0.15, 0.15, 0.01),
          matGreenTransparent.clone()
        ),
        [0.15, 0, 0.15],
        [-Math.PI / 2, 0, 0]
      ),
    ],
  };
}
