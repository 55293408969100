import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import {createRoot} from 'react-dom/client';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
const queryClient = new QueryClient()

const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'th3rdcoast.auth0.com';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || 'u7QGwyT9i3X6X66IVCkKZsqhTqgvJkcG';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.provizxr.com';

const initialSearchURL = window.location.search;
function getInviteParam() {
  const urlParams = new URLSearchParams(initialSearchURL);
  const inviteCode = urlParams.get('invite');
  if (inviteCode) {
    return `?invite=${inviteCode}`;
  }
  return '';
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/callback${getInviteParam()}`}
      audience={audience}
      scope="openid email profile"
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn  more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
