import { ProVizConfig } from "./Config";
import { IHasId, Paged } from "./models";

/**
 * The abort controller is used to cancel requests. The function will throw, but you can use
 * the isAbortError(e) to check if it's just an abort error and ignore it.
 * https://medium.com/doctolib/react-stop-checking-if-your-component-is-mounted-3bb2568a4934
 *  */
export interface IServiceOptions {
  abortController?: AbortController;
}

export class BaseDataService<T extends IHasId> {
  protected endpointRoot: string;
  protected entityType: string;

  constructor(entityType: string) {
    this.entityType = entityType;
    this.endpointRoot = `v1/${entityType.replace(/\s/g, "")}`;
  }

  public async get(id: string, options?: IServiceOptions, queryParams?: { key: string, value: string}[]): Promise<T> {
    return await this.request(`${id}`, options, queryParams);
  }

  public async paged(
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<T>> {
    return await this.request(
      `paged?page=${page}&size=${size}${filter ? `&filter=${filter}` : ""}`,
      options
    );
  }

  public async getAll(options?: IServiceOptions): Promise<T[]> {
    return await this.request(undefined, options);
  }

  public async delete(id: string, options?: IServiceOptions): Promise<any> {
    return await this.request(id, { method: "DELETE", ...options });
  }

  protected async post(
    path?: string,
    body?: any,
    options?: IServiceOptions
  ): Promise<T> {
    return await this.request(path, { method: "POST", body, ...options });
  }

  protected async put(
    path?: string,
    body?: any,
    options?: IServiceOptions
  ): Promise<T> {
    return await this.request(path, { method: "PUT", body, ...options });
  }

  public getOrigin() {
    if (ProVizConfig.selfContained) {
      if (window.location.origin === "file://") {
        const parts = window.location.href.split('/');
        parts.pop();
        return `${parts.join('/')}/`;
      }
      return `${window.location.origin}/${ProVizConfig.selfContainedRoot}`
    }
    return `${ProVizConfig.backend}/`;
  }

  protected getFullPath(path?: string) {
    const endPath = path
      ? `${path}${ProVizConfig.selfContained ? ".json" : ""}`
      : ProVizConfig.selfContained
      ? "data.json"
      : "";
    const endpoint = `${this.getOrigin()}${this.endpointRoot}/${endPath}`;

    return endpoint;
  }

  protected async request(
    path: string = "",
    options: {
      method?: string;
      body?: any;
      forceJSONContent?: boolean;
      abortController?: AbortController;
    } = {},
    queryParams?: { key: string, value: string}[]
  ) {
    const { method, body, forceJSONContent, abortController } = options;
    const init = BaseDataService.buildRequestInit(method || "GET", body, {
      forceJSONContent,
      abortController,
    });
    const url = new URL(this.getFullPath(path));
    if (queryParams) {
      queryParams.forEach(q => url.searchParams.append(q.key, q.value));
    }
    console.log(url, url.href);
    const resp = await fetch(url.href, init);
    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }
    if (resp.status === 205) {
      throw new Error("Out of Date");
    }
    if (resp.status === 206) {
      throw new Error("None found");
    }
    if (resp.status === 401) {
      throw { status: 401 };
    }
    console.error(resp);
    throw new Error("Bad Response: " + resp.status);
  }

  protected async download(
    filename: string,
    options: {
      path?: string;
      method?: string;
      body?: any;
      forceJSONContent?: boolean;
      abortController?: AbortController;
    } = {}
  ) {
    const { path, method, body, forceJSONContent, abortController } = options;
    const init = BaseDataService.buildRequestInit(method || "GET", body, {
      forceJSONContent,
      abortController,
    });
    const resp = await fetch(this.getFullPath(path), init);
    const blob = await resp.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); // afterwards we remove the element again
  }

  protected async submit(
    path: string,
    method: string,
    body: FormData,
    options: { abortController?: AbortController } = {}
  ) {
    const endpoint = `${ProVizConfig.backend}/${this.endpointRoot}/${
      path || ""
    }`;
    const init = BaseDataService.buildRequest(
      method,
      false,
      options?.abortController
    );
    init.body = body;
    const resp = await fetch(endpoint, init);
    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }
    throw new Error("Bad Response");
  }

  private static buildRequestHeaders(jsonContent: boolean) {
    const headers = new Headers();
    headers.append("mode", "cors");
    if (ProVizConfig.token) {
      headers.append("Authorization", `Bearer ${ProVizConfig.token}`);
    }
    if (ProVizConfig.userKey) {
      headers.append("U-Api-Key", ProVizConfig.userKey);
    }
    if (ProVizConfig.machineKey) {
      headers.append("M-Api-Key", ProVizConfig.machineKey);
    }
    if (ProVizConfig.impersonateCompanyId) {
      headers.append("companyId", ProVizConfig.impersonateCompanyId);
    }
    if (jsonContent) {
      headers.append("content-type", "application/json");
    }
    return headers;
  }

  private static buildRequest(
    method: string,
    jsonContent: boolean,
    abortController?: AbortController
  ) {
    const headers = BaseDataService.buildRequestHeaders(jsonContent);
    const requestInit: RequestInit = {
      headers,
      method,
      signal: abortController?.signal,
    };
    return requestInit;
  }

  private static buildRequestInit(
    method: string,
    body?: any,
    options?: { forceJSONContent?: boolean; abortController?: AbortController }
  ) {
    const { forceJSONContent, abortController } = options ?? {};
    const requestInit = BaseDataService.buildRequest(
      method,
      body !== undefined || !!forceJSONContent,
      abortController
    );
    if (body) {
      requestInit.body = JSON.stringify(body);
    }
    return requestInit;
  }
}
