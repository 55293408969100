import {
  BoxGeometry,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  WireframeGeometry,
} from 'three';
import { ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import BasePositionableWidget from '../../basePositionableWidget';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class BoxColliderWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'box-collider';

  // Data
  public clickable: boolean = true;
  private cameraInside: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = BoxColliderWidget.type;
    this.widgetName = 'Box Collider';
    this.label = 'Box Collider';
    this.selectable = true;
    this.category = 'Colliders';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'On Enter',
        name: 'on-enter',
      },
      {
        label: 'On Leave',
        name: 'on-leave',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('clickable', 'Clickable', 'Interaction', 'bool', 'boolean', true),
    ];
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.clickable && this.physicsBounds) {
      result.push(this.physicsBounds);
    }
    return result;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.scene.addEventListener('view-change', () => {
      // Determine if entered box-collider
      var box = this.physicsBounds;
      if (box) {
        // This is only necessary if not allready computed
        box.updateMatrixWorld();
        const localPt = box.worldToLocal(
          new Vector3(
            this.scene.cameraPosition.x,
            this.scene.cameraPosition.y,
            this.scene.cameraPosition.z,
          ),
        );
        box.geometry.computeBoundingBox();
        if (box.geometry.boundingBox) {
          const isInside = box.geometry.boundingBox.containsPoint(localPt);
          if (isInside && !this.cameraInside) {
            // camera has entered box
            this.triggerProVizEvent('on-enter', 'none');
          } else if (!isInside && this.cameraInside) {
            // camera has left box
            this.triggerProVizEvent('on-leave', 'none');
          }
          this.cameraInside = isInside;
        }
      }
    });

    const geometry = new BoxGeometry(1, 1, 1);

    if (
      this.scene.sceneMode === SceneMode.Editor ||
      window.location.search.toLocaleLowerCase().indexOf('dev=true') > -1
    ) {
      const wireframeGeo = new WireframeGeometry(geometry);
      const line = new LineSegments(
        wireframeGeo,
        new LineBasicMaterial({
          depthTest: false,
          color: '#47538C',
        }),
      );
      this.renderNode.add(line);
    }

    const params: any = { color: 0xffffff, visible: false };
    this.physicsBounds = new Mesh(geometry, new MeshBasicMaterial(params));
    // this.physicsBounds.userData.widget = this;
    this.renderNode.add(this.physicsBounds);

    return true;
  }

  dispose() {
    super.dispose();
    if (this.physicsBounds) {
      this.physicsBounds.material.dispose();
      this.physicsBounds.geometry.dispose();
      if (this.physicsBounds) {
        this.physicsBounds.userData.widget = undefined;
      }
      this.renderNode.remove(this.physicsBounds);
    }
  }

  public serialize(): any {
    const result = super.serialize();
    result.clickable = this.clickable;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.clickable = data.clickable;
  }
}

ModuleService.Register(BoxColliderWidget.type, BoxColliderWidget);
