import { CircleGeometry, Mesh, MeshBasicMaterial, TextureLoader } from 'three';
import {
  APIModel,
  APIModelVariant,
  ModelService,
  ModelVariantService,
  getMediaFilePath,
} from '@proviz/api-services';
import {
  is_arquicklook_candidate,
  is_sceneviewer_candidate,
  openQuickLook,
  openSceneViewer,
  ProVizScene,
  SceneMode,
} from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { ProVizEventData } from '../../../ProVizEventData';

export class NativeARViewerWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'native-ar-widget';

  // Data
  public modelId: string = '';
  public modelVariantId: string = '';
  public allowScalingInAR: boolean = false;
  public markerSrc: string = '';

  private model?: APIModel;
  private modelVariant?: APIModelVariant;
  private arAnchor?: HTMLElement;
  private marker?: Mesh<CircleGeometry, MeshBasicMaterial>;

  private canActivateAR: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = NativeARViewerWidget.type;
    this.widgetName = 'Native AR Viewer';
    this.label = 'Native AR';
    this.selectable = true;
    this.category = 'Experimental';

    this.events.push({
      label: 'Clicked',
      name: 'clicked',
    });
    this.addEventListener('clicked', () => {
      if (this.canActivateAR) {
        this.activateAR();
      }
    });

    this.services.push({
      label: 'Set Variant',
      name: 'set-variant',
    });

    this.addEventListener('service-set-variant', async (event: ProVizEventData) => {
      if (event.dataType === 'modelVariantId') {
        this.modelVariantId = event.data as string;
        if (this.modelVariantId) {
          this.modelVariant = await ModelVariantService.get(this.modelVariantId);
        }
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'modelId',
        'Model',
        'Core',
        'model',
        'string',
        true,
        () => this.modelId,
        (data: any) => (this.modelId = data),
      ),
      this.createProperty('modelVariantId', 'Model Variant', 'Core', 'model-variant', 'string', true),
      this.createProperty('allowScalingInAR', 'Allow Scaling in AR', 'Core', 'bool', 'boolean', true),
      this.createProperty(
        'markerSrc',
        'Marker Source',
        'Core',
        'string',
        'string',
        true,
        undefined,
        (data: any) => {
          this.markerSrc = data;
          this.updateMarkerSrc();
        },
      ),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (!this.modelId) {
      return true;
    }
    const deviceSupportForAR = is_arquicklook_candidate || is_sceneviewer_candidate;
    console.log('AR support:', deviceSupportForAR);
    if (this.markerSrc && (deviceSupportForAR || this.scene.sceneMode === SceneMode.Editor)) {
      this.createMarker();
    }

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    this.model = await ModelService.get(this.modelId);
    this.modelVariant = await ModelVariantService.get(
      this.modelVariantId ?? this.model.defaultVariantId,
    );
    if (!this.modelVariant) {
      return true;
    }
    this.arAnchor = document.createElement('a');

    this.canActivateAR = deviceSupportForAR;

    return true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.marker) {
      result.push(this.marker);
    }
    return result;
  }

  public serialize(): any {
    const result = super.serialize();
    result.modelId = this.modelId;
    result.modelVariantId = this.modelVariantId;
    result.markerSrc = this.markerSrc;
    result.allowScalingInAR = this.allowScalingInAR;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.modelId = data.modelId;
    this.modelVariantId = data.modelVariantId;
    this.markerSrc = data.markerSrc;
    this.allowScalingInAR = data.allowScalingInAR;
  }

  private activateAR() {
    console.log('Activating Native AR');
    if (!this.canActivateAR) {
      alert('Cannot activate ar view.');
      return;
    }
    if (is_sceneviewer_candidate) {
      this.openSceneViewer();
    } else if (is_arquicklook_candidate) {
      this.openQuickLook();
    }
  }

  /**
   * Takes a URL and a title string, and attempts to launch Scene Viewer on
   * the current device.
   */
  private async openSceneViewer() {
    if (this.modelVariantId !== this.modelVariant?.id) {
      this.modelVariant = await ModelVariantService.get(this.modelVariantId);
    }
    if (!this.modelVariant || !this.arAnchor) {
      alert("We can't open this model on your device.");
      return;
    }
    await openSceneViewer(this.modelVariant, this.allowScalingInAR, this.arAnchor);
  }

  /**
   * Takes a URL to a USDZ file and sets the appropriate fields so that
   * Safari iOS can intent to their AR Quick Look.
   */
  private async openQuickLook() {
    if (this.modelVariantId !== this.modelVariant?.id) {
      this.modelVariant = await ModelVariantService.get(this.modelVariantId);
    }
    if (!this.model || !this.modelVariant || !this.arAnchor) {
      alert("We can't open this model on your device.");
      return;
    }
    await openQuickLook(this.model, this.modelVariant, this.allowScalingInAR, this.arAnchor);
  }

  public onHoverEnter() {
    document.body.style.cursor = 'pointer';
  }

  public onHoverLeave() {
    document.body.style.cursor = 'default';
  }

  private updateMarkerSrc() {
    if (!this.marker) {
      this.createMarker();
    } else {
      const imgSrc = getMediaFilePath(this.markerSrc);
      const textureLoader = new TextureLoader();
      const tex = textureLoader.load(imgSrc);
      // @ts-ignore
      this.marker.material.map = tex;
    }
  }

  private createMarker() {
    const imgSrc = getMediaFilePath(this.markerSrc);
    const geometry = new CircleGeometry(0.28, 32);
    const textureLoader = new TextureLoader();
    const tex = textureLoader.load(imgSrc);
    this.marker = new Mesh(geometry, new MeshBasicMaterial({ map: tex, transparent: false }));
    this.marker.renderOrder = 2;
    this.renderNode.add(this.marker);
  }

  public dispose(): void {
    super.dispose();
    this.marker?.material.dispose();
    this.marker?.geometry.dispose();
    this.arAnchor?.remove();
  }
}

ModuleService.Register(NativeARViewerWidget.type, NativeARViewerWidget);
