import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import {
  APIFile,
  FileService,
  FileType,
  FileTypes,
  APISprite,
  ProVizConfig,
  SpriteService,
  PermissionEntity,
  PermissionType,
} from "@proviz/api-services";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import UploadModal from "../common/upload/UploadModal";
import LoadingModal from "../common/loadingmodal/LoadingModal";
import { compareByTime } from "../../utils/TimeComparison";
import { SpriteModal } from "./SpriteModal";
import { FileEditModal } from "./FileEditModal";
import { DownloadFileButton } from "./DownloadFileButton";
import Button from "../common/button/Button";
import UploadButton from "../common/button/UploadButton";
import FileThumbnail from "../common/filethumbnail/FileThumbnail";
import GlobalExportMenu from "./GlobalExportMenu";
// import TagSelector from './TagSelector';
import PagingControl from "../common/paging/PagingControl";
import PageContentHeader from "./pageContentHeader";
import { searchStore } from "../../store/SearchStore";
import { useParams } from "react-router-dom";
import { userStore } from "../../store";

const editImage = getAbsoluteUrl("images/pencil.svg");

const FileTypeOptions = ["All", ...FileTypes];

const FileTypeKey = [
    "Model",
    "Texture",
    "Text",
    "Material",
    "Json",
    "Video",
    "Audio",
    "ModelVariant",
    "Sprite",
    "Data",
    "Unknown",
    "EnvMap",
    "Subtitle",
    "Image",
]

const HeaderKey: { [key: string]: string } = {
    Model: "Models",
    Texture: "Textures",
    Text: "Texts",
    Material: "Materials",
    Json: "Json",
    Video: "Videos",
    Audio: "Audio",
    ModelVariant: "Model Variants",
    Sprite: "Sprites",
    Data: "Data",
    Unknown: "Unknown",
    EnvMap: "Env Maps",
    Subtitle: "Subtitles",
    Image: "Images"
}

export default function FileView(): ReactElement {
  const { fileType } = useParams<{ fileType: string }>();

  const [loading, setLoading] = useState<boolean>(true);

  const [selectedFileType, setSelectedFileType] = useState<
    typeof FileTypeOptions[number] | undefined
  >(fileType || undefined);
  useEffect(() => {
    setSelectedFileType(fileType || "All");
  }, [fileType]);

  // const [tagId, setTagId] = useState('');
  const [files, setFiles] = useState<APIFile[]>([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [waitingText, setWaitingText] = useState("");
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const debounceRequest = useRef<NodeJS.Timeout | undefined>(undefined);
  const [spriteModal, setSpriteModal] = useState<APISprite | null>(null);
  const [fileEditModal, setFileEditModal] = useState<APIFile | null>(null);
  const [globalExportMenu, setGLobalExportMenu] = useState<APIFile | null>(
    null
  );
  const [causeUpdate, setCauseUpdate] = useState(0);
  // causeUpdate is incremented whenever an update is requested and not changed by the use effect
  // logic to prevent a loop
  const search = searchStore((x) => x.search);
  const user = userStore((s) => s.user);
  const hasPermission = userStore((s) => s.hasPermission);
  // const canDelete = useMemo(() => hasPermission('File', 'Delete'), [hasPermission]);
  const canEdit = useMemo(
    () => hasPermission(PermissionEntity.File, PermissionType.Edit),
    [hasPermission]
  );

  const isFirstRender = useRef(true);
  const now = useRef(true);

  const updateNow = () => {
    setCauseUpdate(causeUpdate + 1);
    now.current = true;
  };

  /**
   * On the first render add an  event listener for company change which
   * will update files immediately and update files immediately.
   * After that update file calls are debounced.
   * The effect return, removes the update listener when this component is
   * unmounted.
   */
  useEffect(() => {
    const loadFiles = async (fileType: FileType | undefined) => {
      function sortFiles(m: APIFile[]) {
        return m.sort(compareByTime);
      }
      let files;
      // if(tagId !== "") {
      //     files = await FileService.getAllByTag(tagId, fileType, page, 20, searchParams)
      // } else
      if (fileType !== undefined) {
        files = await FileService.getAllByFileTypePaged(
          fileType,
          page,
          20,
          search
        );
      } else {
        files = await FileService.getAllPaged(page, 20, search);
      }
      setTotalPages(files.pagesTotal);
      return sortFiles(files.data);
    };

    const updateFiles = () => {
      if (debounceRequest.current) {
        clearTimeout(debounceRequest.current);
      }
      if (now.current) {
        setLoading(true);
        loadFiles(selectedFileType === "All" ? undefined : selectedFileType)
          .then(setFiles)
          .then(() => {
            setLoading(false);
          });
        debounceRequest.current = undefined;
      } else {
        debounceRequest.current = setTimeout(() => {
          setLoading(true);
          loadFiles(selectedFileType === "All" ? undefined : selectedFileType)
            .then(setFiles)
            .then(() => {
              setLoading(false);
            });
        }, 250);
      }
      now.current = false;
    };

    const impersonateCb = () => {
      now.current = true;
      updateFiles();
    };

    if (isFirstRender.current) {
      ProVizConfig.events.addListener("impersonate-change", impersonateCb);
      isFirstRender.current = false;
    }
    updateFiles();
    now.current = false;
    return () => {
      ProVizConfig.events.removeListener("impersonate-change", impersonateCb);
    };
  }, [search, selectedFileType, page, causeUpdate]);

  const FileEntry = (props: { file: APIFile }) => {
    const { file } = props;
    const filetype = FileTypeKey[parseInt(file.fileType)]
    return (
      <tr key={file.id}>
        <td className="thumbnailCell">
          <FileThumbnail file={file} />
        </td>
        <td data-file-id={file.id}>{file.displayName || file.fileName}</td>
        <td>{new Date(file.created + "Z").toLocaleString()}</td>
        <td>
          {canEdit &&
            (filetype === "Sprite" ? (
              <Button
                onClick={async () =>
                  setSpriteModal(await SpriteService.getSpriteByFileId(file.id))
                }
              >
                <img className="editImage" src={editImage} alt="Edit" />
                Edit
              </Button>
            ) : (
              <Button onClick={() => setFileEditModal(file)}>
                <img className="editImage" src={editImage} alt="Edit" />
                Edit
              </Button>
            ))}
        </td>
        <td>
          <DownloadFileButton file={file} />
        </td>
        <td>
          {user?.isSuperAdmin && (
            <Button onClick={() => setGLobalExportMenu(file)}>
              Export to Global
            </Button>
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <PageContentHeader title={HeaderKey[fileType] || fileType || "Files"}>
        {/* <div>
                <select className="selector" value={selectedFileType} onChange={(e) => {
                    setSelectedFileType(e.target.value as FileType)
                    setPage(0)
                }}>
                    {FileTypeOptions.map((fileType) =>
                        <option value={fileType} key={fileType}>{fileType}</option>
                    )}
                </select>
            </div> */}
        <div>
          <UploadButton onClick={() => setUploadFile(true)} />
        </div>
      </PageContentHeader>

      {spriteModal && (
        <SpriteModal
          sprite={spriteModal}
          close={() => {
            setSpriteModal(null);
            updateNow();
          }}
        />
      )}
      {fileEditModal && (
        <FileEditModal
          file={fileEditModal}
          close={() => {
            setFileEditModal(null);
            setCauseUpdate(causeUpdate + 1);
            now.current = true;
          }}
        />
      )}
      <UploadModal
        open={uploadFile}
        setOpen={() => setUploadFile(true)}
        close={() => setUploadFile(false)}
        reload={updateNow}
        setWaitingText={setWaitingText}
        resourceType={selectedFileType || "All"}
        disableDragAndDrop={!!spriteModal || !!fileEditModal || uploadFile}
      >
        <div className="fileView">
          {loading && <div className="loading-indicator">Loading...</div>}

          {loading === false && files.length === 0 && (
            <div className="loading-indicator">
              There are no assets to show...
            </div>
          )}

          <table>
            <thead>
              <tr>
                <th></th>
                <th>File</th>
                <th>Uploaded</th>
                <th></th>
                <th></th>
                {user?.isSuperAdmin && <th></th>}
              </tr>
            </thead>
            <tbody>
              {files.map((f: APIFile, I:number) => (
                <FileEntry file={f} key={`File-${I}`} />
              ))}
            </tbody>
          </table>
        </div>
        {globalExportMenu && (
          <GlobalExportMenu
            entity={{ type: "File", file: globalExportMenu }}
            close={() => setGLobalExportMenu(null)}
          />
        )}
        {waitingText.length > 0 && (
          <LoadingModal close={() => setWaitingText("")} text={waitingText} />
        )}

        <div className="searchBar">
          <PagingControl
            page={page}
            totalPages={totalPages}
            pageSet={(p: number) => setPage(p)}
            isFileSelect={false}
          />
        </div>
      </UploadModal>
    </>
  );
}
