import React, { ReactElement, useState } from 'react'
import { SettableWidgetProperty } from './SettableWidgetProperty';
import Button from '../../common/button/Button';
import { IMultiLanguageList } from '@proviz/proviz-sdk'
import { ManagerStore } from '../../../store';

interface Props {
    languages: string[];
    property: SettableWidgetProperty;
    header: ReactElement;
}

function maxLength(languageDict: IMultiLanguageList) {
    if (Object.keys(languageDict).length === 0) {
        return 0;
    }
    let maxLen = 0;
    for (let lang of Object.keys(languageDict)) {
        if (languageDict[lang].length > maxLen) {
            maxLen = languageDict[lang].length;
        }
    }
    return maxLen;
}

export const MultiLanguageList = (props: Props) => {
    const { header, languages, property } = props;
    const [activeLang, setActiveLang] = useState(languages[0]);
    const [, updateState] = React.useState<any>();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const sceneManager = ManagerStore( s => s.SceneManager )

    const languageDict: IMultiLanguageList = property.get() || {};
    if (!languageDict[activeLang] || languageDict[activeLang].length === 0) {
        // If the selected language does not exist in the dictionary there are two cases:
        // 1) if there is an entry for the first language in the languagesDict
        //      then create the new entry with empty values for each entry in the 
        //      first language's entry
        // 2) otherwise set it equal to a single empty string list entry
        // Either way set the property on the widget after creating this new value.
        const maxLen = maxLength(languageDict);
        languageDict[activeLang] = Array(maxLen).fill('');
        property.set(Object.assign({}, languageDict));
        // forceUpdate();

        // sceneManager.dispatchWidgetUpdate();
    }
    
    function addOption() {
        // const old = Object.assign({}, languageDict);
        for (let lang of Object.keys(languageDict)) {
            if(!languageDict[lang]) {
                languageDict[lang] = [];
            }
            languageDict[lang].push('');
        }
        // sceneManager.execute(new SetWidgetProperty(property, old, languageDict, sceneManager));
        property.set(languageDict);
        updateState(null);
        forceUpdate();

        sceneManager.dispatchWidgetUpdate();
    }

    return <div className="property-table">
        {header}
        {languages.length > 1 &&
            <select value={activeLang} onChange={e => setActiveLang(e.target.value)}>
                {languages.map((l) => <option key={l} value={l}>{l}</option>)}
            </select>}
        <table>
            <tbody>
                {languageDict[activeLang] && languageDict[activeLang].map((keyValue: string, ind: number) =>
                    <tr key={`${property.name}-${activeLang}-${ind}`}>
                        <td>
                            <input type="text" value={keyValue} onChange={(e) => {
                                // const old = Object.assign({}, languageDict);
                                languageDict[activeLang][ind] = e.target.value;
                                // sceneManager.execute(new SetWidgetProperty(property, old, languageDict, sceneManager));
                                property.set(languageDict);
                                forceUpdate();
                                sceneManager.dispatchWidgetUpdate();
                            }} />
                        </td>
                        <td>
                            <Button type='icon' onClick={() => {
                                // Remove item from list
                                // const old = Object.assign({}, languageDict);
                                for (let lang of Object.keys(languageDict)) {
                                    languageDict[lang].splice(ind, 1);
                                }
                                // sceneManager.execute(new SetWidgetProperty(property, old, languageDict, sceneManager));
                                property.set(languageDict);
                                forceUpdate();
                                sceneManager.dispatchWidgetUpdate();
                            }}>
                                X
                            </Button>
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <Button fullWidth={true} onClick={addOption}>
                            Add Option
                        </Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>;
}
