import { useEffect, useState } from "react";
import styled from "styled-components";
import PageContentHeader from "../home/pageContentHeader";
import PageHeader from "../home/pageHeader";

import { createAvatar } from "@dicebear/avatars";
import * as avatarStyle from "@dicebear/avatars-avataaars-sprites";
import Button from "../common/button/Button";
import { UserService } from "@proviz/api-services";
import { userStore } from "../../store";

const AvatarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  img {
    cursor: pointer;
    border-radius: 50rem;
    &:hover {
      background: #eee;
    }
  }
`;

function Avatar(props: { seed: string; onClick: Function }) {
  const icon = createAvatar(avatarStyle, {
    seed: props.seed,
    dataUri: true,
    // ... and other options
  });
  return <img onClick={() => props.onClick()} src={icon} alt="" />;
}

export default function AvatarView() {
  const user = userStore((s) => s.user);
  const setUser = userStore((s) => s.setUser);
  const [avatars, setAvatars] = useState<string[]>([]);

  const genSeeds = () => {
    const seeds: string[] = [];
    for (let i = 0; i < 100; i++) {
      seeds.push((Math.random() * 999999999).toString());
    }
    setAvatars(seeds);
  };
  useEffect(() => {
    genSeeds();
  }, []);

  const updateIcon = async (seed: string) => {
    const userData = Object.assign({}, user);
    userData.avatar = seed;
    const updatedUser = await UserService.updateAvatar(userData);
    setUser(updatedUser);
  };

  return (
    <div className="homeRightView">
      <PageHeader />
      <div className="homeContent">
        <div className="homeContentContainer">
          <PageContentHeader title="Avatar">
            <Button onClick={() => genSeeds()}>Randomize</Button>
          </PageContentHeader>

          <div className="paddedContainer">
            <AvatarGrid>
              {avatars.map((seed) => (
                <Avatar
                  seed={seed}
                  onClick={() => {
                    updateIcon(seed);
                  }}
                />
              ))}
            </AvatarGrid>
          </div>
        </div>
      </div>
    </div>
  );
}
