import { APIUserKey, IServiceOptions } from "..";
import { BaseDataService } from "../BaseDataService";

export class APIUserKeyService extends BaseDataService<APIUserKey> {
  constructor() {
    super("UserKey");
  }

  async createKey(options?: IServiceOptions): Promise<any> {
    return await this.post("", undefined, options);
  }
}

const UserKeyService = new APIUserKeyService();

export default UserKeyService;
