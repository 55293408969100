import { ReactElement } from 'react';
import { LoadingStore } from '../../../store';
import LoadingModal from './LoadingModal';

export default function LoadingModalHolder(): ReactElement {
    const loadingText = LoadingStore( s => s.LoadingText )
    const setLoadingText = LoadingStore( s => s.setLoadingText )
    return(
    <>
    {loadingText !== '' && <LoadingModal text={loadingText} close={() => setLoadingText('')} />}
    </>
    )     
}