import React, { ReactElement, useEffect, useState } from "react";
import { FileService, FileType, initAbortController, isAbortError } from "@proviz/api-services";
import { SettableWidgetProperty } from "./SettableWidgetProperty";
import SetWidgetProperty from "../../../commands/SetWidgetProperty";
import Button from "../../common/button/Button";
import { useHandleAddResourceWidget } from "../handleAddResourceWidget";
import { BaseWidget, MultiLangOption } from "@proviz/proviz-sdk";
import { ManagerStore } from "../../../store";
import { ResourceManagerStore } from "../../../store/ResourceStore";

interface Props {
  languages: string[];
  property: SettableWidgetProperty;
  node?: BaseWidget;
  header: ReactElement;
  fileType: FileType;
  entityName?: string;
}
/*
* Render file properties that do have options for different languages.
*/
export const FileOptionsProperty = (props: Props) => {
  const { header, languages, property, fileType, entityName } = props;
  const [language, setLanguage] = useState(languages[0]);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [fileDict, setFileDict] = useState<MultiLangOption>(
    property.get()
  );
  const [file, setFile] = useState("Not Selected");
  const [fileLocation, setFileLocation] = useState<string | null>(null);
  const openFileSelect = ResourceManagerStore(s => s.openFileSelect) ?? { openFileSelect: undefined };
  const manager = ManagerStore((s) => s.GraphicsManager);
  const handleAddResourceWidget = useHandleAddResourceWidget()

  useEffect(() => {
    const abortController = initAbortController();
    async function updateFileInfo() {
      if (fileDict[language]) {
        try {
          const fileInfo = await FileService.get(fileDict[language], { abortController });
            setFileLocation(fileInfo.location);
            setFile(fileInfo.displayName ?? fileDict[language]);
        } catch (e) {
          if (isAbortError(e)) return; // if the query was aborted, do nothing
          setFile(`Error getting ${entityName} info`);
        }
      } else {
        setFile("Not selected.");
      }
    }
    updateFileInfo();
    return () => abortController.abort();
  }, [language, fileDict, entityName]);

  useEffect(() => {
    setFileDict(property.get());
  }, [property]);


  const selectResourceCallback = (id: string | null) => {
    let newDict = Object.assign({}, fileDict);
    let oldDict = Object.assign({}, fileDict);
    newDict[language] = id ?? '';
    if(!newDict[language]) {
      delete newDict[language]
    }
    manager.execute(new SetWidgetProperty(property, oldDict, newDict, manager));
    setFileDict(property.get());
    forceUpdate();
  }

  const handleChange = () => {
    openFileSelect &&
      openFileSelect(
        fileType,
        fileType === "Texture",
        selectResourceCallback
      );
  }

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    const resourceId = e.dataTransfer.getData("resource/id");
    const resourceType = e.dataTransfer.getData("resource/type");
    const resourceName = e.dataTransfer.getData("resource/name");
    if (resourceId && resourceType) {
      handleAddResourceWidget(selectResourceCallback, resourceType, resourceId, resourceName);
    }
  }


  return <div className="videoSelector">
      {header}
      {languages.length > 1 && (
        <select
          className="language-selector"
          value={language}
          onChange={(e) => setLanguage(e.target.value)} >
          {languages.map((l) => (
            <option key={l} value={l}>
              {l}
            </option>
          ))}
        </select>
      )}

      <table>
        <tbody>
          <tr>
            <td>
              <p>
                {entityName ?? fileType}: {file}
              </p>
            </td>
          </tr>
          {fileType === 'Audio' && fileLocation !== null && <tr>
            <td>
              <audio key={`audio-${property.name}-${props.node?.id}`} style={{width: "100%"}} controls={true}>
                <source src={fileLocation} />
              </audio>
            </td>
          </tr>}
          {fileType === 'Video' && fileLocation !== null && <tr>
            <td>
              <video key={`video-${property.name}-${props.node?.id}`} style={{width: "100%"}} controls={true}>
                <source src={fileLocation} />
              </video>
            </td>
          </tr>}
          {(fileType === 'Texture' || fileType === 'EnvMap') && fileLocation !== null && <tr>
            <td>
              <img key={`texture-${property.name}-${props.node?.id}`} style={{width: "100%"}} src={fileLocation} alt={file || ""} />
            </td>
          </tr>}
          <tr>
            <td onDrop={handleDrop}>
              <Button type='resource' fullWidth={true} onClick={handleChange} >
                Change
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>;
};
