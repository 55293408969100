import { getAbsoluteUrl } from '@proviz/proviz-sdk';
import { ReactElement } from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import './ViewSelector.css'

export class MenuOption {
    path: string;
    label: string;
    defaultRoute?: string;
    icon?: string;
    large?: boolean;
}

interface Props {
    header?: any;
    options: MenuOption[];
    children?: any;
}

const defaultThumbnail = getAbsoluteUrl('images/thumbnail.svg');

const Logo = styled.div`
    display: inline-block;
    width: 100%;
    margin: 1.5rem 0;
    cursor: pointer;
    img {
        width: 100%;
        height: 3.5em;
    }
`;

const MenuContainer = styled.div`
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 10rem;
    .inner {
        
    }
`;

export default function ViewSelector(props: Props): ReactElement {
    const history = useHistory();

    return <div className="homeLeftView">
        {props.header}
        <Logo>
            <NavLink to="/home">
                <img src={defaultThumbnail} />
            </NavLink>
        </Logo>

        <MenuContainer>
            <div className='inner'>
                <ul className="viewSelector" >
                    {props.options.map(({ path, label, defaultRoute, icon, large }) => <li key={label}
                        className={`viewSelectOption ${!large && (history.location.pathname.startsWith(path) || history.location.pathname === defaultRoute) ? 'selected' : ''} ${large ? 'large' : ''}`}
                        onClick={() => history.push(path)}>
                        {icon && <img src={icon} />} <div className="label">{label}</div>
                    </li>)}
                </ul>
            </div>
        </MenuContainer>
        {/* <ul className="viewSelector bottom" >
            <li
                className={`viewSelectOption ${(history.location.pathname === '/home/settings') ? 'selected' : ''}`}
                onClick={() => history.push('/home/settings')}>
                <span className='icon-person' /> <div className="label">Account</div>
            </li>
        </ul> */}
        {props.children}
    </div>;
}
