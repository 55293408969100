import { CanvasTexture, DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, Vector2 } from 'three';
import { ProVizScene } from '../../..';
import { getMediaFilePath } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { ProVizEventData } from '../../../ProVizEventData';

export class RadialMenuWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'radial-menu';
  private spriteNode?: Mesh<PlaneGeometry, MeshBasicMaterial>;

  // Data
  public imageSrc: string = '';
  public size: number = 500;
  public outerPadding: number = 24;
  public innerPadding: number = 90;
  public numberOfOptions: number = 5;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = RadialMenuWidget.type;
    this.widgetName = 'Radial Menu';
    this.label = 'Radial Menu';
    this.category = 'Experimental';
    this.selectable = true;
    this.events.push(
      {
        label: 'Clicked',
        name: 'clicked',
        desc: '<b>Triggered when the widget has been clicked anywhere</b>',
      },
      {
        label: 'Selected',
        name: 'selected',
        desc: '<b>Triggered when an option has been clicked</b><br /><i>Output: <b>number</b> (1...#)</i>',
      },
    );

    this.addEventListener('clicked', (event: ProVizEventData) => {
      if (event.dataType === 'vector2') {
        const interesection = event.data as Vector2;
        this.handleClick(1.0 - interesection.x, 1.0 - interesection.y);
      }
    });
  }

  private handleClick(x: number, y: number) {
    const halfSize = this.size / 2.0;

    const range = new Vector2((0.5 - x) * 2.0, -(0.5 - y) * 2.0);
    const length = range.length();

    const circlePaddingSize = this.outerPadding / 2.0 / this.size;

    const inCircle = length < 1.0 - circlePaddingSize;

    const innerNoSelection = this.innerPadding / 2.0 / halfSize;
    const isInSelection = length > innerNoSelection;
    const hasSelection = inCircle && isInSelection;

    if (hasSelection) {
      let angle = (Math.atan2(range.x, range.y) * 180) / Math.PI;
      if (angle < 0) {
        angle = 360 + angle;
      }
      const optionsRange = Math.floor(360 / this.numberOfOptions);
      const selection = Math.floor(angle / optionsRange) + 1;

      this.triggerProVizEvent('selected', 'number', selection);
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'imageSrc',
        '',
        'Core',
        'image-options',
        'string',
        true,
        undefined,
        (data: any) => {
          this.imageSrc = data;
          this.setupImage();
        },
      ),
      this.createProperty('numberOfOptions', 'Number of Options', 'Core', 'number', 'number', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.imageSrc = this.imageSrc;
    result.numberOfOptions = this.numberOfOptions;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.imageSrc = data.imageSrc;
    if (data.numberOfOptions) this.numberOfOptions = data.numberOfOptions;
  }

  private async downloadImageSrc(): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      const imgSrc = getMediaFilePath(this.imageSrc);
      img.setAttribute('src', imgSrc);
      img.setAttribute('crossorigin', 'anonymous');
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
    });
  }

  private async setupImage() {
    if (this.spriteNode) {
      this.renderNode.remove(this.spriteNode);
    }
    if (this.imageSrc) {
      this.renderNode.renderOrder = 2;

      // TODO: This can just be a normal image for now, no need to do a canvas unless we start creating the image runtime
      const img = await this.downloadImageSrc();
      const canvas = document.createElement('canvas');
      canvas.width = this.size;
      canvas.height = this.size;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Could not get canvas ctx');
        return;
      }
      ctx.drawImage(img, 0, 0, this.size, this.size);

      const canvasTexture = new CanvasTexture(canvas);

      const geometry = new PlaneGeometry();
      const material = new MeshBasicMaterial({
        map: canvasTexture,
        side: DoubleSide,
        transparent: true,
      });

      this.spriteNode = new Mesh(geometry, material);
      this.spriteNode.renderOrder = 3;
      this.renderNode.add(this.spriteNode);
    }
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.setupImage();
    return true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.spriteNode) {
      result.push(this.spriteNode);
    }
    return result;
  }
  public dispose(): void {
    super.dispose();
    this.spriteNode?.material.map?.dispose();
    this.spriteNode?.material.dispose();
    this.spriteNode?.geometry.dispose();
  }
}

ModuleService.Register(RadialMenuWidget.type, RadialMenuWidget);
