import { ReactElement, useState, useEffect } from 'react'
import { APIMaterialSet, APIModelVariant } from "@proviz/api-services";
import MaterialInfo from './MaterialInfo';
import MaterialSelector from './MaterialSelector'

interface Props {
    variantSelection: APIModelVariant;
    materialSet: APIMaterialSet;
    setMaterialSet: (ms: APIMaterialSet) => void;
}

export default function MaterialSubPanel(props: Props): ReactElement {
    const { materialSet, variantSelection, setMaterialSet } = props;
    const [matSelection, setMatSelection] = useState(-1)

    useEffect(() => {
        if(variantSelection) {
            if(variantSelection.materialSet.materials.length > 0) {
                setMatSelection(0);
            } else {
                setMatSelection(-1);
            }
        } else {
            setMatSelection(-1);
        }
    }, [variantSelection])

    const currentMat = matSelection !== -1 ? materialSet.materials[matSelection] : null;

    return <>
        <h6>Materials({materialSet.materials?.length}):</h6>
        <MaterialSelector materials={materialSet.materials}
                selection={matSelection}
                setSelection={setMatSelection} />
        {currentMat && 
            <MaterialInfo material={currentMat}
                materialSet={materialSet}
                setMaterialSet={setMaterialSet} />}
    </>
}
