import { ModuleService } from '../../../../moduleService';
import { ProVizScene } from '../../../../ProVizScene';
import { BaseWidget } from '../../../baseWidget';
import { BaseWidgetProperty } from '../../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../../IBaseWidgetType';
import { DatabaseWidget } from '../database/databaseWidget';

export class DatabaseCollectionWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'database-collection';

  // Data
  public databaseId: string | undefined = undefined;

  public collection: string | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Database Collection';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = DatabaseCollectionWidget.type;
    this.widgetName = 'Database Collection';
    this.selectable = true;
    this.events = [ ];

    this.services = [ ];

    this.addService('Database', 'database', 'Set the database for this collection', (event) => {
      if (event.dataType === 'widgetId') {
        const wdg = this.scene.getById(event.data as string);
        const databaseWdg = wdg as DatabaseWidget;
        this.databaseId = databaseWdg.databaseId;
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('collection', 'Collection', 'Data', 'string', 'string', true)
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.databaseId = this.databaseId;
    result.collection = this.collection;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.databaseId = data.databaseId ?? this.databaseId;
    this.collection = data.collection ?? this.collection;
  }
}

ModuleService.Register(DatabaseCollectionWidget.type, DatabaseCollectionWidget);
