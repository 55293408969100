import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ToggleWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'toggle';
  private static instanceCount = 0;

  // Data
  public initialValue: boolean = true;

  private state: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    if (!notInScene) {
      ToggleWidget.instanceCount++;
    }
    this.label = 'Toggle ' + ToggleWidget.instanceCount;

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = ToggleWidget.type;
    this.widgetName = 'Toggle';
    this.selectable = true;
    this.events = [
      {
        label: 'True',
        name: 'true',
      },
      {
        label: 'False',
        name: 'false',
      },
      {
        label: 'Value',
        name: 'value',
      },
    ];
    this.services = [
      {
        label: 'Toggle',
        name: 'toggle',
        desc: '<b>Flips the value back forth between True and False</b>',
      },
      {
        label: 'Set False',
        name: 'set-false',
        desc: '<b>Sets the Toggle to False</b>',
      },
      {
        label: 'Set True',
        name: 'set-true',
        desc: '<b>Sets the Toggle to True</b>',
      },
    ];
    this.addEventListener('service-toggle', () => this.toggle());
    this.addEventListener('service-set-false', () => this.setState(false));
    this.addEventListener('service-set-true', () => this.setState(true));
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('initialValue', 'Initial Value', 'Core', 'bool', 'boolean', true)];
  }

  public serialize(): any {
    const result = super.serialize();
    result.initialValue = this.initialValue;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.initialValue = data.initialValue;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    this.state = this.initialValue;
    return true;
  }

  public toggle() {
    this.setState(!this.state);
  }

  private setState(state: boolean) {
    this.state = state;
    this.emit();
  }

  private emit() {
    this.triggerProVizEvent(this.state ? 'true' : 'false', 'boolean', this.state);
    this.triggerProVizEvent('value', 'boolean', this.state);
  }
}

ModuleService.Register(ToggleWidget.type, ToggleWidget);
