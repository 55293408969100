import {
  BackSide,
  DoubleSide,
  FrontSide,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  ShadowMaterial,
} from 'three';
import { BaseWidgetProperty } from '../..';
import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../..';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

const parseSidedness = (s: string) => {
  switch (s) {
    case 'Double':
      return DoubleSide;
    case 'Back':
      return BackSide;
    case 'Front':
      return FrontSide;
    default:
      return DoubleSide;
  }
};

export class PlaneWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'plane';

  // Data
  public color: string = '#fff';
  public receiveShadow: boolean = true;
  public opacity: number = 1;
  public sidedness: string = 'Double';

  private plane?: Mesh<PlaneGeometry, ShadowMaterial>;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = PlaneWidget.type;
    this.widgetName = 'Plane';
    this.label = 'Plane';
    this.category = 'Core';
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.plane = this.createPlane();
    this.renderNode.add(this.plane);

    return true;
  }

  public dispose(): void {
    super.dispose();
    this.plane?.material.dispose();
    delete this.plane;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'color',
        'Color',
        'Core',
        'color',
        'string',
        true,
        undefined,
        (data: string) => {
          this.color = data;
          if (!this.plane) {
            console.error('Plane was not created');
            return;
          }
          this.plane.material.color.set(this.color);
        },
      ),
      this.createProperty(
        'receiveShadow',
        'Receive Shadow',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        (data: boolean) => {
          this.receiveShadow = data;
          if (!this.plane) {
            console.error('Plane was not created');
            return;
          }
          this.plane.receiveShadow = this.receiveShadow;
        },
      ),
      this.createProperty(
        'sidedness',
        'Side to render',
        'Core',
        'select',
        'number',
        true,
        undefined,
        (data: string) => {
          this.sidedness = data;
          if (!this.plane) {
            console.error('Plane was not created');
            return;
          }
          this.plane.receiveShadow = this.receiveShadow;
        },
        () =>
          ['Double', 'Back', 'Front'].map((x) => {
            return { key: x, label: x };
          }),
        'Which side to render in the scene.',
      ),
      this.createProperty(
        'opacity',
        'Opacity',
        'Core',
        'constrained-number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.opacity = data;
          if (!this.plane) {
            console.error('Plane was not created');
            return;
          }
          this.plane.material.opacity = this.opacity;
        },
        () =>
          [0, 1].map((x) => {
            return { key: x, label: x.toString() };
          }), // these are default vals now but we shouldn't rely on this for clarity
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.color = this.color;
    result.receiveShadow = this.receiveShadow;
    result.opacity = this.opacity;
    result.sidedness = this.sidedness;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.color = data.color;
    this.receiveShadow = data.receiveShadow;
    this.sidedness = data.sidedness;
    this.opacity = data.opacity;
  }

  private createPlane() {
    const { color, opacity, receiveShadow, sidedness } = this;
    const geometry = new PlaneGeometry(500, 500, 500, 500);
    const material = new MeshBasicMaterial({ opacity, side: parseSidedness(sidedness), color });
    const mesh = new Mesh(geometry, material);
    mesh.name = this.label;
    mesh.rotation.set(3.14 / 2, 0, 0);
    mesh.receiveShadow = receiveShadow;
    return mesh;
  }
}

ModuleService.Register(PlaneWidget.type, PlaneWidget);
