import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { useState } from 'react'
import { ManagerStore } from "../../../store";

const personIcon = getAbsoluteUrl('images/PersonIcon.svg');

export const ScaleReferenceButton = () => {
    const  manager  = ManagerStore(s => s.ModelViewManager);
    const [active, setActive] = useState(manager?.showScaleModel)
    const handleClick = () => {
        manager?.toggleShowScaleModel();
        setActive(manager?.showScaleModel)
    }

    return <div className='iconContainer'>
        <img className="icon"
            onClick={handleClick}
            src={personIcon}
            alt={active ? 'Hide Scale Reference' : 'Show Scale Reference'}
            title={active ? 'Hide Scale Reference' : 'Show Scale Reference' } />
    </div>;
}
