import React from 'react';
import { ResourceType } from '../../common/fileselect/useFileSelect';
import { TargetType } from '@proviz/api-services';

interface Props {
    name: string, 
    image: string,
    id: string,
    lastUpdated?: string,
    resourceType: ResourceType,
    targetType?: TargetType,
    onClick: (id: string) => void;
}

export const ResourceCard = (props: Props) => {
    const { name, image, id, onClick, resourceType, targetType } = props;

    const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLElement>) => {
        onClick(id);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragStart = (e: React.DragEvent<HTMLElement>) => {
        e.dataTransfer.setData('resource/id', id);
        e.dataTransfer.setData('resource/type', resourceType);
        e.dataTransfer.setData('resource/name', name);
        e.dataTransfer.setData('fromResourceCard', 'true');
        e.dataTransfer.setData('targetType', targetType || '');
        console.log('drag started');
    };

    return <>                                       
        <div className="widgetCard"
            draggable={true}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragStart={handleDragStart}
        >      
            <div className='widget-card-content' onClick={() => onClick(id)}>
                <div className='icon-container' style={{backgroundImage:`url('${image}')`}}>
                
                </div>
                <div className='widget-name'>
                    <div className='truncate-resource'>
                        {name}
                        {props.lastUpdated && <div className='last-updated'>{new Date(props.lastUpdated).toLocaleString()}</div>}
                    </div>
                </div>
            </div>
        </div>
    </>
}