import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidget } from '../../baseWidget';
import { ProVizScene } from '../../../ProVizScene';
import { ModuleService } from '../../../moduleService';
import { BaseWidgetProperty } from '../..';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { Vector3 } from 'three';
import { SceneMode } from '../../..';
import { clamp } from 'lodash';

export class PositionGroupWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'position-group';

  // Data
  public initialPosition: Vector3 = new Vector3();
  public endPosition: Vector3 = new Vector3();
  public movementDuration: number = 1;

  public isLooping: boolean = false;

  toggleMovement: boolean = false; //This is similar to isLooping but it winds up overriding the isLooping boolean so we don't have to rely on it to increment elapsedTime.
  isMoving: boolean = false;
  //This is used to control the amount of time between leeeeeeeerrrrrrrrps.
  elapsedTime: number = 0;
  moveToEndPosition: boolean = true;

  //These are solely used to control the movement direction services.
  startPositionFlag: boolean = false;
  endPositionFlag: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);

    this.label = 'Position Loop';
    this.widgetType = PositionGroupWidget.type;
    this.widgetName = 'Position Loop';

    this.selectable = true;
    this.events = [];

    this.category = 'Animation';

    this.services = [
      {
        label: 'Start movement',
        name: 'start-movement',
        desc: '<b>Starts the movement of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Stop movement',
        name: 'stop-movement',
        desc: '<b>Stops the movement of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Loop movement',
        name: 'loop-movement',
        desc: '<b>Starts looping the movement of this widget between the set distance over time.</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      //TODO: Create manual overrides for the person to click so they can move an object?
      {
        label: 'Return to original position',
        name: 'return-to-origin',
        desc: '<b>Starts looping the movement of this widget between the set distance over time.</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Toggle Loop',
        name: 'toggle-loop',
        desc: '<b>Starts and stops the looping the movement of this widget.',
      },
    ];

    this.addService(
      'Go to destination position',
      'go-to-destination',
      '<b>Starts looping the movement of this widget between the set distance over time.</b><br/><i>Will re-trigger if the same value is set again.</i>',
      () => {
        console.log('Go To Dest');
        this.isMoving = true;
        this.toggleMovement = true;
        this.moveToEndPosition = true;
        this.endPositionFlag = true;
      },
    );

    this.addEventListener('service-start-movement', () => {
      this.isMoving = true;
    });

    this.addEventListener('service-stop-movement', () => {
      this.isMoving = false;
    });

    this.addEventListener('service-loop-movement', () => {
      if (!this.isMoving) this.isMoving = true;
      this.isLooping = true;
    });

    this.addEventListener('service-return-to-origin', () => {
      this.isMoving = true;
      this.toggleMovement = true;
      this.moveToEndPosition = false;
      this.startPositionFlag = true;
    });

    this.addEventListener('service-toggle-loop', () => {
      const { x, y, z } = this.initialPosition;
      this.renderNode.position.set(x, y, z);
      this.elapsedTime = 0;
      this.isLooping = !this.isLooping;
      this.isMoving = this.isLooping;
      this.moveToEndPosition = this.isLooping;
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'endPosition',
        'Move Direction',
        'Core',
        'vec3',
        'vector3',
        true,
        undefined,
        undefined,
        undefined,
        'Setting this property sets how far the transition will go.',
      ),
      this.createProperty(
        'movementDuration',
        'Set Move Time',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Setting this property controls how long the translation will take in seconds before moving to the next destination.',
      ),
      this.createProperty(
        'isMoving',
        'Start Moving',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'By clicking this, you are enabling the starting and stopping of the widget position on load.',
      ),
      this.createProperty('isLooping', 'Loop Movement', 'Core', 'bool', 'boolean', true),
    ];
  }

  async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    this.initialPosition.copy(this.renderNode.position);
    return true;
  }

  public doMovement(delta: number): any {
    if (!this.isMoving) return;

    this.elapsedTime += this.moveToEndPosition ? delta : -delta;
    this.elapsedTime = clamp(this.elapsedTime, 0, this.movementDuration); //clamp the elapsed time to make sure it doesn't keep moving beyond the movementDuration limit.

    let alpha = this.elapsedTime / this.movementDuration;

    alpha = clamp(alpha, 0, 1);

    this.renderNode.position.lerpVectors(this.initialPosition, this.endPosition, alpha);

    if (
      ((this.isLooping || this.toggleMovement) && this.elapsedTime >= this.movementDuration) ||
      this.elapsedTime <= 0
    ) {
      this.toggleMovement = false;

      if (this.toggleMovement && (this.startPositionFlag || this.endPositionFlag)) {
        if (this.startPositionFlag) {
          this.moveToEndPosition = false;
          this.startPositionFlag = false;
        }

        if (this.endPositionFlag) {
          this.moveToEndPosition = true;
          this.endPositionFlag = false;
        }

        if (this.isLooping) this.isLooping = false;
      } else if (this.isLooping) {
        this.moveToEndPosition = !this.moveToEndPosition;
      }
    }
  }

  public update(delta: number): void {
    super.update(delta);
    if (this.scene.sceneMode === SceneMode.Editor) return;
    this.doMovement(delta);
  }

  public serialize(): any {
    const result = super.serialize();
    {
      const { x, y, z } = this.endPosition;
      result.endPosition = { x, y, z };
    }
    result.isMoving = this.isMoving;
    result.isLooping = this.isLooping;
    result.movementDuration = this.movementDuration;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.isMoving = data.isMoving;
    this.isLooping = data.isLooping;

    const { x, y, z } = data.endPosition;
    this.endPosition.set(x, y, z);

    this.movementDuration = data.movementDuration;
  }
}

ModuleService.Register(PositionGroupWidget.type, PositionGroupWidget);
