import { ShaderChunk, Euler, Quaternion, Matrix4, CubeTexture } from 'three';

const applyQuaternionToVector = `vec3 applyQuaternionToVector( vec4 q, vec3 v ) {
  return v + 2.0 * cross( q.xyz, cross( q.xyz, v ) + q.w * v );
}`;

interface ISKyboxShader {
  uniforms: {
    tCube: { type: string; value: CubeTexture | null };
    tFlip: { type: string; value: number };
    panoRotation: { type: string; value: any };
  };
  vertexShader: string;
  fragmentShader: string;
}

export const skyboxShader = (
  rotation: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 },
  texture: CubeTexture,
): ISKyboxShader => {
  const q = adjustedQuaternionFromEuler(rotation).normalize().invert();
  return {
    uniforms: {
      tCube: { type: 't', value: texture },
      tFlip: { type: 'f', value: -1 },
      panoRotation: { type: 'v4', value: q },
    },
    vertexShader: `varying vec3 vWorldPosition;
             varying vec3 rotationMatrix;
             ${ShaderChunk['logdepthbuf_pars_vertex']}
             void main() {
                vec4 worldPosition = modelMatrix * vec4( position, 1.0 ) ;
                vWorldPosition = worldPosition.xyz;
                vec4 pos = (projectionMatrix * modelViewMatrix) * worldPosition;
                gl_Position = pos.xyww;
                ${ShaderChunk['logdepthbuf_vertex']}
            }`,
    fragmentShader: `uniform samplerCube tCube;
             uniform float tFlip;
             varying vec3 vWorldPosition;
             uniform vec4 panoRotation;
             ${ShaderChunk['logdepthbuf_pars_fragment']}
             ${applyQuaternionToVector}
             void main() {
                vec3 adjustedPos = applyQuaternionToVector( panoRotation, vWorldPosition.xyz);
                gl_FragColor = textureCube( tCube, vec3( tFlip * adjustedPos.x, adjustedPos.yz ) );
                ${ShaderChunk['logdepthbuf_fragment']}
            }`,
  };
};

export const adjustedQuaternionFromEuler = (euler: { x: number; y: number; z: number }) => {
  const { x, y, z } = euler;
  const q = new Quaternion();
  q.setFromEuler(new Euler(x, 3.14 / 2.0 + y, z));
  return q;
};

export const zoomSkyboxShader = (
  rotation: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 },
) => {
  const q = adjustedQuaternionFromEuler(rotation);
  return {
    uniforms: {
      tCube: { type: 't', value: null },
      tFlip: { type: 'f', value: -1 },
      panoRotation: { type: 'v4', value: q },
      timeProjMatrix: { type: 'mat4', value: new Matrix4() },
    },
    vertexShader: `varying vec3 vWorldPosition;
             varying vec3 rotationMatrix;
             uniform vec4 panoRotation;
             uniform mat4 timeProjMatrix;
             ${ShaderChunk['logdepthbuf_pars_vertex']}
             ${applyQuaternionToVector}
             void main() {
                vec4 worldPosition = modelMatrix * vec4( position, 1.0 ) ;
                vWorldPosition = worldPosition.xyz;
                vec3 p = applyQuaternionToVector( panoRotation, position + cameraPosition);
                vec4 pos = (projectionMatrix * modelViewMatrix * timeProjMatrix) * vec4(p, 1.0);
                gl_Position = pos.xyww;
                ${ShaderChunk['logdepthbuf_vertex']}
            }`,
    fragmentShader: `uniform samplerCube tCube;
             uniform float tFlip;
             varying vec3 vWorldPosition;
             ${ShaderChunk['logdepthbuf_pars_fragment']}
             void main() {
                gl_FragColor = textureCube( tCube, vec3( tFlip * vWorldPosition.x, vWorldPosition.yz ) );
                ${ShaderChunk['logdepthbuf_fragment']}
            }`,
  };
};

export const fadeSkyboxShader = (
  originRotation: Quaternion = new Quaternion(),
  targetRotation: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 },
) => {
  const q1 = originRotation;
  const q2 = adjustedQuaternionFromEuler(targetRotation);
  q2.normalize().invert();
  return {
    uniforms: {
      originCube: { type: 't', value: null },
      targetCube: { type: 't', value: null },
      tFlip: { type: 'f', value: -1 },
      panoRotation1: { type: 'v4', value: q1 },
      panoRotation2: { type: 'v4', value: q2 },
      transition: { type: 'f', value: 0 },
    },
    vertexShader: `varying vec3 vWorldPosition;
             ${ShaderChunk['logdepthbuf_pars_vertex']}
             void main() {
                vec4 worldPosition = modelMatrix * vec4( position, 1.0 ) ;
                vWorldPosition = worldPosition.xyz;
                vec4 pos = (projectionMatrix * modelViewMatrix) * worldPosition;
                
                gl_Position = pos.xyww;
                ${ShaderChunk['logdepthbuf_vertex']}
            }`,
    fragmentShader: `uniform samplerCube originCube;
             uniform samplerCube targetCube;
             uniform float tFlip;
             varying vec3 vWorldPosition;
             uniform vec4 panoRotation1;
             uniform vec4 panoRotation2;
             uniform float transition;
             varying vec4 c1;
             varying vec4 c2;
             ${ShaderChunk['logdepthbuf_pars_fragment']}
             ${applyQuaternionToVector}
             void main() {
                vec3 p1 = applyQuaternionToVector( panoRotation1, vWorldPosition.xyz);
                vec3 p2 = applyQuaternionToVector( panoRotation2, vWorldPosition.xyz); 
                vec4 c1 = textureCube( originCube, vec3( tFlip * p1.x, p1.yz ) );
                vec4 c2 = textureCube( targetCube, vec3( tFlip * p2.x, p2.yz ) );
                gl_FragColor = mix(c1, c2,transition);
                ${ShaderChunk['logdepthbuf_fragment']}
            }`,
  };
};
