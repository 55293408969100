export class SuspendableTimeout {
  time: number = 0;
  private cancelled: boolean = false;
  private executed: boolean = false;
  private suspended: boolean = false;
  private cancelFn: () => void;
  // the fn passed in is ran only if not cancelled,
  // the cancel function is ran as long as cancel has been called at some point
  constructor(time: number, fn: () => void, cancelFn: () => void) {
    const callbackFn = () => {
      if (this.cancelled) {
        cancelFn();
      } else if (this.suspended) {
        this.suspended = false;
        setTimeout(callbackFn, time);
      } else {
        fn();
      }
      this.executed = true;
    };
    setTimeout(callbackFn, time);
    this.cancelFn = cancelFn;
  }

  cancel() {
    this.cancelled = true;
    if (this.executed) {
      this.cancelFn();
    }
  }

  suspend() {
    this.suspended = true;
  }
}

export const suspendableTimeout = (time: number, fn: () => void, cancelFn: () => void) => {
  return new SuspendableTimeout(time, fn, cancelFn);
};
