import { BasePositionableWidget } from "@proviz/proviz-sdk";
import { Position } from "../graphics/Types";
import Command from "./Command";

export default class SetNodePosition extends Command {
  oldPosition: Position;
  newPosition: Position;
  node: BasePositionableWidget;
  name = "Set Position";

  constructor(
    node: BasePositionableWidget,
    position: Position,
    oldPosition: Position
  ) {
    super();
    this.newPosition = {
      x: position.x || 0,
      y: position.y || 0,
      z: position.z || 0,
    };
    this.oldPosition = { x: oldPosition.x, y: oldPosition.y, z: oldPosition.z };
    this.node = node;
  }

  execute() {
    // Round to 3 digits after the decimal
    this.newPosition.x = Math.round(this.newPosition.x * 1000) / 1000;
    this.newPosition.y = Math.round(this.newPosition.y * 1000) / 1000;
    this.newPosition.z = Math.round(this.newPosition.z * 1000) / 1000;

    this.node.setPosition(this.newPosition);
  }

  undo() {
    this.node.setPosition(this.oldPosition);
  }
}
