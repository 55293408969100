import { ReactElement, useEffect, useState } from 'react';
import { TargetService, ProVizConfig, APITarget, initAbortController, isAbortError } from "@proviz/api-services";
import LoadingModal from '../../common/loadingmodal/LoadingModal';
import Modal from '../../common/modals/Modals';
import TargetCreateModal from '../targets/TargetCreateModal';
import Button from '../../common/button/Button';
import PagingControl from '../../common/paging/PagingControl';
import PageContentHeader from '../pageContentHeader';
import { searchStore } from '../../../store/SearchStore';

export default function TargetsView(): ReactElement {

    const [targets, setTargets] = useState<APITarget[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [failed, setFailed] = useState<boolean>(false);
    const [editTarget, setEditTarget] = useState<APITarget | null>(null);
    const [createTarget, setCreateTarget] = useState(false);
    const [waitingText, setWaitingText] = useState('');
    const [refreshModels, setRefreshModels] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const search = searchStore(x => x.search);

    useEffect(() => {
        const getUpdatedModelsCallback = () => {
            setRefreshModels(true);
        }
        ProVizConfig.events.addListener('impersonate-change', getUpdatedModelsCallback);
        return () => {
            ProVizConfig.events.removeListener('impersonate-change', getUpdatedModelsCallback);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        setRefreshModels(false);
        setTargets([]);
        const abortController = initAbortController();

        TargetService.paged(page, 20, search || undefined, { abortController }).then((pagedTargets) => {
            setTargets(pagedTargets.data);
            setTotalPages(pagedTargets.pagesTotal);
            setLoading(false);
        }).catch((e) => {
            if (isAbortError(e)) return; // if the query was aborted, do nothing
            console.error(e);
            setFailed(true);
        });

        return () => abortController.abort()
    }, [setTargets, setLoading, refreshModels, page, search]);

    if (failed) {
        return <h1>We're afraid something went wrong. Try again later.</h1>
    }
    return <>

        <PageContentHeader title="Targets">
            <div>
                <Button onClick={() => setCreateTarget(true)}>
                    <span className="icon icon-arrow-up"></span>
                    &nbsp;Create
                </Button>
            </div>
        </PageContentHeader>
        
        <div className="modelList">
            {loading && <div className="loading-indicator">Loading...</div>}
            <table>
                <thead>
                    <tr>
                        <th>
                            Target
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Uploaded
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {targets.map((target: APITarget) => <tr key={target.id}>
                        <td id={target.id}>
                            {target.name}
                        </td>
                        <td>
                            {(target.targetType as any) === 3 ? 'Area' : 'Model'}
                        </td>
                        <td>
                            {target.created}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>

        {createTarget && <TargetCreateModal close={() => { setCreateTarget(false); setRefreshModels(true); }} />}

        {editTarget && <Modal title={"Edit Target"} close={() => setEditTarget(null)}></Modal>}

        {/* {editTarget && <ModelInfoModal model={editTarget} close={() => setEditTarget(null)} refresh={() => setRefreshModels(true)}/> }
                */}
        {waitingText.length > 0 && <LoadingModal close={() => setWaitingText('')} text={waitingText} />}

        <div className='searchBar'>
            <PagingControl page={page} totalPages={totalPages} pageSet={(p: number) => setPage(p)} isFileSelect={false} />
        </div>
        
        </>;
}
