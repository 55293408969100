import { APISprite, SpriteService } from '@proviz/api-services';
import { DoubleSide, MeshBasicMaterial, RepeatWrapping, Texture, TextureLoader } from 'three';
import SpriteResource from './SpriteResource';

export default class AnimatedSpriteMaterial {
  private static instances: { [key: string]: AnimatedSpriteMaterial } = {};

  public static async InstanceFromId(spriteId: string) {
    if (AnimatedSpriteMaterial.instances[spriteId]) {
      return AnimatedSpriteMaterial.instances[spriteId];
    }
    const result = this.Instance(spriteId);
    SpriteService.get(spriteId).then((sprite: APISprite) => {
      const imageSrc = (sprite.file?.location ?? '').split('/').pop() || '';
      const spriteResource = new SpriteResource({
        id: sprite.id,
        imageSrc,
        verticalTiles: sprite.verticalTiles,
        horizontalTiles: sprite.horizontalTiles,
        framesPerSecond: 30,
        numberOfTiles: sprite.numberOfTiles,
      });
      result.setSprite(spriteResource);
    });
    return result;
  }

  public static Instance(id: string, spriteResource?: SpriteResource) {
    if (!AnimatedSpriteMaterial.instances[id]) {
      const material = new MeshBasicMaterial({
        side: DoubleSide,
        transparent: true,
      });
      AnimatedSpriteMaterial.instances[id] = new AnimatedSpriteMaterial(material);
    }
    const result = AnimatedSpriteMaterial.instances[id];
    if (spriteResource) result.setSprite(spriteResource);
    return result;
  }

  public static Update(delta: number) {
    Object.keys(AnimatedSpriteMaterial.instances).forEach((instance) =>
      AnimatedSpriteMaterial.instances[instance].update(delta),
    );
  }

  public material: MeshBasicMaterial;
  private texture?: Texture;
  private spriteResource?: SpriteResource;

  private frame: number = 0;
  private displayTime: number = 0;

  constructor(meshBasicMaterial: MeshBasicMaterial) {
    this.material = meshBasicMaterial;
  }

  public setSprite(spriteResource: SpriteResource) {
    this.spriteResource = spriteResource;

    const textureLoader = new TextureLoader();
    this.texture = textureLoader.load(this.spriteResource!.getImageLocation(), () => {});
    this.texture.wrapS = this.texture.wrapT = RepeatWrapping;
    this.texture.repeat.set(
      1 / this.spriteResource!.horizontalTiles,
      1 / this.spriteResource!.verticalTiles,
    );

    this.material.map = this.texture;
  }

  public update(delta: number) {
    if (!this.spriteResource || !this.texture) return;

    this.displayTime += delta * 1000;
    const { horizontalTiles, verticalTiles, framesPerSecond, numberOfTiles } = this.spriteResource;
    const displayDuration = 1000 / framesPerSecond;
    while (this.displayTime > displayDuration) {
      this.displayTime -= displayDuration;
      this.frame++;

      if (this.frame == numberOfTiles) {
        this.frame = 0;
      }

      const currentColumn = this.frame % horizontalTiles;
      this.texture.offset.x = currentColumn / horizontalTiles;
      const currentRow = Math.floor(this.frame / horizontalTiles);
      this.texture.offset.y = 1 - currentRow / verticalTiles;
    }
  }
}
