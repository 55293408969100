
import { ReactElement } from 'react'
import PageHeader from '../home/pageHeader';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PermissionEntity, PermissionType, RoleService } from "@proviz/api-services";
import { useParams } from 'react-router-dom';
import { APIRole } from '@proviz/api-services/lib/models/Role';

export default function RoleEditView(): ReactElement {
    const params = useParams<{ id: string }>();

    const { isLoading, error, data } = useQuery<Promise<APIRole>, undefined, APIRole, [string, string]>([ 'roles', params.id ],  (
        { queryKey: [ key, id ] }) => {
        return RoleService.get(id);
    });

    const permissionEntities: PermissionEntity[] = [];
    for(let i = 0; i < PermissionEntity.Length; i++) {
        permissionEntities.push(i);
    }

    permissionEntities.sort((a, b) => {
        if (PermissionEntity[a] > PermissionEntity[b]) {
            return 1;
        }
        if (PermissionEntity[a] < PermissionEntity[b]) {
            return -1;
        }
        return 0;
    });

    const queryClient = useQueryClient();

    const getPermissionRow = (entity: PermissionEntity) => {

        const rolePermission = data?.permissions.find(x => x.permissionEntity === entity);
        const hasView = !!rolePermission?.permissionTypes.find(x => x === PermissionType.View);
        const hasEdit = !!rolePermission?.permissionTypes.find(x => x === PermissionType.Edit);
        const hasDelete = !!rolePermission?.permissionTypes.find(x => x === PermissionType.Delete);

        const changePermission = async (permType: PermissionType) => {
            // If we have the permission, remove it, Otherwise add it

            const hasPerm = !!rolePermission?.permissionTypes.find(x => x === permType);
            console.log(hasPerm);

            if (data) {
                if (hasPerm) {
                    await RoleService.removePermission(data.id, entity, permType);
                } else {
                    await RoleService.addPermission(data.id, entity, permType);
                }
                queryClient.invalidateQueries(['roles', params.id]);
            }
        }
        
        return <tr>
            <td>
                {PermissionEntity[entity]}
            </td>
            <td>
                <input type="checkbox" checked={hasView} onChange={(e) => { changePermission(PermissionType.View); }} />
            </td>
            <td>
                <input type="checkbox" checked={hasEdit} onChange={(e) => { changePermission(PermissionType.Edit); }} />
            </td>
            <td>
                <input type="checkbox" checked={hasDelete} onChange={(e) => { changePermission(PermissionType.Delete); }} />
            </td>
        </tr>
    }

    if (error) {
        return <h1>We're afraid something went wrong. Try again later.</h1>
    }

    return <div className="homeRightView">
        <PageHeader />
        <div className='homeContent'>
            <div className='homeContentContainer'>
                <div className='paddedContainer'>
                    <h1>Role Permissions</h1>
                    {!isLoading && <div>
                        <h3>{data?.name}</h3>
                        <table>
                            <thead>
                                <th>
                                    Permission
                                </th>
                                <th>
                                    View
                                </th>
                                <th>
                                    Create
                                </th>
                                <th>
                                    Delete
                                </th>
                            </thead>
                            <tbody>
                                {permissionEntities.map((permissionEntity => getPermissionRow(permissionEntity)))}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
    </div>;
}
