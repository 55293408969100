import { Object3D } from "three";
import { TransformMode } from "./ProVizTransformControlsGizmo";

export class GizmoGroup {
  translate: Object3D;
  rotate: Object3D;
  scale: Object3D;

  constructor(translate: Object3D, rotate: Object3D, scale: Object3D) {
    this.translate = translate;
    this.rotate = rotate;
    this.scale = scale;
  }

  mode(mode: TransformMode) {
    switch (mode) {
      case "translate":
        return this.translate;
      case "rotate":
        return this.rotate;
      case "scale":
        return this.scale;
      default:
        return this.translate;
    }
  }

  modeChildren(mode: TransformMode) {
    switch (mode) {
      case "translate":
        return this.translate.children;
      case "rotate":
        return this.rotate.children;
      case "scale":
        return this.scale.children;
      default:
        return this.translate.children;
    }
  }
}
