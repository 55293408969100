import Command from "./Command";
import { Scale } from "../graphics/Types";
import { BasePositionableWidget } from "@proviz/proviz-sdk";

export default class SetNodeScale extends Command {
  oldScale: Scale;
  newScale: Scale;
  node: BasePositionableWidget;
  name = "Scale Model";

  constructor(node: BasePositionableWidget, scale: Scale, oldScale: Scale) {
    super();
    this.newScale = { x: scale.x, y: scale.y, z: scale.z };
    this.oldScale = { x: oldScale.x, y: oldScale.y, z: oldScale.z };
    this.node = node;
  }

  execute() {
    this.node.setScale(this.newScale);
  }

  undo() {
    this.node.setScale(this.oldScale);
  }
}
