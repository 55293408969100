import { ReactElement, useEffect } from "react";
import Header from "./common/header/Header";
import { useAuth0 } from "@auth0/auth0-react";
import AuthButton from "./common/auth/AuthButton";
import { useHistory } from "react-router-dom";

export enum HomeViews {
  Models,
  Collections,
}

export default function SignInPage(): ReactElement {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/home");
    }
  });

  return (
    <div className="home">
      <Header>
        <AuthButton />
      </Header>
      <h3 className="loginTitle">Log in to access your models.</h3>
    </div>
  );
}
