import {
  BackSide,
  BoxGeometry,
  Color,
  CubeTexture,
  Fog,
  Mesh,
  PerspectiveCamera,
  Scene,
  ShaderMaterial,
  Texture,
  TextureLoader,
  WebGLCubeRenderTarget,
} from "three";
import { FileService } from "@proviz/api-services";
import { ProVizLoader, ProVizScene, skyboxShader } from "@proviz/proviz-sdk";
import GraphicsManager from "./GraphicsManager";

export type BackgroundType = "color" | "img";
function isCubeTexture(t: any): t is CubeTexture {
  return t.isCubeTexture === true;
}

export default class Background {
  manager: GraphicsManager;
  color: string = "#d0d0d0";
  img: string = "";
  backgroundType: BackgroundType;
  loader = new TextureLoader();
  private skyboxRemove?: () => void;
  private mapId?: string;
  private skyboxGeometry?: BoxGeometry;
  constructor(manager: GraphicsManager) {
    this.manager = manager;
    this.manager.scene.background = new Color(this.color);
    this.backgroundType = "color";
  }

  applySceneSettings(proVizScene: ProVizScene) {
    this.setBackgroundColor(proVizScene.backgroundColor);
    this.setFog(proVizScene);
    this.setEnvMap(proVizScene.envMapId);
  }

  setFog(provizScene: ProVizScene) {
    const { fogOn, fogNear, fogFar, fogColor } = provizScene;
    if (!fogOn) {
      this.manager.scene.fog = null;
    } else {
      this.manager.scene.fog = new Fog(new Color(fogColor), fogNear, fogFar);
    }
  }

  setEnvMap(mapId: string) {
    if (!mapId) {
      console.warn("using empty environment map");
      this.manager.scene.environment = null;
      this.mapId = undefined;
      return;
    }
    this.mapId = mapId;
    FileService.get(mapId)
      .then(async (f) => {
        if (this.mapId !== mapId) {
          // if map has been set to something else, return;
          return;
        }
        const mapSrc = f.location;

        const generateAndSetEnv = async () => {
          if (!this.manager.renderer.renderer) {
            requestAnimationFrame(() => {
              generateAndSetEnv();
            });
            return;
          }
          const map = await ProVizLoader.getCubeMapTexture(
            mapSrc,
            this.manager.renderer.renderer
          );
          if (this.mapId !== mapId) {
            // if map has been set to something else, return;
            return;
          }
          this.manager.scene.environment = map;
          this.manager.render();
        };

        generateAndSetEnv();
      })
      .catch((e) => {
        console.error("Env map Did not work", e);
        this.manager.scene.environment = null;
      });
  }

  setBackgroundColor(color: string) {
    if (this.skyboxRemove) {
      this.skyboxRemove();
    }
    this.backgroundType = "color";
    this.color = color;
    this.img = "";
    this.manager.scene.background = new Color(color);
    this.manager.dispatchBackgroundUpdate();
  }

  setBackgroundImg(img: string) {
    this.backgroundType = "img";
    this.img = img;
    this.color = "";
    if (this.skyboxRemove) {
      this.skyboxRemove();
    }
    this.loadTexture(img)
      .then((renderTarget) => {
        if (renderTarget) {
          this.manager.scene.background = null;
          const skybox = this.createSkybox(renderTarget.texture, undefined);
          const scene = this.manager.scene;
          this.skyboxRemove = () => {
            requestAnimationFrame(() => {
              scene.remove(skybox);
              this.manager.render();
            });
          };
          scene.add(skybox);
          this.manager.dispatchBackgroundUpdate();
        } else {
          console.error("No render target created");
        }
      })
      .catch((e) => {
        console.error(e, e.message);
        this.manager.dispatchBackgroundUpdate();
      });
  }

  private async loadTexture(img: string): Promise<WebGLCubeRenderTarget> {
    const renderer = this.manager.renderer.renderer;
    const loader = this.loader;
    return new Promise((resolve, reject) => {
      if (!renderer) {
        console.error("no renderer");
        reject();
      }
      loader.load(
        img,
        (texture) => {
          const renderTarget = new WebGLCubeRenderTarget(texture.image.height);
          renderTarget.fromEquirectangularTexture(renderer!, texture);
          resolve(renderTarget);
        },
        undefined,
        (e) => reject(e)
      );
    });
  }

  setSceneEditorBackground(
    texture: Texture | CubeTexture | any | undefined,
    proVizScene: ProVizScene,
    camera: PerspectiveCamera,
    scene: Scene
  ) {
    if (this.skyboxRemove) {
      this.skyboxRemove();
    }
    if (texture) {
      if (!isCubeTexture(texture)) {
        const rt = new WebGLCubeRenderTarget(texture.image.height);
        rt.fromEquirectangularTexture(this.manager.renderer.renderer, texture);
        texture = rt.texture;
      }
      const skybox = this.createSkybox(
        texture,
        proVizScene.perspectiveRotation
      );
      skybox.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
      this.skyboxRemove = () => {
        requestAnimationFrame(() => {
          scene.remove(skybox);
        });
      };
      this.manager.scene.background = null;
      scene.add(skybox);
      this.manager.dispatchBackgroundUpdate();
    } else if (!texture) {
      this.skyboxRemove = undefined;
      this.manager.scene.background = new Color(this.color);
    }
  }

  private createSkybox(
    texture: CubeTexture,
    rotation?: { x: number; y: number; z: number }
  ) {
    if (!this.skyboxGeometry) {
      this.skyboxGeometry = new BoxGeometry(1000, 1000, 1000);
    }
    const shader = skyboxShader(rotation, texture);
    const skyBoxMaterial = new ShaderMaterial({
      fragmentShader: shader.fragmentShader,
      vertexShader: shader.vertexShader,
      uniforms: shader.uniforms,
      depthWrite: false,
      depthTest: false,
      side: BackSide,
    });
    const skybox = new Mesh(this.skyboxGeometry, skyBoxMaterial);
    skybox.renderOrder = 0;
    skybox.frustumCulled = false;
    return skybox;
  }
}
