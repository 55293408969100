import { BaseWidget, BaseWidgetProperty, } from "@proviz/proviz-sdk";
import { useState } from 'react'
import ModelViewManager from '../../../graphics/ModelViewManager';
import SceneManager from '../../../graphics/SceneManager';
import CollapsibleHeaderIcon from "../../common/collapsiblegroup/CollapsibleHeader";
import PropertyRenderer from './PropertyRenderer';

interface Props {
    category: string;
    properties: BaseWidgetProperty[];
    node?: BaseWidget;
    manager: ModelViewManager | SceneManager;
    supressedProperties?: string[];
}

const StartExpanded = ['Core', 'Data']

export const CategoryRenderer = (props: Props) => {
    const { category, properties } = props;
    const [expand, setExpand] = useState(StartExpanded.includes(category));

    return <div>
        <div className="collapsible-properties-header">
            <h5>{category}</h5>
            <CollapsibleHeaderIcon handleClick={() => setExpand(!expand)} expanded={expand} />
        </div>
        <ul>
            {expand && <PropertyRenderer
                properties={properties}
                node={props.node}
                manager={props.manager}
                supressedProperties={props.supressedProperties}
            />}
        </ul>
    </div>;
}
