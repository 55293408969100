import { useEffect, useRef, useState } from "react";
import { Prompt, useParams } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import Split from "react-split";

import { SceneService } from "@proviz/api-services";
import { BaseWidget, AppParamWidget } from "@proviz/proviz-sdk";

import SceneManager, { Layout } from "../../graphics/SceneManager";
import { TokenProps } from "../../auth/WithToken";
import SceneName from "./SceneName";
import FlowEditor from "./flow/FlowEditor";
import PanoramaButton from "./PanoramaButton";
import NodeSelector from "./nodeselector/NodeSelector";

import NodeProperties from "./propertypanel/NodeProperties";
import PublishButton from "./propertypanel/PublishButton";

import SceneEditorBottomContainer from "./bottommenu/SceneEditorBottomContainer";
import ErrorBoundary from "../../errorbounds/ErrorBoundary";

import { ManagerStore } from "../../store";

import {
  Header,
  SaveButton,
  HistoryControls,
  SceneShareButton,
  LoadingModalHolder,
  GridButton,
  EditorToolbar,
  LayoutButton,
  InfoModal,
} from "../common";

// enum EditorState {
//   Ok,
//   Failed,
// }

type Params = { id: string };

interface Props extends RouteComponentProps<Params>, TokenProps {}

export const SceneEditor = (props: Props) => {
  /****************************************************************** variables */
  // Defaults
  const gutterSize = 5;
  const minsize = 100;

  // Hooks
  const { id: sceneId } = useParams<{ id: string }>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Store
  const setSceneManager = ManagerStore((s) => s.setSceneManager);

  // States
  const [manager, ] = useState<SceneManager>(
    new SceneManager(sceneId, Layout.EditorOnly)
  );

  const [layout, setLayout] = useState<Layout>(Layout.Split);
  const [bottomPanelHeight, ] = useState<number>(275);
  const [sceneSaved, setSceneSaved] = useState<boolean>(true);
  const [, setLoading] = useState<boolean>(true);

  // const [state, setState] = useState<EditorState>(EditorState.Ok);
  // const [fileNotTexture, setFileNotTexture] = useState<boolean>(false);
  // const [fileType, setFileType] = useState<FileType | "Scene" | "Sprite">(
  //  "Texture" /
  //);

  /****************************************************************** functions */
  // Set Manager to store
  setSceneManager(manager);

  // Get App Params
  const getAppParams = (): string[] => {
    const widgets = manager.proVizScene.getWidgetsOfType("app-param");
    return widgets.map((widget: BaseWidget) => {
      const p = widget as AppParamWidget;
      return p.param;
    });
  };

  // Publish Scene
  const publish = async (state: boolean) => {
    const scene = await SceneService.publish(manager.apiScene.id, state);
    manager.apiScene = scene;
  };

  /******************************************************************** useEffects */
  useEffect(() => {
    manager?.resize();
  }, [layout, manager]);

  useEffect(() => {
    if (!containerRef.current || !manager) return;

    const init = async () => {
      let scene = await SceneService.getDraft(sceneId);
      await manager.init(scene);
      setLoading(false);
    };

    manager.connectToDom(containerRef.current);
    manager.resize();
    init();
    window.addEventListener("resize", () => manager.resize());
    return window.removeEventListener("resize", () => manager.resize());
    // eslint-disable-next-line
  }, [containerRef, manager]);

  useEffect(() => {
    if (!manager) return;
    manager.attach("layout-update", () => setLayout(manager.layout));
    manager.postUpdateTask = () => setSceneSaved(false);
    // eslint-disable-next-line
  }, [manager]);

  /****************************************************************** Render return */
  return (
    <>
        <Header centerTitle={<SceneName />}>
          <HistoryControls manager={manager} visible={true} />
          <SaveButton sceneSaveHandler={() => setSceneSaved(true)} />
          <PublishButton publish={() => publish(true)} />
          {manager && manager.apiScene && (
            <SceneShareButton
              sceneId={manager.id}
              title={manager.apiScene.name}
              appParams={() => getAppParams()}
            />
          )}
        </Header>
        <div className="editorContainer">
          <div className="interiorEditorContainer">
            <Split
              className="split"
              gutterSize={gutterSize}
              sizes={[80, 20]}
              onDragEnd={() => manager.resize()}
            >
              <Split
                className="split vertical"
                gutterSize={gutterSize}
                minSize={minsize}
                direction="vertical"
                sizes={[74, 26]}
                onDragEnd={() => manager.resize()}
              >
                <div className="editorAndFlowContainer">
                  <Split
                    className="split"
                    sizes={[70, 30]}
                    minSize={minsize}
                    gutterSize={layout === Layout.EditorOnly ? 0 : gutterSize}
                    onDragEnd={() => manager.resize()}
                  >
                    <ErrorBoundary>
                      <div className="canvasContainer" ref={containerRef}>
                        <PanoramaButton />
                        <EditorToolbar>
                          <GridButton manager={manager} />
                          <LayoutButton
                            layout={layout}
                            setLayout={(l) => manager?.changeLayout(l)}
                          />
                          <InfoModal />
                        </EditorToolbar>
                      </div>
                    </ErrorBoundary>
                    <div
                      className={`flow-container ${
                        layout === Layout.Split ? "split" : ""
                      }`}
                    >
                      {manager.proVizScene && <FlowEditor manager={manager} />}
                    </div>
                  </Split>
                </div>
                <div
                  className="bottomPanel"
                  style={{ height: bottomPanelHeight }}
                >
                  <SceneEditorBottomContainer />
                </div>
              </Split>
              <div className="controlPanel">
                <Split
                  className="split vertical"
                  direction="vertical"
                  sizes={[50, 50]}
                >
                  <NodeSelector />
                  <NodeProperties />
                </Split>
              </div>
            </Split>
          </div>
        </div>
        <Prompt
          when={!sceneSaved}
          message="You have unsaved changes, are you sure you want to leave?"
        />
      <LoadingModalHolder />
      </>
  );
};

export default SceneEditor;
