import styled from "styled-components";

const StyledBlock = styled.div`
    padding: 1rem;
`;

export default function PaddedContainer(props: { children?: any }) {
    return <StyledBlock>
        {props.children}
    </StyledBlock>
}