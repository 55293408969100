import {
  APIFolder,
  FolderService,
  FolderType,
  ModelService,
  ProVizConfig,
  SceneService,
} from "@proviz/api-services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactElement, useEffect, useState } from "react";
import FolderSettingsModal from "./FolderSettingsModal";

interface Props {
  folderType: FolderType;
  onFolderChange: (folder: APIFolder | null) => void;
}

const folderSort = (aFolder: APIFolder, bFolder: APIFolder) => {
  const aName = aFolder.name?.toLowerCase() || "";
  const bName = aFolder.name?.toLowerCase() || "";
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export default function FoldersList(props: Props): ReactElement {
  const [currentFolder, setCurrentFolder] = useState<APIFolder | null>(null);
  const [showFolderSettings, setShowFolderSettings] = useState(false);

  const { data } = useQuery<
    Promise<APIFolder[]>,
    undefined,
    APIFolder[],
    [string, string | undefined]
  >(
    [`folders-${props.folderType}`, currentFolder?.id],
    ({ queryKey: [, currentFolderId] }) => {
      if (currentFolderId) {
        return FolderService.getFolders(currentFolderId);
      }
      return FolderService.getFoldersByType(props.folderType, null);
    },
    {
      select: (data) => {
        const d: any = data;
        const result = d as APIFolder[];
        return result.sort(folderSort);
      },
      refetchOnWindowFocus: true,
    }
  );

  /*******************
   * Update useQuery change via Listener
   * for 'impersonate-change' event
   */
  const queryClient = useQueryClient();
  const getUpdatedFoldersCallback = () => {
    setCurrentFolder(null);
    queryClient.invalidateQueries([
      `folders-${props.folderType}`,
      currentFolder?.id,
    ]);
  };

  useEffect(() => {
    ProVizConfig.events.addListener(
      "impersonate-change",
      getUpdatedFoldersCallback
    );
    return () => {
      ProVizConfig.events.removeListener(
        "impersonate-change",
        getUpdatedFoldersCallback
      );
    };
  }, []);

  const handleDragEmpty = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div className="folders-list">
        {currentFolder != null && (
          <div className="folder active">
            <div
              className="button"
              onClick={() => {
                setCurrentFolder(currentFolder.parentFolder);
                props.onFolderChange(currentFolder.parentFolder);
              }}
              onDragOver={(e) => handleDragEmpty(e)}
              onDragEnter={(e) => handleDragEmpty(e)}
              onDragLeave={(e) => handleDragEmpty(e)}
              onDrop={(e) => {
                const sceneId = e.dataTransfer.getData("scene/id");
                const modelId = e.dataTransfer.getData("model/id");
                const folderId = e.dataTransfer.getData("folder/id");
                if (sceneId !== null && sceneId !== "") {
                  SceneService.setFolder(
                    sceneId,
                    currentFolder.parentFolder?.id || null
                  );
                } else if (modelId !== null && modelId !== "") {
                  ModelService.setFolder(
                    modelId,
                    currentFolder.parentFolder?.id || null
                  );
                } else if (folderId !== null && folderId !== "") {
                  const folderName = e.dataTransfer.getData("folder/name");
                  FolderService.update(
                    folderId,
                    folderName,
                    currentFolder.parentFolderId
                  );
                }
              }}
            >
              <span className="control">{"<"}</span>
            </div>
            <div
              className="current"
              onClick={() => {
                setShowFolderSettings(true);
              }}
            >
              <span
                className={`icon ${
                  !currentFolder.companyId
                    ? "icon-object-icon"
                    : "icon-folder-icon"
                }`}
              ></span>
              {currentFolder.name}
              {!currentFolder.companyId && (
                <span className="global">Global</span>
              )}
            </div>
          </div>
        )}
        {data?.map((folder: APIFolder) => {
          return (
            <div
              className={`folder ${!folder.companyId ? "global" : ""}`}
              onDragOver={(e) => handleDragEmpty(e)}
              onDragEnter={(e) => handleDragEmpty(e)}
              onDragLeave={(e) => handleDragEmpty(e)}
              onDragStart={(e) => {
                e.dataTransfer.setData("folder/id", folder.id);
                e.dataTransfer.setData("folder/name", folder.name || "");
              }}
              onDrop={(e) => {
                const sceneId = e.dataTransfer.getData("scene/id");
                const modelId = e.dataTransfer.getData("model/id");
                const folderId = e.dataTransfer.getData("folder/id");
                console.log("Folder dropped on", sceneId);
                if (sceneId !== null && sceneId !== "") {
                  console.log("Set", sceneId, "to folder", folder.id);
                  SceneService.setFolder(sceneId, folder.id);
                } else if (modelId !== null && modelId !== "") {
                  console.log("Set", modelId, "to folder", folder.id);
                  ModelService.setFolder(modelId, folder.id);
                } else if (folderId !== null && folderId !== "") {
                  console.log("Set folder to folder");
                  const folderName = e.dataTransfer.getData("folder/name");
                  FolderService.update(folderId, folderName, folder.id);
                }
              }}
              draggable={true}
              onClick={() => {
                setCurrentFolder(folder);
                props.onFolderChange(folder);
              }}
            >
              <span
                className={`icon ${
                  !folder.companyId ? "icon-object-icon" : "icon-folder-icon"
                }`}
              ></span>
              {folder.name}
              {!folder.companyId && <span className="global">Global</span>}
            </div>
          );
        })}
      </div>

      {showFolderSettings && currentFolder && (
        <FolderSettingsModal
          folder={currentFolder}
          close={() => {
            setShowFolderSettings(false);
          }}
          onDelete={() => {
            setShowFolderSettings(false);
            setCurrentFolder(currentFolder.parentFolder);
            props.onFolderChange(currentFolder.parentFolder);
          }}
        />
      )}
    </>
  );
}
