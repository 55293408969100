import { ReactElement, useEffect, useState } from 'react'
import { AccessKeyService, initAbortController, isAbortError, ProVizConfig } from "@proviz/api-services";
import FormContainer from '../common/formcontainer';
import Button from '../common/button/Button';
import PageHeader from '../home/pageHeader';

export default function AccessKeysView(): ReactElement {
    const [failed, setFailed] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [role, setRole] = useState<number>(1);
    const [updateKeys, setUpdateKeys] = useState(false);

    const [keys, setKeys] = useState<any[]>([]);


    useEffect(() => {
        const updateKeysCb = () => setUpdateKeys(true);
        ProVizConfig.events.addListener('impersonate-change', updateKeysCb);
        return () => {
            ProVizConfig.events.removeListener('impersonate-change', updateKeysCb);
        }
    }, []);

    useEffect(() => {
        if (updateKeys) {
            const abortController = initAbortController();
            AccessKeyService.getAll({ abortController })
                .then((keys) => {
                    setKeys(keys);
                    setUpdateKeys(false);
                })
                .catch((e) => {
                    if (isAbortError(e)) return; // if the query was aborted, do nothing
                    console.error(e);
                    setFailed(true);
                });
            return () => {
                abortController.abort();
            }
        }
        return () => { }
    }, [updateKeys])

    useEffect(() => {
        setUpdateKeys(true);
        setFailed(false);
    }, []);

    let createAccessKey = () => {
        if (name === '') {
            return;
        }
        AccessKeyService.createKey(name, role)
        .then(() => {
            setName('');
            setUpdateKeys(true);
        });
    };

    let deleteKey = (key: string) => {
        AccessKeyService.delete(key).then(() => {
            setUpdateKeys(true);
        });
    }

    if (failed) {
        return <h1>We're afraid something went wrong. Try again later.</h1>
    }
    return <div className="homeRightView">

    <PageHeader />

    <div className='homeContent'>
        <div className='homeContentContainer'>
            <div className='paddedContainer'>
        <div className="userList">
            <div>

                <FormContainer title="Create New Access Token">
                    <table className="inner">
                        <tbody>
                            <tr>
                                <td>Key Name</td>
                                <td>
                                    <input className="provizInputBox" type="text" onChange={(e) => setName(e.target.value)} placeholder="Access Token Name" />
                                </td>
                            </tr>
                            <tr>
                                <td>Role</td>
                                <td>
                                    <select className="provizInputBox" onChange={(e) => setRole(parseInt(e.target.value))}>
                                        <option value={1}>User</option>
                                        <option value={2}>Admin</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <Button onClick={() => createAccessKey()}>Create Access Key</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </FormContainer>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Key</th>
                        <th>Role</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {keys.map((key) => <tr key={key.key}>
                        <td>{key.name}</td>
                        <td>{key.key}</td>
                        <td>{key.userRole}</td>
                        <td>
                            <Button fullWidth={true} onClick={() => deleteKey(key.key)}>Delete</Button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
        </div>
        </div>
    </div>;
}
