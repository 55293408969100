import { WidgetDefinition } from '../../baseWidget';

export const ImageWidgetDefinition: WidgetDefinition = {
  label: 'Image',
  type: 'image',
  // name is used as Label and then converted to the event type by using only lowercase letters
  // and converting spaces to -'s. Example: 'Clicked' -> 'clicked
  events: [
    {
      label: 'Clicked',
      dataType: 'click',
    },
  ],

  // name is used as label and converted to 'set-audio'
  services: [
    {
      label: 'Set Scale',
      dataType: 'vector3',
    },
    {
      label: 'Set Image',
      dataType: 'string',
    },
  ],

  // Data property name converts to camel case 'textContent'.
  // Serialization is setup by the base widget constructor
  properties: [
    {
      key: 'fileIds',
      label: 'File IDs',
      category: 'Core',
      dataType: 'image-options',
    },
    {
      label: 'Clickable',
      category: 'Interaction',
      dataType: 'bool',
    },
    {
      label: 'Opacity',
      category: 'Core',
      dataType: 'constrained-number',
    },
    {
      label: 'Image File Name',
      category: 'Experimental',
      dataType: 'multi-lang-opts',
      infoText: 'For backwards compatability',
    },
    {
      label: 'Transparent',
      category: 'Core',
      dataType: 'bool',
    },
    {
      key: 'overrideFileIdSources',
      label: 'Override File Source',
      category: 'Experimental',
      dataType: 'bool',
      infoText:
        'Turn on to Override file source and supply your own url. (For backwards compatability)',
    },
    {
      key: 'renderOrder',
      label: 'Render Order',
      category: 'Experimental',
      dataType: 'number',
    },
    {
      label: 'Texture Encoding',
      category: 'Experimental',
      dataType: 'select',
    },
  ],
};
