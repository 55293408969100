import { Vector3 } from 'three';
import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { is_android, is_ios } from '../../../utils';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class MobileCheckWidget extends BaseWidget implements IBaseWidgetType {
  static type: string = 'mobile-check';

  // Data
  public mobileScaleValues: Vector3 = new Vector3();

  private entered: boolean = false;
  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = MobileCheckWidget.type;
    this.widgetName = 'Mobile Check';
    this.label = 'Mobile Check';
    this.usage = 'Flow';
    this.category = 'Events';

    this.events.push({
      label: 'On Mobile Set scale',
      name: 'on-mobile',
      desc: 'Fires if this widget is loaded on a mobile device.',
    });
  }

  update() {
    if (!this.entered) {
      if (
        is_android ||
        is_ios
        //  && window.innerWidth < 600
      ) {
        const { x, y, z } = this.mobileScaleValues;
        this.triggerProVizEvent('on-mobile', 'vector3', { x, y, z });
      }
      this.entered = true;
    }
  }

  getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('mobileScaleValues', 'Mobile Scale', 'Data', 'vec3', 'vector3', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    const { x, y, z } = this.mobileScaleValues;
    result.mobileScaleValues = { x, y, z };

    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    if (data.mobileScaleValues) {
      const { x, y, z } = data.mobileScaleValues;
      this.mobileScaleValues.set(x, y, z);
    }
  }
}

ModuleService.Register(MobileCheckWidget.type, MobileCheckWidget);
