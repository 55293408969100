import { FileService } from '@proviz/api-services';
import { BaseWidgetProperty, ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ImageTargetWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'image-target';

  // Data
  private imageId: string = '';
  private sizeInMeters: number = 1.0;
  private loadOnInit: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ImageTargetWidget.type;
    this.widgetName = 'Image Target';
    this.label = 'Image Target';
    this.category = 'Targets';
    this.selectable = true;
    this.events = [
      {
        label: 'Tracking Found',
        name: 'tracking-found',
      },
      {
        label: 'Tracking Lost',
        name: 'tracking-lost',
      },
    ];

    this.addService(
      'Start Tracking',
      'start-tracking',
      'Start the tracking service for this image target',
      () => {
        console.warn('Image Targets not supported on web yet.');
      },
    );
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('imageId', 'Image Target', 'Core', 'image', 'string', true, undefined, (data) => {
        if (data) {
          this.validateImageSize(data);
        } else {
          this.imageId = data;
        }
      }),
      this.createProperty('sizeInMeters', 'Size in Meters', 'Core', 'number', 'number', true),
      this.createProperty('loadOnInit', 'Load On Init', 'Core', 'bool', 'boolean', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.imageId = this.imageId;
    result.sizeInMeters = this.sizeInMeters;
    result.loadOnInit = this.loadOnInit;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.imageId = data.imageId;
    this.sizeInMeters = data.sizeInMeters ?? this.sizeInMeters;
    this.loadOnInit = data.loadOnInit ?? this.loadOnInit;
  }

  // Validating Image Size
  public async validateImageSize(imageId: string) {
    const file = await FileService.get(imageId, { abortController: this.scene.abortController });
    const img = new Image();
    img.src = file.location;
    await img.decode();
    if (img.naturalHeight > 2048 && img.naturalWidth > 2048) {
      window.alert('The image is too big to set as a source');
    } else {
      this.imageId = imageId;
    }
  }
}

ModuleService.Register(ImageTargetWidget.type, ImageTargetWidget);
