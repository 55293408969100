import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

const TIMEZONE = {
  '(GMT-12:00) International Date Line West': 'Etc/GMT+12',
  '(GMT-11:00) Midway Island, Samoa': 'Pacific/Midway',
  '(GMT-10:00) Hawaii': 'Pacific/Honolulu',
  '(GMT-09:00) Alaska': 'US/Alaska',
  '(GMT-08:00) Pacific Time (US & Canada)': 'America/Los_Angeles',
  '(GMT-08:00) Tijuana, Baja California': 'America/Tijuana',
  '(GMT-07:00) Arizona': 'US/Arizona',
  '(GMT-07:00) Chihuahua, La Paz, Mazatlan': 'America/Chihuahua',
  '(GMT-07:00) Mountain Time (US & Canada)': 'US/Mountain',
  '(GMT-06:00) Central America': 'America/Managua',
  '(GMT-06:00) Central Time (US & Canada)': 'US/Central',
  '(GMT-06:00) Guadalajara, Mexico City, Monterrey': 'America/Mexico_City',
  '(GMT-06:00) Saskatchewan': 'Canada/Saskatchewan',
  '(GMT-05:00) Bogota, Lima, Quito, Rio Branco': 'America/Bogota',
  '(GMT-05:00) Eastern Time (US & Canada)': 'US/Eastern',
  '(GMT-05:00) Indiana (East)': 'US/East-Indiana',
  '(GMT-04:00) Atlantic Time (Canada)': 'Canada/Atlantic',
  '(GMT-04:00) Caracas, La Paz': 'America/Caracas',
  '(GMT-04:00) Manaus': 'America/Manaus',
  '(GMT-04:00) Santiago': 'America/Santiago',
  '(GMT-03:30) Newfoundland': 'Canada/Newfoundland',
  '(GMT-03:00) Brasilia': 'America/Sao_Paulo',
  '(GMT-03:00) Buenos Aires, Georgetown': 'America/Argentina/Buenos_Aires',
  '(GMT-03:00) Greenland': 'America/Godthab',
  '(GMT-03:00) Montevideo': 'America/Montevideo',
  '(GMT-02:00) Mid-Atlantic': 'America/Noronha',
  '(GMT-01:00) Cape Verde Is.': 'Atlantic/Cape_Verde',
  '(GMT-01:00) Azores': 'Atlantic/Azores',
  '(GMT+00:00) Casablanca, Monrovia, Reykjavik': 'Africa/Casablanca',
  '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London': 'Etc/Greenwich',
  '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna': 'Europe/Amsterdam',
  '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague': 'Europe/Belgrade',
  '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris': 'Europe/Brussels',
  '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb': 'Europe/Sarajevo',
  '(GMT+01:00) West Central Africa': 'Africa/Lagos',
  '(GMT+02:00) Amman': 'Asia/Amman',
  '(GMT+02:00) Athens, Bucharest, Istanbul': 'Europe/Athens',
  '(GMT+02:00) Beirut': 'Asia/Beirut',
  '(GMT+02:00) Cairo': 'Africa/Cairo',
  '(GMT+02:00) Harare, Pretoria': 'Africa/Harare',
  '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius': 'Europe/Helsinki',
  '(GMT+02:00) Jerusalem': 'Asia/Jerusalem',
  '(GMT+02:00) Minsk': 'Europe/Minsk',
  '(GMT+02:00) Windhoek': 'Africa/Windhoek',
  '(GMT+03:00) Kuwait, Riyadh, Baghdad': 'Asia/Kuwait',
  '(GMT+03:00) Moscow, St. Petersburg, Volgograd': 'Europe/Moscow',
  '(GMT+03:00) Nairobi': 'Africa/Nairobi',
  '(GMT+03:00) Tbilisi': 'Asia/Tbilisi',
  '(GMT+03:30) Tehran': 'Asia/Tehran',
  '(GMT+04:00) Abu Dhabi, Muscat': 'Asia/Muscat',
  '(GMT+04:00) Baku': 'Asia/Baku',
  '(GMT+04:00) Yerevan': 'Asia/Yerevan',
  '(GMT+04:30) Kabul': 'Asia/Kabul',
  '(GMT+05:00) Yekaterinburg': 'Asia/Yekaterinburg',
  '(GMT+05:00) Islamabad, Karachi, Tashkent': 'Asia/Karachi',
  '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi': 'Asia/Calcutta',
  '(GMT+05:30) Sri Jayawardenapura': 'Asia/Calcutta',
  '(GMT+05:45) Kathmandu': 'Asia/Katmandu',
  '(GMT+06:00) Almaty, Novosibirsk': 'Asia/Almaty',
  '(GMT+06:00) Astana, Dhaka': 'Asia/Dhaka',
  '(GMT+06:30) Yangon (Rangoon)': 'Asia/Rangoon',
  '(GMT+07:00) Bangkok, Hanoi, Jakarta': 'Asia/Bangkok',
  '(GMT+07:00) Krasnoyarsk': 'Asia/Krasnoyarsk',
  '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi': 'Asia/Hong_Kong',
  '(GMT+08:00) Kuala Lumpur, Singapore': 'Asia/Kuala_Lumpur',
  '(GMT+08:00) Irkutsk, Ulaan Bataar': 'Asia/Irkutsk',
  '(GMT+08:00) Perth': 'Australia/Perth',
  '(GMT+08:00) Taipei': 'Asia/Taipei',
  '(GMT+09:00) Osaka, Sapporo, Tokyo': 'Asia/Tokyo',
  '(GMT+09:00) Seoul': 'Asia/Seoul',
  '(GMT+09:00) Yakutsk': 'Asia/Yakutsk',
  '(GMT+09:30) Adelaide': 'Australia/Adelaide',
  '(GMT+09:30) Darwin': 'Australia/Darwin',
  '(GMT+10:00) Brisbane': 'Australia/Brisbane',
  '(GMT+10:00) Canberra, Melbourne, Sydney': 'Australia/Canberra',
  '(GMT+10:00) Hobart': 'Australia/Hobart',
  '(GMT+10:00) Guam, Port Moresby': 'Pacific/Guam',
  '(GMT+10:00) Vladivostok': 'Asia/Vladivostok',
  '(GMT+11:00) Magadan, Solomon Is., New Caledonia': 'Asia/Magadan',
  '(GMT+12:00) Auckland, Wellington': 'Pacific/Auckland',
  '(GMT+12:00) Fiji, Kamchatka, Marshall Is.': 'Pacific/Fiji',
  "(GMT+13:00) Nuku'alofa": 'Pacific/Tongatapu',
};

export class DateTimeWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'date-time';

  //Data
  public useLocalTimezone: boolean = true;
  public timezone: string = '(GMT-05:00) Eastern Time (US & Canada)';

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = DateTimeWidget.type;
    this.widgetName = 'Date Time';
    this.label = 'Date Time';
    this.usage = 'Flow';
    this.category = 'Time';
    this.events.push(
      {
        label: 'Current Date',
        name: 'current-date',
      },
      {
        label: 'Current Time',
        name: 'current-time',
      },
      {
        label: 'Current Date and Time',
        name: 'current-datetime',
      },
      {
        label: 'Year',
        name: 'year',
      },
      {
        label: 'Month',
        name: 'month',
      },
      {
        label: 'Date',
        name: 'date',
      },
      {
        label: 'Day',
        name: 'day',
      },
      {
        label: 'Hour',
        name: 'hour',
      },
      {
        label: 'Minute',
        name: 'minute',
      },
      {
        label: 'Second',
        name: 'second',
      },
    );
    this.addService('Update', 'update', '<b>outputs all the events</b>', () => {
      let timezone = this.useLocalTimezone ? undefined : TIMEZONE[this.timezone];
      let date = new Date();
      this.triggerProVizEvent(
        'current-date',
        'string',
        date.toLocaleDateString('en-US', { timeZone: timezone }),
      );
      this.triggerProVizEvent(
        'current-time',
        'string',
        date.toLocaleTimeString('en-US', { timeZone: timezone }),
      );
      this.triggerProVizEvent(
        'current-datetime',
        'string',
        date.toLocaleString('en-US', { timeZone: timezone }),
      );
      this.triggerProVizEvent('year', 'number', date.getFullYear());
      this.triggerProVizEvent('month', 'number', date.getMonth());
      this.triggerProVizEvent('date', 'number', date.getDate());
      this.triggerProVizEvent('day', 'string', date.toDateString().split(' ')[0]);
      this.triggerProVizEvent('hour', 'number', date.getHours());
      this.triggerProVizEvent('minute', 'number', date.getMinutes());
      this.triggerProVizEvent('second', 'number', date.getSeconds());
    });
  }

  public update(delta: number) {
    super.update(delta);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('useLocalTimezone', 'Use Local Timezone', 'Core', 'bool', 'boolean', true),
      this.createProperty(
        'timezone',
        'Timezone',
        'Core',
        'select',
        'string',
        true,
        undefined,
        undefined,
        () => {
          return Object.keys(TIMEZONE).map(x => { return { key: x, label: x }});
        },
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.useLocalTimezone = this.useLocalTimezone;
    result.timezone = this.timezone;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.useLocalTimezone = data.useLocalTimezone ?? this.useLocalTimezone;
    this.timezone = data.timezone ?? this.timezone;
  }
}

ModuleService.Register(DateTimeWidget.type, DateTimeWidget);
