import {
  Box3,
  CameraHelper,
  DirectionalLight,
  DirectionalLightHelper,
  Object3D,
  Vector3,
} from 'three';
import { BaseWidgetProperty } from '..';
import { ModuleService } from '../../moduleService';
import { ProVizScene, SceneMode } from '../..';
import { BaseWidget } from '../baseWidget';
import { BasePositionableWidget } from '../basePositionableWidget';
import { IBaseWidgetType } from '../IBaseWidgetType';
import { ProVizEventData } from '../../ProVizEventData';

type LightPositionSettings = 'standard';

const ShadowMapResolutions = {
  256: '256px',
  512: '512px (default)',
  1024: '1024px',
  2048: '2048px',
  4096: '4096px (not recommended)',
};

export class DirectionalLightWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'directional-light';

  // Data
  public color: string = '#fff';
  public intensity: number = 1;
  public castShadow: boolean = true;
  public shadowIntensity: number = 0;
  public shadowSoftness: number = 1.0;
  public shadowMapSize: number = 512;
  public shadowBias: number = 0;
  // public shadowRad: number = 4;
  // showhelpers is not serialized just for the editor
  public showHelpers: boolean = false;

  private light?: DirectionalLight;
  private targetBB?: Box3;
  private helpers?: { light: DirectionalLightHelper; cam: CameraHelper };

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = DirectionalLightWidget.type;
    this.widgetName = 'Directional Light';
    this.label = 'Directional Light';
    this.category = 'Lights';
    this.selectable = false;

    this.services.push({
      label: 'Position To Object',
      name: 'fit-to',
    });

    this.addEventListener('service-fit-to', (event: ProVizEventData) => {
      if (event.dataType === 'string') {
        const template = event.data as LightPositionSettings;
        const widget = this.scene.getById(event.sourceWidgetId);
        const obj = widget?.renderNode;
        if (obj) {
          this.fitToObject(obj, template);
        }
      }
    });
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.light = new DirectionalLight(this.color, this.intensity);
    // this.light.position.set(0, 0, 0);
    // this.light.castShadow = true;

    // this.light.shadow.camera.near = 1;
    // this.light.shadow.camera.far = 100;
    // this.light.shadow.bias = 0.001;

    // this.light.shadow.mapSize.width = 2048;
    // this.light.shadow.mapSize.height = 2048;


    var target = new Object3D();
    this.scene.scene.add(target);
    // target.position.set(
    //   -this.renderNode.position.x,
    //   -this.renderNode.position.y,
    //   -this.renderNode.position.z
    // );
    this.light.target = target;

    // const helper = new CameraHelper( this.light.shadow.camera );
    // this.scene.scene.add( helper );

    // this.light.frustumCulled = false;
    this.renderNode.add(this.light);
    if (this.scene.sceneMode === SceneMode.Editor) {
      this.helpers = {
        cam: new CameraHelper(this.light.shadow.camera),
        light: new DirectionalLightHelper(this.light, 0.4, '#000'),
      };
      this.helpers.cam.visible = this.helpers.light.visible = false;
      this.renderNode.add(this.helpers.cam);
      this.renderNode.add(this.helpers.light);
    }
    this.updateShadow();

    return true;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('color', 'Color', 'Core', 'color', 'string', true, undefined, (data: string) => {
        this.color = data;
        if (this.light) {
          this.light.color.set(this.color);
        }
      }),
      this.createProperty(
        'intensity',
        'Intensity',
        'Core',
        'constrained-number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.intensity = data;
          if (this.light) {
            this.light.intensity = this.intensity;
          }
        },
        () => [0, 15].map(x => { return { key: x, label: x.toString() }}),
      ),
      this.createProperty(
        'castShadow',
        'Cast Shadow',
        'Shadow',
        'bool',
        'boolean',
        true,
        undefined,
        (data: boolean) => {
          this.castShadow = data;
          this.updateShadow();
        },
      ),
      // Note: this property is not serialized and is only used in the editor
      this.createProperty(
        'showHelpers',
        'Show Light Helper',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        (data: any) => {
          this.showHelpers = data;
          if (this.helpers) {
            this.helpers.light.visible = data;
            this.helpers.cam.visible = data;
          }
        },
      ),
      // This is removed because we are only using PCFSoftShadow map at this time
      // If other shadow maps are supported we may bring this property back.
      // FOr the time being control of sharpeness at the edges of shadows
      // is best controlled by map sizex
      // this.createProperty(
      //   'shadowRad',
      //   'Shadow Radius',
      //   'Shadow',
      //   'constrained-number',
      //   true,
      //   undefined,
      //   (data: number) => {
      //     this.shadowRad = data;
      //     this.updateShadow();
      //     // this.debouncedUpdateShadow()
      //   },
      //   () => [1, 8]
      // ),
      this.createProperty(
        'shadowMapSize',
        'Shadow Map Size',
        'Shadow',
        'select',
        'number',
        true,
        () => ShadowMapResolutions[this.shadowMapSize],
        (data: string) => {
          let reso = parseInt(data); // parseInt will drop the px and any other chars from selection
          this.shadowMapSize = reso;
          this.updateShadow();
          // this.debouncedUpdateShadow()
        },
        () => Object.values(ShadowMapResolutions).map(x => { return { key: x, label: x }}),
        `Use this to control the sharpness of cast shadows in the scene. 
        Larger shadow map sizes will slow performance on low powered devices.`,
      ),
      this.createProperty(
        'shadowBias',
        'Shadow Bias',
        'Shadow',
        'constrained-number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.shadowBias = data;
          this.updateShadow();
          // this.debouncedUpdateShadow()
        },
        () => [0, 0.5].map(x => { return { key: x, label: x.toString() }}),
        `Use this property to help objects feel grounded on a surface. 
        It can remove shadow acne from surfaces facing the light source.`,
      ),
    ];
  }

  updateShadow() {
    if (!this.light) {
      return;
    }
    this.light.castShadow = this.castShadow;
    if (this.castShadow) {
      this.light.shadow.bias = this.shadowBias;
      this.light.shadow.mapSize.set(this.shadowMapSize, this.shadowMapSize);
    } else if (this.light.shadow.map) {
        // @ts-ignore
        this.light.shadow.map.dispose();
    }
    
    // // this.light.shadow.radius = this.shadowRad;
    // this.scene.resizeShadowCameraFrustum(this.light);
    // this.light.shadow.updateMatrices(this.light);
    // this.light.updateMatrixWorld();
  }

  public serialize(): any {
    const result = super.serialize();
    result.color = this.color;
    result.intensity = this.intensity;
    result.castShadow = this.castShadow;
    // result.shadowRad = this.shadowRad;
    result.shadowBias = this.shadowBias;
    result.shadowMapSize = this.shadowMapSize;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.color = data.color;
    this.intensity = data.intensity;
    this.castShadow = data.castShadow;
    this.shadowBias = data.shadowBias ?? this.shadowBias;
    this.shadowMapSize = data.shadowMapSize ?? this.shadowMapSize;
    // this.shadowRad = data.shadowRad ?? this.shadowRad;
  }

  public update(delta) {
    super.update(delta);
  }

  private fitToObject(obj: Object3D, template: LightPositionSettings = 'standard') {
    if (!this.targetBB) {
      this.targetBB = new Box3();
    }
    const box = this.targetBB;
    box.setFromObject(obj);
    const size = box.getSize(new Vector3()).length();
    const center = box.getCenter(new Vector3());
    if (template === 'standard') {
      this.renderNode.position.set(-1.5, 1.5, 0);
      this.renderNode.position.normalize();
      const { x, y, z } = this.renderNode.position;
      this.renderNode.position.set(x * size, y * size, z * size);
      if (this.light) {
        this.light.lookAt(center);
      }
    }

    this.updateShadow();
  }
}

ModuleService.Register(DirectionalLightWidget.type, DirectionalLightWidget);
