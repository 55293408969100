import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { GUIBaseWidget } from '../guiBaseWidget';

export class GUIWidget extends GUIBaseWidget implements IBaseWidgetType {
  public static type: string = 'gui';

  // Data

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = GUIWidget.type;
    this.widgetName = 'GUI';
    this.label = 'GUI';
    this.category = 'UI';
    this.events = [];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    const renderParent = this.scene.renderer?.domElement.parentElement;
    this.guiElement = document.createElement('div');
    this.guiElement.setAttribute('class', 'gui-element');
    const style = `
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 1000;
    `;
    this.guiElement.setAttribute('style', style);
    renderParent?.parentElement?.appendChild(this.guiElement);

    return true;
  }
}

ModuleService.Register(GUIWidget.type, GUIWidget);
