import { ReactElement } from 'react'
import Header from './common/header/Header';
import AuthButton from './common/auth/AuthButton';
import { getAbsoluteUrl } from '@proviz/proviz-sdk';

const errorImage = getAbsoluteUrl('images/ErrorPage.svg');

export default function ErrorPage(): ReactElement {
    return <div className='home'>
        <Header>
            <AuthButton />
        </Header>
        <div style={{ height: '100%', width: '100%', background: '#f0f0f2', }}>

            <img src={errorImage} alt='Error Page'
                style={{
                    display: 'grid', placeContent: 'center', height: '60%', width: '100%',
                    background: '#f0f0f2', paddingBottom: '40px'
                }} />
            <h3>I'm afraid we've encountered some technical difficulties.</h3>
            <h2>Please try again soon.</h2>
        </div>
    </div>;
}
