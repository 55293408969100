import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class MouseWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'mouse';

  //Data
  public mouseCaptureEnabled: boolean = true;

  private bindedMouseDownHandler = this.handleMouseDown.bind(this);
  private bindedMouseUpHandler = this.handleMouseUp.bind(this);
  private bindedMouseMoveHandler = this.handleMouseMove.bind(this);
  private bindedScrollHandler = this.handleScroll.bind(this);

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = MouseWidget.type;
    this.widgetName = 'Mouse';
    this.label = 'Mouse';
    this.usage = 'Flow';
    this.category = 'Events';
    this.events.push(
      {
        name: 'left-button-up',
        label: 'Left Button Up',
      },
      {
        name: 'left-button-down',
        label: 'Left Button Down',
      },
      {
        name: 'right-button-up',
        label: 'Right Button Up',
      },
      {
        name: 'right-button-down',
        label: 'Right Button Down',
      },
      {
        name: 'middle-button-up',
        label: 'Middle Button Up',
      },
      {
        name: 'middle-button-down',
        label: 'Middle Button Down',
      },
      {
        name: 'scroll-down',
        label: 'Scroll Down',
      },
      {
        name: 'scroll-up',
        label: 'Scroll Up',
      },
      {
        name: 'scroll-value',
        label: 'Scroll Value',
      },
      {
        name: 'mouse-move',
        label: 'Mouse Move',
      },
      {
        name: 'x',
        label: 'X',
      },
      {
        name: 'y',
        label: 'Y',
      },
    );
    this.addService('enable Mouse', 'enable-mouse', '<b>Enable mouse capture</b>', () => {
      this.addMouseListeners();
    });
    this.addService('Disable Mouse', 'disable-mouse', '<b>Disables mouse capture</b>', () => {
      this.removeMouseListeners();
    });
  }

  public async init() {
    super.init();
    if (this.mouseCaptureEnabled) {
      this.addMouseListeners();
    }
    return true;
  }

  private addMouseListeners() {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }
    document.addEventListener('mousedown', this.bindedMouseDownHandler);
    document.addEventListener('mouseup', this.bindedMouseUpHandler);
    document.addEventListener('mousemove', this.bindedMouseMoveHandler);
    document.addEventListener('wheel', this.bindedScrollHandler);
  }

  private removeMouseListeners() {
    document.removeEventListener('mousedown', this.bindedMouseDownHandler);
    document.removeEventListener('mouseup', this.bindedMouseUpHandler);
    document.removeEventListener('mousemove', this.bindedMouseMoveHandler);
    document.removeEventListener('wheel', this.bindedScrollHandler);
  }

  private handleMouseDown(e: MouseEvent) {
    switch (e.button) {
      case 0:
        this.triggerProVizEvent('left-button-down', 'vector2', { x: e.clientX, y: e.clientY });
        break;
      case 1:
        this.triggerProVizEvent('middle-button-down', 'vector2', { x: e.clientX, y: e.clientY });
        break;
      case 2:
        this.triggerProVizEvent('right-button-down', 'vector2', { x: e.clientX, y: e.clientY });
        break;
    }
  }

  private handleMouseUp(e: MouseEvent) {
    switch (e.button) {
      case 0:
        this.triggerProVizEvent('left-button-up', 'vector2', { x: e.clientX, y: e.clientY });
        break;
      case 1:
        this.triggerProVizEvent('middle-button-up', 'vector2', { x: e.clientX, y: e.clientY });
        break;
      case 2:
        this.triggerProVizEvent('right-button-up', 'vector2', { x: e.clientX, y: e.clientY });
        break;
    }
  }

  private handleMouseMove(e: MouseEvent) {
    this.triggerProVizEvent('mouse-move', 'vector2', { x: e.x, y: e.y });
    this.triggerProVizEvent('x', 'number', e.x);
    this.triggerProVizEvent('y', 'number', e.y);
  }

  private handleScroll(e: WheelEvent) {
    this.triggerProVizEvent('scroll-value', 'number', e.deltaY);
    if (e.deltaY > 0) {
      this.triggerProVizEvent('scroll-down', 'none');
    } else {
      this.triggerProVizEvent('scroll-up', 'none');
    }
  }

  public getProperties() {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('mouseCaptureEnabled', 'mouse Active', 'Core', 'bool', 'boolean', true),
    ];
  }

  public serialize() {
    const result = super.serialize();
    result.mouseCaptureEnabled = this.mouseCaptureEnabled;
    return result;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.mouseCaptureEnabled = data.mouseCaptureEnabled;
  }

  dispose() {
    super.dispose();
    this.removeMouseListeners();
  }
}
ModuleService.Register(MouseWidget.type, MouseWidget);
