export type SRTEntry = {
  start: number;
  end: number;
  text: string[];
};

function parseTimeStampToSeconds(t: string): number {
  let ret = 0;
  let parts = t.split(':');
  ret += parseInt(parts[0]) * 3600; // hour stamp
  ret += parseInt(parts[1]) * 60; // min stamp
  let [sec, ms] = parts[2].split(',');
  ret += parseInt(sec); // sec stamp
  ret += parseInt(ms) / 1000; // ms stamp

  return ret;
}

export function parseSRT(data: string): SRTEntry[] {
  const res: SRTEntry[] = [];

  for (let d of data.split('\n\n')) {
    let startIdx = 0;
    const lines = d.split('\n');
    while (lines[startIdx] === '') {
      startIdx++;
    }
    startIdx++; // iter past subtitle index
    if (lines.length <= startIdx) {
      continue;
    }
    let times = lines[startIdx].split('-->');
    const start = parseTimeStampToSeconds(times[0]);
    const end = parseTimeStampToSeconds(times[1]);
    const text = lines.slice(startIdx + 1);
    res.push({ start, end, text });
  }

  return res;
}
