import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class URLWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'url';

  // Data
  public url: string = '';
  public newWindow: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'URL';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = URLWidget.type;
    this.widgetName = 'URL';
    this.selectable = true;
    this.events = [];
    this.services = [
      {
        label: 'Open',
        name: 'open',
      },
    ];

    this.addEventListener('service-open', (event) => {
      if (this.scene.sceneMode === SceneMode.Play) {
        window.open(this.url, this.newWindow ? '_blank' : '_self');
      }
    });
  }

  protected setup(def) {
    
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'url',
        'URL',
        'Core',
        'string',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'If this is not a proviz link you will need to include the whole path https://www.example.com',
      ),
      this.createProperty('newWindow', 'Open in new window', 'Core', 'bool', 'boolean', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.url = this.url;
    result.newWindow = this.newWindow;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.url = data.url;
    this.newWindow = data.newWindow;
  }
}

ModuleService.Register(URLWidget.type, URLWidget);
