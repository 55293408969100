import { APISprite } from "../models/Sprite";
import { BaseDataService, IServiceOptions } from "../BaseDataService";

class APISpriteService extends BaseDataService<APISprite> {
  constructor() {
    super("sprite");
  }

  public async upload(
    file: File,
    filename?: string,
    options?: IServiceOptions
  ): Promise<APISprite> {
    const body = new FormData();
    body.append("spriteFile", file, filename);
    return await this.submit("upload", "Post", body, options);
  }

  public async update(sprite: APISprite, options?: IServiceOptions) {
    const { name, horizontalTiles, verticalTiles, numberOfTiles } = sprite;
    const body = { name, horizontalTiles, verticalTiles, numberOfTiles };
    return await this.request(`${sprite.id}/update`, {
      method: "PUT",
      body,
      ...options,
    });
  }

  public async getSpriteByFileId(
    fileId: string,
    options?: IServiceOptions
  ): Promise<APISprite> {
    return await this.request(`file/${fileId}`, { method: "GET", ...options });
  }
}

const SpriteService = new APISpriteService();

export default SpriteService;
