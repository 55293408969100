import { TargetService } from '@proviz/api-services';
import { ModelWidget } from '../../core';
import { BaseWidgetProperty, ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ModelTargetWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'model-target';

  // Data
  public targetId: string = '';
  private loadOnInit: boolean = true;

  private displayModel: ModelWidget | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ModelTargetWidget.type;
    this.widgetName = 'Model Target';
    this.label = 'Model Target';
    this.category = 'Targets';
    this.selectable = true;
    this.events = [
      {
        label: 'Tracking Found',
        name: 'tracking-found',
      },
      {
        label: 'Tracking Lost',
        name: 'tracking-lost',
      },
    ];
    this.addService(
      'Start Tracking',
      'start-tracking',
      'Start the tracking service for this model target',
      () => {
        console.warn('Model Targets not supported on web yet.');
      },
    );
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('targetId', 'Model Target', 'Core', 'model-target', 'string', true),
      this.createProperty('loadOnInit', 'Load On Init', 'Core', 'bool', 'boolean', true),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.targetId) {
      // get the target and use the display model
      TargetService.get(this.targetId).then((target) => {
        const settings = JSON.parse(target.settings || '{}');
        if (settings.displayModelId) {
          this.setupDisplayModel(settings.displayModelId);
        }
      });
    }

    return true;
  }

  private async setupDisplayModel(modelId: string) {
    if (this.displayModel) {
      this.displayModel.remove();
    }

    if (modelId) {
      this.displayModel = new ModelWidget(this.scene, this);
      this.displayModel.modelId = modelId;
      this.displayModel.deserialize({
        modelId: modelId,
        clickable: false,
        transparent: false,
        opacity: 1.0,
      });
      await this.displayModel.init();
      this.renderNode.add(this.displayModel.renderNode);
    } else {
      this.displayModel = undefined;
    }
  }

  public serialize(): any {
    const result = super.serialize();
    result.targetId = this.targetId;
    result.loadOnInit = this.loadOnInit;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.targetId = data.targetId;
    this.loadOnInit = data.loadOnInit || true;
  }

  dispose() {
    super.dispose();
    this.displayModel?.dispose();
  }
}

ModuleService.Register(ModelTargetWidget.type, ModelTargetWidget);
