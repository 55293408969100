import { ReactElement } from "react";

interface Props {
  setOnOff: boolean;
  onChange: (state: boolean) => void;
}

export default function ToggleFlip(props: Props): ReactElement {
  const { setOnOff, onChange } = props;

  return (
    <div
      className={`toggleFlip ${setOnOff === true}`}
      onClick={() => onChange(!setOnOff)}
    >
      <div className="on">On</div>
      <div className="circle">
        <div className="inner"></div>
      </div>
      <div className="off">Off</div>
    </div>
  );
}
