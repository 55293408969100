import { ReactElement, ReactNode } from 'react'

interface Props {
    children?: ReactNode;
    index: number;
    value: number;
}

export default function TabPanel(props: Props): ReactElement {
    const { index, value, children } = props;
    return (
        <div className="tabpanel" hidden={index !== value}>
            {children}
        </div>
    )
}
