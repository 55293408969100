import styled from "styled-components";

const GlobalTag = styled.div`
    text-transform: uppercase;
    color: #fff;
    font-size: 0.6rem;
    background: rgba(0, 0, 0, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: inline-block;
`;

export default GlobalTag;