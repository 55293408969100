import { ReactElement } from 'react'
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const blueCheck = getAbsoluteUrl('images/BlueCheckmark.svg');

interface Props {
    id: string, 
    value: boolean, 
    onChange: () => void;
}

export default function Checkbox(props: Props): ReactElement {
    const { value, onChange } = props;
    return <div className="checkbox" onClick={onChange}>
        {value && <img src={blueCheck} alt='' />}
    </div>;
}
