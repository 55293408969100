import { WidgetDefinition } from '../../baseWidget';

export const BillboardWidgetDefinition: WidgetDefinition = {
  label: 'Billboard',
  type: 'billboard',

  // name is used as Label and then converted to the event type by using only lowercase letters
  // and converting spaces to -'s. Example: 'Clicked' -> 'clicked
  events: [
    {
      label: 'Activated',
      dataType: 'bool',
    },
    {
      label: 'Deactivated',
      dataType: 'bool',
    },
  ],

  // name is used as label and converted to 'set-audio'
  services: [
    {
      label: 'Activate',
      desc: '<b>Widget will begin facing the camera</b>',
      dataType: 'boolean',
    },
    {
      label: 'Deactivate',
      desc: '<b>Widget will no longer actively face the camera</b>',
      dataType: 'boolean',
    },
    {
      label: 'Toggle',
      desc: '<b>Toggle actication of this widget</b>',
      dataType: 'boolean',
    },
  ],

  // Data property name converts to camel case 'textContent'.
  // Serialization is setup by the base widget constructor
  properties: [
    {
      label: 'Active on Start',
      category: 'Core',
      dataType: 'bool',
      editable: true,
    },
    {
      label: 'Lock to Y Axis',
      category: 'Core',
      dataType: 'bool',
      editable: true,
    },
    {
      label: 'Invert the Direction',
      category: 'Core',
      dataType: 'bool',
      editable: true,
    },
  ],
};
