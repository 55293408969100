import { APIFolder, FolderService } from '@proviz/api-services';
import { ReactElement, useState } from 'react';
import Button from '../common/button/Button';
import Modal from '../common/modals/Modals';

interface Props {
    folder: APIFolder;
    close: () => void;
    onDelete: () => void;
}

export default function FolderSettingsModal(props: Props): ReactElement {
    const { folder, close, onDelete } = props;

    const [ folderName, setFolderName ] = useState(folder.name || '');

    return <Modal
        title="Folder Settings"
        close={close}
    >
        <input type="text" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
        <button onClick={() => {
            FolderService.update(folder.id, folderName, folder.parentFolderId).then((() => {
                folder.name = folderName;
                close();
            }));
        }}>Save</button>
        <hr />
        <Button type='danger' onClick={() => {
            if (window.confirm('Are you sure you want to delete this folder?')) {
                FolderService.delete(folder.id).then(onDelete);
            }
        }}>Delete</Button>
    </Modal>
}
