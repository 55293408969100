import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class TimerWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'timer';

  // Data
  public time: number = 30;

  private running: boolean = false;
  private currTime: number = 0;
  private repeat: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = TimerWidget.type;
    this.widgetName = 'Timer';
    this.label = 'Timer';
    this.usage = 'Flow';
    this.category = 'Conditional';
    this.selectable = false;
    this.events = [
      {
        label: 'Started',
        name: 'started',
      },
      {
        label: 'Stopped',
        name: 'stopped',
      },
      {
        label: 'Paused',
        name: 'paused',
      },
      {
        label: 'Finished',
        name: 'finished',
      },
    ];

    this.addService('Start', 'start', 'Start the timer', () => {
      this.running = true;
      this.triggerProVizEvent('started', 'none');
    });

    this.addService('Pause', 'pause', 'Pauses the timer', () => {
      this.running = false;
      this.triggerProVizEvent('paused', 'none');
    });

    this.addService('Stop', 'stop', 'Stops the timer', () => {
      this.running = false;
      this.currTime = 0;
      this.triggerProVizEvent('stopped', 'none');
    });

    this.addService('Reset', 'reset', 'Resets the timer', () => {
      this.currTime = 0;
      this.triggerProVizEvent('reset', 'none');
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result, 
      this.createProperty('time', 'Time', 'Core', 'number', 'number', true),    
      this.createProperty('repeat', 'Repeat', 'Core', 'bool', 'boolean', true)];
  }

  public serialize(): any {
    const result = super.serialize();
    result.time = this.time;
    result.repeat = this.repeat;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.time = data.time;
    this.repeat = data.repeat ?? this.repeat;
  }

  public update(delta: number): void {
    if (this.running) {
      console.log('timer running', delta);
      this.currTime += delta;
      if (this.currTime > this.time) {
        this.running = false;
        this.triggerProVizEvent('finished', 'none');
      }
    }
  }
}

ModuleService.Register(TimerWidget.type, TimerWidget);
