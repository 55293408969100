import { ReactElement, useEffect, useState } from 'react'
import Button from '../../common/button/Button';

type SaveState = 'publishing' | 'failed' | 'ok' | ''

type Props = {
    publish: () => Promise<void>;
}

export default function PublishButton(props: Props): ReactElement {
    const { publish } = props;
    const [requestState, setRequestState] = useState<SaveState>('');


    const publishScene = async () => {
        try {
            setRequestState('publishing')
            await publish();
            setRequestState('ok')
            setTimeout(() => {
                setRequestState('')
            }, 4000);
        } catch (e: any) {
            
            setRequestState('failed')
            if (e.message === "Out of Date") {
                alert("Your version of this scene is out of date, refresh to continue to edit it.")
            }
        }
    }

    const handleKeypress = (evt: KeyboardEvent) => {
        if ((evt.metaKey || evt.ctrlKey) && (evt.code === 'KeyP')) {
            evt.preventDefault();
            evt.stopPropagation();
            publishScene();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeypress, true);
        return () => {
            document.removeEventListener('keydown', handleKeypress, true);
        }
    });

    return <Button
        title="Publishing this scene will update the scene shown in embed with your working changes."
        classNames={`saveButton ${requestState}`}
        onClick={() => publishScene()}>
        {requestState === 'publishing' ? "Publishing" : requestState === "ok" ? "Published" : "Publish"}
    </Button>
}