import {
  Box3,
  Clock,
  Group,
  Intersection,
  Matrix3,
  Vector2,
  Vector3,
} from "three";
import { APIModel, APIMaterialSet, ModelService } from "@proviz/api-services";
import {
  getAbsoluteUrl,
  ProVizLoader,
  setRenderOrder,
  setupDefaultModelScene,
  ModelWidget,
  AnnotationWidget,
  BaseWidget,
} from "@proviz/proviz-sdk";
import GraphicsManager from "./GraphicsManager";
import ModelViewControls from "./controls/ModelViewControls";
import { getMaterialByName } from "./utils/GetMaterial";
import { debounce, DebouncedFunc } from "lodash";
import { AddNode } from "../commands";

enum ModelViewMode {
  Default,
  Annotate,
}

const UpdateRate = 1000 / 30 / 100;
const MaxDelta = UpdateRate * 3;

export default class ModelViewManager extends GraphicsManager {
  entityType = "Model";

  controls: ModelViewControls | null = null;
  materialSet: APIMaterialSet | undefined;
  model: APIModel | undefined;
  modelWidget?: ModelWidget;
  modelSelected: boolean = false;
  showScaleModel: boolean = false;
  scaleModel?: Group;
  mode: ModelViewMode = ModelViewMode.Default;

  private clock: Clock = new Clock();
  private accumulatedDelta = 0;

  private debouncedUpdate: DebouncedFunc<() => void> = debounce(
    () => this.saveSettings(),
    3000,
    { trailing: true }
  );

  constructor(id: string) {
    super(id, "ModelViewer");

    this.resize = this.resize.bind(this);
    // this.renderer.render();
    this.dispatchEvent("init-done", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: "init-done",
    });
    this.update();
  }

  public connectToDom(container: HTMLDivElement): void {
    super.connectToDom(container);
    const { clientWidth, clientHeight } = container;
    this.dimensions = { width: clientWidth, height: clientHeight };
    // controls need to be created after the renderer's dom el has been attached to container
    this.controls = new ModelViewControls(
      this,
      this.camera.camera,
      this.renderer
    );
  }

  async init(model: APIModel) {
    this.model = model;
    console.log('Init ModelVIewManager');
    if (!model.viewerSettings) {
      setupDefaultModelScene(this.proVizScene, model.id);
      await this.saveSettings();
    } else {
      try {
        const sceneData = JSON.parse(model.viewerSettings);
        await this.proVizScene.deserialize(sceneData, "ModelViewer");
      } catch {
        setupDefaultModelScene(this.proVizScene, model.id);
        await this.saveSettings();
      }
    }

    this.modelWidget = this.proVizScene.nodes.find(
      (x) => x.widgetType === "model"
    ) as ModelWidget | undefined;
    if (!this.modelWidget?.isInitialized) {
      this.modelWidget?.init();
    }
    await this.modelWidget?.setupFromModel(model);
    this.setUpFromScene();
    document.addEventListener("keydown", (e) => this.docHandleKey(e));
  }

  rotation() {
    if (!this.modelWidget) {
      throw Error("No Group");
    }
    let r = this.modelWidget.getRotation();
    return { x: r.x, y: r.y, z: r.z };
  }

  scale() {
    if (!this.modelWidget) {
      throw Error("No Group");
    }
    let s = this.modelWidget.getScale();
    return { x: s.x, y: s.y, z: s.z };
  }

  setSelected(status: boolean) {
    this.modelSelected = status;
    this.dispatchSelectionUpdate();
  }

  update() {
    if (this.disposed) {
      return;
    }
    requestAnimationFrame(() => this.update());

    let delta = this.clock.getDelta();
    this.accumulatedDelta += delta;
    if (this.accumulatedDelta >= UpdateRate) {
      this.accumulatedDelta -= UpdateRate;
      this.proVizScene.update(UpdateRate);
      if (this.accumulatedDelta >= MaxDelta) {
        this.accumulatedDelta = MaxDelta;
      }
    }

    if (this.mode !== ModelViewMode.Annotate) {
      this.controls?.update();
    }

    this.renderer.render();
  }

  public postUpdateTask() {
    this.debouncedUpdate();
  }

  private async saveSettings() {
    if (!this.model) {
      throw new Error("Model is undefined when trying to save.");
    }
    const sceneData = JSON.stringify(this.proVizScene.serialize());
    this.model.viewerSettings = sceneData;
    await ModelService.updateViewerSettings(this.model.id, sceneData);
  }

  setRotation(x: number, y: number, z: number) {
    if (!this.modelWidget) {
      return;
    }
    this.modelWidget.setRotation({ x, y, z });
    this.dispatchWidgetUpdate();
  }

  setScale(x: number, y: number, z: number) {
    if (!this.modelWidget) {
      return;
    }
    this.modelWidget.setScale({ x, y, z });
    this.updateScaleModelPos();
    this.dispatchWidgetUpdate();
  }

  async setMaterialSet(materialSet: APIMaterialSet | undefined) {
    this.materialSet = materialSet;
    if (this.modelWidget && materialSet) {
      ProVizLoader.ApplyMaterialSet(this.modelWidget.renderNode, materialSet);
    }
  }

  async refreshMaterials() {
    if (!this.modelWidget || !this.materialSet) {
      return;
    }
    await ProVizLoader.ApplyMaterialSet(
      this.modelWidget.renderNode,
      this.materialSet
    );
    // this.render();
  }

  render() {
    // this.update();
    super.render();
  }

  getNamedMaterial(materialName: string) {
    if (!this.modelWidget) {
      return null;
    }
    return getMaterialByName(materialName, this.modelWidget.renderNode);
  }

  resize() {
    if (this.renderer && this.canvas && this.canvas.parentElement) {
      this.dimensions = {
        width: window.innerWidth - 300,
        height: window.innerHeight - 60,
      };
      const { width, height } = this.dimensions;
      this.renderer.setSize(width, height);
      this.camera.setSize(width, height);
    } else {
      console.error("could not resize");
    }
  }

  public toggleShowScaleModel() {
    this.showScaleModel = !this.showScaleModel;
    if (this.showScaleModel) {
      if (this.scaleModel) {
        this.addScaleModelToScene();
      } else {
        this.loadScaleModel();
      }
    } else {
      if (this.scaleModel) {
        this.scene.remove(this.scaleModel);
      }
    }
    // this.render();
  }

  getHitPoint(
    intersections: Intersection[]
  ): { position: Vector3; normal: Vector3; uv: Vector2 | null } | null {
    if (intersections.length === 0) {
      return null;
    }

    const hit = intersections[0];
    if (!hit.face) {
      return null;
    }

    if (hit.uv == null) {
      const result = {
        position: hit.point,
        normal: hit.face.normal,
        uv: null,
      };
      return result;
    } else {
      hit.face.normal.applyNormalMatrix(
        new Matrix3().getNormalMatrix(hit.object.matrixWorld)
      );

      const result = {
        position: hit.point,
        normal: hit.face.normal,
        uv: hit.uv,
      };
      return result;
    }
  }

  public setAnnotateMode() {
    if (this.mode === ModelViewMode.Annotate) {
      this.canvas?.setAttribute("class", "");
      this.mode = ModelViewMode.Default;
      this.controls!.enabled = true;
      this.controls!.clickOverride = undefined;
      return;
    }

    this.canvas?.setAttribute("class", "selectMode");
    this.mode = ModelViewMode.Annotate;
    this.controls!.enabled = false;
    this.controls!.clickOverride = (intersections: Intersection[]) => {
      const hit = this.getHitPoint(intersections);
      if (hit) {
        // Create annotation
        const wdg = new AnnotationWidget(this.proVizScene);
        wdg.deserialize({
          position: { x: hit.position.x, y: hit.position.y, z: hit.position.z },
          normal: { x: hit.normal.x, y: hit.normal.y, z: hit.normal.z },
          text: "Test",
        });
        this.execute(new AddNode(this, wdg));

        this.postUpdateTask();

        this.mode = ModelViewMode.Default;
        this.controls!.enabled = true;
        this.controls!.clickOverride = undefined;
        this.canvas?.setAttribute("class", "");
      }
    };
  }

  docHandleKey(e: KeyboardEvent) {
    switch (e.key) {
      case "e":
        this.controls?.changeMode("rotate");
        break;
      case "r":
        this.controls?.changeMode("scale");
        break;
    }
  }

  private async loadScaleModel() {
    const url = getAbsoluteUrl("models/human.glb");
    const loader = new ProVizLoader();
    this.scaleModel = await loader.LoadGLTF(url);
    setRenderOrder(this.scaleModel, 1);
    if (this.showScaleModel) {
      this.addScaleModelToScene();
    }
  }

  private getScaleModelCoords() {
    const pos = new Vector3();
    if (!this.modelWidget?.renderNode) {
      return pos;
    }
    const boundingBox = new Box3();
    boundingBox.setFromObject(this.modelWidget.renderNode);
    boundingBox.getSize(pos);
    const center = new Vector3();
    boundingBox.getCenter(center);
    pos.y = 0;
    pos.z = 0;
    pos.x = center.x + pos.x;
    return pos;
  }

  private updateScaleModelPos() {
    if (this.showScaleModel && this.scaleModel) {
      this.scaleModel.position.copy(this.getScaleModelCoords());
    }
  }

  private addScaleModelToScene() {
    if (this.scaleModel) {
      this.scaleModel.position.copy(this.getScaleModelCoords());
      this.scene.add(this.scaleModel);
    }
  }

  add(obj: BaseWidget, parent?: BaseWidget) {
    if (parent) {
      parent.addChild(obj, true);
    } else {
      this.proVizScene.add(obj);
    }
    obj.init();
    this.dispatchWidgetUpdate();
  }

  remove(node: BaseWidget) {
    this.proVizScene.remove(node);
    this.dispatchWidgetUpdate();
  }
}
