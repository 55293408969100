import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';

export class VariantOutputWidget extends BaseWidget {
  public static type: string = 'variant-output';

  // Data
  public modelId: string | undefined;
  public modelVariantId: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = VariantOutputWidget.type;
    this.widgetName = 'Variant Emitter';
    this.label = 'Variant Emitter';
    this.usage = 'Flow';
    this.category = 'Others';

    this.services = [
      {
        label: 'Emit Variant',
        name: 'emit',
      },
    ];

    this.events = [
      {
        label: 'Output Variant',
        name: 'output-variant',
      },
    ];

    this.addEventListener('service-emit', () => {
      this.triggerProVizEvent('output-variant', 'modelVariantId', this.modelVariantId);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('modelVariantId', 'Model Variant', 'Data', 'model-variant', 'string', true),
      this.createProperty('modelId', 'Model', 'Data', 'model', 'string', true),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.modelId = this.modelId;
    result.modelVariantId = this.modelVariantId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.modelId = data.modelId;
    this.modelVariantId = data.modelVariantId;
  }
}

ModuleService.Register(VariantOutputWidget.type, VariantOutputWidget);
