import { BaseWidgetDataConnection, BaseWidgetProperty, Button2D, ProVizScene, URLWidget } from '@proviz/proviz-sdk';
import { useEffect, useState } from 'react'
import { ManagerStore } from '../../../store';
import Button from '../../common/button/Button';
import CollapsibleHeaderIcon from '../../common/collapsiblegroup/CollapsibleHeader';
import PropertyRenderer from '../../sceneeditor/propertypanel/PropertyRenderer';

type Props = { scene: ProVizScene; };

export default function CheckoutButtonSettings(props: Props) {
    const { scene } = props;
    const [buttonWidget, setButtonWidget] = useState(scene.nodes.find(x => x.widgetType === Button2D.type) ?? null);
    const [properties, setProperties] = useState<BaseWidgetProperty[]>([]);
    const [expand, setExpand] = useState(false);
    const  manager  = ManagerStore(s=>s.ModelViewManager);


    useEffect(() => {
        setButtonWidget(scene.nodes.find(x => x.widgetType === Button2D.type) ?? null);
    }, [scene])

    useEffect(() => {
        if (!buttonWidget) {
            setProperties([]);
        } else {
            const btnProps = buttonWidget.getProperties();
            const urlProps = buttonWidget.children[0].getProperties();
            const link = urlProps.find(x => x.name === 'url');
            const newWindow = urlProps.find(x => x.name === 'newWindow')
            const buttonIcon = btnProps.find(x => x.name === 'buttonIcon')
            const hoverText = btnProps.find(x => x.name === 'hoverText')
            if (link && newWindow && buttonIcon && hoverText) {
                setProperties([link, newWindow, buttonIcon, hoverText]);
            } else {
                console.error("Button widget data has been corrupted", btnProps, urlProps);
            }
        }
    }, [buttonWidget]);

    function toggleButtonWidget() {
        if (buttonWidget) {
            while (buttonWidget.children[0]) {
                // manager.execute(new RemoveNode(manager, buttonWidget.children[0]))
                buttonWidget.removeNode(buttonWidget.children[0])
            }
            scene.remove(buttonWidget);
            // manager.execute(new RemoveNode(manager, buttonWidget))
            setButtonWidget(null)
        } else {
            const btn = new Button2D(scene);
            const link = new URLWidget(scene);
            btn.addChild(link);
            const conn = new BaseWidgetDataConnection();
            conn.event = 'clicked';
            conn.targetId = link.id;
            conn.targetService = 'open';
            btn.connections.push(conn);
            // manager.execute(new AddNode(manager, btn));
            scene.add(btn);
            setButtonWidget(btn);
        }
    }

    return <div>
        <div className="collapsible-properties-header">
            <h5>Link Button</h5>
            <CollapsibleHeaderIcon expanded={expand} handleClick={() => setExpand(!expand)} />
        </div>
        <ul>
            {expand &&
            <>
                <Button onClick={toggleButtonWidget}>
                    {buttonWidget ? "Remove Link Button" : 'Add Link Button'}
                </Button>
                {buttonWidget && 
                    <PropertyRenderer manager={manager} 
                        node={buttonWidget} properties={properties} />}
            </>
        }
        </ul>
    </div>;
}