import { IServiceOptions } from "..";
import { BaseDataService } from "../BaseDataService";

export class APIAccessKeyService extends BaseDataService<any> {
  constructor() {
    super("AccessKey");
  }

  async createKey(
    name: string,
    role: number,
    options?: IServiceOptions
  ): Promise<any> {
    return await this.request("", {
      method: "POST",
      body: { name, role },
      ...options,
    });
  }

  async createPersonal(options?: IServiceOptions): Promise<any> {
    return await this.request("personal", { method: "POST", ...options });
  }
}

const AccessKeyService = new APIAccessKeyService();

export default AccessKeyService;
