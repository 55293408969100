export const isAbortControllerSupported =
  global.window?.hasOwnProperty("AbortController");

export const noOp = () => null;

export const initAbortController = (): AbortController =>
  isAbortControllerSupported
    ? new AbortController()
    : ({ abort: noOp, signal: {} } as unknown as AbortController);

export const isAbortError = (e: any) => e.name === "AbortError";
