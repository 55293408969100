import { useEffect, useState } from "react";
import {
  CompanyService,
  getDefaultCompanyId,
  ProVizConfig,
  UserService,
} from "@proviz/api-services";
import Modal from "../modals/Modals";
import Button from "../button/Button";

import "./CompanySelector.css";
import { userStore } from "../../../store";

interface KeyValuePair {
  key: string;
  value: string;
}

export const CompanySelector = () => {
  const [companies, setCompanies] = useState<KeyValuePair[]>([]);
  const [company, setCompany] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >(undefined);
  const [showModal, setShowModal] = useState(false);

  const user = userStore((s) => s.user);
  const setPermissions = userStore((s) => s.setPermissions);

  useEffect(() => {
    if (ProVizConfig.impersonateCompanyId) {
      setSelectedCompanyId(ProVizConfig.impersonateCompanyId);
    } else if (user) {
      setSelectedCompanyId(getDefaultCompanyId(user));
    }
  }, [user]);

  useEffect(() => {
    async function updatePerms() {
      const perms = await UserService.getPermissions();
      setPermissions(perms);
    }
    updatePerms();
  }, [selectedCompanyId, setPermissions]);

  useEffect(() => {
    const activeCompany = companies.find((x) => x.key === selectedCompanyId);
    setCompany(activeCompany?.value || "");
  }, [companies, selectedCompanyId]);

  useEffect(() => {
    CompanyService.getAllKeyValue().then((x) => {
      const sortedCompanies = x.sort((a, b) => {
        if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        }
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      setCompanies(sortedCompanies);
    });
  }, [user]);

  const impersonate = async (id: string) => {
    ProVizConfig.impersonateCompany(id);
    setSelectedCompanyId(id);
  };

  if (user && companies.length > 1) {
    return (
      <>
        <div className="activeOrganization" onClick={() => setShowModal(true)}>
          {company || "[Not Found]"}
        </div>
        {showModal && (
          <Modal title="Change Organization" close={() => setShowModal(false)}>
            <div>
              {companies.map((kvp) => {
                return (
                  <div key={kvp.key} style={{ margin: "10px" }}>
                    <Button
                      fullWidth={true}
                      onClick={() => {
                        impersonate(kvp.key);
                        setShowModal(false);
                      }}
                    >
                      {kvp.value}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Modal>
        )}
      </>
    );
  }

  return <></>;
};
