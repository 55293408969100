import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetEvent } from '../../BaseWidgetEvent';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ResourceWidgetWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'resource-widget';

  // Data
  public resourceId: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Resource Widget';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = ResourceWidgetWidget.type;
    this.widgetName = 'Resource Widget';
    this.selectable = true;
    this.events = [];

    this.services = [
      {
        label: 'Apply',
        name: 'apply',
      },
    ];

    this.addEventListener('service-apply', (event: ProVizEventData) => {
      this.emitNewValue();
    });
  }

  private emitNewValue() {
    this.triggerProVizEvent('resource', 'widgetId', this.resourceId);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'resourceId',
        'Resource',
        'Data',
        'widget',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'This is value will be the resource id being set',
      ),
    ];
  }

  public getEvents(): IBaseWidgetEvent[] {
    return [
      ...super.getEvents(),
      {
        label: this.getWidgetName() || 'Resource',
        name: 'resource',
      },
    ];
  }

  private getWidgetName() {
    if (this.resourceId) {
      const widget = this.scene.getById(this.resourceId);
      if (widget) {
        return widget.label;
      }
    }
    return null;
  }

  public serialize(): any {
    const result = super.serialize();
    result.resourceId = this.resourceId;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.resourceId = data.resourceId;
  }
}

ModuleService.Register(ResourceWidgetWidget.type, ResourceWidgetWidget);
