import { Group, Mesh, Object3D, Material } from "three";

export function getMaterialByName(
  name: string,
  obj: Object3D | Group | Mesh
): Material | null {
  if (obj instanceof Mesh && obj.material) {
    if (obj.material instanceof Material) {
      if (obj.material.name === name) {
        return obj.material;
      }
    } else {
      for (let mat of obj.material) {
        if (mat.name === name) {
          return mat;
        }
      }
    }
  }
  for (let c of obj.children) {
    let mat = getMaterialByName(name, c);
    if (mat) {
      return mat;
    }
  }
  return null;
}
