import React, { ReactElement } from 'react'
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const cancelIcon = getAbsoluteUrl('images/cancel.svg');
const searchIcon = getAbsoluteUrl('images/search.svg');

interface Props {
    searchString: string;
    setSearchParams: (search: string) => void;
    clear: () => void;
}

export default function HierarchySearchBar(props: Props): ReactElement {
    const {  setSearchParams, clear, searchString } = props;

    function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParams(event.target.value);
    }

    return <div className="searchInputContainer">
        <div className="searchInput">
            <div className="input-container">
                <input type='text' onChange={handleInput} value={searchString} placeholder='Search...' />
            </div>
            <div className='search-icon-container' onClick={clear} >
                <img className="search-icon" src={searchString.length > 0 ? cancelIcon : searchIcon} alt=''/>
            </div>
        </div>
    </div>

}
