import { ReactElement, useEffect, useState } from 'react';
import { APIModel, APIModelVariant, APIMaterialSet, MaterialSetService, 
    ModelVariantService, ModelService } from "@proviz/api-services";
import SetMaterialSet from '../../../commands/SetMaterialSet';
import { flatMaterialNames } from '../../../graphics/utils/FlatMaterials';
import MaterialSubPanel from './MaterialSubPanel';
import { VariantSelector } from './VariantSelector';
import VariantDetails from './VariantDetails';
import { CollapsableGroup } from './CollapsableGroup';
import Button from '../../common/button/Button';
import { LoadingStore, ManagerStore } from '../../../store';

interface Props {
    model: APIModel;
}

function liveVariants(variants: APIModelVariant[] | null) {
    if(!variants) {  return []; }
    return variants ? variants.filter((v) => !v.deleted) : variants;
}

export default function VariantPanel(props: Props): ReactElement {
    const { model } = props;
    const  manager = ManagerStore(s=>s.ModelViewManager);
    const setLoadingText = LoadingStore( s => s.setLoadingText)
    const [variants, setVariants] = useState(liveVariants(model.modelVariants));
    const [defaultVariantId, setDefaultVariantId] = useState(model.defaultVariantId);
    let defaultMatSetId = '___unselected___';
    if(defaultVariantId) {
        let defaultVariant = variants.find((v) => v.id === defaultVariantId);
        if(defaultVariant) {
            defaultMatSetId = defaultVariant.materialSetId;
        }
    }
    const [materialSetId, setMaterialSetId] = useState(manager?.materialSet?.id
                                                        ?? defaultMatSetId);
    let variant = materialSetId 
            ? model.modelVariants?.find((v) => v.materialSetId === materialSetId) 
            : undefined;
    const [selection, setSelection] = useState<APIModelVariant | null >(variant ?? null);
    const [materialSet, setMaterialSet] = useState<APIMaterialSet | null>(selection?.materialSet ?? null);

    if(materialSet && !manager.materialSet) {
        manager.setMaterialSet(materialSet);
    }

    useEffect(() => {
        async function setAndApplyMaterialSet(newSet: APIMaterialSet) {
            setLoadingText('Applying materials...')
            await manager.setMaterialSet(newSet);
            setLoadingText('')
        }
        if (selection) {
            // manager.execute(new SetMaterialSet(manager, selection.materialSet));
            setAndApplyMaterialSet(selection.materialSet);
            setMaterialSet(selection.materialSet);
            setMaterialSetId(selection.materialSetId)
        } else {
            manager.execute(new SetMaterialSet(manager, undefined));
            setMaterialSet(null);
            setMaterialSetId('___unselected___');
        }
    }, [selection, manager, setLoadingText]); 

    async function uploadMtl(files: File[]) {
        try {
            if(variant && variant.materialSetId) {
                let updatedMatSet = await MaterialSetService.addMtlFileToSet(variant.materialSetId, files[0]);
                variant.materialSet = updatedMatSet;
            } else {
                let matSet = await MaterialSetService.uploadMaterialSetFromFile(files[0])
                await ModelVariantService.postModelVariant(model.id, matSet.id);
            }
            alert(`Material set upload succeeded.`)
        } catch(err) {
            alert(`Material set upload failed sorry ${err}`)
        }
    }

    async function createNewMaterialSet() {
        if(!manager.modelWidget) {
            return;
        }
        setLoadingText('Creating your material set...');
        const materials = await flatMaterialNames(manager.modelWidget.renderNode);
        const ms = await MaterialSetService.uploadNewMaterialSet(materials);
        const mv = await ModelVariantService.postModelVariant(model.id, ms.id);
        mv.materialSet = ms;
        if(model.modelVariants) {
            model.modelVariants.push(mv);
        } else {
            model.modelVariants = [mv];
        }
        setLoadingText('');
        setVariants(model.modelVariants);
        setSelection(mv);
        setMaterialSet(ms);
    }

    function setName(name: string) {
        materialSet && setMaterialSet({...materialSet, name});
        selection && (selection.materialSet.name = name);
    } 

    async function deleteModelVariant(variant: APIModelVariant) {
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Are you sure you want to delete the variant ${variant.materialSet?.name ?? ""}`))
        if(model.modelVariants) {
            try {
                const deletedVar = await ModelVariantService.deleteModelVariant(variant.id);

                // remove the deleted variant from the list of variants
                const filteredVariants = model.modelVariants.filter(v => v.id !== deletedVar.id);
                model.modelVariants = filteredVariants;
                setVariants(filteredVariants);
                
                // set the selected variant to either the first in the list or null
                const newSelection = filteredVariants.length > 0 ? filteredVariants[0] : null;
                setSelection(newSelection);
                setMaterialSet(newSelection ? newSelection.materialSet : null);

                await manager.refreshMaterials();
            } catch(e) {
                alert("We could not delete your Model Variant, try again later.");
            }
        }
    }

    async function setDefaultVariant(variantId: string) {
        setDefaultVariantId(variantId);
        let updatedModel = await ModelService.setDefaultVariant(model.id, variantId);
        model.defaultVariantId = updatedModel.defaultVariantId;
    }

    return <div className='controlPanelInterior'>
        <h2>Finishes</h2>
        <div className='centering-box'>
            <Button type='primary' onClick={createNewMaterialSet}>
                +Create New Material Set
            </Button>
        </div>

        <div className="variantInfo">
            <h6>Model Variants({model.modelVariants?.length ?? 0}):</h6>
            <VariantSelector
                variants={model.modelVariants}
                materialSetId={materialSetId}
                setSelection={setSelection}
                defaultVariantId={defaultVariantId}
                setDefaultVariant={setDefaultVariant}
                deleteModelVariant={deleteModelVariant}
            />
            {selection && <CollapsableGroup startExpanded={false} groupName='Edit Variant'>
                <VariantDetails variant={selection}
                    updateName={setName}
                    uploadMtl={uploadMtl}
                    setMaterialSet={setMaterialSet} />
            </CollapsableGroup>}
            {selection && materialSet &&
                <MaterialSubPanel
                    variantSelection={selection}
                    materialSet={materialSet}
                    setMaterialSet={setMaterialSet} />}
        </div>
    </div>;
}