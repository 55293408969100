import { BaseWidgetProperty } from "@proviz/proviz-sdk";
import { Fragment } from 'react'
import InfoButton from '../../common/infobutton/InfoButton';

interface Props {
    property: BaseWidgetProperty;
}

export const ImmutablePropertyRender = (props: Props) => {
    const { property } = props;
    if(!property.get) {
        return <Fragment />;
    }
    const val = property.get();
    const header = <div className='property-header'>
        <h4>{property.label}</h4>
        {property.infoText && <InfoButton infoText={property.infoText} />}
    </div>
    switch (property.widgetType) {
        case 'vec3': {
            return <div>
                {header}
                <p>x: {val.x}</p>
                <p>y: {val.y}</p>
                <p>z: {val.z}</p>
                </div>
        }
        case 'button':
        case 'bool':
            return <div>
                {header}
                <span>{val ? 'On' : 'Off'}</span>
            </div>;
        case 'number':
        case 'color':
        case 'string':
            return <div>
                {header}
                <p>{val}</p>
                </div>
        case 'multi-line-string':
            const textValue: string = property.get();
            return <div>
                {header}
                <textarea contentEditable={false} readOnly  defaultValue={textValue} />
            </div>;
        case 'transform':
            return <div>
                {header}
            </div>
        default:
            return <div>
                {header}
                <p>{val}</p>
            </div>
    }
}
