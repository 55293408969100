import { Matrix4, Object3D, PerspectiveCamera, Scene, Vector3 } from 'three';
import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

let renderer: CSS2DRenderer | undefined = undefined;

export class AnnotationWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'annotation';

  // Data
  public text: string = '';

  private spot?: Hotspot;
  private spotContent?: HTMLElement;
  private opened: boolean = false;

  public normal: Vector3 = new Vector3(0, 1, 0);

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = AnnotationWidget.type;
    this.widgetName = 'Annotation';
    this.label = 'Annotation';
    this.category = 'Core';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('text', 'Text', 'Core', 'string', 'string', true)];
  }

  public serialize(): any {
    const result = super.serialize();
    result.text = this.text;
    result.normal = {
      x: this.normal.x,
      y: this.normal.y,
      z: this.normal.z,
    };
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.text = data.text;
    if (data.normal) {
      this.normal.x = data.normal.x;
      this.normal.y = data.normal.y;
      this.normal.z = data.normal.z;
    }
  }

  public update(): void {
    if (renderer !== undefined && this.spot !== undefined) {
      renderer.updateView(this.scene.camera);
      renderer.renderObject(this.spot, this.scene.scene, this.scene.camera);
    }
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.spot = new Hotspot();
    this.spot.element.setAttribute('class', 'annotation');
    this.spot.normal = this.normal;
    this.spot.position.set(
      this.renderNode.position.x,
      this.renderNode.position.y,
      this.renderNode.position.z,
    );
    this.spot.updateWorldMatrix(false, false);

    this.spotContent = document.createElement('div');
    this.spotContent.setAttribute('class', 'hotspot-content hidden');
    this.spotContent.innerHTML = this.text;
    this.spot.element.appendChild(this.spotContent);

    const style = document.createElement('style');
    style.innerText = `
      .hotspot-content.hidden {
        display: none;
      }
      .hotspot-content {
        position: absolute;
        left: 35px;
        top: 0;
        padding: 10px;
        background: white;
        border-radius: 5px;
      }
    `;
    document.body.appendChild(style);

    this.spot.element.onclick = () => {
      this.opened = !this.opened;
      if (!this.spotContent) {
        console.error('No spot content');
        return;
      }
      this.spotContent.setAttribute(
        'class',
        'hotspot-content ' + (this.opened ? 'visible' : 'hidden'),
      );
    };

    if (!renderer) {
      if (this.scene.parentEl) {
        renderer = new CSS2DRenderer(this.scene.parentEl);
      } else {
        console.error('Not on dom. Cannot create annotation.');
      }
    }

    this.update();

    // create 2d node
    // const el = document.createElement('button');
    // el.setAttribute('class', 'hotspot');
    // el.setAttribute('style', 'position: absolute; top: 0; left: 0;');
    // el.innerText = this.text;

    // this.scene.renderer.domElement.parentElement.appendChild(el);

    // const geometry = new SphereGeometry( 0.05, 32, 16 );
    // const material = new MeshBasicMaterial( { color: 0xffffff } );
    // const sphere = new Mesh( geometry, material );
    // this.renderNode.add(sphere);

    return true;
  }

  public dispose(): void {
    super.dispose();
    this.spotContent?.remove();
    this.spotContent = undefined;
  }

  public setText(text: string) {
    this.text = text;
    if (this.spotContent) {
      this.spotContent.innerText = text;
    }
  }

  public remove(): void {
    if (this.spot) {
      this.spot.element.remove();
    }
  }
}

class CSS2DObject extends Object3D {
  element: HTMLElement;
  isCSS2DObject: boolean = true;

  constructor(element = document.createElement('div')) {
    super();

    this.element = element;

    this.element.style.position = 'absolute';
    this.element.style.userSelect = 'none';

    this.element.setAttribute('draggable', 'false');

    this.addEventListener('removed', function () {
      // @ts-ignore
      this.traverse(function (object) {
        if (object.element instanceof Element && object.element.parentNode !== null) {
          object.element.parentNode.removeChild(object.element);
        }
      });
    });
  }

  copy(source, recursive) {
    super.copy(source, recursive);

    this.element = source.element.cloneNode(true);

    return this;
  }
}
CSS2DObject.prototype.isCSS2DObject = true;

//

const _vector = new Vector3();
const _viewMatrix = new Matrix4();
const _viewProjectionMatrix = new Matrix4();
const _a = new Vector3();
const _b = new Vector3();

class CSS2DRenderer {
  domElement: HTMLElement;
  cache: { objects: WeakMap<any, any> };
  width: number;
  height: number;
  widthHalf: number;
  heightHalf: number;

  constructor(element?: HTMLElement) {
    this.cache = {
      objects: new WeakMap(),
    };

    const domElement = element || document.createElement('div');
    domElement.style.overflow = 'hidden';

    this.width = domElement.clientWidth;
    this.height = domElement.clientHeight;

    this.widthHalf = this.width / 2;
    this.heightHalf = this.height / 2;
    this.domElement = domElement;
  }

  getSize() {
    return {
      width: this.width,
      height: this.height,
    };
  }

  render(scene: Scene, camera: PerspectiveCamera) {
    if (scene.autoUpdate === true) scene.updateMatrixWorld();
    if (camera.parent === null) camera.updateMatrixWorld();

    _viewMatrix.copy(camera.matrixWorldInverse);
    _viewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, _viewMatrix);

    this.renderObject(scene, scene, camera);
    this.zOrder(scene);
  }

  updateView(camera: PerspectiveCamera) {
    _viewMatrix.copy(camera.matrixWorldInverse);
    _viewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, _viewMatrix);
  }

  renderObject(object: any, scene: Scene, camera: PerspectiveCamera) {
    if (object.isCSS2DObject) {
      object.onBeforeRender(this, scene, camera);

      _vector.setFromMatrixPosition(object.matrixWorld);
      _vector.applyMatrix4(_viewProjectionMatrix);

      const camPos = new Vector3();
      camPos.copy(camera.position);
      const target = new Vector3();
      target.setFromMatrixPosition(object.matrixWorld);
      camPos.sub(target);

      const normalWorld = new Vector3();
      normalWorld.copy(object.normal).transformDirection(object.matrixWorld);

      let show = true;

      if (camPos.dot(normalWorld) < 0) {
        // hide
        show = false;
      }

      const element = object.element;

      const defaultStyle = `
        display: ${show ? 'block' : 'none'}; position: absolute; top: 0; left: 0; 
        width: 25px;
        height: 25px;
        background: white;
        border-radius: 50px;
        cursor: pointer;
        `;

      if (/apple/i.test(navigator.vendor)) {
        // https://github.com/mrdoob/three.js/issues/21415
        element.setAttribute(
          'style',
          `${defaultStyle}user-select: none;transform: translate(-50%,-50%) translate(${Math.round(
            _vector.x * this.widthHalf + this.widthHalf,
          )}px,${Math.round(-_vector.y * this.heightHalf + this.heightHalf)}px)`,
        );
      } else {
        const x = _vector.x * this.widthHalf + this.widthHalf;
        const y = -_vector.y * this.heightHalf + this.heightHalf;
        element.setAttribute(
          'style',
          `${defaultStyle} user-select: none;transform: translate(-50%,-50%) translate(${x}px,${y}px)`,
        );
      }

      // element.style.display = ( object.visible && _vector.z >= - 1 && _vector.z <= 1 ) ? '' : 'none';

      const objectData = {
        distanceToCameraSquared: this.getDistanceToSquared(camera, object),
      };

      this.cache.objects.set(object, objectData);

      if (element.parentNode !== this.domElement) {
        this.domElement.appendChild(element);
      }

      object.onAfterRender(this, scene, camera);
    }

    for (let i = 0, l = object.children.length; i < l; i++) {
      this.renderObject(object.children[i], scene, camera);
    }
  }

  getDistanceToSquared(object1: any, object2: any) {
    _a.setFromMatrixPosition(object1.matrixWorld);
    _b.setFromMatrixPosition(object2.matrixWorld);

    return _a.distanceToSquared(_b);
  }

  filterAndFlatten(scene: Scene) {
    const result: any[] = [];

    scene.traverse(function (object: any) {
      if (object.isCSS2DObject) result.push(object);
    });

    return result;
  }

  zOrder(scene: Scene) {
    const sorted: any[] = this.filterAndFlatten(scene).sort(function (a, b) {
      // @ts-ignore
      const distanceA = this.cache.objects.get(a).distanceToCameraSquared;
      // @ts-ignore
      const distanceB = this.cache.objects.get(b).distanceToCameraSquared;

      return distanceA - distanceB;
    });

    const zMax = sorted.length;

    for (let i = 0, l = sorted.length; i < l; i++) {
      sorted[i].element.style.zIndex = zMax - i;
    }
  }
}

class Hotspot extends CSS2DObject {
  public normal: Vector3 = new Vector3();
}

ModuleService.Register(AnnotationWidget.type, AnnotationWidget);
