import { ReactElement, useEffect, useState } from "react";
import { ManagerStore } from "../../../../store";
import Button from "../../button/Button";

type SaveState = "saving" | "failed" | "ok" | "";

interface Props {
  title?: string;
  sceneSaveHandler: any;
}

export default function SaveButton(props: Props): ReactElement {
  let { title, sceneSaveHandler } = props;
  const manager = ManagerStore( s => s.SceneManager );
  const [requestState, setRequestState] = useState<SaveState>("");

  title = title ?? "Save";

  const saveScene = async () => {
    try {
      manager.proVizScene.setSceneTitle(manager.apiScene.name);
      setRequestState("saving");
      await manager.save();
      setRequestState("ok");
      sceneSaveHandler();
      setTimeout(() => {
        setRequestState("");
      }, 4000);
    } catch (e: any) {
      setRequestState("failed");
      if (e.message === "Out of Date") {
        alert(
          "Your version of this scene is out of date, refresh to continue to edit it."
        );
      }
    }
  };

  const handleKeypress = (evt: KeyboardEvent) => {
    if ((evt.metaKey || evt.ctrlKey) && evt.code === "KeyS") {
      evt.preventDefault();
      evt.stopPropagation();
      saveScene();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeypress, true);
    return () => {
      document.removeEventListener("keydown", handleKeypress, true);
    };
  });

  return (
    <Button
      classNames={`saveButton ${requestState}`}
      onClick={() => saveScene()}
    >
      {requestState === "saving"
        ? "Saving"
        : requestState === "ok"
        ? "Saved"
        : title}
    </Button>
  );
}
