import create from "zustand";
import {
  APIFile,
  APISprite,
  APITexture,
  APIModel,
  APITarget,
  isAbortError,
  SceneService,
  ModelService,
  TextureService,
  FileService,
  SpriteService,
  TargetService,
  TargetType,
} from "@proviz/api-services";
import { ISceneRecord } from "../components/home/ThumbnailCard";
import {
  HandleSelectType,
  ResourceType,
} from "../components/common/fileselect/useFileSelect";

/********************************************************************** TYPES */
type ResourceManagerTypes = {
  /////////////////////////////////////// Variables & Set Variables
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
  setTotalPages: (totalPages: number) => void;
  displayQuantity: number;
  setDisplayQuantity: (displayQuantity: number) => void;
  searchParams: string;
  setSearchParams: (searchParams: string) => void;
  files: APIFile[];
  setFiles: (files: APIFile[]) => void;
  models: APIModel[];
  setModels: (models: APIModel[]) => void;
  scenes: ISceneRecord[];
  setScenes: (scenes: ISceneRecord[]) => void;
  uploadFile: boolean;
  setUploadFile: (uploadFile: boolean) => void;
  waitingText: string;
  setWaitingText: (waitingText: string) => void;
  displayScenes: ISceneRecord[];
  setDisplayScenes: (displayScenes: ISceneRecord[]) => void;
  textures: APITexture[];
  setTextures: (textures: APITexture[]) => void;
  sprites: APISprite[];
  setSprites: (sprites: APISprite[]) => void;
  update: boolean;
  setUpdate: (update: boolean) => void;
  error: boolean;
  setError: (error: boolean) => void;
  resourceType: ResourceType;
  setResourceType: (resourceType: ResourceType) => void;
  fileNotTexture: boolean;
  setFileNotTexture: (fileNotTexture: boolean) => void;
  selectResourceCallback: HandleSelectType | undefined;
  setSelectResourceCallback: (
    selectResourceCallback: HandleSelectType | undefined
  ) => void;
  extensions: string[] | undefined;
  setExtensions: (extensions: string[] | undefined) => void;
  targets: APITarget[];
  setTargets: (targets: APITarget[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  getExtentions: (resourceType: string) => string[] | undefined;
  currentFolderId: string | null,
  setCurrentFolderID: (currentFolderId: string | null ) => void
  ///////////////////////////////////////  Functions
  handleDisplayQuantChange: (e: any) => void;
  catchError: (e: Error, string: string) => void;
  openFileSelect: (
    fileType: ResourceType,
    fileNotTexture: boolean,
    onSelectHandler: HandleSelectType
  ) => void;
  CallModelService: () => void;
  CallSceneService: () => void;
  CallTextureService: () => void;
  CallFileService: () => void;
  CallSpriteService: () => void;
  CallAreaTargetService: () => void;
  CallModelTargetService: () => void;
  CallImageService: () => void;
  LoadService: () => void;
};

/******************************************************************** RESOURCE KEY */
const resource: { [key: string]: string[] } = {
  Video: ["mp4", "mov", "webm"],
  Audio: ["wav", "mp3"],
  Image: ["png", "jpg", "jpeg"],
  Texture: ["png", "jpg", "jpeg"],
  Sprite: ["png"],
};

/******************************************************************** STORE */
export const ResourceManagerStore = create<ResourceManagerTypes>(
  (set, get) => ({
    /////////////////////////////////////// Variables & Set Variables
    page: 0,
    setPage: (page) => {
      set(() => ({ page }));
    },
    searchParams: "",
    setSearchParams: (searchParams) => {
      set(() => ({ searchParams }));
    },
    resourceType: "Texture",
    setResourceType: (resourceType) => {
      const { setExtensions, getExtentions } = get();
      setExtensions(getExtentions(resourceType));
      set(() => ({ resourceType }));
    },
    update: false,
    setUpdate: (update) => {
      set(() => ({ update }));
    },
    displayQuantity: 20,
    setDisplayQuantity: (displayQuantity) => {
      set(() => ({ displayQuantity }));
    },
    fileNotTexture: true,
    setFileNotTexture: (fileNotTexture) => {
      set(() => ({ fileNotTexture }));
    },
    totalPages: 0,
    setTotalPages: (totalPages) => set(() => ({ totalPages })),
    files: [],
    setFiles: (files) => set(() => ({ files })),
    uploadFile: false,
    setUploadFile: (uploadFile) => set(() => ({ uploadFile })),
    targets: [],
    setTargets: (targets) => set(() => ({ targets })),
    models: [],
    setModels: (models) => set(() => ({ models })),
    scenes: [],
    setScenes: (scenes) => set(() => ({ scenes })),
    displayScenes: [],
    setDisplayScenes: (displayScenes) => set(() => ({ displayScenes })),
    waitingText: "",
    setWaitingText: (waitingText) => set(() => ({ waitingText })),
    textures: [],
    setTextures: (textures) => set(() => ({ textures })),
    sprites: [],
    setSprites: (sprites) => set(() => ({ sprites })),
    extensions: undefined,
    setExtensions: (extensions) => set(() => ({ extensions })),
    getExtentions: (resourceType) =>
      resource[resourceType] ? resource[resourceType] : undefined,
    selectResourceCallback: undefined,
    setSelectResourceCallback: (selectResourceCallback) =>
      set(() => ({ selectResourceCallback })),
    loading: false,
    setLoading: (loading) => set(() => ({ loading })),
    error: false,
    setError: (error) => set(() => ({ error })),
    currentFolderId: null,
    setCurrentFolderID: (currentFolderId) => set(() => ({ currentFolderId })),

    ///////////////////////////////////////  Functions
    catchError: (e: Error, string: string) => {
      const { setError } = get();
      console.error(string, e);
      if (isAbortError(e)) return; // if the query was aborted, do nothing
      console.error(string, e);
      setError(true);
    },
    handleDisplayQuantChange: (e: any) => {
      const { setDisplayQuantity } = get();
      setDisplayQuantity(e.target.value);
    },
    openFileSelect: (
      fileType: ResourceType,
      fileNotTexture: boolean,
      onSelectHandler: HandleSelectType
    ) => {
      const {
        setPage,
        setSearchParams,
        setError,
        setResourceType,
        setFileNotTexture,
        setSelectResourceCallback,
      } = get();

      setPage(0);
      setSearchParams("");
      setError(false);
      setResourceType(fileType);
      setFileNotTexture(fileNotTexture);

      setSelectResourceCallback((id: string | null) => {
        console.log('Select resource callback');
        onSelectHandler(id);
        setSelectResourceCallback(undefined);
      });
    },
    // Indivitual Service Calls
    CallModelService: () => {
      console.log('call model service');

      const {
        page,
        displayQuantity,
        searchParams,
        setModels,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
        currentFolderId
      } = get();

      setUpdate(false);
      setLoading(true);

      if (currentFolderId) {
        ModelService.pagedByFolder(
          currentFolderId,
          page,
          displayQuantity,
          searchParams
        )
          .then((_models) => {
            console.log("got models", _models);
            setModels(_models.data);
            setTotalPages(_models.pagesTotal);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            catchError(e, "Requesting models failed");
          });
      } else {
        ModelService.paged(page, displayQuantity, searchParams)
          .then((_models) => {
            console.log("got models", _models);
            setModels(_models.data);
            setTotalPages(_models.pagesTotal);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            catchError(e, "Requesting models failed");
          });
      }
    },
    CallSceneService: () => {
      const {
        page,
        displayQuantity,
        searchParams,
        setScenes,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
        currentFolderId
      } = get();

      setUpdate(false);
      setLoading(true);

      if (currentFolderId) {
        SceneService.pagedByFolder(
          currentFolderId,
          page,
          displayQuantity,
          searchParams
        )
          .then((data) => {
            setScenes(data.data);
            setTotalPages(data.pagesTotal);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            catchError(e, "Requesting models failed");
          });
      } else {
        SceneService.paged(page, displayQuantity, searchParams)
        .then((data) => {
          setScenes(data.data);
          setTotalPages(data.pagesTotal);
          setLoading(false);
        })
        .catch((e) => catchError(e, "Requesting scene failed"));
      }
    },
    CallTextureService: () => {
      const {
        page,
        displayQuantity,
        searchParams,
        setTextures,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
      } = get();

      setUpdate(false);
      setLoading(true);

      TextureService.paged(page, displayQuantity, searchParams)
        .then((_textures) => {
          setTextures(_textures.data);
          setTotalPages(_textures.pagesTotal);
          setLoading(false);
        })
        .catch((e) => catchError(e, "Requesting textures failed"));
    },
    CallImageService: () => {
      const {
        page,
        displayQuantity,
        searchParams,
        resourceType,
        setFiles,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
      } = get();
      
      setUpdate(false);
      setLoading(true);

      FileService.getAllByFileTypePaged(
        resourceType,
        page,
        displayQuantity,
        searchParams
      )
        .then((_files) => {
          setFiles(_files.data);
          setTotalPages(_files.pagesTotal);
          setLoading(false);
        })
        .catch((e) => catchError(e, "Requesting images failed"));
    },
    CallFileService: () => {
      const {
        page,
        displayQuantity,
        searchParams,
        resourceType,
        setFiles,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
      } = get();

      setUpdate(false);
      setLoading(true);

      FileService.getAllByFileTypePaged(
        resourceType,
        page,
        displayQuantity,
        searchParams
      )
        .then((_files) => {
          setFiles(_files.data);
          setTotalPages(_files.pagesTotal);
          setLoading(false);
        })
        .catch((e) => catchError(e, "Requesting images failed"));
    },
    CallSpriteService: () => {
      const {
        page,
        displayQuantity,
        searchParams,
        setSprites,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
      } = get();

      setUpdate(false);
      setLoading(true);

      SpriteService.paged(page, displayQuantity, searchParams)
        .then((_sprites) => {
          setSprites(_sprites.data);
          setTotalPages(_sprites.pagesTotal);
          setLoading(false);
        })
        .catch((e) => catchError(e, "Requesting sprites failed"));
    },
    CallAreaTargetService: () => {
      const {
        page,
        displayQuantity,
        searchParams,
        setTargets,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
      } = get();

      setUpdate(false);
      setLoading(true);

      TargetService.getAllPagedWithTargetType(
        page,
        displayQuantity,
        searchParams,
        [TargetType.AreaTarget]
      )
        .then((_targets) => {
          setTargets(_targets.data);
          setTotalPages(_targets.pagesTotal);
          setLoading(false);
        })
        .catch((e) => catchError(e, "Requesting area targets failed"));
    },
    CallModelTargetService: () => {
      const {
        page,
        displayQuantity,
        searchParams,
        setTargets,
        setTotalPages,
        setLoading,
        catchError,
        setUpdate,
      } = get();

      setUpdate(false);
      setLoading(true);

      TargetService.getAllPagedWithTargetType(
        page,
        displayQuantity,
        searchParams,
        [TargetType.Model, TargetType.Model360]
      )
        .then((_targets) => {
          setTargets(_targets.data);
          setTotalPages(_targets.pagesTotal);
          setLoading(false);
        })
        .catch((e) => catchError(e, "Requesting model targets failed"));
    },
    // Main Service Loader
    LoadService: () => {
      const {
        resourceType,
        fileNotTexture,
        CallModelService,
        CallSceneService,
        CallTextureService,
        CallFileService,
        CallSpriteService,
        CallAreaTargetService,
        CallModelTargetService,
        CallImageService,
      } = get();

      console.log('load resource type', resourceType);

      if (resourceType === "Model") {
        CallModelService();
      } else if (resourceType === "Scene") {
        CallSceneService();
      } else if (resourceType === "Texture" && !fileNotTexture) {
        CallTextureService();
      } else if (resourceType === "Image") {
        CallImageService();
      } else if (resourceType === "Sprite") {
        CallSpriteService();
      } else if (resourceType === "AreaTarget") {
        CallAreaTargetService();
      } else if (resourceType === "ModelTarget") {
        CallModelTargetService();
      } else {
        CallFileService();
      }

    },
  })
);
