export const FileTypes = [
  "Model",
  "Video",
  "Audio",
  "Image",
  "Texture",
  "Sprite",
  "EnvMap",
  "Subtitle",
];
export type FileType = typeof FileTypes[number];
