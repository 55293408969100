import { ReactElement, useState } from "react";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import Modal from "../modals/Modals";

const infoIcon = getAbsoluteUrl("images/InfoIcon.svg");

export default function InfoModal(): ReactElement {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  return (
    <div className="iconFullContainer">
      <div className="iconContainer">
        <img
          className="icon"
          src={infoIcon}
          onClick={openModal}
          alt="Info Modal Icon"
        />
      </div>
      {open ? (
        <Modal
          title={"Hotkeys you can use"}
          close={closeModal}
          children={
            <table>
              <tr>
                <th>Available Hotkeys</th>
                <th>Action</th>
                <th>Role</th>
              </tr>
              <tr>
                <td>W (Key)</td>
                <td>Move in space</td>
                <td>It allows to move the resources in it's 3 axes</td>
              </tr>
              <tr>
                <td>E (Key)</td>
                <td>Rotation</td>
                <td>Enable rotation for the selected resource</td>
              </tr>
              <tr>
                <td>R (Key)</td>
                <td>Scale</td>
                <td>Enables the functionality of scaling the resource</td>
              </tr>
              <tr>
                <td>F (Key)</td>
                <td>Focus</td>
                <td>Focus the selected widget in the editor</td>
              </tr>
              <tr>
                <td>DEL (Delete Key)</td>
                <td>Delete</td>
                <td>Delete the selected widget in the editor</td>
              </tr>
              <tr>
                <td>Ctrl + C/V</td>
                <td>Copy or Paste</td>
                <td>Used to copy or paste widgets in the editor</td>
              </tr>
              <tr>
                <td>Ctrl + D</td>
                <td>Duplicate</td>
                <td>Used to duplicate widgets in the editor</td>
              </tr>
              <tr>
                <td>Ctrl + Z</td>
                <td>Undo Changes</td>
                <td>Allows you to go back to your previous changes</td>
              </tr>
              <tr>
                <td>Shift Left Click</td>
                <td>Paste Properties</td>
                <td>Used to paste copied properties</td>
              </tr>
            </table>
          }
        />
      ) : (
        open
      )}
    </div>
  );
}
