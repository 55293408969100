import { DirectionalLightWidget, ModelWidget } from '../widgets';
import { ProVizScene } from '..';
import { BaseWidgetDataConnection } from '../widgets/BaseWidgetDataConnection';

export function setupDefaultModelScene(
  provizScene: ProVizScene,
  modelId: string,
  variantId?: string,
): void {
  provizScene.groundOn = false;
  provizScene.autoRotate = true;
  provizScene.arEnabled = true;

  const directionalLight = new DirectionalLightWidget(provizScene);
  directionalLight.setPosition({ x: -1.5, y: 1.5, z: 0 });
  directionalLight.castShadow = true;
  directionalLight.intensity = 0.5;
  directionalLight.shadowIntensity = 3;
  directionalLight.shadowSoftness = 5;
  directionalLight.init();
  provizScene.add(directionalLight);

  const modelWidget = new ModelWidget(provizScene);
  const data = {
    modelId,
    deferInit: true,
    modelVariantId: variantId,
    castShadow: true,
    playAnimationOnLoad: true,
  };
  console.log('Setting up model scene', data);
  modelWidget.deserialize(data);
  // modelId.
  // modelWidget.modelId = modelId;
  // modelWidget.deferInit = true;
  // modelWidget.modelVariantId = variantId;
  // modelWidget.castShadow = true;
  // modelWidget.playAnimationOnLoad = true;
  provizScene.add(modelWidget);

  const fitLightConn = new BaseWidgetDataConnection();
  fitLightConn.event = 'fit-to';
  fitLightConn.targetId = directionalLight.id;
  fitLightConn.targetService = 'fit-to';
  modelWidget.connections.push(fitLightConn);
}
