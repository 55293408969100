import { debounce } from "lodash";
import { APIUser, UserService } from "@proviz/api-services";
import { ReactElement, useEffect, useRef, useState } from "react";
import Input from "../common/input/input";
import { userStore } from "../../store";
import Button from "../common/button/Button";

export default function EditAccount(): ReactElement {
  const user = userStore((s) => s.user);
  const setUser = userStore((s) => s.setUser);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const isFirstRender = useRef(true);

  const debouncedUpdate = useRef(
    debounce(
      async (data: APIUser) => {
        const updatedUser = await UserService.update(data);
        setUser(updatedUser);
      },
      1000,
      { trailing: true }
    )
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    if (isFirstRender.current) {
      setFirstName(user.firstName);
      setLastName(user.lastName);

      // we should not update the server when we first load the values
      isFirstRender.current = false;
      return;
    }

    const userData = Object.assign({}, user);
    userData.firstName = firstName;
    userData.lastName = lastName;
    debouncedUpdate.current(userData);
  }, [user, firstName, lastName]);

  return (
    <div className="userList">
      <table>
        <tr>
          <td>First Name</td>
          <td>
            <Input value={firstName} onChange={(val) => setFirstName(val)} />
          </td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>
            <Input value={lastName} onChange={(val) => setLastName(val)} />
          </td>
        </tr>
      </table>
      {user && (
        <p>ProViz User since: {new Date(user?.created).toLocaleString()}</p>
      )}
      <Button type="default" onClick={() => debouncedUpdate}>
        Save Changes
      </Button>
    </div>
  );
}
