import { useEffect, useState, useRef } from 'react';

export const useHover = () => {
    const [hoverShow, setHoverShow] = useState(false);
    const [inInfoBox, setInInfoBox] = useState(false);
    const [hideTimer, setHideTimer] = useState<NodeJS.Timer | undefined>(undefined);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const cardRef = useRef<HTMLDivElement>(null);

    const setHover = (state: boolean) => {
        if (state) {
            setHoverShow(state);
            if (hideTimer) {
                clearTimeout(hideTimer);
                setHideTimer(undefined);
            }
        } else {
            setHideTimer(setTimeout(() => {
                setHoverShow(false);
                setHideTimer(undefined);
            }, 100));
        }
    }

    useEffect(() => {
        if(cardRef.current && (hoverShow || inInfoBox )) {
            // an absolutely positioned div gets hidden behind the canvas
            // or other elements. So we're using a fixed position element
            // and setting the position based off of the position of the anchor 
            // element on the page
            const pos = cardRef.current.getBoundingClientRect();
            setTop(pos.y);
            setLeft(pos.x);
        }
    }, [hoverShow, inInfoBox])

    return {
        cardRef, 
        setHover, 
        hoverShow, 
        inInfoBox, 
        setInInfoBox, 
        top, 
        left
    }
}
