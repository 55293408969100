export default class ProVizXRRequestCache<T> {
    requests: {[id: string]: {
        callbacks: ((arg: T) => void)[],
        result: T | undefined
    }} = {};

    public request(id: string, makeRequest: () => void): Promise<T> {
        if (!this.requests[id]) {
            this.requests[id] = {
                callbacks: [],
                result: undefined
            }
            makeRequest();
        }

        return new Promise<T>((resolve, reject) => {
            const result = this.requests[id].result;
            if (result) {
                resolve(result)
            } else {
                this.requests[id].callbacks.push(resolve);
            }
        });
    }

    public resolve(id: string, value: T) {
        this.requests[id].callbacks.forEach((c) => c(value));
        this.requests[id].callbacks = [];
        this.requests[id].result = value;
    }
}