import { useState } from "react";
import { ReactElement } from "react";
import Modal from "../../common/modals/Modals";
import { FileService, ModelService, TargetService, TargetType } from "@proviz/api-services";
import Dropzone from "../../common/dropzone/Dropzone";
import Button from "../../common/button/Button";

interface Props {
    close: () => void;
}

export default function TargetCreateModal(props: Props): ReactElement {

    const [ targetType, setTargetType ] = useState<TargetType | undefined>(undefined);
    const [ xmlFile, setXmlFile ] = useState<File | undefined>(undefined);
    const [ datFile, setDatFile ] = useState<File | undefined>(undefined);
    const [ glbFile, setGlbFile ] = useState<File | undefined>(undefined);
    const [ guideFile, setGuideFile ] = useState<File | undefined>(undefined);

    const [ uploading, setUploading ] = useState(false);
    const [ uploadMessage, setUploadMessage ] = useState('');

    const createTarget = async () => {
        setUploading(true);

        if (targetType === undefined) {
            throw new Error('Invalid target type selected');
        }

        const settings = {
            xmlFileId: '',
            datFileId: '',
            displayModelId: '',
            target: '',
            guideFileId: ''
        }

        if (xmlFile) {
            setUploadMessage('Uploading XML');

            const xmlResult = await FileService.uploadFile(xmlFile, 'Data')
            settings.xmlFileId = xmlResult.id;
            // Use the first part of the file name (without the extension)
            // As the target identifier.
            // This is a stop-gap, the best way to do this would be to parse
            // the xml file and retrieve it from there
            settings.target = xmlFile.name.split('.')[0];
        } else {
            throw new Error('An xml file was not selected');
        }

        if (datFile) {
            setUploadMessage('Uploading DAT');

            const datResult = await FileService.uploadFile(datFile, 'Data');
            settings.datFileId = datResult.id;
        } else {
            throw new Error('A dat file was not selected');
        }

        if (targetType === TargetType.Model && guideFile) {
            setUploadMessage('Uploading Guide Image');
            const guideResult = await FileService.uploadFile(guideFile, 'Data');
            settings.guideFileId = guideResult.id;
        }

        if (glbFile) {
            setUploadMessage('Uploading Model');

            const parts = glbFile.name.split('.');
            parts.splice(parts.length - 1, 1);
            const fileName = parts.join('.'); 

            const modelResult = await ModelService.uploadModel(fileName, glbFile, 'glb' );
            settings.displayModelId = modelResult.id;
        } else {
            throw new Error('A model file was not selected');
        }

        setUploadMessage('Creating Target');
        await TargetService.createTarget(settings.target, targetType, JSON.stringify(settings));

        props.close();
    };

    return <Modal title={"Create Target"} close={props.close}>
        {targetType === undefined && <div>
            <h3>What type of target?</h3>
            <Button type="primary" onClick={() => setTargetType(TargetType.AreaTarget)}>Area Target</Button>
            <Button type="primary" onClick={() => setTargetType(TargetType.Model)}>Model Target</Button>
        </div>}

        {!uploading && targetType === TargetType.AreaTarget && <div>
            <h3>Area Target</h3>
            <div>Instructions on creating an area target.</div>
            <h5>XML File</h5>
            <Dropzone small={true} showFilesSelected={true} onUpload={(acceptedFiles: File[]) => { setXmlFile(acceptedFiles.length > 0 ? acceptedFiles[0] : undefined) }} accept={".xml"} reset={() => {}} ></Dropzone>
            <h5>DAT File</h5>
            <Dropzone small={true} showFilesSelected={true} onUpload={(acceptedFiles: File[]) => { setDatFile(acceptedFiles.length > 0 ? acceptedFiles[0] : undefined) }} accept={".dat"} reset={() => {}} ></Dropzone>
            <h5>Authoring Model File</h5>
            <Dropzone small={true} showFilesSelected={true} onUpload={(acceptedFiles: File[]) => { setGlbFile(acceptedFiles.length > 0 ? acceptedFiles[0] : undefined) }} accept={".glb"} reset={() => {}} ></Dropzone>
            <br />
            {xmlFile !== undefined && glbFile !== undefined && datFile !== undefined && 
                <Button type="primary" onClick={() => createTarget()}>
                    Create
                </Button>}
        
        </div>}

        {!uploading && targetType === TargetType.Model && <div>
            <h3>Model Target</h3>
            <div>Instructions on creating a model target.</div>
            <h5>XML File</h5>
            <Dropzone small={true} showFilesSelected={true} onUpload={(acceptedFiles: File[]) => { setXmlFile(acceptedFiles.length > 0 ? acceptedFiles[0] : undefined) }} accept={".xml"} reset={() => {}} ></Dropzone>
            <h5>DAT File</h5>
            <Dropzone small={true} showFilesSelected={true} onUpload={(acceptedFiles: File[]) => { setDatFile(acceptedFiles.length > 0 ? acceptedFiles[0] : undefined) }} accept={".dat"} reset={() => {}} ></Dropzone>
            <h5>Guide File</h5>
            <Dropzone small={true} showFilesSelected={true} onUpload={(acceptedFiles: File[]) => { setGuideFile(acceptedFiles.length > 0 ? acceptedFiles[0] : undefined) }} accept={".png"} reset={() => {}} ></Dropzone>
            <h5>Display Model File</h5>
            <Dropzone small={true} showFilesSelected={true} onUpload={(acceptedFiles: File[]) => { setGlbFile(acceptedFiles.length > 0 ? acceptedFiles[0] : undefined) }} accept={[".glb", ".gltf"]} reset={() => {}} ></Dropzone>
            <br />
            {xmlFile !== undefined && glbFile !== undefined && datFile !== undefined && guideFile !== undefined && 
                <Button type='primary' onClick={() => createTarget()}>
                    Create
                </Button>}
        </div>}

        {uploading && <div>
            {uploadMessage}    
        </div>}
        
    </Modal>
}