import { BaseWidget } from "@proviz/proviz-sdk";
import create from "zustand";

/********************************************************************* TYPES */
type AnimationStoreTypes = {
  time: number;
  setTime: (time: number) => void;
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  widget: BaseWidget | null;
  setWidget: (widget: BaseWidget) => void;
  track: number;
  setTrack: (track: number) => void;
};

/********************************************************************* STORE */
export const AnimationStore = create<AnimationStoreTypes>((set) => ({
  time: 0,
  setTime: (time) => set({ time }),
  menuOpen: false,
  setMenuOpen: (menuOpen) => set({ menuOpen }),
  widget: null,
  setWidget: (widget) => set({ widget }),
  track: 0,
  setTrack: (track) => set({ track }),
}));
