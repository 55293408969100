import { ReactNode, useEffect, useState } from 'react'
import Button from '../button/Button';

type Props = {
    buttonText: string;
    icon?: string;
    children: ReactNode;
    className?: string;
    onClick?: (e?: any) => void;
    onHide?: () => void;
    onShow?: () => void;
}

export default function HoverDropdown(props: Props) {
    const { icon, buttonText, className, children } = props;
    const [hoverShow, setHoverShow] = useState(false);
    const [inBody, setInBody] = useState(false);
    const [inButton, setInButton] = useState(false);

    useEffect(() => {
        const state = inBody || inButton;
        if (state !== hoverShow) {
            if (state && props.onShow) {
                props.onShow();
            } else if (props.onHide) {
                props.onHide();
            }
        }
        setHoverShow(state);
    }, [inBody, inButton, hoverShow, props])
    
    return <div className={`hover-dropdown-container ${className || ''}`}>
        <Button
            onMouseEnter={() => setInBody(true)}
            onMouseLeave={() => setInButton(false)} 
            onClick={props.onClick}>
                {icon 
                    ? <span className={`icon ${icon}`} title={buttonText}></span>
                    : buttonText 
                }
        </Button>
        {hoverShow &&
            <div onMouseEnter={() => setInBody(true)}
                onMouseLeave={() => setInBody(false)}
                className='dropdown-panel'>
                    {children}
            </div>}
    </div>;
}