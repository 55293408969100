interface Props {
    value: string;
    handleChange: (e : any) => void;
}

export const AlphaModeInput = (props: Props) => {
    const { value, handleChange } = props;
    return <>
            <p>Alpha Mode</p>
            <select value={value || ''} onChange={(e) => handleChange(e.target.value)} >
                    <option value='OPAQUE'>Opaque</option>
                    <option value='BLEND'>Blend</option>
                    <option value='MASK'>Mask</option>
            </select>
        </>;
}
