import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetEvent } from '../../BaseWidgetEvent';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class SequenceWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'sequence';

  // Data
  private count: number = 1;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Sequence';

    this.usage = 'Flow';
    this.category = 'Events';

    this.widgetType = SequenceWidget.type;
    this.widgetName = 'Sequence';
    this.selectable = true;
    this.events = [];

    this.services = [];
    this.addService('Trigger', 'trigger', '<b>Triggers this sequence of events</i>', () => {
      for (let i = 0; i < this.count; i++) {
        this.triggerProVizEvent((i + 1).toString(), 'none');
      }
    });
  }

  public migrate(data: any): Promise<any> {
    console.log('migrate seq');
    if (!data.version) {
      data.version = 1;
    }
    switch(data.version) {
      case 1: {
        // invert connections
        if (data.connections) {
          data.connections.reverse();
        }
        this.version = 2;
        data.version = 2;
      }
      case 2: {
        data.connections = data.connections.map((x) => {
          const eNum = parseInt(x.event);
          if (!isNaN(eNum)) {
            const eNumUpdate = (data.count + 1) - eNum;
            x.event = eNumUpdate.toString();
          }
          return x;
        });
        this.version = 3;
        data.version = 3;
      }
    }
    return data;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result, 
      this.createProperty('count', 'Count', 'Data', 'number', 'number', true),
      this.createProperty('reverse', 'Reverse', 'Data', 'button', 'none', true, () => 'Reverse', () => {
        this.connections = this.connections.map((x) => {
          const eNum = parseInt(x.event);
          if (!isNaN(eNum)) {
            const eNumUpdate = (this.count + 1) - eNum;
            x.event = eNumUpdate.toString();
          }
          return x;
        });
      })
    ];
  }

  public getEvents(): IBaseWidgetEvent[] {
    const result = [...super.getEvents()];
    for (let i = this.count; i > 0; i -= 1) {
      result.push({ name: i.toString(), label: i.toString(), desc: `Event ${i}` });
    }
    return result;
  }

  public serialize(): any {
    const result = super.serialize();
    result.count = this.count;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.count = data.count ?? this.count;
  }
}

ModuleService.Register(SequenceWidget.type, SequenceWidget);
