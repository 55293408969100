import ViewSelector, { MenuOption } from './ViewSelector';
import { ReactElement, useMemo } from 'react'
import { WidgetIcons } from '../../documentation/WidgetIcons';
import { userStore } from '../../store';
import { PermissionEntity, PermissionType } from '@proviz/api-services';
// import { getAbsoluteUrl } from '@proviz/proviz-sdk';

//  const defaultThumbnail = getAbsoluteUrl('images/thumbnail.svg');

interface Props {
    children?: any;
}

export default function PrimaryNavigation(props: Props): ReactElement {
    const hasPermission = userStore( s => s.hasPermission)
    const routes = useMemo(() => {
        const routes: MenuOption[] = [
            // { path: '/home', label: 'Home', defaultRoute: '/home', icon: defaultThumbnail, large: true},
            { path: '/home/models', label: 'Models', icon: WidgetIcons['model'] },
        ];
        if (hasPermission(PermissionEntity.Scene, PermissionType.View)) {
            routes.push({ path: '/home/scenes', label: 'Scenes', icon: WidgetIcons['scene'] });
        }
        // if (hasPermission('Target', 'View')) {
        //     routes.push({ path: '/home/targets', label: 'Targets', icon: CategoryIcons['Targets'] });
        // }
        // routes.push({ path: '/home/files', label: 'Files', icon: WidgetIcons['image'] });
        routes.push({ path: '/home/resources', label: 'Resources', icon: WidgetIcons['image'] });
        routes.push({ path: '/home/analytics', label: 'Analytics', icon: WidgetIcons['custom'] });
        return routes
    }, [hasPermission])


    return <ViewSelector options={routes} />;
}