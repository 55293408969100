import { ReactElement, useEffect, useState } from 'react'
import { getRole, initAbortController, isAbortError, MachineKey, MachineKeyService, ProVizConfig } from "@proviz/api-services";
import FormContainer from '../common/formcontainer';
import Button from '../common/button/Button';
import PageHeader from '../home/pageHeader';
import QRious from 'qrious';
import Modal from '../common/modals/Modals';

function generateQR(link: string) {
    const qr = new QRious({
        background: '#fff',
        // foreground: '#6d6e71',
        foreground: '#313E64',
        level: 'H',
        padding: 25,
        size: 1000,
        value: link,
    }
    );
    return qr.toDataURL('image/png');
}

export default function MachineKeysView(): ReactElement {
    const [failed, setFailed] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [role, setRole] = useState<"User" | "Admin">("User");
    const [updateKeys, setUpdateKeys] = useState(true);
    const [keys, setKeys] = useState<MachineKey[]>([]);
    const [ showQR, setShowQR ] = useState<string | undefined>(undefined);
    const [qrSrc, setQrSrc] = useState("");

    useEffect(() => {
        if (showQR) {
            setQrSrc(generateQR(`provizxr://machine-token/${showQR}`));
        }
    }, [showQR]);

    useEffect(() => {
        const updateKeysCb = () =>  setUpdateKeys(true);
        ProVizConfig.events.addListener('impersonate-change', updateKeysCb);
        return () => {
            ProVizConfig.events.removeListener('impersonate-change', updateKeysCb);
        }
    }, []);

    useEffect(() => {
        if (updateKeys) {
            setFailed(false);
            const abortController = initAbortController();
            MachineKeyService.getAll({ abortController })
                .then((k) => {
                    setKeys(k);
                    setUpdateKeys(false);
                })
                .catch((e) => {
                    if (isAbortError(e)) return; // if the query was aborted, do nothing
                    console.error(e);
                    setFailed(true);
                });
            return () => {
                abortController.abort();
            }
        }
        return () => { };
    }, [updateKeys]);
    
    useEffect(() => {
        setUpdateKeys(true);
        setFailed(false);
    }, []);

    const createKey = () => {
        if (name === '') {
            return;
        }
        MachineKeyService.createKey(name, role).then(() => {
            setName('');
            setUpdateKeys(true);
        });
    };

    const deleteKey = (key: string) => {
        MachineKeyService.delete(key).then(() => {
            setUpdateKeys(true);
        });
    }

    if (failed) {
        return <h1>We're afraid something went wrong. Try again later.</h1>
    }
    return <div className="homeRightView">

    <PageHeader />

    {showQR && <Modal title={"Machine Token"} close={() => setShowQR(undefined)}>
        <h1>Scan this code with your device</h1>
        <img style={{width: '100%'}} src={qrSrc} alt={`Machine Token`} />
    </Modal>}
    
    <div className='homeContent'>
        <div className='homeContentContainer'>
            <div className='paddedContainer'>
        <div className="userList">
            <div>
                <FormContainer title="Create New Machine Access Key">
                    <table className="inner">
                        <tbody>
                            <tr>
                                <td>Key Name</td>
                                <td>
                                    <input className="provizInputBox" type="text" onChange={(e) => setName(e.target.value)} placeholder="Access Token Name" />
                                </td>
                            </tr>
                            <tr>
                                <td>Role</td>
                                <td>
                                    <select className="provizInputBox" onChange={(e) => setRole(e.target.value as ("User" | "Admin"))}>
                                        <option value={"User"}>User</option>
                                        <option value={"Admin"}>Admin</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <Button onClick={() => createKey()}>Generate New Key</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </FormContainer>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Key</th>
                        <th>Role</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {keys.map((key) => <tr key={key.key}>
                        <td>{key.name}</td>
                        <td>{key.key}</td>
                        <td>{getRole(key.roleId)}</td>
                        <td>
                            <Button fullWidth={true} onClick={() => deleteKey(key.key)}>Delete</Button>
                        </td>
                        <td>
                            <Button fullWidth={true} onClick={() => {
                                setShowQR(key.key);
                            }}>
                                View
                            </Button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
        </div>
        </div>
    </div>;
}
