import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { is_mobile } from '../../../utils';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { SceneMode } from '../../..';
import { APIModel, ModelService, getMediaFilePath } from '@proviz/api-services';
import { CircleGeometry, Mesh, MeshBasicMaterial, TextureLoader } from 'three';

/**
 * HTML Elements used by widget.
 */
interface IElements {
  menuEl: HTMLDivElement;
  menuList: HTMLUListElement;
  backgroundEl: HTMLDivElement;
}

export class VariantSwitchWidget extends BaseWidget {
  public static type: string = 'variant-switch';
  private static styleEl?: HTMLStyleElement;
  public apiModel?: APIModel;

  // Data
  public modelId: string | undefined;
  public titleText: string = '';
  public menuBackground: string = '#fff';
  public textColor: string = '#313E64';
  public markerSrc: string = '';
  public modelVariantId: string = '';

  private elements?: IElements;
  menuVisible: boolean = false;
  variantsLoaded: boolean = false;
  private marker?: Mesh<CircleGeometry, MeshBasicMaterial>;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = VariantSwitchWidget.type;
    this.widgetName = 'Variant Switch Widget';
    this.label = 'Variant Switch';
    this.category = 'Experimental';

    this.events.push({
      label: 'Clicked',
      name: 'clicked',
    });
    this.services.push({
      label: 'Open',
      name: 'open',
    });

    this.addEventListener('service-open', () => {
      this.toggleMenu(true);
    });

    this.addEventListener('clicked', () => {
      this.toggleMenu(true);
    });

    this.events.push({
      label: 'Output Variant',
      name: 'output-variant',
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('modelId', 'Model', 'Core', 'model', 'string', true),
      this.createProperty('modelVariantId', 'Model Variant', 'Core', 'model-variant', 'string', true),
      this.createProperty('titleText', 'Title Text', 'UI', 'string', 'string', true),
      this.createProperty('menuBackground', 'Menu Background', 'UI', 'string', 'string', true),
      this.createProperty('textColor', 'Text Color', 'UI', 'color', 'string', true),
      this.createProperty(
        'markerSrc',
        'Marker Source',
        'UI',
        'string',
        'string',
        true,
        undefined,
        (data: any) => {
          this.markerSrc = data;
          this.updateMarkerSrc();
        },
      ),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.markerSrc) {
      this.createMarker();
    }

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }
    if (!this.modelId) {
      this.isInitialized = false;
      return this.isInitialized;
    }
    this.apiModel = await ModelService.get(this.modelId);

    const menuEl = document.createElement('div');
    menuEl.setAttribute('class', 'variant-switch-menu hide');
    if (this.titleText) {
      const title = document.createElement('h3');
      title.innerText = this.titleText;
      menuEl.append(title);
    }

    const menuList = document.createElement('ul');
    menuEl.append(menuList);

    const backgroundEl = document.createElement('div');
    backgroundEl.setAttribute('class', 'variant-switch-menu hide');

    if (!VariantSwitchWidget.styleEl) {
      VariantSwitchWidget.styleEl = document.createElement('style');
      VariantSwitchWidget.styleEl.innerText = `
.variant-switch-menu {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: ${is_mobile ? 0 : '10vh 25vw'};
  height: ${is_mobile ? '90vh' : '80vh'};
  width: ${is_mobile ? '100vw' : '50vw'};
  z-index: 1000;
  background: ${this.menuBackground};
  display: grid;
  grid-template-rows: auto 1fr;
  border: 2px solid ${this.textColor};
  border-radius: 20px;
}
.variant-switch-menu.hide {
  display: none;
}
.variant-switch-background {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 999;
  opacity: 0.8;
  background: #F0F1F1;
}
.variant-switch-background.hide {
  display:none;
}
.variant-switch-menu h3 {
  display: grid;
  place-content: center;
  color: ${this.textColor};
}
.variant-switch-menu ul {
  overflow-y: auto;
  margin-right: 40px;
  margin-left: 10px;
}
.variant-switch-menu li {
  border: 2px solid ${this.textColor};
  border-radius: 10px;
  height: 30px;
  align-self: center;
  background: #F0F1F1;
  font-size: large;
  color: ${this.textColor};
  display: grid;
  place-content: center;
  cursor: pointer;
  margin: 5px;
  list-style: none;
}
.variant-switch-menu li:hover {
  cursor: pointer;
  background: #adadad;
}`;
      document.body.append(VariantSwitchWidget.styleEl);
    }

    this.scene?.renderer?.domElement?.parentElement?.append(menuEl);
    if (!is_mobile) {
      this.scene?.renderer?.domElement?.parentElement?.append(backgroundEl);
    }
    this.elements = { backgroundEl, menuList, menuEl };
    return true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.marker) {
      result.push(this.marker);
    }
    return result;
  }

  public onHoverEnter() {
    document.body.style.cursor = 'pointer';
  }

  public onHoverLeave() {
    document.body.style.cursor = 'default';
  }

  public serialize(): any {
    const result = super.serialize();
    result.modelId = this.modelId;
    result.modelVariantId = this.modelVariantId;
    result.titleText = this.titleText;
    result.menuBackground = this.menuBackground;
    result.textColor = this.textColor;
    result.markerSrc = this.markerSrc;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.modelId = data.modelId;
    this.modelVariantId = data.modelVariantId;
    this.titleText = data.titleText;
    this.menuBackground = data.menuBackground;
    this.textColor = data.textColor;
    this.markerSrc = data.markerSrc;
  }

  private toggleMenu(visible: boolean) {
    this.menuVisible = visible;
    if (!this.elements) {
      return;
    }
    const { menuEl, menuList, backgroundEl } = this.elements;
    if (visible) {
      if (this.apiModel) {
        while (menuList.lastChild) {
          menuList.lastChild.remove();
        }
        this.apiModel?.modelVariants?.forEach((v) => {
          const button = document.createElement('li');
          button.innerHTML = v.materialSet.name ?? '';

          const onClick = () => {
            this.triggerProVizEvent('output-variant', 'modelVariantId', v.id);
            this.modelVariantId = v.id;
            this.toggleMenu(false);
          };

          if (is_mobile) {
            setTimeout(() => {
              button.onclick = onClick;
            }, 100);
          } else {
            button.onclick = onClick;
          }
          menuList.append(button);
        });
      }
      if (!is_mobile) {
        // close if user clicks outside menu
        const handler = () => {
          backgroundEl.removeEventListener('click', handler);
          this.toggleMenu(false);
        };
        backgroundEl.addEventListener('click', handler);
      }
    }
    if (!is_mobile) {
      backgroundEl.setAttribute(
        'class',
        this.menuVisible ? 'variant-switch-background' : 'variant-switch-background hide',
      );
    }
    menuEl.setAttribute(
      'class',
      this.menuVisible ? 'variant-switch-menu' : 'variant-switch-menu hide',
    );
  }

  private updateMarkerSrc() {
    if (!this.marker) {
      this.createMarker();
    } else {
      const imgSrc = getMediaFilePath(this.markerSrc);
      const textureLoader = new TextureLoader();
      const tex = textureLoader.load(imgSrc);
      // @ts-ignore
      this.marker.material.map = tex;
    }
  }

  private createMarker() {
    const imgSrc = getMediaFilePath(this.markerSrc);
    const geometry = new CircleGeometry(0.28, 32);
    const textureLoader = new TextureLoader();
    const tex = textureLoader.load(imgSrc);
    this.marker = new Mesh(geometry, new MeshBasicMaterial({ map: tex, transparent: false }));
    this.marker.renderOrder = 2;
    this.renderNode.add(this.marker);
  }

  dispose() {
    super.dispose();
    this.marker?.material.dispose();
    this.marker?.geometry.dispose();
  }
}

ModuleService.Register(VariantSwitchWidget.type, VariantSwitchWidget);
