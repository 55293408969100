import { useState, Fragment, useCallback, useEffect, useRef } from "react";
import { ManagerStore, AnimationStore } from "../../../store";
import SceneEditorBottomMenu from "./SceneEditorBottomMenu";

interface IProps {
  open: boolean;
}

export default function AnimationMenu({ open }: IProps) {
  const manager = ManagerStore((s) => s.SceneManager);
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const timeSliderRef = useRef<HTMLDivElement>(null);
  const time = AnimationStore((s) => s.time);
  const setTime = AnimationStore((s) => s.setTime);
  const setMenuOpen = AnimationStore((s) => s.setMenuOpen);
  const node = AnimationStore((s) => s.widget);
  const setNode = AnimationStore((s) => s.setWidget);
  const setTrack = AnimationStore((s) => s.setTrack);
  const track = AnimationStore((s) => s.track);

  /*
    TODO: 
    add bezier function floating modal
    connect the other properties
  */

  useEffect(() => {
    setNode(manager.selectedNodes[0] ?? null);
    setMenuOpen(open);
  }, [manager.selectedNodes, open]);

  return (
    <SceneEditorBottomMenu
      sidebar={
        <div style={{ margin: "0.5em 1em 0 1em" }}>
          {node && (
            <div>
              {node.animationTrack.map((_: any, index: number) => {
                return (
                  <span
                    key={index}
                    style={{
                      border: "1px black solid",
                      padding: "0.1em 0.25em",
                      background: track === index ? "black" : "",
                      borderBottomLeftRadius: index === 0 ? "50%" : "0",
                      borderTopLeftRadius: index === 0 ? "50%" : "0",
                    }}
                    onClick={() => {
                      setTrack(index);
                    }}
                  >
                    {index}
                  </span>
                );
              })}
              <span
                style={{
                  border: "1px black solid",
                  padding: "0.1em 0.25em",
                  borderBottomRightRadius: "50%",
                  borderTopRightRadius: "50%",
                }}
                onClick={() => {
                  node.addAnimationTrack();
                  forceUpdate();
                }}
              >
                +
              </span>
            </div>
          )}
          {node &&
            node.getProperties().map((nodeProperty, index) => {
              let nearest: any = {};
              if (
                ["vec3" /*, "vec2", "vec4", "number", "color"*/].includes(
                  nodeProperty.widgetType
                )
              ) {
                nearest = node.animationTrack[track].getNearestAnimationPoint(
                  time,
                  nodeProperty
                );
              }
              return (
                <>
                  {["vec3" /*, "vec2", "vec4", "number", "color"*/].includes(
                    nodeProperty.widgetType
                  ) && (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          cursor: "pointer",
                          fontWeight: nearest.left ? "bolder" : "normal",
                        }}
                        onClick={() => {
                          if (nearest.left) {
                            setTime(nearest.left);
                            if (!timeSliderRef || !timeSliderRef.current) {
                              return;
                            }
                            timeSliderRef.current.style.transform = `translate3d(${nearest.left}px, 0, 0)`;
                          }
                        }}
                      >
                        &lt;
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          margin: "0 0.5em",
                          fontWeight: nearest.center ? "bolder" : "normal",
                        }}
                        onClick={() => {
                          node.animationTrack[track].removeAnimationPoint(
                            time,
                            nodeProperty
                          );
                          forceUpdate();
                        }}
                      >
                        o
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          fontWeight: nearest.right ? "bolder" : "normal",
                        }}
                        onClick={() => {
                          if (nearest.right) {
                            setTime(nearest.right);
                            if (!timeSliderRef || !timeSliderRef.current) {
                              return;
                            }
                            timeSliderRef.current.style.transform = `translate3d(${nearest.right}px, 0, 0)`;
                          }
                        }}
                      >
                        &gt;
                      </span>
                      {nodeProperty.label}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      }
      content={
        <div
          style={{
            overflow: "auto",
            paddingBottom: "1em",
            paddingLeft: "1em",
            marginRight: "0.5em",
            minHeight: "90%",
            position: "relative",
          }}
        >
          <div
            draggable
            ref={timeSliderRef}
            style={{ position: "absolute", height: "100%" }}
            onDragEnd={(e) => {
              let rect = e.currentTarget.parentElement
                ? e.currentTarget.parentElement.getBoundingClientRect()
                : { left: 0 };
              e.currentTarget.style.transform = `translate3d(${Math.max(
                e.clientX -
                  rect.left -
                  15 +
                  (e.currentTarget.parentElement?.scrollLeft ?? 0),
                0
              )}px, 0, 0)`;
              setTime(
                Math.max(
                  (e.clientX -
                    rect.left -
                    15 +
                    (e.currentTarget.parentElement?.scrollLeft ?? 0)) /
                    100,
                  0
                )
              );
            }}
          >
            <div
              style={{
                width: "10px",
                height: "20px",
                background: "green",
                transform: "translate3d(-4px, 0, 0)",
              }}
            />
            <div
              style={{
                height: "calc(100% - 20px)",
                width: "2px",
                background: "green",
              }}
            />
          </div>
          <div
            style={{
              width: `${
                100 * (node ? node?.animationTrack[track].time : 15) ?? 15
              }px`,
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={(e) => {
              if (!timeSliderRef || !timeSliderRef.current) {
                return;
              }
              let rect = timeSliderRef.current?.parentElement
                ? timeSliderRef.current?.parentElement.getBoundingClientRect()
                : { left: 0 };
              timeSliderRef.current.style.transform = `translate3d(${Math.max(
                e.clientX -
                  rect.left -
                  15 +
                  (timeSliderRef.current?.parentElement?.scrollLeft ?? 0),
                0
              )}px, 0, 0)`;
              console.log(
                e.clientX,
                rect.left,
                timeSliderRef.current?.parentElement?.scrollLeft
              );
              setTime(
                Math.max(
                  (e.clientX -
                    rect.left -
                    15 +
                    (timeSliderRef.current?.parentElement?.scrollLeft ?? 0)) /
                    100,
                  0
                )
              );
            }}
          >
            {Array.from(
              Array((node?.animationTrack[track].time ?? 15) + 1).keys()
            ).map((v) => {
              return <span key={v}>{v}s</span>;
            })}
          </div>
          {node &&
            node.getProperties().map((nodeProperty: any, index: any) => {
              let animationInfo =
                node.animationTrack[track].getAnimationData(nodeProperty);
              return (
                <>
                  {["vec3" /*, "vec2", "vec4", "number", "color"*/].includes(
                    nodeProperty.widgetType
                  ) && (
                    <div
                      key={index}
                      style={{
                        width: `${
                          100 * node?.animationTrack[track].time ?? 15
                        }px`,
                      }}
                    >
                      <div>{nodeProperty.label}</div>
                      <div
                        style={{
                          width: "100%",
                          height: "5px",
                          background: "gray",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        {animationInfo &&
                          Object.entries(animationInfo.data)
                            .sort((a, b) =>
                              +a[0] > +b[0] ? 1 : +a[0] < +b[0] ? -1 : 0
                            )
                            .map((timeInfo, index, arr) => {
                              return (
                                <Fragment key={index}>
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: +timeInfo[0] * 100,
                                      width: "10px",
                                      height: "10px",
                                      background: "lightgreen",
                                      borderRadius: "50%",
                                      transform: "translate3d(-5px,0,0)",
                                      zIndex: 1,
                                    }}
                                  />
                                  {index > 0 && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: +arr[index - 1][0] * 100,
                                        width:
                                          (+timeInfo[0] - +arr[index - 1][0]) *
                                          100,
                                        height: "5px",
                                        background: "lightgreen",
                                      }}
                                    />
                                  )}
                                </Fragment>
                              );
                            })}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      }
    />
  );
}
