import { AliasService } from "@proviz/api-services";
import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/useInput";
import { ManagerStore } from "../../../store";

export const AliasProperty = () => {
  const sceneManager = ManagerStore( s => s.SceneManager )
  const [AliasList, setAliasList] = useState<string[]>([]);
  /****
   * Input Hook with passed override eventlistners 
   */
  const {
    value: Alias,
    setValue: setAliasVal,
    bind: bindAlias,
  } = useInput("", {onBlur, onChange, onKeyDown});

   /****
   * Submits Alias via API
   */
  async function setAliasAPI(){
    if (AliasList.includes(Alias)) return;
    await AliasService.setAlias(sceneManager.id, Alias);
  }

  /****
   * Eventlisterners for input
   */
  function onBlur(){
    setAliasAPI()
  }
  function onChange(e: React.ChangeEvent<HTMLInputElement>){
    const value = e.target?.value;
    setAliasVal(value.replace(" ", "-"))
  } 
  function onKeyDown(e: React.KeyboardEvent){
    if(e.key === 'Enter') setAliasAPI()
    // Tests keypress if is lowercase & uppercase letters, numbers, space, "_", or "-"
    // if false, it prevents input or default. 
    if(!/[a-zA-Z0-9_-\s]/.test(e.key)) e.preventDefault();
  }

  /****
   * Grabs Alias via API & Scene ID
   */
  useEffect(() => {
    (async () => {
      if (!sceneManager.id) return;
      const myA = await AliasService.getAlias(sceneManager.id);
      setAliasList(myA);
      if (myA.length > 0) {
        setAliasVal(myA[0]);
      }
    })();
  }, [sceneManager.id, setAliasVal]);

  return (
    <ul>
      <li>
        <div className="property-header">
          <h4>Alias Property</h4>
        </div>
        <div className="numeric">
          <input type="text" {...bindAlias} />
        </div>
      </li>
    </ul>
  );
};
