import { Clock, Object3D, Vector3, Box3 } from "three";
import EditorControls from "./controls/EditorControls";
import Exporter from "./Exporter";
import GraphicsManager from "./GraphicsManager";
import { safeDownloadCallback } from "./utils/SafeDownloadCallback";
import { SceneService, APIScene, SceneTypes } from "@proviz/api-services";
import {
  BasePositionableWidget,
  BaseWidget,
  is_firefox,
  ModuleService,
  ProVizEventData,
  WidgetPropertyType,
} from "@proviz/proviz-sdk";
import SetWidgetProperty from "../commands/SetWidgetProperty";
import { SettableWidgetProperty } from "../components/sceneeditor/propertypanel/SettableWidgetProperty";
import { AddNode } from "../commands";

export enum Layout {
  Split,
  EditorOnly,
}

const UpdateRate = 1000 / 30 / 1000;
const MaxDelta = UpdateRate * 3;

export default class SceneManager extends GraphicsManager {
  entityType = "Scene";
  controls: EditorControls | null = null;
  id: string;
  exporter: Exporter;
  apiScene: APIScene;
  layout: Layout;
  bottomPanelHeight: number = 250;

  shiftDown: boolean = false;

  selectedNodes: BaseWidget[] = [];

  private clock: Clock = new Clock();
  private accumulatedDelta = 0;

  constructor(id: string, layout?: Layout) {
    super(id, "Standard");

    this.id = id;
    this.layout = layout ?? Layout.EditorOnly;
    this.dimensions = this.getDimensions();
    this.exporter = new Exporter();
    // We bind this to these functions in order to remove
    // event listeners they are used by in our dispose function.
    // An arrow function would similarly allow for the use of this in the function
    // but the ease of removal is why we bind in this case.
    this.docHandleKey = this.docHandleKey.bind(this);
    this.docHandleKeyUp = this.docHandleKeyUp.bind(this);

    this.proVizScene.addEventListener("rotation-update", () => {
      if (!this.proVizScene.cameraRotation) {
        return;
      }
      const { x, y, z } = this.proVizScene.cameraRotation;
      this.camera.camera.rotation.set(x, y, z);
    });
  }

  public connectToDom(container: HTMLDivElement): void {
    super.connectToDom(container);
    // controls need to be created after renderer and helper scene
    // which are created in the super so no problems here.
    this.controls = new EditorControls(this, this.camera.camera, this.renderer);
  }

  focusSelected() {
    if (
      this.selectedNodes.length > 0 &&
      this.selectedNodes[this.selectedNodes.length - 1] instanceof
        BasePositionableWidget
    ) {
      this.fitCameraToObject(
        this.selectedNodes[this.selectedNodes.length - 1].renderNode
      );
    }
  }

  private fitCameraToObject(object: Object3D) {
    const boundingBox = new Box3();
    boundingBox.setFromObject(object);

    // For debugging uncomment these lines and import boxhelper
    // let selBox = new BoxHelper(object);
    // selBox.visible = true;
    // selBox.setFromObject(object)
    // this.scene.add(selBox);

    const center = boundingBox.getCenter(new Vector3());
    const size = boundingBox.getSize(new Vector3());

    const camera = this.camera.camera;

    const offsetPos = center.clone().sub(object.position);

    // const updatePos = center.sub(object.position);
    // camera.position.set(updatePos.x, updatePos.y, updatePos.z);
    // camera.position.sub(center);

    camera.position.sub(center);
    camera.position.normalize();

    // camera.position.add(object.position);
    const radius = size.length();
    let offset = Math.abs(radius / Math.sin(camera.fov / 2)) * 1.3;
    // console.log("Fit camera to object offset", offset, camera.far, camera.near);

    if (offset > 200) {
      // back off a little for big models since their heads are often cut off at the offset generated above
      offset *= 1.2;
    }
    camera.position.set(
      camera.position.x * offset,
      camera.position.y * offset,
      camera.position.z * offset
    );

    if (boundingBox.max.y > camera.position.y) {
      camera.position.y = boundingBox.max.y * 1.4;
    }
    camera.lookAt(offsetPos);
    camera.position.add(object.position);

    if (this.controls && this.controls.mapControls) {
      this.controls.mapControls.target = center;
    }
  }

  async init(scene: APIScene) {
    this.apiScene = scene;

    this.proVizScene.clear();
    await this.proVizScene.deserialize(
      JSON.parse(scene.data || "{}"),
      SceneTypes[+scene.sceneType]
    );
    if (this.disposed) {
      return;
    }
    this.setUpFromScene();
    document.addEventListener("keydown", this.docHandleKey);
    document.addEventListener("keyup", this.docHandleKeyUp);
    this.update();
    this.dispatchEvent("init-done", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: "init-done",
    });

    // Used for GUI widgets to handle DOM events after drag and drop
    this.proVizScene.addEventListener(
      "scene-move-node",
      (ev: ProVizEventData) => {
        if (ev.dataType === "widgetId") {
          const node = this.proVizScene.getById(ev.data as string);
          const parent = this.proVizScene.getById(ev.sourceWidgetId);
          if (node && parent) {
            this.move(node, parent);
          }
        }
      }
    );
  }

  protected setUpFromScene() {
    super.setUpFromScene();

    this.camera.camera.position.set(10, 10, 10);
    this.camera.camera.updateProjectionMatrix();

    this.dispatchEvent("scene-loaded", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: "scene-loaded",
    });

    this.proVizScene.addEventListener("background-change", () => {
      this.camera.camera.position.set(
        this.proVizScene.cameraPosition.x,
        this.proVizScene.cameraPosition.y,
        this.proVizScene.cameraPosition.z
      );

      this.camera.camera.rotation.set(
        this.proVizScene.cameraRotation?.x ?? 0,
        this.proVizScene.cameraRotation?.y ?? 0,
        this.proVizScene.cameraRotation?.z ?? 0
      );
      this.background.setSceneEditorBackground(
        this.proVizScene.backgroundTexture,
        this.proVizScene,
        this.camera.camera,
        this.scene
      );
    });

    this.proVizScene.addEventListener("view-change", () => {
      if (this.controls) {
        switch (this.proVizScene.view) {
          case "Normal":
            this.controls.panorama = false;
            if (this.controls.mapControls) {
              this.controls.mapControls.enabled = true;
            }
            break;
          case "Panorama":
            this.controls.panorama = true;
            if (this.controls.mapControls) {
              this.controls.mapControls.enabled = false;
            }
            break;
        }
      }

      this.dispatchEvent("view-change", {
        data: undefined,
        dataType: "none",
        sourceWidgetId: "",
        event: "view-change",
      });
    });
  }

  async save() {
    if (this.apiScene) {
      const data = this.proVizScene.serialize();
      this.apiScene.data = JSON.stringify(data);
      this.apiScene.sceneType = this.proVizScene.sceneType;
      this.apiScene = await SceneService.update(this.apiScene);
      return;
    }
    throw new Error("Scene was not loaded yet");
  }

  reset() {
    this.broker.reset();
    this.selectedNodes = [];
  }
  /** The widget will either be added in order of pririty
   * to the parent if provided,
   * to the selected node if one exists AND addToselected is true
   * To provizscene at the top level  */
  add(obj: BaseWidget, parent?: BaseWidget, addToSelected = true) {
    if (parent) {
      if (!parent.addChild(obj, true)) {
        throw new Error("Can not be added");
      }
    } else if (addToSelected && this.selectedNodes.length > 0) {
      if (!this.selectedNodes[this.selectedNodes.length - 1].addChild(obj)) {
        throw new Error("Can not be added");
      }
    } else {
      this.proVizScene.add(obj);
    }
    if (!this.shiftDown) {
      this.setSelection(null);
      this.setSelection(obj);
    }
    this.dispatchWidgetUpdate();
  }

  update() {
    if (this.disposed) {
      return;
    }
    requestAnimationFrame(() => this.update());

    let delta = this.clock.getDelta();
    this.controls?.update(delta);
    this.accumulatedDelta += delta;
    if (this.accumulatedDelta >= UpdateRate) {
      this.accumulatedDelta -= UpdateRate;
      this.proVizScene.update(UpdateRate);
      if (this.accumulatedDelta >= MaxDelta) {
        this.accumulatedDelta = MaxDelta;
      }
    }

    if (this.proVizScene.view === "Panorama") {
      this.camera.camera.position.set(
        this.proVizScene.cameraPosition.x,
        this.proVizScene.cameraPosition.y,
        this.proVizScene.cameraPosition.z
      );
    }

    this.renderer.render();
  }

  dispatchProVizSceneUpdate() {
    // this.render();
    this.dispatchEvent("provizscene-update", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: "provizscene-update",
    });
  }
  dispatchLayoutUpdate() {
    // this.render();
    this.dispatchEvent("layout-update", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: "layout-update",
    });
  }

  dispose() {
    super.dispose();
    this.controls?.dispose();
    document.removeEventListener("keydown", this.docHandleKey);
    document.removeEventListener("keyup", this.docHandleKeyUp);
  }

  async loadScene(scene: APIScene) {
    await this.proVizScene.deserialize(JSON.parse(scene.data), scene.sceneType);
    this.dispatchWidgetUpdate();
  }

  focusOn(selection: BaseWidget | null) {
    if (selection === null) return;

    this.dispatchEvent("focus-node", {
      data: selection.id,
      dataType: "none",
      sourceWidgetId: selection.id ?? "",
      event: "focus-node",
    });
  }

  setSelection(
    selection: BaseWidget | BaseWidget[] | null,
    doNotUpdateTree?: boolean
  ) {
    if (selection === null) {
      this.selectedNodes = [];
    } else {
      if (selection instanceof BaseWidget) {
        if (this.selectedNodes.indexOf(selection) === -1) {
          if (this.shiftDown) {
            this.selectedNodes.push(selection);
          } else {
            this.selectedNodes = [selection];
          }
        } else {
          if (this.shiftDown) {
            let index = this.selectedNodes.indexOf(selection);
            this.selectedNodes = [
              ...this.selectedNodes.slice(0, index),
              ...this.selectedNodes.slice(index + 1),
            ];
          } else {
            this.selectedNodes = [selection];
          }
        }
      } else {
        this.selectedNodes = [...selection];
      }
    }
    // Pass the first selected node to the dispatch so we can use it across
    // the application when needed. If there is no selected node then dispatch
    // the update with undefined as the second arg.
    this.dispatchSelectionUpdate(doNotUpdateTree, this.selectedNodes[0]?.id);
  }

  setSelectionById(id: string, doNotUpdateTree?: boolean) {
    const selection = this.proVizScene.getById(id);
    if (selection) {
      this.setSelection(selection, doNotUpdateTree);
    } else {
      console.error("Could not find widgt id: " + id);
    }
  }

  docHandleKeyUp(e: KeyboardEvent) {
    this.shiftDown = e.shiftKey;

    switch (e.key) {
      case "w":
        this.controls?.moveForward(false);
        break;
      case "s":
        this.controls?.moveBackward(false);
        break;
      case "a":
        this.controls?.moveLeft(false);
        break;
      case "d":
        this.controls?.moveRight(false);
        break;
    }
  }

  docHandleKey(e: KeyboardEvent) {
    this.shiftDown = e.shiftKey;
    const target: any = e.target;
    if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
      return true;
    }
    switch (e.key) {
      case "f":
        this.focusSelected();
        break;
      case "w":
        this.controls?.changeMode("translate");
        this.controls?.moveForward(true);
        break;
      case "s":
        this.controls?.moveBackward(true);
        break;
      case "e":
        this.controls?.changeMode("rotate");
        break;
      case "r":
        this.controls?.changeMode("scale");
        break;
      case "a":
        this.controls?.moveLeft(true);
        break;
      case "d":
        if (e.ctrlKey) {
          if (this.selectedNodes.length > 0) {
            this.proVizScene
              .clone(this.selectedNodes[this.selectedNodes.length - 1])
              .then((result: BaseWidget) => {
                console.log(this);
                this.setSelection(result);
                this.dispatchWidgetUpdate();
              });
          }
          e.preventDefault();
          e.stopPropagation();
          return false;
        } else {
          // Controls move right only if the ctrl key is NOT held
          this.controls?.moveRight(true);
        }
        break;
      case "c":
        if (e.ctrlKey || e.metaKey) {
          let data: any = [];
          this.selectedNodes.forEach((node) => {
            let nodeData = node.serialize();
            nodeData.id = undefined;
            nodeData.showInFlow = false;
            data.push(nodeData);
          });
          navigator.clipboard.writeText(
            JSON.stringify({ type: "widget", data })
          );
        }
        break;
      case "v":
        if (e.ctrlKey || e.metaKey) {
          if (!is_firefox || navigator.clipboard.readText !== undefined) {
            this.pasteWidget();
          } else {
            console.log(
              "Paste is not supported on firefox unless dom.events.asyncClipboard.readText or dom.events.testing.asyncClipboard are activated on about:config "
            );
          }
        }
    }
    return true;
  }

  remove(node: BaseWidget) {
    this.proVizScene.remove(node);
    this.setSelection(null);
    this.dispatchWidgetUpdate();
  }

  move(node: BaseWidget, parent: BaseWidget) {
    this.proVizScene.orphan(node);
    this.add(node, parent);
    this.setSelection(node);
    this.dispatchWidgetUpdate();
  }

  moveAfter(node: BaseWidget, other: BaseWidget) {
    if (other.parent) {
      this.proVizScene.orphan(node);
      other.parent.addChildAfter(node, other);
      this.setSelection(node);
      this.dispatchWidgetUpdate();
    }
  }

  getById(id: string) {
    return this.proVizScene.getById(id);
  }

  resize() {
    if (this.canvas.parentElement) {
      const { clientWidth, clientHeight } = this.canvas.parentElement;
      this.dimensions = { width: clientWidth, height: clientHeight };
      const { width, height } = this.dimensions;
      this.renderer.setSize(width, height);
      this.camera.setSize(width, height);
      // this.render();
    }
  }

  exportGLTF() {
    this.exporter.exportGLTF(this.scene);
  }

  exportGLB() {
    this.exporter.exportGLTF(this.scene, true);
  }

  _download(blob: Blob) {
    safeDownloadCallback(blob, "thumbnail.svg", "")();
  }

  downloadScreenshot() {
    this.renderer.requestScreenShot(this._download);
    this.renderer.render();
  }

  lookupNodeFromObject(object: Object3D) {
    for (let node of this.proVizScene.nodes) {
      if (node.contains(object)) {
        return node;
      }
    }
    return null;
  }

  public changeLayout(layout: Layout) {
    this.layout = layout;
    this.dimensions = this.getDimensions();
    this.resize();
    this.dispatchLayoutUpdate();
  }

  private getDimensions() {
    switch (this.layout) {
      case Layout.EditorOnly:
        return {
          width: window.innerWidth - 300,
          height: window.innerHeight - 40 - this.bottomPanelHeight,
        };
      case Layout.Split:
        return {
          width: (window.innerWidth - 300) / 2,
          height: window.innerHeight - 60 - this.bottomPanelHeight,
        };
    }
  }

  transformWidgetDataType(dataType: WidgetPropertyType) {
    let result = "";
    console.log(dataType);
    switch (dataType) {
      case "vec3":
      case "vec3-radian":
      case "vec3-camera-pos":
      case "vec3-camera-rot":
        result = "vector3";
        break;
      case "vec4":
        result = "vector4";
        break;
      case "bool":
        result = "bool";
        break;
      case "number":
      case "constrained-number":
        result = "number";
        break;
      case "color":
      case "string":
      case "multi-line-string":
        result = "string";
        break;
      case "transform":
        result = "transform";
        break;
    }
    return result;
  }

  public clipboardProperty(property: SettableWidgetProperty) {
    if (this.shiftDown && property.editable) {
      if (!is_firefox || navigator.clipboard.readText !== undefined) {
        this.pasteData(property);
      } else {
        console.log(
          "Paste is not supported on firefox unless dom.events.asyncClipboard.readText or dom.events.testing.asyncClipboard are activated on about:config "
        );
      }
    } else {
      let dataType = this.transformWidgetDataType(property.widgetType);
      let data = property.get();
      if (dataType === "vector3") {
        const v: Vector3 = property.get();
        const { x, y, z } = v;
        data = { x, y, z };
      }
      if (dataType === "transform") {
        const t = property.get();
        const { x: px, y: py, z: pz } = t.position;
        const { x: rx, y: ry, z: rz } = t.rotation;
        const { x: sx, y: sy, z: sz } = t.scale;
        data = {
          position: { x: px, y: py, z: pz },
          rotation: { x: rx, y: ry, z: rz },
          scale: { x: sx, y: sy, z: sz },
        };
      }
      navigator.clipboard.writeText(JSON.stringify({ type: dataType, data }));
    }
  }

  private async pasteData(property: SettableWidgetProperty) {
    const propertyVal = property.get();
    let oldVal =
      typeof propertyVal === "object"
        ? Object.assign({}, propertyVal)
        : propertyVal;
    try {
      let { type, data } = JSON.parse(await navigator.clipboard.readText());
      let propertyDataType = this.transformWidgetDataType(property.widgetType);
      if (type === propertyDataType) {
        console.log(data);
        switch (property.widgetType) {
          case "color":
            if (/^#[0-9abcdef]{6}$/i.test(propertyVal)) {
              this.execute(new SetWidgetProperty(property, oldVal, data, this));
            } else {
              console.log("string does not follos HEX format");
              //throw an error maybe
              return;
            }
            break;
          case "constrained-number":
            const options = (property.options
              ? property.options()
              : undefined) ?? [
              { key: 0, label: "0" },
              { key: 1, label: "1" },
            ];
            this.execute(
              new SetWidgetProperty(
                property,
                oldVal,
                Math.max(
                  Math.min(data, (options[1].key as number) ?? 1),
                  (options[0].key as number) ?? 0
                ),
                this
              )
            );
            break;
          default:
            this.execute(new SetWidgetProperty(property, oldVal, data, this));
        }
      } else {
        console.log("incompatible data types");
      }
    } catch (e) {
      console.log(e);
      console.log("Incompatible information on the clipboard");
    }
  }

  private async pasteWidget() {
    try {
      let clipboardData = JSON.parse(await navigator.clipboard.readText());
      if (
        clipboardData.type === "widget" &&
        typeof clipboardData.data === "object" &&
        clipboardData.data.length
      ) {
        console.log(
          clipboardData.data,
          clipboardData.data.length,
          clipboardData.data[0]
        );
        for (let i = 0; i < clipboardData.data.length; i++) {
          console.log("clone widget");
          this.proVizScene.cloneFromData(
            clipboardData.data[i],
            this.selectedNodes.length > 0 ? this.selectedNodes[0] : undefined
          );
        }
        this.dispatchSelectionUpdate();
        // this.deserializeWidget(clipboardData.data);
      }
    } catch (e) {
      console.log(e);
      console.log("Incompatible information on the clipboard");
    }
  }

  private deserializeWidget(data: any, parent?: BaseWidget) {
    data.forEach((wdgData: any) => {
      const wdg = new ModuleService.widgets[wdgData.type](this.proVizScene);
      wdg.deserialize(wdgData);
      wdg.init();
      this.execute(new AddNode(this, wdg, parent));
      if (wdgData.children) {
        this.deserializeWidget(wdgData.children, wdg);
      }
    });
  }
}
