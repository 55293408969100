import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BaseWidget,
  BaseWidgetDataConnection,
  getAbsoluteUrl,
  ModelWidget,
  ModuleService,
  ProVizEventData,
  EventPassWidget
} from "@proviz/proviz-sdk";
import ReactFlow, {
  MiniMap,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  Edge,
  Node,
  XYPosition,
  EdgeTypes,
  Background
} from "react-flow-renderer";

import SceneManager from "../../../graphics/SceneManager";

import WidgetNode from "./WidgetNode";
import ModelNode from "./ModelNode";
import ErrorBoundary from "../../../errorbounds/ErrorBoundary";
import CustomEdge from "./Components/CustomEdge";
import NoteNode from "./NoteNode";
import { CollapsableMenuGroup } from "../../modeleditor/variantpanel/CollapsableMenuGroup";
import GlobalNode from "./globalNode";

// Importing icons
const cpuCip = getAbsoluteUrl("images/cpuChip.svg");
const calendar = getAbsoluteUrl("images/calendar.svg");
const move = getAbsoluteUrl("images/move.svg");
const lines = getAbsoluteUrl("images/lines.svg");
const clock = getAbsoluteUrl("images/clock.svg");

const connectionLineStyle = { stroke: "#fff" };

interface Position {
  left?: number;
  right?: number;
  top?: number;
}

interface Props {
  manager: SceneManager;
}

const nodeTypes = {
  widget: WidgetNode,
  model: ModelNode,
  note: NoteNode,
  'event-pass': GlobalNode
};

const edgeTypes: EdgeTypes = {
  buttonedge: CustomEdge,
};

const OFFSET = 100;

export default function FlowEditor(props: Props) {
  const [reactFlowInstance, setreactFlowInstance] = useState<any>(undefined);
  // let reactFlowInstance: any = undefined

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const [showContextMenu, setShowContextMenu] = React.useState<boolean>(false);
  const [contextPosition, setContextPosition] = React.useState<Position>({});

  const [showTools, setShowTools] = useState(false);

  const flowRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const nodeTypeKeys = Object.keys(nodeTypes);

  const [ focusPos, setFocusPos ] = useState<{ x: number, y: number } | undefined>(undefined);

  useEffect(() => {
    if (focusPos) {
      reactFlowInstance?.setCenter(focusPos.x, focusPos.y, {
        zoom: 1
      });
    }
  }, [reactFlowInstance, focusPos])

  function addWidget(
    fill: any[],
    node: BaseWidget,
    e?: React.DragEvent | React.MouseEvent
  ) {
    //This is a general offset for drag/drop
    // (Most of the node boxes are about 200 px)

    let spawnPos: XYPosition | undefined = {
      x: node.flowPosition.x,
      y: node.flowPosition.y,
    };

    if (e) {
      let rect = flowRef.current.getBoundingClientRect();

      spawnPos = reactFlowInstance?.project({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
      console.log(spawnPos, reactFlowInstance);
      if (spawnPos) node.flowPosition.set(spawnPos.x - OFFSET, spawnPos.y);
    }

    let resourceId: string | undefined = undefined;
    if (node.widgetType === 'model') {
      const modelWdg = node as ModelWidget;
      if (modelWdg.modelId) {
        resourceId = modelWdg.modelId;
      }
    }

    let label = node.label;
    if (node.widgetType === 'event-pass') {
      label = (node as EventPassWidget).event || node.label;
    }

    fill.push({
      id: node.id,
      type: nodeTypeKeys.find((x) => x === node.widgetType)
        ? node.widgetType
        : "widget",
      data: {
        label,
        note: {
          data: node.note,
          alwaysShow: node.showNote !== undefined ? node.showNote : false,
          toggleShow: (state: boolean) => {
            node.setShowNote(!state);
            widgetsUpdated();
          },
        },
        node: {
          id: node.id,
          widgetType: node.widgetType,
          services: node.getServices(),
          events: node.getEvents(),
          properties: node.getProperties()
        },
        resourceId
      },
      position: {
        x: node.flowPosition.x,
        y: node.flowPosition.y,
      },
      style: {
        background: "#fff",
        border: "0",
        borderRadius: "0",
        visibility: "visible",
      },
    });
  }

  /**
   * connIds: array of strings showing the connections for the nodes?
   * fill: array coming from widgetsUpdated that keeps the values of the elements and edges
   * node: the provizScene node itself
   */
  function fillInData(
    connIds: string[],
    nodesFill: any[],
    edgesFill: any[],
    node: BaseWidget
  ) {
    //Search all the connection IDs and compare with the node ids
    if (connIds.find((x) => x === node.id)) {
      //If found, add a widget to the flow graph.
      if (node.showInFlow) addWidget(nodesFill, node);
    }

    //Add edge connections
    node.connections.forEach((c, idx) => {
      edgesFill.push({
        id: node.id + c.targetId + c.targetService + c.event + idx + "-con",
        source: node.id,
        sourceHandle: c.event,
        target: c.targetId,
        targetHandle: c.targetService,
        animated: false,
        type: "buttonedge",
        style: { stroke: "#ccc" },
      });
    });

    //Fill in the nodes for the children of the node too.
    node.children.forEach((x) => fillInData(connIds, nodesFill, edgesFill, x));
  }

  const createWidget = (
    widget: BaseWidget,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const wdg = new ModuleService.widgets[widget.widgetType](
      props.manager.proVizScene
    );
    wdg.init();
    wdg.showInFlow = true;
    props.manager.add(wdg, undefined, false);

    const els = [...nodes];
    addWidget(els, wdg, e);
    setNodes(els);
    setShowContextMenu(false);
  };

  const widgetsUpdated = () => {
    let nodes: Node[] = [];
    let edges: Edge[] = [];
    const connIds = props.manager.proVizScene.getIdsWithFlow();
    //Grab all nodes in the scene and dump them to the nodes array above.
    props.manager.proVizScene.nodes.forEach((x) =>
      fillInData(connIds, nodes, edges, x)
    );
    //Set the elements in state for the returned nodes values
    if (nodes) {
      setNodes(nodes);
    }
    if (edges) setEdges(edges);

    return {
      edges: edges,
      nodes: nodes,
    };
  };

  const initHandler = () => {
    widgetsUpdated();
    if (reactFlowInstance) {
      reactFlowInstance.fitView({ padding: 0.2, includeHiddenNodes: true });
    }
  };

  //Handle Edge Delete
  const handleEdgeDelete = (change: any, _edges: Edge[] = edges) => {
    if (edges.length === 0) _edges = widgetsUpdated().edges; //Bit of a bruteforce way to do this, but I guess it works for now.

    if (!_edges) throw new Error("No edges passed to the function");
    // return;
    const selectedEdge: any = _edges.find((x) => x.id === change.id);

    if (!selectedEdge || !selectedEdge.source)
      throw new Error("Edge not found");
    const node = props.manager.proVizScene.getById(selectedEdge.source) ?? null;

    if (!node) return;

    if (node) {
      const ind = node.connections.findIndex((x) => {
        return x.targetId === selectedEdge.target;
      });

      if (ind > -1) {
        node.connections.splice(ind, 1);
      }

      props.manager.dispatchWidgetUpdate();
    }
  };

  const handleEdgeSelect = (change: any, edges: any[]) => {
    return;
  };

  const [ flowNodesToSelect, setFlowNodesToSelect ] = useState<string[]>([]);

  useEffect(() => {
    console.log(flowRef);
    const selectedNodesUpdate = (e?: ProVizEventData) => {
      //Grab from the nodes or update the widgets - this avoids problems with useState being mysteriously empty.
      const _nodes = nodes.length > 0 ? nodes : widgetsUpdated().nodes;
      if (e?.sourceWidgetId && _nodes.length > 0 && e.data) {
        let selectedNode = _nodes.find((n) => n.id === e?.sourceWidgetId);
        if (!selectedNode) return;
        else onNodeSelect(selectedNode);
      }

      console.log(_nodes);
      setFlowNodesToSelect(props.manager.selectedNodes.map(n => n.id));

      // reactFlowInstance.setNodes([]);
    };

    const focusNodeUpdate = (e?: ProVizEventData) => {
      console.log('focus on node', e);
      
      const _nodes = nodes.length > 0 ? nodes : widgetsUpdated().nodes;
      if (e?.sourceWidgetId) {
        let selectedNode = _nodes.find((n) => n.id === e?.sourceWidgetId);
        if (!selectedNode) return;
        
        // react flow focus node
        console.log(reactFlowInstance);
        setFocusPos({ x: selectedNode.position.x, y: selectedNode.position.y});
      }


    }
    props.manager.attach("init-done", initHandler);
    props.manager.attach("widgets-updated", widgetsUpdated);
    props.manager.attach("selectednode-update", selectedNodesUpdate);
    props.manager.attach("focus-node", focusNodeUpdate);

    window.addEventListener("delete-edge", (e: any) =>
      handleEdgeDelete(e.detail)
    );

    if (props.manager.apiScene) {
      initHandler();
    }

    return () => {
      props.manager.detach("init-done", initHandler);
      props.manager.detach("widgets-updated", widgetsUpdated);
      props.manager.detach("selectednode-update", selectedNodesUpdate);
      props.manager.detach("focus-node", focusNodeUpdate);
      window.removeEventListener("delete-edge", (e: any) =>
        handleEdgeDelete(e.detail)
      );
    };
  }, []);

  useEffect(() => {
    console.log(reactFlowInstance);
    if (reactFlowInstance) {
      const flowNodes = reactFlowInstance.getNodes();
      flowNodes.forEach((flowNode: any) => {
        if (flowNodesToSelect.find(n => n === flowNode.id)) {
          flowNode.selected = true;
        }
      });
      reactFlowInstance.setNodes(flowNodes);
    }
  }, [reactFlowInstance, flowNodesToSelect])

  const onInit = (rfi: any) => {
    setreactFlowInstance(rfi);
    console.log(rfi);
    // reactFlowInstance = rfi
    // setreactFlowInstance(rfi);
    rfi.fitView({ padding: 0.2, includeHiddenNodes: true });
  };

  const handleNodeDelete = (change: any, nodes: any[]) => {
    const node = props.manager.proVizScene.getById(change.id);
    if (node) {
      node.showInFlow = false;
    }
  };

  const onNodeDragStop = (change: any, nodes: any[]) => {
    if (change.dragging) return; // Ensure we aren't calling the widget position change until after we're done dragging it.

    //Maybe later we can implement this to work with the multi-select, I'll just leave it commented out for now.
    // nodes.forEach((el: any) => {
    //Get the right node to set the flow position by.
    const modifiedNode = nodes.find((n) => n.id === change.id);

    if (!modifiedNode || !props.manager.proVizScene) {
      return;
    }
    //Get the proviz scene node reference
    const node = props.manager.proVizScene.getById(modifiedNode.id);

    //If there's a scene node reference, modify it to the correctly grabbed node
    node?.flowPosition.set(modifiedNode.position.x, modifiedNode.position.y);
    // })
  };

  /*
        ###########################
        ### START NODE CONTROLS ###
        ###########################
    */

  const onNodesChange = useCallback(
    (changes: any) =>
      setNodes((ns) => {
        //Handle all node changes
        changes.forEach((change: any) => {
          switch (change.type) {
            case "remove":
              handleNodeDelete(change, ns);
              break;
            case "position":
              onNodeDragStop(change, ns);
              break;
            case "select":
              console.log("SELECTED", change);

              break;

            default:
              break;
          }
        });
        return applyNodeChanges(changes, ns);
      }),
    []
  );

  //Unused for now.
  const onNodeClick = (event: any, node: Node) => {
    return;
  };

  const onNodeSelect = (node: Node) => {
    if (reactFlowInstance) {
      reactFlowInstance?.setCenter(node.position.x + 100, node.position.y, {
        zoom: reactFlowInstance.getZoom(),
        duration: 232,
      });
    }
  };

  /*
        #########################
        ### END NODE CONTROLS ###
        #########################
    */

  /*
        ###########################
        ### START EDGE CONTROLS ###
        ###########################
    */

  //This one controls the connections themselves
  const onConnect = useCallback(
    (connection: any) =>
      setEdges((eds) => {
        const node = props.manager.proVizScene.getById(connection.source);
        const nodeToConnect = props.manager.proVizScene.getById(
          connection.target
        );
        if (node && nodeToConnect) {
          const conn = new BaseWidgetDataConnection({
            event: connection.sourceHandle,
            targetId: nodeToConnect?.id,
            targetService: connection.targetHandle,
            data: null,
          });
          node.connections.push(conn);
        }
        return addEdge(
          {
            ...connection,
            animated: true,
            type: "buttonedge",
            style: { stroke: "#fff" },
          },
          eds
        );
      }),
    [props.manager.proVizScene]
  );

  const onEdgesChange = useCallback(
    (changes: any) =>
      setEdges((es) => {
        changes.forEach((change: any) => {
          switch (change.type) {
            case "remove":
              handleEdgeDelete(change, es);
              break;
            case "select":
              handleEdgeSelect(change, es);
              break;
            default:
              break;
          }
        });
        return applyEdgeChanges(changes, es);
      }),
    []
  );

  /*
        #########################
        ### END EDGE CONTROLS ###
        #########################
    */

  //Relating to context menu
  const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    const data = e.dataTransfer.getData("node/id");
    const nodeToConnect = props.manager.getById(data);

    if (nodeToConnect && !nodeToConnect.showInFlow) {
      const els = [...nodes];
      nodeToConnect.showInFlow = true;
      addWidget(els, nodeToConnect, e);
      setNodes(els);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button === 2) {
      setShowContextMenu(true);
      setContextPosition({
        left: e.nativeEvent.offsetX - OFFSET,
        right: undefined,
        top: e.nativeEvent.offsetY - 15,
      });
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    return true;
  };

  const handleContextMenuButtonClick = () => {
    setShowContextMenu(!showContextMenu);
    setContextPosition({ right: 65, left: undefined, top: 10 });
  };

  const handleHideTools = () => {
    setShowTools(!showTools);
  };

  const cancelEvent = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const modules = Object.keys(ModuleService.widgets);
  const widgetInstances = useRef(
    modules
      .sort()
      .map(
        (w) =>
          new ModuleService.widgets[w](
            props.manager.proVizScene,
            undefined,
            true
          )
      )
  );
  return (
    <div
      className="flow"
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onMouseDown={(e) => handleMouseDown(e)}
      key="react-flow-editor"
    >
      <ErrorBoundary>
        <ReactFlow
          minZoom={0.02}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          edgeTypes={edgeTypes}
          onNodeClick={onNodeClick}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          proOptions={{
            account: "paid-pro",
            hideAttribution: true,
          }}
          // onEdgeUpdate={edgeUpdated}
          onInit={onInit}
          onContextMenu={cancelEvent}
          onPointerDown={(e) => {
            setShowContextMenu(false);
            return false;
          }}
          onPaneClick={(e) => {
            props.manager.setSelection(null);
          }}
          snapToGrid={true}
          snapGrid={[20, 20]}
          connectionLineStyle={connectionLineStyle}
          ref={flowRef}
        >
          <Background gap={20} size={0.75} color="#6B73A6" />
          {showTools && <MiniMap />}
          <Controls showFitView={true} />
        </ReactFlow>
      </ErrorBoundary>
      <img
        className="flowMenuButton"
        src={getAbsoluteUrl("images/FlowEditorIcon.svg")}
        title="Flow Widgets"
        alt="Flow widgets"
        onClick={handleContextMenuButtonClick}
      />
      <img
        className="flowMenuButton flowToolbarButton"
        src={getAbsoluteUrl("images/Other/Wrench.svg")}
        title="Flow Widget Tools"
        alt="Flow widget tools"
        onClick={handleHideTools}
      />

      {showContextMenu && (
        <div
          className="contextMenu"
          onContextMenu={cancelEvent}
          style={contextPosition}
        >
          <div className="flowWidgetHeader">
            <h2>Flow Widgets</h2>
          </div>
          <div className="flowWidgetList">
            <CollapsableMenuGroup
              groupName="Conditionals"
              startExpanded={false}
              iconSrc={cpuCip}
            >
              {widgetInstances.current
                .filter((x) => x.category === "Conditional")

                .map((x) => (
                  <div
                    className="content"
                    key={x.id}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                      createWidget(x, e)
                    }
                  >
                    <h4 className="widgetName"> {x.widgetName}</h4>
                  </div>
                ))}
            </CollapsableMenuGroup>
            <CollapsableMenuGroup
              groupName="Events"
              startExpanded={false}
              iconSrc={calendar}
            >
              {widgetInstances.current
                .filter((x) => x.category === "Events")
                .map((x) => (
                  <div
                    className="content"
                    key={x.id}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                      createWidget(x, e)
                    }
                  >
                    <h4 className="widgetName"> {x.widgetName}</h4>
                  </div>
                ))}
            </CollapsableMenuGroup>
            <CollapsableMenuGroup
              groupName="Position"
              startExpanded={false}
              iconSrc={move}
            >
              {widgetInstances.current
                .filter((x) => x.category === "Position")
                .map((x) => (
                  <div
                    className="content"
                    key={x.id}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                      createWidget(x, e)
                    }
                  >
                    <h4 className="widgetName"> {x.widgetName}</h4>
                  </div>
                ))}
            </CollapsableMenuGroup>
            <CollapsableMenuGroup
              groupName="Time"
              startExpanded={false}
              iconSrc={clock}
            >
              {widgetInstances.current
                .filter((x) => x.category === "Time")
                .map((x) => (
                  <div
                    className="content"
                    key={x.id}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                      createWidget(x, e)
                    }
                  >
                    <h4 className="widgetName"> {x.widgetName}</h4>
                  </div>
                ))}
            </CollapsableMenuGroup>
            <CollapsableMenuGroup
              groupName="Others"
              startExpanded={false}
              iconSrc={lines}
            >
              {widgetInstances.current
                .filter((x) => x.category === "Others")
                .map((x) => (
                  <div
                    className="content"
                    key={x.id}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                      createWidget(x, e)
                    }
                  >
                    <h4 className="widgetName"> {x.widgetName}</h4>
                  </div>
                ))}
            </CollapsableMenuGroup>
          </div>
        </div>
      )}
    </div>
  );
}
