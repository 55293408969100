import { ReactElement, ReactNode } from 'react';

interface Props {
    condition: boolean;
    wrapper: (children: ReactNode) => ReactElement;
    children: any;
}

export default function ConditionalWrapper(props: Props): ReactElement {
    const { condition, wrapper } = props;
    
    return (
        condition ? wrapper(props.children) : <>{props.children}</>
    );
};