import {
  APIFolder,
  SceneService,
  APIScene,
  ProVizConfig,
} from "@proviz/api-services";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Split from "react-split";
import styled from "styled-components";
import Button from "../common/button/Button";
import ThumbnailCard from "./ThumbnailCard";

const defaultThumbnail = getAbsoluteUrl("images/thumbnail.svg");

const SceneListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .template {
    width: calc(25% - 10px);
    pointer-events: auto;
    display: flex;
    height: fit-content;
    cursor: pointer;
    .thumbnailCard {
      width: 100% !important;
      pointer-events: none;
    }
  }
`;

const SelectedCircle = styled.div`
  width: 25px;
  height: 25px;
  background: blue;
  border-radius: 50%;
  z-index: 1001;
  margin-left: -1.75em;
  margin-top: -0.5em;
`;

interface IProvizTemplateViewProps {
  currentFolder?: APIFolder;
}
export default function CustomTemplate({
  currentFolder,
}: IProvizTemplateViewProps) {
  const history = useHistory();
  const [selectedTemplate, setSelectedTemplate] = useState<APIScene>();
  const [templates, setTemplates] = useState<APIScene[]>([]);

  //modify it to take a sceneId for it to copy the data
  const createScene = async (sceneId: any) => {
    console.log("creating scene");
    const scene = await SceneService.create({
      name: "New Scene",
      sceneType: "Standard",
      folderId: currentFolder?.id || null,
    });
    console.log("fetching scene");
    const baseScene = await SceneService.getDraft(sceneId);
    scene.data = baseScene.data;
    console.log("updating scene");
    await SceneService.update(scene);
    history.push(`/editor/${scene.id}`);
  };

  const getTemplateScenes = async () => {
    return await SceneService.getBySceneType("Template");
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      let data = await getTemplateScenes();
      if (isMounted) {
        setTemplates(data);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const onImpersonateChange = () => {
    getTemplateScenes().then((data) => {
      setTemplates(data);
    });
  };

  useEffect(() => {
    ProVizConfig.events.addListener("impersonate-change", onImpersonateChange);
    return () => {
      ProVizConfig.events.removeListener(
        "impersonate-change",
        onImpersonateChange
      );
    };
  }, []);

  return (
    <>
      <Split className="split" sizes={[75, 25]} gutterSize={5}>
        <SceneListContainer className="templatesList">
          {templates.map((template) => {
            return (
              <div
                className="template"
                onClick={() => {
                  if (selectedTemplate?.id === template.id) {
                    //should be using the id or the type of scene here to create it
                    createScene(selectedTemplate.id);
                  } else {
                    setSelectedTemplate(template);
                  }
                  console.log(selectedTemplate);
                }}
              >
                <ThumbnailCard
                  key={template.id}
                  title={template.name}
                  thumbnail={template.thumbnailFile?.location}
                  openUrl={`/editor/${template.id}`}
                  updated={template.updated}
                  onDragStart={() => {}}
                />
                {selectedTemplate?.id === template.id && <SelectedCircle />}
              </div>
            );
          })}
        </SceneListContainer>
        <div className="templateInfo" style={{ padding: "0 1em" }}>
          {selectedTemplate && (
            <>
              <h1>{selectedTemplate.name}</h1>
              <div
                style={{
                  height: "150px",
                  backgroundImage: `url(${
                    selectedTemplate.thumbnailFile?.location ?? defaultThumbnail
                  })`,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <p>last updated on {selectedTemplate.updated}</p>
              <Button
                onClick={() => {
                  history.push(`/editor/${selectedTemplate.id}`);
                }}
              >
                Edit
              </Button>
              <br />
              <Button
                onClick={() => {
                  createScene(selectedTemplate.id);
                }}
              >
                Create Scene
              </Button>
            </>
          )}
        </div>
      </Split>
    </>
  );
}
