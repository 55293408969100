import { Color, DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader } from 'three';
import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import QRious from 'qrious';
import { BaseWidgetProperty } from '../..';

export class ARCSQRWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'arcs-qr';

  // Data
  public sceneId: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ARCSQRWidget.type;
    this.widgetName = 'ARCS QR';
    this.label = 'ARCS QR';
    this.category = 'ARCS';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('sceneId', 'Scene', 'Core', 'scene', 'string', true)];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    const sceneRoot = (() => {
      switch (window.location.hostname) {
        case 'dev.studio.provizxr.com':
          return `https://dev.embed.provizxr.com/scene`;
        case 'qa.studio.provizxr.com':
          return `https://qa.embed.provizxr.com/scene`;
        case 'localhost':
          return `https://localhost:3002/scene`;
        default:
          return `https://embed.provizxr.com/scene`;
      }
    })();

    const getSceneLink = (sceneId: string) => {
      return `${sceneRoot}/${sceneId}`;
    };

    const qr = new QRious({
      background: '#fff',
      // foreground: '#6d6e71',
      foreground: '#313E64',
      level: 'H',
      padding: 25,
      size: 250,
      value: getSceneLink(this.sceneId),
    });

    const dataUrl = qr.toDataURL('image/png');
    const textureLoader = new TextureLoader();
    const tex = textureLoader.load(dataUrl, () => {});

    // create display only 3d sphere
    const geometry = new PlaneGeometry();
    const mesh = new Mesh(
      geometry,
      new MeshBasicMaterial({
        color: new Color(0xffffff),
        map: tex,
        side: DoubleSide,
      }),
    );
    this.renderNode.add(mesh);

    return true;
  }
}

ModuleService.Register(ARCSQRWidget.type, ARCSQRWidget);
