import { DataSchemaService } from '@proviz/api-services';
import { APIDataSchema } from '@proviz/api-services/lib/models/DataSchema';
import { ModuleService } from '../../../moduleService';
import { ProVizEventDataTypes } from '../../../ProVizEventData';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetEvent } from '../../BaseWidgetEvent';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetService } from '../../BaseWidgetService';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { WidgetPropertyType } from '../../WidgetPropertyTypes';

interface SchemaDataType { label: string, name: string, dataType: WidgetPropertyType, eventDataType: ProVizEventDataTypes }

export class JsonDataWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'json-data';

  // Data
  public schemaId: string | undefined = undefined;
  public schema: string | undefined = undefined;

  private schemaOptions: APIDataSchema[] = [];

  private data: any = { };

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Json Data';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = JsonDataWidget.type;
    this.widgetName = 'Json Data';
    this.selectable = true;
    this.events = [ { name: 'value', label: 'Value', desc: 'When the data is applied.'} ];

    this.services = [ ];

    this.addService('Apply', 'apply', 'Apply the data', () => {
      this.emitValues();
    });
  }

  getSchemaTypes(o: any, parent?: string): SchemaDataType[] {
    const result: SchemaDataType[] = [];
    const keys = Object.keys(o);
    keys.forEach((k) => {
      const p = o[k];
      if (p && p.type) {
        const label = (parent ?? '') + k;
        const name = label.split(' ').join('-');

        switch(p.type) {
          case 'object': {
            result.push(...this.getSchemaTypes(p.properties, (parent ?? '') + k + '.'));
            break;
          }
          case 'array':
            console.log('setup array');
            result.push({
              label,
              name,
              dataType: 'list',
              eventDataType: 'list'
            });
            break;
          case 'number':
          case 'integer':
            result.push({
              label,
              name,
              dataType: 'number',
              eventDataType: 'number'
            });
            break;
          case 'string':
            result.push({
              label,
              name,
              dataType: 'string',
              eventDataType: 'string'
            });
            break;
        }
      }
    });
    return result;
  }

  getSchemaDataValue(key: string) {
    console.log(key);

    if (this.data) {
      const parts = key.split('.');
      let o = this.data;
      for(let i = 0; i < parts.length; i++) {
        o = o[parts[i]];
        if (!o) {
          return undefined;
        }
      }
      return o;
    }
    return undefined;
  }

  setSchemaDataValue(key: string, value: any) {
    console.log('set data', key, value, this.data);
    if (this.data) {
      const parts = key.split('.');
      let o = this.data;
      for(let i = 0; i < parts.length - 1; i++) {
        if (!o[parts[i]]) {
          o[parts[i]] = {};
        }
        o = o[parts[i]];
      }
      return o[parts[parts.length - 1]] = value;
    }
    return undefined;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {}).map(x => {
      return this.createProperty(x.name, x.label, 'Data', x.dataType, x.eventDataType, true, () => {
        return this.getSchemaDataValue(x.name);
      }, (data: any) => {
        this.setSchemaDataValue(x.name, data);
      })
    });

    return [
      ...result,
      ...schema,
      this.createProperty('schemaId', 'Schema', 'Core', 'select', 'string', true, undefined, (data: any) => {
        this.schemaId = data;
        if (this.schemaId) {
          DataSchemaService.get(this.schemaId).then((schema) => {
            this.schema = schema.schema;
          });
        } else {
          this.schema = '';
        }
      }, () => {
        return [
          { key: '', label: '[None]' },
          ...this.schemaOptions.map(x => {
            return {
              key: x.id,
              label: x.name || ''
            }
          })
        ];
      }),
      this.createProperty('schema', 'Schema', 'Core', 'multi-line-string', 'string', true),
    ];
  }

  private emitValues() {
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {});
    schema.forEach(val => this.triggerProVizEvent(val.name, val.eventDataType, this.getSchemaDataValue(val.name)));
    this.triggerProVizEvent('value', 'object', this.data);
  }

  public getServices(): IBaseWidgetService[] {
    const result = super.getServices();
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {}).map(x => {
      return {
        name: x.name,
        label: x.label,
        desc: x.name
      };
    });
    return [
      ...result,
      ...schema
    ]
  }

  public getEvents(): IBaseWidgetEvent[] {
    const result = super.getEvents();
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {}).map(x => {
      return {
        name: x.name,
        label: x.label,
        data: x.dataType,
        desc: x.name,
      }
    });
    return [
      ...result,
      ...schema
    ]
  }

  public serialize(): any {
    const result = super.serialize();
    result.schemaId = this.schemaId;
    result.schema = this.schema;
    result.data = this.data;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.schemaId = data.schemaId ?? this.schemaId;
    this.schema = data.schema ?? this.schema;
    this.data = data.data ?? this.data;
  }

  public override async init(): Promise<boolean> {
    if (this.schemaId) {
      const schema = await DataSchemaService.get(this.schemaId);
      this.schema = schema.schema;
    }

    if (this.scene.sceneMode !== SceneMode.Editor) {
      const json = JSON.parse(this.schema || '{}');
      const schema = this.getSchemaTypes(json.properties || {});
      schema.forEach(val => this.addEventListener(`service-${val.name}`, (data) => {
        if (data.dataType === val.eventDataType) {
          this.setSchemaDataValue(val.name, data.data);
          // this.triggerProVizEvent(val.name, val.eventDataType, data.data);
        }
      }))
    }

    return super.init();
  }
}

ModuleService.Register(JsonDataWidget.type, JsonDataWidget);
