export function removeChildren(el: HTMLElement) {
  while (el.lastChild) {
    el.removeChild(el.lastChild);
  }
}

/**
 *  Chrome does not allow auto play (with audio) so we trigger play
 * on first pointer down action so if play fails we
 * try to add a listener for any kind of interaction
 */
export function addPlayFnListener(fn: Function) {
  const callback = () => {
    fn();
    document.removeEventListener('pointerdown', callback);
  };
  document.addEventListener('pointerdown', callback);
}
