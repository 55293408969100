import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

interface Props {
    data: { key: string, name: string, amt: number }[]
}

const getPath = (x: number, y: number, width: number, height: number) => `M${x-width},${y + height}
          v-${height}
          q 0 -5, 5 -5
          h${width-10}
          q 5 0, 5 5
          v${height}
          z`;

const RoundedBar = (props: any) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x + width / 2 + 5, y, 10, height)} stroke="none" fill={fill} />;
};

export default function BarChartWidget(props: Props) {
    return <ResponsiveContainer width="100%" height="100%">
        <BarChart
            height={300}
            data={props.data}
            margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
            }}
        >
            <CartesianGrid stroke='#9ca2b1' vertical={false} />
            <XAxis dataKey="name" axisLine={false} tickLine={false} stroke='#9ca2b1' />
            <YAxis width={30} axisLine={false} tickLine={false} stroke='#9ca2b1' />
            <Tooltip />
            <Bar dataKey="amt" fill="#46538b" width={10} shape={<RoundedBar />} />
        </BarChart>
    </ResponsiveContainer>;
}