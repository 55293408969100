import { ReactElement, useEffect, useState } from "react";
import {
  ProVizConfig,
  UserService,
  APIUser,
  getCompanyRole,
  initAbortController,
  isAbortError,
  CompanyService,
  RoleService,
} from "@proviz/api-services";
import FormContainer from "../common/formcontainer";
import Button from "../common/button/Button";
import PagingControl from "../common/paging/PagingControl";
import Modal from "../common/modals/Modals";
import PageHeader from "../home/pageHeader";
import { searchStore } from "../../store/SearchStore";
import { userStore } from "../../store";
import { useQuery } from "@tanstack/react-query";
import { APIRole } from "@proviz/api-services/lib/models/Role";
import { useHistory } from "react-router-dom";

export default function UsersView(): ReactElement {
  const user = userStore((s) => s.user);
  const [failed, setFailed] = useState<boolean>(false);
  const [inviteSent, setInviteSent] = useState<boolean>(false);
  const [users, setUsers] = useState<APIUser[]>([]);
  const [refreshUsers, setRefreshUsers] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [companyId] = useState<string | undefined>(
    ProVizConfig.impersonateCompanyId
  );
  const [showCompanyList, setShowCompanyList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined
  );
  const [companies, setCompanies] = useState<{ key: string; value: string }[]>(
    []
  );

  const [inviteEmail, setInviteEmail] = useState<string>("");
  const searchParams = searchStore((x) => x.search);
  const history = useHistory();
  const rolesQuery = useQuery<
    Promise<APIRole[]>,
    undefined,
    APIRole[],
    [string, string]
  >(["roles", "available"], ({ queryKey: [key, role] }) => {
    return RoleService.available();
  });

  useEffect(() => {
    const abortController = initAbortController();
    if (refreshUsers) {
      setRefreshUsers(false);
    }
    UserService.paged(page, 20, searchParams || undefined, { abortController })
      .then((pagedUsers) => {
        console.log(pagedUsers.data);
        setUsers(pagedUsers.data);
        // setUsers([]);
        setTotalPages(pagedUsers.pagesTotal);
      })
      .catch((e) => {
        console.error(e);
        if (isAbortError(e)) return; // if the query was aborted, do nothing
        setFailed(true);
      });
    return () => abortController.abort();
  }, [refreshUsers, page, searchParams]);

  const createInvite = () => {
    if (inviteEmail === "") {
      return;
    }
    UserService.createInvite(inviteEmail)
      .then(() => {
        setInviteEmail("");
        setInviteSent(true);
        setTimeout(() => setInviteSent(false), 3000);
      })
      .catch(() => setFailed(true));
  };

  function changeRole(user: APIUser, role: string) {
    if (!companyId) {
      console.error("No company id");
      return;
    }
    UserService.setRole(user.id, companyId, role)
      // TODO Fix this mem leak
      .then(() => setRefreshUsers(true))
      .catch((e) => {
        console.error("Setting user role failed", e);
        setFailed(true);
      });
  }

  const showCompanyListModal = (userId: string) => {
    setShowCompanyList(true);
    setSelectedUser(userId);
    CompanyService.getAllKeyValue().then((results) => {
      setCompanies(
        results.sort((a, b) => {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        })
      );
    });
  };

  const hideCompanyListModal = () => {
    setShowCompanyList(false);
    setSelectedUser(undefined);
    setCompanies([]);
  };

  const body =
    refreshUsers || !companyId ? (
      "Loading..."
    ) : (
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => {
            const role = getCompanyRole(u, companyId);
            return (
              <tr key={u.id}>
                <td>{u.firstName}</td>
                <td>{u.lastName}</td>
                <td>{u.email}</td>
                <td>
                  {user &&
                  u.id !== user.id && // Users cannot change their own role
                  u.isSuperAdmin ? ( // Super admin status cannot be modified atm
                    <span>Super Admin</span>
                  ) : (
                    <select
                      value={role || ""}
                      className="provizInputBox"
                      onChange={(e) => changeRole(u, e.target.value)}
                    >
                      {rolesQuery.data?.map((r) => (
                        <option value={r.id}>{r.name}</option>
                      ))}
                    </select>
                  )}
                </td>
                <td>
                  <Button onClick={() => showCompanyListModal(u.id)}>
                    Add to Company
                  </Button>
                </td>
                <td>
                  <Button
                    onClick={() =>
                      history.push("/home/settings/editUser", { user: u })
                    }
                  >
                    Edit User
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );

  return failed ? (
    <h1>We're afraid something went wrong. Try again later.</h1>
  ) : (
    <div className="homeRightView">
      <PageHeader />

      <div className="homeContent">
        <div className="homeContentContainer">
          <div className="paddedContainer">
            <div className="userList">
              <FormContainer title="Invite User">
                <table className="inner">
                  <tbody>
                    <tr>
                      <td>Email Address</td>
                      <td>
                        <input
                          className="provizInputBox"
                          type="text"
                          onChange={(e) => setInviteEmail(e.target.value)}
                          placeholder="Email Address"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Button fullWidth={true} onClick={() => createInvite()}>
                          Create Invite
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {inviteSent && <div>Invite Sent Successfuly</div>}
              </FormContainer>
              <br />
              {body}
              <br />
            </div>

            {showCompanyList && (
              <Modal title={"Add User to Company"} close={hideCompanyListModal}>
                <div>Company List: {selectedUser}</div>
                <div>
                  {companies.map((c) => (
                    <div key={`c-${c.key}`} style={{ margin: 10 }}>
                      <Button
                        fullWidth={true}
                        onClick={() => {
                          if (selectedUser) {
                            UserService.addToCompany(selectedUser, c.key);
                          }
                          hideCompanyListModal();
                        }}
                      >
                        {c.value}
                      </Button>
                    </div>
                  ))}
                </div>
              </Modal>
            )}

            <div className="searchBar">
              <PagingControl
                page={page}
                totalPages={totalPages}
                pageSet={(p: number) => setPage(p)}
                isFileSelect={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
