import { ModuleService } from '../../../moduleService';
import { ProVizEventData, ProVizEventDataList } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetEvent } from '../../BaseWidgetEvent';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ResourceWidgetListWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'resource-widget-list';

  // Data
  public resourceIds: string[] = [];

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Resource Widget List';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = ResourceWidgetListWidget.type;
    this.widgetName = 'Resource Widget List';
    this.selectable = true;
    this.events = [];

    this.services = [
      {
        label: 'Apply',
        name: 'apply',
      },
    ];

    this.addEventListener('service-apply', (event: ProVizEventData) => {
      this.emitNewValue();
    });
  }

  private emitNewValue() {
    const data: ProVizEventDataList = {
      dataType: 'widgetId',
      data: this.resourceIds,
    };
    console.log(data);
    this.triggerProVizEvent('list', 'list', data);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'resourceIds',
        'Resource',
        'Data',
        'widget-list',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'This is value will be the resource ids being set',
      ),
    ];
  }

  public getEvents(): IBaseWidgetEvent[] {
    return [
      ...super.getEvents(),
      {
        label: 'List',
        name: 'list',
      },
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.resourceIds = this.resourceIds;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.resourceIds = data.resourceIds;
  }
}

ModuleService.Register(ResourceWidgetListWidget.type, ResourceWidgetListWidget);
