import React, { ReactElement, useEffect, useState } from "react";
import { FileService, FileType, initAbortController, isAbortError } from "@proviz/api-services";
import { SettableWidgetProperty } from "./SettableWidgetProperty";
import SetWidgetProperty from "../../../commands/SetWidgetProperty";
import Button from "../../common/button/Button";
import { useHandleAddResourceWidget } from "../handleAddResourceWidget";
import { ManagerStore } from "../../../store";
import { ResourceManagerStore } from "../../../store/ResourceStore";

interface Props {
  property: SettableWidgetProperty;
  header: ReactElement;
  fileType: FileType;
  entityName?: string;
}
/**
* Render file properties that do not have options for different languages.
*/
export const FileProperty = (props: Props) => {
  const { header, property, fileType, entityName } = props;
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [fileId, setFileId] = useState<string>(property.get());
  const [fileName, setFileName] = useState("Not Selected");
  const openFileSelect = ResourceManagerStore(s => s.openFileSelect) ?? { openFileSelect: undefined };
  const manager = ManagerStore((s) => s.GraphicsManager);
  const handleAddResourceWidget = useHandleAddResourceWidget()

  useEffect(() => {
    const abortController = initAbortController();
    async function updateFileInfo() {
      if (fileId) {
        try {
          const vidInfo = await FileService.get(fileId, { abortController });
          setFileName(vidInfo.displayName ?? fileId);
        } catch (e) {
          if (isAbortError(e)) return; // if the query was aborted, do nothing
          setFileName(`Error getting ${entityName} info`);
        }
      } else {
        setFileName("Not selected.");
      }
    }
    updateFileInfo();
    return () => abortController.abort();
  }, [fileId, entityName]);

  useEffect(() => {
    setFileId(property.get());
  }, [property]);

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    const resourceId = e.dataTransfer.getData("resource/id");
    const resourceType = e.dataTransfer.getData("resource/type");
    const resourceName = e.dataTransfer.getData("resource/name");
    if (resourceId && resourceType) {
      handleAddResourceWidget(selectResourceCallback, resourceType, resourceId, resourceName);
    }
  }

  const selectResourceCallback = (id: string | null) => {
    manager.execute(new SetWidgetProperty(property, fileId, id ?? undefined, manager ));
    setFileId(property.get());
    forceUpdate();
  }

  return <div className="videoSelector">
      {header}
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                {entityName ?? fileType}: {fileName}
              </p>
            </td>
          </tr>
          <tr>
            <td onDrop={handleDrop}>
              <Button type='resource' fullWidth={true}
                onClick={() => {
                  openFileSelect &&
                    openFileSelect(
                      fileType,
                      fileType === "Texture" ? true : false,
                      selectResourceCallback
                    );
                }}
              >
                Change
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>;
};
