import { FileService } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

const FILE_OPTIONS = {
  'area-target': { dataType: 'area-target', propertyType: 'string' },
  audio: { dataType: 'audio-options', propertyType: 'string' },
  'env-map': { dataType: 'env-map', propertyType: 'string' },
  image: { dataType: 'image-options', propertyType: 'string' },
  model: { dataType: 'model', propertyType: 'string' },
  'model-target': { dataType: 'model-target', propertyType: 'string' },
  scene: { dataType: 'scene', propertyType: 'string' },
  sprite: { dataType: 'sprite', propertyType: 'string' },
  srt: { dataType: 'srt-options', propertyType: 'string' },
  'texture-360': { dataType: 'texture-360', propertyType: 'string' },
  video: { dataType: 'video-options', propertyType: 'list' },
};

export default class FileResourceWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'file-resource';
  //data
  public fileType: string = 'model';
  public fileId?: string;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = FileResourceWidget.type;
    this.widgetName = 'File Resource';
    this.label = 'File Resource';
    this.usage = 'Flow';
    this.category = 'Others';
    this.events.push({
      label: 'File Id',
      name: 'file-id',
    });
    this.addService('Apply', 'apply', '<b>Applies the file id saved</b>', async () => {
      let data: any = this.fileId;
      if (typeof data != 'string') {
        data = Object.values(data)[0];
      }
      if (this.fileType === 'video' || this.fileType === 'audio') {
        data = await FileService.get(data);
        data = data.fileName;
        console.log(data);
      }
      this.triggerProVizEvent('file-id', FILE_OPTIONS[this.fileType].propertyType, data);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();

    return [
      ...result,
      this.createProperty(
        'fileType',
        'File Type',
        'Core',
        'select',
        'string',
        true,
        undefined,
        (data) => {
          this.fileType = data;
          console.log(data);
          this.fileId = undefined;
        },
        () => {
          return Object.keys(FILE_OPTIONS).map((x) => {
            return { key: x, label: x };
          });
        },
      ),
      this.createProperty(
        'fileId',
        'File',
        'Core',
        FILE_OPTIONS[this.fileType].dataType,
        FILE_OPTIONS[this.fileType].propertyType,
        true,
      ),
    ];
  }

  public serialize() {
    const result = super.serialize();
    result.fileType = this.fileType;
    result.fileId = this.fileId;
    return result;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.fileType = data.fileType ?? this.fileType;
    this.fileId = data.fileId ?? this.fileId;
  }
}
ModuleService.Register(FileResourceWidget.type, FileResourceWidget);
