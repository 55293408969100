import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class PhotoCaptureWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'photo-capture';

  // Data

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = PhotoCaptureWidget.type;
    this.widgetName = 'Photo Capture';
    this.label = 'Photo Capture';
    this.selectable = false;
    this.category = 'Experimental';

    this.events.push({
      label: 'Countdown Started',
      name: 'countdown-started',
    },{
      label: 'Countdown Finished',
      name: 'countdown-finished',
    },{
      label: 'Countdown',
      name: 'countdown',
      desc: 'The current count down value'
    },{
      label: 'Capture Started',
      name: 'capture-started',
    },{
      label: 'Capture',
      name: 'capture',
      desc: 'Image captured from the camera'
    },{
      label: 'Camera Ready',
      name: 'camera-ready',
      desc: 'Camera is ready to take a picture when pre-setup'
    });

    this.addService('Setup', 'setup', 'Setup the camera in preparation of taking a picture. If not called, capture service will run this on first capture.', () => {
      console.warn('Not supported in browser yet');
    });
    this.addService('Capture', 'capture', 'Capture an image from the camera', () => {
      console.warn('Not supported in browser yet');
    });
  }
}

ModuleService.Register(PhotoCaptureWidget.type, PhotoCaptureWidget);
