import React, { ReactElement, useState } from 'react'
import SetWidgetProperty from '../../../commands/SetWidgetProperty';
import { SpriteModal } from '../../home/SpriteModal';
import { SettableWidgetProperty } from './SettableWidgetProperty'
import { APISprite, SpriteService } from '@proviz/api-services';
import { useHandleAddResourceWidget } from '../handleAddResourceWidget';
import Button from '../../common/button/Button';
import { ManagerStore } from '../../../store';
import { ResourceManagerStore } from '../../../store/ResourceStore';

interface Props {
    property: SettableWidgetProperty;
    header: ReactElement;
    forceUpdate: () => void;
}

export const SpriteSelector = (props: Props) => {
    const { header, property } = props;
    const [spriteModal, setSpriteModal] = useState<APISprite | null>(null);
    const sceneManager = ManagerStore( s => s.SceneManager )
    const spriteId = property.get();
    const openFileSelect = ResourceManagerStore(s => s.openFileSelect) ?? { openFileSelect: undefined };
    const selectionCallback = (newId: string | null) => {
            sceneManager.execute(new SetWidgetProperty(property, spriteId, newId, sceneManager));
            props.forceUpdate();
    }

    const handleAddResourceWidget = useHandleAddResourceWidget();

    const handleDrop = (e: React.DragEvent<HTMLElement>) => {
        const resourceId = e.dataTransfer.getData("resource/id");
        const resourceType = e.dataTransfer.getData("resource/type");
        const resourceName = e.dataTransfer.getData("resource/name");
        if (resourceId && resourceType) {
          handleAddResourceWidget(selectionCallback, resourceType, resourceId, resourceName);
        }
    }

    return (
        <div onDrop={handleDrop}>
            {header}
            <div>{spriteId}</div>
            {spriteId && <Button type='primary' fullWidth={true} onClick={async () => {
                const sprite = await SpriteService.get(spriteId);
                setSpriteModal(sprite);
            }} >Edit</Button>}
            <Button type='resource' fullWidth={true}
                onClick={() => openFileSelect && openFileSelect('Sprite', false, selectionCallback)}>
                    Change
            </Button>
            {spriteModal && 
                <SpriteModal
                    sprite={spriteModal}
                    close={() => {setSpriteModal(null); }} />}
        </div>
    )
}
