import { useState } from 'react';
import { APIFile, FileService } from "@proviz/api-services";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import Button from '../common/button/Button';

const downloadImage = getAbsoluteUrl('images/Other/Download.svg');

interface Props {
    file: APIFile;
}

export const DownloadFileButton = (props: Props) => {
    const { file } = props;
    const [downloading, setDownloading] = useState<string[]>([]);

    return (
        <>
            {file.secure && downloading.indexOf(file.id) !== -1 && <div>Generating Download...</div>}
            {file.secure && downloading.indexOf(file.id) === -1 && 
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Button classNames="downloadButton" onClick={() => {
                if (downloading.indexOf(file.id) !== -1) {
                    return;
                }
                const downloads = [...downloading];
                downloads.push(file.id);
                setDownloading(downloads);
                FileService.downloadSecure(file).then(() => {
                    const downloads = [...downloading];
                    downloads.splice(downloads.indexOf(file.id), 1);
                    setDownloading(downloads);
                }).catch(() => {
                    const downloads = [...downloading];
                    downloads.push(file.id);
                    setDownloading(downloads);
                    FileService.downloadSecure(file).then(() => {
                        const downloads = [...downloading];
                        downloads.splice(downloads.indexOf(file.id), 1);
                        setDownloading(downloads);
                    }).catch(() => {
                        const downloads = [...downloading];
                        downloads.splice(downloads.indexOf(file.id), 1);
                        setDownloading(downloads);
                    })
                })
            }}>
                     <img className="downloadImage" src={downloadImage} alt="Download" /> Download
            </Button>}
            {!file.secure && <Button classNames="downloadButton" link={file.location || ''} >
                     <img className="downloadImage" src={downloadImage} alt="Download" /> Download
            </Button>}
        </>    
    )   
}   