import { BaseDataService, IServiceOptions } from "../BaseDataService";
import { APIUser, APIPermission, Paged } from "../models";

export class APIUserService extends BaseDataService<any> {
  constructor() {
    super("user");
  }

  public async getMe(options?: IServiceOptions): Promise<any> {
    try {
      return await this.request("me", options);
    } catch (err) {
      throw err;
    }
  }

  public async getPermissions(
    options?: IServiceOptions
  ): Promise<APIPermission[]> {
    return await this.request("permissions", options);
  }

  public async getAllPaged(
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APIUser>> {
    return await this.request(
      `?page=${page}&size=${size}&filter=${filter || ""}`,
      options
    );
  }

  public async create(
    companyName: string,
    options?: IServiceOptions
  ): Promise<any> {
    return await this.request("setup", {
      method: "POST",
      body: { companyName },
      ...options,
    });
  }

  public async invite(code: string, options?: IServiceOptions): Promise<any> {
    return await this.request(`invite/${code}`, { method: "POST", ...options });
  }

  public async createInvite(
    email: string,
    options?: IServiceOptions
  ): Promise<void> {
    return await this.request("invite", {
      method: "POST",
      body: { email },
      ...options,
    });
  }

  public async setRole(
    userId: string,
    companyId: string,
    roleId: string,
    options?: IServiceOptions
  ): Promise<APIUser> {
    return await this.request("role", {
      method: "PUT",
      body: {
        userId,
        companyId,
        roleId,
      },
      ...options,
    });
  }

  public async setDefaultCompany(
    userId: string,
    companyId: string,
    options?: IServiceOptions
  ): Promise<APIUser> {
    return await this.request(`setDefault/${userId}/${companyId}`, {
      method: "PUT",
      ...options,
    });
  }

  public async addToCompany(
    userId: string,
    companyId: string,
    options?: IServiceOptions
  ): Promise<APIUser> {
    return await this.request(`addCompany/${userId}/${companyId}`, {
      method: "PUT",
      ...options,
    });
  }

  public async update(
    user: APIUser,
    options?: IServiceOptions
  ): Promise<APIUser> {
    const body = new FormData();
    body.append("firstName", user.firstName);
    body.append("lastName", user.lastName);
    return await this.submit(`${user.id}/update`, "PUT", body, options);
  }

  public async updateAvatar(
    user: APIUser,
    options?: IServiceOptions
  ): Promise<APIUser> {
    const body = new FormData();
    body.append("avatar", user.avatar);
    return await this.submit(`${user.id}/update/avatar`, "PUT", body, options);
  }

  public async updateInfo(
    user: APIUser,
    options?: IServiceOptions
  ): Promise<APIUser> {
    const body = new FormData();
    body.append("firstName", user.firstName);
    body.append("lastName", user.lastName);
    body.append("email", user.email);
    body.append("avatar", user.avatar);
    return await this.submit(`${user.id}/updateUserInfo`, "PUT", body, options);
  }
}

const UserService = new APIUserService();

export default UserService;
