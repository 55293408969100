import { BoxGeometry, Color, Material, Mesh, MeshBasicMaterial, Object3D, PerspectiveCamera, PlaneGeometry, ShaderMaterial, Vector2, Vector3, VideoTexture } from "three";
import { VideoBillboardMode } from "./VideoBillboardMode";
import { fragmentShader, fragmentShaderKeyLeft, fragmentShaderKeyRight, fragShader, vertexShader } from "./videoShaders";
import { VideoTransparencySettings } from "./VideoTransparencySettings";
import { VideoTransparent } from "./VideoTransparent";

export default class VideoUtils {

    static MeshDefaultWidth = 19.2; // 1920 / 100.0
    static MeshDefaultHeight = 10.8; // 1080 / 100.0

    static worldPos: Vector3 = new Vector3();
    static forward: Vector3 = new Vector3();

    static tempCanvas: HTMLCanvasElement | undefined = undefined;

    static createMesh(videoMaterial: Material) {
      const geometry = new PlaneGeometry(VideoUtils.MeshDefaultWidth, VideoUtils.MeshDefaultHeight);
      return new Mesh(geometry, videoMaterial);
    }

    static setupClickableGeometry(visible: boolean = true) {
      const geometry = new BoxGeometry(VideoUtils.MeshDefaultWidth, VideoUtils.MeshDefaultHeight, 0.1);
      const params: any = { color: 0xffffff, visible };
      const physicsBounds = new Mesh(geometry, new MeshBasicMaterial(params));
      return physicsBounds;
    }
    
    static createVideoTexture(video: HTMLVideoElement) {
      const videoTexture = new VideoTexture(video);
      videoTexture.anisotropy = 2;
      return videoTexture;
    }

    static getMimeType(url: string) {
      if (!url) {
        return '';
      }
      const ext = url.split('.').pop();
      switch (ext) {
        case 'webm':
          return 'video/webm';
        case 'mp4':
          return 'video/mp4';
        case 'mpeg':
          return 'video/mpeg';
        default:
          return '';
      }
    }

    static createMaterial(transparencySettings: VideoTransparencySettings, videoTexture: VideoTexture) {
        if (transparencySettings.transparent === VideoTransparent.Chroma) {
            // apply color to texture
            
            // create pixel color data
            // const width = videoTexture.image.width || 1920, height = videoTexture.image.height || 1280;
            // const size = width * height;
            // const data = new Uint8Array( 4 * size );
            // for ( let i = 0; i < size; i ++ ) {
            //     const stride = i * 4,
            //     a1 = i / size,
            //     a2 = i % width / width;
            //     // set r, g, b, and alpha data values
            //     data[ stride ] = Math.floor(255 * a1);            // red
            //     data[ stride + 1 ] = 255 - Math.floor(255 * a1);  // green
            //     data[ stride + 2 ] = Math.floor(255 * a2);        // blue
            //     data[ stride + 3 ] = 255;                         // alpha
            // }
            // videoTexture.source.data = data;
            // videoTexture.source.needsUpdate = true;


        const uniforms = {
            tex: { type: 'sampler2D', value: videoTexture },
            keyColor: {
            value: new Color(transparencySettings.chromaKey),
            },
            texWidth: {
            value: videoTexture.image.width || 1920,
            },
            texHeight: {
            value: videoTexture.image.height || 1280,
            },
            similarity: {
            value: transparencySettings.similarity,
            },
            smoothness: {
            value: transparencySettings.smoothness,
            },
            spill: {
            value: transparencySettings.spill,
            },
            opacity: {
            value: transparencySettings.opacity ?? 1.0,
            // should a 0 value for opacity prevent shader creation altogether?
            },
        };
        return new ShaderMaterial({
            name: 'Video Widget Material',
            uniforms,
            transparent: true,
            vertexShader: vertexShader,
            fragmentShader: fragShader,
        });
        } else if (transparencySettings.transparent !== VideoTransparent.None) {
        const uniforms = {
            tex: { type: 'sampler2D', value: videoTexture },
            keyColor: {
            value: new Color(transparencySettings.chromaKey),
            },
            texWidth: {
            value: videoTexture.image.width || 1920,
            },
            texHeight: {
            value: videoTexture.image.height || 1280,
            },
            similarity: {
            value: transparencySettings.similarity,
            },
            smoothness: {
            value: transparencySettings.smoothness,
            },
            spill: {
            value: transparencySettings.spill,
            },
            opacity: {
            value: transparencySettings.opacity ?? 1.0,
            },
        };
        let _fragShader = fragmentShader;
        switch (transparencySettings.transparent) {
            case VideoTransparent.KeyLeft:
            _fragShader += fragmentShaderKeyLeft;
            break;
            case VideoTransparent.KeyRight:
            _fragShader += fragmentShaderKeyRight;
            break;
            case VideoTransparent.KeyTop:
            _fragShader += fragmentShaderKeyRight;
            break;
            case VideoTransparent.KeyBottom:
            _fragShader += fragmentShaderKeyRight;
            break;
            default:
            break;
        }
        return new ShaderMaterial({
            uniforms,
            transparent: true,
            vertexShader: vertexShader,
            fragmentShader: _fragShader,
        });
        } else {
        return new MeshBasicMaterial({
            map: videoTexture,
            transparent: transparencySettings.opacity < 1,
            opacity: transparencySettings.opacity,
        });
        }
    }
    
    static createVideoDOM(loop: boolean, id: string) {
        const video = document.createElement('video');
        video.setAttribute('crossorigin', 'anonymous');
        video.crossOrigin = 'anonymous';
        video.setAttribute('id', id);
        video.setAttribute('webkit-playsinline', '');
        video.setAttribute('playsinline', '');
        video.setAttribute('preload', 'auto');
        if (loop) {
          video.setAttribute('loop', 'true');
          video.setAttribute('muted', '');
        }
        const videoSource = document.createElement('source');
        video.appendChild(videoSource);
    
        return { video, videoSource };
    }
    
    static applyMaterial(obj: Object3D, m: Material) {
        if (obj instanceof Mesh) {
          obj.material = m;
        }
        obj.children.forEach(o => this.applyMaterial(o, m));
    }

    static applyBillboard(renderNode: Object3D, camera: PerspectiveCamera, billboard: VideoBillboardMode) {

        switch (billboard) {
            case VideoBillboardMode.XYZ: {
              renderNode.lookAt(
                camera.position.x,
                camera.position.y,
                camera.position.z,
              );
              renderNode.updateWorldMatrix(false, true);
            }
            case VideoBillboardMode.YLock: {
              renderNode.getWorldPosition(this.worldPos);
              renderNode.lookAt(
                camera.position.x,
                this.worldPos.y,
                camera.position.z,
              );
              renderNode.updateWorldMatrix(false, true);
            }
            default:
              break;
          }
    }

    static verifyClick(point: Vector3, uv: Vector2, transparencySettings: VideoTransparencySettings, videoMaterial: ShaderMaterial | MeshBasicMaterial) {

        console.log('verifyClick', point, uv);
        // get video texture
        if (videoMaterial) {
          let videoTexture: VideoTexture | undefined = undefined;
          if (videoMaterial instanceof MeshBasicMaterial) {
            videoTexture = (videoMaterial as MeshBasicMaterial).map as VideoTexture;
          } else if (videoMaterial instanceof ShaderMaterial) {
            videoTexture = (videoMaterial as ShaderMaterial).uniforms['tex'].value as VideoTexture;
    
            switch (transparencySettings.transparent) {
              case VideoTransparent.KeyLeft:
                uv.x = uv.x * 0.5;
                break;
              case VideoTransparent.KeyRight:
                uv.x = 0.5 + uv.x * 0.5;
                break;
              case VideoTransparent.KeyTop:
                uv.y = 0.5 + uv.y * 0.5;
                break;
              case VideoTransparent.KeyBottom:
                uv.y = uv.y * 0.5;
                break;
              default:
                break;
            }
          }
    
          // invert texutre lookup
          uv.y = 1.0 - uv.y;
          
          if (videoTexture) {
            // get pixel color of video texture
    
            // create html canvas
            if (!this.tempCanvas) {
              this.tempCanvas = document.createElement('canvas');
              this.tempCanvas.width = videoTexture?.source.data.videoWidth || videoTexture?.image.width || 1920; // videoTexture.image.width;
              this.tempCanvas.height = videoTexture?.source.data.videoHeight ||videoTexture?.image.height || 1080; // videoTexture.image.height;
            }
            // draw video texture to canvas
            const ctx = this.tempCanvas.getContext('2d');
            if (ctx) {
              const chromaKey = new Color(transparencySettings.chromaKey);
              ctx.drawImage(videoTexture.image, 0, 0);
              // get pixel color
              const pixelColorArr = ctx.getImageData(uv.x * this.tempCanvas.width, uv.y * this.tempCanvas.height, 1, 1).data;
              const pixelColor = new Color(pixelColorArr[0] / 255, pixelColorArr[1] / 255, pixelColorArr[2] / 255);
              // console.log(pixelColor, chromaKey);
    
              if (transparencySettings.transparent === VideoTransparent.Chroma) {
                // if pixel color is close to chroma key color
                const r = Math.abs(pixelColor.r * 255 - chromaKey.r * 255);
                const g = Math.abs(pixelColor.g * 255 - chromaKey.g * 255);
                const b = Math.abs(pixelColor.b * 255 - chromaKey.b * 255);
                // console.log(r,g,b);
                if (
                  r < 5 &&
                  g < 5 &&
                  b < 5
                ) {
                  return false;
                }
              }
              // check if pixel color from key is not black
              else if (pixelColor[0] > 0) {
                return false;
              }
            }
          }
        }

        return true;
    }

    static applyInFrontOfCamera(renderNode: Object3D, camera: PerspectiveCamera) {

      renderNode.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z,
      );

      camera.getWorldDirection(this.forward);
      renderNode.position.add(this.forward);

     renderNode.quaternion.set(
        camera.quaternion.x,
        camera.quaternion.y,
        camera.quaternion.z,
        camera.quaternion.w,
      );
    }
}