import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class BoolWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'bool';
  private static instanceCount = 0;

  // Data
  public initialValue: boolean = true;

  private state: boolean = true;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    if (!notInScene) {
      BoolWidget.instanceCount++;
    }
    this.label = 'Bool ' + BoolWidget.instanceCount;

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = BoolWidget.type;
    this.widgetName = 'Bool';
    this.selectable = true;
    this.events = [
      {
        label: 'True',
        name: 'true',
      },
      {
        label: 'False',
        name: 'false',
      },
      {
        label: 'Value',
        name: 'value',
      },
    ];

    this.addService('Set True', 'set-true', 'Sets to true', () => {
      this.state = true;
      this.handleLogicUpdate();
    });
    this.addService('Set False', 'set-false', 'Sets to false', () => {
      this.state = false;
      this.handleLogicUpdate();
    });
    this.addService('Set Value', 'set-value', 'Sets to value', (event: ProVizEventData) => {
      if (event.dataType === 'boolean') {
        this.state = event.data as boolean;
        this.handleLogicUpdate();
      } else {
        console.warn('invalid event passed to set-value. Only accepts boolean.');
      }
    });
    this.addService('Apply', 'apply', 'Apply the current value', () => {
      this.handleLogicUpdate();
    })
  }

  private handleLogicUpdate() {
    this.triggerProVizEvent(this.state.toString(), 'boolean', this.state);
    this.triggerProVizEvent('value', 'boolean', this.state);
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.initialValue = data.initialValue ?? this.initialValue;
  }

  public serialize() {
    const result = super.serialize();
    result.initialValue = this.initialValue;
    return result;
  }

  public async init(): Promise<boolean> {
    if (await super.init()) {
      this.state = this.initialValue;
      return true;
    }
    return false;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result, this.createProperty('initialValue', 'Initial Value', 'Core', 'bool', 'boolean', true)];
  }
}

ModuleService.Register(BoolWidget.type, BoolWidget);
