import { BaseDataService } from "../BaseDataService";
import { APITarget, Paged } from "../models";
import { IServiceOptions, TargetType } from "..";

export class APITargetService extends BaseDataService<APITarget> {
  constructor() {
    super("target");
  }

  async createTarget(
    name: string,
    targetType: TargetType,
    settings: string,
    options?: IServiceOptions
  ): Promise<any> {
    return await this.request("", {
      method: "POST",
      body: { name, targetType, settings },
      ...options,
    });
  }

  public async getAllPagedWithTargetType(
    page: number,
    size: number,
    filter: string,
    targetTypes: TargetType[],
    options?: IServiceOptions
  ): Promise<Paged<APITarget>> {
    return this.request(`pagedWithTarget`, {
      method: "POST",
      body: { page, size, filter, targetTypes },
      ...options,
    });
  }
}

const TargetService = new APITargetService();

export default TargetService;
