import { BaseDataService, IServiceOptions } from "../BaseDataService";
import { APICompany } from "../models";

export class APICompanyService extends BaseDataService<APICompany> {
  constructor() {
    super("Company");
  }

  public async impersonate(
    id: string,
    options?: IServiceOptions
  ): Promise<void> {
    return await this.request(`impersonate/${id}`, {
      method: "POST",
      ...options,
    });
  }

  public async create(body: { name: string; tags: string[] }) {
    return await this.request("create", { method: "POST", body });
  }

  public async stopImpersonate(options?: IServiceOptions): Promise<void> {
    return await this.request("impersonate", { method: "DELETE", ...options });
  }

  public async getAllKeyValue(
    options?: IServiceOptions
  ): Promise<{ key: string; value: string }[]> {
    return await this.request("all", options);
  }

  public async update(
    comp: { name: string; id: string },
    options?: IServiceOptions
  ): Promise<APICompany> {
    const body = new FormData();
    body.append("name", comp.name ?? "");
    return await this.submit(`${comp.id}/update`, "PUT", body, options);
  }

  public async getCompanyFromInvite(inviteId: string): Promise<APICompany> {
    return await this.request(`invites/${inviteId}`);
  }
}

const CompanyService = new APICompanyService();

export default CompanyService;
