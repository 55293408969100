import { PerspectiveCamera } from "three";
import { Size, Position } from "./Types";

export default class Camera {
  camera: PerspectiveCamera;
  constructor(screenDimensions: Size, position: Position) {
    const aspectRatio = screenDimensions.width / screenDimensions.height;
    const fieldOfView = 60;
    const near = 0.01;
    const far = 20000;
    const camera = new PerspectiveCamera(fieldOfView, aspectRatio, near, far);
    const { x, y, z } = position;
    camera.position.set(x, y, z);
    camera.lookAt(0, 0, 0);
    this.camera = camera;
  }

  setSize(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }
}
