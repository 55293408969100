import { APIFile, FileService, ProVizConfig, TextureService } from '@proviz/api-services';
import { PanoramaTexture } from './panoramaTexture';

export class EquirectangularTexture extends PanoramaTexture {
  isEquirectangular = true;
  id: string = '';
  location?: string;

  serialize() {
    return { id: this.id, location: this.location };
  }
  deserialize(data: any) {
    this.id = data.id;
    this.location = data.location;
  }
  async getTextureSource() {
    if (ProVizConfig.selfContained || !this.location) {
      await this.updateTextureSources();
    }
    return this.location;
  }
  async updateTextureSources() {
    if (ProVizConfig.selfContained) {
      this.location = FileService.getLocation((await TextureService.get(this.id))?.file as APIFile);
    } else {
      const texture = await TextureService.get(this.id);
      this.location = texture.file?.location;
    }
  }
}

export function isEquirectangular(t: any): t is EquirectangularTexture {
  return t.isEquirectangular === true;
}
