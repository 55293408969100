import { ReactElement, useEffect, useState } from 'react';
import PageHeader from './pageHeader';
import PageContentHeader from './pageContentHeader';
import { APIModel, APIScene, ModelService, ProVizConfig, SceneService } from '@proviz/api-services';
import { getAbsoluteUrl } from '@proviz/proviz-sdk';
import ThumbnailCard from './ThumbnailCard';
import PaddedContainer from '../common/containers/paddedContainer';
import { userStore } from '../../store';
const failedThumbnail = getAbsoluteUrl('images/Error.svg')
const processingThumbnail = getAbsoluteUrl('images/Processing.svg')

export default function DashboardView(): ReactElement {

    const [ models, setModels ] = useState<APIModel[]>([]);
    const [ scenes, setScenes ] = useState<APIScene[]>([]);
    const user = userStore( s => s.user)

    const getUpdatedData = () => {
        ModelService.paged(0, 4).then((pagedData) => setModels(pagedData.data));
        SceneService.paged(0, 4).then((pagedData) => setScenes(pagedData.data));
    }

    useEffect(() => {
        getUpdatedData();
        ProVizConfig.events.addListener('impersonate-change', getUpdatedData);
        return () => {
            ProVizConfig.events.removeListener('impersonate-change', getUpdatedData);
        }
    }, []);
    
    return <div className="homeRightView">

        <PageHeader />

        <div className='homeContent'>
            <div className='homeContentContainer'>

                <PaddedContainer>
                    <PageContentHeader title={user ? `Hello ${user.firstName}!` : 'Hello!' } />

                    <PageContentHeader title='Latest Scenes' headerType='sub' />
                </PaddedContainer>
                <div className="modelList">
                    {scenes.map((scene) => {
                        return (<ThumbnailCard
                            key={scene.id}
                            title={scene.name}
                            thumbnail={scene.thumbnailFile?.location}
                            openUrl={`/editor/${scene.id}`}
                            updated={scene.updated}
                            onDragStart={(e: React.DragEvent<HTMLElement>) => {
                                console.log('scene drag started');
                                e.dataTransfer.setData('scene/id', scene.id);
                            }}
                            >
                                {/* <InfoIcon onClick={() => {
                                    setInfoModal(scene);
                                }} /> */}
                        </ThumbnailCard>);
                    })}
                </div>

                <PaddedContainer>
                    <PageContentHeader title='Latest Models' headerType='sub' />
                </PaddedContainer>
                <div className="modelList">
                    {models.map((m: APIModel) => {
                        let padded = false;
                        let thumbnail = m.thumbnail;
                        if (m.status === 'Failed') {
                            // since the failed and processing thumbnails are pngs
                            // we don't want to stretch them in the same way as the svg 
                            // defaultthumbnail
                            padded = true;
                            thumbnail = failedThumbnail;
                        }
                        if (m.status === 'Processing') {
                            padded = true;
                            thumbnail = processingThumbnail;
                        }
                        return <ThumbnailCard
                            key={m.id}
                            title={m.name ?? 'Unnamed model'}
                            thumbnail={thumbnail}
                            openUrl={`/model/${m.id}`}
                            padded={padded}
                            updated={m.updated}
                            onDragStart={(e) => {
                                console.log('model drag start', m.id);
                                e.dataTransfer.setData('model/id', m.id);
                            }}
                            >
                                {/* <InfoIcon onClick={() => setStatusModal(m)} /> */}
                        </ThumbnailCard>;
                    })}
                </div>
            </div>
        </div>

    </div>;
}
