import { BaseDataService, IServiceOptions } from "../BaseDataService";
import { APITexture, Paged } from "../models";
import { TextureType } from "../types/TextureType";
import { dataUrlToBlob } from "../utils/DataConversion";

export class APITextureService extends BaseDataService<APITexture> {
  constructor() {
    super("texture");
  }

  public async getPaged(
    quantity: number,
    offset: number,
    options?: IServiceOptions
  ) {
    return await this.request(`paged/${quantity}/${offset}`, options);
  }

  public async getAllPaged(
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APITexture>> {
    return await this.request(
      `?page=${page}&size=${size}&filter=${filter || ""}`,
      options
    );
  }

  public async getTextureByFileId(
    fileId: string,
    options?: IServiceOptions
  ): Promise<APITexture> {
    return await this.request(`file/${fileId}`, { method: "GET", ...options });
  }

  public async getBatch(
    ids: string[],
    options?: IServiceOptions
  ): Promise<APITexture[]> {
    return await this.request(`batch`, {
      method: "POST",
      body: ids,
      ...options,
    });
  }

  public async uploadTexture(
    textureType: TextureType,
    fileAsDataURI: string,
    options?: IServiceOptions
  ): Promise<APITexture> {
    const body = new FormData();
    body.append("texture", fileAsDataURI);
    return await this.submit(`upload/${textureType}`, "POST", body, options);
  }

  public async uploadTextureFromDatUri(
    data: string,
    options?: IServiceOptions
  ): Promise<APITexture> {
    const body = new FormData();
    body.append("texture", await dataUrlToBlob(data));
    return await this.submit(`upload/albedo`, "POST", body, options);
  }

  public async uploadTextureFile(
    file: string | Blob,
    options?: IServiceOptions
  ): Promise<APITexture> {
    const body = new FormData();
    body.append("texture", file);
    return await this.submit(`upload/albedo`, "POST", body, options);
  }
}

const TextureService = new APITextureService();

export default TextureService;
