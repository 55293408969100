import { DoubleSide, LineBasicMaterial, Mesh, ShapeGeometry } from 'three';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { ProVizScene } from '../../..';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { proVizFonts } from '../../../proVizFonts';
import { ProVizEventData } from '../../../ProVizEventData';
import { TextWidgetDefinition } from './textWidgetDefinition';

export class TextWidget extends BasePositionableWidget implements IBaseWidgetType {
  private static instanceCount = 0;

  private threejsFont?: Font;
  private textMesh?: Mesh<ShapeGeometry, LineBasicMaterial>;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.setup(TextWidgetDefinition);
    this.widgetName = TextWidgetDefinition.label;

    // TODO: instance count increment, move to base widget
    TextWidget.instanceCount++;
    this.label = 'Text ' + TextWidget.instanceCount;

    this.attachToProperty('textContent', {
      set: (data: any) => {
        this.setPropertyValue('textContent', data);
        this.updateText();
      }
    })
  }

  public async init() {
    if (!(await super.init())) {
      return false;
    }

    this.selectable = true;
    this.addServiceHandler('Set Text', this.updateText.bind(this));

    this.loadFont();

    return true;
  }

  private loadFont() {
    const fontLoader = new FontLoader();
    fontLoader.load(proVizFonts.helvetiker.regular, (threejsFont: Font) => {
      this.threejsFont = threejsFont;
      this.updateText();
    });
  }

  updateText(ev?: ProVizEventData) {
    if (ev?.dataType === 'string') {
      this.setPropertyValue('textContent', ev.data);
    } else if (ev) {
      this.setPropertyValue('textContent', '');
    }

    var textContent: string = this.getPropertyValue('Text Content') ?? '';

    var fontSize: number = this.getPropertyValue('Font Size');
    var color: string = this.getPropertyValue('Color');

    if (!this.threejsFont) {
      // font hasn't been setup yet
      return;
    }

    if (this.textMesh) {
      this.renderNode.remove(this.textMesh);
    }

    const matDark = new LineBasicMaterial({
      color,
      side: DoubleSide,
    });

    const shapes = this.threejsFont.generateShapes(textContent, (fontSize || 12) * 0.01);
    const geometry = new ShapeGeometry(shapes);
    geometry.computeBoundingBox();
    if (!geometry.boundingBox) {
      console.error('Cannot compute bounding box');
      return;
    }

    // Center the text
    const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
    geometry.translate(xMid, 0, 0);
    this.textMesh = new Mesh(geometry, matDark);
    this.renderNode.add(this.textMesh);
  }

  dispose() {
    super.dispose();
    this.textMesh?.material.dispose();
    this.textMesh?.geometry.dispose();
  }
}
