import React, {
  ReactElement,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  ModelService,
  FileService,
  FileType,
  TextureService,
  SpriteService,
} from "@proviz/api-services";
import {
  getAbsoluteUrl,
  suspendableTimeout,
  SuspendableTimeout,
} from "@proviz/proviz-sdk";
import { Dropzone } from "../dropzone";
import Button from "../button/Button";

const xIcon = getAbsoluteUrl("images/cancel.svg");
const uploadIcon = getAbsoluteUrl("images/upload.svg");

interface Props {
  open: boolean;
  setOpen: () => void;
  close: () => void;
  reload: () => void;
  setWaitingText: (text: string) => void;
  resourceType: FileType;
  extensionsOverride?: string[];
  dragRef?: React.RefObject<Element>;
  disableDragAndDrop?: boolean;
  children: any;
}

const MultiFileExts = ["gltf", "glb", "fbx"];

export default function UploadModal(props: Props): ReactElement {
  const {
    open,
    reload,
    setWaitingText: setShowLoading,
    setOpen,
    resourceType,
    extensionsOverride,
    dragRef,
    disableDragAndDrop,
  } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const [resources, setResources] = useState<File[]>([]);
  const [rejectedFileNames, setRejectedFileNames] = useState<string[]>([]);
  const [fullWindowDragActive, setFullWindowDragActive] =
    useState<boolean>(false);
  const [ext, setExt] = useState("");

  const handleUpload = useCallback((files: File[]) => {
    if (files.length < 0) {
      return;
    }
    let f = files[0];
    const _ext = f.name.split(".").pop();
    if (!(f.name && _ext)) {
      return;
    }
    setFile(f);
    setExt(_ext);
  }, []);

  async function handleResourceUpload(
    acceptedFiles: File[],
    rejectedFiles: File[] = []
  ) {
    const fList = [...resources];
    const rejectList = [...rejectedFileNames];
    for (let i = 0; i < acceptedFiles.length; i++) {
      // Depending on if the files were passed in from a directory input or a file input,
      // they would have relative or absolute paths, which will not work with the API
      // This extracts the file contents and creates a new File object to avoid the issue
      const bits = await acceptedFiles[i].arrayBuffer();
      const currentFile = new File([bits], acceptedFiles[i].name);
      fList.push(currentFile);
    }
    for (let i = 0; i < rejectedFiles.length; i++) {
      rejectList.push(rejectedFiles[i].name);
    }
    setRejectedFileNames(rejectList);
    setResources(fList);
  }

  async function upload() {
    props.close();

    setShowLoading(
      resourceType === "All"
        ? "Uploading your File"
        : `Uploading your ${resourceType}`
    );
    try {
      if (!file) {
        throw Error("No file.");
      }
      let fileName = file.name || `New ${resourceType}`;

      if (
        resourceType === "Model" ||
        (resourceType === "All" && MultiFileExts.includes(ext))
      ) {
        const parts = fileName.split(".");
        parts.splice(parts.length - 1, 1);
        fileName = parts.join(".");

        if (resources.length > 0) {
          await ModelService.uploadModelWithResources(
            fileName,
            file,
            resources,
            ext
          );
        } else {
          await ModelService.uploadModel(fileName, file, ext);
        }
      } else if (resourceType === "Texture") {
        await TextureService.uploadTextureFile(file);
      } else if (resourceType === "Sprite") {
        await SpriteService.upload(file, fileName);
      } else if (
        resourceType === "Image" ||
        (resourceType === "All" && ["png", "jpg", "jpeg"].includes(ext))
      ) {
        await FileService.uploadFile(file, "Image", fileName, {
          abortController: new AbortController(),
        });
      } else {
        const fileTypes: { [key: string]: FileType } = {
          mp4: "Video",
          mov: "Video",
          webm: "Video",
          wav: "Audio",
          mp3: "Audio",
          dat: "Data",
          xml: "Data",
          json: "Data",
          zip: "Data",
          hdr: "EnvMap",
          srt: "Subtitle",
        };

        const fileType = fileTypes[ext] ? fileTypes[ext] : "File";
        await FileService.uploadFile(file, fileType, fileName, {
          abortController: new AbortController(),
        });
      }
    } catch (e) {
      console.error(`Failed to upload ${resourceType}`, e);
      alert(
        `We aplogize that your ${resourceType} upload failed. Please try again later.`
      );
    }
    setShowLoading("");
    reload();
    setFile(undefined);
    if (resourceType === "Model") {
      setResources([]);
      setExt("");
    }
  }
  // Register a full screen drop handler that opens the
  // upload modal if a compatible file type is dropped
  const extensions = useMemo(() => {
    if (extensionsOverride) {
      return extensionsOverride;
    } else if (resourceType === "Model") {
      return ["gltf", "glb", "fbx", "obj", "stl", "blend"];
    } else if (resourceType === "Video") {
      return ["mp4", "mov", "webm"];
    } else if (resourceType === "Audio") {
      return ["wav", "mp3"];
    } else if (resourceType === "Image") {
      return ["png", "jpg", "jpeg"];
    } else if (resourceType === "Texture") {
      return ["png", "jpg", "jpeg"];
    } else if (resourceType === "Sprite") {
      return ["png"];
    } else if (resourceType === "EnvMap") {
      return ["hdr"];
    } else if (resourceType === "Subtitle") {
      return ["srt"];
    } else {
      return [
        "gltf",
        "mp4",
        "mov",
        "webm",
        "wav",
        "mp3",
        "png",
        "jpg",
        "jpeg",
        "dat",
        "xml",
        "json",
        "zip",
        "hdr",
        "srt",
      ];
    }
  }, [resourceType, extensionsOverride]);

  useEffect(() => {
    const validateDrop = (f: File) => {
      const name = f.name;
      const fExt = name.split(".").pop() || "";
      return extensions.includes(fExt.toLowerCase());
    };

    let delayClose: SuspendableTimeout | undefined;
    function handleWindowDrag(e: any) {
      if (open) {
        return;
      }

      console.log("handle window drag:", e.dataTransfer.getData("model/id"));
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        let item = e.dataTransfer.items[i];
        console.log(item.kind);
        console.log(item.type);
        if (item.kind === "string" || item.type === "model/id") {
          setFullWindowDragActive(false);
          return;
        }
      }

      setFullWindowDragActive(true);
      if (delayClose) {
        delayClose.suspend();
      } else {
        // If no drag movement is registered for .6
        // seconds close the full screen view.
        delayClose = suspendableTimeout(
          600,
          () => {
            setFullWindowDragActive(false);
            delayClose = undefined;
          },
          () => {
            delayClose = undefined;
          }
        );
      }
    }

    function handleDrop(e: any) {
      setFullWindowDragActive(false);
      let acceptedFiles = [];
      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === "file") {
            const file = e.dataTransfer.items[i].getAsFile();
            if (validateDrop(file)) {
              acceptedFiles.push(file);
            }
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          let f = e.dataTransfer.files[i];
          if (validateDrop(f)) {
            acceptedFiles.push(f);
          }
        }
      }
      if (acceptedFiles.length > 0) {
        setOpen();
        handleUpload(acceptedFiles);
      }
    }

    window.addEventListener("drop", handleDrop);

    if (disableDragAndDrop) {
      window.removeEventListener("dragenter", handleWindowDrag);
      window.removeEventListener("drop", handleDrop);
      dragRef?.current?.removeEventListener("dragenter", handleWindowDrag);
      return;
    } else if (dragRef?.current) {
      dragRef.current.addEventListener("dragenter", handleWindowDrag);
    } else {
      window.addEventListener("dragenter", handleWindowDrag);
    }

    const dragRefCurrent = dragRef?.current;

    return () => {
      if (dragRefCurrent) {
        dragRefCurrent.removeEventListener("dragenter", handleWindowDrag);
      } else {
        window.addEventListener("dragenter", handleWindowDrag);
      }
      window.removeEventListener("drop", handleDrop);
    };
  }, [
    setOpen,
    open,
    handleUpload,
    resourceType,
    extensions,
    dragRef,
    disableDragAndDrop,
  ]);

  const handleClose = () => {
    setFile(undefined);
    if (resourceType === "Model") {
      setResources([]);
      setRejectedFileNames([]);
      setExt("");
    }
    props.close();
  };

  const uploadModalMessage = (resourceType: FileType) => {
    switch (resourceType) {
      case "All":
        return "Upload a File";
      case "Audio":
        return "Upload an Audio File";
      case "EnvMap":
        return "Upload an hdr File";
      case "Subtitle":
        return "Upload an srt File";
      case "Image":
        return "Upload an Image";
      case "AreaTarget":
        return "Upload an Area Target";
      default:
        return `Upload a ${resourceType}`;
    }
  };

  return (
    <>
      {props.children}
      <div className={`modal ${open ? "" : "hidden"}`}>
        <>
          <div className="modalHeader">
            {uploadModalMessage(resourceType)}
            <div className="buttonContainer">
              <img src={xIcon} alt="Close" onClick={handleClose} />
            </div>
          </div>
          <div className="modalBody">
            <Dropzone
              onUpload={handleUpload}
              accept={extensions.map((extension) => "." + extension).join(",")}
              text={uploadModalMessage(resourceType)}
              reset={() => {
                setFile(undefined);
                setExt("");
              }}
              hide={file !== undefined}
            />
            {(resourceType === "Model" || resourceType === "All") &&
              file &&
              MultiFileExts.includes(ext) && (
                <div>
                  <p>
                    If your gltf file requires a bin file or resources
                    directory, select the folder containing your model and all
                    the resources.
                  </p>
                  <div className="dropzone-buttons">
                    <div>
                      <Dropzone
                        onUpload={handleResourceUpload}
                        accept={".bin,.png,.jpg,.jpeg,.webm,.svg"}
                        text={"Drop any additional resources here."}
                        reset={() => {}}
                        hideUploadImage={true}
                        isFolderUpload={true}
                        multiple
                      />
                    </div>
                    <div>
                      <Dropzone
                        onUpload={handleResourceUpload}
                        accept={".bin,.png,.jpg,.jpeg,.webm,.svg"}
                        text={"Drop any additional resources here."}
                        reset={() => {}}
                        hideUploadImage={true}
                        isFolderUpload={false}
                        uploadFile={false}
                        multiple
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "50px" }}>
                    <Dropzone
                      onUpload={handleResourceUpload}
                      accept={".bin,.png,.jpg,.jpeg,.webm,.svg"}
                      text={"Drop any additional resources here."}
                      reset={() => {}}
                      multiple
                    />
                  </div>
                </div>
              )}
            {file && (
              <div className="File list">
                {rejectedFileNames.length > 0 && (
                  <div>
                    <ul>
                      Rejected:
                      {rejectedFileNames.map((r) => {
                        return (
                          <li key={r}>
                            <p>{r}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {file.name}
                {resources && (
                  <ul>
                    {resources.map((r) => {
                      return (
                        <li key={r.name}>
                          <p>{r.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <Button onClick={upload}>Upload</Button>
              </div>
            )}
          </div>
        </>
        <table className="info-table">
          <tr>
            <td>
              <strong>Filetypes</strong>
            </td>
            <td>
              <strong>Max File Size</strong>
            </td>
          </tr>
          <tr>
            <td>FBX</td>
            <td>250MB</td>
          </tr>
          <tr>
            <td>GLB</td>
            <td>250MB</td>
          </tr>
          <tr>
            <td>GLTF</td>
            <td>250MB</td>
          </tr>
          <tr>
            <td>STL</td>
            <td>250MB</td>
          </tr>
          <tr>
            <td>OBJ</td>
            <td>250MB</td>
          </tr>
          <tr>
            <td>USDZ with AR</td>
            <td>50MB & 100k polygons</td>
          </tr>
        </table>
      </div>
      <div
        className={`modalOverlay ${open ? "" : "hidden"}`}
        onClick={open ? handleClose : () => {}}
      >
        {/* <img src={uploadIcon} alt='Upload' />
            <h2>Drop a file for upload</h2> */}
      </div>

      <div
        className={`fullDragOverlay ${fullWindowDragActive ? "active" : ""}`}
        onClick={() => setFullWindowDragActive(false)}
      >
        <img src={uploadIcon} alt="Upload" />
        <h2>Drop a file for upload</h2>
      </div>
    </>
  );
}
