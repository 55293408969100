import { Vector3 } from 'three';
import { BaseWidgetProperty } from './BaseWidgetProperty';
import { BaseWidget } from './baseWidget';
export class AnimationTrack {
  public time = 15;
  public animationData: any = {};
  public widget;

  constructor(widget: BaseWidget, time?: number, animationData?: any) {
    this.widget = widget;
    this.time = time ?? this.time;
    this.animationData = animationData ?? this.animationData;
  }

  public addAnimationPoint(time: number, property: BaseWidgetProperty, value: any) {
    if (!this.getAnimationData(property)) {
      this.animationData[`${this.widget.id}-${property.name}`] = {
        propertyName: property.name,
        data: {},
      };
    }
    this.getAnimationData(property).data[`${time}`] = {
      value,
    };
  }

  public removeAnimationPoint(time: number, property: BaseWidgetProperty) {
    if (this.getAnimationData(property)) {
      delete this.getAnimationData(property).data[`${time}`];
    }
  }

  public getAnimationValue(time: number, property: BaseWidgetProperty) {
    let animationInfo = this.getAnimationData(property);
    if (animationInfo && animationInfo.data[`${time}`]) {
      return animationInfo.data[`${time}`].value;
    }
    return this.calculateAnimationValue(time, property);
  }

  public calculateAnimationValue(time: number, property: BaseWidgetProperty) {
    if (!this.getAnimationData(property)) {
      return property.get ? property.get() : null;
    }
    let animationInfo = Object.entries(this.getAnimationData(property).data).sort((a, b) =>
      +a[0] > +b[0] ? 1 : +a[0] < +b[0] ? -1 : 0,
    );
    if (!animationInfo.length) {
      return property.get ? property.get() : null;
    }
    let stIndex = 0;
    if (time > +animationInfo[animationInfo.length - 1][0]) {
      return (animationInfo[animationInfo.length - 1][1] as { value: any }).value;
    }
    for (let i = 0; i < animationInfo.length; i++) {
      if (+animationInfo[i][0] > time) {
        stIndex = i - 1;
        break;
      }
    }
    if (stIndex < 0) {
      return property.get ? property.get() : null;
    }
    let animationSegmentDuration = +animationInfo[stIndex + 1][0] - +animationInfo[stIndex][0];
    let animationSegmentTime = time - +animationInfo[stIndex][0];
    let animationSegmentCompletion = animationSegmentTime / animationSegmentDuration;
    let valueChanges;
    let endValue;
    switch (property.widgetType) {
      case 'vec3':
        valueChanges = {
          x:
            +(animationInfo[stIndex + 1][1] as { value: Vector3 }).value.x -
            +(animationInfo[stIndex][1] as { value: Vector3 }).value.x,
          y:
            +(animationInfo[stIndex + 1][1] as { value: Vector3 }).value.y -
            +(animationInfo[stIndex][1] as { value: Vector3 }).value.y,
          z:
            +(animationInfo[stIndex + 1][1] as { value: Vector3 }).value.z -
            +(animationInfo[stIndex][1] as { value: Vector3 }).value.z,
        };
        endValue = {
          x:
            +(animationInfo[stIndex][1] as { value: Vector3 }).value.x +
            animationSegmentCompletion * valueChanges.x,
          y:
            +(animationInfo[stIndex][1] as { value: Vector3 }).value.y +
            animationSegmentCompletion * valueChanges.y,
          z:
            +(animationInfo[stIndex][1] as { value: Vector3 }).value.z +
            animationSegmentCompletion * valueChanges.z,
        };
        break;
      default:
        endValue = property.get ? property.get() : null;
    }
    return endValue;
  }

  public getNearestAnimationPoint(time: number, property: BaseWidgetProperty) {
    if (!this.getAnimationData(property)) {
      return {};
    }
    let res: any = {};
    if (this.getAnimationPoint(time, property)) {
      res['center'] = time;
    }
    let animationInfo = Object.entries(this.getAnimationData(property).data).sort((a, b) =>
      +a[0] > +b[0] ? 1 : +a[0] < +b[0] ? -1 : 0,
    );
    if (!animationInfo.length) {
      return {};
    }
    let stIndex = 0;
    if (time > +animationInfo[animationInfo.length - 1][0]) {
      res['left'] = +animationInfo[animationInfo.length - 1][0];
      return res;
    } else if (time === +animationInfo[animationInfo.length - 1][0]) {
      if (animationInfo.length > 1) {
        res['left'] = +animationInfo[animationInfo.length - 2][0];
      }
      return res;
    }
    for (let i = 0; i < animationInfo.length; i++) {
      if (+animationInfo[i][0] > time) {
        stIndex = i - 1;
        break;
      }
    }
    if (stIndex < 0) {
      res['right'] = +animationInfo[0][0];
      return res;
    }
    if (res.center && +animationInfo[stIndex][0] === res.center) {
      if (stIndex > 0) {
        res['left'] = +animationInfo[stIndex - 1][0];
      }
    } else {
      res['left'] = +animationInfo[stIndex][0];
    }
    if (res.center && +animationInfo[stIndex + 1][0] === res.center) {
      if (stIndex + 2 < animationInfo.length) {
        res['right'] = +animationInfo[stIndex + 1][0];
      }
    } else {
      res['right'] = +animationInfo[stIndex + 1][0];
    }
    return res;
  }

  public getAnimationPoint(time: number, property: BaseWidgetProperty) {
    let animationInfo = this.getAnimationData(property);
    if (animationInfo && animationInfo.data[`${time}`]) {
      return animationInfo.data[`${time}`].value;
    }
    return null;
  }

  public getAnimationData(property: BaseWidgetProperty) {
    return this.animationData[`${this.widget.id}-${property.name}`];
  }
}
