import { ReactElement } from 'react';
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const provizGif = getAbsoluteUrl("gifs/ProViz_Logo-blue.gif");
interface Props {
    close: () => void;
    text: string;
}

export default function LoadingModal(props: Props): ReactElement {
    return <>
        <div className='modal'>
            <div className={"modalBody loading-modal"}>
                <img src={provizGif} alt='Loading' />
                <br />
                <div className='loadingText'>
                    {props.text}
                </div>
            </div>
        </div>
        <div className='modalOverlay' onClick={props.close} />
    </>
}
