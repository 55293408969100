import { Group, Object3D } from 'three';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter';
import { LoadingScreen, ProVizLoader } from '.';
import { APIModel, APIModelVariant } from '@proviz/api-services';

const noArViewerSigil = '#model-viewer-no-ar-fallback';
// let isSceneViewerBlocked = false;

/**
 * Takes a URL and a title string, and attempts to launch Scene Viewer on
 * the current device.
 */
export const openSceneViewer = async (
  modelVariant: APIModelVariant,
  allowScalingInAR: boolean = false,
  arAnchor: HTMLElement,
) => {
  const location = self.location.toString();
  const locationUrl = new URL(location);
  const glbFile = modelVariant.modelVariantFile.glbFile.location;
  const modelUrl = new URL(glbFile, location);
  const params = new URLSearchParams(modelUrl.search);

  const fallbackUrl = locationUrl.toString().replace(/open-ar=true/, '');
  console.log('fallback', fallbackUrl);
  locationUrl.hash = noArViewerSigil;

  // modelUrl can contain title/link/sound etc.
  params.set('mode', 'ar_preferred');
  // if (!params.has('disable_occlusion')) {
  //   params.set('disable_occlusion', 'true');
  // }
  params.set('resizable', allowScalingInAR.toString());
  // if (this.arPlacement === 'wall') {
  //   params.set('enable_vertical_placement', 'true');
  // }
  // if (params.has('sound')) {
  //   const soundUrl = new URL(params.get('sound')!, location);
  //   params.set('sound', soundUrl.toString());
  // }
  // if (params.has('link')) {
  //   const linkUrl = new URL(params.get('link')!, location);
  //   params.set('link', linkUrl.toString());
  // }

  const intent = `intent://arvr.google.com/scene-viewer/1.0?${params.toString()}&file=${encodeURIComponent(
    modelUrl.toString(),
  )}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${fallbackUrl};end;`;

  console.log('Attempting to open', intent);

  const undoHashChange = () => {
    if (self.location.hash === noArViewerSigil) {
      // isSceneViewerBlocked = true;
      // The new history will be the current URL with a new hash.
      // Go back one step so that we reset to the expected URL.
      // NOTE(cdata): this should not invoke any browser-level navigation
      // because hash-only changes modify the URL in-place without
      // navigating:
      // The exception to going back in history is if the the page
      // is automatically directing to scene viewer in the case
      // that open ar automatically is true. In this case history
      // back will take you to the new tab page
      console.log('loc', window.location.toString(), location);
      if (window.location.toString() !== location) {
        self.history.back();
      }
      console.warn('Error while trying to present in AR with Scene Viewer');
      console.warn('Falling back to next ar-mode');
      alert("We can't seem to open this model in AR. This should work better in Chrome.");
    }
  };

  self.addEventListener('hashchange', undoHashChange, { once: true });

  arAnchor.setAttribute('href', intent);
  console.log('Attempting to present in AR with Scene Viewer...');
  arAnchor.click();
};

/**
 * Takes a URL to a USDZ file and sets the appropriate fields so that
 * Safari iOS can intent to their AR Quick Look.
 */
export const openQuickLook = async (
  model: APIModel,
  modelVariant: APIModelVariant,
  allowScalingInAR: boolean,
  arAnchor: HTMLElement,
  group?: Group | Object3D,
) => {
  LoadingScreen.ShowLoading('Opening AR...');

  let objectURL =
    modelVariant?.modelVariantFile?.usdzFile?.location ??
    (await generateUsdz(model, modelVariant, group));
  const modelUrl = new URL(objectURL, self.location.toString());
  if (!allowScalingInAR) {
    if (modelUrl.hash) {
      modelUrl.hash += '&';
    }
    modelUrl.hash += 'allowsContentScaling=0';
  }
  console.log(modelUrl);

  arAnchor.setAttribute('rel', 'ar');
  const img = document.createElement('img');
  arAnchor.appendChild(img);
  arAnchor.setAttribute('href', modelUrl.toString());

  console.log('Attempting to present in AR with Quick Look...');
  arAnchor.click();
  arAnchor.removeChild(img);

  LoadingScreen.HideLoading();
};

async function generateUsdz(model: APIModel, variant: APIModelVariant, group?: Group | Object3D) {
  if (!group) {
    const loader = new ProVizLoader();
    let { glb, gltf } = ProVizLoader.GetFiles(model, variant);
    if (!glb || gltf) {
      console.error('No file to generate usdz from');
      return;
    }
    const loadedGLTF = await loader.LoadGLTF(glb ?? gltf);
    group = loadedGLTF;
  }
  const exporter = new USDZExporter();
  const arraybuffer = await exporter.parse(group);
  const blob = new Blob([arraybuffer], {
    type: 'model/vnd.usdz+zip',
  });

  const url = URL.createObjectURL(blob);

  return url;
}
