import React, { ReactElement } from "react";
import ReactMarkdown from "react-markdown";
import { ManagerStore } from "../../../store";

interface Props {
    data: {
        label: string,
        note: {
            data: string,
            alwaysShow:boolean,
            toggleShow: Function,
        },
        node: {
            id: string,
            widgetType: string,
        }
    }
}

export default function NoteNode(props: Props): ReactElement {
    const { node, note } = props.data;
    const manager = ManagerStore( s => s.SceneManager );
    const isSelected = manager.selectedNodes.findIndex((wdg) => wdg.id === node.id) > -1;
    
    return <React.Fragment key={node.id}>
        <div className={`flow-note ${isSelected?'selected':''}`}
        onClick={() => {
            manager.setSelectionById(node.id)
        }}>
            <ReactMarkdown children={note.data} />
        </div>
    </React.Fragment>
}