import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class FaceTargetWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'face-target';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = FaceTargetWidget.type;
    this.widgetName = 'Face Target';
    this.label = 'Face Target';
    this.category = 'Targets';
    this.selectable = true;
    this.events = [
      {
        label: 'Tracking Found',
        name: 'tracking-found',
      },
      {
        label: 'Tracking Lost',
        name: 'tracking-lost',
      },
    ];
  }
}

ModuleService.Register(FaceTargetWidget.type, FaceTargetWidget);
