import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';

export class VideoPlaylist2DWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'video-playlist-2d';

  // Data

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = VideoPlaylist2DWidget.type;
    this.widgetName = 'Video Playlist 2D';
    this.label = 'Video Playlist 2D';
    this.category = 'UI';
    this.events = [
      {
        label: 'Closed',
        name: 'closed',
      },
    ];

    this.addService(
      'Set Playlist',
      'set-playlist',
      'Set the list of videos available to play',
      () => {
        console.warn('Video playlist not setup for the web yet.');
      },
    );
  }

  public getProperties(): BaseWidgetProperty[] {
    return super.getProperties();
  }
}

ModuleService.Register(VideoPlaylist2DWidget.type, VideoPlaylist2DWidget);
