import { Raycaster, Vector2, Object3D, Camera } from 'three';
import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export default class RaycastWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'raycast';

  raycaster: Raycaster = new Raycaster();

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = RaycastWidget.type;
    this.widgetName = 'Raycast';
    this.label = 'Raycast';
    this.usage = 'Flow';
    this.category = 'Others';
    this.events.push(
      {
        label: 'Widget List',
        name: 'widget-list',
      },
      {
        label: 'Widget',
        name: 'widget',
      },
    );
    this.addService(
      'Raycast All',
      'raycast-all',
      '<b>Emits widget list</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'vector2') {
          let wdgArray = this.intersectObjects(
            new Vector2(
              Math.min(Math.max((event.data as Vector2).x / window.innerWidth, -1), 1),
              Math.min(Math.max((event.data as Vector2).y / window.innerHeight, -1), 1),
            ),
            this.scene.getClickables(),
            this.scene.camera,
          );
          this.triggerProVizEvent(
            'widget-list',
            'list',
            wdgArray.map((wdg) => wdg?.id),
          );
        }
      },
    );
    this.addService(
      'Raycast',
      'raycast',
      '<b>emits the closest widget to the raycast</b>',
      (event: ProVizEventData) => {
        if (event.dataType === 'vector2') {
          console.log(event.data);
          let wdgArray = this.intersectObjects(
            new Vector2(
              Math.min(Math.max((event.data as Vector2).x / window.innerWidth, -1), 1),
              Math.min(Math.max((event.data as Vector2).y / window.innerHeight, -1), 1),
            ),
            this.scene.getClickables(),
            this.scene.camera,
          );
          if (wdgArray.length > 0) {
            this.triggerProVizEvent('widget', 'widgetId', wdgArray[0]?.id);
          }
        }
      },
    );
  }

  intersectObjects(point: Vector2, objects: Object3D[], camera: Camera) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    // we flip y
    let normalizedPos = new Vector2();
    normalizedPos.set(point.x * 2 - 1, point.y * -2 + 1);
    this.raycaster.setFromCamera(normalizedPos, camera);
    // recursive is true by default for >= r133
    const intersects = this.raycaster.intersectObjects(objects, true);
    return intersects.map((intersect) => {
      const wdg = this.scene.getWidget(intersect.object);
      if (wdg) {
        if (intersect.uv) {
          return wdg;
        }
      }
    });
  }
}
ModuleService.Register(RaycastWidget.type, RaycastWidget);
