import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { Object3D } from 'three';
import { ModelWidget } from './modelWidget';
import { setVisibleRec } from '../../../utils';

// This should not be created by itself but is only created by a model widget
export class MeshWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'mesh';

  // Data

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = MeshWidget.type;
    this.widgetName = 'Mesh';
    this.label = 'Mesh';
    this.category = 'Unlisted';
    this.selectable = true;
    this.events = [
      {
        name: 'clicked',
        label: 'Clicked',
      },
    ];
    this.canReposition = false;
  }

  public setVisible(state: boolean) {
    super.setVisible(state);
    // We recursively set all children visible in case
    // the parent model widget that created these meshes ran hideall
    // and set the visibility of all children to false
    setVisibleRec(this.renderNode, state);
    // Iterate up the scene tree until the parent model widget is
    // found and set visible - this means that to set a piece of a model
    // visible it will set everything that parents it visible as well.
    let wdg = this.parent;
    do {
      if (!wdg) {
        console.error('This scene tree is corrupted, no model parent of a mesh wdg.');
        return;
      }
      // We don't call setvisible because we don't want set
      // all of their children visible
      wdg.visible = state;
      wdg.renderNode.visible = true;
      wdg = wdg.parent;
    } while (wdg && wdg.widgetType !== ModelWidget.type);
  }

  public setReference(o: Object3D) {
    const oldRenderNode = this.renderNode; // may need to do more to get this ready for gc
    this.renderNode = o;
    this.renderNode.userData.widget = this;
    for (let w of this.children) {
      // We add references to the rendernode of all children in the scene
      // hierarchy EXCEPT other meshwidgets because their rendernode will
      // be replaced by a reference to a mesh in the model as well. They will then be
      // responsible for patching their childrens' references
      if (w.widgetType !== MeshWidget.type) {
        oldRenderNode.remove(w.renderNode);
        this.renderNode.add(w.renderNode);
      }
    }
    oldRenderNode.removeFromParent();
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.renderNode.renderOrder = 3;

    return true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.renderNode) {
      result.push(this.renderNode);
    }
    return result;
  }
}

ModuleService.Register(MeshWidget.type, MeshWidget);
