export class Timeout {
  private cancelled: boolean = false;
  private executed: boolean = false;
  private cancelFn: () => void;
  // the fn passed in is ran only if not cancelled,
  // the cancel function is ran as long as cancel has been called at some point
  constructor(time: number, fn: () => void, cancelFn: () => void) {
    setTimeout(() => {
      if (this.cancelled) {
        cancelFn();
      } else {
        fn();
      }
      this.executed = true;
    }, time);
    this.cancelFn = cancelFn;
  }

  cancel() {
    this.cancelled = true;
    if (this.executed) {
      this.cancelFn();
    }
  }
}

export const timeout = (time: number, fn: () => void, cancelFn: () => void) => {
  return new Timeout(time, fn, cancelFn);
};
