import { ModelWidget } from "@proviz/proviz-sdk"
import { ReactElement, useEffect, useState } from 'react'
import { ManagerStore } from "../../../store";
import CollapsibleHeaderIcon from "../../common/collapsiblegroup/CollapsibleHeader";
import PropertyRenderer from '../../sceneeditor/propertypanel/PropertyRenderer';


// const PropertiesToRender = [ 'playAnimationOnLoad', 'selectedAnimation' ]

interface Props {
    modelWidget: ModelWidget;
}

/**
 * Not all models have animations - further we don't serialize the 
 * animations on a model since when model widgets are used in scenes
 * the model that they represent may be changed and we don't yet
 * want to worry about the complications of keeping an animation list on the widget
 * in sync with the animations defined on the model itself. So, we need
 * to wait for the gltf to be loaded to know whether it has animations.
 * That's the motivation for this loaded logic since we don't want to render
 * anything if there are no animations.
 */
export default function ModelAnimationControls(props: Props): ReactElement {
    const { modelWidget } = props;
    const [expand, setExpand] = useState(false);
    const [hasAnimations, setHasAnimations] = useState(false);
    const manager = ManagerStore(s=>s.ModelViewManager);

    useEffect(() => {
        function update() {
            if(modelWidget) {
                setHasAnimations(modelWidget.animations && modelWidget.animations.length > 0);
                modelWidget.removeEventListener('loaded', update);
            }
        }
        if(modelWidget.loadingState !== 'loaded') {
            modelWidget.addEventListener("model-loaded", update);
        }
        return () => {
            modelWidget.removeEventListener('loaded', update);
        }
    }, [modelWidget])

    if(!hasAnimations) {
        return <></>
    }
     
    return <div>
        <div className="collapsible-properties-header">
            <h5>Animation</h5>
            <CollapsibleHeaderIcon handleClick={() => setExpand(!expand)} expanded={expand} />
        </div>
        <ul>
            {expand && <>
                {modelWidget && <div >
                    {/* <CategoryRenderer category={'Core'}
                        properties={modelWidget.properties.filter(x => PropertiesToRender.includes(x.name))}
                        manager={manager}
                    /> */}
                    <PropertyRenderer node={modelWidget}
                            properties={modelWidget.properties.filter(x => x.category === 'Animation')}
                            manager={manager}
                            supressedProperties={[]} />
                </div>}
            </>}
        </ul>
    </div>;
}
