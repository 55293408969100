import ModelViewManager from "../graphics/ModelViewManager";
import Command from "./Command";
import { APIMaterialSet } from "@proviz/api-services";

export default class SetMaterialSet extends Command {
  oldVal: APIMaterialSet | undefined;
  newVal: APIMaterialSet | undefined;
  manager: ModelViewManager;
  name = "Set Material Set";

  constructor(
    manager: ModelViewManager,
    materialSet: APIMaterialSet | undefined
  ) {
    super();
    this.newVal = materialSet;
    this.oldVal = manager.materialSet;
    this.manager = manager;
  }

  execute() {
    this.manager.setMaterialSet(this.newVal);
  }

  undo() {
    this.manager.setMaterialSet(this.oldVal);
  }
}
