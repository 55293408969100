import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class KeyboardWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'keyboard';

  //Data
  public keyboardCaptureEnabled: boolean = true;

  private bindedKeyUpHandler = this.handleKeyUp.bind(this);
  private bindedKeyDownHandler = this.handleKeyDown.bind(this);

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = KeyboardWidget.type;
    this.widgetName = 'Keyboard';
    this.label = 'Keyboard';
    this.usage = 'Flow';
    this.category = 'Events';
    this.events.push(
      {
        name: 'key-down',
        label: 'KeyDown',
      },
      {
        name: 'key-up',
        label: 'KeyUp',
      },
    );
    this.addService(
      'enable Keyboard',
      'enable-keyboard',
      '<b>Enable keyboard key capture</b>',
      () => {
        this.addKeyboardListeners();
      },
    );
    this.addService(
      'Disable Keyboard',
      'disable-keyboard',
      '<b>Disables keyboard key capture</b>',
      () => {
        this.removeKeyboardListeners();
      },
    );
  }

  public async init() {
    super.init();
    if (this.keyboardCaptureEnabled) {
      this.addKeyboardListeners();
    }
    return true;
  }

  private addKeyboardListeners() {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }
    document.addEventListener('keyup', this.bindedKeyUpHandler);
    document.addEventListener('keydown', this.bindedKeyDownHandler);
  }

  private removeKeyboardListeners() {
    document.removeEventListener('keyup', this.bindedKeyUpHandler);
    document.removeEventListener('keydown', this.bindedKeyDownHandler);
  }

  private handleKeyUp(e: KeyboardEvent) {
    this.triggerProVizEvent('key-up', 'string', e.key);
  }

  private handleKeyDown(e: KeyboardEvent) {
    this.triggerProVizEvent('key-down', 'string', e.key);
  }

  public getProperties() {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('keyboardCaptureEnabled', 'Keyboard Active', 'Core', 'bool', 'boolean', true),
    ];
  }

  public serialize() {
    const result = super.serialize();
    result.keyboardCaptureEnabled = this.keyboardCaptureEnabled;
    return result;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.keyboardCaptureEnabled = data.keyboardCaptureEnabled;
  }

  dispose() {
    super.dispose();
    this.removeKeyboardListeners();
  }
}
ModuleService.Register(KeyboardWidget.type, KeyboardWidget);
