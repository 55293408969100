import { ReactElement } from 'react'

interface Props {
    value: string;
    fieldName: string;
    handleChange: (e: any) => void;
}


export default function WrapInput(props: Props): ReactElement {
    const { value, fieldName, handleChange } = props;
    return <>
        <p>{fieldName}</p>
        <select value={value || ''} onChange={handleChange} >
            <option value='RepeatWrapping'>RepeatWrapping</option>
            <option value='ClampToEdgeWrapping'>ClampToEdgeWrapping</option>
            <option value='MirroredRepeatWrapping'>MirroredRepeatWrapping</option>
        </select>
    </>;
}
