import { ReactElement, useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AccessKeysView from "./AccessKeysView";
import UsersView from "./UsersView";
import AccountPage from "./AccountPage";
import { OrganizationsView } from "./OrganizationsView";
import UserKeysView from "./UserKeysView";
import MachineKeysView from "./MachineKeyView";
import BuildInfo from "./BuildInfo";
import TagsView from "./TagsView";
import PrimaryNavigation from "../home/PrimaryNavigation";
import AvatarView from "./AvatarView";
import { userStore } from "../../store";
import { PermissionEntity, PermissionType } from "@proviz/api-services";
import RolesView from "./RolesView";
import RoleEditView from "./RoleEditView";
import EditUserAccount from "./EditUserAccount";
import LicenseEditView from "./LicenseEditView";
import LicensesView from "./LicensesView";

export default function Settings(): ReactElement {
  const { path } = useRouteMatch();
  const hasPermission = userStore((s) => s.hasPermission);

  const secureRoutes = useMemo(() => {
    return [
      {
        path: `${path}/machinekeys`,
        fallback: `${path}/account`,
        component: MachineKeysView,
        permission: {
          entity: PermissionEntity.MachineKey,
          type: PermissionType.View,
        },
      },
      {
        path: `${path}/userkeys`,
        fallback: `${path}/account`,
        component: UserKeysView,
        permission: {
          entity: PermissionEntity.MachineKey,
          type: PermissionType.View,
        },
      },
      {
        path: `${path}/roles/:id`,
        fallback: `${path}/account`,
        component: RoleEditView,
        permission: {
          entity: PermissionEntity.Role,
          type: PermissionType.Edit,
        },
      },
      {
        path: `${path}/roles`,
        fallback: `${path}/account`,
        component: RolesView,
        permission: {
          entity: PermissionEntity.Role,
          type: PermissionType.View,
        },
      },
    ];
  }, [path]);

  return (
    <>
      <PrimaryNavigation />
      <div className="homeRightView">
        <Switch>
          <Route exact path={`${path}/avatar`} component={AvatarView} />
          <Route exact path={`${path}/editUser`} component={EditUserAccount} />
          <Route exact path={`${path}/users`} component={UsersView} />
          <Route exact path={`${path}/account`} component={AccountPage} />
          <Route exact path={`${path}/accesskeys`} component={AccessKeysView} />
          <Route exact path={`${path}/licenses/:id`} component={LicenseEditView} />
          <Route exact path={`${path}/licenses`} component={LicensesView} />
          <Route
            exact
            path={`${path}/organizations`}
            component={OrganizationsView}
          />
          <Route exact path={`${path}/tags`} component={TagsView} />
          {secureRoutes
            .filter((r) =>
              hasPermission(r.permission.entity, r.permission.type)
            )
            .map((r) => (
              <Route path={r.path} component={r.component} />
            ))}
          <Route component={UsersView} />
        </Switch>

        <BuildInfo />
      </div>
    </>
  );
}
