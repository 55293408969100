import { ReactElement } from "react";

interface Props {
    title: string;
    children?: any;
}

export default function FormContainer(props: Props): ReactElement {
    return <div className="provizFormContainer">
        <div className="title">
            {props.title}
        </div>
        <div className="content">
            {props.children}
        </div>
    </div>;
}