import { BaseDataService } from "../BaseDataService";

class APIAliasService extends BaseDataService<any> {
  constructor() {
    super("scene");
  }
  
  public async getAlias(
    sceneId: string,
  ): Promise<string[]> {
    return await this.request(`alias/${sceneId}`, {
      method: "GET",
    });
  }

  public async getAliasData(
    alias: string
  ): Promise<string> {
    return await this.request(`alias/${alias}/data`, {
      method: "GET",
    });
  }

  public async getAliasGzip(
    alias: string,
  ): Promise<string> {
    return await this.request(`alias/${alias}/gzip`, {
      method: "GET",
    });
  }

  public async setAlias(
    sceneId: string,
    alias: string,
  ): Promise<string> {
    return await this.request(`${sceneId}/alias/${alias}`, {
      method: "POST",
    });
  }
}

const AliasService = new APIAliasService();

export default AliasService;
