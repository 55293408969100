import { BaseWidgetProperty } from "@proviz/proviz-sdk";

export interface SettableWidgetProperty extends BaseWidgetProperty {
    get: () => any; 
    set: (data: any, updateNodes?: (() => void)) => void,
}

export function isSettable(property: any) : property is SettableWidgetProperty {
    return property.get && property.set;
}
