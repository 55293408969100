import { DatabaseService, DataSchemaService } from '@proviz/api-services';
import { APIDatabase } from '@proviz/api-services/lib/models/Database';
import { APIDataSchema } from '@proviz/api-services/lib/models/DataSchema';
import { ModuleService } from '../../../../moduleService';
import { ProVizEventDataTypes } from '../../../../ProVizEventData';
import { ProVizScene, SceneMode } from '../../../../ProVizScene';
import { BaseWidget } from '../../../baseWidget';
import { IBaseWidgetEvent } from '../../../BaseWidgetEvent';
import { BaseWidgetProperty } from '../../../BaseWidgetProperty';
import { IBaseWidgetService } from '../../../BaseWidgetService';
import { IBaseWidgetType } from '../../../IBaseWidgetType';
import { WidgetPropertyType } from '../../../WidgetPropertyTypes';
import { DatabaseWidget } from '../database/databaseWidget';
import { DatabaseCollectionWidget } from '../databaseCollection';

interface SchemaDataType { label: string, name: string, dataType: WidgetPropertyType, eventDataType: ProVizEventDataTypes }

export class DatabaseRecordWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'database-record';

  // Data
  public recordId: string | undefined = undefined;
  public schemaId: string | undefined = undefined;
  public schema: string | undefined = undefined;

  private databaseId: string | undefined = undefined;
  private collection: string | undefined = undefined;

  private schemaOptions: APIDataSchema[] = [];
  private databaseOptions: APIDatabase[] = [];

  private data: any = { };

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Database Record';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = DatabaseRecordWidget.type;
    this.widgetName = 'Database Record';
    this.selectable = true;
    this.events = [ { name: 'saved', label: 'Saved', desc: 'When the record is saved.'} ];

    this.services = [ ];
    this.addService('Database', 'databaseId', 'Set the database', (e) => {
      if (e.dataType === 'widgetId') {
        const wdg = this.scene.getById(e.data as string);
        if (wdg?.widgetType === 'database') {
          const dbWdg = wdg as DatabaseWidget;
          this.databaseId = dbWdg.databaseId;
        }
      }
    });

    this.addService('Collection', 'collection', 'Set the collection', (e) => {
      if (e.dataType === 'widgetId') {
        const wdg = this.scene.getById(e.data as string);
        if (wdg?.widgetType === 'database-collection') {
          const collWdg = wdg as DatabaseCollectionWidget;
          this.databaseId = collWdg.databaseId;
          this.collection = collWdg.collection;
        }
      }
    });

    this.addService('Load', 'load', 'Load the record', () => {
      console.log('load');
      if (this.recordId && this.databaseId && (this.collection || this.schemaId)) {
        if (this.schemaId) {
          DatabaseService.getSchemaRecord(this.databaseId, this.schemaId, this.recordId).then((record) => {
            console.log(record);
            this.data = record;
            this.emitValues();          
          });
        } else if (this.collection) {
          DatabaseService.getCollectionRecord(this.databaseId, this.collection, this.recordId).then((record) => {
            console.log(record);
            this.data = record;
            this.emitValues();          
          });
        }
      }
    });
    
    this.addService('Save', 'save', 'Saves the record', () => {
      if (this.recordId && this.databaseId && (this.collection || this.schemaId)) {
        if (this.schemaId) {
          DatabaseService.updateSchemaRecord(this.databaseId, this.schemaId, this.recordId, this.data).then((record) => {
            console.log(record);
            this.triggerProVizEvent('saved', 'none');
          });
        } else if (this.collection) {
          DatabaseService.updateCollectionRecord(this.databaseId, this.collection, this.recordId, this.data).then((record) => {
            console.log(record);
            this.triggerProVizEvent('saved', 'none');
          });
        }
      } else if (this.databaseId && (this.collection || this.schemaId)) {
        if (this.schemaId) {
          DatabaseService.createSchemaRecord(this.databaseId, this.schemaId, this.data).then((record) => {
            console.log(record);
            this.recordId = record._id;
            this.data = record;
          });
        } else if (this.collection) {
          DatabaseService.createCollectionRecord(this.databaseId, this.collection, this.data).then((record) => {
            console.log(record);
            this.recordId = record._id;
            this.data = record;
          });
        }
      }
    });
  }

  getSchemaTypes(o: any, parent?: string): SchemaDataType[] {
    const result: SchemaDataType[] = [];
    const keys = Object.keys(o);
    keys.forEach((k) => {
      const p = o[k];
      if (p && p.type) {
        const label = (parent ?? '') + k;
        const name = label.split(' ').join('-');

        switch(p.type) {
          case 'object': {
            result.push(...this.getSchemaTypes(p.properties, (parent ?? '') + k + '.'));
            break;
          }
          case 'number':
          case 'integer':
            result.push({
              label,
              name,
              dataType: 'number',
              eventDataType: 'number'
            });
            break;
          case 'string':
            result.push({
              label,
              name,
              dataType: 'string',
              eventDataType: 'string'
            });
            break;
        }
      }
    });
    return result;
  }

  getSchemaDataValue(key: string) {
    console.log(key);

    if (this.data) {
      const parts = key.split('.');
      let o = this.data;
      for(let i = 0; i < parts.length; i++) {
        o = o[parts[i]];
        if (!o) {
          return undefined;
        }
      }
      return o;
    }
    return undefined;
  }

  setSchemaDataValue(key: string, value: any) {
    console.log('set data', key, value, this.data);
    if (this.data) {
      const parts = key.split('.');
      let o = this.data;
      for(let i = 0; i < parts.length - 1; i++) {
        if (!o[parts[i]]) {
          o[parts[i]] = {};
        }
        o = o[parts[i]];
      }
      return o[parts[parts.length - 1]] = value;
    }
    return undefined;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {}).map(x => {
      return this.createProperty(x.name, x.label, 'Data', x.dataType, x.eventDataType, true, () => {
        return this.getSchemaDataValue(x.name);
      }, (data: any) => {
        this.setSchemaDataValue(x.name, data);
      })
    });

    return [
      ...result,
      ...schema,
      this.createProperty('recordId', 'Record ID', 'Core', 'string', 'string', true),
      this.createProperty('schemaId', 'Schema', 'Core', 'select', 'string', true, undefined, (data: any) => {
        this.schemaId = data;
        if (this.schemaId) {
          DataSchemaService.get(this.schemaId).then((schema) => {
            this.schema = schema.schema;
          });
        } else {
          this.schema = '';
        }
      }, () => {
        return [
          { key: '', label: '[None]' },
          ...this.schemaOptions.map(x => {
            return {
              key: x.id,
              label: x.name || ''
            }
          })
        ];
      }),
      this.createProperty('schema', 'Schema', 'Core', 'multi-line-string', 'string', true),
      this.createProperty('databaseId', 'Database', 'Core', 'select', 'string', true, undefined, undefined, () => {
        const options = this.databaseOptions.map(x => {
          return {
              key: x.id,
              label: x.name || ''
          };
        });
        return [
            { key: '', label: '[None]'},
            ...options
        ]
      }),
    ];
  }

  private emitValues() {
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {});
    schema.forEach(val => this.triggerProVizEvent(val.name, val.eventDataType, this.getSchemaDataValue(val.name)));
  }

  public getServices(): IBaseWidgetService[] {
    const result = super.getServices();
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {}).map(x => {
      return {
        name: x.name,
        label: x.label,
        desc: x.name
      };
    });
    return [
      ...result,
      ...schema
    ]
  }

  public getEvents(): IBaseWidgetEvent[] {
    const result = super.getEvents();
    const json = JSON.parse(this.schema || '{}');
    const schema = this.getSchemaTypes(json.properties || {}).map(x => {
      return {
        name: x.name,
        label: x.label,
        data: x.dataType,
        desc: x.name,
      }
    });
    return [
      ...result,
      ...schema
    ]
  }

  public serialize(): any {
    const result = super.serialize();
    result.recordId = this.recordId;
    result.databaseId = this.databaseId;
    result.schemaId = this.schemaId;
    result.schema = this.schema;
    result.data = this.data;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.recordId = data.recordId ?? this.recordId;
    this.databaseId = data.databaseId ?? this.databaseId;
    this.schemaId = data.schemaId ?? this.schemaId;
    this.schema = data.schema ?? this.schema;
    this.data = data.data ?? this.data;
  }

  public override async init(): Promise<boolean> {
    if (this.schemaId) {
      const schema = await DataSchemaService.get(this.schemaId);
      this.schema = schema.schema;
    }

    if (this.scene.sceneMode !== SceneMode.Editor) {
      this.emitValues();
      const json = JSON.parse(this.schema || '{}');
      const schema = this.getSchemaTypes(json.properties || {});
      schema.forEach(val => this.addEventListener(`service-${val.name}`, (data) => {
        if (data.dataType === val.eventDataType) {
          this.setSchemaDataValue(val.name, data.data);
          this.triggerProVizEvent(val.name, val.eventDataType, data.data);
        }
      }))
    } else {
      DatabaseService.getAll().then((data) => {
          this.databaseOptions = data;
          this.databaseId = this.databaseId ?? data[0].id;
      });
      DataSchemaService.getAll().then((data) => {
          this.schemaOptions = data;
          this.schemaId = this.schemaId ?? data[0].id;
      });
    }

    return super.init();
  }
}

ModuleService.Register(DatabaseRecordWidget.type, DatabaseRecordWidget);
