import { Box3, Object3D, Vector3 } from 'three';
import { ProVizScene, setRenderOrder } from '../../..';
import { APIModel, APIModelVariant, ModelService, ModelVariantService } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import ProVizLoader from '../../../utils/ProVizLoader';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { disposeModel } from '../../../utils';

interface ExplodeChild {
  node: Object3D;
  position: Vector3;
  dir: Vector3;
}

export class ExplodeWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'explode';
  public apiModel?: APIModel;
  public apiModelVariant?: APIModelVariant;

  // Data
  public modelId: string = '';
  public modelVariantId?: string;
  public explodeAmount: number = 5;
  public explodeSpeed: number = 1;
  public explodeOnClick: boolean = true;
  public doNotExplode: string[] = [];

  private explodeChildren: ExplodeChild[] = [];
  private explodeCurrent: number = 0;
  private explodeDir = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ExplodeWidget.type;
    this.widgetName = 'Explode';
    this.label = 'Explode';
    this.selectable = true;
    this.category = 'Core';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
      {
        label: 'Explode Finished',
        name: 'explode-finished',
      },
      {
        label: 'Explode Started',
        name: 'explode-started',
      },
    ];

    this.services.push({
      label: 'Explode',
      name: 'explode',
    });
    this.services.push({
      label: 'UnExplode',
      name: 'unexplode',
    });

    this.addEventListener('service-explode', (event) => {
      this.explodeDir = true;
    });
    this.addEventListener('service-unexplode', (event) => {
      this.explodeDir = false;
    });

    this.addEventListener('clicked', () => {
      if (this.explodeOnClick) {
        this.explodeDir = !this.explodeDir;
      }
    });
  }

  public update(delta: number) {
    super.update(delta);
    if (!this.isInitialized || !this.visible) {
      return;
    }
    this.explodeCurrent += (this.explodeDir ? 0.01 : -0.01) * this.explodeSpeed;
    if (this.explodeDir && this.explodeCurrent > 1) {
      this.explodeCurrent = 1;
    } else if (!this.explodeDir && this.explodeCurrent < 0) {
      this.explodeCurrent = 0;
    }
    this.explodeChildren.forEach((c: ExplodeChild) => {
      const newPos = c.position.clone();
      const dir = c.dir.clone();
      newPos.add(dir.multiplyScalar(this.explodeCurrent * this.explodeAmount));
      c.node.position.set(newPos.x, newPos.y, newPos.z);
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'modelId',
        'Model',
        'Core',
        'model',
        'string',
        true,
        () => this.modelId,
        (data: any) => (this.modelId = data),
      ),
      this.createProperty('explodeOnClick', 'Explode on Click', 'Interaction', 'bool', 'boolean', true),
      this.createProperty('modelVariantId', 'Model Variant', 'Core', 'model-variant', 'string', true),
      this.createProperty('explodeAmount', 'Explode Amount', 'Explode', 'number', 'number', true),
      this.createProperty('explodeSpeed', 'Explode Speed', 'Explode', 'number', 'number', true),
      this.createProperty(
        'dotNotExplode',
        'Parts not to Explode',
        'Explode',
        'child-select',
        'boolean',
        true,
        () => this.doNotExplode,
        (options) => {
          this.doNotExplode = options;
        },
        () => {
          if (!this.apiModel || this.renderNode.children.length === 0) {
            return [];
          }
          return this.getAllKeyNames(this.renderNode.children[0]).map(x => { return { key: x, label: x }});;
        },
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.modelId = this.modelId;
    result.explodeAmount = this.explodeAmount;
    result.explodeSpeed = this.explodeSpeed;
    result.explodeOnClick = this.explodeOnClick;
    result.dotNotExplode = this.doNotExplode;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.modelId = data.modelId;
    this.explodeAmount = data.explodeAmount;
    this.explodeSpeed = data.explodeSpeed;
    this.explodeOnClick = data.explodeOnClick;
    this.doNotExplode = data.dotNotExplode ?? [];
  }

  public getClickable() {
    const result = super.getClickable();
    result.push(this.renderNode);
    return result;
  }

  private setupExplodeChildren(o: Object3D, center: Vector3) {
    if (this.doNotExplode.indexOf(o.userData.keyName) === -1) {
      const childCenter = this.getCenterPosition(o);
      const result: ExplodeChild = {
        node: o,
        position: o.position.clone(),
        dir: childCenter.clone().sub(center).normalize(),
      };
      this.explodeChildren.push(result);
    }

    o.children.map((c) => this.setupExplodeChildren(c, center));
  }

  private getCenterPosition(o: Object3D) {
    const box = new Box3().setFromObject(o);
    const center = new Vector3();
    box.getCenter(center);
    return center;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.modelId) {
      // Load model from API
      const apiModel = await ModelService.get(this.modelId, {
        abortController: this.scene.abortController,
      });
      this.apiModel = apiModel;
      // Create Three.js render object
      const proVizLoader = new ProVizLoader();
      try {
        const model = await proVizLoader.LoadModel(this.apiModel, 'gltf');
        setRenderOrder(model, 2);
        this.renderNode.add(model);
        this.setupKeyNames(model, {});

        const center = this.getCenterPosition(model);

        this.setupExplodeChildren(model, center);

        // Get model variant
        if (this.modelVariantId || apiModel.defaultVariantId) {
          const modelVariantId = this.modelVariantId || apiModel.defaultVariantId;
          this.apiModelVariant = await ModelVariantService.get(modelVariantId);
        }

        // Apply material set
        if (this.apiModelVariant) {
          await ProVizLoader.ApplyMaterialSet(model, this.apiModelVariant.materialSet);
        }
      } catch (err) {
        console.error('Could not load model information for explode widget', err);
      }
    }

    return true;
  }

  dispose() {
    super.dispose();
    for (let i = 0; i < this.explodeChildren.length; i++) {
      disposeModel(this.explodeChildren[i].node);
    }
  }
}

ModuleService.Register(ExplodeWidget.type, ExplodeWidget);
