import ModelViewManager from "../graphics/ModelViewManager";
import { Rotation } from "../graphics/Types";
import Command from "./Command";

export default class SetModelRotation extends Command {
  oldVal: Rotation;
  newVal: Rotation;
  manager: ModelViewManager;
  name = "Rotate Model";
  constructor(
    manager: ModelViewManager,
    newRotation: Rotation,
    oldRotation: Rotation
  ) {
    super();
    this.newVal = { x: newRotation.x, y: newRotation.y, z: newRotation.z };
    this.oldVal = { x: oldRotation.x, y: oldRotation.y, z: oldRotation.z };
    this.manager = manager;
  }
  execute() {
    let { x, y, z } = this.newVal;
    this.manager.setRotation(x, y, z);
  }

  undo() {
    let { x, y, z } = this.oldVal;
    this.manager.setRotation(x, y, z);
  }
}
