import {
  ACESFilmicToneMapping,
  CineonToneMapping,
  Color,
  Fog,
  LinearEncoding,
  LinearToneMapping,
  Material,
  Mesh,
  NoToneMapping,
  Object3D,
  PCFSoftShadowMap,
  ReinhardToneMapping,
  Scene,
  RepeatWrapping,
  ClampToEdgeWrapping,
  MirroredRepeatWrapping,
  Wrapping,
  sRGBEncoding,
  TextureEncoding,
  ToneMapping,
  WebGLRenderer,
  Group,
  Texture,
} from 'three';
import { APIMaterialParameter, ISize } from '@proviz/api-services';
import { ProVizScene } from '..';

export function setCastShadow(obj: Object3D, cast: boolean = true) {
  const _setCastShadow = (obj: Object3D) => {
    obj.castShadow = cast;
    obj.children.forEach((o) => _setCastShadow(o));
  };
  _setCastShadow(obj);
}
// https://github.com/mrdoob/three.js/blob/master/examples/webgl_tonemapping.html
// export type ToneMappingOptions = 'None' | 'Linear' | 'Reinhard' | 'Cineon' | 'ACESFilmic';
// | 'Custom';

export const toneMappingOptions: { [tone: string]: ToneMapping } = {
  None: NoToneMapping,
  Linear: LinearToneMapping,
  Reinhard: ReinhardToneMapping,
  Cineon: CineonToneMapping,
  ACESFilmic: ACESFilmicToneMapping,
  // Custom: CustomToneMapping
};

export const encodingOptions: { [key: string]: TextureEncoding } = {
  linear: LinearEncoding,
  sRGB: sRGBEncoding,
};

/**
 * Render Order Guide
 *
 * 0 - Skybox, Ground Plane
 * 1 - Marker Borders
 * 2 - Basewidget rendernode default, Textboxes,  Marker Backgrounds
 * 3 -
 * 4 - VideoWidgets
 */
export function setRenderOrder(obj: Object3D, renderOrder: number) {
  obj.renderOrder = renderOrder;
  obj.children.forEach((o) => setRenderOrder(o, renderOrder));
}

export function buildRenderer(screenDimensions: ISize) {
  const renderer = new WebGLRenderer({ antialias: true, alpha: true });
  const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
  renderer.setPixelRatio(DPR);
  renderer.setSize(screenDimensions.width, screenDimensions.height);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFSoftShadowMap; // default

  renderer.outputEncoding = sRGBEncoding;
  renderer.toneMapping = CineonToneMapping;
  renderer.toneMappingExposure = 0.6;
  renderer.autoClear = false;

  return renderer;
}

// See this forum post form don mccurdy about gamma factor
export function applyRendererSettings(renderer: WebGLRenderer, proVizScene: ProVizScene) {
  let newToneMap = toneMappingOptions[proVizScene.toneMapping] ?? NoToneMapping;
  if (newToneMap !== renderer.toneMapping) {
    renderer.toneMapping = newToneMap;
  }

  const newToneMapExposure = proVizScene.toneMapExposure ?? 1;
  if (newToneMapExposure !== renderer.toneMappingExposure) {
    renderer.toneMappingExposure = newToneMapExposure;
  }
  const newOutputEncoding = encodingOptions[proVizScene.outputEncoding] ?? sRGBEncoding;
  if (newOutputEncoding !== renderer.outputEncoding) {
    renderer.outputEncoding = newOutputEncoding;
  }
  if (renderer.physicallyCorrectLights !== proVizScene.physCorrectLight ?? false) {
    renderer.physicallyCorrectLights = proVizScene.physCorrectLight ?? false;
  }
  console.log(
    `apply renderer settings toneMap ${newToneMap} (${proVizScene.toneMapping}), exposure ${newToneMapExposure}, output ${newOutputEncoding} (${proVizScene.outputEncoding})`,
  );
}

export function applyFogSettings(scene: Scene, proVizScene: ProVizScene) {
  const { fogOn, fogNear, fogFar, fogColor } = proVizScene;
  if (!fogOn) {
    scene.fog = null;
  } else {
    scene.fog = new Fog(new Color(fogColor), fogNear, fogFar);
  }
}
/*
 * Traverse a scene or object and set all needsUpdate true on all materials
 * in their tree.
 * Some cases where this is used can be cut when we update past r127.
 */
export function setMaterialsNeedUpdate(o: Object3D | Scene) {
  if (o instanceof Mesh) {
    if (o.material instanceof Material) {
      o.material.needsUpdate = true;
    } else {
      for (let mat of o.material) {
        mat.needsUpdate = true;
      }
    }
  }
  if (o.children) {
    o.children.forEach((c) => setMaterialsNeedUpdate(c));
  }
}

export function applyTextureEncoding(o: Object3D, encoding: TextureEncoding) {
  if (o instanceof Mesh) {
    if (o.material instanceof Material) {
      o.material.needsUpdate = true;
    } else {
      for (let material of o.material) {
        let needsUpdate = false;
        if (material.map && material.map.encoding !== encoding) {
          material.map.encoding = encoding;
          needsUpdate = true;
        }
        if (material.emissiveMap && material.emissiveMap.encoding !== encoding) {
          material.emissiveMap.encoding = encoding;
          needsUpdate = true;
        }
        if (needsUpdate) {
          material.needsUpdate = true;
        }
      }
    }
  }
  if (o.children) {
    o.children.forEach((c) => applyTextureEncoding(c, encoding));
  }
}

export function decodeWrapping(p: APIMaterialParameter): Wrapping | undefined {
  switch (p.stringValue) {
    case 'RepeatWrapping':
      return RepeatWrapping;
    case 'ClampToEdgeWrapping':
      return ClampToEdgeWrapping;
    case 'MirroredRepeatWrapping':
      return MirroredRepeatWrapping;
    default:
      return undefined;
  }
}

export function setVisibleRec(o: Object3D, state: boolean) {
  o.visible = state;
  o.children.forEach((o) => setVisibleRec(o, state));
}

export function disposeModel(m: Group | Object3D) {
  m.children.forEach((child) => {
    child.traverse(function (obj) {
      // @ts-ignore
      delete obj.userData?.widget;
      if (obj.type === 'Mesh') {
        let o = obj as Mesh;
        o.geometry?.dispose();
        const materials = Array.isArray(o.material) ? o.material : [o.material];
        materials.forEach((mat) => {
          for (const prop in mat) {
            const propertyValue = (mat as any)[prop];
            if (propertyValue instanceof Texture) {
              propertyValue.dispose();
            }
          }
          mat.dispose();
        });
      }
    });
  });
}
