import { BoxHelper, Euler, Object3D, Scene, Vector2, Vector3 } from "three";
import { BaseWidget, EventDispatcher } from "@proviz/proviz-sdk";
import Raycast from "../Raycast";

export type ControlsEvents = "mode-update";

export default class Controls extends EventDispatcher {
  canvas: HTMLCanvasElement;
  raycaster: Raycast = new Raycast();

  objectPositionOnDown: Vector3 = new Vector3();
  objectRotationOnDown: Euler = new Euler();
  objectScaleOnDown: Vector3 = new Vector3();

  onDownPosition: Vector2 = new Vector2();
  onUpPosition: Vector2 = new Vector2();

  selectionBoxes: BoxHelper[] = [];
  helperScene: Scene;

  constructor(canvas: HTMLCanvasElement, helperScene: Scene) {
    super();
    this.canvas = canvas;
    this.helperScene = helperScene;

    this.attach = this.attach.bind(this);
    this.detach = this.detach.bind(this);
  }

  setBoxes(selections: BaseWidget[]) {
    let boxCount = 0;
    selections.forEach((widget) => {
      if (this.selectionBoxes.length === boxCount) {
        const box = new BoxHelper(new Object3D());
        this.selectionBoxes.push(box);
        this.helperScene.add(box);
      }
      this.selectionBoxes[boxCount].visible = true;
      this.selectionBoxes[boxCount].setFromObject(widget.renderNode);
      boxCount++;
    });

    for (; boxCount < this.selectionBoxes.length; boxCount++) {
      this.selectionBoxes[boxCount].visible = false;
    }
  }

  dispatchModeUpdate() {
    this.dispatchEvent("mode-update", {
      data: undefined,
      dataType: "none",
      sourceWidgetId: "",
      event: 'mode-update'
    });
  }

  /* Wrapper to give us type checking on events */
  attach(event: ControlsEvents, fn: () => void) {
    this.addEventListener(event, fn);
  }

  /* Wrapper to give us type checking on events */
  detach(event: ControlsEvents, fn: () => void) {
    this.removeEventListener(event, fn);
  }
}
