import { ReactElement, useEffect, useState } from 'react'
import { ManagerStore } from '../../store';


export default function PanoramaButton(): ReactElement {
    const manager = ManagerStore( s => s.SceneManager );
    const [show, setShow] = useState(manager.proVizScene && manager.proVizScene.view === 'Panorama');

    useEffect(() => {
        const update = () => 
            setShow(manager.proVizScene && manager.proVizScene.view === 'Panorama');
        manager.addEventListener('view-change', update);
        return () => {
            manager.removeEventListener('view-change', update);
        }
    });

    return <button className={`panoramaSceneEditorButton ${show ? 'show' : ''}`} onClick={() => {
            if(manager.proVizScene.leaveModeFn) {
                manager.proVizScene.leaveModeFn();
                requestAnimationFrame(() => manager.render())
            }
        }}>
            Leave Panorama View
        </button>;
}
