import { WidgetDefinition } from '../../baseWidget';

export const AudioWidgetDefinition: WidgetDefinition = {
  label: 'Audio',
  type: 'audio',
  // name is used as Label and then converted to the event type by using only lowercase letters
  // and converting spaces to -'s. Example: 'Clicked' -> 'clicked
  events: [
    {
      label: 'Play Finished',
      dataType: 'audio',
    },
    {
      label: 'Play Started',
      dataType: 'audio',
    },
    {
      label: 'Play Paused',
      dataType: 'audio',
    },
    {
      label: 'Play Stopped',
      dataType: 'audio',
    },
  ],

  // name is used as label and converted to 'set-audio'
  services: [
    {
      label: 'Play',
      desc: '<b>Plays the audio from current time</b>',
      dataType: 'audio',
    },
    {
      label: 'Stop',
      dataType: 'audio',
      desc: '<b>Stops the audio and resets playback location to start</b>',
    },
    {
      label: 'Pause',
      dataType: 'audio',
      desc: '<b>Pauses the audio</b>',
    },
    {
      label: 'Set Source',
      dataType: 'audio',
      desc: '<b>Set audio source</b><br /><i>Input: <b>(string)</b></i>',
    },
  ],

  // Data property name converts to camel case 'textContent'.
  // Serialization is setup by the base widget constructor
  properties: [
    {
      label: 'Audio Sources',
      category: 'Core',
      dataType: 'audio-options',
      editable: true,
    },
    {
      label: 'Play on Load',
      category: 'Media Behavior',
      dataType: 'bool',
      editable: true,
    },
    {
      label: 'Loop',
      category: 'Media Behavior',
      dataType: 'bool',
      editable: true,
    },
  ],
};
