import {
  DoubleSide,
  Euler,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Quaternion,
  Vector3,
} from "three";

export const _tempVector = new Vector3();
export const _v1 = new Vector3();
export const _v2 = new Vector3();
export const _v3 = new Vector3();

export const _unitX = new Vector3(1, 0, 0);
export const _unitY = new Vector3(0, 1, 0);
export const _unitZ = new Vector3(0, 0, 1);

export const _tempEuler = new Euler();
export const _alignVector = new Vector3(0, 1, 0);
export const _zeroVector = new Vector3(0, 0, 0);
export const _lookAtMatrix = new Matrix4();
export const _tempQuaternion = new Quaternion();
export const _tempQuaternion2 = new Quaternion();
export const _identityQuaternion = new Quaternion();
export const _dirVector = new Vector3();
export const _tempMatrix = new Matrix4();

//
export class ProVizTransformControlsPlane extends Mesh {
  cameraQuaternion: Quaternion = new Quaternion();

  worldQuaternion: Quaternion = new Quaternion();

  worldPosition: Vector3 = new Vector3();

  eye: Vector3 = new Vector3();

  space: string;

  mode: string;

  axis: string;

  constructor() {
    super(
      new PlaneGeometry(100000, 100000, 2, 2),
      new MeshBasicMaterial({
        visible: false,
        wireframe: true,
        side: DoubleSide,
        transparent: true,
        opacity: 0.1,
        toneMapped: false,
      })
    );

    this.type = "TransformControlsPlane";
  }

  updateMatrixWorld(force: boolean) {
    let space = this.space;

    this.position.copy(this.worldPosition);

    if (this.mode === "scale") space = "local"; // scale always oriented to local rotation

    _v1
      .copy(_unitX)
      .applyQuaternion(
        space === "local" ? this.worldQuaternion : _identityQuaternion
      );
    _v2
      .copy(_unitY)
      .applyQuaternion(
        space === "local" ? this.worldQuaternion : _identityQuaternion
      );
    _v3
      .copy(_unitZ)
      .applyQuaternion(
        space === "local" ? this.worldQuaternion : _identityQuaternion
      );

    // Align the plane for current transform mode, axis and space.
    _alignVector.copy(_v2);

    switch (this.mode) {
      case "translate":
      case "scale":
        switch (this.axis) {
          case "X":
            _alignVector.copy(this.eye).cross(_v1);
            _dirVector.copy(_v1).cross(_alignVector);
            break;
          case "Y":
            _alignVector.copy(this.eye).cross(_v2);
            _dirVector.copy(_v2).cross(_alignVector);
            break;
          case "Z":
            _alignVector.copy(this.eye).cross(_v3);
            _dirVector.copy(_v3).cross(_alignVector);
            break;
          case "XY":
            _dirVector.copy(_v3);
            break;
          case "YZ":
            _dirVector.copy(_v1);
            break;
          case "XZ":
            _alignVector.copy(_v3);
            _dirVector.copy(_v2);
            break;
          case "XYZ":
          case "E":
            _dirVector.set(0, 0, 0);
            break;
        }

        break;
      case "rotate":
      default:
        // special case for rotate
        _dirVector.set(0, 0, 0);
    }

    if (_dirVector.length() === 0) {
      // If in rotate mode, make the plane parallel to camera
      this.quaternion.copy(this.cameraQuaternion);
    } else {
      _tempMatrix.lookAt(_tempVector.set(0, 0, 0), _dirVector, _alignVector);

      this.quaternion.setFromRotationMatrix(_tempMatrix);
    }

    super.updateMatrixWorld(force);
  }
}
