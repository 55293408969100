import { useState } from 'react'
import { APIModel, ModelService } from "@proviz/api-services"

interface Props {
    model: APIModel;
    refresh: () => void;
}

type State = 'none' | 'simplifying' | 'failed';

function stateToButtonText(s: State) {
    switch(s) {
        case('none'):
            return "Simplify"
        case('simplifying'):
            return "Simplifying...";
        case('failed'):
            return 'failed, try again?'
    }
}

export const SimplifyButton = (props: Props) => {
    const [state, setState] = useState<State>('none');
    const [factor, setFactor] = useState(1);

    const simplify = () => {
        setState('simplifying');
        ModelService.simplify(props.model.id, factor)
            .then(model => {
                props.refresh();
                setState('none');
            })
            .catch(e => {
                console.error('Could not simplify model', e);
                setState('failed')
            });
    }

    return (
        <div>
           <button onClick={simplify}>{stateToButtonText(state)}</button>
            Simplification Factor:
            <input value={factor} onChange={(e) => setFactor(parseFloat(e.target.value))} />
        </div>
    )
}
