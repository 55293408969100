import GraphicsManager from "../graphics/GraphicsManager";
import Command from "./Command";

export default class CommandBroker {
  manager: GraphicsManager;
  history: Command[] = [];
  futureHistory: Command[] = [];

  constructor(manager: GraphicsManager) {
    this.manager = manager;
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
    this.execute = this.execute.bind(this);

    this.dispatchHistoryUpdate = this.dispatchHistoryUpdate.bind(this);
  }

  execute(command: Command) {
    if (this.futureHistory) {
      this.futureHistory = [];
    }
    try {
      command.execute();
      this.history.push(command);
    } catch (error) {
      alert(
        error ||
          "I'm afraid we weren't able to complete that last instruction. Maybe try again."
      );

      // TODO: Properly handle errors from commands failing
      // Ex: Two paths
      // * Try to add a widget to a widget that it's disallowed, failure that does not
      // need to be undone
      // * Add a model - failed network request and should undo
      // command.undo();
      return;
    }
    this.dispatchHistoryUpdate();
  }

  undo() {
    let command = this.history.pop();
    if (!command) {
      return;
    }
    command.undo();
    this.futureHistory.push(command);
    this.dispatchHistoryUpdate();
  }

  redo() {
    let command = this.futureHistory.pop();
    if (!command) {
      return;
    }
    command.execute();
    this.history.push(command);
    this.dispatchHistoryUpdate();
  }

  dispatchHistoryUpdate() {
    this.manager.dispatchHistoryUpdate();
  }

  reset() {
    this.history.length = this.futureHistory.length = 0;
  }
}
