import { Auth0Context } from "@auth0/auth0-react";
import { UserService, CompanyService, APICompany } from "@proviz/api-services";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Header } from "./common";
import { useHistory } from "react-router-dom";
import { userStore } from "../store";
import Button from "./common/button/Button";
import Input from "./common/input/input";

export default function SignInPage(): ReactElement {
  const initialSearchURL = window.location.search;
  const { user } = useContext(Auth0Context);
  const apiUser = userStore((s) => s.user);
  const setUser = userStore((s) => s.setUser);
  const history = useHistory();
  const [firstName, setFirstName] = useState(
    apiUser?.firstName ?? (user?.given_name || "")
  );
  const [lastName, setLastName] = useState(
    apiUser?.lastName ?? (user?.family_name || "")
  );
  const [companyName, setCompanyName] = useState("");
  const [userInviteCode, setUserInviteCode] = useState<string | null>(null);
  const [company, setCompany] = useState<APICompany>();
  const [joinInvite, setJoinInvite] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(initialSearchURL);
      const invite = urlParams.get("invite");
      if (invite) {
        setUserInviteCode(invite);
        try {
          setCompany(await CompanyService.getCompanyFromInvite(invite));
        } catch (e) {
          console.log(e);
        }
      }
    })();
  }, []);

  return (
    <div className="home" style={{ background: "#f7f8fa" }}>
      <Header />
      <div className="loginTitle">
        <table>
          <tr>
            <td>First Name</td>
            <td>
              <Input value={firstName} onChange={(val) => setFirstName(val)} />
            </td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>
              <Input value={lastName} onChange={(val) => setLastName(val)} />
            </td>
          </tr>
          {(!company || !joinInvite) && (
            <tr>
              <td>Company Name</td>
              <td>
                <Input
                  value={companyName}
                  onChange={(val) => setCompanyName(val)}
                />
              </td>
            </tr>
          )}
        </table>
        {company && (
          <div style={{ minWidth: "320px", width: "20%", marginBottom: "1em" }}>
            <p>
              You have been invited to join <b>{company?.name}</b>
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{ boxShadow: joinInvite ? "0px 0px 10px green" : "" }}
              >
                <Button
                  type="submit"
                  onClick={() => {
                    setJoinInvite(true);
                  }}
                >
                  Accept
                </Button>
              </div>
              <div style={{ boxShadow: !joinInvite ? "0px 0px 10px red" : "" }}>
                <Button
                  type="danger"
                  onClick={() => {
                    setJoinInvite(false);
                  }}
                >
                  Deny
                </Button>
              </div>
            </div>
          </div>
        )}
        <Button
          type="default"
          disabled={
            !firstName ||
            !lastName ||
            !(companyName || (company && joinInvite)) ||
            saving
          }
          onClick={async () => {
            let user;
            setSaving(true);
            try {
              if (userInviteCode && company) {
                user = await UserService.invite(userInviteCode);
              } else {
                user = await UserService.create(companyName);
              }
              const userData = Object.assign({}, user);
              userData.firstName = firstName;
              userData.lastName = lastName;
              user = await UserService.update(userData);
            } catch (e) {
              console.log(e);
            } finally {
              setSaving(false);
              if (user) {
                setUser(user);
                history.push("/created");
              }
            }
          }}
        >
          Save Changes
        </Button>
        {saving && <>Saving, please wait</>}
      </div>
    </div>
  );
}
