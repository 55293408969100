import { ReactElement, useEffect, useState } from 'react';
import {
    APIFile,
    APIModel, APITag, FileService, initAbortController, ModelService, TagService,
} from "@proviz/api-services";
import ModelName from '../modeleditor/ModelName';
import Modal from '../common/modals/Modals';
import Button from '../common/button/Button';

type Entity = { type: 'File', file: APIFile } | { type: 'Model', model: APIModel };

interface Props {
    entity: Entity;
    close: () => void;
}

type RequestState = 'None' | 'Exporting' | 'Failed';

function buttonText(s: RequestState) {
    switch(s) {
        case 'None':
            return 'Export'
        case 'Exporting':
            return 'Exporting';
        case 'Failed':
            return 'Failed';
    }
}

export default function GlobalExportMenu(props: Props): ReactElement {
    const { entity, close } = props;
    const [availableTags, setAvailableTags] = useState<APITag[]>([]);
    const [tags, setTags] = useState<string[]>([""]);
    const [state, setState] = useState<RequestState>('None')

    useEffect(() => {
        const abortController = initAbortController();
        TagService.getAll({ abortController }).then(setAvailableTags);
      return () => abortController.abort();
    }, []);

    async function exportToGlobal() {
        setState('Exporting');
        try {
            if(entity.type === 'File') {
                await FileService.exportToGlobal(entity.file.id, tags);
            } else if (entity.type === 'Model') {
                await ModelService.exportToGlobal(entity.model.id, tags);
            }
            setState('None');
            close();
        } catch (e) {
            console.error(e);
            setState('Failed');
        }
    }

    return <Modal title={entity.type === 'Model' ? <ModelName model={entity.model} /> : <h1>{entity.file.displayName}</h1>} close={close} >
        <div className='modalBody'>
            {tags.map((tag: string, ind: number) => {
                return <div key={`${tag}-${ind}`} className='flex'>
                    <div>
                        <select value={tag} onChange={(e) => {
                            const newTags = [...tags];
                            newTags[ind] = e.target.value;
                            setTags(newTags);
                        }}>
                            <option value=""></option>
                            {availableTags.map((t) => <option value={t.id} key={t.id}>{t.name}</option>)}
                        </select>
                    </div>
                    <div>
                        <Button type='icon' onClick={() => {
                            const newTags = [...tags];
                            newTags.splice(ind, 1);
                            setTags(newTags);
                        }}>
                            X
                        </Button>
                    </div>
                </div>
            })}
            <Button fullWidth={true} onClick={() => setTags([...tags, ""])}>
                Add Tag
            </Button>
            <Button onClick={exportToGlobal}>{buttonText(state)}</Button>
            {state === 'Failed' && "We ran into an error exporting to global"}
        </div>
    </Modal>
}
