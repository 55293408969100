import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { ProVizScene } from '../../../ProVizScene';
import { ModuleService } from '../../../moduleService';
import { BaseWidgetProperty } from '../..';
import { Vector3 } from 'three';
import { SceneMode } from '../../..';

export class RotationGroupWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'rotation-group';

  public rotationVector = new Vector3();
  public rotating: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Rotation Loop';

    this.widgetType = RotationGroupWidget.type;
    this.widgetName = 'Rotation Loop';
    this.selectable = true;
    this.events = [];

    this.category = 'Animation';

    this.services = [
      {
        label: 'Start Rotation',
        name: 'start-rotation',
        desc: '<b>Starts the rotation of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Stop Rotation',
        name: 'stop-rotation',
        desc: '<b>Stops the rotation of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
      {
        label: 'Toggle Rotation',
        name: 'toggle-rotation',
        desc: '<b>Toggles the rotation of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
    ];

    this.addEventListener('service-start-rotation', () => {
      this.rotating = true;
    });

    this.addEventListener('service-stop-rotation', () => {
      this.rotating = false;
    });

    this.addEventListener('service-toggle-rotation', () => {
      this.rotating = !this.rotating;
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'rotationVector',
        'Set Rotation Speed',
        'Core',
        'vec3',
        'vector3',
        true,
        undefined,
        undefined,
        undefined,
        'Setting this number sets the number of times the widget spins a full 360 degrees on its axis.',
      ),
      this.createProperty(
        'rotating',
        'Start Rotating on Load',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'By clicking this, you are enabling the starting and stopping of the widget rotation on load.',
      ),
    ];
  }

  public rotate(delta: number): any {
    if (!this.rotating) return;
    this.rotationVector.add(new Vector3(
      this.rotationVector.x * 360 * (delta / 60),
      this.rotationVector.y * 360 * (delta / 60),
      this.rotationVector.z * 360 * (delta / 60)
    ));
  }

  public update(delta: number): void {
    super.update(delta);
    if (this.scene.sceneMode === SceneMode.Editor) return;
    this.rotate(delta);
  }

  public serialize(): any {
    const result = super.serialize();
    //Set the data for the rotation here.
    result.rotationVector = this.rotationVector;
    result.rotating = this.rotating;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.rotating = data.rotating;
    this.rotationVector = new Vector3(parseFloat(data.rotationVector?.x || 0), parseFloat(data.rotationVector?.y || 0), parseFloat(data.rotationVector?.z || 0));
  }
}

ModuleService.Register(RotationGroupWidget.type, RotationGroupWidget);
