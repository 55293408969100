import { useState, useCallback } from 'react';
import Resizer from "react-image-file-resizer";
import { FileService } from '@proviz/api-services';
import { getAbsoluteUrl } from '@proviz/proviz-sdk';
import Button from '../common/button/Button';
import { Dropzone } from '../common/dropzone';

const uploadImage = getAbsoluteUrl('images/upload.svg');

const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
interface Props {
    fileId: string;
}

export const UploadThumbnailButton = (props: Props) => {
    const { fileId } = props;
    const [uploading, setUploading] = useState<boolean>(false);
    const [thumbnail, setThumbnail] = useState<File | null>(null);

    const handleFile = useCallback((files: File[]) => {
        if (!files.length) {
            return;
        }
        let f = files[0];
        if (!(f.name)) {
            return;
        }
        setThumbnail(f);
    }, [])

    const handleUpload = useCallback(async() => {
        if (uploading || !thumbnail) {
            return;
        }
        setUploading(true);
        const image = await resizeFile(thumbnail);
        FileService.setThumbnail(fileId, image as File).finally(() => {
            setUploading(false);
        }).catch((error) => {
            console.error(error);
        })
    }, [thumbnail, fileId, uploading])
 
    return (
        <div className="thumbnail">
            {uploading && <div>Generating Upload...</div>}
            <div style={{ marginTop: '50px' }}>
                <Dropzone
                    onUpload={handleFile}
                    accept={".bin,.png,.jpg,.jpeg,.webm,.svg"}
                    text={"Drop a thumbnail here"}
                    reset={() => { }}
                    showFilesSelected
                />
            </div>
             <br />
             <div style={{ marginTop: '25px' }}>
                <Button onClick={handleUpload}>
                    <img src={uploadImage} alt="Upload" /> Upload Thumbnail
                </Button>
            </div>
        </div>
    )
}


    