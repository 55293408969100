import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { getMediaFilePath } from '@proviz/api-services';
import { is_ios, is_landscape, is_mobile, SceneMode } from '../../..';
import { CircleGeometry, Mesh, MeshBasicMaterial, TextureLoader } from 'three';
import { ProVizEventData } from '../../../ProVizEventData';

export class ModelViewerWidget extends BaseWidget {
  public static type: string = 'model-viewer';
  private static iframe?: HTMLIFrameElement;
  private static styleEl?: HTMLStyleElement;

  // Data
  public modelId: string | undefined;
  public modelVariantId: string = '';
  public markerSrc: string = '';

  elements?: { el: HTMLDivElement; backgroundEl: HTMLDivElement };
  private marker?: Mesh<CircleGeometry, MeshBasicMaterial>;
  private removelisteners?: () => void;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ModelViewerWidget.type;
    this.widgetName = 'Model Viewer';
    this.label = 'Model Viewer';
    this.category = 'Experimental';

    this.events.push({
      label: 'Clicked',
      name: 'clicked',
    });

    this.addEventListener('clicked', (e) => {
      this.handleClick();
    });

    this.services.push({
      label: 'Set Variant',
      name: 'set-variant',
    });

    this.addEventListener('service-set-variant', async (event: ProVizEventData) => {
      if (event.dataType === 'modelVariantId') {
        this.modelVariantId = event.data as string;
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('modelId', 'Model', 'Core', 'model', 'string', true),
      this.createProperty('modelVariantId', 'Model Variant', 'Core', 'model-variant', 'string', true),
      this.createProperty(
        'markerSrc',
        'Marker Source',
        'Core',
        'string',
        'string',
        true,
        undefined,
        (data: any) => {
          this.markerSrc = data;
          this.updateMarkerSrc();
        },
      ),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.markerSrc) {
      this.createMarker();
    }

    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    const el = document.createElement('div');
    el.setAttribute('class', 'model-viewer-widget-div hidden');

    const backgroundEl = document.createElement('div');
    backgroundEl.setAttribute('class', 'model-viewer-background-el hidden');

    this.elements = { el, backgroundEl };

    if (!ModelViewerWidget.styleEl) {
      ModelViewerWidget.styleEl = document.createElement('style');
      ModelViewerWidget.styleEl.innerText = ` 
.model-viewer-widget-div {
  width: 90vw;
  height: ${is_mobile ? '70vh' : '90vh'};
  display: grid;
  place-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  margin: ${is_mobile ? '10vh 5vw' : '5vh 5vw'};
  z-index: 2;
}
.model-viewer-widget-div.horizontal {
  margin: ${is_ios ? '15vh 5vw 5vh 5vw' : '5vh 5vw'};
}
.model-viewer-widget-div.hidden {
  display: none;
}
.model-viewer-background-el {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #dedede;
  cursor: pointer;
  opacity: 0.6;
  z-index: 1;
}
.model-viewer-background-el.hidden {
  display: none;
}
.model-viewer-widget-div iframe {
  width: 90vw;
  height: ${is_mobile ? '70vh' : '90vh'};
  opacity: 1;
}`;
      document.body.append(ModelViewerWidget.styleEl);
    }

    this.scene?.parentEl?.append(this.elements.el);
    this.scene?.parentEl?.append(this.elements.backgroundEl);
    return true;
  }

  public getClickable() {
    const result = super.getClickable();
    if (this.visible && this.marker) {
      result.push(this.marker);
    }
    return result;
  }

  public onHoverEnter() {
    document.body.style.cursor = 'pointer';
  }

  public onHoverLeave() {
    document.body.style.cursor = 'default';
  }

  public serialize(): any {
    const result = super.serialize();
    result.modelId = this.modelId;
    result.modelVariantId = this.modelVariantId;
    result.markerSrc = this.markerSrc;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.modelId = data.modelId;
    this.modelVariantId = data.modelVariantId;

    this.markerSrc = data.markerSrc;
  }

  private handleClick() {
    if (!this.elements) {
      return;
    }
    const { el, backgroundEl } = this.elements;
    el.setAttribute('class', 'model-viewer-widget-div');
    backgroundEl.setAttribute('class', 'model-viewer-background-el');
    if (!ModelViewerWidget.iframe) {
      ModelViewerWidget.iframe = document.createElement('iframe');
    }
    ModelViewerWidget.iframe.setAttribute('src', this.getEmbedLink());
    el.append(ModelViewerWidget.iframe);

    const hideBackground = () => this.hideDiv();

    backgroundEl.addEventListener('click', hideBackground);

    // On ios we need to shift down the top margin a little extra to not overlap
    // with the search bar
    const deviceRotate = () => {
      if (is_landscape()) {
        el.setAttribute('class', 'model-viewer-widget-div horizontal');
      }
      backgroundEl.setAttribute('class', 'model-viewer-background-el');
    };
    if (is_ios) {
      window.addEventListener('resize', deviceRotate);
    }

    this.removelisteners = () => {
      backgroundEl.removeEventListener('click', hideBackground);
      if (is_ios) {
        window.removeEventListener('resize', deviceRotate);
      }
    };
  }

  private hideDiv() {
    if (!this.elements) {
      return;
    }
    const { el, backgroundEl } = this.elements;
    el.setAttribute('class', 'model-viewer-widget-div hidden');
    backgroundEl.setAttribute('class', 'model-viewer-background-el hidden');
    if (ModelViewerWidget.iframe) {
      el.removeChild(ModelViewerWidget.iframe);
    }

    if (this.removelisteners) {
      this.removelisteners();
    }
  }

  private getEmbedLink() {
    return `${window.location.origin}/model/${this.modelId}${
      this.modelVariantId && `?variant=${this.modelVariantId}`
    }`;
  }

  private updateMarkerSrc() {
    if (!this.marker) {
      this.createMarker();
    } else {
      const imgSrc = getMediaFilePath(this.markerSrc);
      const textureLoader = new TextureLoader();
      const tex = textureLoader.load(imgSrc);
      // @ts-ignore
      this.marker.material.map = tex;
    }
  }

  private createMarker() {
    const imgSrc = getMediaFilePath(this.markerSrc);
    const geometry = new CircleGeometry(0.28, 32);
    const textureLoader = new TextureLoader();
    const tex = textureLoader.load(imgSrc);
    this.marker = new Mesh(geometry, new MeshBasicMaterial({ map: tex, transparent: false }));
    this.marker.renderOrder = 2;
    this.renderNode.add(this.marker);
  }
  public dispose(): void {
    super.dispose();
    this.marker?.material.dispose();
    this.marker?.geometry.dispose();
  }
}

ModuleService.Register(ModelViewerWidget.type, ModelViewerWidget);
