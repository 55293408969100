import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { getAbsoluteUrl } from '@proviz/proviz-sdk';

const defaultThumbnail = getAbsoluteUrl('images/thumbnail.svg');

export interface ISceneRecord {
    id: string;
    name: string;
    thumbnailFile: { location: string } | null;
    updated: string;
}

interface Props {
    title: string;
    thumbnail?: string;
    openUrl: string;
    updated: string;
    padded?: boolean;
    global?: boolean;
    onDragStart: (e: React.DragEvent<HTMLElement>) => void;
    children?: React.ReactNode;
}

export function timeSinceUpdated(date: string) {
    let lastEdited = "";
    let d = new Date(date + "Z")
    try {
        lastEdited = formatDistanceToNow(d, {includeSeconds: true});
    } catch(e: any) {
        console.error("Time since failed", e.message, date, d);
    }
    return lastEdited;
 }   

export default function ThumbnailCard(props: Props): ReactElement {
    const history = useHistory();

    function open() { 
        history.push(props.openUrl);
    }

    const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragStart = (e: React.DragEvent<HTMLElement>) => {
        props.onDragStart(e);
    };

    const thumbnailStyle: { backgroundImage: string, backgroundSize: string | undefined} = {
        backgroundImage: `url('${props.thumbnail || defaultThumbnail}')`,
        backgroundSize: undefined
    }

    if (!props.thumbnail) {
        thumbnailStyle.backgroundSize = '75%';
    }

    return  <div className="thumbnailCard"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragStart={handleDragStart}
        draggable={true}>
        <div className="thumbnailContainer" >
            <div onClick={open} className={props.padded ? 'bg padded' : 'bg'} style={thumbnailStyle}>
                
            </div>
            <div className="last-update">
                    {timeSinceUpdated(props.updated) && <p>{timeSinceUpdated(props.updated)} ago</p>}
                </div>
                <div className="buttonsContainer">
                    {props.children}
                </div>

                {props.global && <div className='global'>Global</div>}
        </div>
        <div className="infoRow">
            <div className="leftInfo">
                <b onClick={open}>{props.title} </b>
            </div>
        </div>
    </div>
}