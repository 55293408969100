const Endpoint = "http://localhost:8010/proxy"; // For now we set up a proxy to the matterport api to get around cors issues.
// const Endpoint = "https://api.matterport.com/api/models/graph"

export type Resolution = "low" | "high" | "2k" | "4k";

/** GraphQL query for the matterport api */
const PanoQuery = (resolution: Resolution) => `query getPanos($modelId:ID!){
  model(id: $modelId) {
    name
  panoLocations {
    id
    position {x, y, z}
    rotation {x, y, z, w}
    skybox(resolution: "${resolution}") {
      id
      perspective {
        id
        anchor {
          id
          position {x, y, z}
        }
      }
      status
      format
      children
    }
  }
}
}`;

// {
//   "modelId":"58uAzX1zrd7"
// }

export default class MatterportService {
  // Perhaps this shouldnt be in the source code. When this moves beyond an internal
  // tool you're gonna need to set up a whole login integeration, but for now we'll just
  // hardcode Joe's account.
  private static secret = "609d5afb16463f707697cae804481077";
  private static tokenId = "6b02f0df02d01d45";

  public static async getPanoramas(id: string, resolution: Resolution) {
    const headers = new Headers();
    headers.append("mode", "cors");
    headers.append("content-type", "application/json");
    const token = window.btoa(
      MatterportService.tokenId + ":" + MatterportService.secret
    );
    headers.append("Authorization", `Basic ${token}`);
    const body = {
      query: PanoQuery(resolution),
      variables: { modelId: id },
    };
    const requestInit = {
      headers,
      method: "POST",
      body: JSON.stringify(body),
    };
    const resp = await fetch(Endpoint, requestInit);
    if (resp.status === 200 || resp.status === 201) {
      let respData = await resp.json();
      return respData.data;
    }
    throw Error("Invalid response from matterport");
  }

  public static ExtractFileName(url: string) {
    try {
      let a = url.split("?");
      let b = a[0].split("/");
      return b[b.length - 1];
    } catch (e) {
      console.error(e);
      return "untitled.jpg";
    }
  }
  /** Documenting a long time since I wrote this but what I'm pretty sure
   * this does is map the matterpot skybox index to a threejs skybox index.
   * Threejs/we use a different ordering for images in our skyboxes from the
   * ordering that matterport uses.
   */
  public static matterportToThreeIndex(matterportIdx: number) {
    switch (matterportIdx) {
      case 0:
        return 2;
      case 1:
        return 4;
      case 2:
        return 0;
      case 3:
        return 5;
      case 4:
        return 1;
      case 5:
        return 3;
      default:
        throw new Error("Out of range");
    }
  }
}
