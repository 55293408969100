import { ReactElement, useEffect, useState } from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { initAbortController, isAbortError, SceneService } from "@proviz/api-services";
import { ISceneRecord } from './ThumbnailCard';
import Modal from '../common/modals/Modals';
import { LoadingStore } from '../../store';

interface ISceneVersionRecord extends ISceneRecord {
    version: number;
}
interface Props {
    scene: ISceneRecord;
    close: () => void;
}
/** Comparator to sort versions newest (highest number) first. */
const versionComp = (s1: ISceneVersionRecord, s2: ISceneVersionRecord) =>
    s2.version - s1.version;

type LoadingState = 'loading' | 'loaded' | 'failed'

export default function SceneVersionModal(props: Props): ReactElement {
    const { scene, close } = props;
    const [sceneVersions, setsceneVersions] = useState<ISceneVersionRecord[]>([]);
    const [loadState, setLoadState] = useState<LoadingState>('loading');
    const setLoadingText = LoadingStore( s => s.setLoadingText)

    useEffect(() => {
        const abortController = initAbortController();
        SceneService.getVersionHistory(scene.id, { abortController }).then((s) => {
            const sortScenes = (scenesToSort: ISceneVersionRecord[]) => {
                return scenesToSort.sort(versionComp);
            }
            setLoadState('loaded')
            setsceneVersions(sortScenes(s));
        }).catch((e) => {
            if (isAbortError(e)) return; // if the query was aborted, do nothing
            setLoadState('failed');
        })
        return () => abortController.abort()
    }, [scene.id])

    const downloadJson = async (s: ISceneVersionRecord) => {
        const scene = await SceneService.getVersion(s.id, s.version);
        const fileName = `${s.name}-version-${s.version}.json`

        const blob = new Blob([JSON.stringify(scene)], {
            type: 'application/json',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute('style', 'display: none');
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const rollBack = async (s: ISceneVersionRecord) => {
        setLoadingText("1/3 Publishing current working Version");
        // Publish the changes in the current working version
        const lastPublished = await SceneService.publish(s.id, true);
        setLoadingText("2/3 Downloading target version");
        // Get the data for the version we're rolling back to
        const targetScene = await SceneService.getVersion(s.id, s.version);
        // Give this version the most recent version number # or this will fail
        targetScene.version = lastPublished.version + 1;
        await SceneService.update(targetScene);
        setLoadingText("3/3 Saving target version");
        close();
        window.location.reload();
    }

    return <Modal
        title={`${scene.name} Version History`}
        close={close}
    >
        <p><i>Note: </i>Rolling back will set the current versions data equal to the version you are rolling back to.
            You will need to refresh the page to see the updated scene.</p>
        <div className="versionEntry">
            <p>Version No.</p>
            <p>Last update</p>
            <p></p>
        </div>
        <hr />
        {loadState === 'loading' && <div className="loading-indicator">Loading...</div>}
        {loadState === 'failed' && <p>Request Failed</p>}
        {sceneVersions.map((s) => {
            let lastEdit = "";
            const d = new Date(s.updated + "Z")
            try {
                lastEdit = formatDistanceToNow(d, { includeSeconds: true });
            } catch (e: any) {
                console.error("Time since failed", e.message, s.id, d);
            }
            return <div className="versionEntry" key={s.version}>
                <p>{s.version}</p>
                <p>{lastEdit}</p>
                <button onClick={() => downloadJson(s)}>Download</button>
                <button onClick={() => rollBack(s)}>Rollback</button>
            </div>;
        })}
    </Modal>
}
