export class BaseWidgetDataConnection {
  public event: string; // ex: on-click
  public targetId: string; // ex: video-wdg-123
  public targetService: string; // ex: play
  public data?: string; // ex: 0

  constructor(data?: any) {
    if (data) {
      this.event = data.event;
      this.targetId = data.targetId;
      this.targetService = data.targetService;
      this.data = data.data;
    } else {
      this.event = '';
      this.targetId = '';
      this.targetService = '';
      this.data = '';
    }
  }

  public deserialize(data: any) {
    this.event = data.event;
    this.targetId = data.targetId;
    this.targetService = data.targetService;
    this.data = data.data;
  }

  public serialize() {
    return {
      data: this.data,
      event: this.event,
      targetId: this.targetId,
      targetService: this.targetService,
    };
  }
}

export function connectionsEqual(c1: BaseWidgetDataConnection, c2: BaseWidgetDataConnection) {
  return (
    c1.targetId === c2.targetId && c1.targetService === c2.targetService && c1.event === c2.event
  );
}
