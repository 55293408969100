import { ProVizScene, SceneMode } from '../../..';
import { getMediaFilePath } from '@proviz/api-services';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { ProVizEventData } from '../../../ProVizEventData';
import { AudioWidgetDefinition } from './audioWidgetDefinition';

export class AudioWidget extends BaseWidget implements IBaseWidgetType {
  private static instanceCount = 0;

  // Data
  // public audioOptions: MultiLangOption = {};
  public loop: boolean = false;
  public playOnLoad: boolean = false;
  public playOnClick: boolean = false;

  private audio?: HTMLAudioElement;
  private audioSource?: HTMLSourceElement;
  /**
   * This boolean keeps track of if the widget received a play
   * event while it was not visible or not done initializing.
   *  */
  public shouldPlay: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.setup(AudioWidgetDefinition);

    this.widgetName = AudioWidgetDefinition.label;

    AudioWidget.instanceCount++;
    this.label = 'Audio ' + AudioWidget.instanceCount;

    this.category = 'Core';

    this.addEventListener('service-play', () => {
      if (this.scene.sceneMode == SceneMode.Editor) return;
      this.play();
      this.triggerProVizEvent('play-started', 'number', this.audio?.currentTime);
    });
    this.addEventListener('service-pause', () => {
      this.pause();
      this.triggerProVizEvent('play-paused', 'none');
    });
    this.addEventListener('service-stop', () => {
      this.pause();
      if (this.audio) {
        this.audio.currentTime = 0;
      }
      this.triggerProVizEvent('play-stopped', 'none');
    });

    this.addEventListener('service-set-source', (event: ProVizEventData) => {
      if (event.dataType === 'string') {
        const filename = decodeURIComponent(event.data as string);

        if (!filename) return;

        if (!this.audio) {
          this.audio = this.createAudioDOM();
        }
        if (!this.audioSource) {
          this.audioSource = document.createElement('source');
          this.audio.appendChild(this.audioSource);
        }

        const audioSrc = getMediaFilePath(filename);
        this.audio.pause();

        this.audioSource.setAttribute('type', AudioWidget.getMimeType(audioSrc));
        this.audioSource.setAttribute('src', audioSrc);

        this.audio.addEventListener('loadeddata', () => {
          this.play();
        });
        this.audio.load();
      }
    });
  }

  public async init() {
    if (!(await super.init())) {
      return false;
    }

    this.selectable = true;

    return true;
  }

  private async play() {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }
    if (!this.audio) {
      this.shouldPlay = true;
    } else {
      await this.audio.play();
      this.shouldPlay = false;
    }
  }

  private pause() {
    this.shouldPlay = false;
    this.audio?.pause();
  }

  private createAudioDOM() {
    const audio = document.createElement('audio');
    audio.setAttribute('crossorigin', 'anonymous');
    audio.crossOrigin = 'anonymous';
    audio.id = this.id;

    if (this.loop) {
      audio.loop = this.loop;
      audio.muted = false;
    }
    audio.onended = () => this.triggerProVizEvent('play-finished', 'none');

    return audio;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [...result];
  }

  private static getMimeType(url: string) {
    if (!url) {
      return '';
    }
    const ext = url.split('.').pop();
    let type = '';
    switch (ext) {
      case 'mp3':
        type = 'audio/mp3';
        break;
      case 'ogg':
        type = 'audio/ogg';
        break;
      case 'wav':
        type = 'audio/wav';
        break;
      default:
        console.error(
          `audio files of type ${ext} are not supported. Please use mp3, ogg, or wav format`,
        );
        type = '';
        break;
    }
    return type;
  }

  dispose() {
    super.dispose();
    this.audio?.remove();
    this.audioSource?.remove();
  }
}
