import { ReactNode, useEffect, useMemo, useState } from "react";
import SetWidgetProperty from "../../../commands/SetWidgetProperty";
import GraphicsManager from "../../../graphics/GraphicsManager";
import { SettableWidgetProperty } from "./SettableWidgetProperty";

interface Props {
  header: ReactNode;
  property: SettableWidgetProperty;
  manager: GraphicsManager;
}

function parseRange(options: any) {
  if (options && options.length >= 2) {
    if (options[0].key) {
      return { min: options[0].key, max: options[1].key };
    }
    return { min: options[0], max: options[1] };
  }
  return { min: 0, max: 1 };
}

export const ConstrainedNumberProperty = (props: Props) => {
  const { header, property, manager } = props;
  const { min, max } = useMemo(
    () => parseRange(property.options && property.options()),
    [property]
  );
  const [value, setValue] = useState(property.get() ?? min);

  useEffect(() => {
    console.log('set value');
    setValue(property.get() ?? min);
  }, [property, min]);

  function handleChange(e: any) {
    const n = e.target.value;
    console.log(n, min, max);
    if (n >= min.key && n <= max.key) {
      setValue(n);
      manager.execute(new SetWidgetProperty(property, value, n, manager));
    }
  }

  return (
    <div>
      {header}
      <div className="slideContainer">
        <input value={value} onChange={handleChange} />
        <input
          className="slider"
          type="range"
          value={value}
          onChange={handleChange}
          min={min.key}
          max={max.key}
          step="0.05"
        />
      </div>
    </div>
  );
};
