import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { ProVizScene } from '../../../ProVizScene';
import { ModuleService } from '../../../moduleService';
import { BaseWidgetProperty } from '../..';
import { Euler, Vector3 } from 'three';
import { SceneMode } from '../../..';
import { Tween, TweenType } from '../../../utils/Tween';

export class RotateToWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'rotate-to';

  public rotationVector = new Vector3();
  public rotatingTo: boolean = false;
  public rotationTime: number = 1;
  public tweenType: TweenType = TweenType.Linear;
  private rotateTime: number = 0;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Rotation To';

    this.widgetType = RotateToWidget.type;
    this.widgetName = 'Rotation To';
    this.selectable = true;
    this.events = [
      {
        label: 'Rotation Started',
        name: 'rotation-started',
      },
      {
        label: 'Rotation Finished',
        name: 'rotation-finished',
      },
    ];

    this.category = 'Animation';

    this.addService('Start Rotate', 'start-rotate', 'Starts rotating to the end rotation', () => {
      this.rotatingTo = true;
    });

    this.addService(
      'Start Reverse Rotate',
      'start-reverse-rotate',
      'Starts rotating back to the initial rotation',
      () => {
        this.rotatingTo = false;
      },
    );
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'rotationVector',
        'Rotate Target',
        'Core',
        'vec3',
        'vector3',
        true,
        undefined,
        undefined,
        undefined,
        'Setting this number sets the number of times the widget spins a full 360 degrees on its axis.',
      ),
      this.createProperty(
        'rotatingTo',
        'Start Rotating on Load',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'By clicking this, you are enabling the starting and stopping of the widget rotation on load.',
      ),
      this.createProperty(
        'rotationTime',
        'Duration',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Duration of the rotation in seconds',
      ),
      this.createProperty(
        'tweenType',
        'Tween',
        'Core',
        'tween',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Tweening curve used for this rotation',
      ),
    ];
  }

  public rotate(delta: number): any {
    this.rotateTime += this.rotatingTo ? delta : -delta;
    if (this.rotateTime < 0) {
      this.rotateTime = 0;
    } else if (this.rotateTime > this.rotationTime) {
      this.rotateTime = this.rotationTime;
    }

    const percentage = this.rotateTime / this.rotationTime;

    const tweenPercentage = Tween.tween(this.tweenType, percentage);

    const degreeToRadians = Math.PI / 180.0;

    this.renderNode.setRotationFromEuler(
      new Euler(
        this.rotationVector.x * tweenPercentage * degreeToRadians,
        this.rotationVector.y * tweenPercentage * degreeToRadians,
        this.rotationVector.z * tweenPercentage * degreeToRadians,
      ),
    );
  }

  public update(delta: number): void {
    super.update(delta);
    if (this.scene.sceneMode === SceneMode.Editor) return;
    this.rotate(delta);
  }

  public serialize(): any {
    const result = super.serialize();
    //Set the data for the rotation here.
    result.rotationVector = this.rotationVector;
    result.rotationTime = this.rotationTime;
    result.rotatingTo = this.rotatingTo;
    result.tweenType = this.tweenType;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.rotationTime = data.rotationTime;
    this.rotationVector = data.rotationVector;
    this.rotatingTo = data.rotatingTo;
    this.tweenType = data.tweenType;
  }
}

ModuleService.Register(RotateToWidget.type, RotateToWidget);
