import { ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class TeleportWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'teleport';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = TeleportWidget.type;
    this.widgetName = 'Teleport';
    this.label = 'Teleport';
    this.selectable = true;
    this.events = [];

    this.addService('Teleport', 'teleport', 'Move camera to this widgets location', () => {
      console.warn('[WARNING] Not implemented browser side yet');
    });
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.renderNode.renderOrder = 3;

    return true;
  }
}

ModuleService.Register(TeleportWidget.type, TeleportWidget);
