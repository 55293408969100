import { ReactElement, useRef, useState, useEffect } from 'react'
import { TextureService } from "@proviz/api-services"
import { getAbsoluteUrl, } from '@proviz/proviz-sdk'

const blueSlash = getAbsoluteUrl('images/slash.svg');

interface Props {
    textureId?: string;
    setTextureId: (id: string) => void;
}

export default function SinglePanoramaUpload(props: Props): ReactElement {
    const { textureId } = props;
    const [texSrc, setTexSrc] = useState("")
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
    
        return () => { mounted.current = false; };
    }, []);
    
    useEffect(() => {
        async function updateTex() {
            if (textureId) {
                let tex = await TextureService.get(textureId);
                if(tex.file && tex.file.location) {
                    if(mounted.current) {
                        setTexSrc(tex.file?.location);
                    }
                }
            }
        }
        updateTex();
    })

    async function setTexture(e: any) {
        let file = e.target.files[0];
        const tex = await TextureService.uploadTexture('Albedo', file);
        props.setTextureId(tex.id);
        if(mounted.current) { 
            // this mounted use makes sense since we want to complete the
            // request even if the compoenent is unmounted but do not
            // want to update the UI

            setTexSrc(file);
        }
    }

    function uploadTexture() {
        if (hiddenFileInput.current instanceof HTMLInputElement) {
            hiddenFileInput.current?.click();
        }
    }

    return (
        <div className="panoramaUpload" >
            {texSrc ?
                <img className='textureThumbnail' src={texSrc} alt='' onClick={uploadTexture} /> :
                <img className='textureThumbnail' src={blueSlash} alt='' onClick={uploadTexture} />}
            {!texSrc}
            <input type="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={setTexture}
                style={{ display: 'none' }}
            />
        </div>
    )
}
