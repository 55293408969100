import { BaseWidget, getAbsoluteUrl, } from '@proviz/proviz-sdk';
import { WidgetIcons } from '../../../documentation/WidgetIcons';
import { useHover } from './useHover';

const infoIcon = getAbsoluteUrl('images/Other/Information.svg')

interface Props {
    widget: BaseWidget;
    create: (w: BaseWidget) => void;
    openModal: () => void;
}

export const WidgetCard = (props: Props) => {
    const { widget, create } = props;
    const { cardRef, setHover, hoverShow } = useHover();

    return <>

        <div className="widgetCard" 
                    ref={cardRef}
                    onMouseEnter={() => setHover(true)} 
                    onMouseLeave={() => setHover(false)}>
            <div className='widget-card-content'
                    onClick={() => create(widget)} >
                <div className='icon-container icon' style={{backgroundImage:`url('${WidgetIcons[widget.widgetType] || WidgetIcons['default']}')`}}> </div>
                <div className='widget-name'><p>{widget.widgetName}</p></div>
            </div>

            { hoverShow && <div className='widget-info'><img onClick={() => {
                props.openModal();
            }} src={infoIcon} /></div>}
        </div>

    </>
}
