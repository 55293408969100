import {  useState } from 'react'
import { APIModelFile, ModelService, ModelStats } from "@proviz/api-services";
import { StatsDisplay } from '../../common/StatsDisplay';
import Button from '../../common/button/Button';
import { userStore } from '../../../store';

interface Props {
    file: APIModelFile;
    modelId: string;
}

export const ModelFileEntry = (props: Props) => {
    const { file, modelId } = props;
    const user = userStore( s => s.user)
    const [stats, setStats] = useState<ModelStats | null>(null);
    const [statsError, setStatsError] = useState(false);

    const getStats = () => {
        // we use the fileId not the modelFileId
        ModelService.getStats(modelId, file.fileId)
            .then(setStats)
            .catch(() => setStatsError(true));
    }


    if (file.file.fileCompression !== "None") {
        return <></>;
    }
    return <>
        <div key={file.fileId} className="fileEntry">
            <p>{file.file.extension?.toUpperCase()}</p>
            <a href={file.file.location}>Download</a>
        </div>
        {user?.isSuperAdmin &&
            <div>
                {!stats && !statsError  &&
                    <Button type='primary' onClick={getStats}>Get Stats</Button>}
                {statsError && <p>We had an error calculating file stats.</p>}
                {stats &&
                    <StatsDisplay stats={stats} />
                }
            </div>
            }
        {file.additionalFiles?.map(f => 
            <div key={f.id} className='fileEntry'>
                <p>{f.displayName + ' ' + f.extension?.toUpperCase()}</p>
                <a href={f.location}>Download</a>
            </div>)
        }
    </>;
}
