import { ReactElement, ReactNode, useEffect } from "react";
import { APIFile, APISprite, APITexture } from "@proviz/api-services";

import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import UploadModal from "../upload/UploadModal";
import LoadingModal from "../loadingmodal/LoadingModal";
import { ISceneRecord, timeSinceUpdated } from "../../home/ThumbnailCard";
import SearchBar from "../search/SearchBar";
import ConditionalWrapper from "../ConditionalWrapper";
import Button from "../button/Button";
import UploadButton from "../button/UploadButton";
import { ResourceManagerStore } from "../../../store/ResourceStore";

const defaultThumbnail = getAbsoluteUrl("images/thumbnail.svg");
const blueSlash = getAbsoluteUrl("images/slash.svg");

const displayLenOptions = [20, 50, 100];
interface Props {
  children: ReactNode;
}

export default function FileSelect(props: Props): ReactElement<Props> {
  const page = ResourceManagerStore((s) => s.page);
  const setPage = ResourceManagerStore((s) => s.setPage);
  const totalPages = ResourceManagerStore((s) => s.totalPages);
  const handleDisplayQuantChange = ResourceManagerStore(
    (s) => s.handleDisplayQuantChange
  );
  const searchParams = ResourceManagerStore((s) => s.searchParams);
  const setSearchParams = ResourceManagerStore((s) => s.setSearchParams);
  const files = ResourceManagerStore((s) => s.files);
  const uploadFile = ResourceManagerStore((s) => s.uploadFile);
  const setUploadFile = ResourceManagerStore((s) => s.setUploadFile);
  const waitingText = ResourceManagerStore((s) => s.waitingText);
  const setWaitingText = ResourceManagerStore((s) => s.setWaitingText);
  const displayScenes = ResourceManagerStore((s) => s.displayScenes);
  const textures = ResourceManagerStore((s) => s.textures);
  const sprites = ResourceManagerStore((s) => s.sprites);
  const setUpdate = ResourceManagerStore((s) => s.setUpdate);
  const error = ResourceManagerStore((s) => s.error);
  const resourceType = ResourceManagerStore((s) => s.resourceType);
  const fileNotTexture = ResourceManagerStore((s) => s.fileNotTexture);
  const LoadService = ResourceManagerStore((s) => s.LoadService);
  const displayQuantity = ResourceManagerStore((s) => s.displayQuantity);
  const selectResourceCallback = ResourceManagerStore(
    (s) => s.selectResourceCallback
  );
  const setSelectResourceCallback = ResourceManagerStore(
    (s) => s.setSelectResourceCallback
  );
  const extensions = ResourceManagerStore((s) => s.extensions);
  const loading = ResourceManagerStore((s) => s.loading); 

  useEffect(() => {
    LoadService();
  }, [
    LoadService,
    page,
    searchParams,
    resourceType,
    displayQuantity,
    fileNotTexture,
  ]);

  const data = ResourceManagerStore() ?? { data: undefined };
  if (!data) {
    return <></>;
  }

  // const { page, setPage, totalPages, handleDisplayQuantChange,
  //     searchParams, setSearchParams, files, uploadFile, setUploadFile,
  //     waitingText, setWaitingText, displayScenes, textures,
  //     sprites, setUpdate, error, resourceType,
  //     fileNotTexture, selectResourceCallback,
  //     setSelectResourceCallback, extensions, loading } = data;

  const showModal = !!selectResourceCallback;
  const closeModal = () => setSelectResourceCallback(undefined);

  // only use internally
  const handleResourceSelectEvent = (id: string | null) => {
    closeModal();
    setPage(0);
    selectResourceCallback && selectResourceCallback(id);
  };

  let bodyContent;
  if (selectResourceCallback === null) {
    bodyContent = <></>;
  } else if (
    ![
      "Scene",
      "Texture",
      "Image",
      "Sprite",
      "AreaTarget",
      "ModelTarget",
    ].includes(resourceType) ||
    (resourceType === "Texture" && fileNotTexture)
  ) {
    bodyContent = files.map((f: APIFile) => {
      const thumbnailSrc = f.thumbnail?.thumbnail || blueSlash;
      return (
        <tr className="file" key={f.id}>
          <td>
            <img src={thumbnailSrc} alt={f.displayName || ""} />
          </td>
          <td>{f.displayName}</td>
          <td className="date-column">
            {new Date(f.created + "Z").toLocaleString()}
          </td>
          <td className="select-button-column">
            <Button
              onClick={() => handleResourceSelectEvent(f.id)}
              fullWidth={true}
            >
              Select
            </Button>
          </td>
        </tr>
      );
    });
  } else if (resourceType === "Scene") {
    bodyContent = displayScenes.map((s: ISceneRecord) => {
      const thumbnail = s.thumbnailFile
        ? s.thumbnailFile.location
        : defaultThumbnail;
      return (
        <tr className="file" key={s.id}>
          <td>
            <img
              src={thumbnail}
              alt={s.name}
              style={{ width: "150px", maxHeight: "100px" }}
            />
          </td>
          <td>{s.name}</td>
          <td className="date-column">{timeSinceUpdated(s.updated)}</td>
          <td className="select-button-column">
            <Button
              onClick={() => handleResourceSelectEvent(s.id)}
              fullWidth={true}
            >
              Select
            </Button>
          </td>
        </tr>
      );
    });
  } else if (resourceType === "Texture") {
    bodyContent = textures.map((t: APITexture) => {
      const thumbnailSrc = t.file?.thumbnail?.thumbnail || blueSlash;
      return (
        <tr className="file" key={t.id}>
          <td>
            <img className="tex-thumbnail" alt="" src={thumbnailSrc} />
          </td>
          <td>{t.file?.displayName}</td>
          <td className="date-column">
            {new Date(t.created + "Z").toLocaleString()}
          </td>
          <td className="select-button-column">
            <Button
              onClick={() => handleResourceSelectEvent(t.id)}
              fullWidth={true}
            >
              Select
            </Button>
          </td>
        </tr>
      );
    });
  } else if (resourceType === "Image") {
    bodyContent = files.map((f: APIFile) => {
      const thumbnailSrc = f.thumbnail?.thumbnail || blueSlash;
      return (
        <tr className="file" key={f.id}>
          <td>
            <img className="tex-thumbnail" alt="" src={thumbnailSrc} />
          </td>
          <td>{f.displayName}</td>
          <td className="date-column">
            {new Date(f.created + "Z").toLocaleString()}
          </td>
          <td className="select-button-column">
            <Button
              onClick={() => handleResourceSelectEvent(f.id)}
              fullWidth={true}
            >
              Select
            </Button>
          </td>
        </tr>
      );
    });
  } else if (resourceType === "Sprite") {
    bodyContent = sprites.map((s: APISprite) => {
      const thumbnail = s.file?.location ?? blueSlash;
      return (
        <tr className="file" key={s.id}>
          <td>
            <img className="tex-thumbnail" alt="" src={thumbnail} />
          </td>
          <td>{s.name}</td>
          <td className="date-column">
            {new Date(s.created + "Z").toLocaleString()}
          </td>
          <td className="select-button-column">
            <Button
              onClick={() => handleResourceSelectEvent(s.id)}
              fullWidth={true}
            >
              Select
            </Button>
          </td>
        </tr>
      );
    });
  }

  const pageChanged = (page: number) => {
    setPage(page);
  };

  return (
    <>
      {props.children}
      {showModal && (
        <>
          <div className="modal file-select">
            <div className="inner-container">
              {/* ConditionalWrapper is here to conditionally display the upload button.
                  A switch statement is not used because the UploadModal has children.  */}
              <ConditionalWrapper
                condition={!(resourceType === "Scene")}
                wrapper={(children) => (
                  <UploadModal
                    open={uploadFile}
                    setOpen={() => setUploadFile(true)}
                    close={() => setUploadFile(false)}
                    reload={() => setUpdate(true)}
                    setWaitingText={setWaitingText}
                    resourceType={resourceType}
                    extensionsOverride={extensions}
                  >
                    {children}
                  </UploadModal>
                )}
              >
                <div className="mode-select">
                  <div className="option active" onClick={closeModal}>
                    Resource
                  </div>
                </div>
                <div className="content">
                  <SearchBar
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    showPaging={true}
                    page={page}
                    totalPages={totalPages}
                    pageSet={pageChanged}
                    setDisplayMode={(state: boolean) => {}}
                    isFileSelect
                  >
                    <Button onClick={() => handleResourceSelectEvent(null)}>
                      {" "}
                      Set to None
                    </Button>
                    <div className="divider" />
                    {!(resourceType === "Scene") && (
                      <>
                        <UploadButton onClick={() => setUploadFile(true)} />
                        <div className="divider" />
                      </>
                    )}
                    <div className="item">
                      {(!["Scene", "Texture", "Sprite"].includes(
                        resourceType
                      ) ||
                        (resourceType === "Texture" && fileNotTexture)) && (
                        <>
                          {waitingText.length > 0 && (
                            <LoadingModal
                              close={() => setWaitingText("")}
                              text={waitingText}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="item">
                      <select onChange={handleDisplayQuantChange}>
                        {displayLenOptions.map((l: number) => (
                          <option key={l} value={l}>
                            {l}
                          </option>
                        ))}
                      </select>
                    </div>
                  </SearchBar>

                  <div className="files">
                    {loading && <div>Loading...</div>}
                    <table>
                      <tbody>
                        {error && (
                          <p>
                            Looks like we ran into an error with that request.
                          </p>
                        )}
                        {!error && bodyContent}
                      </tbody>
                    </table>
                  </div>
                </div>
              </ConditionalWrapper>
            </div>
          </div>
          <div className="modalOverlay" onClick={closeModal} />
        </>
      )}
    </>
  );
}
