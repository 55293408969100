import React, { useState } from 'react';
import { BaseWidget, BaseWidgetDataConnection, getAbsoluteUrl } from "@proviz/proviz-sdk";
import { CategoryRenderer } from './CategoryRenderer';
import { NodeJson } from './NodeJson';
import { WidgetInfoModal } from '../../../documentation/WidgetInfoModal';
import Button from '../../common/button/Button';
import CollapsibleHeaderIcon from '../../common/collapsiblegroup/CollapsibleHeader';
import { RemoveNode } from '../../../commands';
import { ManagerStore } from '../../../store';

const infoIcon = getAbsoluteUrl('images/Other/Information.svg')
interface Props {
    node: BaseWidget;
}

export default function PropertiesPanel(props: Props) {
    const sceneManager = ManagerStore( s => s.SceneManager ) 

    const { node } = props;

    const [, updateState] = useState<any>();
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [showConnections, setShowConnections] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const forceUpdate = React.useCallback(() => updateState({}), []);

    
    React.useEffect(() => {
        window.addEventListener("keydown", onBackspaceHandler);

        return () => window.removeEventListener("keydown", onBackspaceHandler);
      });

    const onBackspaceHandler = (event:any) => {
        if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') && event.key === 'Backspace') {
            return;
          }
        if (event.key === 'Backspace') {
            remove();
        }
      };
    
    // Deleting multiple nodes at once
    const remove = () => {
            sceneManager.selectedNodes.forEach(node => {
            sceneManager.execute(new RemoveNode(sceneManager, node))
            })
    }
    
    if (!node) {
        return (<div>
            <h3>Select a node to inspect its material.</h3>
        </div>);
    }

    const properties = node.getProperties();
    const categories: string[] = [];
    properties.forEach((p) => {
        const c = p.category;
        if (categories.indexOf(c) === -1) {
            categories.push(c);
        }
    });

    return <>
        <WidgetInfoModal widget={node} close={() => setShowHelpModal(false)} open={showHelpModal} />
        <div className="propertiesPanel">
            <div className='propertiesHeader'>
                <h3>Properties</h3>
                <img src={infoIcon} alt='' onClick={() => setShowHelpModal(true)}
                    className='infoButton'></img>
            </div>
            {categories.map((cat) => {
                return <CategoryRenderer key={cat} category={cat}
                    properties={properties.filter(x => x.category === cat)}
                    node={node}
                    manager={sceneManager}
                />;
            })}
            <div>
                <div className="collapsible-properties-header">
                    <h5>Connections</h5>
                    <CollapsibleHeaderIcon handleClick={() => setShowConnections(!showConnections)} expanded={showConnections} />
                </div>
                {showConnections && (node.connections.length > 0 
                    ? <div className="event-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Widget</th>
                                    <th>Service</th>
                                    <th>Remove</th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {node.connections.map((c: BaseWidgetDataConnection, ind: number) => <tr key={c.targetId + c.targetService + c.event}>
                                    <td>{c.event}</td>
                                    <td>{sceneManager.proVizScene.getById(c.targetId)?.label}</td>
                                    <td>{c.targetService}</td>
                                    <td>
                                        <Button type='icon' onClick={() => {
                                            node.connections.splice(ind, 1);
                                            sceneManager.dispatchWidgetUpdate();
                                            forceUpdate();
                                        }}>X</Button>
                                    </td>
                                    <td>
                                        {ind !== 0 && <Button type='icon' onClick={() => {
                                            const prev = node.connections[ind - 1];
                                            node.connections[ind - 1] = c;
                                            node.connections[ind] = prev;
                                            sceneManager.dispatchWidgetUpdate();
                                            forceUpdate();
                                        }}>Up</Button>}
                                        
                                        {ind !== node.connections.length - 1 && <Button type='icon' onClick={() => {
                                            const next = node.connections[ind + 1];
                                            node.connections[ind + 1] = c;
                                            node.connections[ind] = next;
                                            sceneManager.dispatchWidgetUpdate();
                                            forceUpdate();
                                        }}>Down</Button>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    : <p>No Connections</p>)}
            </div>
            <div>
                <div className="collapsible-properties-header">
                    <h5>Advanced</h5>
                    <CollapsibleHeaderIcon handleClick={() => setShowAdvanced(!showAdvanced)} expanded={showAdvanced} />
                </div>
            </div>
            {showAdvanced && node.widgetType !== 'custom' && <NodeJson node={node} />}
            <div className="removeButton">
                <Button type='danger' fullWidth={true} onClick={remove}>Remove</Button>
            </div>
        </div>
    </>;
}
