import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { searchStore } from "../../store/SearchStore";
import AuthButton from "../common/auth/AuthButton";
import { CompanySelector } from "../common/companyselector/CompanySelector";
import SearchButton from "../common/search/SearchButton";
import { createAvatar } from "@dicebear/avatars";
import * as avatarStyle from "@dicebear/avatars-avataaars-sprites";
import {
  APIUser,
  PermissionEntity,
  PermissionType,
} from "@proviz/api-services";
import { useState } from "react";
import Button from "../common/button/Button";
import { userStore } from "../../store";
import { useAuth0 } from "@auth0/auth0-react";

const IconButton = styled.div`
  width: 5rem;
  position: relative;
  a {
    cursor: pointer;
    color: #313e64;
    text-decoration: none;
    display: inline-block;
    margin: 1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 3rem;
    background: #ecf0f8;
    position: relative;
    img {
      border: 0;
      padding: 0;
      margin: 0;
      position: relative;
      border-radius: 50rem;
    }
  }
  span {
    width: 100%;
    font-size: 1.5rem;
    padding: 0.25rem;
    display: block;
    cursor: pointer;
  }
`;

const MenuContainer = styled.div`
  width: 5rem;
  position: relative;
`;

const StyledAccountMenu = styled.div`
  position: absolute;
  right: 2rem;
  top: 4rem;
  background: #fff;
  width: 20rem;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 15%);
  border-radius: 0.25rem;
  border: 1px solid #ecf0f8;
  .padded {
    padding: 0 2rem;
  }
  a {
    display: block;
    margin: 0.25rem 0;
    text-decoration: none;
    padding: 0.5rem 2rem;
    &:hover {
      background: #eee;
    }
  }
  .user-info {
    text-align: center;
    img {
      border-radius: 50rem;
      width: 10rem;
    }
    .name-email {
      margin: 0.5rem 0;
    }
    .email {
      font-weight: normal;
      color: #aaa;
    }
  }
  hr {
    background: #ccc;
    border: 0;
    margin: 1rem 0;
    height: 1px;
  }

  .account-avatar:hover {
    opacity: 0.5;
    border-radius: 10%;

    .edit-avatar-text {
      display: block;
      position: absolute;
      margin-top: -6.6rem;
      margin-left: 4.4rem;
      color: #fff;
    }
  }

  .edit-avatar-text {
    display: none;
  }
`;

function AccountMenu() {
  const allowedBackURL = process.env.REACT_APP_AUTH0_ALLOWED_LOGOUT_URL;

  const user = userStore((s) => s.user);

  const { logout } = useAuth0();

  const hasPermission = userStore((s) => s.hasPermission);

  const accountIcon = createAvatar(avatarStyle, {
    seed: user ? (user as APIUser).avatar || (user as APIUser).email : "",
    dataUri: true,
    // ... and other options
  });

  return (
    <StyledAccountMenu>
      <br />
      <div className="padded">
        <div className="user-info">
          <NavLink to={"/home/settings/avatar"} className="account-avatar">
            <img src={accountIcon} alt="icon" />
            <h1 className="edit-avatar-text">EDIT</h1>
          </NavLink>
          <div className="name-email">
            <div className="name">
              {user?.firstName} {user?.lastName}
            </div>
            <div className="email">{user?.email}</div>
          </div>
        </div>
        <hr />
      </div>
      <NavLink to={"/home/settings/account"}>Account Settings</NavLink>
      {hasPermission(PermissionEntity.User, PermissionType.View) && (
        <NavLink to={"/home/settings/users"}>Users</NavLink>
      )}
      {hasPermission(PermissionEntity.Company, PermissionType.View) && (
        <NavLink to={"/home/settings/organizations"}>Organizations</NavLink>
      )}
      {user?.isSuperAdmin && (
        <NavLink to={"/home/settings/tags"}>Tag Management</NavLink>
      )}
      {hasPermission(PermissionEntity.MachineKey, PermissionType.View) && (
        <NavLink to={"/home/settings/machinekeys"}>Machine Keys</NavLink>
      )}
      {hasPermission(PermissionEntity.MachineKey, PermissionType.View) && (
        <NavLink to={"/home/settings/userkeys"}>User Keys</NavLink>
      )}
      {user?.isSuperAdmin && (
        <NavLink to={"/home/settings/roles"}>Roles</NavLink>
      )}
      {user?.isSuperAdmin && (
        <NavLink to={"/home/settings/licenses"}>Licenses</NavLink>
      )}

      <div className="padded">
        <hr />
        <div className="user-info">
          <Button
            fullWidth
            onClick={() => logout({ returnTo: allowedBackURL })}
          >
            Logout
          </Button>
        </div>
        <br />
      </div>
    </StyledAccountMenu>
  );
}

export default function PageHeader() {
  const setSearch = searchStore((x) => x.setSearch);
  const user = userStore((s) => s.user);
  const [showMenu, setShowMenu] = useState(false);

  const accountIcon = createAvatar(avatarStyle, {
    seed: user ? (user as APIUser).avatar || (user as APIUser).email : "",
    dataUri: true,
    // ... and other options
  });

  return (
    <nav className="headerBar">
      <div className="buttonsContainer">
        <SearchButton setSearchParams={(search: string) => setSearch(search)} />
      </div>
      <div className="buttonsContainer">
        <CompanySelector />
        <AuthButton />
        <MenuContainer>
          <IconButton>
            <a onClick={() => setShowMenu(!showMenu)}>
              <img src={accountIcon} />
            </a>
          </IconButton>
          {showMenu && <AccountMenu />}
        </MenuContainer>
      </div>
    </nav>
  );
}
