import { APIModelVariant } from "@proviz/api-services";

interface Props {
    variants: APIModelVariant[] | null;
    materialSetId: string;
    setSelection: (v: APIModelVariant | null) => void;
    defaultVariantId: string;
    setDefaultVariant: (variantId: string) => void;
    deleteModelVariant: (variant: APIModelVariant) => Promise<void>;
}

export const VariantSelector = (props: Props) => {
    const { defaultVariantId, materialSetId, setDefaultVariant, setSelection,
        variants, deleteModelVariant } = props;


    function handleVariantClick(materialSetId: string) {
        if (materialSetId === '' || !variants) {
            setSelection(null);
            return;
        }
        let v = variants.find((v) => v.materialSetId === materialSetId);
        setSelection(v ?? null);
    }

    return <ul className="variantSelector">
        {variants && variants.map((v, idx) => {
            const selected = v.materialSetId === materialSetId;
            const isDefault = defaultVariantId === v.id;
            return <li
                key={idx}
                value={v.materialSetId}
                onClick={() => handleVariantClick(v.materialSetId)}
                className={`variantSelectorEntry ${selected ? 'selected' : ''}`}
            >
                <span aria-hidden="true" title="Set Default Variant"
                            className={`${isDefault ? 'icon-yellow_star ' : 'icon-outlined_star'}`}
                            onClick={isDefault ? () => { } : () => setDefaultVariant(v.id)}
                        ></span>
                <p>{v.materialSet && v.materialSet.name}</p>
                <div className='buttonContainer'>
                    <span className='icon-ProViz-Studio-Assets-12' onClick={() => deleteModelVariant(v)}
                        aria-hidden="true" />
                </div>
            </li>
        })}
    </ul>
}
