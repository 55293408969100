import create from "zustand";
import SceneManager from '../graphics/SceneManager';
import ModelViewManager from "../graphics/ModelViewManager";
import GraphicsManager from "../graphics/GraphicsManager";

/********************************************************************* TYPES */
type ManagerStoreTypes = {
  ModelViewManager: ( ModelViewManager );
  setModelViewManager: (ModelViewManager: ModelViewManager) => void;
  SceneManager: ( SceneManager );
  setSceneManager: (SceneManager: SceneManager) => void;
  GraphicsManager: ( GraphicsManager );
  setGraphicsContext: (GraphicsContext: GraphicsManager) => void;
};

/********************************************************************* STORE */
export const ManagerStore = create<ManagerStoreTypes>((set) => ({
  ModelViewManager: {} as ModelViewManager,
  setModelViewManager: (ModelViewManager) => set(() => ({ModelViewManager, GraphicsManager: ModelViewManager })),
  SceneManager: {} as SceneManager,
  setSceneManager: (SceneManager) => set(() => ({SceneManager, GraphicsManager: SceneManager })),
  GraphicsManager: {} as GraphicsManager,
  setGraphicsContext: (GraphicsManager) => set(() => ({ GraphicsManager })),
}));