import { DataSchemaService } from '@proviz/api-services';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../common/button/Button';
import Input from '../../common/input/input';
import PageContentHeader from '../pageContentHeader';

const defaultSchema = `{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "$id": "https://example.com/product.schema.json",
    "title": "ProVizXR Schema Spec",
    "description": "A ProVizXR data schema",
    "type": "object",
    "properties": { 

    },
    "required": [ ]
}`;


const JSONArea = styled.textarea`
    width: 100%;
    height: 200pt;
`;

const InputBlock = styled.div`
    margin: 1rem 0;
`;

const ErrorBlock = styled.div`
    color: #f00;
`;

export function SchemasCreateView() {
    const history = useHistory();

    const [ schema, setSchema ] = useState(defaultSchema);
    const [ schemaName, setSchemaName ] = useState('');
    const [ schemaColl, setSchemaColl ] = useState('');
    const [ schemaRead, setSchemaRead ] = useState(false);
    const [ schemaWrite, setSchemaWrite ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ errors, setErrors ] = useState<string[]>([]);

    const createSchema = async () => {
        const err: string[] = [];
        if (schemaName.length === 0) {
            err.push('Schema Name required');
        }
        if (schemaColl.length === 0) {
            err.push('Schema Collection required');
        }

        if (err.length === 0) {
            setSaving(true);
            await DataSchemaService.create({
                name: schemaName,
                collection: schemaColl,
                read: schemaRead,
                write: schemaWrite,
                schema
            });
            history.push('/home/resources/schemas');
        }

        setErrors(err);
        setSaving(false);
    }
    
    return <>
        <PageContentHeader title="Create Schema" />
        <a href="https://json-schema.org/specification.html" target="_blank">JSON Specification</a>
        <InputBlock>
            <label>Name</label>
            <Input className='full' onChange={(val) => setSchemaName(val)} />
        </InputBlock>
        <InputBlock>
            <label>Collection</label>
            <Input className='full' value={schemaColl} onChange={setSchemaColl} />
        </InputBlock>
        <InputBlock>
            <label>Require User for Read</label>
            <input type="checkbox" checked={schemaRead} onChange={(e) => {
                setSchemaRead(e.target.checked)} 
            } />
        </InputBlock>
        <InputBlock>
            <label>Require User for Write</label>
            <input type="checkbox" checked={schemaWrite} onChange={(e) => {
                setSchemaWrite(e.target.checked)} 
            }/>
        </InputBlock>
        <JSONArea onChange={(e) => setSchema(e.target.value)} value={schema} />
        {errors.map(e => <ErrorBlock>{e}</ErrorBlock>)}
        <Button disabled={saving} onClick={createSchema}>{saving ? 'Saving...' : 'Save'}</Button>
    </>;
}
