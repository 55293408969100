import { ReactElement } from 'react'
import { Layout } from '../../../graphics/SceneManager'
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const flowIcon = getAbsoluteUrl('images/Other/Flow Editor.svg');
const modelEditorIcon = getAbsoluteUrl('images/ModelDepthIcon.svg');

interface Props {
    layout: Layout;
    setLayout: (l: Layout) => void;
}

export default function LayoutButton(props: Props): ReactElement {
    const { layout, setLayout } = props;
    let src, fn;
    if (layout === Layout.Split) {
        src = modelEditorIcon
        fn = () => setLayout(Layout.EditorOnly)
    } else if (layout === Layout.EditorOnly) {
        src = flowIcon;
        fn = () => setLayout(Layout.Split);
    }

    return <div className='iconContainer'>
        <img className="icon"
            onClick={fn}
            src={src}
            alt="Toggle editor layout."
            title="Toggle editor layout." />
    </div>;
}
