import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class SwitchWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'switch';

  // Data
  public initialValue: string = '';
  public options: string = '';

  private value: string | number = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Switch';

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = SwitchWidget.type;
    this.widgetName = 'Switch';
    this.selectable = true;
    this.events = [];
    this.services = [
      {
        label: 'Set Value',
        name: 'set-value',
        desc: '<b>Sets the value of this widget</b><br/><i>Will re-trigger if the same value is set again.</i>',
      },
    ];
    this.addEventListener('service-set-value', (event: ProVizEventData) => {
      if (event.dataType === 'number') {
        this.value = event.data as number;
      } else if (event.dataType === 'string') {
        this.value = event.data as string;
      } else {
        console.error('Tried setting a non string/number value on switch widget');
        return;
      }

      const eventKey = `value-change-${this.value}`;
      const valueType = typeof this.value === 'string' ? 'string' : 'number';
      const e = this.events.find((x) => x.name === eventKey);
      if (e) {
        this.triggerProVizEvent(eventKey, valueType, this.value);
      } else {
        this.triggerProVizEvent('default', 'none');
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('initialValue', 'Initial Value', 'Core', 'bool', 'boolean', true),
      this.createProperty(
        'options',
        'Options',
        'Data',
        'options',
        'string',
        true,
        () => this.options,
        (value: string) => {
          this.options = value;
          this.events = [];
          this.setupEvents();
        },
      ),
    ];
  }

  private setupEvents() {
    this.events = [
      {
        name: 'default',
        label: 'Default',
        desc: 'If no match is found, the default case will emit',
      },
    ];

    const availableValues = (this.options || '').split(',');
    availableValues.forEach((val: string) => {
      const keyVal = val.split(':');
      if (keyVal.length === 2) {
        this.events.push({
          label: keyVal[0],
          name: `value-change-${keyVal[0]}`,
          data: keyVal[1],
          desc: keyVal[1],
        });
      } else {
        this.events.push({
          label: val,
          name: `value-change-${val}`,
          desc: val,
        });
      }
    });
  }

  public serialize(): any {
    const result = super.serialize();
    result.initialValue = this.initialValue;
    result.options = this.options;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.initialValue = data.initialValue;
    this.options = data.options;
    this.setupEvents();
  }
}

ModuleService.Register(SwitchWidget.type, SwitchWidget);
