import { APIFolder, FolderService, FolderType } from '@proviz/api-services';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement, useState } from 'react';
import Modal from '../common/modals/Modals';

interface Props {
    folder: APIFolder | null;
    folderType: FolderType;
    close: () => void;
}

export default function FolderNewModal(props: Props): ReactElement {
    const { close, folderType, folder } = props;
    
    const queryClient = useQueryClient()

    const [folderName, setFolderName] = useState('');

    return <Modal
        title="New Folder"
        close={close}
    >
        <input type="text" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
        <button onClick={() => {
            FolderService.create(folderName, folderType, folder?.id || null).then(() => {
                queryClient.invalidateQueries([ `folders-${props.folderType}`, folder?.id]);
                close();
            });
        }}>Create</button>
    </Modal>
}
