import {
  BackSide,
  Color,
  DoubleSide,
  FrontSide,
  Group,
  LoadingManager,
  Material,
  Mesh,
  Object3D,
  PMREMGenerator,
  Side,
  TangentSpaceNormalMap,
  Texture,
  TextureLoader,
  Vector2,
  WebGLRenderer,
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import {
  APIMaterialParameter,
  APIMaterialSet,
  APIModel,
  APIModelVariant,
  FileService,
  getParam,
} from '@proviz/api-services';
import { isColor } from '.';
import { decodeWrapping, Deferred, getAbsoluteUrl } from '.';

export type EnvMapAndSkybox = {
  envMap: Texture;
  skybox: Texture | null;
};

export default class ProVizLoader {
  private static manager: LoadingManager;
  private static gltfLoader?: GLTFLoader;
  private static dracoLoader?: DRACOLoader;
  private static textureLoader?: TextureLoader;
  private static rgbeLoader?: RGBELoader; // For HDR Images
  private static pmremGenerator?: PMREMGenerator;

  constructor() {
    if (!ProVizLoader.manager) {
      ProVizLoader.manager = new LoadingManager();
      ProVizLoader.gltfLoader = new GLTFLoader(ProVizLoader.manager);
      ProVizLoader.textureLoader = new TextureLoader(ProVizLoader.manager);
    }
  }
  public async LoadModel(model: APIModel, prefer?: 'gltf' | 'glb'): Promise<Group> {
    const { gltf, glb } = ProVizLoader.GetFiles(model);
    if (!(gltf || glb)) {
      throw new Error('No model file could be found');
    }
    if (prefer === glb && glb) {
      return await this.LoadGLTF(glb);
    } else if (gltf) {
      return await this.LoadGLTF(gltf);
    }
    throw new Error('Invalid model');
  }
  public LoadGLTF(file: string, onProgress?: (event: ProgressEvent) => void): Promise<Group> {
    let defer = new Deferred<Group>();
    if (!ProVizLoader.gltfLoader) {
      ProVizLoader.gltfLoader = new GLTFLoader(ProVizLoader.manager);
    }
    ProVizLoader.gltfLoader.load(
      file,
      (gltf: GLTF) => {
        gltf.scene.animations = gltf.animations;
        defer.resolve(gltf.scene);
      },
      onProgress,
      (event: ErrorEvent) => {
        if (event.message === 'THREE.GLTFLoader: No DRACOLoader instance provided.') {
          // if the model uses draco compression we set the draco loader decode
          // path to our local copy of the decoder files then set the
          // dracoloader on the gltfloader and try loading the model again
          // if this fails and the dracoloader already exists then we reject
          if (ProVizLoader.dracoLoader) {
            console.error(`%cDraco decoding failed ${event.message}`, 'color: red;');
            return defer.reject();
          }
          console.error('%cDownloading draco decoder', 'color: yellowgreen;');
          ProVizLoader.dracoLoader = new DRACOLoader();
          ProVizLoader.dracoLoader.setDecoderPath(getAbsoluteUrl('decoder/'));
          ProVizLoader.gltfLoader?.setDRACOLoader(ProVizLoader.dracoLoader);
          this.LoadGLTF(file)
            .then((g) => defer.resolve(g))
            .catch(() => defer.reject());
        } else {
          console.error(`%cModel load failed ${event.message}`, 'color: red;');
          defer.reject();
        }
      },
    );
    return defer.promise;
  }

  public static async LoadTexture(location: string): Promise<Texture> {
    return new Promise((resolve, reject) => {
      if (!ProVizLoader.textureLoader) {
        ProVizLoader.textureLoader = new TextureLoader(ProVizLoader.manager);
      }
      ProVizLoader.textureLoader.load(location, resolve, undefined, reject);
    });
  }

  public static getCubeMapTexture(path: string, renderer: WebGLRenderer): Promise<Texture> {
    return new Promise((resolve, reject) => {
      if (!ProVizLoader.pmremGenerator) {
        ProVizLoader.pmremGenerator = new PMREMGenerator(renderer);
        // Pre-compiles the equirectangular shader. We can get faster start-up
        // by invoking this method during our texture's network fetch for increased concurrency.
        ProVizLoader.pmremGenerator.compileEquirectangularShader();
      }
      if (!ProVizLoader.rgbeLoader) {
        ProVizLoader.rgbeLoader = new RGBELoader();
        // Post r135 rgbeloader defaults to HalfFloatType - seems to work fine now that UnsignedByteType has been removed
        // HDR Workflows with Webgl 1 require the float texture extension support
      }
      ProVizLoader.rgbeLoader.load(
        path,
        (texture) => {
          // From three.js:
          // the ideal input image size is 1k (1024 x 512), as this matches best with the
          // 256 x 256 cubemap output.
          const envMap = ProVizLoader.pmremGenerator!.fromEquirectangular(texture).texture;
          resolve(envMap);
        },
        undefined,
        reject,
      );
    });
  }

  public static SetOpacity(obj: Object3D, opacity: number = 1.0) {
    if (obj instanceof Mesh) {
      const m: Material = obj.material;
      m.transparent = opacity < 1.0;
      m.opacity = opacity;
      m.needsUpdate = true;
    }
    obj.children.map((o) => ProVizLoader.SetOpacity(o, opacity));
  }

  public static async ApplyMaterialSet(
    obj: Object3D,
    materialSet: APIMaterialSet,
    opacity: number = 1.0,
    options?: { depthTest?: boolean, depthWrite?: boolean, side?: Side }
  ) {
    if (obj instanceof Mesh) {
      const m: Material = obj.material;
      m.transparent = opacity < 1.0;
      m.opacity = opacity;
      m.depthTest = options?.depthTest ?? true;
      m.depthWrite = options?.depthWrite ?? opacity === 1.0;
      if (options?.side !== undefined) {
        m.side = options?.side;
      }
      console.log(options);
      m.needsUpdate = true;
      try {
        if (materialSet.materials) {
          const apiMat = materialSet.materials.find((mat) => mat.name === m.name);
          if (apiMat && apiMat.materialParameters && apiMat.materialParameters.length > 0) {
            await ProVizLoader.applyMaterialParameters(
              obj,
              apiMat.materialParameters,
              materialSet.flipY,
            );
            if (options?.side != undefined) {
              m.side = options?.side;
            }
          } else {
            obj.material.map = null;
          }
        } else {
          obj.material.map = null;
        }
        obj.material.needsUpdate = true;
      } catch (e) {
        console.error(`This variant wasn't set up entirely correctly: ${m.name} ${e}`);
      }
    }
    await Promise.all(obj.children.map((o) => this.ApplyMaterialSet(o, materialSet, opacity, options)));
  }

  static async applyMaterialParameters(
    obj: Mesh<any, any>,
    materialParameters: APIMaterialParameter[],
    flipY = false,
  ) {
    let wrapTVal, wrapSVal;
    const wrapSParam = getParam(materialParameters, 'WrapS');
    if (wrapSParam) {
      wrapSVal = decodeWrapping(wrapSParam);
    }
    const wrapTParam = getParam(materialParameters, 'WrapT');
    if (wrapTParam) {
      wrapTVal = decodeWrapping(wrapTParam);
    }

    const albedoParam = getParam(materialParameters, 'AlbedoTexture');
    if (albedoParam) {
      const baseTexture = albedoParam.texture;
      if (baseTexture && baseTexture.file && baseTexture.file.location) {
        obj.material.map = await ProVizLoader.LoadTexture(
          FileService.getLocation(baseTexture.file),
        );
        if (wrapSVal) {
          obj.material.map.wrapS = wrapSVal;
        }
        if (wrapTVal) {
          obj.material.map.wrapT = wrapTVal;
        }
        obj.material.map.flipY = flipY;
      } else {
        obj.material.map = null;
      }
    } else {
      obj.material.map = null;
    }

    const roughParam = getParam(materialParameters, 'Roughness');
    if (roughParam && obj.material.roughness) {
      obj.material.roughness = roughParam.floatValue;
    }

    const metalParam = getParam(materialParameters, 'Metalness');
    if (metalParam && obj.material.metalness) {
      obj.material.metalness = metalParam.floatValue;
      if (metalParam.texture?.file) {
        // metalness and roughness maps are the same
        const metalnessMap = await ProVizLoader.LoadTexture(
          FileService.getLocation(metalParam.texture.file),
        );
        metalnessMap.flipY = flipY;
        obj.material.metalnessMap = metalnessMap;
        obj.material.roughnessMap = metalnessMap;
        if (wrapSVal) {
          obj.material.metalnessMap.wrapS = wrapSVal;
          obj.material.roughnessMap.wrapS = wrapSVal;
        }
        if (wrapTVal) {
          obj.material.metalnessMap.wrapT = wrapTVal;
          obj.material.roughnessMap.wrapT = wrapTVal;
        }
      }
    }

    const emissiveParam = getParam(materialParameters, 'Emissive');
    if (emissiveParam) {
      // we check this way becasue want to use the value if it is 0
      if (emissiveParam.floatValue ?? null) {
        obj.material.emissiveIntensity = emissiveParam.floatValue;
      }
      if (emissiveParam.texture?.file) {
        obj.material.emmisiveMap = await ProVizLoader.LoadTexture(
          FileService.getLocation(emissiveParam.texture.file),
        );
        obj.material.emmisiveMap.flipY;
      }
    }

    const normalParam = getParam(materialParameters, 'Normal');
    if (normalParam) {
      if (normalParam.texture?.file) {
        const location = FileService.getLocation(normalParam.texture.file);
        obj.material.normalMap = await ProVizLoader.LoadTexture(location);
        obj.material.normalMap.flipY = flipY;
        obj.material.normalMapType = TangentSpaceNormalMap; // FROM Three.js GLTFLoader line 1447
        const scale = normalParam.floatValue ?? 1.0;
        obj.material.normalScale = new Vector2(scale, -scale);
        if (wrapSVal) {
          obj.material.normalMap.wrapS = wrapSVal;
        }
        if (wrapTVal) {
          obj.material.normalMap.wrapT = wrapTVal;
        }
      }
    }

    const occlusionParam = getParam(materialParameters, 'Occlusion');
    if (occlusionParam) {
      if (occlusionParam.texture?.file) {
        const location = FileService.getLocation(occlusionParam.texture.file);
        // The red channel of this texture is used as the ambient occlusion map. Default is null. The aoMap requires a second set of UVs.
        obj.material.aoMap = await ProVizLoader.LoadTexture(location);
        obj.material.aoMap.flipY = flipY;
        obj.material.aoMapIntensity = occlusionParam.floatValue;
        if (wrapSVal) {
          obj.material.aoMap.wrapS = wrapSVal;
        }
        if (wrapTVal) {
          obj.material.aoMap.wrapT = wrapTVal;
        }
      }
    }

    // Emissive factor is only applied to materials that allow
    // for emissive
    const emissiveFactorParam = getParam(materialParameters, 'EmissiveFactor');
    if (
      emissiveFactorParam &&
      emissiveFactorParam.stringValue &&
      obj.material.emissive &&
      isColor(emissiveFactorParam.stringValue)
    ) {
      const v = emissiveFactorParam.stringValue;
      const color = new Color();
      color.set(v.length > 7 ? v.substring(0, 7) : v);
      // if all emissive parameters are 0 do not apply
      // this is also the behavior of the three loader
      if (!(color.r === 0 && color.g === 0 && color.b === 0)) {
        obj.material.emissive = color;
      }
    }

    const colorParam = getParam(materialParameters, 'Color');
    if (
      colorParam &&
      obj.material.color
      // && isColor(colorParam.stringValue)
    ) {
      const v = colorParam.stringValue ?? '';
      // drop alpha component of color
      if (v.length === 9) {
        const alphaStr = v.substring(7, 9);
        const parsedAlpha = parseInt(alphaStr, 16) / 256;
        obj.material.opacity = parsedAlpha;
      }
      // # + first 6 characters of color === 7 characters
      const colorStr = v.length > 7 ? v.substring(0, 7) : v;
      obj.material.color.set(colorStr);
    }

    // const specularParam = getParam(materialParameters, 'Specular');
    // if (specularParam) {
    //   obj.material.specular = new Color(specularParam.stringValue);
    // }

    const shinyParam = getParam(materialParameters, 'Shininess');
    if (shinyParam) {
      obj.material.shininess = shinyParam.floatValue; // specular exponent
    }

    const sideParam = getParam(materialParameters, 'Side');
    if (sideParam) {
      let val = sideParam.stringValue;
      if (val === 'Double') {
        obj.material.side = DoubleSide;
      } else if (val === 'Back') {
        obj.material.side = BackSide;
      } else if (val === 'Front') {
        obj.material.side = FrontSide;
      }
    }
    const alphaParam = getParam(materialParameters, 'AlphaBlendMode');
    if (alphaParam) {
      let val = alphaParam.stringValue;
      if (val === 'BLEND') {
        obj.material.transparent = true; // See: https://github.com/mrdoob/three.js/issues/17706
        obj.material.depthWrite = false;
        obj.material.needsUpdate = true;
      } else {
        obj.material.transparent = false;
        obj.material.depthWrite = true;
        obj.material.needsUpdate = true;
        if (val === 'MASK') {
          obj.material.alphaTest = obj.material.alphaText ? obj.material.alphaTest : 0.5;
          // below is what threejs would do.
          // materialDef.alphaCutoff !== undefined ? materialDef.alphaCutoff : 0.5;
        }
      }
    }
  }
  // This has been moved from the model widget to here, though I'm not entirely sold that this
  // is the best home for it. Best alternative I see now is in the api-services
  // package in APIModel definition.
  public static GetFiles(model: APIModel, variant?: APIModelVariant) {
    let glb: string | undefined = undefined;
    let gltf: string | undefined = undefined;
    let usdz: string | undefined = undefined;
    let updateMaterials = true;

    if (variant && variant.modelVariantFile) {
      let mvFiles = variant.modelVariantFile;
      // we prefer to set the source file as the glb file associated either
      // with the variant set by the url query parameter or other method
      // or the default variant. We need not call applymaterialset because
      // the materialset will already be encoded in the glb file.
      if (mvFiles.glbFile) {
        glb = mvFiles.glbFile.location;
        updateMaterials = false;
      } else {
        // if a glb file does not exist we set the modelviewers to the gltf related
        // to the mode. This may not have the correct material set encode on it
        // so we apply the material set once it loads.
        const gltfArray = model.modelFiles?.filter(
          (f: any) => f.file.extension.includes('gltf') || f.file.extension.includes('glb'),
        );
        if (gltfArray) gltf = gltfArray[0].file?.location;
      }

      // if a usdz file exists on the variant -- sometimes this
      // conversion fails and if it does we attempt to see if a usdz
      // file has been uploaded as a modelfile then we use it as the
      // ios source instead. This source will only be used if the user
      // opens the model in AR.
      if (mvFiles.usdzFile) {
        usdz = mvFiles.usdzFile.location;
      } else {
        const usdzArray = model.modelFiles?.filter((f: any) => f.file.extension.includes('usdz'));
        if (usdzArray && usdzArray.length > 0) usdz = usdzArray[0].file.location;
      }
    } else if (model.gltfFile) {
      gltf = model.gltfFile.file.location;
      updateMaterials = true;
    } else {
      // If no variant id is set, ( this should not happen ) we search for glb or gltf
      // files on the model, and a usdz for apple quick look. This may be a case where
      // simply showing an error should be desired behavior.
      let gltfArray = model.modelFiles?.filter((f: any) => f.file.extension.includes('gltf'));
      if (gltfArray && gltfArray.length > 0) gltf = gltfArray[0].file?.location;

      let glbArray = model.modelFiles?.filter((f: any) => f.file.extension.includes('glb'));
      if (glbArray && glbArray.length > 0) glb = glbArray[0].file?.location;

      const usdzArray = model.modelFiles?.filter((f: any) => f.file.extension.includes('usdz'));
      if (usdzArray && usdzArray.length > 0) usdz = usdzArray[0].file?.location;
    }
    return { glb, gltf, usdz, updateMaterials };
  }
}
