import { ReactElement, useEffect, useState } from "react";
import {
  ModelService,
  ProVizConfig,
  APIModel,
  Paged,
  APIFolder,
  AnalyticService,
} from "@proviz/api-services";
import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import ThumbnailCard from "./ThumbnailCard";
import ModelInfoModal from "./ModelInfoModal";
import UploadModal from "../common/upload/UploadModal";
import LoadingModal from "../common/loadingmodal/LoadingModal";
import ModelStatusModal from "./statusmodal/ModelStatusModal";
import UploadButton from "../common/button/UploadButton";
import GlobalExportMenu from "./GlobalExportMenu";
// import TagSelector from './TagSelector';
import Button from "../common/button/Button";
import FoldersList from "./FoldersList";
import FolderNewModal from "./FolderNewModal";
import InfoIcon from "./InfoIcon";
import PagingControl from "../common/paging/PagingControl";
import { searchStore } from "../../store/SearchStore";
import PageHeader from "./pageHeader";
import PageContentHeader from "./pageContentHeader";
import PaddedContainer from "../common/containers/paddedContainer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userStore } from "../../store";
import Analytic from "@proviz/api-services/lib/types/Analytic";

const failedThumbnail = getAbsoluteUrl("images/Error.svg");
const processingThumbnail = getAbsoluteUrl("images/Processing.svg");

export default function ModelView(): ReactElement {
  const queryClient = useQueryClient();

  const [currentFolder, setCurrentFolder] = useState<APIFolder | null>(null);
  const [editModel, setEditModel] = useState<APIModel | null>(null);
  const [statusModal, setStatusModal] = useState<APIModel | null>(null);
  const [globalExportMenu, setGLobalExportMenu] = useState<APIModel | null>(
    null
  );
  const [uploadModel, setUploadModel] = useState(false);
  const [waitingText, setWaitingText] = useState("");
  const [page, setPage] = useState(0);
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [modelAnalytics, setModelAnalytics] = useState<any>();

  const search = searchStore((x) => x.search);

  const { isLoading, error, data } = useQuery<
    Promise<Paged<APIModel>>,
    undefined,
    Paged<APIModel>,
    [string, number, string | undefined]
  >(
    ["models", page, currentFolder?.id],
    ({ queryKey: [key, page, currentFolderId] }) => {
      if (currentFolderId) {
        return ModelService.pagedByFolder(
          currentFolderId,
          page as number,
          10,
          search
        );
      }
      return ModelService.paged(page as number, 10, search);
    }
  );

  const getUpdatedModelsCallback = () => {
    queryClient.invalidateQueries(["models", page, currentFolder?.id]);
  };

  const onImpersonateChange = () => {
    setCurrentFolder(null);
    getUpdatedModelsCallback();
  };

  const user = userStore((s) => s.user);

  useEffect(() => {
    ProVizConfig.events.addListener("impersonate-change", onImpersonateChange);
    return () => {
      ProVizConfig.events.removeListener(
        "impersonate-change",
        onImpersonateChange
      );
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (statusModal) {
        const modelView = await AnalyticService.modelCount(
          Analytic.ModelView,
          statusModal.id
        );
        const modelOpen = await AnalyticService.modelCount(
          Analytic.ModelOpen,
          statusModal.id
        );
        const modelUpdate = await AnalyticService.modelCount(
          Analytic.ModelUpdate,
          statusModal.id
        );
        setModelAnalytics({ modelView, modelOpen, modelUpdate });
      } else {
        setModelAnalytics({});
      }
    })();
  }, [statusModal]);

  if (error) {
    return <h1>We're afraid something went wrong. Try again later.</h1>;
  }

  return (
    <div className="homeRightView">
      <PageHeader />

      <UploadModal
        open={uploadModel}
        setOpen={() => setUploadModel(true)}
        close={() => setUploadModel(false)}
        reload={() => getUpdatedModelsCallback()}
        setWaitingText={setWaitingText}
        resourceType={"Model"}
      >
        <div className="homeContent">
          <div className="homeContentContainer">
            <PaddedContainer>
              <PageContentHeader title="Models">
                <div>
                  <Button type="default" onClick={() => setShowNewFolder(true)}>
                    <span className="icon icon-plus"> </span>
                    Folder
                  </Button>
                </div>
                <div>
                  <UploadButton onClick={() => setUploadModel(true)} />
                </div>
              </PageContentHeader>
            </PaddedContainer>

            <FoldersList
              folderType="Model"
              onFolderChange={(folder: APIFolder | null) => {
                setCurrentFolder(folder);
              }}
            />
            <div className="modelList">
              {isLoading && <div className="loading-indicator">Loading...</div>}
              {data?.data.map((m: APIModel) => {
                let padded = false;
                let thumbnail = m.thumbnail;
                if (m.status === "Failed") {
                  // since the failed and processing thumbnails are pngs
                  // we don't want to stretch them in the same way as the svg
                  // defaultthumbnail
                  padded = true;
                  thumbnail = failedThumbnail;
                }
                if (m.status === "Processing") {
                  padded = true;
                  thumbnail = processingThumbnail;
                }
                return (
                  <ThumbnailCard
                    key={m.id}
                    title={m.name ?? "Unnamed model"}
                    thumbnail={thumbnail}
                    openUrl={`/model/${m.id}`}
                    padded={padded}
                    global={!m.companyId}
                    updated={m.updated}
                    onDragStart={(e) => {
                      console.log("model drag start", m.id);
                      e.dataTransfer.setData("model/id", m.id);
                    }}
                  >
                    <InfoIcon onClick={() => setStatusModal(m)} />
                    {/* <DropdownMenu menuOptions={[
                            { name: 'Open', onSelect: () => { history.push(`/model/${m.id}`) }, icon: 'icon-open' },
                            { name: 'View Model', icon: 'icon-eye-icon', link: getModelLink(m.id) },
                            { name: 'Info', onSelect: () => setStatusModal(m), icon: 'icon-object-icon' },
                            {
                                name: 'Export to Global', //tagId === "" ? 'Export to Global' : 'Import',
                                onSelect: () => setGLobalExportMenu(m), //tagId === "" ? () => setGLobalExportMenu(m) : () => ModelService.importModel(m.id),
                                icon: 'icon-object-icon' 
                            }
                        ]} /> */}
                  </ThumbnailCard>
                );
              })}
            </div>
            {editModel && (
              <ModelInfoModal
                model={editModel}
                close={() => setEditModel(null)}
                refresh={() => getUpdatedModelsCallback()}
              />
            )}
            {statusModal && (
              <ModelStatusModal
                model={statusModal}
                close={() => setStatusModal(null)}
              >
                <hr />
                <table>
                  <tr>
                    <th>Model Views</th>
                    <th>Model Open</th>
                    <th>Model Updates</th>
                  </tr>
                  <tr>
                    <td>{modelAnalytics.modelView ?? 0}</td>
                    <td>{modelAnalytics.modelOpen ?? 0}</td>
                    <td>{modelAnalytics.modelUpdate ?? 0}</td>
                  </tr>
                </table>
                {user?.isSuperAdmin && (
                  <Button
                    onClick={() => {
                      ModelService.toGlobal(statusModal.id);
                      setStatusModal(null);
                    }}
                  >
                    <span className="icon icon-object-icon"></span> To Global
                  </Button>
                )}
                {user?.isSuperAdmin && (
                  <Button
                    onClick={() => {
                      setGLobalExportMenu(statusModal);
                      setStatusModal(null);
                    }}
                  >
                    <span className="icon icon-object-icon"></span> Export to
                    Global
                  </Button>
                )}
              </ModelStatusModal>
            )}
            {globalExportMenu && (
              <GlobalExportMenu
                entity={{ type: "Model", model: globalExportMenu }}
                close={() => setGLobalExportMenu(null)}
              />
            )}
            {waitingText.length > 0 && (
              <LoadingModal
                close={() => setWaitingText("")}
                text={waitingText}
              />
            )}

            <div className="searchBar">
              <PagingControl
                page={page}
                totalPages={data?.pagesTotal || 0}
                pageSet={(p: number) => setPage(p)}
                isFileSelect={false}
              />
            </div>
          </div>
        </div>
      </UploadModal>

      {showNewFolder && (
        <FolderNewModal
          folder={currentFolder}
          folderType={"Model"}
          close={() => {
            setShowNewFolder(false);
          }}
        />
      )}
    </div>
  );
}
