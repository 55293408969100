import { useState, useCallback } from 'react';
import { APIFile, FileService } from '@proviz/api-services';
import { getAbsoluteUrl } from "@proviz/proviz-sdk";

const defaultThumbnail = getAbsoluteUrl('images/thumbnail.svg');

export const useGetFileThumbnail = () => {
    const [thumbnails, setThumbnails] = useState<Record<string,string>>({})

    const fetchThumbnail = useCallback(async (file: APIFile) => {
        if(file.thumbnailId) {
            try {
                const data = await FileService.getThumbnail(file)
                setThumbnails({[file.id]: data, ...thumbnails})
            } catch (error) {
                setThumbnails({[file.id]: defaultThumbnail, ...thumbnails})
            }
        } else {
            setThumbnails({[file.id]: defaultThumbnail, ...thumbnails})
        }
    }, [thumbnails])

    

    const getThumbnail = useCallback((f: APIFile) => {
        if (thumbnails[f.id]) {
            return thumbnails[f.id]
        }
        fetchThumbnail(f)
        return defaultThumbnail;
    }, [thumbnails, fetchThumbnail])

    return getThumbnail
}