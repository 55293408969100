import Command from "./Command";
import { SettableWidgetProperty } from "../components/sceneeditor/propertypanel/SettableWidgetProperty";
import GraphicsManager from "../graphics/GraphicsManager";

export default class SetWidgetProperty extends Command {
  name = "Set Widget Property";
  oldVal: any;
  newVal: any;
  property: SettableWidgetProperty;
  manager: GraphicsManager;

  constructor(
    property: SettableWidgetProperty,
    oldVal: any,
    newVal: any,
    manager: GraphicsManager
  ) {
    super();
    this.property = property;
    if (
      typeof newVal === "object" &&
      !Array.isArray(newVal) &&
      newVal !== null
    ) {
      this.oldVal = { ...oldVal };
      this.newVal = { ...newVal };
    } else {
      this.oldVal = oldVal;
      this.newVal = newVal;
    }
    this.manager = manager;
  }

  execute() {
    this.property.set(this.newVal, () => this.manager.dispatchWidgetUpdate());
    this.manager.postUpdateTask();
  }

  undo() {
    this.property.set(this.oldVal, () => this.manager.dispatchWidgetUpdate());
    this.manager.postUpdateTask();
  }
}
