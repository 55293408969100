import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { ReactElement } from "react";

import './InfoIcon.css'

const infoIcon = getAbsoluteUrl('images/Other/Information.svg');

interface Props {
    onClick: Function;
}
export default function InfoIcon(props: Props): ReactElement {
    return  <div className="info-icon" onClick={() => props.onClick()}>
        <img src={infoIcon} alt=''></img>
    </div>;
}