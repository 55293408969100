import { Line, Mesh } from "three";

export class GizmoPart {
  mesh: Mesh | Line;

  position?: [number, number, number];

  rotation?: [number, number, number];

  scale?: [number, number, number];

  category: string;

  constructor(
    mesh: Mesh | Line,
    position?: [number, number, number],
    rotation?: [number, number, number],
    scale?: [number, number, number],
    category: string = "default"
  ) {
    this.mesh = mesh;
    this.position = position;
    this.rotation = rotation;
    this.scale = scale || [1, 1, 1];
    this.category = category;
  }
}
