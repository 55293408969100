import React, { useEffect, useState } from 'react';
import { IBaseWidgetEvent, IBaseWidgetService, getAbsoluteUrl } from "@proviz/proviz-sdk";
import { Handle, Position } from 'react-flow-renderer';
import ReactMarkdown from 'react-markdown';
import { ManagerStore } from '../../../store';

// const boltIcon = getAbsoluteUrl('images/bolt.svg');

interface Props {
  data: {
    label: string,
    note: {
      data: string,
      alwaysShow: boolean,
      toggleShow: Function,
    },
    node: {
      id: string,
      widgetType: string,
      services: IBaseWidgetService[],
      events: IBaseWidgetEvent[]
  }
  }
}

export default function WidgetNode(props: Props) {
  const { node, note } = props.data;
  const manager = ManagerStore( s => s.SceneManager );
  const [isSelected, setIsSelected] = useState<boolean>(false)

  // let isSelected = 

  // React.useEffect(() => {
  //   const updateSelected = () => {
  //     setSelected(props.data.node === manager.selectedNode);
  //   }
  //   manager.attach('selectednode-update', updateSelected);
  //   return () => {
  //     manager.detach('selectednode-update', updateSelected);
  //   }
  // })

    useEffect(() => {
      setIsSelected(manager.selectedNodes.findIndex((wdg) => wdg.id === node.id) > -1)
    }, [manager.selectedNodes, node.id])
    
  const maxHeightNodes = Math.max(props.data.node.services.length, props.data.node.events.length);
  const height = 30 + maxHeightNodes * 15;


  return (
    <React.Fragment>
      <div style={{ position: 'relative', padding: '0px', height: `${height}px`, width: '200px' }}>

        {node.services.map((s: IBaseWidgetService, ind: number) => <Handle
          className="flow-handle"
          key={node.id + s.name + ind}
          id={s.name}
          type="target"
          position={Position.Left}
          style={{ 
            bottom: ind * 15, 
            top: 'auto', 
            left: -15,
            background: '#fff',
            padding: '2px',
            zIndex: 100 - ind 
          }}
          // onConnect={(params) => console.log('handle onConnect', params)}
        >
          {/* <img src={boltIcon} style={{ width: '7px', position: 'absolute', pointerEvents: 'none' }} alt='' /> */}
          <div style={{ position: 'absolute', top: '-2px', left: '18px', fontSize: '10px', whiteSpace: 'nowrap', width: '100px' }}>
            {s.label}
          </div>
          {s.desc && <div className="desc" dangerouslySetInnerHTML={{ __html: s.desc || '' }}></div>}
        </Handle>)}

        <div className={`flow-node-header ${isSelected ? 'selected' : ''}`}
          style={{display:"flex"}}
          onClick={() => {
            manager.setSelectionById(node.id);
          }} >
            {note.data && <div className='note'>
              <div onClick={() => {
                console.log(note.alwaysShow)
                note.toggleShow(note.alwaysShow)
                }}>
                  <img className='widget-icon' 
                    src={note.alwaysShow?getAbsoluteUrl("images/noteIcon2.svg"):getAbsoluteUrl("images/noteIcon1.svg")} 
                    alt="toggle notes"
                  />
              </div>
              <div className="note-desc" style={{display: `${note.alwaysShow?"block":""}`}}>
                <ReactMarkdown children={note.data} />
              </div>
              </div>}
            {isSelected ? <b style={{flexGrow:1}}>{props.data.label}</b> : <span style={{flexGrow:1}}>{props.data.label}</span>}
            <Handle
              className="flow-handle"
              key={node.id + '-widget-source'}
              type="source"
              position={Position.Right}
              id={node.id + '-widget-source'}
              style={{ top: 12.5, right: 5, background: '#fff', borderRadius: '100%', padding: '2px', zIndex: 100 }}
            >
              <div style={{ position: 'absolute', top: '-2px', right: '20px', fontSize: '10px', whiteSpace: 'nowrap' }}>
                
              </div>
              <div className="desc">Widget</div>
            </Handle>
        </div>

        {props.data.node.events.map((e: IBaseWidgetEvent, ind: number) => <Handle
          className="flow-handle"
          key={node.id + e.name + ind}
          type="source"
          position={Position.Right}
          id={e.name}
          style={{ bottom: ind * 15, top: 'auto', right: -15, background: '#fff', borderRadius: '100%', padding: '2px', zIndex: 100 - ind }}
        >
          <div style={{ position: 'absolute', top: '-2px', right: '20px', fontSize: '10px', whiteSpace: 'nowrap' }}>
            {e.label}
          </div>
          {e.desc && <div className="desc" dangerouslySetInnerHTML={{ __html: e.desc || '' }}></div>}
        </Handle>)}
      </div>
    </React.Fragment>
  );
}