import { ReactElement, useEffect, useState } from "react";
import PageHeader from "../home/pageHeader";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ProVizConfig, RoleService } from "@proviz/api-services";
import { Link } from "react-router-dom";
import Button from "../common/button/Button";
import styled from "styled-components";
import Input from "../common/input/input";

const GlobalTag = styled.div`
  text-transform: uppercase;
  color: #fff;
  font-size: 0.6rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: inline-block;
`;

export default function RolesView(): ReactElement {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [, setUpdateRoles] = useState(true);

  const queryData = useQuery(["roles"], () => {
    setUpdateRoles(true);
    return RoleService.getAll();
  });

  const create = async () => {
    await RoleService.create(name);
    queryClient.invalidateQueries(["roles"]);
  };

  const deleteRole = async (id: string) => {
    await RoleService.delete(id);
    queryClient.invalidateQueries(["roles"]);
  };

  useEffect(() => {
    const updateRoles = () => setUpdateRoles(true);
    ProVizConfig.events.addListener("impersonate-change", updateRoles);
    return () => {
      ProVizConfig.events.removeListener("impersonate-change", updateRoles);
    };
  }, []);

  useEffect(() => {
    setUpdateRoles(true);
  }, []);

  return (
    <div className="homeRightView">
      <PageHeader />
      <div className="homeContent">
        <div className="homeContentContainer">
          <div className="paddedContainer">
            <h1>Roles</h1>
            <div style={{ width: "40rem", margin: "2rem 0" }}>
              <h3>Create Role</h3>
              <div style={{ margin: "1rem 0" }}>
                <Input
                  className="full"
                  placeholder="Name"
                  value={name}
                  onChange={(value) => setName(value)}
                />
              </div>
              <Button fullWidth onClick={() => create()}>
                Create
              </Button>
            </div>
            <table>
              <tbody>
                {queryData?.data
                  ?.sort((a, b) => {
                    // Sort by global first
                    if (a.companyId && !b.companyId) {
                      return 1;
                    }
                    if (!a.companyId && b.companyId) {
                      return -1;
                    }

                    // Sort by name
                    if (a.name > b.name) {
                      return 1;
                    }
                    if (a.name < b.name) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((r: any) => (
                    <tr>
                      <td>
                        {!r.companyId && <GlobalTag>GLOBAL</GlobalTag>} {r.name}
                      </td>
                      <td>
                        <Link to={`/home/settings/roles/${r.id}`}>Edit</Link>
                      </td>
                      <td>
                        <Button type="danger" onClick={() => deleteRole(r.id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
