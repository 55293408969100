import {
  Euler,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  ShaderMaterial,
  Vector2,
  Vector3,
  VideoTexture,
} from 'three';
import {
  encodingOptions,
  MultiLangOption,
  ProVizScene,
  SceneMode,
} from '../../..';
import { APIFile, FileService, getMediaFilePath, ProVizConfig } from '@proviz/api-services';
import { ReverseSizeLookup, SizeLookup, SRTPlayer } from './srt';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { EventDispatcher } from '../../../EventDispatcher';
import { ProVizEventData } from '../../../ProVizEventData';
import { ModelWidget } from '../model';
import { ResourceLink } from '@proviz/api-services';
import { IBaseWidgetEvent } from '../../BaseWidgetEvent';
import VideoUtils from './videoUtils';
import { VideoTransparencySettings } from './VideoTransparencySettings';
import { VideoTransparent } from './VideoTransparent';
import { VideoBillboardMode } from './VideoBillboardMode';
import { VideoSRTSettings } from './VideoSRTSettings';

export class VideoWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'video';
  public static eventHandler: EventDispatcher = new EventDispatcher();
  private videoFile?: APIFile;
  private static instanceCount = 0;

  // Data
  public videoOptions: MultiLangOption = {};
  public srtOptions: MultiLangOption = {};
  public videoSrcOptions: MultiLangOption = {};
  public playOnLoad: boolean = false;
  public playOnClick: boolean = false;
  public showCC: boolean = false;
  public startWithCCOn: boolean = true;
  public clickable: boolean = true;
  public pauseWhenLeavingHotspot: boolean = false;
  public srtSettings: VideoSRTSettings = {
    sbtBgrndColor: '#000',
    sbtColor: '#fff',
    sbtSize: 'x-large'
  }
  public loop: boolean = false;
  public billboard: VideoBillboardMode = VideoBillboardMode.None;
  public timeEvents: number[] = [];
  public version = 2;
  public muteAudio: boolean = false;
  public meshIdOverride: string | undefined = undefined;
  public textureEncoding: string = 'sRGB';
  private transparencySettings: VideoTransparencySettings = {
    transparent: VideoTransparent.None,
    chromaKey: '#1cff01',
    similarity: 0.4,
    smoothness: 0.08,
    spill: 0.1,
    opacity: 1.0
  }

  private videoDOMElement?: {
    video: HTMLVideoElement;
    videoSource: HTMLSourceElement;
  } = undefined;
  private videoTexture?: VideoTexture;
  private videoMaterial?: ShaderMaterial | MeshBasicMaterial;
  private video3DMesh?: Mesh;
  private isFullscreen: boolean = false;

  private isPlaying: boolean = false;
  private preload: boolean = false;
  private videoSrc: string | undefined = undefined;
  private wireframeModel: Object3D | undefined;
  private playWhenReady: boolean = false;

  private fullscreenReset = {
    position: new Vector3(),
    rotation: new Euler(),
    scale: new Vector3(),
  };

  /**
   * used for time based events
   */
  private lastTime: number = 0;
  private srtPlayer?: SRTPlayer;
  /**
   * This boolean keeps track of if the widget received a play
   * event while it was not visible or not done initializing.
   *  */
  private shouldPlay: boolean = false;

  public static DOMVideoElement: HTMLVideoElement;
  public static DOMVideoSource: HTMLSourceElement;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = VideoWidget.type;
    if (!notInScene) {
      VideoWidget.instanceCount++;
    }
    this.label = 'Video ' + VideoWidget.instanceCount;
    this.widgetName = 'Video';

    this.selectable = true;
    this.renderNode.renderOrder = 4;
    this.events.push(
      { label: 'Clicked', name: 'clicked' },
      { label: 'Play Finished', name: 'play-finished' },
      { label: 'Play Started', name: 'play-started' },
      { label: 'Play Paused', name: 'play-paused' },
      { label: 'Video Ready', name: 'video-ready' },
      { label: 'Video Show', name: 'video-show' },
    );

    this.addService('Play', 'play', 'Play video', this.playVideo.bind(this));
    this.addService('Pause', 'pause', 'Pause video', this.pauseVideo.bind(this));
    this.addService('Stop', 'stop', 'Stop video', this.serviceStop.bind(this));
    this.addService('Set Source', 'set-source', 'Set video source', this.serviceSetSource.bind(this));
    this.addService('Full Screen-dom', 'full-screen-dom', 'Plays the video on full screen', this.serviceFullscreenDom.bind(this));
    this.addService('Fullscreen', 'fullscreen', 'Fullscreen video', this.serviceFullscreen.bind(this));
    this.addService('Preload', 'preload', 'Preload the video for playback', this.preloadVideo.bind(this));
    this.addEventListener('clicked', this.serviceClicked.bind(this));
    this.addEventListener('service-wireframe', this.serviceWireframe.bind(this));

    if (!VideoWidget.DOMVideoElement || !VideoWidget.DOMVideoSource) {
      const videoElements = this.createVideoDOM();
      VideoWidget.DOMVideoElement = videoElements.video;
      VideoWidget.DOMVideoElement.setAttribute('id', 'fullscreen-video');
      VideoWidget.DOMVideoElement.removeAttribute('loop');
      VideoWidget.DOMVideoSource = videoElements.videoSource;
      if (document.getElementById('proviz-container')) {
        document.getElementById('proviz-container')!.appendChild(VideoWidget.DOMVideoElement);
      }
    }

  }

  /*
    Video Widget Services
  */

  serviceStop() {
    this.pauseVideo();
    this.srtPlayer?.stop();
    if (this.videoDOMElement) {
      this.videoDOMElement.video.currentTime = 0;
      this.videoDOMElement.video.pause()
    }
  }

  serviceClicked() {
    if (this.playOnClick && this.visible) {
      // If there are no video elements/ this widget has not finished being initialized
      // we stack play requests
      if (!this.videoDOMElement || this.videoDOMElement.video.paused) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    }
  }

  serviceFullscreenDom() {
    if (this.getVideoSource()) {
      const videoSrc = this.getVideoSource();
      VideoWidget.DOMVideoSource.setAttribute('src', videoSrc);
      VideoWidget.DOMVideoSource.setAttribute('type', VideoUtils.getMimeType(videoSrc));
      VideoWidget.DOMVideoElement.setAttribute('src', videoSrc);
      VideoWidget.DOMVideoElement.setAttribute('type', VideoUtils.getMimeType(videoSrc));
      VideoWidget.DOMVideoElement.onended = () => {
        this.srtPlayer?.stop();
        if (document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document['webkitExitFullscreen']) {
            document['webkitExitFullscreen']();
          } else if (document['msExitFullscreen']) {
            document['msExitFullscreen']();
          } else if (document['mozExitFullscreen']) {
            document['mozExitFullscreen']();
          }
        }
        this.triggerProVizEvent('play-finished', 'none');
      };

      VideoWidget.DOMVideoElement.onplay = () => {
        this.triggerProVizEvent(
          'play-started',
          'number',
          VideoWidget.DOMVideoElement.currentTime,
        );
      };

      VideoWidget.DOMVideoElement.onpause = () => this.triggerProVizEvent('play-paused', 'none');

      if (VideoWidget.DOMVideoElement.requestFullscreen) {
        VideoWidget.DOMVideoElement.requestFullscreen();
      } else if (VideoWidget.DOMVideoElement['webkitRequestFullscreen']) {
        VideoWidget.DOMVideoElement['webkitRequestFullscreen']();
      } else if (VideoWidget.DOMVideoElement['msRequestFullscreen']) {
        VideoWidget.DOMVideoElement['msRequestFullscreen']();
      } else if (VideoWidget.DOMVideoElement['mozRequestFullScreen']) {
        VideoWidget.DOMVideoElement['mozRequestFullScreen']();
      }
      VideoWidget.DOMVideoElement.play();
    }
  }

  serviceFullscreen() {
    if (this.isFullscreen) {
      this.isFullscreen = false;
      this.renderNode.position.copy(this.fullscreenReset.position);
      this.renderNode.rotation.copy(this.fullscreenReset.rotation);
      this.renderNode.scale.copy(this.fullscreenReset.scale);
    } else {
      this.isFullscreen = true;

      this.fullscreenReset.position.copy(this.renderNode.position);
      this.fullscreenReset.rotation.copy(this.renderNode.rotation);
      this.fullscreenReset.scale.copy(this.renderNode.scale);

      console.log(this.scene.camera);
    }
  }
  
  serviceWireframe(event: ProVizEventData) {
    if (this.video3DMesh) {
      if (event.dataType === 'boolean') {
        const wireframe = event.data as boolean;
        this.setWireframe(wireframe);
      }
    }
  }

  serviceSetSource(event: ProVizEventData) {
    let filename;
    if (event.dataType === 'string') {
      filename = decodeURIComponent(event.data as string);
    }
    if (!filename) {
      return;
    }
    const videoSrc = getMediaFilePath(filename);
    if (!this.videoDOMElement) {
      console.error('Cannot set video source because video does not yet exist. This is an error.');
      return;
    }
    const { video, videoSource } = this.videoDOMElement;
    video.pause();
    videoSource.setAttribute('type', VideoUtils.getMimeType(videoSrc));
    videoSource.setAttribute('src', ProVizConfig.getPath(videoSrc));
    video.load();
  }

  public setWireframe(state: boolean) {
    console.log('set wireframe');
    if (state && this.video3DMesh) {
      if (!this.wireframeModel) {
        const wireframeMaterial = new MeshStandardMaterial({
          wireframe: true
        });
        this.wireframeModel = this.video3DMesh.clone(true);
        VideoUtils.applyMaterial(this.wireframeModel, wireframeMaterial);
      }
      this.renderNode.add(this.wireframeModel);
    } else if (!state && this.wireframeModel) {
      this.renderNode.remove(this.wireframeModel);
    }
  }  

  /*
    Video Widget Update
  */

  public update(delta: number) {
    super.update(delta);
    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }

    if (this.isFullscreen) {
      VideoUtils.applyInFrontOfCamera(this.renderNode, this.scene.camera);

      const scale = 0.12;
      this.video3DMesh?.scale.set(scale, scale, scale);
    }

    // check if any registered events' time stamps have been passed
    if (this.timeEvents && this.videoDOMElement && !this.videoDOMElement.video.paused) {
      for (let t of this.timeEvents) {
        if (!Number.isNaN(t) && t >= this.lastTime && t <= this.videoDOMElement.video.currentTime) {
          this.triggerProVizEvent(`time-${t}`, 'none');
        }
      }
      this.lastTime = this.videoDOMElement.video.currentTime;
    }

    if (this.srtPlayer && this.showCC && this.videoDOMElement) {
      this.srtPlayer.update(this.videoDOMElement.video.currentTime);
    }

    VideoUtils.applyBillboard(this.renderNode, this.scene.camera, this.billboard);
  }

  private setupPlaneGeometry(visible: boolean = true) {
    this.physicsBounds = VideoUtils.setupClickableGeometry(visible);
    this.physicsBounds.userData.widget = this;
    this.renderNode.add(this.physicsBounds);
  }

  public async init() {
    console.log('Video widget init');
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    console.log('Video widget init');
    if (this.renderNode.scale.z === 0) {
      console.warn('Video was set to 0 scale on the Z, adjusting so that it does not disappear.');
      this.renderNode.scale.z = 0.2;
    }

    if (this.videoOptions) {
      if (this.scene.sceneMode === SceneMode.Editor) {
        // Show visual representation in the editor
        // TODO: Show thumbnail of the video
        if (!this.meshIdOverride) {
          this.setupPlaneGeometry();
        }
        return true;
      }

      let videoId = this.getVideoId();
      if (!videoId || videoId === 'undefined') {
        console.warn('No video for', this.label, this.videoOptions);
        return true;
      }

      if (Object.keys(this.srtOptions).length > 0) {
        this.srtPlayer = new SRTPlayer(
          this.srtOptions,
          this.scene,
          this.srtSettings.sbtColor,
          this.srtSettings.sbtBgrndColor,
          this.srtSettings.sbtSize,
          this.label,
        );
      }

      // Video was not already set up
      if (!this.videoDOMElement) {
        this.preloadVideo();
      }

      // Source is set whenever language changes
      // language from url is read after scene is deserialized
      this.scene.addEventListener('language-change', this.onSceneLanguageChange.bind(this));

      if (this.pauseWhenLeavingHotspot) {
        this.scene.addEventListener('view-change', this.pauseVideo.bind(this));
      }
    }

    if (this.clickable) {
      this.setupPlaneGeometry(false);
    }

    return true;
  }

  private async onSceneLanguageChange() {
    this.srtPlayer?.setLanguage(this.scene.selectedLanguage, this.scene.defaultLanguage);
    const newVideoId = this.getVideoId();
    const newVideoSrc = this.getVideoSource();

    let videoId = this.getVideoId();
    // If its the same video do no more, the subtitles may change though
    if (newVideoId === videoId) {
      return;
    }
    const isPlaying = this.videoDOMElement ? this.videoDOMElement.video.paused === false : false;
    if (newVideoSrc) {
      this.setVideoSource(newVideoSrc);
    } else {
      await this.setupVideoSource(newVideoId);
    }
    isPlaying && this.playVideo(); // only play video if it was already playing
  }

  private async preloadVideo() {
    if (!this.videoDOMElement) {
      console.log('Preloading script');
      this.videoDOMElement = this.createVideoDOM();
    }

    // Ensure up to date video source

    let videoId = this.getVideoId();
    if (!videoId || videoId === 'undefined') {
      console.warn('No video for', this.label, this.videoOptions);
      return true;
    }
    
    let videoSrc = this.getVideoSource();
    if (this.videoSrc !== videoSrc) {
      this.videoSrc = videoSrc;
      if (videoSrc) {
        this.setVideoSource(videoSrc);
      } else {
        await this.setupVideoSource(videoId);
      }
    }
  }

  private getVideoId = () =>
    (this.videoOptions && this.videoOptions[this.scene.selectedLanguage]) ??
    (this.videoOptions && this.videoOptions[this.scene.defaultLanguage]) ??
    '';

  private getVideoSource = () =>
    ProVizConfig.getPath((this.videoSrcOptions && this.videoSrcOptions[this.scene.selectedLanguage]) ??
    (this.videoSrcOptions && this.videoSrcOptions[this.scene.defaultLanguage]) ??
    '');

  public getClickable() {
    const result = super.getClickable();
    if (this.clickable && this.video3DMesh && this.visible) {
      result.push(this.video3DMesh);
    }
    return result;
  }

  public verifyClick(point: Vector3, uv: Vector2) {
    if (this.transparencySettings.transparent === VideoTransparent.None || !this.videoMaterial) {
      return true;
    }

    return VideoUtils.verifyClick(point, uv, this.transparencySettings, this.videoMaterial);
  }

  public getHoverable(): Object3D[] {
    if (this.clickable && this.physicsBounds && this.visible) {
      const result = super.getHoverable();
      if (this.video3DMesh) {
        result.push(this.video3DMesh);
      }
      return result;
    }
    return super.getHoverable();
  }

  private async playVideo(): Promise<void> {
    console.log('play video');
    if (!this.isInitialized) {
      this.shouldPlay = true;
      console.log('tried to play video but not initialized yet');
      return;
    }

    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }


    super.setVisible(false);
    console.log('Play video');

    this.srtPlayer?.play(this.videoDOMElement?.video.currentTime);
    // Ensure video elements are loaded
    this.preloadVideo();
    this.shouldPlay = true;

    try {
      await this.videoDOMElement?.video.play();
    } catch {
      const w: any = window;
      w.lastVideoWidgetError = this;
      console.warn('Could not start play', this.videoDOMElement, this);
      this.playWhenReady = true;
    }
  }

  private pauseVideo() {
    console.log('pause the video');
    this.isPlaying = false;
    this.shouldPlay = false;
    this.playWhenReady = false;
    if (!this.visible || !this.isInitialized) {
      // If video is not initialized
      return;
    }
    this.videoDOMElement?.video.pause();
  };

  public async setVisible(state: boolean) {
    if (!this.isInitialized) {
      await this.init();
    };
    super.setVisible(state);
    // If a video is playing it should be hidden upon hiding
    if (!state && this.isPlaying) {
      this.pauseVideo();
    }
    // we check if this video was initialized before the super
    // is called because the video wil be played after its initialized
    // if shouldPlay is true but we also want to play it if it was
    // paused hidden, then a play event was registered before it was set visible again
    if (this.shouldPlay) {
      this.playVideo();
    }
  }

  private createVideoDOM() {
    const { video, videoSource } = VideoUtils.createVideoDOM(this.loop, this.id);
    video.onended = this.videoDOMOnEnded.bind(this);
    video.onplay = this.videoDOMOnPlay.bind(this);
    video.onpause = this.videoDOMOnPause.bind(this);
    video.addEventListener('loadeddata', this.videoDOMOnLoadedData.bind(this));
    video.ontimeupdate = this.videoDOMOnTimeUpdate.bind(this);
    return { video, videoSource };
  }

  private videoDOMOnTimeUpdate() {
    if (!this.visible && this.isPlaying) {
      super.setVisible(true);
      this.triggerProVizEvent('video-show', 'none');
    }
  }

  private async setupVideoSource(videoId: string) {
    this.videoFile = await FileService.get(videoId);
    const videoSrc = FileService.getLocation(this.videoFile);
    if (!this.videoSrcOptions) {
      this.videoSrcOptions = {};
    }
    this.videoSrcOptions[
      this.videoOptions[this.scene.selectedLanguage]
        ? this.scene.selectedLanguage
        : this.scene.defaultLanguage
    ] = videoSrc;
    // If the video source or video have not been set up yet
    // their values will be set once this is set up
    this.setVideoSource(ProVizConfig.getPath(videoSrc));
  }

  private setVideoSource(videoSrc: string) {
    if (!this.videoDOMElement) {
      this.videoDOMElement = this.createVideoDOM();
    }
    const { video, videoSource } = this.videoDOMElement;
    videoSource.setAttribute('src', videoSrc);
    videoSource.setAttribute('type', VideoUtils.getMimeType(videoSrc));
    video.setAttribute('src', videoSrc);
    video.setAttribute('type', VideoUtils.getMimeType(videoSrc));
  }

  /*
     Video DOM Events
  */

  private videoDOMOnEnded() {
    if (!this.isInitialized) return;
    this.isPlaying = false;
    this.srtPlayer?.stop();
    this.triggerProVizEvent('play-finished', 'none');
  }

  private videoDOMOnPlay() {
    if (!this.isInitialized) return;
    this.setVisible(true);
    console.log('Video is playing');
    this.isPlaying = true;
    this.shouldPlay = false;
    this.triggerProVizEvent('play-started', 'number', this.videoDOMElement?.video.currentTime);
  }

  private videoDOMOnPause() {
    if (!this.isInitialized) return;
    console.log('Video paused');
    const debugHandler = this.scene.getDebugHandler();
    if (debugHandler && debugHandler.isPaused()) {
      return;
    }
    this.isPlaying = false;
    this.triggerProVizEvent('play-paused', 'none');
  }

  private videoDOMOnLoadedData() {
    console.log('Video loaded data');

    // remove old video mesh
    if (this.video3DMesh) {
      this.renderNode.remove(this.video3DMesh);
    }

    // Setup new video mesh with material and texture
    if (this.videoDOMElement && this.videoDOMElement.video) {
      this.videoTexture = VideoUtils.createVideoTexture(this.videoDOMElement?.video);
      this.videoTexture.encoding = encodingOptions[this.textureEncoding] ?? this.videoTexture.encoding;
      this.videoMaterial = VideoUtils.createMaterial(this.transparencySettings, this.videoTexture);
      this.videoMaterial.opacity = 0;

      if (this.meshIdOverride) {
        var modelWidget = this.scene.getById(this.meshIdOverride) as ModelWidget;
        if (modelWidget) {
          modelWidget.setMaterial(this.videoMaterial);
        } else {
          console.error('Could not find mesh override for Video widget: ' + this.label);
        }
      } else {
        console.warn('####### . creating video mesh');
        this.video3DMesh = VideoUtils.createMesh(this.videoMaterial);
        this.video3DMesh.renderOrder = 4;
        this.renderNode.add(this.video3DMesh);
      }

      this.triggerProVizEvent('video-ready', 'none');
      console.log('video ready')

      if (this.shouldPlay || this.playWhenReady) {
        this.playWhenReady = false;
        this.playVideo();
      }

    } else {
      console.error('Video DOM element or video is not initialized, but loaded data event was triggered');
    }
  }

  /*
    Widget Handlers
  */

  public serialize(): any {
    const result = super.serialize();
    result.videoOptions = this.videoOptions;
    result.videoSrcOptions = this.videoSrcOptions;
    result.clickable = this.clickable;
    result.opacity = this.transparencySettings.opacity;
    result.playOnLoad = this.playOnLoad;
    result.playOnClick = this.playOnClick;
    result.chromaKey = this.transparencySettings.chromaKey;
    result.similarity = this.transparencySettings.similarity;
    result.smoothness = this.transparencySettings.smoothness;
    result.spill = this.transparencySettings.spill;
    result.loop = this.loop;
    result.transparent = this.transparencySettings.transparent;
    result.billboard = this.billboard;
    result.pauseWhenLeavingHotspot = this.pauseWhenLeavingHotspot;
    result.timeEvents = this.timeEvents;
    const srtOptionsCleaned: MultiLangOption = {};
    const srtKeys = Object.keys(this.srtOptions);
    for (let i = 0; i < srtKeys.length; i++) {
      const key = srtKeys[i];
      if (this.srtOptions[key]) {
        srtOptionsCleaned[key] = this.srtOptions[key];
      }
    }
    result.srtOptions = srtOptionsCleaned;
    result.showCC = this.showCC;
    result.startWithCCOn = this.srtOptions;
    result.sbtColor = this.srtSettings.sbtColor;
    result.sbtBgrndColor = this.srtSettings.sbtBgrndColor;
    result.sbtSize = this.srtSettings.sbtSize;
    result.meshIdOverride = this.meshIdOverride;
    result.textureEncoding = this.textureEncoding;
    result.preload = this.preload;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.videoOptions = data.videoOptions ?? this.videoOptions;
    this.videoSrcOptions = data.videoSrcOptions ?? this.videoSrcOptions;
    this.clickable = data.clickable;
    this.playOnLoad = data.playOnLoad;
    // shouldPlay is initialized from playOnLoad
    // it is a runtime variable that will be modified by events.
    this.shouldPlay = data.playOnLoad;
    this.playOnClick = data.playOnClick;
    this.transparencySettings.chromaKey = data.chromaKey ?? this.transparencySettings.chromaKey;
    this.transparencySettings.similarity = data.similarity ?? this.transparencySettings.similarity;
    this.transparencySettings.smoothness = data.smoothness ?? this.transparencySettings.smoothness;
    this.transparencySettings.spill = data.spill;
    this.transparencySettings.opacity = data.opacity ?? this.transparencySettings.opacity;
    this.loop = data.loop;
    this.transparencySettings.transparent = data.transparent;
    this.billboard = data.billboard;
    this.pauseWhenLeavingHotspot = data.pauseWhenLeavingHotspot ?? false;
    this.timeEvents = data.timeEvents ?? [];
    this.srtOptions = data.srtOptions ?? this.srtOptions;
    this.showCC = data.showCC ?? this.showCC;
    this.startWithCCOn = data.startWithCCOn ?? this.startWithCCOn;
    this.srtSettings.sbtColor = data.sbtColor ?? this.srtSettings.sbtColor;
    this.srtSettings.sbtBgrndColor = data.sbtBgrndColor ?? this.srtSettings.sbtBgrndColor;
    this.srtSettings.sbtSize = data.sbtSize ?? this.srtSettings.sbtSize;
    this.meshIdOverride = data.meshIdOverride ?? this.meshIdOverride;
    this.textureEncoding = data.textureEncoding ?? this.textureEncoding;
    this.preload = data.preload ?? this.preload;

    if (this.preload) {
      this.preloadVideo();
    }
  }

  async migrate(data: any) {
    if (!data.version) {
      data.version = 1;
    }
    switch (data.version) {
      case 1:
        if (!data.videoOptions) {
          data.videoOptions = { en: data.videoId ?? '' };
        } else {
          const videoIds = {};
          data.videoOptions.split(',').forEach((t: string) => {
            const parts = t.split(':');
            videoIds[parts[0]] = decodeURIComponent(parts[1]);
          });
          data.videoOptions = videoIds;
        }
    }
    return data;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'videoOptions',
        'Video Sources',
        'Core',
        'video-options',
        'list',
        true,
        undefined,
        (data) => {
          console.log(data);
          const keys = Object.keys(data);
          this.videoSrcOptions = {};
          Object.keys(this.videoOptions)
            .filter((lng) => !keys.includes(lng))
            .map((lng) => {
              delete this.videoSrcOptions[lng];
            });
          keys.map((lng) => {
            if (this.videoOptions[lng] != data[lng]) {
              (async () => {
                const file = await FileService.get(data[lng]);
                this.videoSrcOptions[lng] = FileService.getLocation(file);
              })();
            }
          });
          this.videoOptions = data;
        },
      ),
      this.createProperty('playOnLoad', 'Play on Load', 'Media Behavior', 'bool', 'boolean', true),
      this.createProperty('loop', 'Loop', 'Media Behavior', 'bool', 'boolean', true),
      this.createProperty('clickable', 'Clickable', 'Interaction', 'bool', 'boolean', true),
      this.createProperty('playOnClick', 'Play on Click', 'Interaction', 'bool', 'boolean', true),
      this.createProperty('muteAudio', 'Mute Audio', 'Media Behavior', 'bool', 'boolean', true),
      this.createProperty('preload', 'Preload', 'Media Behavior', 'bool', 'boolean', true, undefined, undefined, undefined, 'Preload the video file'),
      this.createProperty(
        'pauseWhenLeavingHotspot',
        'Pause When Leaving Hotspot',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'If this is set to true the video will pause when the user exits any panorama. For complicated pausing logic you should probably use the node editor.',
      ),
      this.createProperty('opacity', 'Opacity', 'Transparency', 'number', 'number', true),
      this.createProperty(
        'transparent',
        'Transparent',
        'Transparency',
        'select',
        'string',
        true,
        () => VideoTransparent[this.transparencySettings.transparent],
        (val: string) => {
          console.log(val, VideoTransparent[val]);
          this.transparencySettings.transparent = VideoTransparent[val];
        },
        () =>
          Object.keys(VideoTransparent)
            .filter((value) => typeof value === 'string' && value.length > 1)
            .map((value) => {
              return { key: VideoTransparent[value], label: value };
            }),
      ),
      this.createProperty('chromaKey', 'Chroma Key', 'Transparency', 'string', 'string', true),
      this.createProperty(
        'similarity',
        'Chroma Similarity',
        'Transparency',
        'number',
        'number',
        true,
      ),
      this.createProperty(
        'smoothness',
        'Chroma Smoothness',
        'Transparency',
        'number',
        'number',
        true,
      ),
      this.createProperty('spill', 'Chroma Spill', 'Transparency', 'number', 'number', true),
      this.createProperty(
        'billboard',
        'Face Camera Mode',
        'Core',
        'select',
        'string',
        false,
        () => VideoBillboardMode[this.billboard],
        (val: string) => {
          this.billboard = VideoBillboardMode[val];
        },
        () => {
          return Object.keys(VideoBillboardMode)
            .filter((value) => isNaN(Number(value)) === false)
            .map((key) => VideoBillboardMode[key]);
        },
      ),
      this.createProperty(
        'srtOptions',
        'Subtitle Sources',
        'Subtitles',
        'srt-options',
        'list',
        true,
      ),
      this.createProperty('sbtColor', 'Subtitle Color', 'Subtitles', 'color', 'string', true),
      this.createProperty(
        'sbtBgrndColor',
        'Subtitle Background Color',
        'Subtitles',
        'color',
        'string',
        true,
      ),
      this.createProperty(
        'sbtSize',
        'Subtitle Size',
        'Subtitles',
        'select',
        'string',
        true,
        () => ReverseSizeLookup[this.srtSettings.sbtSize],
        (data) => (this.srtSettings.sbtSize = SizeLookup[data]),
        () =>
          Object.keys(SizeLookup).map((x) => {
            return { key: x, label: x };
          }),
      ),
      this.createProperty(
        'timeEvents',
        'Timeline Events',
        'Video Events',
        'list',
        'list',
        true,
        undefined,
        (data: string[]) => {
          // we're using string entry for now and enforcing numericness here so it doesn't feel great
          // from the user end,if this interface stays a new widgetpropertytype should be added for arrays of floats.
          this.timeEvents = data.map((d) => {
            const result = parseFloat(d);
            if (Number.isNaN(result)) {
              return 0;
            }
            return result;
          });
        },
        undefined,
        `Put time stamps in seconds. If the event is within a few seconds of the end of the video use the play finished event instead.`,
      ),
      this.createProperty(
        'meshIdOverride',
        'Mesh Override',
        'Advanced',
        'widget',
        'string',
        true,
        undefined,
        (data: string) => {
          this.meshIdOverride = data;
          if (this.meshIdOverride && !this.physicsBounds) {
            this.setupPlaneGeometry();
          } else if (!this.meshIdOverride && this.physicsBounds) {
            this.renderNode.remove(this.physicsBounds);
            this.physicsBounds = undefined;
          }
        },
      ),
      this.createProperty(
        'textureEncoding',
        'Texture Encoding',
        'Experimental',
        'select',
        'string',
        true,
        undefined,
        (data: any) => {
          this.textureEncoding = data;
        },
        () =>
          Object.keys(encodingOptions).map((x) => {
            return { key: x, label: x };
          }),
      ),
    ];
  }
  
  public getEvents(): IBaseWidgetEvent[] {
    const result = super.getEvents();
    return [
      ...result,
      ...this.timeEvents.map((v) => {
        return {
          name: `time-${v}`,
          label: v.toString(),
          desc: `Emits an event at ${v} seconds`,
        };
      }),
    ];
  }

  public async getResourceLinks(): Promise<ResourceLink[]> {
    const result = await super.getResourceLinks();
    console.log(this.videoOptions);
    const keys = Object.keys(this.videoOptions);
    for (let k = 0; k < keys.length; k++) {
      const videoFile = await FileService.get(this.videoOptions[keys[k]]);
      result.push({
        filename: `_v1_File_${this.videoOptions[keys[k]]}`,
        data: JSON.stringify(videoFile),
        location: undefined,
      });
    }
    return result;
  }

  public debugState(state: boolean): void {
    console.log('debug state change', state);
    super.debugState(state);
    if (state && this.isPlaying) {
      this.videoDOMElement?.video.pause();
    } else if (!state && this.isPlaying) {
      this.videoDOMElement?.video.play();
    }
  }

  dispose() {
    super.dispose();
    if (this.physicsBounds) {
      if (this.physicsBounds.userData?.widget) {
        this.physicsBounds.userData.widget = undefined;
      }
      this.physicsBounds.material.dispose();
      this.physicsBounds.geometry.dispose();
    }
    this.videoTexture?.dispose();
    this.videoMaterial?.dispose();
    this.videoDOMElement?.video.remove();
    this.videoDOMElement?.videoSource.remove();
  }
}

ModuleService.Register(VideoWidget.type, VideoWidget);
