import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class StepManagerWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'step-manager';

  // Data
  public totalSteps: number = 1;

  private currentStep: number = 1;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Step Manager';

    this.usage = 'Flow';
    this.category = 'Events';

    this.widgetType = StepManagerWidget.type;
    this.widgetName = 'Step Manager';
    this.selectable = true;
    this.setupEvents();

    this.services = [
      {
        label: 'Increment',
        name: 'increment',
      },
      {
        label: 'Decrement',
        name: 'decrement',
      },
      {
        label: 'Set Step',
        name: 'set-step',
      },
    ];

    this.addEventListener('service-increment', () => {
      this.currentStep++;
      if (this.currentStep > this.totalSteps) {
        this.currentStep = this.totalSteps;
      } else {
        // only emit if step changed
        this.emitNewValue();
      }
    });
    this.addEventListener('service-decrement', () => {
      this.currentStep--;
      // 1 based not 0 indexed
      if (this.currentStep < 1) {
        this.currentStep = 1;
      } else {
        // only emit if step changed
        this.emitNewValue();
      }
    });

    this.addEventListener('service-set-step', (event: ProVizEventData) => {
      if (event.dataType === 'number') {
        const step = event.data as number;
        if (step && step <= this.totalSteps && step > 0) {
          this.currentStep = step;
          this.emitNewValue();
        }
      } else if (event.dataType === 'string') {
        const step = parseInt(event.data as string);
        if (!Number.isNaN(step)) {
          if (step && step <= this.totalSteps && step > 0) {
            this.currentStep = step;
            this.emitNewValue();
          }
        }
      }
    });
  }

  private setupEvents() {
    this.events = [
      {
        label: 'Changed Step',
        name: 'changed-step',
      },
    ];
    for (let i = 0; i < this.totalSteps; i++) {
      this.events.push({
        label: `Step ${i + 1} Show`,
        name: `step-show-${i + 1}`,
      });
      this.events.push({
        label: `Step ${i + 1} Hide`,
        name: `step-hide-${i + 1}`,
      });
    }
  }

  private emitNewValue() {
    this.triggerProVizEvent('changed-step', 'number', this.currentStep);
    for (let i = 0; i < this.totalSteps; i++) {
      const event = i + 1 === this.currentStep ? `step-show-${i + 1}` : `step-hide-${i + 1}`;
      this.triggerProVizEvent(event, 'number', this.currentStep);
    }
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'totalSteps',
        'Total Steps',
        'Data',
        'number',
        'number',
        true,
        undefined,
        (data: number) => {
          if (data === 0) {
            return;
          }
          this.totalSteps = data;
          this.setupEvents();
        },
        undefined,
        'This is value will be the total number of steps',
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.totalSteps = this.totalSteps;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.totalSteps = data.totalSteps;
    this.setupEvents();
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    this.emitNewValue(); // update any attached widgets
    return true;
  }
}

ModuleService.Register(StepManagerWidget.type, StepManagerWidget);
