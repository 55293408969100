import { useState, useEffect, useRef } from "react";
import { ModelWidget } from "@proviz/proviz-sdk";
import { CategoryRenderer } from "../../sceneeditor/propertypanel/CategoryRenderer";
import ModelAnimationControls from "./ModelAnimationControls";
import ModelLightingControls from "./ModelLightingControls";
import CheckoutButtonSettings from "./CheckoutButtonSettings";
import { ManagerStore } from "../../../store";

export const ModelPropertyCategories = ["Environment"];

export const CoreProperties = [
  "fullScreenEnabled",
  "arEnabled",
  "allowScalingInAR",
  "autoRotate",
  "rotateSpeed",
  "envMapId",
  "outputEncoding",
  "toneMapping",
  "toneMapExposure",
  "physCorrectLight",
];

enum State {
  Failed,
  Ok,
}

export const ModelViewerSettings = () => {
  const manager = ManagerStore((s) => s.ModelViewManager);
  const [state, setState] = useState<State>(State.Ok);

  // TODO put this in a memo
  const modelWidget = manager.proVizScene.nodes.find(
    (w) => w.widgetType === "model"
  ) as ModelWidget | undefined;
  const isFirstRender = useRef(true);

  useEffect(() => {
    const updateModel = async () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      setState(State.Ok);
    };
    updateModel();
  }, []);

  return (
    <div className="controlPanelInterior">
      <h2>Viewer Settings</h2>
      <ul className="modelViewerSettings propertiesPanel">
        {state === State.Failed && (
          <h2>We're having trouble communicating with our server.</h2>
        )}
        <CategoryRenderer
          category={"Core"}
          properties={manager.proVizScene.properties.filter((x) =>
            CoreProperties.includes(x.name)
          )}
          manager={manager}
        />
        <CheckoutButtonSettings scene={manager.proVizScene} />
        {modelWidget && <ModelAnimationControls modelWidget={modelWidget} />}
        {ModelPropertyCategories.map((cat) => (
          <CategoryRenderer
            key={cat}
            category={cat}
            properties={manager.proVizScene.properties.filter(
              (x) => x.category === cat
            )}
            manager={manager}
          />
        ))}
        <ModelLightingControls widgets={manager.proVizScene.nodes} />
      </ul>
    </div>
  );
};
