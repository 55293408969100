
import { ReactElement, useEffect, useState } from 'react'
import { UserKeyService, ProVizConfig, initAbortController, isAbortError, APIUserKey } from "@proviz/api-services";
import FormContainer from '../common/formcontainer';
import Button from '../common/button/Button';
import PageHeader from '../home/pageHeader';
import Modal from '../common/modals/Modals';
import QRious from 'qrious';

function compareByTime(k1: APIUserKey, k2: APIUserKey) {
    return new Date(k2.expires).valueOf() - new Date(k1.expires).valueOf();
}

function generateQR(link: string) {
    const qr = new QRious({
        background: '#fff',
        // foreground: '#6d6e71',
        foreground: '#313E64',
        level: 'H',
        padding: 25,
        size: 1000,
        value: link,
    }
    );
    return qr.toDataURL('image/png');
}
export default function UserKeysView(): ReactElement {
    const [failed, setFailed] = useState<boolean>(false);
    const [updateKeys, setUpdateKeys] = useState(true);
    const [keys, setKeys] = useState<any[]>([]);
    const [ showQR, setShowQR ] = useState<string | undefined>(undefined);
    const [qrSrc, setQrSrc] = useState("");

    useEffect(() => {
        if (showQR) {
            setQrSrc(generateQR(`provizxr://personal-token/${showQR}`));
        }
    }, [showQR]);

    useEffect(() => {
        const updateKeysCb = () => setUpdateKeys(true);
        ProVizConfig.events.addListener('impersonate-change', updateKeysCb);
        return () => {
            ProVizConfig.events.removeListener('impersonate-change', updateKeysCb);
        }
    }, []);

    useEffect(() => {
        if (updateKeys) {
            setFailed(false);
            const abortController = initAbortController();
            UserKeyService.getAll({ abortController })
                .then((k: APIUserKey[]) => {
                    setKeys(k.sort(compareByTime));
                    setUpdateKeys(false);
                })
                .catch((e) => {
                    if (isAbortError(e)) return; // if the query was aborted, do nothing
                    console.error(e);
                    setFailed(true);
                });
            return () => abortController.abort();
        }
        return () => { }
    }, [updateKeys]);

    const createKey = () => {
        UserKeyService.createKey()
            .then(() => setUpdateKeys(true));
    };

    const deleteKey = (key: string) => {
        UserKeyService.delete(key)
            .then(() => setUpdateKeys(true));
    }

    if (failed) {
        return <h1>We're afraid something went wrong. Try again later.</h1>
    }
    return <div className="homeRightView">

    <PageHeader />

    {showQR && <Modal title={"Access Token"} close={() => setShowQR(undefined)}>
        <h1>Scan this code with your device</h1>
        <img style={{width: '100%'}} src={qrSrc} alt={`Access Token`} />
    </Modal>}

    <div className='homeContent'>
        <div className='homeContentContainer'>
            <div className='paddedContainer'>
        <div className="userList">
            <div>

                <FormContainer title="Create User Access Key">
                    <table className="inner">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <Button onClick={createKey}>
                                        Generate New Key
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </FormContainer>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Expires</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {keys.map((key) => <tr key={key.key}>
                        <td>{key.key}</td>
                        <td>{new Date(key.expires).toLocaleString()}</td>
                        <td>
                            <Button fullWidth={true} onClick={() => deleteKey(key.key)}>
                                Delete
                            </Button>
                        </td>
                        <td>
                            <Button fullWidth={true} onClick={() => {
                                setShowQR(key.key);
                            }}>
                                View
                            </Button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
        </div>
        </div>
    </div>;
}
