import React, {ReactElement, useState } from 'react';
import BufferedSet from '../../../utils/BufferedSet';

interface Props {
    name: string | null;
    updateName: (n: string) => void; 
}

export default function EditableTitle(props: Props): ReactElement {
    const [name, setName] = useState(props.name || '');
    const bufferedName = new BufferedSet(props.updateName);

    function nameChange(e: React.ChangeEvent<HTMLInputElement>) {
        let name = e.target.value; 
        setName(name);
        bufferedName.setValue(name);
    }

    return <div className='editableTitle'>
              <input className="nameInput" type="string" value={name} onChange={nameChange} />
           </div>
}