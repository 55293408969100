import {
  Camera,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Quaternion,
  Raycaster,
  Vector3,
} from "three";
import {
  ProVizTransformControlsGizmo,
  TransformAxis,
  TransformMode,
} from "./ProVizTransformControlsGizmo";
import { ProVizTransformControlsPlane } from "./ProVizTransformControlsPlane";

const _changeEvent = { type: "change" };
const _mouseDownEvent = { type: "mouseDown", mode: "" };
const _mouseUpEvent = { type: "mouseUp", mode: "" };
const _objectChangeEvent = { type: "objectChange" };
const _unit = {
  X: new Vector3(1, 0, 0),
  Y: new Vector3(0, 1, 0),
  Z: new Vector3(0, 0, 1),
};

export const _tempQuaternion = new Quaternion();

export const _tempVector = new Vector3();
export const _tempVector2 = new Vector3();

class TransformObjectWrapper {
  object: Object3D;

  startingPosition: Vector3 = new Vector3();

  quaternionStart = new Quaternion();

  scaleStart = new Vector3();

  constructor(object: Object3D) {
    this.object = object;
  }
}

export default class ProVizTransformControls extends Object3D {
  visible: boolean = false;
  domElement: HTMLElement;
  gizmo: ProVizTransformControlsGizmo;
  plane: ProVizTransformControlsPlane;
  isTransformControls: boolean = true;
  enabled: boolean = true;
  objects: TransformObjectWrapper[] = [];
  camera: Camera;
  cameraPosition: Vector3 = new Vector3();
  cameraQuaternion: Quaternion = new Quaternion();
  eye: Vector3 = new Vector3();
  dragging: boolean = false;
  mode: TransformMode;
  axis?: TransformAxis;
  _raycaster: Raycaster = new Raycaster();
  rotationAxis: Vector3 = new Vector3();
  rotationAngle: number = 0;

  _offset = new Vector3();
  _startNorm = new Vector3();
  _endNorm = new Vector3();
  _cameraScale = new Vector3();

  _parentPosition = new Vector3();
  _parentQuaternion = new Quaternion();
  _parentQuaternionInv = new Quaternion();
  _parentScale = new Vector3();

  _worldScaleStart = new Vector3();
  _worldQuaternionInv = new Quaternion();
  _worldScale = new Vector3();

  // _positionStart = new Vector3();
  // _quaternionStart = new Quaternion();
  // _scaleStart = new Vector3();

  worldPosition: Vector3 = new Vector3();
  worldQuaternion: Quaternion = new Quaternion();

  worldPositionStart: Vector3 = new Vector3();
  worldQuaternionStart: Quaternion = new Quaternion();

  translationSnap: number;
  rotationSnap: number;
  scaleSnap: number;
  size: number;
  space: string;
  pointStart: Vector3;
  pointEnd: Vector3;

  constructor(camera: Camera, domElement: HTMLElement) {
    super();

    this.domElement = domElement;
    this.domElement.style.touchAction = "none"; // disable touch scroll

    this.gizmo = new ProVizTransformControlsGizmo();
    this.add(this.gizmo);

    this.plane = new ProVizTransformControlsPlane();
    this.add(this.plane);

    const scope: any = this;

    // Defined getter, setter and store for a property
    const defineProperty = (propName: string, defaultValue: any) => {
      let propValue = defaultValue;
      Object.defineProperty(scope, propName, {
        get: function () {
          return propValue !== undefined ? propValue : defaultValue;
        },

        set: function (value) {
          if (propValue !== value) {
            propValue = value;
            this.plane[propName] = value;
            this.gizmo[propName] = value;

            scope.dispatchEvent({ type: propName + "-changed", value: value });
            scope.dispatchEvent(_changeEvent);
          }
        },
      });

      scope[propName] = defaultValue;
      scope.plane[propName] = defaultValue;
      scope.gizmo[propName] = defaultValue;
    };

    // Define properties with getters/setter
    // Setting the defined property will automatically trigger change event
    // Defined properties are passed down to gizmo and plane

    defineProperty("camera", camera);
    defineProperty("object", undefined);
    defineProperty("enabled", true);
    defineProperty("axis", null);
    defineProperty("mode", "translate");
    defineProperty("translationSnap", null);
    defineProperty("rotationSnap", null);
    defineProperty("scaleSnap", null);
    defineProperty("space", "world");
    defineProperty("size", 1);
    defineProperty("dragging", false);
    defineProperty("showX", true);
    defineProperty("showY", true);
    defineProperty("showZ", true);

    // Reusable utility variables

    const worldPosition = new Vector3();
    const worldPositionStart = new Vector3();
    const worldQuaternion = new Quaternion();
    const worldQuaternionStart = new Quaternion();
    const cameraPosition = new Vector3();
    const cameraQuaternion = new Quaternion();
    const pointStart = new Vector3();
    const pointEnd = new Vector3();
    const rotationAxis = new Vector3();
    const rotationAngle = 0;
    const eye = new Vector3();

    // TODO: remove properties unused in plane and gizmo
    defineProperty("worldPosition", worldPosition);
    defineProperty("worldPositionStart", worldPositionStart);
    defineProperty("worldQuaternion", worldQuaternion);
    defineProperty("worldQuaternionStart", worldQuaternionStart);
    defineProperty("cameraPosition", cameraPosition);
    defineProperty("cameraQuaternion", cameraQuaternion);
    defineProperty("pointStart", pointStart);
    defineProperty("pointEnd", pointEnd);
    defineProperty("rotationAxis", rotationAxis);
    defineProperty("rotationAngle", rotationAngle);
    defineProperty("eye", eye);

    this._offset = new Vector3();
    this._startNorm = new Vector3();
    this._endNorm = new Vector3();
    this._cameraScale = new Vector3();

    this._parentPosition = new Vector3();
    this._parentQuaternion = new Quaternion();
    this._parentQuaternionInv = new Quaternion();
    this._parentScale = new Vector3();

    this._worldScaleStart = new Vector3();
    this._worldQuaternionInv = new Quaternion();
    this._worldScale = new Vector3();

    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerHover = this.onPointerHover.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.domElement.addEventListener("pointerdown", this.onPointerDown);
    this.domElement.addEventListener("pointermove", this.onPointerHover);
    this.domElement.addEventListener("pointerup", this.onPointerUp);
  }

  // updateMatrixWorld  updates key transformation variables
  updateMatrixWorld() {
    this.objects.forEach((object) => {
      object.object.updateMatrixWorld();
      if (object.object.parent === null) {
        console.error(
          "TransformControls: The attached 3D object must be a part of the scene graph."
        );
      } else {
        object.object.parent.matrixWorld.decompose(
          this._parentPosition,
          this._parentQuaternion,
          this._parentScale
        );
      }

      object.object.matrixWorld.decompose(
        this.worldPosition,
        this.worldQuaternion,
        this._worldScale
      );

      this._parentQuaternionInv.copy(this._parentQuaternion).invert();
      this._worldQuaternionInv.copy(this.worldQuaternion).invert();
    });

    this.camera.updateMatrixWorld();
    this.camera.matrixWorld.decompose(
      this.cameraPosition,
      this.cameraQuaternion,
      this._cameraScale
    );

    this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize();
    super.updateMatrixWorld(true);
  }

  pointerHover(pointer: { x: number; y: number; button: number }) {
    if (this.objects.length === 0 || this.dragging === true) return;
    this._raycaster.setFromCamera(pointer, this.camera);
    const intersect = this.intersectObjectWithRay(
      this.gizmo.picker.mode(this.mode),
      this._raycaster
    );
    if (intersect) {
      this.axis = intersect.object.name as TransformAxis;
    } else {
      this.axis = undefined;
    }
  }

  pointerDown(pointer: { x: number; y: number; button: number }) {
    if (
      this.objects.length === 0 ||
      this.dragging === true ||
      pointer.button !== 0
    )
      return;
    if (this.axis !== null) {
      this._raycaster.setFromCamera(pointer, this.camera);
      const planeIntersect = this.intersectObjectWithRay(
        this.plane,
        this._raycaster,
        true
      );
      if (planeIntersect) {
        this.objects.forEach((object) => {
          object.object.updateMatrixWorld();
          if (object.object.parent) {
            object.object.parent.updateMatrixWorld();
          }
          object.startingPosition.copy(object.object.position);
          object.quaternionStart.copy(object.object.quaternion);
          object.scaleStart.copy(object.object.scale);
        });

        const object = this.objects[this.objects.length - 1];
        // this._positionStart.copy( object.object.position );
        // this._quaternionStart.copy( object.object.quaternion );
        // this._scaleStart.copy( object.object.scale );

        object.object.matrixWorld.decompose(
          this.worldPositionStart,
          this.worldQuaternionStart,
          this._worldScaleStart
        );
        this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);
      }

      this.dragging = true;
      _mouseDownEvent.mode = this.mode;
      this.dispatchEvent(_mouseDownEvent);
    }
  }

  pointerMove(pointer: { x: number; y: number; button: number }) {
    const axis = this.axis;
    const mode = this.mode;
    const objects = this.objects;
    let space = this.space;
    if (mode === "scale") {
      space = "local";
    } else if (axis === "E" || axis === "XYZE" || axis === "XYZ") {
      space = "world";
    }

    if (
      objects.length === 0 ||
      axis === null ||
      this.dragging === false ||
      pointer.button !== -1
    )
      return;

    this._raycaster.setFromCamera(pointer, this.camera);
    const planeIntersect = this.intersectObjectWithRay(
      this.plane,
      this._raycaster,
      true
    );

    if (!planeIntersect) return;

    this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart);
    objects.forEach((object) => {
      if (mode === "translate" && this.axis) {
        // Apply translate
        this._offset.copy(this.pointEnd).sub(this.pointStart);
        if (space === "local" && axis !== "XYZ") {
          this._offset.applyQuaternion(this._worldQuaternionInv);
        }
        if (this.axis.indexOf("X") === -1) this._offset.x = 0;
        if (this.axis.indexOf("Y") === -1) this._offset.y = 0;
        if (this.axis.indexOf("Z") === -1) this._offset.z = 0;

        if (space === "local" && axis !== "XYZ") {
          this._offset
            .applyQuaternion(object.quaternionStart)
            .divide(this._parentScale);
        } else {
          this._offset
            .applyQuaternion(this._parentQuaternionInv)
            .divide(this._parentScale);
        }

        object.object.position.copy(this._offset).add(object.startingPosition);

        // Apply translation snap
        if (this.translationSnap) {
          if (space === "local") {
            object.object.position.applyQuaternion(
              _tempQuaternion.copy(object.quaternionStart).invert()
            );
            if (this.axis.search("X") !== -1) {
              object.object.position.x =
                Math.round(object.object.position.x / this.translationSnap) *
                this.translationSnap;
            }
            if (this.axis.search("Y") !== -1) {
              object.object.position.y =
                Math.round(object.object.position.y / this.translationSnap) *
                this.translationSnap;
            }
            if (this.axis.search("Z") !== -1) {
              object.object.position.z =
                Math.round(object.object.position.z / this.translationSnap) *
                this.translationSnap;
            }
            object.object.position.applyQuaternion(object.quaternionStart);
          }

          if (space === "world") {
            if (object.object.parent) {
              object.object.position.add(
                _tempVector.setFromMatrixPosition(
                  object.object.parent.matrixWorld
                )
              );
            }
            if (this.axis.search("X") !== -1) {
              object.object.position.x =
                Math.round(object.object.position.x / this.translationSnap) *
                this.translationSnap;
            }
            if (this.axis.search("Y") !== -1) {
              object.object.position.y =
                Math.round(object.object.position.y / this.translationSnap) *
                this.translationSnap;
            }

            if (this.axis.search("Z") !== -1) {
              object.object.position.z =
                Math.round(object.object.position.z / this.translationSnap) *
                this.translationSnap;
            }

            if (object.object.parent) {
              object.object.position.sub(
                _tempVector.setFromMatrixPosition(
                  object.object.parent.matrixWorld
                )
              );
            }
          }
        }
      } else if (mode === "scale" && this.axis) {
        if (this.axis.search("XYZ") !== -1) {
          let d = this.pointEnd.length() / this.pointStart.length();
          if (this.pointEnd.dot(this.pointStart) < 0) d *= -1;
          _tempVector2.set(d, d, d);
        } else {
          _tempVector.copy(this.pointStart);
          _tempVector2.copy(this.pointEnd);

          _tempVector.applyQuaternion(this._worldQuaternionInv);
          _tempVector2.applyQuaternion(this._worldQuaternionInv);

          _tempVector2.divide(_tempVector);

          if (this.axis.search("X") === -1) {
            _tempVector2.x = 1;
          }
          if (this.axis.search("Y") === -1) {
            _tempVector2.y = 1;
          }
          if (this.axis.search("Z") === -1) {
            _tempVector2.z = 1;
          }
        }
        // Apply scale
        object.object.scale.copy(object.scaleStart).multiply(_tempVector2);

        if (this.scaleSnap) {
          if (this.axis.search("X") !== -1) {
            object.object.scale.x =
              Math.round(object.object.scale.x / this.scaleSnap) *
                this.scaleSnap || this.scaleSnap;
          }
          if (this.axis.search("Y") !== -1) {
            object.object.scale.y =
              Math.round(object.object.scale.y / this.scaleSnap) *
                this.scaleSnap || this.scaleSnap;
          }
          if (this.axis.search("Z") !== -1) {
            object.object.scale.z =
              Math.round(object.object.scale.z / this.scaleSnap) *
                this.scaleSnap || this.scaleSnap;
          }
        }
      } else if (mode === "rotate" && this.axis) {
        this._offset.copy(this.pointEnd).sub(this.pointStart);
        const ROTATION_SPEED =
          20 /
          this.worldPosition.distanceTo(
            _tempVector.setFromMatrixPosition(this.camera.matrixWorld)
          );
        if (axis === "E") {
          this.rotationAxis.copy(this.eye);
          this.rotationAngle = this.pointEnd.angleTo(this.pointStart);
          this._startNorm.copy(this.pointStart).normalize();
          this._endNorm.copy(this.pointEnd).normalize();
          this.rotationAngle *=
            this._endNorm.cross(this._startNorm).dot(this.eye) < 0 ? 1 : -1;
        } else if (axis === "XYZE") {
          this.rotationAxis.copy(this._offset).cross(this.eye).normalize();
          this.rotationAngle =
            this._offset.dot(
              _tempVector.copy(this.rotationAxis).cross(this.eye)
            ) * ROTATION_SPEED;
        } else if (axis === "X" || axis === "Y" || axis === "Z") {
          this.rotationAxis.copy(_unit[axis]);
          _tempVector.copy(_unit[axis]);
          if (space === "local") {
            _tempVector.applyQuaternion(this.worldQuaternion);
          }
          this.rotationAngle =
            this._offset.dot(_tempVector.cross(this.eye).normalize()) *
            ROTATION_SPEED;
        }

        // Apply rotation snap
        if (this.rotationSnap)
          this.rotationAngle =
            Math.round(this.rotationAngle / this.rotationSnap) *
            this.rotationSnap;

        // Apply rotate
        if (space === "local" && axis !== "E" && axis !== "XYZE") {
          object.object.quaternion.copy(object.quaternionStart);
          object.object.quaternion
            .multiply(
              _tempQuaternion.setFromAxisAngle(
                this.rotationAxis,
                this.rotationAngle
              )
            )
            .normalize();
        } else {
          this.rotationAxis.applyQuaternion(this._parentQuaternionInv);
          object.object.quaternion.copy(
            _tempQuaternion.setFromAxisAngle(
              this.rotationAxis,
              this.rotationAngle
            )
          );
          object.object.quaternion.multiply(object.quaternionStart).normalize();
        }
      }
    });

    this.dispatchEvent(_changeEvent);
    this.dispatchEvent(_objectChangeEvent);
  }

  pointerUp(pointer: { x: number; y: number; button: number }) {
    if (pointer.button !== 0) return;

    if (this.dragging && this.axis !== null) {
      _mouseUpEvent.mode = this.mode;
      this.dispatchEvent(_mouseUpEvent);
    }

    this.dragging = false;
    this.axis = undefined;
  }

  dispose() {
    this.domElement.removeEventListener("pointerdown", this.onPointerDown);
    this.domElement.removeEventListener("pointermove", this.onPointerHover);
    this.domElement.removeEventListener("pointermove", this.onPointerMove);
    this.domElement.removeEventListener("pointerup", this.onPointerUp);

    this.traverse(function (child) {
      if (child instanceof Mesh) {
        if (child.geometry) child.geometry.dispose();
        if (child.material instanceof MeshBasicMaterial) {
          if (child.material) child.material.dispose();
        }
      }
    });
  }

  // Set current object
  attach(object: Object3D) {
    this.objects.push(new TransformObjectWrapper(object));
    this.visible = true;
    return this;
  }

  // Detatch from object
  detach() {
    this.objects = [];
    this.visible = false;
    this.axis = undefined;
    return this;
  }

  reset() {
    if (!this.enabled) return;
    if (this.dragging && this.objects.length > 0) {
      // this.objects[this.objects.length - 1].object.position.copy( this._positionStart );
      // this.objects[this.objects.length - 1].object.quaternion.copy( this._quaternionStart );
      // this.objects[this.objects.length - 1].object.scale.copy( this._scaleStart );

      this.dispatchEvent(_changeEvent);
      this.dispatchEvent(_objectChangeEvent);
      this.pointStart.copy(this.pointEnd);
    }
  }

  getRaycaster() {
    return this._raycaster;
  }

  // TODO: deprecate

  getMode() {
    return this.mode;
  }

  setMode(mode: TransformMode) {
    this.mode = mode;
  }

  setTranslationSnap(translationSnap: number) {
    this.translationSnap = translationSnap;
  }

  setRotationSnap(rotationSnap: number) {
    this.rotationSnap = rotationSnap;
  }

  setScaleSnap(scaleSnap: number) {
    this.scaleSnap = scaleSnap;
  }

  setSize(size: number) {
    this.size = size;
  }

  setSpace(space: string) {
    this.space = space;
  }

  update() {
    console.warn(
      "THREE.TransformControls: update function has no more functionality and therefore has been deprecated."
    );
  }

  // mouse / touch event handlers

  getPointer(event: PointerEvent) {
    if (this.domElement.ownerDocument.pointerLockElement) {
      return {
        x: 0,
        y: 0,
        button: event.button,
      };
    } else {
      const rect = this.domElement.getBoundingClientRect();

      return {
        x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
        y: (-(event.clientY - rect.top) / rect.height) * 2 + 1,
        button: event.button,
      };
    }
  }

  onPointerHover(event: PointerEvent) {
    if (!this.enabled) return;

    switch (event.pointerType) {
      case "mouse":
      case "pen":
        this.pointerHover(this.getPointer(event));
        break;
    }
  }

  onPointerDown(event: PointerEvent) {
    if (!this.enabled) return;
    if (!document.pointerLockElement) {
      this.domElement.setPointerCapture(event.pointerId);
    }

    this.domElement.addEventListener(
      "pointermove",
      this.onPointerMove.bind(this)
    );

    this.pointerHover(this.getPointer(event));
    this.pointerDown(this.getPointer(event));
  }

  onPointerMove(event: PointerEvent) {
    if (!this.enabled) return;
    this.pointerMove(this.getPointer(event));
  }

  onPointerUp(event: PointerEvent) {
    if (!this.enabled) return;
    this.domElement.releasePointerCapture(event.pointerId);
    this.domElement.removeEventListener(
      "pointermove",
      this.onPointerMove.bind(this)
    );
    this.pointerUp(this.getPointer(event));
  }

  intersectObjectWithRay(
    object: Object3D,
    raycaster: Raycaster,
    includeInvisible?: boolean
  ) {
    const allIntersections = raycaster.intersectObject(object, true);
    for (let i = 0; i < allIntersections.length; i++) {
      if (allIntersections[i].object.visible || includeInvisible) {
        return allIntersections[i];
      }
    }
    return false;
  }
}
