import { Mesh, MeshBasicMaterial, TorusGeometry } from "three";
import { GizmoPart } from "./GizmoBase";

export function RotateGizmo(): { [key: string]: GizmoPart[] } {
  const gizmoMaterial = new MeshBasicMaterial({
    depthTest: false,
    depthWrite: false,
    fog: false,
    toneMapped: false,
    transparent: true,
  });

  const matRed = gizmoMaterial.clone();
  matRed.color.setHex(0xff0000);

  const matGreen = gizmoMaterial.clone();
  matGreen.color.setHex(0x00ff00);

  const matBlue = gizmoMaterial.clone();
  matBlue.color.setHex(0x0000ff);

  const matYellowTransparent = gizmoMaterial.clone();
  matYellowTransparent.color.setHex(0xffff00);
  matYellowTransparent.opacity = 0.25;

  const matGray = gizmoMaterial.clone();
  matGray.color.setHex(0x787878);

  function CircleGeometry(radius: number, arc: number) {
    const geometry = new TorusGeometry(
      radius,
      0.0075,
      3,
      64,
      arc * Math.PI * 2
    );
    geometry.rotateY(Math.PI / 2);
    geometry.rotateX(Math.PI / 2);
    return geometry;
  }

  return {
    XYZE: [
      new GizmoPart(new Mesh(CircleGeometry(0.5, 1), matGray), undefined, [
        0,
        Math.PI / 2,
        0,
      ]),
    ],
    X: [new GizmoPart(new Mesh(CircleGeometry(0.5, 0.5), matRed))],
    Y: [
      new GizmoPart(new Mesh(CircleGeometry(0.5, 0.5), matGreen), undefined, [
        0,
        0,
        -Math.PI / 2,
      ]),
    ],
    Z: [
      new GizmoPart(new Mesh(CircleGeometry(0.5, 0.5), matBlue), undefined, [
        0,
        Math.PI / 2,
        0,
      ]),
    ],
    E: [
      new GizmoPart(
        new Mesh(CircleGeometry(0.75, 1), matYellowTransparent),
        undefined,
        [0, Math.PI / 2, 0]
      ),
    ],
  };
}
