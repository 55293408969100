import { CanvasTexture, DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry } from 'three';
import { ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { ProVizEventData } from '../../../ProVizEventData';

export class ProgressBarWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'progress-bar';

  // Data
  public backgroundColor: string = '#75833f';
  public primaryColor: string = '#1a5633';
  public borderRadius: number = 0.3;
  public borderWidth: number = 0.2;
  public height: number = 2;
  public width: number = 5;

  private progress = 0;
  private canvas?: HTMLCanvasElement;
  private canvasTexture?: CanvasTexture;
  private mesh?: Mesh<PlaneGeometry, MeshBasicMaterial>;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ProgressBarWidget.type;
    this.widgetName = 'Progress Bar';
    this.label = 'Progress Bar';
    this.selectable = true;
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
    ];

    this.services.push({
      label: 'Set Progress',
      name: 'set-progress',
    });

    this.addEventListener('service-set-progress', (event: ProVizEventData) => {
      if (event.dataType === 'number') {
        this.progress = event.data as number;
        this.updateBar();
      }
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'primaryColor',
        'Primary Color',
        'Core',
        'color',
        'string',
        true,
        undefined,
        (data: string) => {
          this.primaryColor = data;
        },
      ),
      this.createProperty(
        'backgroundColor',
        'Background Color',
        'Core',
        'color',
        'string',
        true,
        undefined,
        (data: string) => {
          this.backgroundColor = data;
          this.updateBar();
        },
      ),
      this.createProperty(
        'borderRadius',
        'Border Radius',
        'Core',
        'number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.borderRadius = data;
          this.updateBar();
        },
      ),
      this.createProperty(
        'borderWidth',
        'Border Width',
        'Core',
        'number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.borderWidth = data;
          this.updateBar();
        },
      ),
      this.createProperty(
        'height',
        'Height',
        'Core',
        'number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.height = data;
          this.updateBar();
        },
      ),
      this.createProperty(
        'width',
        'Width',
        'Core',
        'number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.width = data;
          this.updateBar();
        },
      ),
    ];
  }

  private updateBar() {
    if (!this.canvas || !this.canvasTexture) {
      return;
    }
    const { backgroundColor, borderRadius, borderWidth, height, width } = this;
    this.canvas.width = this.width * 64;
    this.canvas.height = this.height * 64;

    const ctx = this.canvas.getContext('2d');
    if (!ctx) {
      console.error('Could not get canvas ctx');
      return;
    }
    const doubleBorderWidth = borderWidth * 2;
    this.drawRectangle(ctx, 0, 0, height * 64, width * 64, borderRadius * 64, backgroundColor);
    if (this.progress > 0) {
      let ratio = this.progress > 1 ? 1 : this.progress;
      this.drawRectangle(
        ctx,
        borderWidth * 64,
        borderWidth * 64,
        (height - doubleBorderWidth) * 64,
        (width - doubleBorderWidth) * ratio * 64,
        borderRadius * 64,
        this.primaryColor,
      );
    }
    this.canvasTexture.needsUpdate = true;
  }

  private drawRectangle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    height: number,
    width: number,
    radius: number,
    color: string,
  ) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();
  }

  public serialize(): any {
    const result = super.serialize();
    result.primaryColor = this.primaryColor;
    result.backgroundColor = this.backgroundColor;
    result.borderRadius = this.borderRadius;
    result.borderWidth = this.borderWidth;
    result.height = this.height;
    result.width = this.width;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.primaryColor = data.primaryColor;
    this.backgroundColor = data.backgroundColor;
    this.borderRadius = data.borderRadius;
    this.borderWidth = data.borderWidth;
    this.height = data.height;
    this.width = data.width;
  }

  public getClickable() {
    const result = super.getClickable();
    result.push(this.renderNode);
    return result;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.scene.sceneMode === SceneMode.Editor) {
      // in editor we set the progress to above half so that
      // it's easier to see the effect of editting settings
      this.progress = 0.6;
    }
    this.canvas = document.createElement('canvas');
    this.canvas.width = this.width * 64;
    this.canvas.height = this.height * 64;
    this.canvasTexture = new CanvasTexture(this.canvas);
    const geometry = new PlaneGeometry(this.width, this.height);
    const material = new MeshBasicMaterial({
      map: this.canvasTexture,
      side: DoubleSide,
      transparent: true,
    });
    this.mesh = new Mesh(geometry, material);
    this.mesh.renderOrder = 3;
    this.renderNode.add(this.mesh);
    this.updateBar();
    return true;
  }

  public dispose(): void {
    super.dispose();
    this.canvas?.remove();
    this.canvasTexture?.dispose();
    delete this.canvasTexture;
    this.mesh?.material.dispose();
  }
}

ModuleService.Register(ProgressBarWidget.type, ProgressBarWidget);
