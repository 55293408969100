interface Props {
    value: string;
    handleChange: (e: any) => void;
}

export const SideInput = (props: Props) => {
    const { value, handleChange } = props;
    return <>
        <p>Side</p>
        <select value={value || ''} onChange={(e) => handleChange(e.target.value)} >
            <option value='Double'>Double Sided</option>
            <option value='Front'>Front Side</option>
            <option value='Back'>Back Side</option>
        </select>
    </>;
}
