import { AmbientLight } from 'three';
import { BaseWidgetProperty } from '..';
import { ModuleService } from '../../moduleService';
import { ProVizScene } from '../..';
import { BaseWidget } from '../baseWidget';
import { IBaseWidgetType } from '../IBaseWidgetType';

export class AmbientLightWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'ambient-light';

  // Data
  public color: string = '#fff';
  public intensity: number = 1;

  private light?: AmbientLight;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = AmbientLightWidget.type;
    this.widgetName = 'Ambient Light';
    this.label = 'Ambient Light';
    this.category = 'Lights';
    this.selectable = false;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.light = new AmbientLight(this.color, this.intensity);
    this.renderNode.add(this.light);

    return true;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('color', 'Color', 'Core', 'color', 'string', true, undefined, (data: string) => {
        this.color = data;
        if (this.light) {
          this.light.color.set(this.color);
        }
      }),
      this.createProperty(
        'intensity',
        'Intensity',
        'Core',
        'constrained-number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.intensity = data;
          if (this.light) {
            this.light.intensity = this.intensity;
          }
        },
        () => [0, 3].map(x => { return { key: x, label: x.toString() }}),
      ),
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.color = this.color;
    result.intensity = this.intensity;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.color = data.color;
    this.intensity = data.intensity;
  }
}

ModuleService.Register(AmbientLightWidget.type, AmbientLightWidget);
