import React, { ReactElement, useState } from 'react'
import { searchStore } from '../../../store/SearchStore';

import './SearchButton.css'

interface Props  {
    setSearchParams: (search: string) => void;
}

export default function SearchButton(props: Props): ReactElement {
    const [debounceRequest, setDebounceRequest] = useState<NodeJS.Timeout | undefined>(undefined);

    const startingSearch = searchStore(x => x.search);
    const [ search, setSearch ] = useState(startingSearch || '');
    
    function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
        setSearch(event.target.value);
        if (debounceRequest) {
            clearTimeout(debounceRequest);
        }

        setDebounceRequest(setTimeout(() => {
            props.setSearchParams(event.target.value);
        }, 1000));
    }

    return <div className="searchInput">
        <span aria-hidden="true" className="icon-search1"></span>
        <input type='text' value={search} onChange={handleInput} />
    </div>;
}
