export const is_chrome = /chrome/i.test(navigator.userAgent);

export const is_android = /Android/i.test(navigator.userAgent);

// Prior to iOS 13, detecting iOS Safari was relatively straight-forward.
// As of iOS 13, Safari on iPad (in its default configuration) reports the same
// user-agent string as Safari on desktop MacOS.So, we must rely on
// known, detectable signals to distinguish iOS Safari from MacOS Safari. At the
// time of this writing, there are no non-iOS/iPadOS Apple devices with
// multi-touch displays.
// @see https://stackoverflow.com/questions/57765958/how-to-detect-ipad-and-ipad-os-version-in-ios-13-and-up
// @see https://forums.developer.apple.com/thread/119186
// @see https://github.com/google/model-viewer/issues/758
export const is_ios =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !(window as any).MSStream);
export const is_mobile = is_android || is_ios;

const uA = navigator.userAgent;
const vendor = navigator.vendor;
export const is_desktop_safari =
  /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);

export const is_landscape = () => {
  if (is_ios) {
    if (typeof window.onorientationchange === 'undefined') {
      return false;
    }
    return window.orientation == 90 || window.orientation == -90;
  }
  if (is_android) {
    const orientation =
      // @ts-ignore
      (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
    return orientation === 'landscape-primary' || orientation === 'landscape-secondary';
  }
  return false;
};

export const is_arquicklook_candidate = (() => {
  const tempAnchor = document.createElement('a');

  return Boolean(
    tempAnchor.relList && tempAnchor.relList.supports && tempAnchor.relList.supports('ar'),
  );
})();

// @see https://developer.chrome.com/multidevice/user-agent
export const is_safari = /Safari\//.test(navigator.userAgent);
export const is_firefox = /firefox/i.test(navigator.userAgent);
export const is_oculus = /OculusBrowser/.test(navigator.userAgent);
export const is_ios_chrome = is_ios && /CriOS\//.test(navigator.userAgent);
export const is_ios_safari = is_ios && is_safari;

export const is_sceneviewer_candidate = is_android && !is_firefox && !is_oculus;

// @ts-ignore
const { fullscreenEnabled, webkitFullscreenEnabled, msFullscreenEnabled } = document;
export const is_fullscreen_candidate =
  fullscreenEnabled || webkitFullscreenEnabled || msFullscreenEnabled;
