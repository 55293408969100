import { BaseWidgetProperty, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { GUIBaseWidget } from '../guiBaseWidget';

export class GUITextWidget extends GUIBaseWidget implements IBaseWidgetType {
  public static type: string = 'gui-text';

  // Data
  private content: string = 'Text';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = GUITextWidget.type;
    this.widgetName = 'GUI Text';
    this.label = 'GUI Text';
    this.category = 'UI';
    this.events = [
      {
        label: 'Clicked',
        name: 'clicked',
      },
    ];
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('content', 'Content', 'Core', 'string', 'string', true, undefined, (data) => {
        this.content = data;
        if (this.guiElement) {
          this.guiElement.innerHTML = this.content;
        }
      }),
    ];
  }

  public serialize() {
    const result = super.serialize();
    result.content = this.content;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.content = data.content ?? this.content;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.parent) {
      const gui = this.parent as GUIBaseWidget;
      const guiParent = gui.guiElement;

      this.guiElement = document.createElement('span');
      this.guiElement.setAttribute('class', 'gui-text');
      const style = `
        cursor: pointer;
        pointer-events: all;
        user-select: none;
      `;
      this.guiElement.innerHTML = this.content;
      if (this.scene.sceneMode === SceneMode.Editor) {
        this.guiElement.onclick = () => {
          // select this widget
        };
        this.guiElement.setAttribute('draggable', 'true');
        this.guiElement.ondragstart = (ev: DragEvent) => {
          ev.dataTransfer?.setData('node/id', this.id);
          this.setDraggingMode(true);
        };
        this.guiElement.ondragend = (ev: DragEvent) => {
          this.setDraggingMode(false);
        };
      } else {
        this.guiElement.onclick = () => {
          this.triggerProVizEvent('clicked', 'none');
        };
      }
      this.guiElement.setAttribute('style', style);
      guiParent?.appendChild(this.guiElement);
    }

    return true;
  }
}

ModuleService.Register(GUITextWidget.type, GUITextWidget);
