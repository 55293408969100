import { ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class ToolbeltWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'toolbelt';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ToolbeltWidget.type;
    this.widgetName = 'Toolbelt';
    this.label = 'Toolbelt';
    this.selectable = true;
    this.category = 'Experimental';
  }

  public update(delta: number): void {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return;
    }
    this.renderNode.position.set(
      this.scene.camera.position.x,
      this.scene.camera.position.y,
      this.scene.camera.position.z);

      this.renderNode.quaternion.set(
        this.scene.camera.quaternion.x,
        this.scene.camera.quaternion.y,
        this.scene.camera.quaternion.z,
        this.scene.camera.quaternion.w)
  }
}

ModuleService.Register(ToolbeltWidget.type, ToolbeltWidget);
