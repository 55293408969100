import { BaseDataService, IServiceOptions } from "../BaseDataService";
import { APIMaterialParameter } from "../models";
import { MaterialParameterType } from "../types";

export class APIMaterialParameterService extends BaseDataService<APIMaterialParameter> {
  constructor() {
    super("materialparameter");
  }

  // This method assigns a new texture value to an existing material parameter
  public async assignTextureToMaterialParameter(
    materialParameterId: string,
    textureId: string | null,
    options?: IServiceOptions
  ): Promise<APIMaterialParameter> {
    const body = new FormData();
    body.append("textureId", textureId === null ? "" : textureId);
    return await this.submit(
      `${materialParameterId}/texture`,
      "PUT",
      body,
      options
    );
  }

  // This method creates a new material parameter of the supplied type and assigns
  // the supplied texture value to it.
  public async assignTextureToMaterial(
    materialId: string,
    textureId: string,
    mpType: MaterialParameterType,
    options?: IServiceOptions
  ): Promise<APIMaterialParameter> {
    const body = new FormData();
    body.append("textureId", textureId === null ? "" : textureId);
    return await this.request(`${materialId}/texture/${textureId}/${mpType}`, {
      method: "POST",
      ...options,
    });
  }

  // This creates a material parameter with an empty texture field
  public async createMaterialParam(
    materialId: string,
    mpType: MaterialParameterType,
    float: number,
    str: string,
    options?: IServiceOptions
  ): Promise<APIMaterialParameter> {
    const body = new FormData();
    body.append("floatValue", float.toString());
    body.append("stringValue", str);
    return await this.submit(`${materialId}/${mpType}`, "POST", body, options);
  }

  // Updates either the string or float values of a material parameter
  public async updateMaterialParam(
    mpId: string,
    value: string | number,
    valueType: "string" | "float",
    options?: IServiceOptions
  ): Promise<APIMaterialParameter> {
    const body = new FormData();
    body.append("value", value.toString());
    return await this.submit(
      `${mpId}/update/${valueType}`,
      "PUT",
      body,
      options
    );
  }
}

const MaterialParameterService = new APIMaterialParameterService();

export default MaterialParameterService;
