
import { ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import * as THREE from 'three'

export class EighthWall extends BaseWidget implements IBaseWidgetType {
  public static type: string = '8th-wall';

  private static scriptElement?: HTMLScriptElement;
  //data
  private appKey: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = EighthWall.type;
    this.widgetName = '8th Wall';
    this.label = '8th Wall';
    this.category = 'Integration';
    this.events = [{
      label: 'Loaded',
      name: 'loaded',
      desc: 'When 8thWall has been loaded'
    }];

    this.services = [];

    //Keep this as an example
    // this.addService('Show', 'show', 'Show the image', (event: ProVizEventData) => {
    //   this.visible = true;
    //   this.setSettings();
    // });

  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('appKey', 'App Key', 'Core', 'string', 'string', true),
    ];
  }

  setSettings(xr8: any) {
    //Set up camera and stuff

    //Add all the things.
    if (xr8) {
      const { XR8 } = xr8;
      XR8.addCameraPipelineModule(XR8.GlTextureRenderer.pipelineModule())
      XR8.addCameraPipelineModule(XR8.Threejs.pipelineModule())
      XR8.addCameraPipelineModule(XR8.XrController.pipelineModule()) // Required for SLAM tracking
      // Add a GlTextureRenderer which draws the camera feed to the canvas.
      XR8.addCameraPipelineModule({
        name: 'custom',
        onStart: () => {
          console.log("start")
          const { scene, camera } = XR8.Threejs.xrScene()
          //Change the scene camera to the one from 8th wall
          this.scene.camera = camera;
          //Sync the xr controller with the scene camera
          //We should also use this to use VPS and some other features.
          //More info: https://www.8thwall.com/docs/web/#xr8xrcontrollerconfigure
          XR8.XrController.updateCameraProjectionMatrix({
            origin: this.scene.camera.position,
            facing: this.scene.camera.quaternion,
          })

          //Add a sphere to the scene
          const geometry = new THREE.SphereGeometry(0.5, 32, 32);
          const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
          const sphere = new THREE.Mesh(geometry, material);
          // set sphere to 0,0,0
          sphere.position.set(0, 0, 0);
          scene.add(sphere);

          // this.scene.renderer = renderer;
          this.scene.setScene(scene);
        },
        onCameraUpdate: () => {
          //Not sure what's supposed to go into here.

        }
      });

      XR8.addCameraPipelineModule(XR8.XrController.pipelineModule())

      XR8.initialize().then(
        () => {
          if (this.scene.renderer?.domElement) {
            console.log("Canvas exists, running XR8");
            XR8.run({ canvas: this.scene.renderer?.domElement });
          }
        }
      )

    } else {
      throw new Error('8th Wall not loaded');
    }
  }

  public async init(): Promise<boolean> {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }
    return new Promise((resolve, reject) => {

      //Check if script is loaded
      if (!EighthWall.scriptElement) {
        let script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.type = 'text/javascript';
        script.src = 'https://8thwall.app/xrweb?appKey=' + this.appKey;
        EighthWall.scriptElement = script;

        let addonScript = document.createElement('script');
        addonScript.async = true;
        addonScript.defer = true;
        addonScript.type = 'text/javascript';
        addonScript.src = "https://cdn.8thwall.com/web/xrextras/xrextras.js";

        //Add three to window
        window['THREE'] = THREE;

        document.head.append(script);
        document.head.append(addonScript);

        const get8thWall = async () => {
          const g: any = global;
          return new Promise((resolve, reject) => {
            const failTimeout = setTimeout(() => {
              reject('Timeout');
              clearInterval(interval);
            }, 3000);

            const interval = setInterval(() => {
              if (g.XR8 && g.XRExtras) {
                clearTimeout(failTimeout);
                clearInterval(interval);
                // resolve(g.XR8);
                resolve({ XR8: g.XR8, XRExtras: g.XRExtras });
              }
            }, 100);
          });
        }

        script.addEventListener("load", async () => {
          const XR8 = await get8thWall();
          this.setSettings(XR8);
          this.triggerProVizEvent('loaded', 'boolean', true);
          super.init();
          resolve(true);
        });
      }
    });
  }

  public serialize() {
    const result = super.serialize();
    result.appKey = this.appKey;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.appKey = data.appKey;
  }
}

ModuleService.Register(EighthWall.type, EighthWall);
