import { ReactElement, ReactNode } from 'react'

interface Props {
    children: ReactNode; 
}

export default function EditorToolbar(props: Props): ReactElement {
    return <div className='editorToolbar'>
            {props.children}
        </div>;
}
