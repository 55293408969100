import React, { useEffect } from "react";
import {
  BaseWidget,
  ModelWidget,
  BaseWidgetProperty,
} from "@proviz/proviz-sdk";
import { Object3D, Vector2, Vector3, Vector4 } from "three";
import SinglePanoramaUpload from "./SinglePanoramaUpload";
import MultiPanoramaUpload from "./MultiPanoramaUpload";
import SetWidgetProperty from "../../../commands/SetWidgetProperty";
import InfoButton from "../../common/infobutton/InfoButton";
import { FileOptionsProperty } from "./FileOptionsProperty";
import { MultiLanguageList } from "./MultiLanguageList";
import { ModelVariantSelector } from "./ModelVariantSelector";
import { ImmutablePropertyRender } from "./ImmutablePropertyRender";
import { isSettable } from "./SettableWidgetProperty";
import { SpriteSelector } from "./SpriteSelector";
import { MultiLangTextProperty } from "./MultiLangTextProperty";
import ColorBox from "../../common/texturebox/ColorBox";
import ModelViewManager from "../../../graphics/ModelViewManager";
import SceneManager from "../../../graphics/SceneManager";
import TransformProperty from "./TransformProperty";
import { ConstrainedNumberProperty } from "./ConstrainedNumberProperty";
import { FileProperty } from "./FileProperty";
import Button from "../../common/button/Button";
import ToggleFlip from "../../common/toggleFlip/ToggleFlip";
import { ModelSelector } from "./ModelSelector";
import { WidgetSelect } from "./WidgetSelect";
import { AreaTargetSelector } from "./AreaTargetSelector";
import { ModelTargetSelector } from "./ModelTargetSelector";
import { WidgetSelectList } from "./WidgetSelectList";
import { Tween } from "./Tween";
import FourValInput from "../../common/threevalinput/FourValInput";
import RotationInput from "../../common/RotationInput";
import ThreeValInput from "../../common/threevalinput/ThreeValInput";
import TwoValInput from "../../common/threevalinput/TwoValInput";
import { ResourceManagerStore } from "../../../store/ResourceStore";

interface Props {
  properties: BaseWidgetProperty[];
  node?: BaseWidget;
  manager: ModelViewManager | SceneManager;
  supressedProperties?: string[];
}

export default function PropertyRenderer(props: Props) {
  const { manager } = props;
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const { properties, node, supressedProperties } = props;
  const openFileSelect = ResourceManagerStore(s => s.openFileSelect) ?? {
    openFileSelect: undefined,
  };

  useEffect(() => {
    manager.attach("history-update", forceUpdate);
    return () => {
      manager.detach("history-update", forceUpdate);
    };
  }, [manager, forceUpdate]);

  const renderChildSelector = (
    o: Object3D,
    childValues: string[],
    property: BaseWidgetProperty
  ) => {
    const childSelectedInd = childValues.indexOf(o.userData.keyName);
    return (
      <div key={o.uuid} className="renderNodeEntry">
        <input
          type="checkbox"
          checked={childSelectedInd > -1}
          onChange={() => {
            if (childSelectedInd > -1) {
              childValues.splice(childSelectedInd, 1);
            } else {
              childValues.push(o.userData.keyName);
            }
            if (property.set) {
              property.set(childValues);
            }
            forceUpdate();
          }}
        />
        <span>{o.userData.keyName || o.name}</span>
        <div className="childNodes">
          {o.children.map((c: Object3D) =>
            renderChildSelector(c, childValues, property)
          )}
        </div>
      </div>
    );
  };

  const propertyRenderer = (property: BaseWidgetProperty) => {
    if (supressedProperties?.includes(property.name)) {
      return <></>;
    }

    const key: string = `${property.name}-${node?.id}`;
    if (!property.editable) {
      return <ImmutablePropertyRender property={property} />;
    }

    const header = (
      <div className="property-header">
        <h4
          style={{ flexGrow: 1 }}
          onClick={(e) => {
            function isSceneManager(manager: any): manager is SceneManager {
              return manager.clipboardProperty;
            }
            if (isSettable(property) && isSceneManager(manager)) {
              manager.clipboardProperty(property);
              forceUpdate();
            }
          }}
        >
          {property.label}
        </h4>
        {property.infoText && <InfoButton infoText={property.infoText} />}
      </div>
    );
    const languages = manager.proVizScene.languages;
    if (isSettable(property)) {
      switch (property.widgetType) {
        case "vec2": {
          const val: Vector2 = property.get();
          return (
            <div>
              {header}
              <TwoValInput
                input1={{
                  id: `${property.name.substring(0, 1)}-x`,
                  value: val.x,
                  label: "x",
                }}
                input2={{
                  id: `${property.name.substring(0, 1)}-y`,
                  value: val.y,
                  label: "y",
                }}
                onChange={(newVal: { x: number; y: number }) => {
                  const { x, y } = val;
                  manager.execute(
                    new SetWidgetProperty(property, { x, y }, newVal, manager)
                  );
                  forceUpdate();
                }}
              />
            </div>
          );
        }
        case "vec3": {
          return (
            <TransformProperty
              property={property}
              header={header}
              forceUpdate={forceUpdate}
              manager={manager}
            />
          );
        }
        case "vec3-radian": {
          const val: Vector3 = property.get();
          return (
            <div>
              {header}
              <RotationInput
                rotation={{ x: val.x, y: val.y, z: val.z }}
                setRotation={(newVal: { x: number; y: number; z: number }) => {
                  const { x, y, z } = val;
                  manager.execute(
                    new SetWidgetProperty(
                      property,
                      { x, y, z },
                      newVal,
                      manager
                    )
                  );
                  forceUpdate();
                }}
              />
            </div>
          );
        }
        case "vec3-camera-rot": {
          const val: Vector3 = property.get();
          // Options [0] should be the default value
          const options = property.options
            ? property.options()
            : [{ x: 0, y: 0, z: 0 }];
          return (
            <div>
              {header}
              <RotationInput
                rotation={{ x: val.x, y: val.y, z: val.z }}
                setRotation={(newVal: { x: number; y: number; z: number }) => {
                  const { x, y, z } = val;
                  manager.execute(
                    new SetWidgetProperty(
                      property,
                      { x, y, z },
                      newVal,
                      manager
                    )
                  );
                  forceUpdate();
                }}
              />
              <div
                className="flex"
                style={{ width: "100%", placeContent: "center" }}
              >
                <Button
                  onClick={() => {
                    const { x, y, z } = manager.camera.camera.rotation;
                    manager.execute(
                      new SetWidgetProperty(property, val, { x, y, z }, manager)
                    );
                  }}
                >
                  Set From Camera
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    manager.execute(
                      new SetWidgetProperty(property, val, options[0], manager)
                    );
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
          );
        }
        case "vec3-camera-pos": {
          const val: Vector3 = property.get();
          // Options [0] should be the default value
          const options = property.options
            ? property.options()
            : [{ x: 0, y: 0, z: 0 }];
          return (
            <div>
              {header}
              <ThreeValInput
                input1={{
                  id: `${property.name.substring(0, 1)}-x`,
                  value: val.x,
                  label: "x",
                }}
                input2={{
                  id: `${property.name.substring(0, 1)}-y`,
                  value: val.y,
                  label: "y",
                }}
                input3={{
                  id: `${property.name.substring(0, 1)}-z`,
                  value: val.z,
                  label: "z",
                }}
                onChange={(newVal: { x: number; y: number; z: number }) => {
                  const { x, y, z } = val;
                  manager.execute(
                    new SetWidgetProperty(
                      property,
                      { x, y, z },
                      newVal,
                      manager
                    )
                  );
                  forceUpdate();
                }}
              />
              <div
                className="flex"
                style={{ width: "100%", placeContent: "center" }}
              >
                <Button
                  onClick={() => {
                    manager.execute(
                      new SetWidgetProperty(
                        property,
                        val,
                        manager.camera.camera.position,
                        manager
                      )
                    );
                  }}
                >
                  Set From Camera
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    manager.execute(
                      new SetWidgetProperty(property, val, options[0], manager)
                    );
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
          );
        }
        case "vec4": {
          const val: Vector4 = property.get();
          return (
            <div>
              {header}
              <FourValInput
                key={`wdg-p-v4-${property.label.substring(0, 2)}`}
                input1={{
                  id: `${property.name.substring(0, 1)}-x`,
                  value: val.x,
                  label: "x",
                }}
                input2={{
                  id: `${property.name.substring(0, 1)}-y`,
                  value: val.y,
                  label: "y",
                }}
                input3={{
                  id: `${property.name.substring(0, 1)}-z`,
                  value: val.z,
                  label: "z",
                }}
                input4={{
                  id: `${property.name.substring(0, 1)}-w`,
                  value: val.w,
                  label: "w",
                }}
                onChange={(newVal: {
                  x: number;
                  y: number;
                  z: number;
                  w: number;
                }) => {
                  const { x, y, z, w } = val;
                  manager.execute(
                    new SetWidgetProperty(
                      property,
                      { x, y, z, w },
                      newVal,
                      manager
                    )
                  );
                  forceUpdate();
                }}
              />
            </div>
          );
        }
        case "font": {
          return header;
        }
        case "button":
          return (
            <div className="button-property">
              {header}
              <Button
                onClick={async () => {
                  // These functions are actually async - should be addressed in type signature
                  await property.set(null, () => manager.render());
                  forceUpdate();
                }}
              >
                {property.get() || ""}
              </Button>
            </div>
          );
        case "bool": {
          const onOff: boolean = property.get();
          let options: { key: string | number | boolean, label: string }[] = (property.options && property.options()) || [
            { key: 'on', label: "on" },
            { key: 'off', label: "off" },
          ];
          if (options.length !== 2) {
            options = [{ key: 'on', label: "on" },
            { key: 'off', label: "off" },];
          }
          return (
            <div>
              {header}
              <ToggleFlip
                setOnOff={onOff}
                onChange={(state: boolean) => {
                  manager.execute(
                    new SetWidgetProperty(property, onOff, state, manager)
                  );
                  forceUpdate();
                }}
              />
            </div>
          );
        }
        case "number":
          const numericValue: number = property.get();
          return (
            <div>
              {header}
              <div className="numeric" key={`widget-prop-${key}`}>
                <input
                  type="number"
                  value={numericValue}
                  onChange={(e) => {
                    manager.execute(
                      new SetWidgetProperty(
                        property,
                        numericValue,
                        parseFloat(e.target.value),
                        manager
                      )
                    );
                    forceUpdate();
                  }}
                />
              </div>
            </div>
          );
        case "constrained-number":
          return (
            <ConstrainedNumberProperty
              key={`widget-prop-${key}`}
              header={header}
              property={property}
              manager={manager}
            />
          );
        case "color":
          const colorValue: string = property.get() ?? "#FFF";
          return (
            <div key={`widget-prop-${key}`}>
              {header}
              <div className="textureColorBoxes">
                <ColorBox
                  hideTitle={true}
                  isPropertyRenderer={true}
                  color={colorValue}
                  setColor={(colorWithAlpha) => {
                    const color = colorWithAlpha.substring(0, 7);
                    manager.execute(
                      new SetWidgetProperty(
                        property,
                        colorValue,
                        color,
                        manager
                      )
                    );
                    forceUpdate();
                  }}
                />
              </div>
            </div>
          );
        case "string":
          const stringValue: string = property.get();
          return (
            <div key={`widget-prop-${key}`}>
              {header}
              <div className="numeric" key={`widget-prop-${key}`}>
                <input
                  type="text"
                  value={stringValue}
                  onChange={(e) => {
                    manager.execute(
                      new SetWidgetProperty(
                        property,
                        stringValue,
                        e.target.value,
                        manager
                      )
                    );
                    forceUpdate();
                  }}
                />
              </div>
            </div>
          );
        case "multi-line-string":
          const textValue: string = property.get();
          return (
            <div key={`widget-prop-${key}`}>
              {header}
              <div className="numeric" key={`widget-prop-${key}`}>
                <textarea
                  value={textValue}
                  onChange={(e) => {
                    manager.execute(
                      new SetWidgetProperty(
                        property,
                        textValue,
                        e.target.value,
                        manager
                      )
                    );
                    forceUpdate();
                  }}
                />
              </div>
            </div>
          );
        case "child-select":
          const childValues: string[] = property.get() || [];
          return (
            <div className="modelChildSelect">
              {header}
              <div key={`widget-prop-${key}`}>
                {node &&
                  node.renderNode.children.length > 0 &&
                  renderChildSelector(
                    node.renderNode.children[0],
                    childValues,
                    property
                  )}
              </div>
            </div>
          );
        case "select":
          const options: { key: string | number | boolean, label: string }[] =
            (property.options && property.options()) || [];
          console.log(options);
          return (
            <div className="modelChildSelect" key={`widget-prop-${key}`}>
              {header}
              <div>
                <select
                  key={`widget-prop-select-${key}`}
                  value={property.get()}
                  onChange={(e) => {
                    manager.execute(
                      new SetWidgetProperty(
                        property,
                        options,
                        e.target.value,
                        manager
                      )
                    );
                    forceUpdate();
                  }}
                >
                  {options.map((o) => (
                    <option key={`${key}-${o.key.toString()}`} value={o.key.toString()}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          );
        case "model-variant":
          // @ts-ignore
          if (node.modelId) {
            return (
              <ModelVariantSelector
                key={`widget-prop-${key}`}
                node={node as ModelWidget}
                property={property}
                header={header}
              />
            );
          } else {
            return <div>Select a model to select it's variant.</div>;
          }
        case "model":
          return (
            <ModelSelector
              header={header}
              property={property}
              forceUpdate={forceUpdate}
            />
          );
        case "scene":
          const sceneValue = property.get();
          return (
            <div className="sceneSelector" key={`widget-prop-${key}`}>
              {header}
              <div>{sceneValue}</div>
              <Button
                fullWidth={true}
                type="resource"
                onClick={() => {
                  console.log("scene change");
                  openFileSelect &&
                    openFileSelect("Scene", false, (id: string | null) => {
                      manager.execute(
                        new SetWidgetProperty(property, sceneValue, id, manager)
                      );
                      forceUpdate();
                    });
                }}
              >
                Change
              </Button>
            </div>
          );
        case "sprite":
          return (
            <SpriteSelector
              key={`widget-prop-${key}`}
              header={header}
              property={property}
              forceUpdate={forceUpdate}
            />
          );
        case "texture-360":
          const texSrc = property.get();
          if (!!texSrc || texSrc.isEquirectangular) {
            return (
              <ul key={`widget-prop-${key}`}>
                <li>
                  {header}
                  <SinglePanoramaUpload
                    key={`widget-prop-${key}`}
                    textureId={texSrc.id}
                    setTextureId={property.set}
                  />
                </li>
              </ul>
            );
          }
          if (texSrc.isSkybox) {
            return (
              <ul key={`widget-prop-${key}`}>
                <li>
                  {header}
                  <MultiPanoramaUpload
                    key={`widget-prop-${key}`}
                    textureSources={texSrc}
                    setTextures={property.set}
                  />
                </li>
              </ul>
            );
          }
          break;
        case "options": {
          const curr: string = property.get() || "";
          const keyValues = curr.split(",");

          return (
            <div key={`widget-prop-${key}`} className="property-table">
              {header}
              <table>
                <tbody>
                  {keyValues.map((keyValue: string, ind: number) => {
                    const parts = keyValue.split(":");
                    if (parts.length === 1) {
                      parts.push("");
                    }
                    let content;
                    try {
                      content = decodeURIComponent(parts[1]);
                    } catch (e) {
                      content = "";
                    }
                    return (
                      <tr key={`${property.name}-${ind}`}>
                        <td>
                          <input
                            type="text"
                            value={parts[0]}
                            onChange={(e) => {
                              parts[0] = e.target.value;
                              keyValues[ind] = parts.join(":");
                              manager.execute(
                                new SetWidgetProperty(
                                  property,
                                  curr,
                                  keyValues.join(","),
                                  manager
                                )
                              );
                              forceUpdate();
                            }}
                          ></input>
                        </td>
                        <td>
                          <input
                            type="text"
                            value={content}
                            onChange={(e) => {
                              parts[1] = encodeURIComponent(e.target.value);
                              keyValues[ind] = parts.join(":");
                              manager.execute(
                                new SetWidgetProperty(
                                  property,
                                  curr,
                                  keyValues.join(","),
                                  manager
                                )
                              );
                              forceUpdate();
                            }}
                          ></input>
                        </td>
                        <td>
                          <Button
                            type="icon"
                            onClick={() => {
                              keyValues.splice(ind, 1);
                              manager.execute(
                                new SetWidgetProperty(
                                  property,
                                  curr,
                                  keyValues.join(","),
                                  manager
                                )
                              );
                              forceUpdate();
                            }}
                          >
                            X
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <br />
              <Button
                fullWidth={true}
                onClick={() => {
                  manager.execute(
                    new SetWidgetProperty(property, curr, curr + ",", manager)
                  );
                  forceUpdate();
                }}
              >
                Add Option
              </Button>
            </div>
          );
        }
        case "options-array": {
          const curr: { key: string; default: string }[] = property.get() || [];

          return (
            <div key={`widget-prop-${key}`} className="property-table">
              {header}
              <table>
                <tbody>
                  {curr.map(
                    (entry: { key: string; default: string }, ind: number) => {
                      let content;
                      try {
                        content = decodeURIComponent(entry.default);
                      } catch (e) {
                        content = "";
                      }
                      return (
                        <tr key={`${property.name}-${ind}`}>
                          <td>
                            <input
                              type="text"
                              value={entry.key}
                              onChange={(e) => {
                                curr[ind].key = e.target.value;
                                if (property.set) {
                                  property.set(curr);
                                }
                                forceUpdate();
                              }}
                            ></input>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={content}
                              onChange={(e) => {
                                curr[ind].default = encodeURIComponent(
                                  e.target.value
                                );
                                if (property.set) {
                                  property.set(curr);
                                }
                                forceUpdate();
                              }}
                            ></input>
                          </td>
                          <td>
                            <Button
                              type="icon"
                              onClick={() => {
                                curr.splice(ind, 1);
                                if (property.set) {
                                  property.set(curr);
                                }
                                forceUpdate();
                              }}
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              <br />
              <Button
                onClick={() => {
                  manager.execute(
                    new SetWidgetProperty(
                      property,
                      curr,
                      [...curr, { key: "", default: "" }],
                      manager
                    )
                  );
                  forceUpdate();
                }}
              >
                Add Option
              </Button>
            </div>
          );
        }
        case "video-options":
          return (
            <FileOptionsProperty
              node={node}
              key={`widget-prop-${key}`}
              property={property}
              languages={languages}
              header={header}
              fileType={"Video"}
            />
          );
        case "audio-options":
          return (
            <FileOptionsProperty
              node={node}
              key={`widget-prop-${key}`}
              property={property}
              languages={languages}
              header={header}
              fileType={"Audio"}
            />
          );
        case "area-target":
          return (
            <AreaTargetSelector
              header={header}
              property={property}
              forceUpdate={forceUpdate}
            />
          );
        case "model-target":
          return (
            <ModelTargetSelector
              header={header}
              property={property}
              forceUpdate={forceUpdate}
            />
          );
        case "image-options":
          return (
            <FileOptionsProperty
              node={node}
              key={`widget-prop-${key}`}
              property={property}
              languages={languages}
              header={header}
              fileType={"Image"}
              entityName="Image"
            />
          );
        case "image":
          return (
            <FileProperty
              key={`widget-prop-${key}`}
              property={property}
              header={header}
              fileType={"Image"}
              entityName={property.name}
            />
          );
        case "env-map":
          return (
            <FileProperty
              key={`widget-prop-${key}`}
              property={property}
              header={header}
              fileType={"EnvMap"}
              entityName="Environment Lighting Map"
            />
          );
        case "srt-options":
          return (
            <FileOptionsProperty
              node={node}
              key={`widget-prop-${key}`}
              property={property}
              languages={languages}
              header={header}
              fileType={"Subtitle"}
              entityName="Subtitles"
            />
          );
        case "multi-lang-opts": {
          // New style uses a json object not a string to store
          // dictionary.
          return (
            <MultiLangTextProperty
              key={`widget-prop-${key}`}
              languages={languages}
              property={property}
              header={header}
              multiLine={false}
            />
          );
        }
        case "multi-lang-opts-multi-line": {
          // New style uses a json object not a string to store
          // dictionary.
          return (
            <MultiLangTextProperty
              key={`widget-prop-${key}`}
              languages={languages}
              property={property}
              header={header}
              multiLine={true}
            />
          );
        }
        case "list": {
          const curr: string[] = property.get() || [];

          return (
            <div key={`widget-prop-${key}`} className="property-table">
              {header}
              <table>
                <tbody>
                  {curr.map((keyValue: string, ind: number) => {
                    return (
                      <tr key={`${property.name}-${ind}`}>
                        <td>
                          <input
                            type="text"
                            value={keyValue}
                            onChange={(e) => {
                              const old = Object.assign({}, curr);
                              curr[ind] = e.target.value;
                              manager.execute(
                                new SetWidgetProperty(
                                  property,
                                  old,
                                  curr,
                                  manager
                                )
                              );
                              forceUpdate();
                            }}
                          ></input>
                        </td>
                        <td>
                          <Button
                            type="icon"
                            onClick={() => {
                              const old = Object.assign({}, curr);
                              curr.splice(ind, 1);
                              manager.execute(
                                new SetWidgetProperty(
                                  property,
                                  old,
                                  curr,
                                  manager
                                )
                              );
                              forceUpdate();
                            }}
                          >
                            X
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <br />
              <Button
                fullWidth={true}
                onClick={() => {
                  manager.execute(
                    new SetWidgetProperty(
                      property,
                      curr,
                      [...curr, "0"],
                      manager
                    )
                  );
                  forceUpdate();
                }}
              >
                Add Option
              </Button>
            </div>
          );
        }
        case "multi-lang-list":
          return (
            <MultiLanguageList
              key={`widget-prop-${key}`}
              languages={languages}
              property={property}
              header={header}
            />
          );
        case "widget":
          return (
            <WidgetSelect
              key={`widget-prop-${key}`}
              property={property}
              header={header}
              forceUpdate={forceUpdate}
            />
          );

        case "widget-list":
          return (
            <WidgetSelectList
              key={`widget-prop-${key}`}
              property={property}
              header={header}
              forceUpdate={forceUpdate}
            />
          );

        case "tween":
          return (
            <Tween
              key={`widget-prop-${key}`}
              property={property}
              header={header}
              forceUpdate={forceUpdate}
            />
          );
        case "transform":
          return header;
      }
    }
    return <span>{property.label}</span>;
  };

  return (
    <ul key={`property-render-${node?.id}`}>
      {properties.map((property: BaseWidgetProperty) => (
        <li key={property.name}>{propertyRenderer(property)}</li>
      ))}
    </ul>
  );
}
