import { IServiceOptions } from "..";
import { BaseDataService } from "../BaseDataService";
import { APIModel, APIModelNameDTO, IModelLog, Paged } from "../models";
import { APIModelFile } from "../models/ModelFile";
import { IRotation } from "../types/Rotation";
import { IScale } from "../types/Scale";

export class APIModelService extends BaseDataService<APIModel> {
  constructor() {
    super("model");
  }

  /**
   * Returns only the data for the specified model and the specified variant
   */
   public async getSmall(
    modelId: string,
    options?: IServiceOptions
  ): Promise<APIModel> {
    return await this.request(`${modelId}/small`, options);
  }

  /**
   * Returns only the data for the specified model and the specified variant
   */
  public async getWithOneVariant(
    modelId: string,
    variantId: string,
    options?: IServiceOptions
  ): Promise<APIModel> {
    return await this.request(`${modelId}/variant/${variantId}`, options);
  }

  /**
   * Returns the model files for specified model.
   */
  public async getAllModelFiles(
    id: string,
    options?: IServiceOptions
  ): Promise<APIModelFile[]> {
    return await this.request(`${id}/ModelFiles`, options);
  }

  public async getWithAllFiles(
    id: string,
    options?: IServiceOptions
  ): Promise<APIModel> {
    return await this.request(`${id}/allfiles`, options);
  }
  /**
   * Returns the model logs for specified model.
   */
  public async getLogs(
    id: string,
    options?: IServiceOptions
  ): Promise<IModelLog[]> {
    return await this.request(`${id}/logs`, options);
  }

  /**
   * This is a lighter request that returns the names of all models in the active company.
   */
  public async getAllNames(
    options?: IServiceOptions
  ): Promise<APIModelNameDTO[]> {
    return await this.request(`names`, options);
  }

  public async getByTag(
    tagId: string,
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APIModel>> {
    return await this.request(
      `tag/${tagId}?page=${page}&size=${size}${
        filter ? `&filter=${filter}` : ""
      }`,
      options
    );
  }

  public async pagedByFolder(
    folderId: string,
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APIModel>> {
    return await this.request(
      `paged/${folderId}?page=${page}&size=${size}${filter ? `&filter=${filter}` : ""}`,
      options
    );
  }

  public async addTag(
    modelId: string,
    tagId: string,
    options: IServiceOptions
  ) {
    const body = new FormData();
    body.append("modelId", modelId);
    body.append("tagId", tagId);
    return await this.submit("tag", "POST", body, options);
  }

  public async uploadModel(
    name: string,
    file: File,
    extension: string,
    additionalFile?: string,
    options?: IServiceOptions
  ): Promise<APIModel> {
    const body = new FormData();
    body.append("modelFiles", file);
    body.append("name", name);
    body.append("ext", extension);
    if (additionalFile) {
      body.append("modelFiles", additionalFile);
    }
    return await this.submit("upload", "POST", body, options);
  }

  public async uploadModelWithResources(
    name: string,
    modelFile: File,
    resources: File[],
    extension: string,
    options?: IServiceOptions
  ): Promise<APIModel> {
    const body = new FormData();
    body.append("name", name);
    body.append("modelFile", modelFile);
    for (const r of resources) {
      body.append("resources", r);
    }
    body.append("ext", extension);
    return await this.submit("upload/multi", "POST", body, options);
  }

  public async updateModel(
    modelId: string,
    name: string,
    scale: IScale,
    rotation: IRotation,
    options?: IServiceOptions
  ): Promise<APIModel> {
    const body = new FormData();
    body.append("name", name);
    body.append("scaleX", (scale.x || 1).toString());
    body.append("scaleY", (scale.y || 1).toString());
    body.append("scaleZ", (scale.z || 1).toString());
    body.append("rotX", (rotation.x || 0).toString());
    body.append("rotY", (rotation.y || 0).toString());
    body.append("rotZ", (rotation.z || 0).toString());
    return await this.submit(`${modelId}/update`, "PUT", body, options);
  }

  public async updateViewerSettings(
    modelId: string,
    viewerSettings: string,
    options?: IServiceOptions
  ) {
    const body = new FormData();
    body.append("settings", viewerSettings);
    return await this.submit(
      `${modelId}/update/viewersettings`,
      "PUT",
      body,
      options
    );
  }

  public async toGlobal(modelId: string) {
    return await this.post(`${modelId}/toGlobal`, { });
  }

  public async exportToGlobal(modelId: string, tags: string[]) {
    return await this.post("exportToGlobal", { modelId, tags });
  }

  public async importModel(modelId: string) {
    return await this.post(`import/${modelId}`);
  }

  public async convert(
    modelId: string,
    fileId: string,
    options?: IServiceOptions
  ) {
    return await this.request(`${modelId}/${fileId}/convert`, {
      method: "POST",
      ...options,
    });
  }

  public async status(modelId: string, options?: IServiceOptions) {
    const status = await this.request(`${modelId}/status`, {
      method: "GET",
      ...options,
    });
    return status.status;
  }

  public async SetThumbnail(
    modelId: string,
    dataUrl: string,
    options?: IServiceOptions
  ) {
    const body = new FormData();
    body.append("thumbnailData", dataUrl);
    return await this.submit(`${modelId}/thumbnail`, "POST", body, options);
  }

  public async setDefaultVariant(
    modelId: string,
    variantId: string,
    options?: IServiceOptions
  ): Promise<APIModel> {
    const body = new FormData();
    body.append("generateThumbnail", true.toString());
    return await this.submit(
      `defaultVariant/${modelId}/${variantId}`,
      "POST",
      body,
      options
    );
  }

  public async createDefaultVariant(
    modelId: string,
    fileId: string,
    options?: IServiceOptions
  ) {
    return await this.request(`${modelId}/createDefaultVariant/${fileId}`, {
      method: "POST",
      ...options,
    });
  }

  public async getStats(
    modelId: string,
    fileId: string,
    options?: IServiceOptions
  ) {
    return await this.request(`${modelId}/${fileId}/stats`, options);
  }

  public async simplify(
    modelId: string,
    factor: number,
    options?: IServiceOptions
  ) {
    return await this.request(`${modelId}/simplify/${factor}`, {
      method: "POST",
      ...options,
    });
  }

  public async setFolder(
    modelId: string,
    folderId: string | null
  ): Promise<APIModel> {
    if (folderId === null)
      return await this.request(`${modelId}/folder`, { method: 'POST' });
    return await this.request(`${modelId}/folder/${folderId}`, { method: 'POST' });
  }
}

const ModelService = new APIModelService();

export default ModelService;
