import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { ManagerStore } from "../../../store";
const pencilIcon = getAbsoluteUrl('images/pencil.svg');

export const AnnotationButton = () => {
    const manager = ManagerStore( s => s.ModelViewManager )
    const handleClick = () => manager?.setAnnotateMode();

    return <div className='iconContainer'>
        <img className="icon"
            onClick={handleClick}
            src={pencilIcon}
            alt={'Annotate'}
            title={'Annotate' } />
    </div>;
}
