import { Component } from 'react'
import Command from '../../commands/Command';
import Button from './button/Button';
import ManagerProps from './ManagerProps';

interface Props extends ManagerProps {
    visible: boolean;
}

interface State {
    mapped: boolean;
    history: Command[];
    futureHistory: Command[],
    undoActive: boolean;
    redoActive: boolean;
}

export default class HistoryControls extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.manager && props.manager.broker) {
            const broker = props.manager.broker
            this.state = {
                mapped: true,
                history: broker.history,
                futureHistory: broker.futureHistory,
                undoActive: false,
                redoActive: false
            }
        } else {
            this.state = {
                mapped: false,
                history: [],
                futureHistory: [],
                undoActive: false,
                redoActive: false
            }
        }
        this.update = this.update.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
    }

    componentDidMount() {
        this.props.manager.attach('history-update', () => this.update());
        document.addEventListener('keydown', (e) => this.handleKeypress(e), true)
    }

    componentDidUpdate() {
        if (this.props.manager && !this.state.mapped) {
            const broker = this.props.manager.broker
            this.setState({
                mapped: true,
                history: broker.history,
                futureHistory: broker.futureHistory,
            });
        }
    }

    componentWillUnmount() {
        this.props.manager.detach('history-update', () => this.update())
        document.removeEventListener('keydown', (e) => this.handleKeypress(e))
    }

    handleKeypress(evt: KeyboardEvent) {
        if (evt.metaKey || evt.ctrlKey) {
            switch (evt.code) {
                case 'KeyY': // redo, CMD+Y
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.handleRedo();
                    break;
                case 'KeyZ':
                    if (evt.shiftKey) { // redo, CMD+SHIFT+Z
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.handleRedo();
                    } else { // undo, CMD+Z
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.handleUndo()
                    }
                    break;
            }
        }
    }

    handleUndo() {
        if(this.state.history.length === 0) {
            console.warn("no history to undo");
            return;
        }
        this.setState({
            undoActive: true
        })
        setTimeout(() => this.setState({undoActive: false}), 1500)
        this.props.manager.undo();
    }

    handleRedo() {
        if(this.state.futureHistory.length === 0) {
            console.warn("no future history to redo");
            return;
        }
        this.setState({
            redoActive: true
        });
        setTimeout(() => this.setState({redoActive: false}), 1500)
        this.props.manager.redo()
    }

    update() {
        const { manager } = this.props;
        this.setState({
            history: manager.broker.history,
            futureHistory: manager.broker.futureHistory,
        })
    }

    render() {
        const { visible } = this.props;
        const { history, futureHistory, undoActive, redoActive } = this.state;
        // Active indicates that the an undo or redo command is currently being exectued

        const undoAvailable = history.length > 0;
        const redoAvailable = futureHistory.length > 0;
        // Available indicates whether there is a command in the history to redo or undo

        if(!visible) {
            return <></>
        }
        return <div className="historyControls">
                <Button classNames={`${undoAvailable ? 'available' : 'unavailable'} ${undoActive ? 'active' : ''}`}>
                    <span aria-hidden="true" className={`icon-undo ${undoAvailable ? 'available' : 'unavailable'}`}
                    onClick={() => this.handleUndo() } />
                </Button>
                <Button classNames={`${redoAvailable ? 'available' : 'unavailable'} ${redoActive ? 'active' : ''}`}>
                    <span aria-hidden="true" className={`icon-redo ${redoAvailable ? 'available' : 'unavailable'}`}
                    onClick={() => this.handleRedo()} />
                </Button>
            </div>;
    }
}