import ModelViewManager from "../graphics/ModelViewManager";
import Command from "./Command";
import { Scale } from "../graphics/Types";

export default class SetNodeScale extends Command {
  oldScale: Scale;
  newScale: Scale;
  manager: ModelViewManager;
  name = "Scale Model";

  constructor(manager: ModelViewManager, newScale: Scale, oldScale: Scale) {
    super();
    this.newScale = { x: newScale.x, y: newScale.y, z: newScale.z };
    this.oldScale = { x: oldScale.x, y: oldScale.y, z: oldScale.z };
    this.manager = manager;
  }

  execute() {
    let { x, y, z } = this.newScale;
    this.manager.setScale(x, y, z);
  }

  undo() {
    let { x, y, z } = this.oldScale;
    this.manager.setScale(x, y, z);
  }
}
