import React, { ReactElement, useEffect } from "react";
import { SceneService } from "@proviz/api-services";
import EditableTitle from "../common/editabletitle/EditableTitle";
import { ManagerStore } from "../../store";

export default function SceneName(): ReactElement {
  const manager = ManagerStore( s => s.SceneManager );
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const scene = manager.apiScene;

  useEffect(() => {
    const update = () => forceUpdate();
    manager.attach("init-done", update);
    return () => manager.detach("init-done", update);
  }, [manager, forceUpdate]);

  if (!scene) {
    return <></>;
  }

  return (
    <EditableTitle
      name={scene.name}
      updateName={(name: string) => {
        scene.name = name;
        manager.proVizScene.setSceneTitle(name);
        manager.save();
        SceneService.update(scene);
      }}
    />
  );
}
