import { ReactElement, useEffect, useState } from 'react'
import {  Vector4 } from 'three';

interface Props {
    input1: { id: string, value: number, label: string };
    input2: { id: string, value: number, label: string };
    input3: { id: string, value: number, label: string };
    input4: { id: string, value: number, label: string };
    onChange: (e: Vector4) => void;
}

export default function FourValInput(props: Props): ReactElement {
    const { input1, input2, input3, input4, onChange } = props;

    // To fixed returns a string, so we need to parse that string
    // back into a float
    const convertToFixedFloat = (val: any) => {
        let res;
        //Make sure the passed value is a number, if it isn't just reset it.
        if(isNaN(parseFloat(val)))
            val = 0;
        if(typeof(val) === 'number') res = val.toFixed(3);
        else res = parseFloat(val).toFixed(3);

        return res === '0.000' || res === null ? '0' : res;
    }

    // Take the initial input values and do the fixed float conversion hack
    // This allows you to input things like 1.5 and output 1.500 or 1.543 and output 1.543 or 1 and get 1.000 all the while not resetting the cursor position
    const [inp1, setInp1] = useState<any>(convertToFixedFloat(input1.value));
    const [inp2, setInp2] = useState<any>(convertToFixedFloat(input2.value));
    const [inp3, setInp3] = useState<any>(convertToFixedFloat(input3.value));
    const [inp4, setInp4] = useState<any>(convertToFixedFloat(input4.value));

    useEffect(() => {
        setInp1(convertToFixedFloat(input1.value));
        setInp2(convertToFixedFloat(input2.value));
        setInp3(convertToFixedFloat(input3.value));
        setInp4(convertToFixedFloat(input4.value));
    }, [input1, input2, input3, input4])

    const handleKeySubmit = (event: any) => {
        if (event.key === 'Enter') onChange(new Vector4(inp1, inp2, inp3, inp4));
    }

    return <div className="threevalinputrow">
            <form className='threevalentry'>
                <div className="inputcell">
                    <label htmlFor={input1.id}>{input1.label}</label>
                    {(!isNaN(input1.value)) &&
                        <input type="number"
                            id={input1.id}
                            value={inp1}
                            onChange={e => setInp1(e.target.value)}
                            onBlur={() => onChange(new Vector4(inp1, inp2, inp3, inp4))}
                            onKeyPressCapture={e => handleKeySubmit(e)}
                            onFocus={e => e.target.select()}
                        />}
                </div>
                <div className="inputcell borderLeft">
                    <label htmlFor={input2.id}>{input2.label}</label>
                    {(!isNaN(input2.value)) &&
                        <input type="number"
                            id={input2.id}
                            value={inp2}
                            onChange={e => setInp2(e.target.value)}
                            onBlur={() => onChange(new Vector4(inp1, inp2, inp3, inp4))}
                            onKeyPressCapture={e => handleKeySubmit(e)}
                            onFocus={e => e.target.select()}
                        />}
                </div>
                <div className="inputcell borderLeft borderRight">
                    <label htmlFor={input3.id}>{input3.label}</label>
                    {(!isNaN(input3.value)) &&
                        <input type="number"
                            id={input3.id}
                            value={inp3}
                            onChange={e => setInp3(e.target.value)}
                            onBlur={() => onChange(new Vector4(inp1, inp2, inp3, inp4))}
                            onKeyPressCapture={e => handleKeySubmit(e)}
                            onFocus={e => e.target.select()}
                        />}
                </div>
                <div className="inputcell">
                    <label htmlFor={input4.id}>{input4.label}</label>
                    {(!isNaN(input4.value)) &&
                        <input type="number"
                            id={input4.id}
                            value={inp3}
                            onChange={e => setInp3(e.target.value)}
                            onBlur={() => onChange(new Vector4(inp1, inp2, inp3, inp4))}
                            onKeyPressCapture={e => handleKeySubmit(e)}
                            onFocus={e => e.target.select()}
                        />}
                </div>
            </form>
        </div>;
}
