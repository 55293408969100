import React, { ReactElement } from "react";
import { ManagerStore } from "../../../store";
import { SettableWidgetProperty } from "./SettableWidgetProperty";

interface Props {
  property: SettableWidgetProperty;
  header: ReactElement;
  forceUpdate: () => void;
}

export const WidgetSelect = (props: Props) => {
  const { header, property } = props;
  const sceneManager = ManagerStore( s => s.SceneManager )

  const widget = sceneManager.getById(property.get());

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    console.log(e);
    // const resourceId = e.dataTransfer.getData("resource/id");
    const nodeId = e.dataTransfer.getData("node/id");
    if ( nodeId ) {
      property.set(nodeId);
      props.forceUpdate();
    }
  }

  const select = () => {
    if (widget) {
      sceneManager.setSelectionById(widget.id);
    }
  }

  const remove = () => {
    property.set(null);
    props.forceUpdate();
  }

  return <div className="videoSelector">
      {header}
        <div onDrop={handleDrop} onDragEnter={(e) => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
          <div className="widget-select">
            {widget && <div>
              <div className="widget-select-part" onClick={select}>{widget.label}</div>
              <div className="widget-select-remove" onClick={() => remove()}>
                  X
              </div>
            </div>}
            {!widget && <span>[Drop Widget]</span>}
          </div>
        </div>
    </div>;
};
