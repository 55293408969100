import { createAvatar } from "@dicebear/avatars";
import * as avatarStyle from "@dicebear/avatars-avataaars-sprites";
import {
  APIRole,
  APIUser,
  CompanyService,
  getCompanyRole,
  ProVizConfig,
  RoleService,
  UserService,
} from "@proviz/api-services";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { userStore } from "../../store";
import Button from "../common/button/Button";
import Input from "../common/input/input";
import Modal from "../common/modals/Modals";
import PageContentHeader from "../home/pageContentHeader";
import PageHeader from "../home/pageHeader";

const IconButton = styled.div`
  width: 5rem;
  position: relative;
  margin: 0 auto;
  div {
    cursor: pointer;
    color: #313e64;
    text-decoration: none;
    display: inline-block;
    margin: 1.25rem;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background: #ecf0f8;
    position: relative;
    img {
      border: 0;
      padding: 0;
      margin: 0;
      position: relative;
      border-radius: 50%;
    }
  }
  span {
    width: 100%;
    font-size: 1.5rem;
    padding: 0.25rem;
    display: block;
    cursor: pointer;
  }
`;
const AvatarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  img {
    cursor: pointer;
    border-radius: 50rem;
    &:hover {
      background: #eee;
    }
  }
`;
function Avatar(props: { seed: string; onClick: Function }) {
  const icon = createAvatar(avatarStyle, {
    seed: props.seed,
    dataUri: true,
    // ... and other options
  });
  return <img onClick={() => props.onClick()} src={icon} alt="" />;
}

export default function EditUserAccount() {
  const [userToModify, setUserToModify] = useState<APIUser>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyId] = useState<string | undefined>(
    ProVizConfig.impersonateCompanyId
  );
  const [role, setRole] = useState("");
  const [avatar, setAvatar] = useState("");
  const [showAvatarList, setShowAvatarList] = useState(false);
  const [avatars, setAvatars] = useState<string[]>([]);
  const [showCompanyList, setShowCompanyList] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [companies, setCompanies] = useState<{ key: string; value: string }[]>(
    []
  );
  const user = userStore((s) => s.user);
  const location = useLocation();

  const genSeeds = () => {
    const seeds: string[] = [];
    for (let i = 0; i < 50; i++) {
      seeds.push((Math.random() * 999999999).toString());
    }
    setAvatars(seeds);
  };

  const showAvatarListModal = () => {
    setShowAvatarList(true);
    genSeeds();
  };

  const closeAvatarListModal = () => {
    setShowAvatarList(false);
    setAvatars([]);
  };

  const showCompanyListModal = (userId: string) => {
    setShowCompanyList(true);
    CompanyService.getAllKeyValue().then((results) => {
      setCompanies(
        results.sort((a, b) => {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        })
      );
    });
  };

  const hideCompanyListModal = () => {
    setShowCompanyList(false);
    setCompanies([]);
  };

  useEffect(() => {
    if ((location.state as { user: APIUser })?.user) {
      const u = (location.state as { user: APIUser }).user;
      setUserToModify(u);
      if (companyId) {
        setFirstName(u.firstName ?? "");
        setLastName(u.lastName ?? "");
        setEmail(u.email ?? "");
        setRole(getCompanyRole(u, companyId) ?? "");
        setAvatar(u.avatar ?? "");
      }
    }
  }, []);

  const rolesQuery = useQuery<
    Promise<APIRole[]>,
    undefined,
    APIRole[],
    [string, string]
  >(["roles", "available"], ({ queryKey: [key, role] }) => {
    return RoleService.available();
  });

  const accountIcon = createAvatar(avatarStyle, {
    seed: avatar ?? (userToModify as APIUser).email,
    dataUri: true,
    // ... and other options
  });

  return (
    <div className="homeRightView">
      <PageHeader />
      <div className="homeContent">
        <div className="homeContentContainer">
          <PageContentHeader title="Edit User" />

          <div className="paddedContainer">
            <div className="userList">
              <div className="userIcon" style={{ display: "flex" }}>
                <IconButton>
                  <div onClick={showAvatarListModal}>
                    <img src={accountIcon} alt="" />
                  </div>
                </IconButton>
              </div>
              <table>
                <tr>
                  <td>First Name</td>
                  <td>
                    <Input
                      className="full"
                      value={firstName}
                      onChange={(val) => setFirstName(val)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    <Input
                      className="full"
                      value={lastName}
                      onChange={(val) => setLastName(val)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <Input
                      className="full"
                      value={email}
                      onChange={(val) => setEmail(val)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>
                    {user &&
                    userToModify?.id !== user.id && // Users cannot change their own role
                    userToModify?.isSuperAdmin ? ( // Super admin status cannot be modified atm
                      <span>Super Admin</span>
                    ) : (
                      <select
                        value={role || ""}
                        className="provizInputBox"
                        onChange={(e) => setRole(e.target.value)}
                      >
                        {rolesQuery.data?.map((r) => (
                          <option value={r.id}>{r.name}</option>
                        ))}
                      </select>
                    )}
                  </td>
                </tr>
              </table>
              <Button
                type="default"
                onClick={async () => {
                  if (!submitting) {
                    setSubmitting(true);
                    try {
                      let userData = Object.assign({}, userToModify);
                      userData = {
                        ...userData,
                        firstName,
                        lastName,
                        avatar,
                        email,
                      };
                      await UserService.updateInfo(userData);
                      if (companyId) {
                        await UserService.setRole(userData.id, companyId, role);
                      }
                    } catch (e) {
                      console.error(e);
                    } finally {
                      setSubmitting(false);
                    }
                  }
                }}
              >
                Save Changes
              </Button>
              <Button type="default" onClick={showCompanyListModal}>
                Add to Company
              </Button>
              <br />
              {submitting && <p>Saving Changes...</p>}
              <br />
              <Button
                type="danger"
                onClick={() => {
                  if (userToModify) {
                    UserService.delete(userToModify?.id);
                  }
                }}
              >
                {userToModify && userToModify.companies.length > 1
                  ? "Remove from company"
                  : "Delete User"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showCompanyList && userToModify && (
        <Modal title={"Add User to Company"} close={hideCompanyListModal}>
          <div>Company List: {userToModify.id}</div>
          <div>
            {companies.map((c) => (
              <div key={`c-${c.key}`} style={{ margin: 10 }}>
                <Button
                  fullWidth={true}
                  onClick={() => {
                    if (userToModify) {
                      UserService.addToCompany(userToModify.id, c.key);
                    }
                    hideCompanyListModal();
                  }}
                >
                  {c.value}
                </Button>
              </div>
            ))}
          </div>
        </Modal>
      )}
      {showAvatarList && (
        <Modal title="Change user Avatar" close={closeAvatarListModal}>
          <AvatarGrid>
            {avatars.map((seed) => (
              <Avatar
                seed={seed}
                onClick={() => {
                  setAvatar(seed);
                  closeAvatarListModal();
                }}
              />
            ))}
          </AvatarGrid>
          <Button onClick={() => genSeeds()}>Randomize</Button>
        </Modal>
      )}
    </div>
  );
}
