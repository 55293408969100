import { ModelWidget, ProVizScene } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { Color, Mesh, MeshBasicMaterial, SphereGeometry } from 'three';

export class TunnelWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'tunnel';

  // Data
  private count: number = 10;
  private distanceCutOff: number = 1.0;
  private useCutOff: boolean = true;
  private distanceMin: number = 0.25;
  private modelId: string | undefined = undefined;
  private targetWidgetId: string | undefined = undefined;

  private displayModel: ModelWidget | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = TunnelWidget.type;
    this.widgetName = 'Tunnel';
    this.label = 'Tunnel';
    this.selectable = false;
    this.category = 'Experimental';

    this.events.push({
      label: 'Target Changed',
      name: 'target-changed',
    });

    this.addService(
      'Set Target',
      'set-target',
      'Sets the target the tunnel widget is pointing to',
      () => {
        console.warn('Not yet working in the browser');
      },
    );
  }

  public getProperties(): BaseWidgetProperty[] {
    return [
      ...super.getProperties(),
      this.createProperty(
        'count',
        'Node Count',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Number of nodes to show',
      ),
      this.createProperty(
        'distanceCutOff',
        'Distance Cut Off',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Maximum distance between nodes',
      ),
      this.createProperty(
        'useCutOff',
        'Use Cutoff',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        undefined,
        undefined,
        'Whether the tunnel will always be shown',
      ),
      this.createProperty(
        'distanceMin',
        'Minimum Distance',
        'Core',
        'number',
        'number',
        true,
        undefined,
        undefined,
        undefined,
        'Minimum Distance when tunnel is not shown',
      ),
      this.createProperty(
        'modelId',
        'Node Model',
        'Core',
        'model',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The model to use instead of the default sphere',
      ),
      this.createProperty('targetWidgetId', 'Target Widget', 'Core', 'widget', 'string', true),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (!this.modelId) {
      const geometry = new SphereGeometry(0.05);
      const material = new MeshBasicMaterial({
        color: new Color(0xcccccc),
      });

      for (let i = 0; i < this.count; i++) {
        const mesh = new Mesh(geometry, material);
        mesh.position.set(0, 0, i * 1);
        this.renderNode.add(mesh);
      }
    } else {
      for (let i = 0; i < this.count; i++) {
        this.displayModel = new ModelWidget(this.scene, this);
        this.displayModel.modelId = this.modelId;
        this.displayModel.deserialize({
          modelId: this.modelId,
          clickable: false,
          transparent: false,
          opacity: 1.0,
        });
        await this.displayModel.init();
        this.displayModel.setPosition({ x: 0, y: 0, z: i * 1 });
        this.renderNode.add(this.displayModel.renderNode);
      }
    }

    return true;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.count = data.count;
    this.distanceCutOff = data.distanceCutOff;
    this.useCutOff = data.useCutOff;
    this.distanceMin = data.distanceMin;
    this.modelId = data.modelId;
    this.targetWidgetId = data.targetWidgetId;
  }

  public serialize() {
    const result = super.serialize();
    result.count = this.count;
    result.distanceCutOff = this.distanceCutOff;
    result.useCutOff = this.useCutOff;
    result.distanceMin = this.distanceMin;
    result.modelId = this.modelId;
    result.targetWidgetId = this.targetWidgetId;
    return result;
  }
}

ModuleService.Register(TunnelWidget.type, TunnelWidget);
