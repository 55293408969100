export const WidgetInfoText: { [category: string]: string } = {
  // Core
  model: `Display a model in the scene.`,
  "depth-model": `The depth model widget will block the view of items behind it from the perspective
    of the camera. If clickable is set to true then it will also intercept click and hover events. Setting \
    clickable to true may hurt performance. Further, for some models, if clickable is set to true they will intercept
    events that do not appear to be blocking, breaking scene functionality.`,

  "native-ar-widget": `The native AR widget wil open the selected variant of its model
    in the native 3D viewer application of the device it's running on (quick look for iOS, scene viewer on Android).
    The icon will only appear in the scene if it is being viewed on a device that supports
    either of these services (the icon will not render on desktop).

    If you are using this widget in conjunction with a variant selector widget you need to 
    connect the Output Variant service event to the Set Variant service on your AR widget as well
    as the Set Variant service on your model widget. A mismatch of models and variants will produce
    undefined behavior.

    The allow scaling in AR will enable users to modify the size of the model in the native app if it is supported.
    
    Models with more than 100k polygons will not display in ios quick look.`,

  "model-viewer": `The Model Viewer widget will open a modal with the embedded model viewer when clicked. If the 
    device has support for AR a button to open the native AR viewer will be visible in the bottom right corner. Setting
    the variant for this widget does not currently have an effect. Development on that front is underway.`,

  "variant-switch": `The variant switch widget can be used to let the user change the variant
    of a model used in a scene. When clicked it will open a menu with all of the variants for
    the selected model listed. When the user makes a selection it will emit an Output Variant event
    that if connected to a widget with a Set Variant service, will set that widget's variant. Be sure that any connected
    widgets have the same model selected, inconsistency here will break functionality.`,

  "progress-bar": `The progress bar widget adds a rectangular progress bar to the scene where the progress fill is
    set to the ratio from the Set Progress event. Connect the Set Progress service to the Counter widget's Changed Ratio event
    to set the progress. Border width and border radius must be less than half of either height and width.`,

  "box-collider": `A box collider adds an invisible box to the scene that intercepts click events. Use it 
    to hide widgets behind it or to block interactions with them.`,

  "closed-caption": `A closed caption widget takes a video and places it over the scene in conjunction with a video widget.
    If you connect the Close Caption play service to a video Play Started event it will sync with the current time of the video triggering it. 
    A video source can be set for every language in the scene. If there is not a source for the current language in the scene it will show the 
    closed caption video for the default language in the scene (by default english[en]).`,

  "rotation-group": `A widget that works similar to the group view. It will provide a full 360 degree rotation over a set amount of time (in seconds)
   to all of the children in its heirarchy.`,

  "scale-group": `A widget that works similar to the group view. It will provide a dynamic scale effect to all of the children in its heirarchy.`,

  "pos-group": `A widget that works similar to the group view. It will provide a dynamic position translation functionality to all of the children in its heirarchy.`,

  group: `This widget does not add anything to the scene but can be useful to organize your scene hierarchy. Any scale, rotation, or position 
    settings set on the group will be applied to all of its children.`,

  image: `Add an image to the scene. Currently the source needs to be just the filename on proviz servers. This will be changing soon.
    If the source is a png and transparency is set to true, transparent parts of the image will be transparent can 
    otherwise they will be white.`,

  panorama: `A panorama is a 360 degree image that will surround the camera. Generally these will be created during the import process.
     When imported they will generally consist of 6 square images. In other cases you can upload a single equirectangular image. If uploading your
     own you may need to adjust the rotation of the image with regrad to the scene using the Skybox Rotation property. To improve download performance
     you can also convert the imported 6 images into a single merged image using the Convert To Merged Skybox button. The rotation and scale values
     affect the hotspot sprite associated with the panorama but will not change the experience within the panorama. If you want a user to start inside a 
     panorama connect the Flow Editor Entry point widget Scene Start event to the Enter service on your chosen panorama.`,

  sprite: `Sprites are 2D animated images. Use a PNG sheet to as their source and be sure to set the correct number of vertical, horizontal and total tiles
    in the sheet. They are useful as buttons, or navigation assists within scenes. PNG sheets can be generated from gifs, movs, mp4 files and others using tools 
    like https://www.codeandweb.com/free-sprite-sheet-packer for large animations you may need to use ImageMagick`,

  text: `Generates 2D text in the scene, you can update the text at runtime using the Set Text service.`,

  "text-box-2d":
    "A 2D textbox that is opened when the corresponding sprite is clicked.",

  "button-2d":
    "A 2D button that is placed in the bottom bar next to the full screen or language selector buttons.",

  video: `Add a video to your scene.  A video source can be set for every language in the scene. If there is not a source for the current language in the scene it will show the 
    closed caption video for the default language in the scene (by default english[en]).`,

  audio: `Add an audio clip to your scene.`,

  // Custom
  "circular-button-widget": `Custom button solution that emits a text value when clicked.`,

  custom: `This is a placeholder for widgets that are available in Unity Apps but not on the web.`,

  explode: `The explode widget can be applied to a model and when triggered by a flow event will expand all meshes in the selected model.`,

  plane: `A two sided plane.`,

  "radial-menu": `Custom widget solution that triggers different events depending on where the image is clicked.`,

  "timeline-element-widget": `Custom widget solution that shows an image with a border that when clicked enlarges in front of a background video
    with a larger version of that image above text in the foreground.`,

  // Flow
  "random-value":
    "Emit a random value in the number range at the frequency set.",

  // Lights

  "ambient-light":
    "Light that reaches all points with the same intensity. No position, rotation or scale. This light does not cast shadows.",

  "directional-light":
    "The directional light widget shines a light in one direction",

  "spot-light":
    "A spot light. This light gets emitted from a single point in one direction, along a cone that increases in size the further from the light it gets. Can cast shadows.",

  "point-light":
    "A light that emanates from a single point like a light bulb. Can cast shadows.",

  "hemi-light":
    "A light source positioned directly above the scene, with color fading from the sky color to the ground color. This light does not cast shadows.",

  "toolbelt": "Inherits the camera position. Forward in front of the camera is in the negative Z direction, so set a widget at -1 to have it 1 meter in front of the camera.",

  default: "",

  // ARCS
};

export enum SupportLevel {
  Full,
  Partial,
  None,
  Never,
}

export type SupportList = {
  studio: SupportLevel;
  embed: SupportLevel;
  androidIosWebSdk: SupportLevel;
  hololens: SupportLevel;
  oculus: SupportLevel;
  androidIosUnitySdk: SupportLevel;
  notes?: string;
};

export const WidgetSupport: { [widget: string]: SupportList } = {
  "box-collider": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "closed-caption": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "depth-model": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  explode: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  group: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.Full,
    oculus: SupportLevel.Full,
    androidIosUnitySdk: SupportLevel.None,
  },
  image: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  increment: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  model: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.Full,
    oculus: SupportLevel.Full,
    androidIosUnitySdk: SupportLevel.None,
  },
  "model-viewer": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Partial,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
    notes: "Needs to support new embed SDK for models",
  },
  "native-ar": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Partial,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.Never,
    oculus: SupportLevel.Never,
    androidIosUnitySdk: SupportLevel.Never,
  },
  panorama: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "progress-bar": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.None,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  sprite: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  text: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "text-box": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "text-box-2d": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "button-2d": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.None,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "variant-switch": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  video: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.Partial,
    oculus: SupportLevel.Partial,
    androidIosUnitySdk: SupportLevel.None,
    notes: "Green screen effect in progress for Unity",
  },
  "circular-button-widget": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  custom: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "radial-menu": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "timeline-element-widget": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "arcs-section": {
    studio: SupportLevel.Full,
    embed: SupportLevel.None,
    androidIosWebSdk: SupportLevel.None,
    hololens: SupportLevel.Full,
    oculus: SupportLevel.Full,
    androidIosUnitySdk: SupportLevel.None,
  },
  "arcs-step": {
    studio: SupportLevel.Full,
    embed: SupportLevel.None,
    androidIosWebSdk: SupportLevel.None,
    hololens: SupportLevel.Full,
    oculus: SupportLevel.Full,
    androidIosUnitySdk: SupportLevel.None,
  },
  "arcs-syncpoint": {
    studio: SupportLevel.Full,
    embed: SupportLevel.None,
    androidIosWebSdk: SupportLevel.None,
    hololens: SupportLevel.Full,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  entrypoint: {
    studio: SupportLevel.Full,
    embed: SupportLevel.None,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.Full,
    oculus: SupportLevel.Full,
    androidIosUnitySdk: SupportLevel.None,
  },
  toggle: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "mobile-check": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "mobile-switch": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "random-value": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  rest: {
    studio: SupportLevel.Partial,
    embed: SupportLevel.Partial,
    androidIosWebSdk: SupportLevel.Partial,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "scene-change": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  "transport-menu": {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
  switch: {
    studio: SupportLevel.Full,
    embed: SupportLevel.Full,
    androidIosWebSdk: SupportLevel.Full,
    hololens: SupportLevel.None,
    oculus: SupportLevel.None,
    androidIosUnitySdk: SupportLevel.None,
  },
};
