import { ImageWidget } from './imageWidget';

export class ImageWidgetStudio extends ImageWidget {
  public async init(): Promise<boolean> {
    super.init();

    // add wireframe

    return true;
  }
}
