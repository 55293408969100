import React, { ReactElement, useEffect, useState } from "react";
import { BaseWidget, getAbsoluteUrl, ProVizEventData } from "@proviz/proviz-sdk";
import { useHandleAddResourceWidget } from "../handleAddResourceWidget";
import HierarchySearchBar from "../HierarchySearchBar";
import { WidgetIcons } from "../../../documentation/WidgetIcons";
import { TargetType } from "@proviz/api-services";
import { BaseWidgetProperty } from "@proviz/proviz-sdk";
import { ManagerStore } from "../../../store";

const flowIcon = getAbsoluteUrl("images/FlowEditorIcon.svg");

enum SearchMatchResult {
  NoMatch,
  Match,
  ChildrenMatch
}

function searchMatch(searchStr: string, w: BaseWidget): SearchMatchResult {

  // match if label or widget type match searchstr
  let widgetContains = w.label.toLowerCase().includes(searchStr) 
    || w.widgetType.toLowerCase().includes(searchStr) 
    || w.widgetName.toLowerCase().includes(searchStr)

  // if starting with t:
  //    only search for widgets of a type and ignore the name
  if (searchStr.startsWith('t:')) {
    widgetContains = w.widgetType.toLowerCase().includes(searchStr.substring(2));
  }

  // if starting with n:
  //    only search for widgets name, ignore type
  if (searchStr.startsWith('n:')) {
    widgetContains = w.label.toLowerCase().includes(searchStr.substring(2)) 
      || w.widgetType.toLowerCase().includes(searchStr.substring(2)) 
  }

  // if starting with p:
  //    only search for widgets with property of value
  if (searchStr.startsWith('p:')) {
    widgetContains = false;
    const wdg: any = w;
    const searchTerm = searchStr.substring(2);
    if (searchTerm.indexOf(':') > -1) {
      const propParts: string[] = searchTerm.split(':');
      const propertyName = propParts[0];
      propParts.shift();
      const propertySearchTerm = propParts.join(':');
      // search by property
      w.properties
        .filter((p: BaseWidgetProperty) => {
          return p.name.toLowerCase().includes(propertyName) ||
            p.label.toLowerCase().includes(propertyName);
        })
        .forEach((p: BaseWidgetProperty) => {
          const propVal = (wdg[p.name] || '').toString().toLowerCase();
          if (propVal.includes(propertySearchTerm)) {
            widgetContains = true;
          }
        });
    } else {
      w.properties.forEach((p: BaseWidgetProperty) => {
        const propVal = (wdg[p.name] || '').toString().toLowerCase();
        if (propVal.includes(searchTerm)) {
          widgetContains = true;
        }
      });
    }
  }

  if(widgetContains) {
    return SearchMatchResult.Match
  }
    

  for(let i = 0; i < w.children.length; i++) {
    if(searchMatch(searchStr, w.children[i]) !== SearchMatchResult.NoMatch) {
      return SearchMatchResult.ChildrenMatch
    }
  }

    return SearchMatchResult.NoMatch;
}

function doNotReposition(e: React.DragEvent<HTMLElement>) {
  return e.dataTransfer.getData("node/canReposition") === 'false'
}

interface TreeProps {
  node: BaseWidget;
}

export default function NodeSelector(): ReactElement {
  const manager = ManagerStore( s => s.SceneManager );
  const handleAddResourceWidget = useHandleAddResourceWidget();

  const [selected, setSelected] = useState(manager.selectedNodes);
  const [nodes, setNodes] = useState(manager.proVizScene.nodes);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [searchString, setSearchString] = useState("");
  const [showFlow, setShowFlow] = useState(false);


  function updateSelection(ev?: ProVizEventData) {
    const selectedNode = manager.selectedNodes;
    if (!ev || (ev.dataType === 'boolean' && (ev.data as boolean) === false)) {
      scrollToNode(manager.selectedNodes[manager.selectedNodes.length - 1]);
    }
    setSelected([...selectedNode]);
  }

  function updateNodes() {
    setNodes(manager.proVizScene.nodes);
  }

  function scrollToNode(node: BaseWidget | null) {
    if (node) {
      const el = document.getElementById(`node-${node.id}`);
      if (el) {
        el.scrollIntoView(true);
      }
    } else {
      const el = document.getElementById(`node-scene`);
      if (el) {
        el.scrollIntoView(true);
      }
    }
  }

  manager.addEventListener("scene-loaded", () => {
    updateSelection();
    updateNodes();
    forceUpdate();
  });

  useEffect(() => {
    manager.attach("selectednode-update", updateSelection);
    return () => manager.detach("selectednode-update", updateSelection);
  });

  useEffect(() => {
    manager.attach("widgets-updated", updateNodes);
    return () => manager.detach("widgets-updated", updateNodes);
  });

  const handleResourceDrop = (e: React.DragEvent<HTMLElement>, parent?: BaseWidget | undefined, addToSelected = true) => {
    const resourceId = e.dataTransfer.getData("resource/id");
    const resourceType = e.dataTransfer.getData("resource/type");
    const resourceName = e.dataTransfer.getData("resource/name");
    const targetType = (e.dataTransfer.getData("targetType") || undefined) as TargetType | undefined;

    if (resourceId) {
      handleAddResourceWidget(undefined, resourceType, resourceId, resourceName, targetType, parent, addToSelected);
    }

    e.preventDefault();
    e.stopPropagation();
  }

  function NodeTree(props: TreeProps): ReactElement {
    const [ dropping, setDropping ] = useState(false);
    
    const { node } = props;
    const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
      if (doNotReposition(e)) {
        e.dataTransfer.dropEffect = 'none';
      }
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDropToNode = (e: React.DragEvent<HTMLElement>) => {
    
      e.preventDefault();
      e.stopPropagation();
      if (doNotReposition(e)) {
        console.error("cant drop this node!");
        return;
      }
      const fromResource = e.dataTransfer.getData("fromResourceCard");

      if (fromResource === 'true') {
        handleResourceDrop(e, node);
        return
      }

      const nodeId = e.dataTransfer.getData("node/id");

      if (node.id !== nodeId) {
        const nodeToRemove = manager.getById(nodeId);
        if (nodeToRemove) {
          manager.move(nodeToRemove, node);
        }
      }

    };
    const handleDragStart = (e: React.DragEvent<HTMLElement>) => {
      e.dataTransfer.setData("node/id", node.id);
      e.dataTransfer.setData("node/canReposition", node.canReposition.toString());
      if (!node.canReposition) {
        // This is not currently working correctly in chrome while dragging over other nodes
        // it stays with a green +. Seems like an open bug. Also don't love how it's
        // behaving in safari
        // https://groups.google.com/a/chromium.org/g/chromium-bugs/c/e6oxtdvbXvc?pli=1
        // https://stackoverflow.com/questions/62283773/drag-n-drop-events-firing-multiple-times
        e.dataTransfer.dropEffect = 'none';
      }
      // e.dataTransfer.items.add(node.id, 'node-id');
      // e.preventDefault();
      // e.stopPropagation();
    };

    let searchMatchResults = SearchMatchResult.Match;

    if(searchString) {
      searchMatchResults = searchMatch(searchString.toLowerCase(), node);
      if (searchMatchResults === SearchMatchResult.NoMatch) {
        return <></>
      }
    }

    const flowFilterFn = showFlow
      ? () => true
      : (x: BaseWidget) => x.usage !== "Flow";
    const relevantChildCount = node.children.filter(flowFilterFn).length;

    return <div
      id={`node-${node.id}`}
      className={`nodeSelectorEntry drag-drop-zone ${node.visible ? 'visible' : 'hidden'}`}
    >
      <div className={`nodeSelectIndex ${dropping ? 'drop' : ''}`} onDrop={(e) => {
        const fromResource = e.dataTransfer.getData("fromResourceCard");
        if(fromResource === 'true') {
          handleResourceDrop(e, node.parent, false);
          return
        }
        const nodeId = e.dataTransfer.getData("node/id");
        if (node.id !== nodeId) {
          const nodeToMove = manager.getById(nodeId);
          if (nodeToMove && node.parent) {
            manager.moveAfter(nodeToMove, node);
          }
        }

        setDropping(false);
        e.preventDefault();
        e.stopPropagation();
        return false;
      }} onDragEnter={(e) => {
        e.preventDefault()
        return false;
      }} onDragOver={(e) => {
        setDropping(true);
        e.preventDefault();
        return false;
      }} onDragLeave={(e) => {
        setDropping(false);
        e.preventDefault();
        return false;
      }}>&nbsp;</div>
      <div draggable={true}
        className={`nodeSelectEntity indent ${searchMatchResults === SearchMatchResult.ChildrenMatch && 'hasChildSearchMatch'} ${selected.findIndex((wdg) => node === wdg) > -1 ? "selected" : ""
          } ${node.canReposition ? '' : 'cannotReposition'}`}
        onDragStart={(e) => handleDragStart(e)}
        onDrop={(e) => handleDropToNode(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
      >
        {relevantChildCount > 0 ? (
          <div className="tree-control"
            onClick={() => {
              node.editorMinimized = !node.editorMinimized;
              forceUpdate();
            }}
          >
            <span aria-hidden="true">{node.editorMinimized ? "▸" : "▾"}</span>
          </div>
        ) : (
          <div className="tree-control">
            <span>&nbsp;</span>
          </div>
        )}
        <div className="widget-icon-container">
          <img className="widget-icon" alt=""
            src={WidgetIcons[node.widgetType] ?? WidgetIcons["default"]}
          />
        </div>
        <div className={`node-label`} onClick={() => {
          manager.setSelection(node, true)}
        }>
          {node.label}
          {!node.visible && <span style={{paddingLeft: '6px'}} aria-hidden="true"
            className="icon-eye-2-icon"
          />}
        </div>
        <div className="icon"
          onClick={() => {
            console.log(node);
            manager.focusOn(node);
          }}
        >
          <span aria-hidden="true"
            className="icon-hand-icon"
          />
        </div>
        <div className="icon"
          onClick={() => {
            node.setEditorVisible(!node.editorVisible);
            const getNodes = (n: BaseWidget): BaseWidget[] => {
              const result = [ n ];
              for(let i = 0; i < n.children.length; i++) {
                result.push(...getNodes(n.children[i]));
              }
              return result;
            }
            const nodeAndChildren = getNodes(node);

            manager.setSelection(nodeAndChildren, false);
            manager.dispatchProVizSceneUpdate();
            forceUpdate();
          }}
        >
          <span aria-hidden="true"
            className="icon-share1"
          />
        </div>
        <div className="icon"
          onClick={() => {
            node.setEditorVisible(!node.editorVisible);
            manager.dispatchProVizSceneUpdate();
            forceUpdate();
          }}
        >
          <span aria-hidden="true"
            className={
              node.editorVisible ? "icon-eye-icon" : "icon-eye-2-icon"
            }
          />
        </div>
        <div className="icon"
          onClick={() => {
            node.setEditorLocked(!node.editorLocked);
            manager.dispatchProVizSceneUpdate();
            forceUpdate();
          }}
        >
          <span aria-hidden="true"
            className={
              node.editorLocked
                ? "icon-locked-icon"
                : "icon-ProViz-Studio-Assets-58"
            }
          />
        </div>
      </div>
      {(!node.editorMinimized || searchString) && <div className="nodeChildren">
        {node.children
          .filter(flowFilterFn)
          .map((childWdg) => <NodeTree node={childWdg} key={childWdg.id} />)
        }
      </div>}
    </div>;
  };



  const SceneTree = () => {
    const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDropTopLevel = (e: React.DragEvent<HTMLElement>) => {
    
      const fromResource = e.dataTransfer.getData("fromResourceCard");

      if (fromResource === 'true') {
        handleResourceDrop(e, undefined, false);
        return
      }

      const nodeId = e.dataTransfer.getData("node/id");

      const nodeToRemove = manager.getById(nodeId);
      if (nodeToRemove) {
        manager.remove(nodeToRemove);
        manager.add(nodeToRemove);
      }

      e.preventDefault();
      e.stopPropagation();
    };

    const flowFilterFn = showFlow
      ? (_: BaseWidget) => true
      : (x: BaseWidget) => x.usage !== "Flow";

    return <div
      className="nodeSelectorEntry drag-drop-zone"
      key="scene-node"
      onDrop={(e) => handleDropTopLevel(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      <div
        id="node-scene"
        style={{ gridTemplateColumns: "1fr" }}
        className={`nodeSelectEntity ${selected.length === 0 ? "selected" : ""}`}
      >
        <div
          style={{ display: "flex", width: "100%", boxSizing: "border-box" }}
          onClick={() => { 
            manager.setSelection(null, true);
          }}
        >
          <div style={{ flex: "1" }}>Scene</div>
          <div style={{ width: "17px" }} className="icon">
            <img
              className={`flow-icon ${showFlow ? "selected" : ""}`}
              onClick={() => setShowFlow(!showFlow)}
              src={flowIcon}
              title="Show flow widgets in list."
              alt=""
            />
          </div>
        </div>
        <div className="nodeSelectIndex">&nbsp;</div>
      </div>
      <div className="nodeChildren" style={{ margin: 0 }}>
        {nodes
          .filter(flowFilterFn)
          .map((node: BaseWidget, idx: number) =>
            <NodeTree node={node} key={`wdg-${idx}`} />
          )}
      </div>
    </div>;
  };

  return <div className="nodeSelector" onDrop={handleResourceDrop}>
    <HierarchySearchBar
      setSearchParams={(str: string) => setSearchString(str)}
      clear={() => setSearchString("")}
      searchString={searchString}
    />
    <SceneTree />
  </div>
}
