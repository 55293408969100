import {
  BoxGeometry,
  BufferGeometry,
  Camera,
  CylinderGeometry,
  Euler,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Quaternion,
  Vector3,
} from "three";
import { GizmoGroup } from "./GizmoGroup";
import { GizmoPart } from "./gizmos/GizmoBase";
import { RotateGizmo } from "./gizmos/RotateGizmo";
import { RotateHelper } from "./gizmos/RotateHelper";
import { RotatePicker } from "./gizmos/RotatePicker";
import { ScaleGizmo } from "./gizmos/ScaleGizmo";
import { ScaleHelper } from "./gizmos/ScaleHelper";
import { ScalePicker } from "./gizmos/ScalePicker";
import { TranslateGizmo } from "./gizmos/TranslateGizmo";
import { TranslateHelper } from "./gizmos/TranslateHelper";
import { TranslatePicker } from "./gizmos/TranslatePicker";

export const _tempEuler = new Euler();
export const _alignVector = new Vector3(0, 1, 0);
export const _zeroVector = new Vector3(0, 0, 0);
export const _lookAtMatrix = new Matrix4();
export const _tempQuaternion = new Quaternion();
export const _tempQuaternion2 = new Quaternion();
export const _identityQuaternion = new Quaternion();
export const _dirVector = new Vector3();
export const _tempVector = new Vector3();
export const _tempMatrix = new Matrix4();

export const _unitX = new Vector3(1, 0, 0);
export const _unitY = new Vector3(0, 1, 0);
export const _unitZ = new Vector3(0, 0, 1);

export const _v1 = new Vector3();
export const _v2 = new Vector3();
export const _v3 = new Vector3();

export type TransformMode = "translate" | "scale" | "rotate";
export type TransformAxis = "X" | "Y" | "Z" | "XYZE" | "E" | "XYZ";

export class ProVizTransformControlsGizmo extends Object3D {
  gizmo: GizmoGroup;
  picker: GizmoGroup;
  helper: GizmoGroup;

  mode: TransformMode = "translate";
  space: string = "local";
  axis: TransformAxis = "X";
  rotationAxis: Vector3 = new Vector3();
  worldPosition: Vector3 = new Vector3();
  worldPositionStart: Vector3 = new Vector3();
  worldQuaternion: Quaternion = new Quaternion();
  worldQuaternionStart: Quaternion = new Quaternion();
  camera: Camera;
  cameraPosition: Vector3 = new Vector3();
  eye: Vector3 = new Vector3();
  size: number = 1;
  dragging: boolean = false;
  showX: boolean = true;
  showY: boolean = true;
  showZ: boolean = true;
  enabled: boolean = true;

  constructor() {
    super();
    this.type = "TransformControlsGizmo";

    // shared materials
    const gizmoMaterial = new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
      fog: false,
      toneMapped: false,
      transparent: true,
    });

    const gizmoLineMaterial = new LineBasicMaterial({
      depthTest: false,
      depthWrite: false,
      fog: false,
      toneMapped: false,
      transparent: true,
    });

    // Make unique material for each axis/color
    const matInvisible = gizmoMaterial.clone();
    matInvisible.opacity = 0.15;

    const matHelper = gizmoLineMaterial.clone();
    matHelper.opacity = 0.5;

    const matRed = gizmoMaterial.clone();
    matRed.color.setHex(0xff0000);

    const matGreen = gizmoMaterial.clone();
    matGreen.color.setHex(0x00ff00);

    const matBlue = gizmoMaterial.clone();
    matBlue.color.setHex(0x0000ff);

    const matRedTransparent = gizmoMaterial.clone();
    matRedTransparent.color.setHex(0xff0000);
    matRedTransparent.opacity = 0.5;

    const matGreenTransparent = gizmoMaterial.clone();
    matGreenTransparent.color.setHex(0x00ff00);
    matGreenTransparent.opacity = 0.5;

    const matBlueTransparent = gizmoMaterial.clone();
    matBlueTransparent.color.setHex(0x0000ff);
    matBlueTransparent.opacity = 0.5;

    const matWhiteTransparent = gizmoMaterial.clone();
    matWhiteTransparent.opacity = 0.25;

    const matYellowTransparent = gizmoMaterial.clone();
    matYellowTransparent.color.setHex(0xffff00);
    matYellowTransparent.opacity = 0.25;

    const matYellow = gizmoMaterial.clone();
    matYellow.color.setHex(0xffff00);

    const matGray = gizmoMaterial.clone();
    matGray.color.setHex(0x787878);

    // reusable geometry
    const arrowGeometry = new CylinderGeometry(0, 0.04, 0.1, 12);
    arrowGeometry.translate(0, 0.05, 0);

    const scaleHandleGeometry = new BoxGeometry(0.08, 0.08, 0.08);
    scaleHandleGeometry.translate(0, 0.04, 0);

    const lineGeometry = new BufferGeometry();
    lineGeometry.setAttribute(
      "position",
      new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3)
    );

    const lineGeometry2 = new CylinderGeometry(0.0075, 0.0075, 0.5, 3);
    lineGeometry2.translate(0, 0.25, 0);

    const gizmoTranslate = TranslateGizmo();
    const pickerTranslate = TranslatePicker();
    const helperTranslate = TranslateHelper();
    const gizmoRotate = RotateGizmo();
    const helperRotate = RotateHelper();
    const pickerRotate = RotatePicker();
    const gizmoScale = ScaleGizmo();
    const pickerScale = ScalePicker();
    const helperScale = ScaleHelper();

    // Creates an Object3D with gizmos described in custom hierarchy definition.
    function setupGizmo(gizmoMap: { [key: string]: GizmoPart[] }) {
      const gizmo = new Object3D();
      for (const name in gizmoMap) {
        for (let i = gizmoMap[name].length; i--; ) {
          const object = gizmoMap[name][i].mesh.clone();
          const position = gizmoMap[name][i].position;
          const rotation = gizmoMap[name][i].rotation;
          const scale = gizmoMap[name][i].scale;
          const tag = gizmoMap[name][i].category;

          // name and tag properties are essential for picking and updating logic.
          object.name = name;
          object.userData = { tag };

          if (position) {
            object.position.set(position[0], position[1], position[2]);
          }

          if (rotation) {
            object.rotation.set(rotation[0], rotation[1], rotation[2]);
          }

          if (scale) {
            object.scale.set(scale[0], scale[1], scale[2]);
          }

          object.updateMatrix();

          const tempGeometry = object.geometry.clone();
          tempGeometry.applyMatrix4(object.matrix);
          object.geometry = tempGeometry;
          object.renderOrder = Infinity;

          object.position.set(0, 0, 0);
          object.rotation.set(0, 0, 0);
          object.scale.set(1, 1, 1);

          gizmo.add(object);
        }
      }
      return gizmo;
    }

    // Gizmo creation
    this.gizmo = new GizmoGroup(
      setupGizmo(gizmoTranslate),
      setupGizmo(gizmoRotate),
      setupGizmo(gizmoScale)
    );
    this.add(this.gizmo.translate, this.gizmo.rotate, this.gizmo.scale);
    this.picker = new GizmoGroup(
      setupGizmo(pickerTranslate),
      setupGizmo(pickerRotate),
      setupGizmo(pickerScale)
    );
    this.add(this.picker.translate, this.picker.rotate, this.picker.scale);
    this.helper = new GizmoGroup(
      setupGizmo(helperTranslate),
      setupGizmo(helperRotate),
      setupGizmo(helperScale)
    );
    this.add(this.helper.translate, this.helper.rotate, this.helper.scale);

    // Pickers should be hidden always
    this.picker.translate.visible = false;
    this.picker.rotate.visible = false;
    this.picker.scale.visible = false;
  }

  // updateMatrixWorld will update transformations and appearance of individual handles
  updateMatrixWorld(force: boolean) {
    const space = this.mode === "scale" ? "local" : this.space; // scale always oriented to local rotation
    const quaternion =
      space === "local" ? this.worldQuaternion : _identityQuaternion;

    // Show only gizmos for current transform mode
    this.gizmo["translate"].visible = this.mode === "translate";
    this.gizmo["rotate"].visible = this.mode === "rotate";
    this.gizmo["scale"].visible = this.mode === "scale";

    this.helper["translate"].visible = this.mode === "translate";
    this.helper["rotate"].visible = this.mode === "rotate";
    this.helper["scale"].visible = this.mode === "scale";

    let handles: Object3D[] = [];
    handles = handles.concat(this.picker.modeChildren(this.mode));
    handles = handles.concat(this.gizmo.modeChildren(this.mode));
    handles = handles.concat(this.helper.modeChildren(this.mode));

    for (let i = 0; i < handles.length; i++) {
      if (!(handles[i] instanceof Mesh) && !(handles[i] instanceof Line)) {
        continue;
      }
      let handle: Mesh | Line = handles[i] as Mesh;

      // hide aligned to camera
      handle.visible = true;
      handle.rotation.set(0, 0, 0);
      handle.position.copy(this.worldPosition);

      let factor;
      if (this.camera instanceof OrthographicCamera) {
        const cam: OrthographicCamera = this.camera as OrthographicCamera;
        factor = (cam.top - cam.bottom) / cam.zoom;
      } else {
        const cam: PerspectiveCamera = this.camera as PerspectiveCamera;
        factor =
          this.worldPosition.distanceTo(this.cameraPosition) *
          Math.min((1.9 * Math.tan((Math.PI * cam.fov) / 360)) / cam.zoom, 7);
      }

      handle.scale.set(1, 1, 1).multiplyScalar((factor * this.size) / 4);

      // TODO: simplify helpers and consider decoupling from gizmo
      if (handle.userData.tag === "helper") {
        handle.visible = false;
        if (handle.name === "AXIS") {
          handle.position.copy(this.worldPositionStart);
          handle.visible = !!this.axis;
          if (this.axis === "X") {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, 0, 0));
            handle.quaternion.copy(quaternion).multiply(_tempQuaternion);

            if (
              Math.abs(
                _alignVector
                  .copy(_unitX)
                  .applyQuaternion(quaternion)
                  .dot(this.eye)
              ) > 0.9
            ) {
              handle.visible = false;
            }
          }

          if (this.axis === "Y") {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, 0, Math.PI / 2));
            handle.quaternion.copy(quaternion).multiply(_tempQuaternion);

            if (
              Math.abs(
                _alignVector
                  .copy(_unitY)
                  .applyQuaternion(quaternion)
                  .dot(this.eye)
              ) > 0.9
            ) {
              handle.visible = false;
            }
          }

          if (this.axis === "Z") {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, Math.PI / 2, 0));
            handle.quaternion.copy(quaternion).multiply(_tempQuaternion);

            if (
              Math.abs(
                _alignVector
                  .copy(_unitZ)
                  .applyQuaternion(quaternion)
                  .dot(this.eye)
              ) > 0.9
            ) {
              handle.visible = false;
            }
          }

          if (this.axis === "XYZE") {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, Math.PI / 2, 0));
            _alignVector.copy(this.rotationAxis);
            handle.quaternion.setFromRotationMatrix(
              _lookAtMatrix.lookAt(_zeroVector, _alignVector, _unitY)
            );
            handle.quaternion.multiply(_tempQuaternion);
            handle.visible = this.dragging;
          }

          if (this.axis === "E") {
            handle.visible = false;
          }
        } else if (handle.name === "START") {
          handle.position.copy(this.worldPositionStart);
          handle.visible = this.dragging;
        } else if (handle.name === "END") {
          handle.position.copy(this.worldPosition);
          handle.visible = this.dragging;
        } else if (handle.name === "DELTA") {
          handle.position.copy(this.worldPositionStart);
          handle.quaternion.copy(this.worldQuaternionStart);
          _tempVector
            .set(1e-10, 1e-10, 1e-10)
            .add(this.worldPositionStart)
            .sub(this.worldPosition)
            .multiplyScalar(-1);
          _tempVector.applyQuaternion(
            this.worldQuaternionStart.clone().invert()
          );
          handle.scale.copy(_tempVector);
          handle.visible = this.dragging;
        } else {
          handle.quaternion.copy(quaternion);
          if (this.dragging) {
            handle.position.copy(this.worldPositionStart);
          } else {
            handle.position.copy(this.worldPosition);
          }

          if (this.axis) {
            handle.visible = this.axis.search(handle.name) !== -1;
          }
        }
        // If updating helper, skip rest of the loop
        continue;
      }

      // Align handles to current local or world rotation
      handle.quaternion.copy(quaternion);

      if (this.mode === "translate" || this.mode === "scale") {
        // Hide translate and scale axis facing the camera
        const AXIS_HIDE_TRESHOLD = 0.99;
        const PLANE_HIDE_TRESHOLD = 0.2;

        if (handle.name === "X") {
          if (
            Math.abs(
              _alignVector
                .copy(_unitX)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }
        if (handle.name === "Y") {
          if (
            Math.abs(
              _alignVector
                .copy(_unitY)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }
        if (handle.name === "Z") {
          if (
            Math.abs(
              _alignVector
                .copy(_unitZ)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }
        if (handle.name === "XY") {
          if (
            Math.abs(
              _alignVector
                .copy(_unitZ)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }
        if (handle.name === "YZ") {
          if (
            Math.abs(
              _alignVector
                .copy(_unitX)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }
        if (handle.name === "XZ") {
          if (
            Math.abs(
              _alignVector
                .copy(_unitY)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }
      } else if (this.mode === "rotate") {
        // Align handles to current local or world rotation
        _tempQuaternion2.copy(quaternion);
        _alignVector
          .copy(this.eye)
          .applyQuaternion(_tempQuaternion.copy(quaternion).invert());

        if (handle.name.search("E") !== -1) {
          handle.quaternion.setFromRotationMatrix(
            _lookAtMatrix.lookAt(this.eye, _zeroVector, _unitY)
          );
        }
        if (handle.name === "X") {
          _tempQuaternion.setFromAxisAngle(
            _unitX,
            Math.atan2(-_alignVector.y, _alignVector.z)
          );
          _tempQuaternion.multiplyQuaternions(
            _tempQuaternion2,
            _tempQuaternion
          );
          handle.quaternion.copy(_tempQuaternion);
        }
        if (handle.name === "Y") {
          _tempQuaternion.setFromAxisAngle(
            _unitY,
            Math.atan2(_alignVector.x, _alignVector.z)
          );
          _tempQuaternion.multiplyQuaternions(
            _tempQuaternion2,
            _tempQuaternion
          );
          handle.quaternion.copy(_tempQuaternion);
        }
        if (handle.name === "Z") {
          _tempQuaternion.setFromAxisAngle(
            _unitZ,
            Math.atan2(_alignVector.y, _alignVector.x)
          );
          _tempQuaternion.multiplyQuaternions(
            _tempQuaternion2,
            _tempQuaternion
          );
          handle.quaternion.copy(_tempQuaternion);
        }
      }

      // Hide disabled axes
      handle.visible =
        handle.visible && (handle.name.indexOf("X") === -1 || this.showX);
      handle.visible =
        handle.visible && (handle.name.indexOf("Y") === -1 || this.showY);
      handle.visible =
        handle.visible && (handle.name.indexOf("Z") === -1 || this.showZ);
      handle.visible =
        handle.visible &&
        (handle.name.indexOf("E") === -1 ||
          (this.showX && this.showY && this.showZ));

      // highlight selected axis
      const mat: MeshBasicMaterial = handle.material as MeshBasicMaterial;
      const matAny: any = mat;

      matAny._color = matAny._color || mat.color.clone();
      matAny._opacity = matAny._opacity || mat.opacity;

      mat.color.copy(matAny._color);
      mat.opacity = matAny._opacity;

      if (this.enabled && this.axis) {
        if (handle.name === this.axis) {
          mat.color.setHex(0xffff00);
          mat.opacity = 1.0;
        } else if (
          this.axis.split("").some(function (a) {
            return handle.name === a;
          })
        ) {
          mat.color.setHex(0xffff00);
          mat.opacity = 1.0;
        }
      }
    }
    super.updateMatrixWorld(force);
  }
}
