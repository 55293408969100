import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class NotWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'not';
  private static instanceCount = 0;
  
  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    if (!notInScene) {
      NotWidget.instanceCount++;
    }
    this.label = 'Not ' + NotWidget.instanceCount;

    this.usage = 'Flow';
    this.category = 'Conditional';

    this.widgetType = NotWidget.type;
    this.widgetName = 'Not';
    this.selectable = true;
    this.events = [
      {
        label: 'Value',
        name: 'value',
      },
    ];

    this.addService('In', 'in', 'In bool value', (event: ProVizEventData) => {
      if (event.dataType === 'boolean') {
        const d = event.data as boolean;
        this.triggerProVizEvent('value', 'boolean', !d);
      }
    });

  }
}

ModuleService.Register(NotWidget.type, NotWidget);
