export default class BufferedSet {
  onChange: (data: string) => void;
  value: string;
  timer: NodeJS.Timeout;

  constructor(onChange: (data: string) => void) {
    this.onChange = onChange;
    this.value = "";
  }

  setValue(data: string) {
    this.value = data;
    this.onChange(this.value);
  }
}
