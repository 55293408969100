import { Mesh, MeshBasicMaterial, SphereGeometry, TorusGeometry } from "three";
import { GizmoPart } from "./GizmoBase";

export function RotatePicker(): { [key: string]: GizmoPart[] } {
  const gizmoMaterial = new MeshBasicMaterial({
    depthTest: false,
    depthWrite: false,
    fog: false,
    toneMapped: false,
    transparent: true,
  });

  const matInvisible = gizmoMaterial.clone();
  matInvisible.opacity = 0.15;

  return {
    XYZE: [
      new GizmoPart(new Mesh(new SphereGeometry(0.25, 10, 8), matInvisible)),
    ],
    X: [
      new GizmoPart(
        new Mesh(new TorusGeometry(0.5, 0.1, 4, 24), matInvisible),
        [0, 0, 0],
        [0, -Math.PI / 2, -Math.PI / 2]
      ),
    ],
    Y: [
      new GizmoPart(
        new Mesh(new TorusGeometry(0.5, 0.1, 4, 24), matInvisible),
        [0, 0, 0],
        [Math.PI / 2, 0, 0]
      ),
    ],
    Z: [
      new GizmoPart(
        new Mesh(new TorusGeometry(0.5, 0.1, 4, 24), matInvisible),
        [0, 0, 0],
        [0, 0, -Math.PI / 2]
      ),
    ],
    E: [
      new GizmoPart(
        new Mesh(new TorusGeometry(0.75, 0.1, 2, 24), matInvisible)
      ),
    ],
  };
}
