import {Component} from 'react'

// class ErrorBoundary extends Component<any, any> {
//   constructor(props: any) {
//     super(props);
//     this.state = { 
//       hasError: false,
//       error: null,
//       errorInfo: null
//     };
//   }

//   // static getDerivedStateFromError(error: any) {
//   //   console.log("YEP")
//   //   // Update state so the next render will show the fallback UI.
//   //   return { hasError: true };
//   // }

//   componentDidCatch(error: any, errorInfo: any) {
//     console.log("ERROR WAS CAUGHT!!!")
//     this.setState({
//       error: error,
//       errorInfo: errorInfo
//     })
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return (
//         <div className="ErrorBoundary">
//           <h1>Something went wrong.</h1>
//           <details style={{ whiteSpace: 'pre-wrap' }}>
//             {this.state.error && this.state.error.toString()}
//             <br />
//             {this.state.errorInfo.componentStack}
//           </details>
//         </div>
//       )
//     }

//     return this.props.children; 
//   }
// }

// export default ErrorBoundary;

class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}

export default ErrorBoundary;