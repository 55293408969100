import { PointLight, PointLightHelper } from 'three';
import { BaseWidgetProperty } from '..';
import { ModuleService } from '../../moduleService';
import { ProVizScene, SceneMode } from '../..';
import { BaseWidget } from '../baseWidget';
import { BasePositionableWidget } from '../basePositionableWidget';
import { IBaseWidgetType } from '../IBaseWidgetType';

export class PointLightWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'point-light';

  // Data
  public color: string = '#fff';
  public intensity: number = 1;
  public castShadow: boolean = true;

  private light?: PointLight;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = PointLightWidget.type;
    this.widgetName = 'Point Light';
    this.label = 'Point Light';
    this.category = 'Lights';
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    this.light = new PointLight(this.color, this.intensity);
    this.light.castShadow = true;
    this.light.shadow.camera.near = 1;
    this.light.shadow.camera.far = 100;
    this.light.shadow.bias = 0.001;

    this.light.shadow.mapSize.width = 2048;
    this.light.shadow.mapSize.height = 2048;
    this.renderNode.add(this.light);

    if (this.scene.sceneMode === SceneMode.Editor) {
      const helper = new PointLightHelper(this.light, 0.4, ' #181818');
      this.renderNode.add(helper);
    }

    return true;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('color', 'Color', 'Core', 'color', 'string', true, undefined, (data: string) => {
        this.color = data;
        if (this.light) {
          this.light.color.set(this.color);
        }
      }),
      this.createProperty(
        'intensity',
        'Intensity',
        'Core',
        'constrained-number',
        'number',
        true,
        undefined,
        (data: number) => {
          this.intensity = data;
          if (this.light) {
            this.light.intensity = this.intensity;
          }
        },
        () => [0, 35].map(x => { return { key: x, label: x.toString() }}),
      ),
      this.createProperty(
        'castShadow',
        'Cast Shadow',
        'Core',
        'bool',
        'boolean',
        true,
        undefined,
        (data: boolean) => {
          this.castShadow = data;
          this.updateShadow();
        },
      ),
    ];
  }

  updateShadow() {
    if (this.light) {
      this.light.castShadow = this.castShadow;
    }
  }

  public serialize(): any {
    const result = super.serialize();
    result.color = this.color;
    result.intensity = this.intensity;
    result.castShadow = this.castShadow;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.color = data.color;
    this.intensity = data.intensity;
    this.castShadow = data.castShadow;
  }
}

ModuleService.Register(PointLightWidget.type, PointLightWidget);
