import { BaseDataService, IServiceOptions } from "../BaseDataService";

class APITagService extends BaseDataService<any> {
  constructor() {
    super("tag");
  }

  async create(name: string, options?: IServiceOptions) {
    const body = new FormData();
    body.append("name", name);
    return await this.submit("", "POST", body, options);
  }
}

const TagService = new APITagService();

export default TagService;
