import { DataSchemaService } from '@proviz/api-services';
import { APIDataSchema } from '@proviz/api-services/lib/models/DataSchema';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../common/button/Button';
import PageContentHeader from '../pageContentHeader';

export function SchemasView() {
    const history = useHistory();
    const [ schemas, setSchemas ] = useState<APIDataSchema[]>([]);

    useEffect(() => {
        DataSchemaService.getAll().then(setSchemas);
        // DatabaseService.getAll()
    }, []);

    const deleteSchema = async (schema: APIDataSchema) => {
        if (window.confirm('Are you sure you want to delete this schema?')) {
            await DataSchemaService.delete(schema.id);
            DataSchemaService.getAll().then(setSchemas);
        }
    }

    return <>
        <PageContentHeader title="Schemas">
            <Button onClick={() => {
                // create schema
                history.push('/home/resources/schemas/create');
            }}>Create</Button>
        </PageContentHeader>
        <table>
            {schemas.map(s => <tr>
                <td>
                    {s.name}
                </td>
                <td>
                    <Button type='danger' onClick={() => deleteSchema(s)}>Delete</Button>
                </td>
            </tr>)}
        </table>
    </>;
}
