import { APICompany } from "..";
import { BaseDataService } from "../BaseDataService";
import { APICompanyLicense, APILicense } from "../models/License";

export class APICompanyLicenseService extends BaseDataService<APICompanyLicense> {
  constructor() {
    super("license");
  }

  async all() : Promise<APICompanyLicense[]> {
    return await this.request('all');
  }

  async create(name: string): Promise<APICompanyLicense> {
    return await this.post('', { name });
  }

  async add(id: string): Promise<APICompanyLicense> {
    return await this.post(id);
  }

  async update(id: string, data: APICompanyLicense) {
    return await this.put(`${id}`, {
      name: data.license.name,
      settings: data.license.settings
    });
  }

  async available() : Promise<APILicense[]> {
    return await this.request('available');
  }

  async getOrgs(licenseId: string) : Promise<APICompany[]> {
    return await this.request(`${licenseId}/orgs`);
  }

  async updateExpiration(licenseId: string, expires?: Date) {
    return await this.put(`${licenseId}/expires`, {
      expires
    });
  }
}

const CompanyLicenseService = new APICompanyLicenseService();

export default CompanyLicenseService;
