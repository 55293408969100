import { useState, useEffect, ReactElement } from "react";
import { Tab, TabBar, TabPanel } from "../../common/tabs";
import WidgetMenu from "./WidgetMenu";
import ResourceMenu from "./ResourceMenu";
import { ResourceManagerStore } from "../../../store/ResourceStore";
import AnimationMenu from "./AnimationMenu";

export default function SceneEditorBottomContainer(): ReactElement {
  const [tab, setTab] = useState(0);

  const selectResourceCallback = ResourceManagerStore(
    (s) => s.selectResourceCallback
  );

  // Focus the resource tab when someone is selecting the change button in the sidebar
  useEffect(() => {
    if (selectResourceCallback !== undefined) {
      setTab(1);
    }
  }, [selectResourceCallback]);

  return (
    <div className="sceneEditorBottomContainer">
      <TabBar>
        <Tab
          label="Widgets"
          index={0}
          value={tab}
          onClick={() => setTab(0)}
          selectedLabel="label selectedLabel"
          unselectedLabel="label unselectedLabel"
        />
        <Tab
          label="Resources"
          index={1}
          value={tab}
          onClick={() => setTab(1)}
          selectedLabel="label selectedLabel"
          unselectedLabel="label unselectedLabel"
        />
        <Tab
          label="Animation Track"
          index={2}
          value={tab}
          onClick={() => setTab(2)}
          selectedLabel="label selectedLabel"
          unselectedLabel="label unselectedLabel"
        />
      </TabBar>

      <TabPanel index={0} value={tab}>
        <WidgetMenu />
      </TabPanel>
      <TabPanel index={1} value={tab}>
        <ResourceMenu />
      </TabPanel>
      <TabPanel index={2} value={tab}>
        <AnimationMenu open={tab === 2} />
      </TabPanel>
    </div>
  );
}
