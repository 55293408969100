import { ModuleService } from '../../../moduleService';
import { ProVizEventData } from '../../../ProVizEventData';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class EventPassWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'event-pass';
  public static eventPassWidgets: EventPassWidget[] = [];

  // Data
  public event: string | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'Event Pass';

    this.usage = 'Flow';
    this.category = 'Events';

    this.widgetType = EventPassWidget.type;
    this.widgetName = 'Event Pass';
    this.selectable = true;
    this.events = [ { name: 'out', label: 'Out', desc: 'Event to pass'} ];

    this.services = [ ];

    this.addService('In', 'in', 'Event to pass', (event: ProVizEventData) => {
      console.log(EventPassWidget.eventPassWidgets);
      EventPassWidget.eventPassWidgets.filter(x => x.event === this.event).forEach(w => w.triggerProVizEvent('out', event.dataType, event.data));
    });

    EventPassWidget.eventPassWidgets.push(this);
  }
  
  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('event', 'Event', 'Core', 'string', 'string', true)
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.event = this.event;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.event = data.event ?? this.event;
  }
}

ModuleService.Register(EventPassWidget.type, EventPassWidget);
