import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class VoiceHandlerWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'voice-handler';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);

    this.usage = 'Flow';
    this.category = 'Others';
    this.widgetType = VoiceHandlerWidget.type;
    this.widgetName = 'Voice Handler';
    this.label = 'Voice Handler';
    this.selectable = true;
    this.events = [
      {
        label: 'Select',
        name: 'select',
      },
      {
        label: 'Confirm',
        name: 'confirm',
      },
      {
        label: 'Scan',
        name: 'scan',
      },
      {
        label: 'Scan Code',
        name: 'scan code',
      },
      {
        label: 'Cancel',
        name: 'cancel',
      },
      {
        label: 'Exit',
        name: 'exit',
      },
      {
        label: 'Show Card',
        name: 'show card',
      },
    ];
    this.services = [];
  }
}

ModuleService.Register(VoiceHandlerWidget.type, VoiceHandlerWidget);
