import { Vector2, Intersection, PerspectiveCamera } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import Renderer from "../Renderer";
import ModelViewManager from "../ModelViewManager";
import { SetModelRotation, SetModelScale } from "../../commands";
import Controls from "./Controls";
import { getMousePosition } from "../Raycast";
import { Rotation, Scale } from "../Types";

export type ModelViewerControlModes = "rotate" | "scale";

function samePosition(v1: Vector2, v2: Vector2) {
  return v1.x === v2.x && v1.y && v2.y;
}

export function isModelontrols(c: any): c is ModelViewControls {
  return c.type === "ModelControls";
}

export default class ModelViewControls extends Controls {
  type = "ModelControls";
  transformControls: TransformControls;
  orbitControls: OrbitControls;
  mode: ModelViewerControlModes = "scale";
  manager: ModelViewManager;
  renderer: Renderer;
  scaleOnDown: Scale;
  rotationOnDown: Rotation;
  enabled: boolean = true;
  mouseDown: boolean = false;
  // This is hacky, need to re-work the mouse click events
  clickOverride?: (intersections: Intersection[]) => void;

  constructor(
    modelViewManager: ModelViewManager,
    camera: PerspectiveCamera,
    renderer: Renderer
  ) {
    super(renderer.domElement, modelViewManager.helperScene);
    this.renderer = renderer;
    this.manager = modelViewManager;
    this.transformControls = new TransformControls(camera, renderer.domElement);
    this.orbitControls = new OrbitControls(camera, renderer.domElement);
    // binding here instead of using arrow functions to enable listener disposal
    this.controlsHandleChange = this.controlsHandleChange.bind(this);
    this.controlsOnMouseDown = this.controlsOnMouseDown.bind(this);
    this.controlsOnMouseUp = this.controlsOnMouseUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.receiveSelectionUpdate = this.receiveSelectionUpdate.bind(this);
    this.controlsHandleChange = this.controlsHandleChange.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.controlsOnMouseMove = this.controlsOnMouseMove.bind(this);

    this.manager.attach("selectednode-update", () =>
      this.receiveSelectionUpdate()
    );

    let transformControls = this.transformControls;
    transformControls.enabled = true;
    transformControls.setMode(this.mode);
    // transformControls.addEventListener("change", _ => this.controlsHandleChange());
    // transformControls.addEventListener("mouseDown", _ => this.controlsOnMouseDown());
    // transformControls.addEventListener("mouseUp", _ => this.controlsOnMouseUp());
    transformControls.addEventListener("change", this.controlsHandleChange);
    transformControls.addEventListener("mouseDown", this.controlsOnMouseDown);
    transformControls.addEventListener("mouseUp", this.controlsOnMouseUp);
    document.addEventListener("pointermove", this.controlsOnMouseMove, false);

    modelViewManager.helperScene.add(this.transformControls);
    modelViewManager.attach("history-update", this.controlsHandleChange);

    // OrbitControls uses Pointer Events which requires the app to use Poiner Events, too.
    // https://discourse.threejs.org/t/mousedown-event-is-not-getting-triggered/18685
    this.renderer.domElement.parentElement?.addEventListener(
      "pointerdown",
      this.onMouseDown
    );
  }

  controlsOnMouseMove() {
    if (this.mouseDown) {
      this.renderer.render();
    }
  }

  controlsHandleChange() {
    const object = this.transformControls.object;
    const { modelWidget } = this.manager;
    if (!object || !modelWidget) {
      this.setBoxes([]);
      return;
    }
    this.setBoxes([modelWidget]);
    this.renderer.render();
  }

  handleClick() {
    if (samePosition(this.onDownPosition, this.onUpPosition)) {
      let intersections = this.raycaster.intersectObjects(
        this.onUpPosition,
        this.manager.modelWidget ? [this.manager.modelWidget.renderNode] : [],
        this.manager.camera.camera
      );

      if (this.clickOverride !== undefined) {
        this.clickOverride(intersections);
        return;
      }

      if (!this.enabled) return;

      if (intersections.length > 0) {
        this.manager.setSelected(true);
      } else {
        this.manager.setSelected(false);
      }
      this.renderer.render();
    }
  }

  onMouseDown(event: MouseEvent) {
    this.mouseDown = true;
    const array = getMousePosition(
      this.renderer.domElement.parentElement!,
      event.clientX,
      event.clientY
    );
    this.onDownPosition.fromArray(array);
    try {
      this.scaleOnDown = this.manager.scale();
      this.rotationOnDown = this.manager.rotation();
    } catch (e) {
      console.error("Clicking too early, model may still be loading", e);
      return;
    }

    document.addEventListener("pointerup", this.onMouseUp, false);
  }

  onMouseUp(event: MouseEvent) {
    this.mouseDown = false;
    const array = getMousePosition(
      this.renderer.domElement.parentElement!,
      event.clientX,
      event.clientY
    );
    this.onUpPosition.fromArray(array);

    this.handleClick();

    document.removeEventListener("pointerup", this.onMouseUp, false);
  }

  receiveSelectionUpdate() {
    if (!this.enabled) return;

    const { modelWidget, modelSelected } = this.manager;
    if (!modelSelected || !modelWidget) {
      this.transformControls.detach();
    } else {
      this.transformControls.attach(modelWidget.renderNode);
    }
  }

  controlsOnMouseDown() {
    const object = this.transformControls.object;
    if (!object) {
      return;
    }
    this.objectPositionOnDown.copy(object.position);
    this.objectRotationOnDown.copy(object.rotation);
    this.objectScaleOnDown.copy(object.scale);

    this.orbitControls.enabled = false;
  }

  controlsOnMouseUp() {
    const object = this.transformControls.object;
    if (!object) {
      return;
    }
    switch (this.transformControls.getMode()) {
      case "rotate":
        if (!this.objectRotationOnDown.equals(object.rotation)) {
          const { x, y, z } = object.rotation;
          this.manager.execute(
            new SetModelRotation(this.manager, { x, y, z }, this.rotationOnDown)
          );
        }
        break;
      case "scale":
        if (!this.objectScaleOnDown.equals(object.scale)) {
          const { x, y, z } = object.scale;
          this.manager.execute(
            new SetModelScale(this.manager, { x, y, z }, this.scaleOnDown)
          );
        }
        break;
    }
    this.orbitControls.enabled = true;
  }

  changeMode(mode: ModelViewerControlModes) {
    this.mode = mode;
    this.transformControls.setMode(mode);
    this.dispatchModeUpdate();
    if (this.manager.modelWidget) {
      this.manager.setSelected(true);
    }
  }

  update() {
    if (!this.enabled) return;

    this.orbitControls.update();
  }
}
