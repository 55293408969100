import Button from './Button';

type Props = {
  onClick: (e: any) => void;
};

export default function UploadButton(props: Props) {
  return <Button onClick={props.onClick}>
    <span className="icon icon-arrow-up"></span>
    &nbsp;Upload
  </Button>;
}