enum Analytic {
    Generic = 0,

    User = 100,
    UserCreate = 101,
    UserUpdate = 102,
    UserDelete = 103,
    UserLogin = 104,
    UserLogout = 105,
    UserInvite = 106,

    Scene = 200,
    SceneCreate = 201,
    SceneUpdate = 202,
    SceneDelete = 203,
    ScenePublish = 204,
    SceneOpen = 205,
    SceneView = 206,

    Model = 300,
    ModelUpload = 301,
    ModelCreate = 302,
    ModelUpdate = 303,
    ModelDelete = 304,
    ModelOpen = 305,
    ModelView = 306,
    ModelToGlobal = 307,
    ModelSourceAdded = 308,

    Organization = 400,
    OrganizationCreate = 401,
    OrganizationUpdate = 402,
    OrganizationDelete = 403,

    SceneSession = 500,
    SceneSessionStart = 501,
    SceneSessionEnd = 502,
    SceneSessionEvent = 503,

    File = 600,
    FileCreate = 601,
    FileUpdate = 602,
    FileDelete = 603,

    Target = 700,
    TargetCreated = 701,
    TargetUpdated = 702,
    TargetDelete = 703,
    TargetLockedOn = 704
}

export default Analytic;