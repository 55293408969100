import { ProVizScene } from '../../..';
import { BasePositionableWidget } from '../../basePositionableWidget';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry } from 'three';

enum ButtonType {
  Circle,
  Square,
}

export class ButtonWidget extends BasePositionableWidget implements IBaseWidgetType {
  public static type: string = 'button';

  // Data
  private buttonType: ButtonType = ButtonType.Circle;
  private showBacking: boolean = true;
  private useGaze: boolean = true;
  private gazeTime: number = 1.0;
  private imageId: string | undefined = undefined;
  private text: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);

    this.widgetType = ButtonWidget.type;
    this.widgetName = 'Button';
    this.label = 'Button';
    this.selectable = true;
    this.category = 'MRTK';

    this.events.push({
      label: 'Clicked',
      name: 'clicked',
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();

    return [
      ...result,
      this.createProperty(
        'buttonType',
        'Button Type',
        'Core',
        'select',
        'string',
        true,
        () => ButtonType[this.buttonType],
        (val: string) => {
          this.buttonType = ButtonType[val];
        },
        () =>
          Object.keys(ButtonType)
            .filter((value) => isNaN(Number(value)) === false)
            .map((key) => ButtonType[key]),
      ),
      this.createProperty('imageId', 'Image', 'Core', 'image', 'string', true),
      this.createProperty('text', 'Text', 'Core', 'string', 'string', true),
      this.createProperty('showBacking', 'Show Backing', 'Core', 'bool', 'boolean', true),
      this.createProperty('useGaze', 'Use Gaze Click', 'Core', 'bool', 'boolean', true),
      this.createProperty('gazeTime', 'Gaze Time', 'Core', 'number', 'number', true),
    ];
  }

  public async init(): Promise<boolean> {
    await super.init();

    this.renderNode.add(this.createPlane());

    return true;
  }

  private createPlane() {
    const geometry = new PlaneGeometry(1, 1, 10, 10);
    const material = new MeshBasicMaterial({ opacity: 1.0, side: DoubleSide, color: '#fff' });
    const mesh = new Mesh(geometry, material);
    mesh.name = this.label;
    mesh.rotation.set(0, 0, 0);
    return mesh;
  }

  public deserialize(data: any): void {
    super.deserialize(data);
    this.buttonType = data.buttonType ?? this.buttonType;
    this.imageId = data.imageId ?? this.imageId;
    this.text = data.text ?? this.text;
    this.showBacking = data.showBacking ?? this.showBacking;
    this.useGaze = data.useGaze ?? this.useGaze;
    this.gazeTime = data.gazeTime ?? this.gazeTime;
  }

  public serialize() {
    const result = super.serialize();
    result.buttonType = this.buttonType;
    result.imageId = this.imageId;
    result.text = this.text;
    result.showBacking = this.showBacking;
    result.useGaze = this.useGaze;
    result.gazeTime = this.gazeTime;
    return result;
  }
}

ModuleService.Register(ButtonWidget.type, ButtonWidget);
