import { getAbsoluteUrl } from "@proviz/proviz-sdk";
import { ReactNode, useState } from "react";

const downArrow = getAbsoluteUrl("images/downArrow.svg");
const leftArrow = getAbsoluteUrl("images/leftArrow.svg");

interface Props {
  children: ReactNode;
  startExpanded: boolean;
  groupName: string;
  iconSrc: string;
}

export const CollapsableMenuGroup = (props: Props) => {
  const [expanded, setExpanded] = useState(props.startExpanded);
  return (
    <>
      <div className="menus">
        <div className="content">
          <img className="categoryIcon" src={props.iconSrc} width={20} alt="" />
          <h4 className="widgetGroupName">{props.groupName}</h4>
          <img
            className="arrowIcon"
            src={expanded ? downArrow : leftArrow}
            alt={expanded ? "Expand" : "Minimize"}
            title={expanded ? "Expand" : "Minimize"}
            style={{ transform: expanded ? "rotate: 90deg" : "" }}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>
      {expanded && props.children}
    </>
  );
};
