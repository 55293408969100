export default class Command {
  name: string = "";
  type: string = "";
  id: number = -1;
  inMemory: boolean = false;
  updateable: boolean = false;

  toJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
    };
  }

  fromJSON(json: any) {
    this.inMemory = true;
    this.type = json.type;
    this.id = json.id;
    this.name = json.name;
  }

  execute() {}
  undo() {}
}
