import { ReactElement, useEffect, useState } from 'react';
import {
    APIModel, APIModelFile, initAbortController, isAbortError, ModelService,
    ModelVariantService
} from "@proviz/api-services"; 
import ModelName from '../modeleditor/ModelName';
import ThumbnailGenerator from '../../services/ThumbnailGenerator';
import { SimplifyButton } from './SimplifyButton';
import Modal from '../common/modals/Modals';
import { userStore } from '../../store';

interface Props {
    model: APIModel;
    close: () => void;
    refresh: () => void;
}

export default function ModelInfoModal(props: Props): ReactElement {
    const { model, close } = props;
    const user = userStore( s => s.user)
    const [status, setStatus] = useState('');
    const [files, setFiles] = useState<APIModelFile[]>([]);
    const [creatingVariant, setCreatingVariant] = useState(false)

    useEffect(() => {
        const abortController = initAbortController();
        ModelService.status(model.id, { abortController }).then((_status) => {
            setStatus(_status)
        }).catch((e) => {
            if (isAbortError(e)) return; // if the query was aborted, do nothing
            setStatus("Error")
            console.error("Could not get model status from model service", e);
        })
        return () => {
            abortController.abort()
        }
    });

    useEffect(() => {
        const abortController = initAbortController();
        ModelService.get(model.id, { abortController }).then((m) => {
            if (m.modelFiles) {
                setFiles(m.modelFiles);
            }
        })
        return () => abortController.abort()
    }, [model.id]);

    async function convert(fileId: string) {
        try {
            await ModelService.convert(model.id, fileId);
        } catch (e) {
            console.error('Could not convert model', e);
        }
    }

    async function createVariant(fileId: string) {
        if (creatingVariant) {
            return;
        }
        setCreatingVariant(true);
        try {
            await ModelService.createDefaultVariant(model.id, fileId)
        } catch (e) {
            alert(`creating variant failed ${e}`)
        }

        setCreatingVariant(false);
    }

    async function newThumbnail() {
        try {

            const thumbnailGenerator = new ThumbnailGenerator(287, 150)
            let file = files.filter((m) => m.file &&
                (m.file.extension === 'glb' || m.file.extension === 'gltf'
                    || m.file.extension === 'obj'))
            if (!file || file.length === 0) { return }
            let modelFile = file[0].file;
            let thumbnail;
            if (model.defaultVariantId) {
                let defaultVariant = await ModelVariantService.get(model.defaultVariantId)
                thumbnail = await thumbnailGenerator.Generate(model, modelFile, defaultVariant.materialSetId);
            } else {
                thumbnail = await thumbnailGenerator.Generate(model, modelFile);
            }
            model.thumbnail = thumbnail;
            await ModelService.SetThumbnail(model.id, thumbnail);
            props.refresh();
        } catch (e) {
            alert("We're having trouble communicating with our server");
        }
    }

    return <Modal title={<ModelName model={model} />} close={close} >
        <div className='modalBody'>
            <b>Status</b><p>{status}</p>
            <button onClick={newThumbnail}>Refresh Thumbnail</button>
            {user && user.isSuperAdmin &&
                <SimplifyButton model={model} refresh={props.refresh} />
            }
            {user && user.isSuperAdmin &&
                <>
                    <p>Pull Materials out</p>
                    {files.length === 0 && 'loading...'}
                    <ul>
                        {files.map((f) => {
                            return (<li key={f.fileId}>
                                <p>Type: {f.file.fileType} </p>
                                <p>Extension: {f.file.extension}  </p>
                                <p>Name: {f.file.fileName} </p>
                                <button onClick={() => convert(f.fileId)}>
                                    Convert
                                </button>
                                {f.file.extension === 'gltf' &&
                                    <button onClick={() => createVariant(f.fileId)}>
                                        {creatingVariant ? 'creating...' : 'Create Variant'}
                                    </button>}
                            </li>)
                        })}
                    </ul>
                </>}
        </div>
    </Modal>
}
