import { APIFile } from "./File";
import { IHasId } from "./interfaces/IHasId";

export const SceneTypes = [
  "Standard",
  "ARCS",
  "InspectCollect",
  "WorkInstruct",
  "Template",
  "ModelViewer",
] as const;
export type SceneType = typeof SceneTypes[number];

export interface APIScene extends IHasId {
  id: string;
  version: number;
  name: string;
  data: string;
  thumbnailFile: APIFile;
  sceneType: SceneType;
  created: string;
  updated: string;
  isPublished: boolean;
  companyId: string;
  folderId: string | null;
  isPublic: boolean;
}
