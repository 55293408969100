import { ReactElement, useEffect, useState } from 'react'
import { APITexture, TextureService } from '@proviz/api-services';
import { getAbsoluteUrl } from '@proviz/proviz-sdk';
import { ResourceManagerStore } from '../../../store/ResourceStore';

const blueSlash = getAbsoluteUrl('images/slash.svg');

interface Props {
    texture: APITexture | null;
    onUpload: (newTex: string) => void;
    assignTexture: (tex: APITexture | null) => void;
}

export default function TextureBox(props: Props): ReactElement {
    const { assignTexture, texture } = props;
    const openFileSelect = ResourceManagerStore((s) => s.openFileSelect);
    useEffect(() => {
        setTexImgSrc((texture && texture.file && texture.file.location) ? texture.file.location : blueSlash)
    }, [texture]);

    const [teximgSrc, setTexImgSrc] = useState((texture && texture.file && texture.file.location) ? texture.file.location : blueSlash)

    const handleTextureSelection = async (id: string | null) => {
      if (!id) {
          console.warn("Setting texture as undefined");
          setTexImgSrc(blueSlash);
          assignTexture(null);
          return;
        }
      try {
        const tex = await TextureService.get(id);
        setTexImgSrc(tex.file?.location ?? blueSlash);
        assignTexture(tex);
      } catch (error) {
        console.error(error);
      }

    };

    return (
      <div>
        <p>Texture:</p>
        <div className="textureBox">
          <img
            className={`texture ${
              texture && texture.file && texture.file.location ? "" : "default"
            }`}
            onClick={() =>
              openFileSelect &&
              openFileSelect("Texture", false, handleTextureSelection)
            }
            src={teximgSrc}
            alt={
              texture && texture.file?.displayName
                ? texture.file?.displayName
                : ""
            }
          />
        </div>
      </div>
    );
}
