import { APIFile, FileService, FileType } from '@proviz/api-services';
import { ModuleService } from '../../../moduleService';
import { ProVizScene, SceneMode } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { WidgetPropertyType } from '../../WidgetPropertyTypes';

export class ShareWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'share';
  //Data
  public shareType: 'file' | 'url' = 'url';
  public title: string = '';
  public text: string = '';
  public url: string = '';
  public fileId: string = '';
  public fileType: FileType = 'Image';

  public file: any;

  constructor(scene: ProVizScene, parent?: BaseWidget, notInScene?: boolean) {
    super(scene, parent);
    this.widgetType = ShareWidget.type;
    this.widgetName = 'Share';
    this.label = 'Share';
    this.usage = 'Flow';
    this.category = 'Others';

    this.addService('Share', 'share', 'shares the selected link or file', () => {
      (async () => {
        try {
          if (this.shareType == 'file' && navigator.canShare({ files: [this.file] })) {
            await navigator.share({ title: this.title, text: this.text, files: [this.file] });
          } else if (this.shareType == 'url' && navigator.share) {
            await navigator.share({ title: this.title, text: this.text, url: this.url });
          }
        } catch (e) {
          console.error(e);
        }
      })();
    });
  }

  public async init() {
    super.init();
    let fileInfo: APIFile;
    if (this.scene.sceneMode === SceneMode.Play && this.shareType === 'file') {
      switch (this.fileType) {
        case 'Image':
        case 'Video':
        case 'Audio':
          fileInfo = await FileService.get(
            this.fileId[this.scene.selectedLanguage] ?? this.fileId[this.scene.defaultLanguage],
            {
              abortController: this.scene.abortController,
            },
          );
          break;
        default:
          fileInfo = await FileService.get(this.fileId, {
            abortController: this.scene.abortController,
          });
      }
      let response = await fetch(fileInfo.location);
      let blob = await response.blob();
      let htmlFileType = `${this.fileType.toLowerCase()}/${fileInfo.displayName?.substring(
        fileInfo.displayName?.lastIndexOf('.') + 1,
      )}`;
      this.file = new File([blob], fileInfo.displayName ?? 'shared-file', { type: htmlFileType });
    }
    return true;
  }

  public getProperties() {
    const result = super.getProperties();
    let extra: BaseWidgetProperty[];
    if (this.shareType == 'url') {
      extra = [this.createProperty('url', 'url', 'Core', 'string', 'string', true)];
    } else {
      let fileProperty: WidgetPropertyType = 'image';
      let options = ['Video', 'Audio', 'Image'];
      switch (this.fileType) {
        case 'Video':
          fileProperty = 'video-options';
          break;
        case 'Audio':
          fileProperty = 'audio-options';
          break;
        case 'Image':
          fileProperty = 'image-options';
          break;
      }
      extra = [
        this.createProperty(
          'fileType',
          'File Type',
          'Core',
          'select',
          'string',
          true,
          undefined,
          (data) => {
            this.fileType = data;
            this.fileId = '';
          },
          () => options.map(x => { return { key: x, label: x }}),
        ),
        this.createProperty('fileId', 'File', 'Core', fileProperty, 'string', true),
      ];
    }
    return [
      ...result,
      this.createProperty(
        'shareType',
        'Sharing',
        'Core',
        'select',
        'string',
        true,
        undefined,
        undefined,
        () => ['file', 'url'].map(x => { return { key: x, label: x }}),
      ),
      this.createProperty('title', 'Title', 'Core', 'string', 'string', true),
      this.createProperty('text', 'Text', 'Core', 'multi-line-string', 'string', true),
      ...extra,
    ];
  }

  public serialize() {
    let data = super.serialize();
    data.shareType = this.shareType;
    data.url = this.url;
    data.fileId = this.fileId;
    data.title = this.title;
    data.text = this.text;
    data.fileType = this.fileType;
    return data;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.shareType = data.shareType ?? this.shareType;
    this.url = data.url ?? this.url;
    this.fileId = data.fileId ?? this.fileId;
    this.title = data.title ?? this.title;
    this.text = data.text ?? this.text;
    this.fileType = data.fileType ?? this.fileType;
  }
}
ModuleService.Register(ShareWidget.type, ShareWidget);
