import { BoxGeometry, Group, IUniform, Mesh, ShaderMaterial } from 'three';

const vertexShader: string = `
uniform float offset;

void main()
{
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position + normal * offset * 0.2f, 1.0 );
}`;

const fragShader: string = `void main(void) {
    gl_FragColor = vec4(0.192f, 0.243f, 0.392f, 1);
}`;

export class LoadingIndicator {
  private static initialized: boolean = false;
  private static animateDirection: boolean = false;
  private static uniforms: { [uniform: string]: IUniform<any> };
  private static geometry: BoxGeometry;
  private static material: ShaderMaterial;
  private static instances: Group[] = [];

  public static init() {
    if (this.initialized) {
      return;
    }
    this.geometry = new BoxGeometry(1, 1, 1);
    this.uniforms = {
      offset: { value: 0 },
    };
    this.material = new ShaderMaterial({
      uniforms: this.uniforms,
      transparent: true,
      vertexShader: vertexShader,
      fragmentShader: fragShader,
    });

    this.initialized = true;
  }

  public static createInstance() {
    this.init();
    const result = new Group();
    const mesh = new Mesh(this.geometry, this.material);
    mesh.rotateX(45);
    mesh.rotateZ(45);
    result.scale.set(0.5, 0.5, 0.5);
    result.add(mesh);
    this.instances.push(result);
    return result;
  }

  public static Update(delta: number) {
    if (!this.initialized) {
      return;
    }
    this.instances.forEach((mesh: Group) => {
      mesh.rotateY(delta * 5);
    });
    this.uniforms.offset.value += delta * 5 * (this.animateDirection ? 1 : -1);
    if (this.uniforms.offset.value > 1 || this.uniforms.offset.value < 0) {
      this.animateDirection = !this.animateDirection;
    }
  }

  public static drop(instance: Group) {
    if (instance.parent) {
      instance.parent.remove(instance);
    }
    const ind = this.instances.findIndex((x) => x === instance);
    if (ind > -1) {
      this.instances.splice(ind, 1);
    }
  }

  public static shutdown() {
    if (this.initialized) {
      for (let i = 0; i < this.instances.length; i++) {
        const parent = this.instances[i].parent;
        if (parent) {
          parent.remove(this.instances[i]);
        }
        delete this.instances[i];
      }
      this.instances = [];
      this.geometry.dispose();
      this.material.dispose();
      // @ts-ignore
      delete this.geometry;
      // @ts-ignore
      delete this.material;
      // @ts-ignore
      delete this.uniforms;
      this.initialized = false;
    }
  }
}
