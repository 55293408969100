import { ReactElement, useEffect, useState } from "react";
import { Vector3 } from "three";
import SetWidgetProperty from "../../../commands/SetWidgetProperty";
import { isModelontrols } from "../../../graphics/controls/ModelViewControls";
import ModelViewManager from "../../../graphics/ModelViewManager";
import SceneManager from "../../../graphics/SceneManager";
import RotationInput from "../../common/RotationInput";
import ThreeValInput from "../../common/threevalinput/ThreeValInput";
import { SettableWidgetProperty } from "./SettableWidgetProperty";
import { AnimationStore } from "../../../store";

interface Props {
  property: SettableWidgetProperty;
  header: ReactElement;
  forceUpdate: () => void;
  manager: ModelViewManager | SceneManager;
}

export default function TransformProperty(props: Props): ReactElement {
  const { property, header, forceUpdate, manager } = props;
  const [controlMode, setControlMode] = useState(
    manager.controls ? manager.controls.mode : ""
  );
  const widget = AnimationStore((s) => s.widget);
  const animationMenuOpen = AnimationStore((s) => s.menuOpen);
  const animationTime = AnimationStore((s) => s.time);
  const track = AnimationStore((s) => s.track);

  useEffect(() => {
    const controls = manager.controls;
    if (!controls) {
      return;
    }
    function updateMode() {
      setControlMode(manager.controls ? manager.controls.mode : "");
    }
    controls.attach("mode-update", updateMode);
    return () => {
      controls.detach("mode-update", updateMode);
    };
  }, [manager]);

  const checkMarkFromPropertyLabel = (label: string) => {
    const controls = manager.controls;
    if (!controls) {
      return undefined;
    }
    switch (label) {
      case "Position": {
        if (!isModelontrols(controls)) {
          return {
            id: "positionModeCheckbox",
            value: controlMode === "translate",
            onChange: () => controls.changeMode("translate"),
          };
        } else {
          return undefined;
        }
      }
      case "Rotation": {
        return {
          id: "positionModeCheckbox",
          value: controlMode === "rotate",
          onChange: () => controls.changeMode("rotate"),
        };
      }
      case "Scale": {
        return {
          id: "positionModeCheckbox",
          value: controlMode === "scale",
          onChange: () => controls.changeMode("scale"),
        };
      }
      default: {
        return undefined;
      }
    }
  };

  const val: Vector3 =
    widget && animationMenuOpen
      ? widget.animationTrack[track].getAnimationValue(
          animationTime,
          property
        ) ?? {
          x: 0,
          y: 0,
          z: 0,
        }
      : property.get();

  if (!val) {
    console.error(
      `Property name ${property.label} is invalid. property doesn't exist: ${val}`
    );
    return (
      <div>
        {props.header}
        <p>This data appears to be corrupted</p>
      </div>
    );
  }

  const setProperty = (newVal: { x: number; y: number; z: number }) => {
    const { x, y, z } = val;
    if (!animationMenuOpen) {
      manager.execute(
        new SetWidgetProperty(property, { x, y, z }, newVal, manager)
      );
    } else {
      widget?.animationTrack[track].addAnimationPoint(
        animationTime,
        property,
        newVal
      );
    }
    forceUpdate();
  };
  if (property.label === "Rotation" || property.label === "Skybox Rotation") {
    return (
      <div>
        {header}
        <RotationInput
          key={`widget-prop-${property.label.substring(0, 2)}`}
          rotation={{ x: val.x, y: val.y, z: val.z }}
          setRotation={setProperty}
          checkmark={checkMarkFromPropertyLabel(property.label)}
        />
      </div>
    );
  }
  return (
    <div>
      {header}
      <ThreeValInput
        key={`wdg-prop-${property.label.substring(0, 2)}`}
        input1={{
          id: `${property.name.substring(0, 1)}-x`,
          value: val.x,
          label: "x",
        }}
        input2={{
          id: `${property.name.substring(0, 1)}-y`,
          value: val.y,
          label: "y",
        }}
        input3={{
          id: `${property.name.substring(0, 1)}-z`,
          value: val.z,
          label: "z",
        }}
        onChange={setProperty}
        checkmark={checkMarkFromPropertyLabel(property.label)}
        draggable={true}
      />
    </div>
  );
}
