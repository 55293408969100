import { BaseWidgetProperty, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { GUIBaseWidget } from '../guiBaseWidget';

type TextAlign = 'left' | 'center' | 'right';
const TextAlignOptions: TextAlign[] = ['left', 'center', 'right'];

let GUIColumnWidgetSetup: boolean = false;

export class GUIColumnWidget extends GUIBaseWidget implements IBaseWidgetType {
  public static type: string = 'gui-column';

  // Data
  private textAlign: TextAlign = 'left';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = GUIColumnWidget.type;
    this.widgetName = 'GUI Column';
    this.label = 'GUI Column';
    this.category = 'UI';
    this.events = [];
  }

  private setStyle() {
    const style = `
        flex: 1;
        padding: 5px;
        margin: 5px;
        pointer-events: visible;
        text-align: ${this.textAlign};
    `;
    this.guiElement?.setAttribute('style', style);
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'textAlign',
        'Text Align',
        'Core',
        'select',
        'string',
        true,
        undefined,
        (data) => {
          this.textAlign = data;
          this.setStyle();
        },
        () => {
          return TextAlignOptions.map(x => { return { key: x, label: x }});
        },
      ),
    ];
  }

  public serialize() {
    const result = super.serialize();
    result.textAlign = this.textAlign;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.textAlign = data.textAlign ?? this.textAlign;
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }

    if (this.parent) {
      const gui = this.parent as GUIBaseWidget;
      const guiParent = gui.guiElement;

      this.guiElement = document.createElement('div');
      this.guiElement.setAttribute('class', 'gui-column');

      if (this.scene.sceneMode === SceneMode.Editor) {
        this.guiElement.ondragend = (ev: DragEvent) => {
          this.guiElement?.setAttribute('class', 'gui-column');
        };
        this.guiElement.ondragleave = (ev: DragEvent) => {
          this.guiElement?.setAttribute('class', 'gui-column');
        };
        this.guiElement.ondrop = (ev: DragEvent) => {
          if (!ev.dataTransfer) {
            console.warn('Dropped elements must have a node id');
            return;
          }
          this.scene.dispatchEvent('scene-move-node', {
            data: ev.dataTransfer.getData('node/id'),
            dataType: 'widgetId',
            sourceWidgetId: this.id,
            event: 'scene-move-node'
          });

          ev.preventDefault();
          ev.stopPropagation();
        };
        this.guiElement.ondragover = (ev: DragEvent) => {
          this.guiElement?.setAttribute('class', 'gui-column dropping');
          ev.preventDefault();
          ev.stopPropagation();
        };
        this.guiElement.ondragenter = (ev: DragEvent) => {
          ev.preventDefault();
          ev.stopPropagation();
        };
      }
      this.setStyle();
      guiParent?.appendChild(this.guiElement);
    }

    if (!GUIColumnWidgetSetup) {
      GUIColumnWidgetSetup = true;
      const style = document.createElement('style');
      style.setAttribute('type', 'text/css');
      style.innerText = `
        .gui-element.dragging .gui-column {
          background: #eee;
        }
        .gui-element.dragging .gui-column.dropping {
          background: #fee;
        }
      `;
      document.body.appendChild(style);
    }

    return true;
  }
}

ModuleService.Register(GUIColumnWidget.type, GUIColumnWidget);
