import { CompanyService, UserService, getDefaultCompanyId, initAbortController, isAbortError } from '@proviz/api-services';
import { ReactElement, useEffect, useState } from 'react';
import ErrorBoundary from '../../errorbounds/ErrorBoundary';
import { userStore } from '../../store';
import PageHeader from '../home/pageHeader';
import { CompanyOrganizationSettings } from './components/CompanyOrganizationSettings';
import OrganizationCreate from './OrganizationCreate';

interface KeyValuePair { // This should be refactored to be clearer
  key: string; // company id
  value: string; // company name 
}


export const OrganizationsView = (): ReactElement => {
  const user = userStore( s => s.user)
  const setUser = userStore( s => s.setUser)
  const [companies, setCompanies] = useState<KeyValuePair[]>([]);
  const [companyDeleted, setCompanyDeleted] = useState(false);
  const [defaultCompanyId, setDefaultCompanyId] = useState(user ? getDefaultCompanyId(user) : undefined)
  const [updateCompanies, setUpdateCompanies] = useState(null);


  useEffect(() => {
    if (user) {
      setDefaultCompanyId(getDefaultCompanyId(user));
    }
  }, [user]);


  const deleteCompany = async (companyId: string) => {
    try {
      await CompanyService.delete(companyId);
      setCompanyDeleted(true);
      setTimeout(() => setCompanyDeleted(false), 3000);
    } catch (error) {
      console.error(error);
    }
  }

  const editCompanyName = async (companyId: string, companyName: string) => {
    try {
      await CompanyService.update({id: companyId, name: companyName});
    } catch (error) {
      console.error(error);
    }
  }

  const setDefault = async (companyId: string) => {
    if (!user) {
      console.error("No user data");
      return;
    }
    try {
      const updatedUser = await UserService.setDefaultCompany(user.id, companyId);
      setDefaultCompanyId(companyId)
      setUser(updatedUser);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const abortController = initAbortController();
    CompanyService.getAllKeyValue({ abortController })
      .then((x => {
        setCompanies(x.sort((a, b) => {
          if (a.value.toLowerCase() > b.value.toLowerCase()) {
            return 1;
          }
          if (a.value.toLowerCase() < b.value.toLowerCase()) {
            return -1;
          }
          return 0;
        }));
      }))
      .catch(e => {
        if (isAbortError(e)) return; // if the query was aborted, do nothing
        console.error(e);
      });
      return () => abortController.abort();
  }, [updateCompanies, companyDeleted]);



  return <div className="homeRightView">

  <PageHeader />
  <div className='homeContent'>
      <div className='homeContentContainer'>
          <div className='paddedContainer'>
    {user?.isSuperAdmin && 
      <OrganizationCreate updateCompanies={() => setUpdateCompanies(null)} />}
    <br />
    <table >
      <thead>
        <tr>
          <th>Organization Name</th>
        </tr>
      </thead>
      <tbody>
        {companies.map((comp) => <tr key={comp.key}>
          <ErrorBoundary>
            <CompanyOrganizationSettings
              companyId={comp.key}
              companyName={comp.value}
              editCompanyName={editCompanyName}
              defaultCompanyId={defaultCompanyId}
              setDefault={setDefault}
              deleteCompany={deleteCompany}
            />
          </ErrorBoundary>
        </tr>)}
      </tbody>
    </table>
    <br />
  </div>
  </div>
  </div>
  </div>;
};
