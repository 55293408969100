import { MultiLangOption, ProVizEventData, ProVizScene, SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { BaseWidget } from '../../baseWidget';
import { IBaseWidgetType } from '../../IBaseWidgetType';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { FileService } from '@proviz/api-services';

export class ImageUIWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'image-ui';

  private static styleEl?: HTMLStyleElement;

  // Data
  public image: MultiLangOption = {};
  public fadeInTime: number = 0;
  public visibleTime: number = 0;
  public fadeOutTime: number = 0;
  public screenX: number = 0;
  public screenY: number = 0;
  public maxWidth: number = 0;
  public maxHeight: number = 0;

  private imageContainer: HTMLDivElement;
  private img: HTMLImageElement | undefined = undefined;
  private imgSrc: string | undefined = undefined;
  private currFileId: string | undefined = undefined;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.widgetType = ImageUIWidget.type;
    this.widgetName = 'Image UI';
    this.label = 'Image UI';
    this.category = 'UI';
    this.events = [{
      label: 'Clicked',
      name: 'clicked',
      desc: 'When the image has been clicked'
    }, {
      label: 'Loaded',
      name: 'loaded',
      desc: 'When the image has been loaded'
    }];

    this.imageContainer = document.createElement('div');

    this.services = [];
    this.addService('Show', 'show', 'Show the image', (event: ProVizEventData) => {
      this.visible = true;
      this.setSettings();
    });
    this.addService('Hide', 'hide', 'Hide the image', (event: ProVizEventData) => {
      this.visible = false;
      this.setSettings();
    });
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty('image', 'Image', 'Core', 'image-options', 'string', true, undefined, (data) => {
        this.image = data;
        this.updateImage();
      }),
      this.createProperty('maxWidth', 'Max Width', 'Core', 'number', 'number', true),
      this.createProperty('maxHeight', 'Max Height', 'Core', 'number', 'number', true),
      this.createProperty('screenY', 'Top', 'Core', 'number', 'number', true),
      this.createProperty('screenX', 'Left', 'Core', 'number', 'number', true),
    ];
  }

  setSettings() {
    if (this.visible && this.img === undefined) {
      this.img = document.createElement('img');
      this.imageContainer.appendChild(this.img);

      this.img.addEventListener('click', () => {
        this.triggerProVizEvent('clicked', 'none');
      });
    }
    if (this.img) {
      const styleImg = `
        top: ${this.screenY}px;
        left: ${this.screenX}px;
        max-width: ${this.maxWidth}px;
        max-height: ${this.maxHeight}px;
      `;
      this.img.setAttribute('style', styleImg);
    }
    const style = `
      top: ${this.screenY}px;
      left: ${this.screenX}px;
    `;
    this.imageContainer.setAttribute('style', style);
    this.imageContainer.setAttribute('class', `image-ui ${(this.visible && this.imgSrc) ? 'widget-visible': 'widget-hidden'}`);
  }

  async updateImage() {
    if (!this.img) {
      return;
    }
    const fileId =
      this.image[this.scene.selectedLanguage] ??
      this.image[this.scene.defaultLanguage] ??
      '';
    if (fileId !== this.currFileId) {
      this.currFileId = fileId;
      const file = await FileService.get(fileId);
      const src = FileService.getLocation(file);
      this.imgSrc = src;
      this.img.setAttribute('src', src);
      this.img.onload = () => {
        this.setSettings();
      }
    }
  }

  public async init(): Promise<boolean> {
    if (this.scene.sceneMode === SceneMode.Editor) {
      return true;
    }

    // Add to the DOM
    document.body.append(this.imageContainer);

    if (!ImageUIWidget.styleEl) {
      ImageUIWidget.styleEl = document.createElement('style');

      ImageUIWidget.styleEl.innerText =`
        .image-ui {
          position: absolute;
          opacity: 0;
        }
        .image-ui.widget-hidden {
          pointer-events: none;
        }
        .image-ui.widget-visible { 
          animation: fadeIn 1s;
          -webkit-animation: fadeIn 1s;
          -moz-animation: fadeIn 1s;
          -o-animation: fadeIn 1s;
          -ms-animation: fadeIn 1s;
          animation-fill-mode: forwards;
        }
        .image-ui.widget-hidden {
          animation: fadeOut 1s;
          -webkit-animation: fadeOut 1s;
          -moz-animation: fadeOut 1s;
          -o-animation: fadeOut 1s;
          -ms-animation: fadeOut 1s;
          animation-fill-mode: forwards;
        }
        @keyframes fadeIn {
          0% { opacity: 0; }
          100% { opacity: 1; }
        }
        @keyframes fadeOut {
          0% { opacity: 1; }
          100% { opacity: 0; }
        }
      `;
      document.body.append(ImageUIWidget.styleEl);
    }

    this.setSettings();
    this.updateImage();

    this.scene.addEventListener('language-change', async () => {
      this.updateImage();
    });

    return true;
  }

  public serialize() {
    const result = super.serialize();
    result.image = this.image;
    result.maxWidth = this.maxWidth;
    result.maxHeight = this.maxHeight;
    result.screenX = this.screenX;
    result.screenY = this.screenY;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.image = data.image ?? this.image;
    this.maxWidth = data.maxWidth;
    this.maxHeight = data.maxHeight;
    this.screenX = data.screenX;
    this.screenY = data.screenY;
  }
}

ModuleService.Register(ImageUIWidget.type, ImageUIWidget);
