import { Scene } from "three";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";

function save(blob: any, filename: string) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename || "data.json";
  link.dispatchEvent(new MouseEvent("click"));
  // URL.revokeObjectURL( url ); breaks Firefox...
}

function saveArrayBuffer(buffer: any, filename: string) {
  save(new Blob([buffer], { type: "application/octet-stream" }), filename);
}

function saveString(text: string, filename: string) {
  save(new Blob([text], { type: "text/plain" }), filename);
}

export default class Exporter {
  gltfExporter: GLTFExporter | null = null;

  exportGLTF(scene: Scene, binary: boolean = false) {
    if (!this.gltfExporter) {
      this.gltfExporter = new GLTFExporter();
    }
    const options = {
      binary,
      trs: false,
      onlyVisible: true,
      truncateDrawRange: true,
      //embedImages: true, //removed on r141
      maxTextureSize: Infinity,
      animations: [],
      includeCustomExtensions: false,
    };
    this.gltfExporter.parse(
      scene,
      (result) => {
        if (result instanceof ArrayBuffer) {
          saveArrayBuffer(result, "scene.glb");
        } else {
          const output = JSON.stringify(result, null, 2);
          saveString(output, "scene.gltf");
        }
      },
      (error) => {
        console.log(error.message);
      },
      options
    );
  }
}
