import { Raycaster, Object3D, Vector2, Camera } from "three";

export function getMousePosition(canvas: HTMLElement, x: number, y: number) {
  const rect = canvas.getBoundingClientRect();
  return [(x - rect.left) / rect.width, (y - rect.top) / rect.height];
}

export default class Raycast {
  raycaster: Raycaster = new Raycaster();
  normalizedPos: Vector2 = new Vector2();

  intersectObjects(point: Vector2, objects: Object3D[], camera: Camera) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    // we flip y
    this.normalizedPos.set(point.x * 2 - 1, point.y * -2 + 1);
    this.raycaster.setFromCamera(this.normalizedPos, camera);

    // recursive is true by default for >= r133
    return this.raycaster.intersectObjects(objects, true);
  }
}
