/** Object that makes it easier to pass around or hold onto promises. */
export class Deferred<T> {
  promise: Promise<T>;

  // @ts-ignore
  reject: Function;
  // @ts-ignore
  resolve: Function;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
