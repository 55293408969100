import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class AppParamWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'app-param';

  // Data
  public param: string = '';
  public defaultValue: string = '';

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);
    this.label = 'App Parameter';

    this.usage = 'Flow';
    this.category = 'Others';

    this.widgetType = AppParamWidget.type;
    this.widgetName = 'App Parameter';
    this.selectable = true;
    this.events = [
      {
        label: 'Value',
        name: 'value',
      },
    ];
    this.services = [
      {
        label: 'Load',
        name: 'load',
      },
    ];

    this.addEventListener('service-load', (event) => {
      const params = AppParamWidget.getWindowParams();
      const val = params[this.param.toLowerCase()];
      if (val) {
        this.triggerProVizEvent('value', 'string', decodeURIComponent(val));
      } else {
        this.triggerProVizEvent('value', 'string', '');
      }
    });
  }

  static getWindowParams(): { [key: string]: string } {
    const vars = window.location.search.split('?');
    if (vars.length !== 2) {
      return {};
    }
    const params = vars[1].split('&');
    const result = {};
    for (let i = 0; i < params.length; i += 1) {
      const keyValue = params[i].split('=');
      const [, value] = keyValue;
      result[keyValue[0].toLowerCase()] = value;
    }
    return result;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'param',
        'App Param',
        'Core',
        'string',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The app parameter to load in from the url',
      ),
      this.createProperty(
        'defaultValue',
        'Default Value',
        'Core',
        'string',
        'string',
        true,
        undefined,
        undefined,
        undefined,
        'The value to use on load',
      ),
    ];
  }

  public async init() {
    const continueInitializing = await super.init();
    if (!continueInitializing) {
      return continueInitializing;
    }
    if (this.defaultValue) {
      this.triggerProVizEvent('value', 'string', this.defaultValue);
    }
    return true;
  }

  public serialize(): any {
    const result = super.serialize();
    result.param = this.param;
    result.defaultValue = this.defaultValue;
    return result;
  }

  public deserialize(data: any) {
    super.deserialize(data);
    this.param = data.param;
    this.defaultValue = data.defaultValue;
  }
}

ModuleService.Register(AppParamWidget.type, AppParamWidget);
