import Command from "./Command";
import { IRotation } from "@proviz/api-services";
import { BasePositionableWidget } from "@proviz/proviz-sdk";

export default class SetNodeRotation extends Command {
  oldVal: IRotation;
  newVal: IRotation;
  node: BasePositionableWidget;
  name = "Rotate Model";

  constructor(
    node: BasePositionableWidget,
    rotation: IRotation,
    oldRotation: IRotation
  ) {
    super();
    this.newVal = { x: rotation.x, y: rotation.y, z: rotation.z };
    this.oldVal = { x: oldRotation.x, y: oldRotation.y, z: oldRotation.z };
    this.node = node;
  }

  execute() {
    this.node.setRotation(this.newVal);
  }

  undo() {
    this.node.setRotation(this.oldVal);
  }
}
