import { ReactElement, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import {
    APIMaterial, APITexture, MaterialParameterService, MaterialParameterType,
    getParam, APIMaterialSet, TextureService
} from "@proviz/api-services"
import TextureBox from '../../common/texturebox/TextureBox';
import ColorBox from '../../common/texturebox/ColorBox';
import MaterialInput from './MaterialInput';
import WrapInput from './WrapInput';
import { SideInput } from './SideInput';
import { BackSide, DoubleSide, FrontSide } from 'three';
import { AlphaModeInput } from './AlphaModeInput';
import { CollapsableGroup } from './CollapsableGroup';
import Button from '../../common/button/Button';
import { LoadingStore, ManagerStore } from '../../../store';

interface Props {
    material: APIMaterial;
    materialSet: APIMaterialSet;
    setMaterialSet: (ms: APIMaterialSet) => void;
}

/**
 * A material may not have material Parameters for all values.
 * If a material does not have a material parameter defined then we just
 * use the value that it has.
 * If that value is changed only then do we create a material parameter api side.
 */
export default function MaterialInfo(props: Props): ReactElement {
    const { materialSet, setMaterialSet, material } = props;
    const  manager  = ManagerStore(s=>s.ModelViewManager);
    const setLoadingText = LoadingStore( s => s.setLoadingText)
    const [baseTexture, setBaseTexture] = useState<APITexture | null>(null);
    const [color, setColor] = useState<string | null>(null);
    const [roughness, setRoughness] = useState<number | null>(null);
    const [metalness, setMetalness] = useState<number | null>(null);
    const [pbrMap, setPbrMap] = useState<APITexture | null>(null);
    const [emissiveIntensity, setEmissiveIntensity] = useState<number | null>(null);
    const [emissiveFactor, setEmissiveFactor] = useState<string | null>(null);
    const [emissiveMap, setEmissiveMap] = useState<APITexture | null>(null);
    const [normalMap, setNormalMap] = useState<APITexture | null>(null);
    const [normalScale, setNormalScale] = useState<number | null>(null);
    const [occlusiveMap, setOcclusiveMap] = useState<APITexture | null>(null);
    const [occlusiveStrength, setOcclusiveStrength] = useState<number | null>(null);
    const [side, setSide] = useState<string | null>(null);
    const [alphaMode, setAlphaMode] = useState<string | null>(null);
    const [wrapS, setWrapS] = useState<string | null>(null);
    const [wrapT, setWrapT] = useState<string | null>(null);

    useEffect(() => {
        async function clearState() {
            setBaseTexture(null);
            setColor(null);
            setRoughness(null);
            setMetalness(null);
            setPbrMap(null);
            setEmissiveIntensity(null);
            setEmissiveFactor(null);
            setEmissiveMap(null);
            setNormalMap(null);
            setNormalScale(1);
            setSide(null);
            setAlphaMode(null);
            setWrapS(null);
            setWrapT(null);
        }
       
        async function updateMaterialParameters() {

          
            const mat = manager.getNamedMaterial(material.name ?? "");
            const albedoParam = getParam(material.materialParameters, 'AlbedoTexture');
            if (albedoParam) {
                setBaseTexture(albedoParam.texture);
            }


            const roughParam = getParam(material.materialParameters, 'Roughness');
            if (roughParam) {
                // roughParam = createMp('Roughness', 1.0, "")
                setRoughness(roughParam.floatValue);
            } else if (mat && 'roughness' in mat) {
                setRoughness((mat as any).roughness);
            }

            const metalParam = getParam(material.materialParameters, 'Metalness');
            if (metalParam) {
                setMetalness(metalParam.floatValue);
                setPbrMap(metalParam.texture);
            } else if (mat && 'metalness' in mat) {
                setMetalness((mat as any).metalness);
            }

            const emissiveParam = getParam(material.materialParameters, 'Emissive');
            if (emissiveParam) {
                setEmissiveIntensity(emissiveParam.floatValue)
                setEmissiveMap(emissiveParam.texture);
            } else if (mat && 'emissiveIntensity' in mat) {
                setEmissiveIntensity((mat as any).emissiveIntensity)
            }

            let emissiveFactorParam = getParam(material.materialParameters, 'EmissiveFactor');
            if (emissiveFactorParam) {
                setEmissiveFactor(emissiveFactorParam.stringValue ?? "");
            } else if (mat && 'emissive' in mat) {
                setEmissiveFactor((mat as any).emissive.getHex());
            }

            const normalParam = getParam(material.materialParameters, 'Normal');
            if (normalParam) {
                setNormalMap(normalParam.texture ?? null);
                setNormalScale(normalParam.floatValue ?? 1)
            } else if (mat && 'normalScale' in mat) {
                setNormalScale((mat as any).normalScale);
            }

            const occlusionParam = getParam(material.materialParameters, 'Occlusion');
            if (occlusionParam) {
                setOcclusiveMap(occlusionParam.texture)
                setOcclusiveStrength(occlusionParam.floatValue ?? 1);
            } else if (mat && 'aoMapIntensity' in mat) {
                setOcclusiveStrength((mat as any).aoMapIntensity);
            }

            const colorParam = getParam(material.materialParameters, 'Color');
            if (colorParam) {
                setColor(colorParam.stringValue);
            } else if (mat && 'color' in mat) {
                setColor((mat as any).color.getHex().toString());
            } else {
                setColor('');
            }

            const sideParam = getParam(material.materialParameters, 'Side');
            if (sideParam) {
                setSide(sideParam.stringValue);
            } else if (mat) {
                let side = mat.side;
                let sideString;
                if (side === FrontSide) {
                    sideString = 'Front';
                } else if (side === DoubleSide) {
                    sideString = 'Double';
                } else if (side === BackSide) {
                    sideString = 'Back';
                }
                if (sideString) {
                    setSide(sideString);
                }
            }

            const alphaParam = getParam(material.materialParameters, 'AlphaBlendMode');
            if (alphaParam) {
                setAlphaMode(alphaParam.stringValue)
            } else if (mat) {
                let matAlphaMode = 'OPAQUE';
                if (mat.transparent && !mat.depthWrite) {
                    matAlphaMode = 'BLEND';
                } else if (mat.alphaTest !== 0) { // alphaTest is only overwritten on MASK materials
                    matAlphaMode = 'MASK';
                }
                setAlphaMode(matAlphaMode)
            }

            const wrapSParam = getParam(material.materialParameters, 'WrapS');
            if (wrapSParam) {
                setWrapS(wrapSParam.stringValue);
            } else {
                /// THIS is wrong not sure how we should handle this or the wrapt block
                // setWrapS('RepeatWrapping');
            }

            const wrapTParam = getParam(material.materialParameters, 'WrapT');
            if (wrapTParam) {
                setWrapT(wrapTParam.stringValue);
            } else {
                // setWrapT('RepeatWrapping');
            }
        }

        async function handleMaterialChange() {
            await clearState();
            await updateMaterialParameters();
        }

        handleMaterialChange();

    }, [props.material, manager, material.materialParameters, material.name]);





    async function _assignTextureToMatParam(materialId: string, materialParameterId: string, texture: APITexture | null) {
        let mp;
        if (texture === null) {
            mp = await MaterialParameterService.assignTextureToMaterialParameter(materialParameterId, null);
            mp.textureId = null;
        } else {
            mp = await MaterialParameterService.assignTextureToMaterialParameter(materialParameterId, texture.id);
            mp.textureId = texture.id;
        }
        mp.texture = texture;
        let mat = materialSet.materials.find((m) => m.id === materialId);
        if (!mat) {
            console.error("Material", materialId, "does not exist");
            return;
        }
        const filteredMps = (mat.materialParameters ?? []).filter(_mp => _mp.id !== materialParameterId)
        mat.materialParameters = [...filteredMps, mp];
        const tempMaterials = [...materialSet.materials];
        const idx = tempMaterials.findIndex((m) => m.id === materialId);
        tempMaterials[idx] = mat;
        setMaterialSet({ ...materialSet, materials: tempMaterials });
        await manager.refreshMaterials();
    }

    async function _assignTextureToMaterial(materialId: string, tex: APITexture | null, mpType: MaterialParameterType) {
        if (tex === null) {
            // if there's no texture to assign then we don't need to create a material parameter, I think.
            return;
        }
        const mp = await MaterialParameterService.assignTextureToMaterial(materialId, tex.id, mpType);
        let updatedMps = [...(material.materialParameters ?? []), mp];
        const updatedMat = Object.assign({}, material);
        updatedMat.materialParameters = updatedMps;
        const tempMaterials = [...materialSet.materials];
        const idx = tempMaterials.findIndex((m) => m.id === materialId);
        tempMaterials[idx] = updatedMat;
        setMaterialSet({ ...materialSet, materials: tempMaterials });
        await manager.refreshMaterials();
    }

    async function assignTexture(tex: APITexture | null, mpType: MaterialParameterType) {
        if (!tex) {
            console.warn("setting texture to undefined");
        }
        const param = getParam(material.materialParameters, mpType);
        if (!param) {
            await _assignTextureToMaterial(material.id, tex, mpType);
        } else {
            await _assignTextureToMatParam(material.id, param.id, tex);
        }
    }

    async function createMp(mpType: MaterialParameterType, floatValue: number, stringValue: string) {
        const newMp = await MaterialParameterService.createMaterialParam(material.id, mpType, floatValue, stringValue);
        material.materialParameters = [...(material.materialParameters ?? []), newMp];
        return newMp;
    }

    async function numValueChange(mpType: MaterialParameterType, newValue: number) {
        if (mpType === 'Roughness') {
            setRoughness(newValue);
            const roughParam = getParam(material.materialParameters, 'Roughness');
            if (roughParam) {
                roughParam.floatValue = newValue;
                await MaterialParameterService.updateMaterialParam(roughParam.id, newValue, 'float');
            } else {
                await createMp('Roughness', newValue, "");
            }
            manager.refreshMaterials();
        }
        if (mpType === 'Metalness') {
            setMetalness(newValue)
            const metalParam = getParam(material.materialParameters, 'Metalness');
            if (metalParam) {
                metalParam.floatValue = newValue;
                await MaterialParameterService.updateMaterialParam(metalParam.id, newValue, 'float');
            } else {
                await createMp('Metalness', newValue, "");
            }
            manager.refreshMaterials();
        }
        if (mpType === 'Emissive') {
            setEmissiveIntensity(newValue);
            const emissiveParam = getParam(material.materialParameters, 'Emissive');
            if (emissiveParam) {
                emissiveParam.floatValue = newValue;
                await MaterialParameterService.updateMaterialParam(emissiveParam.id, newValue, 'float');
            } else {
                await createMp('Emissive', newValue, "");
            }
            manager.refreshMaterials();
        }
        if (mpType === 'Normal') {
            setNormalScale(newValue);
            const normalParam = getParam(material.materialParameters, 'Normal');
            if (normalParam) {
                normalParam.floatValue = newValue;
                await MaterialParameterService.updateMaterialParam(normalParam.id, newValue, 'float');
            } else {
                await createMp('Normal', newValue, '');
            }
            manager.refreshMaterials();
        }
        if (mpType === 'Occlusion') {
            setOcclusiveStrength(newValue);
            const occlusionParam = getParam(material.materialParameters, 'Occlusion');
            if (occlusionParam) {
                occlusionParam.floatValue = newValue;
                await MaterialParameterService.updateMaterialParam(occlusionParam.id, newValue, 'float');
            } else {
                await createMp('Occlusion', newValue, "");
            }
            manager.refreshMaterials();
        }
    }

    const debouncedRefresh = debounce(() => manager.refreshMaterials(), 200, { trailing: true });
    async function colorChange(newColor: string) {
        setColor(newColor);
        const colorParam = getParam(material.materialParameters, 'Color');
        if (colorParam) {
            colorParam.stringValue = newColor;
            const newMp = await MaterialParameterService.updateMaterialParam(colorParam.id, newColor, 'string');
            const filteredMps = (material.materialParameters ?? []).filter((m) => m !== colorParam);
            material.materialParameters = [...filteredMps, newMp]
        } else {
            await createMp('Color', 0, newColor);
        }
        debouncedRefresh();
    }

    async function emissiveColorChange(newColor: string) {
        setEmissiveFactor(newColor);
        const emissiveFactorParam = getParam(material.materialParameters, 'EmissiveFactor');
        if (emissiveFactorParam) {
            emissiveFactorParam.stringValue = newColor;
            const newMp = await MaterialParameterService.updateMaterialParam(emissiveFactorParam.id, newColor, 'string');
            const filteredMps = (material.materialParameters ?? []).filter((m) => m !== emissiveFactorParam);
            material.materialParameters = [...filteredMps, newMp];
        } else {
            await createMp('EmissiveFactor', 0, newColor);
        }
        debouncedRefresh();
    }

    async function sideChange(value: string) {
        if (!value) {
            return;
        }
        setSide(value);
        const param = getParam(material.materialParameters, 'Side');
        if (param) {
            param.stringValue = value;
            const newMp = await MaterialParameterService.updateMaterialParam(param.id, value, 'string');
            const filteredMps = (material.materialParameters ?? []).filter((m) => m !== param);
            material.materialParameters = [...filteredMps, newMp];
        } else {
            await createMp('Side', 0, value);
        }
        manager.refreshMaterials();
    }

    async function alphaBlendModeChange(value: string) {
        if (!value) {
            return;
        }
        setAlphaMode(value);
        const param = getParam(material.materialParameters, 'AlphaBlendMode');
        if (param) {
            param.stringValue = value;
            const newMp = await MaterialParameterService.updateMaterialParam(param.id, value, 'string');
            const filteredMps = (material.materialParameters ?? []).filter((m) => m !== param);
            material.materialParameters = [...filteredMps, newMp];
        } else {
            await createMp('AlphaBlendMode', 0, value);
        }
        manager.refreshMaterials();
    }

    async function wrappingChange(mpType: 'WrapS' | 'WrapT', value: string) {
        if (!value) {
            return;
        }
        if (mpType === 'WrapS') {
            setWrapS(value);
        } else if (mpType === 'WrapT') {
            setWrapT(value);
        }
        const param = getParam(material.materialParameters, mpType);
        if (param) {
            param.stringValue = value;
            const newMp = await MaterialParameterService.updateMaterialParam(param.id, value, 'string');
            const filteredMps = (material.materialParameters ?? []).filter((m) => m !== param);
            material.materialParameters = [...filteredMps, newMp];
        } else {
            await createMp(mpType, 0, value);
        }
        manager.refreshMaterials();
    }

    async function handleUpload(matId: string, mpType: MaterialParameterType, file: string) {
        setLoadingText('Uploading material...');
        try {
            const tex = await TextureService.uploadTextureFile(file);

            const mat = materialSet.materials.find((m) => m.id === matId);
            if (!mat) {
                throw new Error("Material data corrupted");
            }
            const mParam = getParam(material.materialParameters, mpType);
            if (mParam) {
                _assignTextureToMatParam(matId, mParam.id, tex);
            } else {
                _assignTextureToMaterial(matId, tex, mpType);
            }
            await manager.setMaterialSet(materialSet);
        } catch (err) {
            console.error(`File upload failed ${err}`)
            alert(`File upload failed ${err}`)
        }
        setLoadingText('');
    }
    
    return <>
        <CollapsableGroup groupName="Base Color and Texture" startExpanded={true}>
            <div className="textureColorBoxes">
                <TextureBox
                    texture={baseTexture}
                    assignTexture={(tex) => assignTexture(tex, 'AlbedoTexture')}
                    onUpload={(newTex: string) =>
                        handleUpload(material.id, 'AlbedoTexture', newTex)} />
                <ColorBox color={color} setColor={colorChange} materialId={material.id}  />
            </div>
            <div  className="resetButton">
            <Button
                type='primary'
                onClick={() => colorChange('#ffffff')}
                >
                Reset Color
            </Button>
            </div>
        </CollapsableGroup>
        <CollapsableGroup groupName="Metallic Roughness" startExpanded={true}>
            {roughness !== null &&
               <div>
                <p>Roughness Factor</p>
                <div className="slideContainer" >
                    <input value={roughness} onChange={(e: any) => { numValueChange('Roughness', parseFloat(e.target.value))}} />
                    <input className='slider' type="range" value={roughness} onChange={(e: any) => { numValueChange('Roughness', parseFloat(e.target.value))}}
                        min={0} max={1} step="0.02" />
                    </div>
                </div>
                    }
            {metalness !== null &&
               <div>
                <p>Metallic Factor</p>
                <div className="slideContainer" >
                    <input value={metalness} onChange={(e: any) => { numValueChange('Metalness', parseFloat(e.target.value))}} />
                    <input className='slider' type="range" value={metalness} onChange={(e: any) => { numValueChange('Metalness', parseFloat(e.target.value))}}
                        min={0} max={1} step="0.02" />
                    </div>
                </div>
                    }
            <TextureBox
                texture={pbrMap}
                assignTexture={(tex) => assignTexture(tex, 'Metalness')}
                onUpload={(newTex: string) =>
                    handleUpload(material.id, 'Metalness', newTex)} />
        </CollapsableGroup>
        <CollapsableGroup groupName="Emissive" startExpanded={false}>
            {emissiveIntensity !== null &&
                <div>
                <p>Emissive</p>
                <div className="slideContainer" >
                    <input value={emissiveIntensity} onChange={(e: any) => { numValueChange('Emissive', parseFloat(e.target.value))}} />
                    <input className='slider' type="range" value={emissiveIntensity} onChange={(e: any) => { numValueChange('Emissive', parseFloat(e.target.value))}}
                        min={0} max={1} step="0.02" />
                    </div>
                </div>
            }
            {emissiveFactor !== null && <div>
                <p>Emissive Factor</p>
                <div className="emmisiveFactorContainer">
                    <Button
                        type='primary'
                        onClick={() => emissiveColorChange("")}>
                        Reset
                    </Button>
                    <ColorBox hideTitle={true}
                        color={emissiveFactor}
                        setColor={emissiveColorChange}
                        materialId={material.id} />
                </div>
            </div>
            }
            <TextureBox
                texture={emissiveMap}
                assignTexture={(tex) => assignTexture(tex, 'Emissive')}
                onUpload={(newTex: string) =>
                    handleUpload(material.id, 'Emissive', newTex)} />
        </CollapsableGroup>
        <CollapsableGroup groupName="Normal" startExpanded={false}>
            {normalMap !== null && normalScale != null && // Scale Only matters if a normal map exists - (it defaults to 1)
                <MaterialInput value={normalScale} fieldName="Normal Scale"
                    handleChange={(e: any) =>
                        numValueChange('Normal', parseFloat(e.target.value))
                    } />}
            <TextureBox
                texture={normalMap}
                assignTexture={(tex) => assignTexture(tex, 'Normal')}
                onUpload={(newTex: string) =>
                    handleUpload(material.id, 'Normal', newTex)} />
        </CollapsableGroup>
        <CollapsableGroup groupName="Occlusion" startExpanded={false}>
            {occlusiveMap != null && occlusiveStrength !== null &&
                // Occlusive strength only matters if an occlusive map exists - (it defaults to 1)
                <MaterialInput value={occlusiveStrength} fieldName="Occlusive Strength"
                    handleChange={(e: any) =>
                        numValueChange('Occlusion', parseFloat(e.target.value))
                    } />}
            <TextureBox
                texture={occlusiveMap}
                assignTexture={(tex) => assignTexture(tex, 'Occlusion')}
                onUpload={(newTex: string) =>
                    handleUpload(material.id, 'Occlusion', newTex)} />
        </CollapsableGroup>
        <CollapsableGroup groupName="Advanced" startExpanded={false} >
            {side !== null &&
                <SideInput value={side} handleChange={sideChange} />}
            {alphaMode !== null &&
                <AlphaModeInput value={alphaMode} handleChange={alphaBlendModeChange} />}
            {wrapS !== null &&
                <WrapInput value={wrapS} fieldName='WrapS'
                    handleChange={(e) => wrappingChange('WrapS', e.target.value)} />}
            {wrapT !== null &&
                <WrapInput value={wrapT} fieldName='WrapT'
                    handleChange={(e) => wrappingChange('WrapT', e.target.value)} />}
        </CollapsableGroup>
    </>;
}
