import { HandleSelectType, ResourceType } from "../common/fileselect/useFileSelect";
import {
  ModelWidget,
  SpriteWidget,
  ImageWidget,
  VideoWidget,
  AudioWidget,
  AreaTargetWidget, 
  FaceTargetWidget, 
  ImageTargetWidget,
  ModelTargetWidget, 
  PlaneTargetWidget,
  BaseWidget,
  SceneWidget
} from "@proviz/proviz-sdk";
import { AddNode } from "../../commands";
import { TargetType } from '@proviz/api-services';
import { ManagerStore } from "../../store";

export const useHandleAddResourceWidget = () => {
  let manager = ManagerStore((s) => s.GraphicsManager);
  return (
    selectResourceCallback: HandleSelectType | undefined,
    resourceType: ResourceType,
    resourceId: string, 
    resourceName: string,
    targetType?: TargetType,
    parent?: BaseWidget | undefined,
    addToSelected = true
  ) => {
    if (selectResourceCallback) {
      selectResourceCallback(resourceId);
    } else if (resourceType === "Model") {
      const wdg = new ModelWidget(manager.proVizScene);
      wdg.label = resourceName;
      wdg.modelId = resourceId;
      wdg.init();
      manager.execute(new AddNode(manager, wdg, parent, addToSelected));
    } else if (resourceType === "Scene") {
      const wdg = new SceneWidget(manager.proVizScene);
      wdg.sceneId = resourceId;
      wdg.init();
      manager.execute(new AddNode(manager, wdg, parent, addToSelected));
    } else if (resourceType === "Sprite") {
      const wdg = new SpriteWidget(manager.proVizScene);
      wdg.spriteId = resourceId;
      wdg.init();
      manager.execute(new AddNode(manager, wdg, parent, addToSelected));
    } else if (["AreaTarget", "ModelTarget"].includes(resourceType)) {
        if (targetType === "AreaTarget") {
            const wdg = new AreaTargetWidget(manager.proVizScene);
            wdg.targetId = resourceId;    
            wdg.init();
            manager.execute(new AddNode(manager, wdg, parent, addToSelected));
        } else if (targetType === "Face") {
            const wdg = new FaceTargetWidget(manager.proVizScene);
            wdg.init();
            manager.execute(new AddNode(manager, wdg, parent, addToSelected));
        } else if (targetType === "Image") {
            const wdg = new ImageTargetWidget(manager.proVizScene);   
            wdg.init();
            manager.execute(new AddNode(manager, wdg, parent, addToSelected));
        } else if (targetType === "Model") {
            const wdg = new ModelTargetWidget(manager.proVizScene);
            wdg.targetId = resourceId;    
            wdg.init();
            manager.execute(new AddNode(manager, wdg, parent, addToSelected));
        } else if (targetType === "Plane") {
            const wdg = new PlaneTargetWidget(manager.proVizScene);  
            wdg.init();
            manager.execute(new AddNode(manager, wdg, parent, addToSelected));
        }
    } else if (!["Model", "Scene", "Sprite", "AreaTarget", "ModelTarget"].includes(resourceType)) {
      if (resourceType === "Texture") {
        const wdg = new ImageWidget(manager.proVizScene);
        wdg.fileIds = {
          en: resourceId || "",
        };
        wdg.init();
        manager.execute(new AddNode(manager, wdg, parent, addToSelected));
      } else if (resourceType === "Image") {
          const wdg = new ImageWidget(manager.proVizScene);
          wdg.fileIds = {
            en: resourceId || "",
          };
          wdg.init();
          manager.execute(new AddNode(manager, wdg, parent, addToSelected));
      } else if (resourceType === "Video") {
        const wdg = new VideoWidget(manager.proVizScene);
        wdg.videoOptions = {
          en: resourceId || "",
        };
        wdg.init();
        manager.execute(new AddNode(manager, wdg, parent, addToSelected));
      } else if (resourceType === "Audio") {
        const wdg = new AudioWidget(manager.proVizScene);
        // wdg.audioOptions = {
        //   en: resourceId || "",
        // };
        wdg.init();
        manager.execute(new AddNode(manager, wdg, parent, addToSelected));
      }
    }
  };
};
