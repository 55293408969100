import { SceneMode } from '../../..';
import { ModuleService } from '../../../moduleService';
import { ProVizScene } from '../../../ProVizScene';
import { BaseWidget } from '../../baseWidget';
import { BaseWidgetProperty } from '../../BaseWidgetProperty';
import { IBaseWidgetType } from '../../IBaseWidgetType';

export class EntryPointWidget extends BaseWidget implements IBaseWidgetType {
  public static type: string = 'entrypoint';

  public windowParameters: string[] = [];
  public version = 2;

  private entered: boolean = false;

  constructor(scene: ProVizScene, parent?: BaseWidget) {
    super(scene, parent);

    this.usage = 'Flow';
    this.category = 'Events';
    this.widgetType = EntryPointWidget.type;
    this.widgetName = 'Entry Point';
    this.label = 'Scene Entry Point';
    this.selectable = true;
    this.events = [
      {
        label: 'Scene Start',
        name: 'start',
      },
      {
        label: 'Scene Exit',
        name: 'exiting',
        desc: 'Only has effect in Unity apps.',
      },
    ];

    this.services = [
      {
        label: 'Exit Scene',
        name: 'exit-scene',
        desc: 'Only has effect in Unity apps.',
      },
    ];
  }

  public serialize(): any {
    const result = super.serialize();
    result.windowParameters = this.windowParameters;
    return result;
  }

  deserialize(data: any) {
    super.deserialize(data);
    this.windowParameters = data.windowParameters;

    const searchParams = new URL(window.location.href).searchParams;
    this.windowParameters.forEach((val: string) => {
      this.events.push({
        label: val,
        name: `value-change-${val}`,
        data: searchParams.get(val),
      });
    });
  }

  public migrate(data: any): Promise<any> {
    if (!data.version) {
      data.version = 1;
    }

    switch (data.version) {
      case 1:
        if (data.windowParameters === '' || !data.windowParameters) {
          data.windowParameters = [];
        } else if (typeof data.windowParameters === 'string') {
          data.windowParameters = (data.windowParameters || '').split(',');
        }
    }
    return data;
  }

  public getProperties(): BaseWidgetProperty[] {
    const result = super.getProperties();
    return [
      ...result,
      this.createProperty(
        'windowParameters',
        'Query Options',
        'Data',
        'list',
        'list',
        true,
        () => this.windowParameters,
        (value: string[], updateNodes) => {
          this.windowParameters = value;
          this.events = [
            {
              label: 'Scene Start',
              name: 'start',
            },
          ];
          this.windowParameters.forEach((val: string) => {
            this.events.push({
              label: val,
              name: `value-change-${val}`,
            });
          });
          updateNodes && updateNodes();
        },
      ),
    ];
  }

  update(delta: number) {
    super.update(delta);
    // Only triggered if not in editor and not triggered before
    if (this.scene.sceneMode !== SceneMode.Editor && !this.entered) {
      this.triggerProVizEvent('start', 'none');
      this.entered = true;
    }
  }
}

ModuleService.Register(EntryPointWidget.type, EntryPointWidget);
