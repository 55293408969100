import { APIFile } from "@proviz/api-services";
import { ReactElement } from "react";
import { useGetFileThumbnail } from "./useGetFileThumbnail";

interface Props {
    file: APIFile;
    className?: string;
}

export default function FileThumbnail(props: Props): ReactElement {
    const getThumbnail = useGetFileThumbnail();

    return <img
        src={getThumbnail(props.file)}
        alt=''
        className={props.className}
    />;
}