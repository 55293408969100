import { ProVizScene } from "@proviz/proviz-sdk";
import { Scene, AmbientLight } from "three";

export default class Lights {
  ambientLight: AmbientLight;
  scene: Scene;

  constructor(scene: Scene) {
    this.scene = scene;

    this.ambientLight = new AmbientLight(0xffffff, 1);
    scene.add(this.ambientLight);
  }

  applySceneSettings(scene: ProVizScene) {
    this.ambientLight.intensity = scene.ambientIntensity ?? 1;
    this.ambientLight.color.set(scene.ambientColor ?? "#fff");
    scene.addEventListener("light-update", () => {
      this.ambientLight.intensity = scene.ambientIntensity;
      this.ambientLight.color.set(scene.ambientColor ?? "#fff");
    });
  }

  dispose() {
    this.scene.remove(this.ambientLight);
    this.ambientLight.dispose();
    // @ts-ignore
    delete this.scene;
  }
}
