import { debounce } from 'lodash';
import { APISprite, SpriteService } from "@proviz/api-services"
import { useEffect, useRef, useState } from 'react'
import Modal from '../common/modals/Modals';

interface Props {
    sprite: APISprite;
    close: () => void;
}

const SaveStates = ['Saving', 'Saved'];
type SaveState = typeof SaveStates[number];

export const SpriteModal = (props: Props) => {
    const { sprite, close } = props;
    const [saveState, setSaveState] = useState<SaveState>('Saved');

    const [name, setName] = useState(sprite.name || "");
    const [horizontalTiles, setHorizontalTiles] = useState(sprite.horizontalTiles);
    const [verticalTiles, setVerticalTiles] = useState(sprite.verticalTiles);
    const [totalTiles, setTotalTiles] = useState(sprite.numberOfTiles);

    const isFirstRender = useRef(true);
    const debouncedUpdate = useRef(debounce(async (spriteData: APISprite) => {
        await SpriteService.update(spriteData);
        setSaveState('Saved');
    }, 1000, { trailing: true }));


    useEffect(() => {
        if (isFirstRender.current) {
            // we should not update the server when we first load the values
            isFirstRender.current = false;
            return;
        }
        setSaveState('Saving');
        const spriteData = Object.assign({}, sprite);
        spriteData.name = name;
        spriteData.horizontalTiles = horizontalTiles;
        spriteData.verticalTiles = verticalTiles;
        spriteData.numberOfTiles = totalTiles;
        debouncedUpdate.current(spriteData);
    }, [sprite, name, horizontalTiles, verticalTiles, totalTiles, debouncedUpdate]);

    return <Modal
        title={
            <div className="spriteModalHeader">
                <h1>Edit Sprite</h1>
                <a href={sprite.file?.location}>Download</a>
            </div>
        }
        statusMessage={saveState}
        close={close}
    >
        <h6>Name:</h6>
        <input value={name} onChange={(e) => setName(e.target.value)} />

        <h6>Horizontal Tiles</h6>
        <input value={horizontalTiles} onChange={(e) => setHorizontalTiles(parseInt(e.target.value))} />

        <h6>Vertical Tiles</h6>
        <input value={verticalTiles} onChange={(e) => setVerticalTiles(parseInt(e.target.value))} />

        <h6>Total Number of Tiles</h6>
        <input value={totalTiles} onChange={(e) => setTotalTiles(parseInt(e.target.value))} />
    </Modal>
}
