import { PermissionEntity, PermissionType } from '@proviz/api-services'
import React, {  useMemo } from 'react'
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { StaticContext } from 'react-router';
import withToken from "./WithToken";
import { userStore } from '../store';

type IPermission = {
    type: PermissionType;
    entity: PermissionEntity;
}

type Props = {
    permission?: IPermission;
    component: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>;
    path: string;
    exact?: boolean;
    fallback: string
}

/**
 * Authenticated routes require a user to have certain permissions to 
 * access. 
 */
function AuthorizedRoute(props: Props) {
    const hasPermission = userStore( s => s.hasPermission)
    const { permission, path, component, exact, fallback } = props;
    const canAccess = useMemo(() => 
        permission ? hasPermission(permission.entity, permission.type) : true
    , [hasPermission, permission]);
    return canAccess
        ? <Route exact={exact} path={path} component={component} />
        : <Redirect to={fallback} />;
}

// merge with protected route and pass in the permissions optionally

export default withToken(AuthorizedRoute)